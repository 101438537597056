import React, { useEffect, useState } from "react";

// API STUFF
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { groupStartupAdd } from "private/Apollo/Mutations";

// UI COMPONENTS
import { NotataButton } from "Components/NotataComponents";
import { groupGetV2 } from "../../../Apollo/Queries";
import { Loader } from "../../../../Components/NotataComponents";

// MAIN FUNCTION
export default function GroupStartupsImport({
    fromGroupId,
    toGroupId,
    canInvite,
    preStartups
  }) {

  // States
  let [isAdding, setIsAdding] = useState({});

  // Queries
  let {data, loading} = useQuery(groupGetV2, {variables: {id : fromGroupId}})

  // Mutations
  let [addStartupMutation] = useMutation(groupStartupAdd);

  // Maps & Reducers
  const group = data?.groupGetV2 || {}

  // Mutations
  const addStartup = async(creativeId) => {
    if (isAdding[creativeId]) {
      return;
    }

    setIsAdding({
      ...isAdding,
      [creativeId]: true,
    });

    let variables = {
      groupId: toGroupId,
      creativeId: creativeId,
    };
    try {
      await addStartupMutation({ variables });
    } catch (error) {
      console.log("error", error);
    }
    setIsAdding({
      ...isAdding,
      [creativeId]: false,
    });
  }

  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">Import from Group</span>
      <div className="invite-member-modal__member-container__data">

        {
          (loading && !data) && (
            <Loader
              style={{
                position: "relative",
                top: "0",
                left: "0",
                paddingTop: "20px",
                paddingBottom: "20px"
              }}
            />
          )
        }

        {
          (data && group?.startups?.length === 0) && (
            <p className="message--not-found" >
              This group has no startups...
            </p>
          )
        }


        {group?.startups?.map(( startup, index) => {
          let { creative } = startup;
          let isAlreadyInvited = preStartups?.some(m => m?.creative?.name === creative?.name);
          return (
            <div
              className="invite-member-modal__member-container__member"
              key={`group-memebr-key-${index}`}
              >
              <div className="invite-member-modal__member-container__member__info">
                <div className="invite-member-modal__member-container__member__info__name">
                  {creative?.name}
                </div>
              </div>
              <NotataButton
                loading={isAdding[creative?.id]}
                text={
                  isAlreadyInvited ? 'added' : "add"
                }
                bgcolor={isAlreadyInvited ? "outlined" : "primary_color"}
                size="medium invite-member-modal__member-container__member__invite-btn"
                onClick={async () => !isAlreadyInvited ? await addStartup(creative?.id) : null}
              />
            </div>
          )
        })}

      </div>
    </div>
  )
}