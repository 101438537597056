import React, { useEffect, useState } from "react";
import Scrollspy from "react-scrollspy";
import queryString from "query-string";

// API STUFF
import { useLazyQuery, useMutation } from "@apollo/client";
import { 
  evaluationUpdate, 
  evaluationCreate, 
  groupEvaluationAdd
} from "private/Apollo/Mutations";
import {
  connectionGet,
  evaluationGet,
  evaluationTemplateGet,
} from "private/Apollo/Queries";
import {
  evaluationTemplateGet as publicEvaluationTemplateGet,
  publicSharingGet
} from "public/Apollo/Queries";

// UI COMPONENTS
import { Loader } from "Components/NotataComponents";

// DEFINITIONS
import { evaluation_summary_page } from "definitions";

// OTHERS
import TemplatedForm  from "Components/Forms/TemplatedForm"

// STYLES
import "./Evaluate.scss";

const transform = obj => {
  if (obj) {
    return {
      inputType: obj.inputType,
      questionId: obj.questionId,
      sectionId: obj.sectionId,
      sid: obj.sid,
      val: obj.val,
    };
  }
};


export default function EvaluatePage({
   match,
   history,
   location,
   evaluateModel,
   sharedId,
   templId,
   saveEvaluation,
   publicLoading
}) {
  
  let { connectionId, templateId, evaluationId } = match?.params ?? {};

  // States
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState({});

  // Queries
  const [getConnection, connectionRes] = useLazyQuery(connectionGet);
  const queryOptions = { context: { clientName: "public" } };
  const [getTemplate, templateRes] = useLazyQuery(templId ? publicEvaluationTemplateGet :  evaluationTemplateGet, templId && queryOptions);
  const [getPublicSharingGetPublic, publicSharingPublicData] = useLazyQuery(publicSharingGet, queryOptions);
  const [getEvaluation, evaluationRes] = useLazyQuery(evaluationGet);

  // Mutations
  const [mutateEvaluationUpdate] = useMutation(evaluationUpdate, {
    refetchQueries: {query: connectionGet, evaluationTemplateGet, evaluationGet}
  });
  const [mutateEvaluationCreate] = useMutation(evaluationCreate, {
    refetchQueries: {query: connectionGet, evaluationTemplateGet, evaluationGet}
  });

  const [evaluationAddToGroup] = useMutation(groupEvaluationAdd, {
    refetchQueries: {query: connectionGet, evaluationTemplateGet, evaluationGet}
  });

  // Maps and reducers
  let connection = connectionRes?.data?.connectionGet;
  let template = templateRes?.data?.evaluationTemplateGet;
  let evaluation = evaluationRes?.data?.evaluationGet;
  let publicEvaluation = publicSharingPublicData?.data?.publicSharingGet;


  // Effects
  useEffect(() => {
    if(connectionId){
      try {
        let variables = { id: connectionId };
        getConnection({ variables });
      } catch (error) {
        return console.log("error", error);
      }
    }
  }, [connectionId]);

  useEffect(() => {
    try {
      let variables = { id: templateId ?? templId };
      getTemplate({ variables });
    } catch (error) {
      return console.log("error", error);
    }
  }, [templateId, templId]);

  useEffect(() => {
    if (evaluationId) {
      try {
        let variables = { id: evaluationId };
        getEvaluation({ variables });
      } catch (error) {
        return console.log("error", error);
      }
    }
  }, [evaluationId]);

  useEffect(()=>{
    if(history?.location?.search && sharedId){
      try {
        const email = new URLSearchParams(history.location.search).get('sharing');
        let variables = { id: sharedId, email: email };
        getPublicSharingGetPublic({ variables });
      } catch (error) {
        return console.log("error", error);
      }
    }
  },[])

  useEffect(() => {
    if (evaluation) {
      let ansArr = evaluation?.answers?.map(transform);
      setAnswers(ansArr || []);
    }
  }, [evaluation]);

  useEffect(()=>{
    if(publicEvaluation){
      setAnswers(publicEvaluation?.publicEvaluation?.answers || []);
    }
  }, [publicEvaluation])
  
  let isLoading = connectionRes.loading || templateRes.loading || evaluationRes.loading || publicSharingPublicData.loading;

  // Functions
  const save = async (answers) => {


    if (saveEvaluation) {
      saveEvaluation(answers);
      return;
    }
    let { groupId } = queryString.parse(location.search);

    setLoading(true);

    // Create new
    if (!evaluationId) {
      try {
        let variables = {
          connectionId,
          templateId,
          answers,
        };
        let res = await mutateEvaluationCreate({ variables });
        evaluationId = res?.data?.evaluationCreate?.id;
      } catch (error) {
        console.log("error", error);
      }
    }

    // Update
    if (evaluationId) {
      try {
        let updateVariables = {
          id: evaluationId,
          answers,
        };
        await mutateEvaluationUpdate({
          variables: updateVariables,
        });
      } catch (error) {
        console.log("error", error);
      }
    }

    if (groupId) {
      // Variables
      let variables = {
        evaluationId,
        groupId,
        creativeId: connection.creative.id,
      };

      // Add evaluation
      try {
        await evaluationAddToGroup({ variables });
      } catch(error) {
        console.log("error", error);
      }
    }

    history.push(
      `${evaluation_summary_page}/${connectionId}/${templateId}/${evaluationId}?groupId=${groupId ? groupId : ""}`,
      {
        evaluationId: evaluationId,
        groupId: groupId,
        creative: connection?.creative,
        connection
      }
    )
  }

  return (
    <div className="edit-evaluation">
      <div className="card edit-evaluation-container__card col-md-12">
      {isLoading && <Loader/>}
      <div>
        <span
          className="material-icons back-icon"
          onClick={() =>
            history.go(-1)
          }
        >
          arrow_back_ios
        </span> 
        {evaluateModel && (
           <span
           className="material-icons back-icon"
           onClick={evaluateModel}
         >
            arrow_back_ios
          </span>
        )}
        <span className="page-heading">
          {connection?.creative?.name===undefined?
           template?.name:
        ` ${connection?.creative?.name}: ${template?.name}`
        }
        </span>
      </div>
      <div className="edit-evaluation-container">
        <div className="menu-container-1">
          <Scrollspy
            items={template?.sections || []}
            currentClassName="is-current"
          >
            {template?.sections?.map(section => (
              <li key={section.id}>
                <a
                  href={`#${section.name}`}
                  onClick={() => {
                    setCollapsed({
                      ...collapsed,
                      [section.id]: false,
                    });
                  }}
                >
                  {section.name}
                </a>
              </li>
            ))}
          </Scrollspy>
        </div>


        <TemplatedForm
          template={{
            sections: template?.sections,
          }}
          location={location}
          loading={loading || publicLoading}
          content={answers}
          submit={answers => {
            setAnswers(answers);
            save(answers);
          }}
          onCancel={() => history.go(-1)}
          showComments={true}
        />
      </div>
    </div>
    </div>
  )
}
