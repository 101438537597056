import React, { useState } from "react";
import DeleteMemberModal from "./SettingsYourTeamModals/DeleteMemberModal";

import { NotataList } from "Components/NotataComponents";

export default function TeamMembers({
  teamMembers
}) {

  // Mutations
  const [deleteModalForMember, setDeleteModalForMemberForMember] = useState(undefined);

  let tableHeader = [
    {
      className: "settingsYour-team__icon-container"
    },
    {
      className: "settingsYour-team__member",
      content: <div>Team members</div>
    }
  ]

  let tableBody = teamMembers?.map(member => [

    {
      content: (
        <i
          className="fa fa-trash-alt"
          aria-hidden="true"
          style={{
            visibility: member.isMe ? "hidden" : "visible",
          }}
          onClick={() => {
            setDeleteModalForMemberForMember(member);
          }}
        />
      )
    },

    {
      content: (
        <div className="settingsYour-team__members__member">

          <div>
            {member.given_name} {member.family_name}
            {member.isMe && " (you)"}
          </div>

          <div className="settingsYour-team__members__member__email">
            {member.email}
          </div>

        </div>
      )
    }

  ])


  return (
    <>
      <NotataList
        header={tableHeader}
        body={tableBody}
      />

      {deleteModalForMember && (
        <DeleteMemberModal
          close={() => setDeleteModalForMemberForMember(undefined)}
          member={deleteModalForMember}
        />
      )}
    </>
  );
}
