
export const TourSteps = {
  DASHBOARD: [
    {
      selector: '[data-tut="dashboard-menu"]',
      content: 'Your dashboard gives you a quick summary of whats going on in your account.',
    },
    {
      selector: '[data-tut="recently-added-startup"]',
      content: 'Here is a list of startups that have been shared with you in groups.',
    },
    {
      selector: '[data-tut="group-invitations"]',
      content: 'Here you can see open groups that you can join or private groups that you have been invited to.',
    },
    {
      selector: '[data-tut="my-startups"]',
      content: 'Here you can see the last three startups that you’ve been working on.',
    },    
    {
      selector: '[data-tut="news"]',
      content: 'This is where we share news with you! Do you know about anything exciting that we should share with our community? Let us know!',
      action: (node) => {
        // by using this, focus trap is temporary disabled
        node.focus()
        //node.scrollIntoView();
      },
    },
    {
      selector: '[data-tut="notifications"]',
      content: 'If you have any notifications from groups, investors, startups or team members they will appear here.',
    },
    {
      selector: '[data-tut="reports-analytics"]',
      content: 'Here is a graphical summary of your activity.'
    },
  ],
  MYSTARTUPS:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'My Startups is where you can see all of the startups that you and your team have added to your deal flow. You may also sync with any groups you are part of and add the startups from those groups to "My Startups".',
    },
    {
      selector: '[data-tut="add-new-startup"]',
      content: 'To add a startup to your deal flow, click here.',
    },
    {
      selector: '[data-tut="search-startup"]',
      content: 'Search among startups in your deal flow.',
    },
    {
      selector: '[data-tut="kanban-tab"]',
      content: 'Search among startups in your deal flow.',
    },
    {
      selector: '[data-tut="list-tab"]',
      content: 'Search among startups in your deal flow.',
    },
    {
      selector: '[data-tut="manage-columns"]',
      content: 'Search among startups in your deal flow.',
    },
    {
      selector: '[data-tut="startup-filter"]',
      content: 'Search among startups in your deal flow.',
    },
  ],
  COMPANYPAGESTATRUPINFO:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'Startup page is all about startups. Ask a startup to add their info. Here is where you will see the evaluations you have made of this startup. The startup overview and which groups you are a part of that also have this startup listed there.',
    },
    {
      selector: '[data-tut="edit-info"]',
      content: 'Edit the startup info yourself if you have enough information.',
    },
    {
      selector: '[data-tut="invite-startup"]',
      content: 'In most cases, you would want the startup to fill in the company information for you. You can invite the startup to do this here.',
    },
    {
      selector: '[data-tut="startup-info"]',
      content: 'Under this tab you can see the company information',
    },
    {
      selector: '[data-tut="overview"]',
      content: 'The "overview" tab gives you access to general information, such as comments, tags, funnels, etc',
    },
    {
      selector: '[data-tut="evaluations"]',
      content: 'The "evaluation" tab takes you to the part of notata where you can evaluate startups',
    },
    {
      selector: '[data-tut="groups"]',
      content: 'The "groups" tab shows you what groups you are sharing this startup with, and what information you are sharing',
    },
  ],
  COMPANYPAGEOVERVIEW:[
    {
      selector: '[data-tut="notes"]',
      content: 'This is the comment section. Here you take notes for yourself or for your team.',
    },
    {
      selector: '[data-tut="subjective-score"]',
      content: 'The "subjective score" is used to set a simple score for your first impression ',
    },
    {
      selector: '[data-tut="overview-tags"]',
      content: 'The better you are at tagging a startup, the better reports you will get, and the easier it will be to filter and compare companies.',
    },
    {
      selector: '[data-tut="overview-funnels"]',
      content: 'Funnels represents the stage of your relationship with this company. ',
    },
    {
      selector: '[data-tut="overview-groups"]',
      content: 'Get a quick overview of what groups this startup is part of.',
    },
    {
      selector: '[data-tut="overview-impactgoals"]',
      content: 'These are the 17 UN impact goals. You can set what impact goals you think that the company is meeting.',
    },
    {
      selector: '[data-tut="overview-similar-startups"]',
      content: 'Here you get a list of 3 startups from your own deal flow that are the most similar to the current startup we are looking at (based on tags)',
    },
  ],
  COMPANYPAGEEVALUATION:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'Evaluations is where you can evaluate a startup. You may mark your subjective score and see all of the evaluations you and your team have made of an individual startup.',
    },
    {
      selector: '[data-tut="evaluation-startup"]',
      content: 'Click here to evaluate a startup',
    },
    {
      selector: '[data-tut="evaluation-templates"]',
      content: 'If you want to change the evaluation template, click here.',
    }
  ],
  COMPANYPAGEGROUPS:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'Here you may add a startup to a new group!',
    },
    {
      selector: '[data-tut="groups-add-new-group"]',
      content: 'If you want to add startup to a new group, you can click here.',
    }
  ],
  GROUPS:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'Groups is the place on notata where you can safely communicate and collaborate with other investors. Share deals, evaluate startups, chat and find co-investors.',
    },
    {
      selector: '[data-tut="create-new-group"]',
      content: 'Click here to create a new group. You can create public or private groups. You can also clone existing groups.',
    }
  ],
  GROUPSDASHBOARD:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'Public groups can be seen by anyone on notata. Private groups are a protected space where you can share sensitive information with others. You can request the members of a group to evaluate startups for you. You control what the members of the groups get access to see. Make as many groups as you want.',
    },
    {
      selector: '[data-tut="info-browse"]',
      content: 'Here you can edit the description, set permission for members, clone and delete the group',
    },
    {
      selector: '[data-tut="admin-view"]',
      content: 'As a group administrator, you can toggle view mode.',
    },
    {
      selector: '[data-tut="member-view"]',
      content: 'When "member view" is selected, you, as an admin, can see what the group looks like for members.',
    },
    {
      selector: '[data-tut="manage-templates"]',
      content: 'Admin only: By adding evaluation templates, the members of the group will be asked to evaluate the startup in the group with the shared template',
    },
    {
      selector: '[data-tut="invite-members"]',
      content: 'Admin only: You can invite members and set their roles here',
    },
    {
      selector: '[data-tut="manage-startups"]',
      content: 'Admin only: Here you can add startups to the group',
    },
    {
      selector: '[data-tut="group-chart"]',
      content: 'Stats section. Here you see a graph of the evaluations that have been shared with the group',
    },
    {
      selector: '[data-tut="startup-0"]',
      content: 'This is the "startup card". Here you access information shared with the group, like evaluation summaries, evaluation details, subjective scores, comments, etc.',
    }
  ],
  REPORTS:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'Reports visualize information about your deal flow including funnels, groups, people in your network, all the startups you have added, your different tags.',
    },
    {
      selector: '[data-tut="funnels"]',
      content: 'Here what your funnel looks like. If you have more than one funnel, you choose which funnel you want to see in the top left corner. You choose which funnel stage you want to see in the list by clicking the funnel stage bars in the graph.',
    },
    {
      selector: '[data-tut="my-groups"]',
      content: 'This is how many groups you are member of.',
    },
    {
      selector: '[data-tut="all-startups"]',
      content: 'This is all the starups you have in "my startups"',
    },
    {
      selector: '[data-tut="people-in-your-network"]',
      content: 'This is how many people you are in groups with.',
    },
    {
      selector: '[data-tut="group-startups"]',
      content: 'This is how many startups that have been shared in the groups you are member of.',
    },
    {
      selector: '[data-tut="tag-0"]',
      content: 'This is a tag group card. You can toggle how you want this data to be displayed by clicking the small buttons in the top right corner of the card.',
    }
  ],
  EVALUATIONTEMPLATES:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'An evaluation template is what you can use to evaluate a startup. Several templates come with notata and you may also customize as many different templates as you want to match up with different stages in your funnel, different applications of the tool or other decision making processes.',
    },
    {
      selector: '[data-tut="template-0"]',
      content: 'Here is your evaluation template. All templates consists of sections, and each section contains questions. You can clone a template by clicking the three little dots [...]',
    },
    {
      selector: '[data-tut="create-new-template"]',
      content: 'Create as many templates as you want, but we recommend sticking to a few good ones, as it makes it easier to compare companies.',
    },
  ],
  CREATEEVALUATIONTEMPLATES:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'A template is a place that is totally customizable, there are pre made options as well. You can create new templates with custom fields, questions, tags to make evaluations.',
    },
    {
      selector: '[data-tut="section-0"]',
      content: 'Each section contains a list of questions. You can clone a section by clicking the three little dots [...]',
    },
    {
      selector: '[data-tut="add-new-section"]',
      content: 'Create new section here.',
    },
    {
      selector: '[data-tut="import-section"]',
      content: 'Import sections from any of your evaluation templates as a starting point.',
    }
  ],
  SETTINGSFUNNEL:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'A funnel can display what stage of the evaluation journey you are on.',
    },
    {
      selector: '[data-tut="funnel-browse-0"]',
      content: 'To edit the funnel, you can click these three little dots.',
    },
    {
      selector: '[data-tut="create-new-group"]',
      content: 'If you want to have more than one funnel, you can create it here.',
    }
  ],
  SETTINGS:[
    {
      selector: '[data-tut="evaluation-templates"]',
      content: 'Edit, manage and create evaluation templates here. You can evaluate startups based on these templates.',
    },
    {
      selector: '[data-tut="setting-tags"]',
      content: 'Manage your tags and tag groups here.',
    },
    {
      selector: '[data-tut="setting-funnels"]',
      content: 'Edit your funnel, such as setting the colors, adding and removing stages, etc.',
    },
    {
      selector: '[data-tut="setting-your-team"]',
      content: 'You and your team share all information between each other, such as deal flow, evaluations, subjective scores, comments, etc.',
    },
    {
      selector: '[data-tut="setting-web-form"]',
      content: 'The web form allows you to add a form to your template that startups can fill out. When a form is submitted you will receive a notification that allows you to add the startup from the form to your deal flow.',
    },
    {
      selector: '[data-tut="setting-user-profile"]',
      content: 'This is your personal user profile.',
    },
    {
      selector: '[data-tut="setting-notifications"]',
      content: 'This is your notifications archive.',
    }
  ],
  SETTINGSTAGS:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'Tags help organize information. Tagsare customizable and can include ideas like phase, founders, location, tech, sector, product. Organize tags how you wish.',
    },
    {
      selector: '[data-tut="tags-0-0"]',
      content: 'If you remove a tag here, it will be removed from all the startups you have used this tag on.',
    },
    {
      selector: '[data-tut="add-new-tag"]',
      content: 'Add a new tag to a tag group here.',
    },
    {
      selector: '[data-tut="tags-0-browse"]',
      content: 'Edit the tag group properties here.',
    }
  ],
  SETTINGSYOURTEAM:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'You and your team share all information between each other, such as deal flow, evaluations, subjective scores, comments, etc.',
    },
    {
      selector: '[data-tut="team-members"]',
      content: 'These are your team members.',
    },
    {
      selector: '[data-tut="invite-new-member"]',
      content: 'Here you can invite a new person to join your team. They do not have to have an account with notata for this invitation. When you click [+ invite] an email with instructions will be sent to the recipient.',
    }
  ],
  SETTINGSWEBFORM:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'The web form allows you to add a form to your website that startups can fill out. When a form is submitted you will receive a notification that allows you to add the startup from the form to your deal flow.',
    },
    {
      selector: '[data-tut="link"]',
      content: 'Refer to this link from your website. Startups filling in this information will go straight to your notata account.',
    },
    {
      selector: '[data-tut="code"]',
      content: 'If you prefer to embed the notata form on your website, like YouTube is often embedded in blogs, you can use this iframe.',
    }
  ],
  DASHBOARDHOME:[
    {
      selector: '[data-tut="welcome-box"]',
      content: 'WELCOME TO NOTATA',
    },
    {
      selector: '[data-tut="tour-icon"]',
      content: 'Start and stop tour. You can toggle this tour by clicking this button. If you need help on any page, you can always start the tour for some hot tips and tricks.',
    },
    {
      selector: '[data-tut="notification-icon"]',
      content: 'Notifications. Hover this to see your latest notifications, or click to see your entire notifiaction log.',
    },
    {
      selector: '[data-tut="profile-icon"]',
      content: 'Here you find some handy shortcuts.',
    },
    {
      selector: '[data-tut="dashboard-menu"]',
      content: 'Your dashboard gives you a quick summary of whats going on in your account.',
    },
    {
      selector: '[data-tut="my-startups-menu"]',
      content: 'My startups shows you all the startups that you have in your deal flow. If you are part of a team, you have access to the same deal flow.',
    },
    {
      selector: '[data-tut="groups-menu"]',
      content: 'Groups is where you share startups, evaluations and comments with people outside of your core team.',
    },
    {
      selector: '[data-tut="reports-menu"]',
      content: 'Reports gives you a general overview of what your dealflow looks like. It is based on funnels and tags, so the better you are at tagging your startups, the better reports you will get.',
    },
    {
      selector: '[data-tut="news-menu"]',
      content: 'News is where we will occasionally update you with news that team Notata thinks you might be interested in.',
    },
    {
      selector: '[data-tut="settings-menu"]',
      content: 'On the settings page, you can customize parts of notata.',
    },
  ],
}