import gql from "graphql-tag";
import SimpleUserFields from "../User/SimpleUserFields"

export default gql`

    fragment LogFields on LogItem {
        id
        isMe
        createdAt
        updatedAt
        createdByUser {
            ...SimpleUserFields
        }
        logType
        dataPairs {
            val
            key
        }
    }
    ${SimpleUserFields}
`




