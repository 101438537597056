import gql from "graphql-tag";
import SimpleUserFields from "../User/SimpleUserFields"

export default gql`
    fragment SubjectiveScoreFields on SubjectiveScore {
        score
        createdBy
        createdAt
        isMe
        createdByUser {
            ...SimpleUserFields
        }
    }
    ${SimpleUserFields}
`




