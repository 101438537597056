import React from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { connectionPut } from "private/Apollo/Mutations";

// UI COMPONENTS
import { Modal } from "Components/NotataComponents";

// DEFINITIONS
import { archive_page } from "definitions";

// MAIN FUNCTION
export default function ArchiveModal({ connection, close, history }) {

  // MUTATION
  const [archive, archiveRes] = useMutation(connectionPut);

  return (
    <Modal
      title="Archive startup"
      loading={archiveRes.loading}
      submit={async () => {
        // varoables
        let variables = {
          id: connection.id,
          input: {
            archived: true,
          },
        };
        try {
          await archive({ variables });
        } catch (error) {
          return console.log("error", error);
        }
        history.push(archive_page,connection.id);
      }}
      close={close}
      submitTxt="Archive"
      closeTxt="CANCEL"
      children={
        <div className="archive-modal-description">
          When archiving a startup it will not longer be visible in your deal flow (my startups), but it will show up in your reports. If you want to remove the startup completely, you will have to delete it.
        </div>
      }
    />
  );
}