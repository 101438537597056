import React, { useState } from "react";
import { useForm } from "react-hook-form";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { groupUserInvite } from "private/Apollo/Mutations";
import { groupsGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { Modal, InputFormWithButton, Dropdown } from "Components/NotataComponents";

// CUSTOM COMPONENTS
import InvitedMember from "./InvitedMember";
import PendingMembers from "./PendingMembers";
import CardMessageContainer from "../Components/CardMessageContainer";
import GroupMembersImport from "./GroupMembersImport";

// Validations
import validateEmail from "../../../../utils/validateEmail";

// OTHERS--form
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

export default function ManageMembersModal({ group, close }) {

  // CONSTANTS
  let canInvite = group?.iAmAdmin || group?.settings?.addUser;
  let canRemove = group?.iAmAdmin;

  // States
  const [isEmail, setIsEmail] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState();

  // Queries
  let groupsQuery = useQuery(groupsGetV2);
  // Data maps
  let groups = groupsQuery?.data?.groupsGetV2 || [{name:"--Select Group--", id:"0"}];

  // Mutations
  const [userInvite, userInviteRes] = useMutation(groupUserInvite);

  // useForm
  const { register, handleSubmit, formState:{errors}, setValue } = useForm({
    resolver:  yupResolver(
      yup.object().shape({
        email: yup.string().email().required(),
      })
    ),
  });

  // ONSUBMIT FUNCTION
  async function onSubmit({ email }, event) {
    if (!canInvite) {
      return;
    }
    let variables = {
      groupId: group.id,
      email: email.toLocaleLowerCase().trim(),
    };
    try {
      await userInvite({ variables });
    } catch (error) {
      console.log("error", error);
    }

    setValue("email", "");
    setIsEmail("")
  }


  return (
    <Modal
      title="Manage Group Members"
      submit={(e)=>{
        if(e.key==="Enter") return null;
        else return close()
      }}
      close={close}
      submitTxt="OK"
      innerClassName="invite-member-modal"
    >

      <div className="invite-member-modal-container">
        <div className="row invite-row" >
          <CardMessageContainer
            title="Heads up!"
            message="By inviting someone to join this group, an automatic email will be sent with instructions to sign up and join your group free of charge."
            notice
          />

          {
            canInvite && (
              <div>

                {/* Search users */}
                <div className="col-sm-12" style={{ marginTop: "20px" }}>
                  <div className="invite-member-modal__search-header">
                    Invite user to join group
                  </div>

                  <div className="invite-member-modal__search"  >
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                    >
                      <div className="inputform">
                        <InputFormWithButton
                          type="email"
                          name="email"
                          placeholder="Email"
                          handleChange={(e) => {
                            setIsEmail(validateEmail(e.target.value));
                            setIsDuplicate(
                              group.members?.some(({ user }) => user?.email === e.target.value)
                            )
                          }}
                          btn={true}
                          loading={userInviteRes.loading}
                          buttonType={"submit"}
                          buttonStyle={`search-with-button ${!isEmail || isDuplicate ? ' search-with-button__not-validated' : ''}`}
                          btntext="invite"
                          setBtn={setIsEmail}
                          reference={register({ required: true })}
                          isValid={isEmail}
                        />
                      </div>

                      {isDuplicate && (
                        <div className="is-duplicate">
                          That user is already member of this group
                        </div>
                      )}
                    </form>
                  </div>
                </div>

                {/* Import users */}
                <div className="col-sm-12" style={{ marginTop: "20px" }}>

                  <div className="invite-member-modal__search-header">
                    Import users from existing group
                  </div>

                  <div className="invite-member-modal__select-group">
                    <Dropdown
                      title="Group"
                      items={groups}
                      setSelectedItem={item => {
                        setSelectedGroupId(item.id);
                      }}
                    />
                  </div>

                  {
                    selectedGroupId && (
                      <GroupMembersImport
                        toGroupId={group.id}
                        fromGroupId={selectedGroupId}
                        canInvite={canInvite}
                        preMembers={
                          [
                            ...group?.members?.map(({user}) => user.email) || [],
                            ...group?.pendingInvitations?.map(({email}) => email) || []
                          ]
                        }
                      />
                    )
                  }

                </div>
              </div>

            )
          }

        </div>


        {
          (
            group?.pendingInvitations &&
            group?.pendingInvitations?.length !== 0
          ) && (
            <PendingMembers
              pendingMembers={group.pendingInvitations}
              group={group}
              canRemove={canRemove}
              header={"PENDING INVITATIONS"}
            />
          )
        }

        <InvitedMember
          canInvite={canInvite}
          group={group}
          canRemove={canRemove}
        />

      </div>

    </Modal>
  );
}