import gql from "graphql-tag";

export default gql`
  fragment userStatsFields on UserStats {
    user {
      given_name
      family_name
    }
    lastLogin
    createdGroupsByUserCount
    joinedGroupsByUserCount
    peopleInvitedToGroupByUserCount
    connectionAccountOfUserCount
    evaluationCreatedByUserCount
    membersOnAccountCount
    membersOnAccount{
        email
        given_name
        family_name
        role
        latestActivity
        isMe
    }
    startupsInvitationCount
    loggedInCount
    invitedPeopleToEvaluateStartupCount
    isSuperAdmin
  }
`;

