import React, { useState } from "react";

//API STUFF
import {
  connectionExternalResourceRemove,
  connectionExternalResourceAdd,
} from "private/Apollo/Mutations";
import { connectionGet } from "private/Apollo/Queries";
import { useMutation } from "@apollo/client";

// UI COMPONENTS
import { NotataButton } from "Components/NotataComponents";

// CUSTOM  COMPONENTS
import AddExternalResourceModal from "../OverviewModals/AddExternalResourceModal";

// STYLES
import "./ExternalResources.scss";

// OTHERS
import moment from "moment";

export default function ExternalResources({ connection }) {
  // Constants
  const { externalResources } = connection;

  // STATES
  const [addNewResourceModal, setAddNewResourceModal] = useState(false);
  const [link, setLink] = useState("");
  const [label, setLabel] = useState("");
  const [removingLoader, setRemovingLoader] = useState({});

  // MUTATIONS
  const [removeExternalResource, removeExternalResourceRes] = useMutation(
    connectionExternalResourceRemove,
    {
      refetchQueries: [
        { query: connectionGet, variables: { id: connection.id } },
      ],
    }
  );
  const [addConnectionExternalResource, addExternalResourceRes] = useMutation(
    connectionExternalResourceAdd
  );

  // Functions

  // Delete External Resource function
  const deleteExternalResource = async externalResourceId => {
    setRemovingLoader({
      ...removingLoader,
      [externalResourceId]: true,
    });
    const { id } = connection;
    let variables = {
      connectionId: id,
      resourceId: externalResourceId,
    };
    try {
      await removeExternalResource({ variables });
    } catch (error) {
      console.log("error", error);
    }

    setRemovingLoader({
      ...removingLoader,
      [externalResourceId]: false,
    });
  };

  // Add External Resource function
  const addExternalResource = async () => {
    const { id } = connection;
    let variables = {
      connectionId: id,
      label: label,
      link: link,
    };
    try {
      await addConnectionExternalResource({ variables });
      setAddNewResourceModal(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  //    window.open(externalResource.link)
  const externalResourcesFunction = link => {
    if (link.includes("https://")) {
      window.open(link);
    } else {
      window.open(`https://${link}`);
    }
  };

  return (
    <div className="external-resources-container">
      {externalResources?.map((externalResource, index) => {
        return (
          <div key={index} className="external-resources-container__resource">
            <div
              className="external-resources-container__resource__resource-label"
              onClick={() => externalResourcesFunction(externalResource.link)}
            >
              {externalResource.label}
            </div>
            <div className="external-resources-container__resource__resource-createdAt">
              {moment(externalResource.createdAt).format("ll")}
            </div>
            <div
              className="external-resources-container__resource__delete"
              onClick={() => deleteExternalResource(externalResource.id)}
            >
              {removingLoader[externalResource.id] ? (
                <i className="fas fa-spinner fa-spin" />
              ) : (
                <i className="fal fa-trash-alt" />
              )}
            </div>
          </div>
        );
      })}

      {(!externalResources || externalResources.length === 0) && (
        <div className="card-empty-text" style={{ paddingTop: "0px" }}>
          Here you can add links to external resources, such as Google Drive and
          DropBox.
        </div>
      )}

      <div className="external-resources-container__add-new">
        <NotataButton
          size="large"
          bgcolor="primary_color"
          text="Add new resource"
          endIcon="add"
          onClick={() => setAddNewResourceModal(true)}
        />
      </div>

      {/* addNewResourceModal */}
      {addNewResourceModal && (
        <AddExternalResourceModal
          setLink={setLink}
          setLabel={setLabel}
          addExternalResource={addExternalResource}
          addExternalResourceRes={addExternalResourceRes}
          setAddNewResourceModal={setAddNewResourceModal}
        />
      )}
    </div>
  );
}
