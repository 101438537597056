import React, {useState}  from 'react'

// API STUFF
import { useMutation } from "@apollo/client";
import { groupStartupAdd } from "private/Apollo/Mutations";

// UI COMPONENTS
import { NotataButton } from "Components/NotataComponents";

// MAIN FUNCTION
export default function StartupSearchResult({ group, preStartups, data }) {
  // States
  let [isAdding, setIsAdding] = useState({});
  // Mutations
  let [addStartupMutation] = useMutation(groupStartupAdd);
  // Mutations
  const addStartup = async(creativeId) => {
    if (isAdding[creativeId]) {
      return;
    }

    setIsAdding({
      ...isAdding,
      [creativeId]: true,
    });

    let variables = {
      groupId: group.id,
      creativeId: creativeId,
    };
    try {
      await addStartupMutation({ variables });
    } catch (error) {
      console.log("error", error);
    }
    setIsAdding({
      ...isAdding,
      [creativeId]: false,
    });
  }
  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">Search results</span>
      <div className="invite-member-modal__member-container__data">
        {data.map(({creativeName, creativeId }, index) => {
          let isAlreadyInvited = preStartups?.some(m => m.creative?.name === creativeName);
          return <div className="invite-member-modal__member-container__member" key={`group-memebr-key-${index}`}>
            <div className="invite-member-modal__member-container__member__info">
              <div className="invite-member-modal__member-container__member__info__name">{creativeName}</div>
            </div>
            <NotataButton
              loading={isAdding[creativeId]}
              text={
                isAlreadyInvited ? 'added' : "add"
              }  bgcolor={isAlreadyInvited ? "outlined" : "primary_color"}
              size="medium invite-member-modal__member-container__member__invite-btn"
              onClick={async () => !isAlreadyInvited ? await addStartup(creativeId) : null}
            />
          </div>
        })}
      </div>
    </div>
  )
}