import React, { useEffect, useState } from "react";
// API Stuff
import { useMutation } from "@apollo/client";
import { connectionSubjectiveScorePut } from "private/Apollo/Mutations";
// STYLES
import styles from "../Spreadsheet/Table/styles.module.css";

// OTHERS
import classnames from "classnames";

function getMyScore({ connection }) {
  return connection?.subjectiveScores?.find(({ isMe }) => isMe)?.score;
}

export function SubjectiveScore({ connection, isPublic, score }) {
  // States
  const [subjectiveScore, setSubjectiveScore] = useState("");

  // mutations
  const [mutate, mRes] = useMutation(connectionSubjectiveScorePut);


  // store the value in state
  const handleUpdateScore = newScore => {
    if(newScore === subjectiveScore) {
      setSubjectiveScore("")
    } else {
      setSubjectiveScore(newScore);
    }
    if(isPublic){
      score && score(newScore);
      return;
    }
    let variables = {
      id: connection.id,
      score: newScore,
    };
    mutate({ variables });
  };

  // score given by this user or not
  useEffect(() => {
    let yourScore = getMyScore({ connection });
    if (yourScore) {
      setSubjectiveScore(yourScore || "");
    }
  }, []);

  return (

    <>

      <div className={styles.score}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(sc => (
          <div
            key={`sc-${sc}`}
            className={classnames(
              styles.child,
              subjectiveScore === sc ? styles.activeChild : ""
            )}
            onClick={() => handleUpdateScore(sc)}
          >
            <p>{sc}</p>
          </div>
        ))}
      </div>


    </>
  );
}
