import React ,{ useState } from 'react'
import { useMutation } from "@apollo/client";
import { groupPublicMemberRemove } from "../../../Apollo/Mutations";

// MAIN FUNCTION
export default function PublicMembers({canInvite, group, canRemove }) {

  const [loaders, setLoaders] = useState({});
  const [removeMember] = useMutation(groupPublicMemberRemove)

  async function removeUser({ email }) {
    if (loaders[email]) { return }
    setLoaders({...loaders, [email]: true })
    try {
      let variables = {
        groupId: group.id,
        email: email
      }
      await removeMember({variables})
    } catch (error) {
      console.log('error', error)
    }
    setLoaders({...loaders, [email]: false })
  }

  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">PUBLIC MEMBERS</span>
      <div className="invite-member-modal__member-container__data">

        {
          (
            !group.publicUsers ||
            group?.publicUsers?.length === 0
          ) && (
            <p className="message--not-found" >
              This group has no public members...
            </p>
          )
        }

        {
          group.publicUsers?.map((member, index) => {
            return (
              <div
                className="invite-member-modal__member-container__member"
                key={`group-memebr-key-${index}`}
                >
                {
                  canRemove && (
                    <i
                      className={
                        loaders[member?.email]
                          ? "fa fa-spinner fa-spin icon"
                          : "fas fa-trash-alt"
                      }
                      aria-hidden="true"
                      onClick={async () => await removeUser(member)}
                    />
                  )
                }
                <div className="invite-member-modal__member-container__member__info">
                  <div className="invite-member-modal__member-container__member__info__name">
                    {member.given_name} {member.family_name}
                  </div>
                  <div className="invite-member-modal__member-container__member__info__email">
                    {member?.email}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}