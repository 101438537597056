import React, { useEffect } from "react";

// API STUFF
import { useLazyQuery } from "@apollo/client";
import { connectionsGet } from "private/Apollo/Queries";

// UI COMPONENTS
import { Card, Tag } from "Components/NotataComponents";

// STYLES
import "./SimilarStartupsCard.scss"

// COMMON COMMPONENTS
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";

// DEFINITIONS
import { startup_page } from "definitions";

// OTHERS
import moment from "moment";
import { Link } from "react-router-dom";
import { CardComponent } from "../../../../../../Components/NotataComponents/Card/Card";

// GET TOTAL SCORE FUNCTION
const getTotalScore = arr => {
  if (Array.isArray(arr) && arr.length > 0) {
    return arr?.reduce((acc, obj) => {
      return acc + (obj.score || 0);
    }, 0);
  }
  return 0;
};

//  GET COMPAVG FUNCTION
const getCompAvg = subjectiveScores => {
  if (subjectiveScores) {
    return (
      getTotalScore(subjectiveScores) / subjectiveScores.length || 0
    ).toFixed(1);
  }
  return 0;
};


//MAIN FUNCTION
export default function SimilarStartupsCard({ connection, history }) {
  const [getSimilarConnections, { data /* , error, loading */ }] = useLazyQuery(
    connectionsGet
  );

  // EFFECTS
  useEffect(() => {
    if (!connection) {
      return;
    }

    // VARIABLES
    let variables = {
      filters: {
        similarTo: connection.id,
      },
    };

    getSimilarConnections({ variables });
  }, [connection, getSimilarConnections]);

  let similarConnections = data?.connectionsGet?.records;

  return (
    <CardComponent
    containerClassName="card similar-startup-card" data-tut="overview-similar-startups"
    title="Similar Startups"
    titleSize="medium"
    padding="medium"
    content={
      <>
        {/* <div className="overview-container__scores__heading card-heading">
        Similar Startups
      </div> */}
      <div className="similar-startups-contianer__table-container">
        <table>
          <thead>
            <tr className="grid-header">
              <th>COMPANY NAME</th>
              <th>TAGS</th>
              <th>SUBJECTIVE SCORE</th>
              <th>UPDATED</th>
            </tr>
          </thead>
          <tbody>
            {similarConnections?.map(connection => (
              <tr
                className="connection-link"
                key={connection.id}
                onClick={() => {
                  let path = `${startup_page}/company/${connection.id}`;
                  history.push(path);
                }}
              >
                <td className="company-name">
                  <Link className="similar_startups_table" to={`${startup_page}/company/${connection.id}`}>
                   <span>
                     {/* STARUP LOGO */}
                    <StartupLogo
                        url={connection.creative.logo}
                        startupName={connection.creative.name}
                        size={"mini"}
                      />
                    </span>
                    <span className="name">{connection?.creative?.name}</span>
                  </Link>
                </td>
                <td>
                  <div className="tag-placeholder">
                    {connection?.tags?.slice(0, 2).map(tag => (
                      <Tag 
                      color="primary_tag"
                      size="sm_tag"
                      label={`${tag.name}`}
                      key={tag.id}
                      tagmargin="tag_margin"
                      />
                    ))}
                    {connection?.tags?.length > 2 ? (
                      <p>
                        +{connection?.tags?.length - 2}
                      </p>
                    ) : null}
                  </div>
                </td>
                <td>
                  <div
                    className="subjective-score"
                    style={{
                      filter:
                        getCompAvg(connection.subjectiveScores) === "0.0"
                          ? "grayscale(0%)"
                          : "",
                    }}
                  >
                    {getCompAvg(connection.subjectiveScores)}
                  </div>
                </td>

                <td>
                  <span className="updated-date">
                    {moment(connection.updatedAt).format("ll")}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </>
    }
    >
      
    </CardComponent>
  );
}