import React, { useState } from "react";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { tagGroupsGet } from "private/Apollo/Queries";
import { tagGroupCreate } from "private/Apollo/Mutations";

// UI COMPONENTS
import {
  NotataButton,
  BreadCrumbs,
  TextBox,
  Loader,
  CardComponent,
  Modal,
} from "Components/NotataComponents";

// DEFINATIONS
import { settings } from "definitions";

// OTHER COMPONENTS
import SettingsTagGroup from "./SettingsTagGroup";

// STYLES
import "./SettingsTags.scss";

// MAIN FUNCTION
export default function SettingsTags({ history }) {
  // Queries
  const { data, loading } = useQuery(tagGroupsGet);

  // Mutations
  const [createTagGroup, createTagGroupRes] = useMutation(tagGroupCreate, {
    refetchQueries: [{ query: tagGroupsGet }],
  });

  // Data maps
  let tagGroups = data?.tagGroupsGet || [];

  // STATES
  const [newTagGroup, setNewTagGroup] = useState("");
  const [createModal, setCreateModal] = useState(false);

  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[{ seperator: ">", page1: "TAGS" }]}
      />

      <div className="settingsTags-container1">
        <CardComponent
          containerClassName="settingsTags-container1__card col-md-12"
          padding="large"
          title="Tags"
          titleSize="medium"
          backLink={() => history.push(settings)}
          content={
            <div>
              {!data && loading && <Loader />}
              <div className="settingsTags-container1__tags">
                {tagGroups.map((tagGroup, tagGroupIndex) => (
                  <SettingsTagGroup
                    tagGroup={tagGroup}
                    key={tagGroup.id}
                    tagGroupIndex={tagGroupIndex}
                  />
                ))}
              </div>
            </div>
          }
          footer={
            <NotataButton
              size="large"
              bgcolor="primary_color"
              startIcon="add"
              text="CREATE NEW GROUP"
              onClick={() => setCreateModal(true)}
            />
          }
        />

        {createModal && (
          <Modal
            title="Create new tag group"
            loading={createTagGroupRes.loading}
            submit={async () => {
              if (createTagGroupRes.loading) {
                return;
              }

              if (!newTagGroup.length) {
                return;
              }

              let variables = {
                input: {
                  name: newTagGroup,
                },
              };
              try {
                await createTagGroup({ variables });
              } catch (error) {
                console.log("error", error);
              }
              setCreateModal(false);
              setNewTagGroup("");
            }}
            close={() => {
              setCreateModal(false);
              setNewTagGroup("");
            }}
            submitTxt="Create"
            closeTxt="Cancel"
            children={
              <TextBox
                textBoxStyle={"settingsTags-container1__marginToptext"}
                type="text"
                placeholder="Tag group name"
                value={newTagGroup}
                onChange={e => setNewTagGroup(e.target.value)}
                autoComplete="off"
              />
            }
          />
        )}
      </div>
    </>
  );
}
