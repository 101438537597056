import React, { useState, useEffect } from "react";
// COMPONENTS
// import { Tabsection } from "Components/NotataComponents";
import { AddStartup } from "./AddStartup";
// import Expand from "../Spreadsheet/AddStartupModal/Expand";
import { Modal } from "Components/NotataComponents";


//STYLES
import styles from "./modal.module.css";
import { useHistory } from "react-router-dom";

export default function AddStartupModal({
   closeModal,
   connections,
   setFilters
  }) {

  const history = useHistory()

  // CONST
  const tabArr = [
    { value: "short", text: "short" },
    { value: "expand", text: "expand" },
  ];

  // STATES
  const [activeTab, setActiveTab] = useState();

  // EFFECT
  useEffect(() => {
    setActiveTab(tabArr[0]?.value);
  }, [closeModal]);

  if (!activeTab) return null;

  return (
    <Modal
      title="Add startup"
      close={closeModal}
      submitTxt={"OK"}
    >
      <div className={styles.startup}>
        <AddStartup
          history={history}
          closeModal={closeModal}
          connections={connections}
          styles={styles}
          setFilters={setFilters}
        />
      </div>
    </Modal>
  );
}
