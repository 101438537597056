import React from "react";

// STYLES
import "./EvaluationSummariesComp.scss";

import ScoreSummary from "Components/NotataComponents/ScoreSummary/ScoreSummary";

// MAIN FUNCTION
export default function EvaluationSummariesComp({ connection }) {
  return (
    <div>
      {connection?.evaluationSummaries?.map(evaluation => {
        return (
          <ScoreSummary
            key={evaluation?.templateId}
            title={evaluation.templateName}
            score={evaluation.averagePercentageScore}
            highest={evaluation.highestScore}
            lowest={evaluation.lowestScore}
            submissions={evaluation?.submissions}
          />
        );
      })}
    </div>
  );
}
