import React, { useState } from "react";
import moment from "moment";

// UI COMPONENTS
import {NotataButton, Card} from "Components/NotataComponents";

// MODALS
import ManageMembersModal from "../../../Modals/ManageMembersModal";
import ManageTemplatesModal from "../../../Modals/ManageTemplatesModal";
import ManageStartupsModal from "../../../Modals/ManageStartupsModal";
import ManagePublicMembersModal from "../../../Modals/ManagePublicMemberModal";

// STYLES
import "./ManageResources.scss"

function CustomButtonContent({ iconClass, label, count }) {
  return(
    <div className="manage-resources__actions__action">
      <div className="manage-resources__actions__action__icon">
        <i className={iconClass}/>
      </div>
      <div className="manage-resources__actions__action__text">
        {label}
      </div>
      <div className="manage-resources__actions__action__count">
        {count}
      </div>
    </div>
  )
}

function getCounters(group) {


  let counters = {
    log: 0,
    evaluations: 0,
    subjectiveScores: 0,
    publicEvaluations: 0,
    publicSubjectiveScores: 0,
    unreadLog: 0
  }
  for (let startup of group?.startups || []) {
    for (let key of Object.keys(counters)) {
      counters[key] += startup[key]?.length || 0;
    }
    counters.unreadLog += startup.log.filter(({seen}) => !seen).length;
  }
  return counters
}

function getLatestActivity(group) {
  let latestStamp = group.createdAt;
  for (let startup of group.startups || []) {
    if (startup.createdAt > latestStamp) {
      latestStamp = startup.createdAt
    }
    for (let { createdAt } of startup.evaluations || []) {
      if (createdAt > latestStamp) {
        latestStamp = createdAt
      }
    }
    for (let { createdAt } of startup.log || []) {
      if (createdAt > latestStamp) {
        latestStamp = createdAt
      }
    }
  }
  return latestStamp
}


// Main function
export default function ManageResources({ group, adminView }) {

  // STATES
  const [manageTemplateModal, setManageTemplateModal] = useState(false);
  const [inviteMembersModal, setInviteMembersModal] = useState(false);
  const [manageStartupsModal, setManageStartupModal] = useState(false);
  const [managePublicMemberModal, setManagePublicMemberModal] = useState(false);

  const counters = getCounters(group);

  const latestActivity = getLatestActivity(group);





  return (
    <>
      <Card containerClassName="manage-resources">

        <div className={"manage-list"}>

          {/* GENERAL SECTION */}
          <div className={"manage-list__section"}>

            {/* EVERYONE */}
            <div>

              {/* Date created */}
              <div className={"manage-line"}>
                <div className={"manage-line__key"}>
                  Created on:
                </div>
                <div className={"manage-line__val"}>
                  {moment(group.createdAt).format("ll")}
                </div>
              </div>

              {/* Latest activity */}
              <div className={"manage-line"}>
                <div className={"manage-line__key"}>
                  Last activity:
                </div>
                <div className={"manage-line__val"}>
                  {moment(latestActivity).format("ll")}
                </div>
              </div>

              {/* Administrators */}
              <div className={"manage-line"}>
                <div className={"manage-line__key"}>
                  Group administrators:
                </div>
                <div className={"manage-line__val"}>
                  {
                    group?.members?.filter(({ role }) => role === "ADMIN").map(({ user }) => {
                      return (
                        <div key={user.email}>
                          {user.email}
                        </div>
                      )
                    })
                  }
                </div>
              </div>

              {/* Startups */}
              <div className={"manage-line"}>
                <div className={"manage-line__key"}>
                  Startups:
                </div>
                <div className={"manage-line__val"}>
                  {group.startups?.length || "0"}
                </div>
              </div>

              {/* Members */}
              <div className={"manage-line"}>
                <div className={"manage-line__key"}>
                  Members:
                </div>
                <div className={"manage-line__val"}>
                  {group.members?.length || "0"}
                </div>
              </div>

              {/* Unread comments */}
              <div className={"manage-line"}>
                <div className={"manage-line__key"}>
                  Unread comments:
                </div>
                <div className={"manage-line__val"}>
                  {counters.unreadLog}
                </div>
              </div>

            </div>


            {/* ADMIN ONLY */}
            {
              adminView && (
                <div>

                  {/* Evaluations */}
                  <div className={"manage-line"}>
                    <div className={"manage-line__key"}>
                      Shared evaluations:
                    </div>
                    <div className={"manage-line__val"}>
                      {counters.evaluations}
                    </div>
                  </div>

                  {/* Subjective scores */}
                  <div className={"manage-line"}>
                    <div className={"manage-line__key"}>
                      Shared subjective scores:
                    </div>
                    <div className={"manage-line__val"}>
                      {counters.subjectiveScores}
                    </div>
                  </div>

                </div>
              )
            }

          </div>


          {/* PUBLIC / ADMIN STUFF */}

          {
            (adminView && group?.settings?.isPublic) && (
              <div className={"manage-list__section"}>

                {/* Public members */}
                <div className={"manage-line"}>
                  <div className={"manage-line__key"}>
                    Public members:
                  </div>
                  <div className={"manage-line__val"}>
                    {group.publicUsers?.length || "0"}
                  </div>
                </div>


                {/* Public evaluations */}
                {
                  (
                    group?.publicSettings?.evaluationTemplate &&
                    group?.publicSettings?.withEvaluationTemplate
                  ) && (
                    <div className={"manage-line"}>
                  <div className={"manage-line__key"}>
                    Public evaluations:
                  </div>
                  <div className={"manage-line__val"}>
                    {counters.publicEvaluations}
                  </div>
                </div>
                  )
                }

                {/* Public subjective scores */}
                {
                  group?.publicSettings?.withSubjectiveScores && (
                    <div className={"manage-line"}>
                      <div className={"manage-line__key"}>
                        Public subjective scores:
                      </div>
                      <div className={"manage-line__val"}>
                        {counters.publicSubjectiveScores}
                      </div>
                    </div>
                  )
                }

              </div>
            )
          }

        </div>



        {
          adminView && (
            <div className="manage-resources__actions">
              <NotataButton
                datatut="manage-startups"
                bgcolor="primary_color large1"
                text={
                  <CustomButtonContent
                    iconClass={"far fa-building"}
                    label={"Startups"}
                    count={group.startups?.length || "0"}
                  />
                }
                onClick={() =>
                  adminView && setManageStartupModal(true)
                }
              />
              <NotataButton
                datatut="invite-members"
                bgcolor="primary_color large1"
                text={
                  <CustomButtonContent
                    iconClass={"far fa-users"}
                    label={"Members"}
                    count={group.members?.length || "0"}
                  />
                }
                onClick={() =>
                  adminView && setInviteMembersModal(true)
                }
              />

              {
                (adminView && group?.settings?.isPublic) && (
                  <NotataButton
                    datatut="public-members"
                    bgcolor="primary_color large1"
                    text={
                      <CustomButtonContent
                        iconClass={"far fa-users"}
                        label={"Public Members"}
                        count={group.publicUsers?.length || "0"}
                      />
                    }
                    onClick={() => setManagePublicMemberModal(true)}
                  />
                )
              }
            </div>

          )
        }

      </Card>


      {/* MODALS */}

      {/* ... manage startups modal */}
      {manageStartupsModal && (
        <ManageStartupsModal
          group={group}
          close={() => setManageStartupModal(false)}
        />
      )}

      {/* ... manage evaluation templates modal */}
      {manageTemplateModal && (
        <ManageTemplatesModal
          group={group}
          close={() => setManageTemplateModal(false)}
        />
      )}

      {/* ... members templates modal */}
      {
        inviteMembersModal && (
          <ManageMembersModal
            group={group}
            close={() => setInviteMembersModal(false)}
          />
        )
      }

      {/* ... public members templates modal */}
      {
        managePublicMemberModal && (
          <ManagePublicMembersModal
            group={group}
            close={() => setManagePublicMemberModal(false)}
          />
        )
      }


    </>
  );
}
