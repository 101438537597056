import React, { useState, useEffect } from "react";

// API
import { useQuery } from "@apollo/client";
import { evaluationTemplatesGet, tagGroupsGet, funnelGroupGet } from "private/Apollo/Queries";

// COMPONENTS
import { Kanban } from "./Kanban/Kanban";
import NavigationBar from "./Navigation/NavigationBar"
import { defaultColumnValues, defaultFilters } from "./constants";

import ColumnSidebar from "./Sidebars/ColumnsSidebar/ColumnSidebar";
import FilterSideBar from "./Sidebars/FilterSidebar/FilterSidebar";
import Spreadsheet from "./Spreadsheet/Spreadsheet";
import Paginator from "./Paginator/Paginator";
import { omit } from "lodash";
import { userGet } from "../../Apollo/Queries";


export default function MyStartups() {

  // Constants
  const DEFAULT_TAB_VALUE = "spreadsheet";

  // STATES
  const [filters, setFilterState] = useState(defaultFilters);
  const [viewSidebar, setViewSidebar] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(undefined);
  const [tabValue, setTabValue] = useState(DEFAULT_TAB_VALUE);
  const [selectedFunnelGroup, setSelectedFunnelGroup] = useState(0);
  const [columnValues, setColumnValues] = useState(defaultColumnValues)

  // QUERIES
  const funnelGroupQuery = useQuery(funnelGroupGet);
  const evaluationTemplatesQuery = useQuery(evaluationTemplatesGet);
  const tagGroupsQuery = useQuery(tagGroupsGet);
  const userQuery = useQuery(userGet)

  // MAPS & REDUCERS
  const tagGroups = tagGroupsQuery?.data?.tagGroupsGet || [];
  const funnelGroups = funnelGroupQuery?.data?.accountGet?.funnelGroups || [];
  const evaluationTemplates = evaluationTemplatesQuery?.data?.accountGet?.evaluationTemplates || [];
  const user = userQuery?.data?.userGet || {};

  // EFFECTS
  useEffect(() => {
    let columnSettings = {};
    // If column settings is stored on user
    if (user.columnSettings) {
      let cs = user.columnSettings[0] || {};
      columnSettings = {
        ...omit(cs, "__typename"),
        evaluationTemplates: cs.evaluationTemplates || [],
        tagGroups: cs.tagGroups || [],
      }
    }
    // If user does not have column settings
    if (!user.columnSettings) {
      columnSettings = {
        evaluationTemplates: evaluationTemplates.map(({id}) => id) || [],
        // tagGroups: tagGroups.map(({id}) => id) || []
      }
    }
    // Set local state
    setColumnValues({
      ...columnValues,
      ...columnSettings
    });

  }, [user]);

  // Load filters from local store
  useEffect(() => {
    let f;
    try {
      f = JSON.parse(localStorage.getItem("filters"));
    } catch (error) { /* do nothing */}
    if (f) {
      setFilterState(f);
    }
    let p;
    try {
      p = JSON.parse(localStorage.getItem("paginator"))
    } catch (error) { /* do nothing */}
    setCurrentPage(p)
  }, []);

  // Close sidebars if we're in kanban mode
  useEffect(() => {
    if (tabValue === "kanban" && viewSidebar) {
      setViewSidebar(undefined)
    }
  }, [tabValue])


  // Function
  // Setting filters: save to local store
  function setFilters(filterData) {
    localStorage.setItem("filters", JSON.stringify(filterData));
    setFilterState(filterData);
    let p;
    try {
      p = JSON.parse(localStorage.getItem("paginator"))
      p.pageNo = 1;
      p.LastEvaluatedId = undefined;
      localStorage.setItem("paginator", JSON.stringify(p))
      setCurrentPage(p)
    } catch (error) { }
  }



  return (
    <div className="startUpPageContainter">

      <NavigationBar
        funnelGroups={funnelGroups}
        filters={filters}
        setFilters={setFilters}
        tabValue={tabValue}
        setTabValue={setTabValue}
        selectedFunnelGroup={selectedFunnelGroup}
        setSelectedFunnelGroup={setSelectedFunnelGroup}
        viewSidebar={viewSidebar}
        setViewSidebar={setViewSidebar}
      />

      {tabValue === "spreadsheet" && (
        <>
          <Spreadsheet
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filters={filters}
            setFilters={setFilters}
            evaluationTemplates={evaluationTemplates}
            columnValues={columnValues}
          />

          <Paginator
            filters={filters}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>

      )}

      {tabValue === "kanban" && (
        <Kanban
          selectedfunnelGroup={selectedFunnelGroup}
        />
      )}

      {viewSidebar === "filter" && (
        <FilterSideBar
          close={setViewSidebar}
          filters={filters}
          setFilters={setFilters}
        />
      )}

      {viewSidebar === "column" && (
        <ColumnSidebar
          tagGroups={tagGroups}
          close={setViewSidebar}
          evaluationTemplates={evaluationTemplates}
          columnValues={columnValues}
          setColumnValues={setColumnValues}
        />
      )}

    </div>

  );
}
