import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";
import { checkAppSumoCode } from "../../../../public/Apollo/Queries";

// UI COMPONENT
import { InputForm, NotataButton } from "Components/NotataComponents";

// STYLES
import "../LoginPages.scss";

// ASSETS
import man_standing from "assets/images/man_standing.svg";

// CUSTOM COMPONENTS
import { userLoggedIn } from "actions/user";
import { getUserIsLoggedIn } from "reducers/selectors/user";

// DEFINITIONS
import { payment_page } from "definitions.js";

// OTHERS
import { Message } from "../Message/Message";


function SignupComp({ history, location, match, userIsLoggedIn }) {

  // STATES
  const [errorMessage, setErrorMessage] = useState();
  const [codeSuccess, setCodeSuccess] = useState(false);

  const queryOptions = { context: { clientName: "public" } };
  const [ checkCode, { error, data, loading } ] = useLazyQuery(checkAppSumoCode, queryOptions)

  // FORMS
  const { register, handleSubmit, setValue, getValues } = useForm();


  let search = useLocation().search;
  useEffect(() => {
    let code = new URLSearchParams(search).get('code');
    if (code) {
      setValue('code', code)
    }
  }, [search])

  useEffect(() => {
    if (data?.checkAppSumoCode) {
      setCodeSuccess(true);
      window.localStorage.setItem("app_sumo_code", getValues('code'))
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setErrorMessage("Code not valid");
    }
  }, [error])



  // FUNCTIONS

  // onsubmit function
  function onSubmit({ code }) {
    checkCode({variables: { code }})
  }

  if (codeSuccess) {
    return (
      <Message
        heading={"SUCCESS! 🚀"}
        subHead1={
          "Awesome! The code has been assigned to you. All you have to do now is to sign up and choose plan!"
        }
        image={man_standing}
        history={history}
        path={payment_page}
        buttonText={"Sign me up!"}
      />
    )
  }


  return (
    <>
      <div className='auth_structure'>

        <div className="auth_structure__left">
          <div className='auth_structure__left__mainContent'>
            <form onSubmit={handleSubmit(onSubmit)}>

              {/* Input fields  */}
              <InputForm
                label="Redeem code"
                type="text"
                name="code"
                placeholder="CODE"
                required={true}
                errorMessage={errorMessage}
                reference={register({required:true})}
              />
              <NotataButton
                size="xx_large"
                bgcolor="primary_color"
                text="REDEEM"
                buttonType="submit"
                loading={loading}
              />
            </form>

          </div>

        </div>

        <div className='auth_structure__right'>
          <img src={man_standing} alt="auth_image" />
        </div>
      </div>
    </>
  );
}

// main function
export const AppSumoSignUp = connect(
  state => ({
    userIsLoggedIn: getUserIsLoggedIn(state),
  }),
  {
    userLoggedIn,
  }
)(SignupComp);