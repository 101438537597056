import React from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { groupDelete } from "private/Apollo/Mutations";
import { groupsGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { Modal } from "Components/NotataComponents";

// STYLES
import "./DeleteGroupModal.scss"

// MAIN FUNCTION
export default function DeleteGroupModal({ group, close, submitCallBack }) {
  
  // MUTATIONS
  const [deleteGroup, { loading }] = useMutation(groupDelete, {
    refetchQueries: [{ query: groupsGetV2 }],
    awaitRefetchQueries: true,
  });

  // SAVE FUNCTION
  async function save() {
    if (loading) return;
    try {
      await deleteGroup({ variables: { id: group.id } });
      if(submitCallBack){
        submitCallBack();
      }
    } catch (error) {
      console.log("error", error);
    }
    close();
  }

  return (
    <Modal
      title="Delete group"
      loading={loading}
      submit={save}
      close={close}
      submitTxt="Delete"
      closeTxt="Cancel"
      submitButtonStyle="secondary"
      children={
        <div className="delete-group-modal-container">
          <div className="description">
            Are you sure you want to delete this group permanently?
          </div>
          <div className="remember">Remember:</div>
          <div className="options">
            - Startups will be removed from group <br />
            - Comments will be deleted <br />
            - Evaluations will be removed from group <br />
          </div>
        </div>
      }
    />
  );
}