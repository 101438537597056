import React, { useState } from 'react'

// UI COMPONENT
import { InputForm, Modal } from "Components/NotataComponents";

// Others
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import {urlPattern} from '../../../../../../utils/regex'


export default function AddExternalResourceModal({
    setLink, 
    setLabel,
    addExternalResource,
    setAddNewResourceModal,
    addExternalResourceRes,
}) {
 
    // Use Form
   const { register, handleSubmit } = useForm({
    resolver: yupResolver(
        yup.object().shape({
        linkname: yup.string().required().matches(urlPattern),
        labelname:yup.string().required()
        })
    )
    });

    
    return (
            <form onSubmit={handleSubmit(addExternalResource)}>
                <Modal
                    title="Add new resource"
                    loading={addExternalResourceRes.loading}
                    close={() => { setAddNewResourceModal(false) }}
                    submit={()=>{}}
                    submitTxt="save"
                    closeTxt="cancel"
                    buttonType="submit"
                    children={
                            <div className="add-external-resource-container">
                                {/* Link input field */}
                                <InputForm
                                    label={`Link`}
                                    type="url"
                                    fullWidth={true}
                                    required
                                    name={"linkname"}
                                    placeholder="http://..."
                                    handleInputChange={(value) => {
                                        setLink(value)
                                    }}
                                    reference={register({ required: true })}
                                />
                                {/* Label input field */}
                                <InputForm
                                    label={`Label`}
                                    type="text"
                                    fullWidth={true}
                                    required={true}
                                    name={"labelname"}
                                    placeholder="DD Room"
                                    handleInputChange={value => setLabel(value)}
                                    reference={register({ required: true })}
                                />
                            </div>
                        }
                />
            </form>
       )
}
