import React, { useState, useEffect } from "react";

// API
import { useMutation, useQuery } from "@apollo/client";
import { groupStartupsAdd } from "../../../Apollo/Mutations";
import { groupsGetV2 } from "private/Apollo/Queries";

// COMPONENT
import {
  Modal,
  Dropdown,
  Loader,
  ModalErrorMessage,
} from "Components/NotataComponents";

export default function AddToGroupModal({ close, connections, refetch }) {
  // STATES
  const [selectedGroup, setSelectedGroup] = useState(false);
  const [groupHasPermission, setGroupHasPermission] = useState(true);

  // QUERIES
  //eslint-disable-next-line
  const { data, loading, error } = useQuery(groupsGetV2);

  // MUTATION
  const [addToGroupMutation, mRes] = useMutation(groupStartupsAdd);

  // DATA MAPS
  let groups = data?.groupsGetV2 || [];
  // groups = groups.filter(({ iAmAdmin }) => iAmAdmin);

  const findCompanyIsPartOfGroup = (connection, groupId) => {
    return connection?.groupSharingInfo.some(
      info => info?.group?.id === groupId
    );
  };
  // Remove groups we are already part of
  const filteredGroups = groups.filter(({ id }) => {
    const isMatch = connections.filter(con =>
      con.groupSharingInfo.some(info => info.group.id === id)
    );
    return isMatch.length ? false : true;
  });

  const handlePermission = selectedGroup => {
    selectedGroup = selectedGroup.filter(
      ({ iAmAdmin, settings }) => iAmAdmin || settings.addStartup
    );
    selectedGroup ? setGroupHasPermission(true) : setGroupHasPermission(false);
  };

  useEffect(() => {
    if (selectedGroup) {
      handlePermission([selectedGroup]);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (filteredGroups && filteredGroups.length === 1) {
      setSelectedGroup(filteredGroups[0]);
    }
  }, [filteredGroups]);

  async function addToGroup() {
    //filter connections who don't belongs to the selected group
    let filteredConnections = connections.filter(
      connection => !findCompanyIsPartOfGroup(connection, selectedGroup.id)
    );

    //collect creative ids
    const creativeIds = filteredConnections.map(({ creative }) => creative.id);
    if (creativeIds.length === 0) {
      setSelectedGroup(false);
      close();
      return;
    }

    if (mRes.loading) return;
    if (!selectedGroup) return;
    if (!selectedGroup?.settings?.addStartup && !selectedGroup?.iAmAdmin) {
      setGroupHasPermission(false);
      return;
    }
    try {
      let variables = {
        groupId: selectedGroup.id,
        creativeIds,
      };
      await addToGroupMutation({ variables });
      refetch();
    } catch (error) {
      return console.log(error);
    }
    setSelectedGroup(false);
    close();
  }

  const noGroups = !groups.length && !loading;
  const noFilteredGroups = !filteredGroups.length && !loading;

  return (
    <Modal
      title={`Add ${connections.length} startups to group`}
      loading={mRes.loading}
      submit={addToGroup}
      close={close}
      submitTxt={filteredGroups.length > 0 ? "Add" : "CREATE NEW GROUP"}
      submitButtonSize={filteredGroups.length > 0 ? "small" : "big"}
      closeTxt="Cancel"
      children={
        <>
          {noGroups && <div>You are in no groups</div>}

          {noFilteredGroups && !noGroups && (
            <div>
              This startup is already in all available groups. Click "create new
              group" button to create a new group.
            </div>
          )}
          {!noGroups && !noFilteredGroups && connections?.length === 0 && (
            <div className="delete-group-modal-container">
              <div className="description">
                Are you sure you want to add {connections?.length} startups?
              </div>
            </div>
          )}
          {!noGroups && !noFilteredGroups && connections?.length > 0 && (
            <>
              <div style={{ marginBottom: "10px" }}>
                {!data && loading && <Loader />}
                <h2 className="add_group">Groups</h2>
              </div>

              <div className={"groupDropContainer"}>
                <Dropdown
                  title=""
                  items={groups}
                  setSelectedItem={setSelectedGroup}
                />
              </div>
            </>
          )}
          {!groupHasPermission && (
            <ModalErrorMessage msg="You don't have the right privileges to add startups to this group." />
          )}
        </>
      }
    ></Modal>
  );
}
