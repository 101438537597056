import React from "react";
import { Link } from "react-router-dom";

// API STUFF
import { useQuery } from "@apollo/client";
import { groupGetRecentlyAddedStartups } from "private/Apollo/Queries";

// CUSTOM COMPONENTS
import { Loader, CardComponent } from "Components/NotataComponents";

// DEFINITIONS
import { group_dashboard } from "definitions";

// MAIN FUNCTION
export default function DashboardRecentlyAddedStartups() {
  const { data, loading } = useQuery(groupGetRecentlyAddedStartups);
  let list = data?.groupGetRecentlyAddedStartups || [];
  return (
    <CardComponent
      containerClassName="dashboard-container__recently-added-card"
      padding="small"
      data-tut="recently-added-startup"
      title="Recent group activities"
      titleSize="small"
      titleColor="white"
      content={
        <>
          {!data && loading && <Loader />}
          {!list.length && !(!data && loading) && (
            <div className="empty-wrapper">
              <div className="demo-msg">
                Here you will see the latest group activities
              </div>
            </div>
          )}
          <div className="dashboard-container__recently-added-card__data-container">
            {list.slice(0, 3).map(({ group, creative }) => (
              <div
                className="dashboard-container__recently-added-card__data-container__data-entry"
                key={`${group.id}-${creative.id}`}
              >
                <div className="startup-name">{creative.name}</div>

                <Link
                  to={`${group_dashboard}/${group.id}`}
                  className="link_underline__recentlyAdd"
                >
                  <div className="group-name">{group.name}</div>
                </Link>
              </div>
            ))}
          </div>
        </>
      }
    />
  );
}
