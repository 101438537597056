import React, { useState } from "react";

// API
import { useMutation, useQuery } from "@apollo/client";
import { tagGroupsGet } from "private/Apollo/Queries";
import { connectionTagAdd } from "private/Apollo/Mutations";

import { Modal, Dropdown, Loader } from "Components/NotataComponents";

export default function AddTagsModal({ close, ids }) {
  // STATES
  const [isLoading, setLoading] = useState(false);
  const [selectedTagGroup, setSelectedTagGroup] = useState(false);
  const [selectedTag, setSelectedTag] = useState(false);

  // MUTATION
  const [addTagMutation] = useMutation(connectionTagAdd);

  // QUERIE
  const { data, loading } = useQuery(tagGroupsGet);

  // Data maps
  let tagGroups = data?.tagGroupsGet || [];

  async function addTag() {
    if (isLoading) return;
    if(ids?.length === 0){
      close();
      return
    }
    if (!selectedTag) return;
    setLoading(true);

    if (!selectedTag) {
      return;
    }

    let promises = ids.map(connectionId => {
      let variables = {
        tagId: selectedTag.id,
        connectionId,
      };
      return addTagMutation({ variables });
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.log("error", error);
    }

    setLoading(false);
    setSelectedTagGroup(false);
    close();
  }

  return (
    <Modal
      title="Add tags"
      loading={isLoading}
      submit={addTag}
      close={close}
      submitTxt="Add tag"
      closeTxt="Cancel"
      children={
      <>
      {ids?.length === 0 ?
            <div className="delete-group-modal-container">
            <div className="description">
              Are you sure you want to add {ids?.length} startups?
            </div>
          </div> :
        // delete-group-modal-container
        <div className="">
          {!data && loading && <Loader />}

          <div className="row">
            <div className="col-sm-12 col-xs-12">
            {/* drop-down-heading */}
              <div className="add_group">Tag groups</div>
              <Dropdown
                title=""
                items={tagGroups}
                setSelectedItem={setSelectedTagGroup}
              />
            </div>
          </div>

          {selectedTagGroup && (
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-sm-12 col-xs-12">
              {/* drop-down-heading */}
                <div className="add_group">Tags</div>
                <Dropdown
                  title=""
                  items={selectedTagGroup.tags || []}
                  setSelectedItem={setSelectedTag}
                />
              </div>
            </div>
          )}
        </div>}
      </>
      }
    />
  );
}
