import React from "react";

// Library Components
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Styles
import "./InputCheckBox.scss";

// Main Function
export const InputCheckBox = ({
  checked,
  onChange,
  label,
  name,
  value,
  defaultChecked,
  register,
  ...props
}) =>
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          defaultChecked={defaultChecked}
          value={value}
          name={name}
          onChange={onChange}
          register={register}
        />
      }
      label={label}
      {...props}
    />
