import React from "react";

//Styles
import "./BreadCrumbs.scss"

// Main Function
export const BreadCrumbs = ({ routePage, pages, onClick, history }) =>
    <div className="header-routing">
      <span className="header-routing__container">
        <span className="header-routing__highlight" onClick={onClick}>
          {routePage}</span>
        {(pages || []).map((item, index) =>
          <span className="header-routing__span" key={index}>
            <i className="fal fa-chevron-right header-routing__stilt"></i>
            <span className="header-routing__name" onClick={() => history.push(item.route)}>
              {item.page1}
            </span>
          </span>
        )}
      </span>

    </div>
