import React, { useState } from "react";

// API STUFF
import { useQuery, useMutation } from "@apollo/client";
import { creativeTemplateGet } from "private/Apollo/Queries";
import { creativeUpdate } from "private/Apollo/Mutations";

// GENERAL COMPONENTS
import { GhostLoader } from "Components/NotataComponents";

// CUSTOM COMPONENTS
import InviteStartupModal from "../StartupInfoModal/InviteStartupModal";
import EditStartupContent from "./EditStartupContent";

// STYLES
import "./EditStartupInfo.scss";

export default function EditStartupInfo({ backToInfoPage, connection, history }) {
  // STATES
  const [inviteStartUpModal, setInviteStartUpModal] = useState(false);

  // QUERIES
  const { data, loading } = useQuery(creativeTemplateGet);

  // MUTATIONS
  const [mutateCreativeUpdate, mutateCreativeUpdateRes] = useMutation(
    creativeUpdate
  );

  // MAPS AND REDUCERS
  let creativeTemplate = data?.creativeTemplateGet;

  // Loader
  if (!data && loading) {
    return <GhostLoader />;
  }

  return (
    <>
      <div className="tab-panel-container">
        <div className="">

          <EditStartupContent
            template={creativeTemplate}
            creative={connection?.creative}
            connectionId={connection?.id}
            backToInfoPage={backToInfoPage}
            mutateCreativeUpdateRes={mutateCreativeUpdateRes}
            saveCreative={async input => {
              try {
                let variables = {
                  id: connection?.creative?.id,
                  input,
                };
                await mutateCreativeUpdate({ variables });
              } catch (error) {
                console.log("error", error);
              }
            }}
            history={history}
            />
        </div>
        
      </div>

      {inviteStartUpModal && (
        <InviteStartupModal
          connection={connection}
          close={() => {
            setInviteStartUpModal(false);
          }}
        />
      )}
    </>
  );
}
