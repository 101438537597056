import React from "react";

export default function TextInput({
  rows,
  style,
  disabled,
  defaultValue,
  onChange,
  onBlur,
}) {
  return (
    <div className="textbox">
      <textarea
        placeholder="Write your answer..."
        rows={rows || 7}
        style={style}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
}
