import React from "react";
import { Button } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import "./NotataButton.scss";

export function NotataButton({
  text,
  bgcolor,
  color,
  size,
  buttonType,
  loading,
  buttonStyle,
  startIcon,
  endIcon,
  iconElement,
  iconElementStyles,
  onClick,
  ReSize,
  disabled,
  onKeyDown,
  datatut,
  style
}) {

  const icon = () => {
    const iconData = {};

    if (startIcon && !loading) {
      iconData.startIcon = <Icon>{startIcon}</Icon>;
    }
    if (iconElement && !loading) {
      iconData.startIcon = (
        <span className={iconElementStyles}>{iconElement}</span>
      );
    }
    if (endIcon && !loading) {
      iconData.endIcon = <Icon>{endIcon}</Icon>;
    }
    return iconData;
  };

  return (
    <Button
      type={buttonType}
      {...icon()}
      className={`NotataButton_container ${color} ${size} ${buttonStyle} ${bgcolor} ${ReSize}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      disabled={disabled}
      data-tut={datatut}
      style={style}
    >
      {loading ? <span className="fa fa-spinner fa-spin" /> : <div>{text}</div>}
    </Button>
  );
}