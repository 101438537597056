import React, { useState, useRef, useEffect } from "react";

// Library Components
import moment from "moment";
import { Loader, TextBox } from "Components/NotataComponents";

// STYLES
import "./CommentBox.scss";

// ASSETS
import chatIcon from "assets/images/chatIcon.svg";

// Main Function
export default function CommentBox({
  comments,
  deleteComment,
  createComment,
  updateComment,
  loading,
  inputTxt,
  createLoader,
  coomentBox,
  editLoader
}) {
  // REFS
  const commentsRef = useRef(null);
  const textareaRef = useRef(null);
  // States
  const [updateMessage, setUpdateMessage] = useState(null);
  const [editChat, setEditChat] = useState(false);
  const [hidden, setHidden] = useState({});
  const [message, setMessage] = useState("");
  const [editingComment, setEditingComment] = useState(undefined);
  const [deletingComment, setDeletingComment] = useState(undefined);

  useEffect(() => {
    // Scrolls the list to bottom
    if(commentsRef.current) {
      commentsRef.current.scroll({ top: commentsRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [comments])

  // Functions  
  const handleMessageChange = e => {
    const { value } = e.target;
    setMessage(value);
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = (textareaRef.current.scrollHeight)+"px";
  };

  const handleUpdateMessageChange = e => {
    const { value } = e.target;
    setUpdateMessage(value);
  };

  const setEditComment = async index => {
    setHidden({ [index]: !hidden[index] });
    setEditChat(true);
  };

  const handleEnter = async event => {
    if (event.keyCode === 13) {
      if (!event.shiftKey) {
        event.preventDefault();
        await createComment(message, commentsRef.current);
        setMessage("");
        textareaRef.current.style.height = "auto"
        textareaRef.current.style.height = "40px";
        setEditChat(false);
        setHidden({ });
        setEditingComment(null);
        return false;
      }
    }
  };
  const focusCommentInput = () =>{
    if(comments === null || comments?.length == 0){
      textareaRef.current.focus();
    }
  }
  return (
    <div className={"comment-box__commentBox"} onClick={focusCommentInput}>
      <div id="your_div" ref={commentsRef} className={`${"comments"} ${coomentBox}`}>
        {comments?.map((comment, index) => {
          if(!comment?.dataPairs[0]?.val){
            return;
          }
          let isMe = !!comment?.createdByUser?.isMe;
          return (  
            // Comments
            <div className={"comment"} key={`comment-key-${index}`}>
              <div className={"commentHead"}>
                {editLoader && editingComment === comment?.id || deletingComment === comment?.id ? <i className="fa fa-spinner fa-spin spinner" /> : <img src={chatIcon} alt="" />}
                <div className={"commentUserName"}>
                  {isMe
                    ? "You"
                    : `${comment?.createdByUser?.given_name} ${comment?.createdByUser?.family_name}`}
                </div>
                {isMe ? (
                  <div className={"commentHeadEditDeleteIcon"}>
                    <i
                      onClick={() => setEditComment(index)}
                      className={"fas fa-pen " + "editIcon"}
                    />
                    <i
                      onClick={() => {
                        setDeletingComment(comment.id)
                        deleteComment(comment.id, index);
                        setEditChat(false);
                        setHidden({ });
                        setEditingComment(null);
                      }}
                      className={"fal fa-trash-alt deleteIcon"}
                    />
                  </div>
                ) : null}
              </div>

              <div>
                <div className={"commentText"}>
                  {!hidden[index]
                    ? comment?.dataPairs?.length > 0 &&
                    comment?.dataPairs[0].val
                    : ""}
                </div>
                {hidden[index] && editChat ? (
                  <div className={"editBox"}>
                    <div>
                      {/* Edit Comment box Text Field */}
                      <TextBox
                        inputval={
                          comment?.dataPairs?.length > 0 &&
                          comment?.dataPairs[0].val
                        }
                        onChange={handleUpdateMessageChange}
                      />
                    </div>
                    <div className={"editBoxButtons"}>
                      {/* Cancel Button */}
                      <button
                        className={"editCancel"}
                        onClick={() => setEditComment(index)}
                      >
                        Cancel
                      </button>
                      {/* Save button */}
                      <button
                        className={"editSave"}
                        onClick={() => {
                          if(updateMessage){
                            updateComment(comment.id, updateMessage, index);
                            setMessage("");
                            setEditComment(index);
                            setEditingComment(comment.id);
                            return;
                          }
                          setMessage("");
                          setEditComment(index);
                          setEditingComment(comment.id);
                          setEditChat(false);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {!hidden[index] ? (
                <div className={"commentTime"}>
                  {moment(comment.createdAt).format("lll")}{" "}
                  {comment.createdAt !== comment.updatedAt && (
                    <span>(edited)</span>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
        {loading && <Loader />}
      </div>
      <div className={`${"comment-box__commentInput"} ${inputTxt}`}>
        {/* Comment box Text field */}
        <textarea
          name="message"
          placeholder="Say something"
          className="text-box"
          rows="1"
          cols="50"
          name="comment"
          className={!message ? "placeHolderOpacity" : "placeHolder"}
          onChange={handleMessageChange}
          onKeyDown={e => handleEnter(e)}
          ref={textareaRef}
          value={message}
        />
        <button
          type="button"
          onClick={async () => {
            await createComment(message);
            setMessage("");
            setEditingComment(undefined);
          }}
        >
          {createLoader ? <i className={"fa fa-spinner fa-spin"} /> : <i className="fa fa-paper-plane" />}
        </button>
      </div>
    </div>
  );
}
