import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

// API STUFF
import { useQuery } from "@apollo/client";
import { accountGet } from "private/Apollo/Queries";

// UI COMPONENTS
import {
  BrowseDropDown,
  GhostLoader,
  BreadCrumbs,
  NotataButton,
  CardComponent,
} from "Components/NotataComponents";

// MODALS
import CreateNewTemplateModal from "./EvaluationTemplatePageModals/CreateNewTemplateModal";
import DeleteTemplateModal from "./EvaluationTemplatePageModals/DeleteTemplateModal";
import EditTemplateModal from "./EvaluationTemplatePageModals/EditTemplateModal";
import CloneTemplateModal from "./EvaluationTemplatePageModals/CloneTemplateModal";

// DEFINITIONS
import { evaluation_template_page, settings } from "definitions";

// STYLES
import "./EvaluationTemplatesPage.scss";

function TemplateModal({
  setEditModalForTemplate,
  setCloneModalForTemplate,
  setDeleteModalForTemplate,
  template,
  open,
  setOpen,
}) {
  return (
    <div className="evaluation-templates-container__data-container__data__browse__drop-dwon">
      <BrowseDropDown
        open={open}
        setOpen={setOpen}
        items={[
          {
            text: "Edit",
            icon: <i className="fal fa-edit"/>,
            leave: false,
            callBack: () => setEditModalForTemplate(template),
          },
          {
            text: "CLONE",
            icon: <i class="far fa-clone"></i>,
            leave: false,
            callBack: () => setCloneModalForTemplate(template),
          },
          {
            text: "Delete",
            icon: <i className="fa fa-trash"/>,
            leave: true,
            callBack: () => setDeleteModalForTemplate(template),
          },
        ]}
      />
    </div>
  );
}

//Main FUNCTION
export const EvaluationTemplatesPage = ({ history }) => {
  // Constant
  const back = useHistory();
  const popup = useRef();

  // States
  const [viewDropdown, setViewDropdown] = useState(null);
  const [createNewModal, setCreateNewModal] = useState(false);

  const [deleteModalForTemplate, setDeleteModalForTemplate] = useState(
    undefined
  );
  const [editModalForTemplate, setEditModalForTemplate] = useState(undefined);
  const [cloneModalForTemplate, setCloneModalForTemplate] = useState(undefined);
  const [open, setOpen] = useState(true);

  // Queries
  const { data, loading } = useQuery(accountGet);

  // Data maps
  let templates = data?.accountGet?.evaluationTemplates;

  if (!data && loading) {
    return <GhostLoader />;
  }

  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[{ seperator: ">", page1: "EVALUATION TEMPLATES" }]}
      />

      <div>
        <CardComponent
          containerClassName="evaluation-templates-container evaluation-templates-container__card"
          padding="large"
          title="Evaluation templates"
          titleSize="medium"
          backLink={() => back.goBack()}
          headerRight={
            <NotataButton
              size="large1"
              bgcolor="primary_color"
              startIcon="add"
              text="Create new template"
              datatut="create-new-template"
              onClick={() => setCreateNewModal(true)}
            />
          }
          content={
            <div className="evaluation-templates-container__data-container">
              {templates?.map((template, index) => (
                <div
                  className="evaluation-templates-container__data-container__data"
                  data-tut={index === 0 ? "template-0" : ""}
                  key={`row-id-${template.id}`}
                >
                  <Link
                    to={`${evaluation_template_page}/${template?.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="template-name">{template.name}</div>
                  </Link>
                  <div className="sections">
                    {template?.sections?.length || 0} sections
                  </div>
                  <div className="sections">
                    {template?.possibleScore || 0} points
                  </div>

                  <div className="evaluation-templates-container__data-container__data__browse">
                    <span
                      className="material-icons"
                      onMouseLeave={() => setViewDropdown(null)}
                      onClick={() => {
                        setViewDropdown(
                          viewDropdown === template.id ? null : template.id
                        );
                      }}
                    >
                      <i class="fal fa-cog" style={{ fontSize: "20px" }}></i>

                      {viewDropdown === template.id && (
                        <div className="evaluation-templates-container__data-container__data__browse__dropdown">
                          <TemplateModal
                            open={viewDropdown}
                            setOpen={() => setViewDropdown(!viewDropdown)}
                            setCloneModalForTemplate={setCloneModalForTemplate}
                            setDeleteModalForTemplate={
                              setDeleteModalForTemplate
                            }
                            setEditModalForTemplate={setEditModalForTemplate}
                            template={template}
                          />
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          }
        />

        {createNewModal && (
          <CreateNewTemplateModal
            history={history}
            close={() => setCreateNewModal(false)}
          />
        )}

        {deleteModalForTemplate && (
          <DeleteTemplateModal
            history={history}
            template={deleteModalForTemplate}
            close={() => setDeleteModalForTemplate(undefined)}
          />
        )}

        {editModalForTemplate && (
          <EditTemplateModal
            history={history}
            template={editModalForTemplate}
            close={() => setEditModalForTemplate(undefined)}
          />
        )}

        {cloneModalForTemplate && (
          <CloneTemplateModal
            history={history}
            template={cloneModalForTemplate}
            close={() => setCloneModalForTemplate(undefined)}
          />
        )}
      </div>
    </>
  );
};
