import React, { useState } from "react";
import { Link } from "react-router-dom";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { groupsGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import {
  NotataButton,
  BreadCrumbs,
  BrowseDropDown,
  GhostLoader,
  CardComponent,
} from "Components/NotataComponents";

// DEFINITION
import { group_dashboard } from "definitions";

// STYLES
import "./GroupsHome.scss";
import "../../public.scss";

// MODALS
import LeaveGroupModal from "../Modals/LeaveGroupModal";
import DeleteGroupModal from "../Modals/DeleteGroupModal";
import GroupSettingsModal from "../Modals/GroupSettingsModal";

import CreateGroup from "Components/Common/Modals/CreateGroup/CreateGroup";
import { NotataList } from "../../../../Components/NotataComponents";

import { CardContent as GroupInvitations } from "../../Dashboard/DashboardComponents/DashboardGroupInvitations"
import CardMessageContainer from "../Components/CardMessageContainer";





// MAIN FUNCTION
export default function Groups({ history }) {

  // STATES
  const [browseDropDownState, setBrowseDropDownState] = useState(undefined);
  const [deleteModal, setDeleteModal] = useState(undefined);
  const [leaveModal, setLeaveModal] = useState(undefined);
  const [settingsModal, setSettingsModal] = useState(undefined);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [open, setOpen] = useState(true);

  // Queries
  let groupsQuery = useQuery(groupsGetV2);

  // MAP AND REDUCERS
  let groups = groupsQuery.data?.groupsGetV2;
  if (!groupsQuery.data && groupsQuery.loading) {
    return <GhostLoader />;
  }

  // GET BROWSE ITEM FUNCTION
  const getBrowseItems = group => {
    if (group.iAmAdmin) {
      return [
        {
          text: "Settings",
          icon: <i className="fal fa-cog"/>,
          callBack: () => setSettingsModal(group),
        },
        {
          style: {color: "red"},
          text: "DELETE GROUP",
          icon: <i className="fa fa-trash"/>,
          callBack: () => setDeleteModal(group),
        },
      ];
    }

    if (!group.iAmAdmin) {
      return [
        {
          text: "LEAVE GROUP",
          icon: <i className="fa fa-sign-out"/>,
          callBack: () => setLeaveModal(group),
        },
      ];
    }
  };

  let tableHeader = [
    {
      className: "groups-container__list__left-icons"
    },
    {
      className: "groups-container__list__group-name"
    },
    {
      className: "groups-container__list__group-members"
    },
    {
      className: "groups-container__list__group-startups"
    },
    {
      className: "groups-container__list__group-settings-icon"
    }

  ];

  let tableBody = groups?.map((group) => [

    { // ICONS
      content: (
        <>
          {group.iAmAdmin && (
            <i className="fas fa-user-crown" />
          )}

          {!group.iAmAdmin && group.settings.isPublic && (
            <i className="fas fa-lock-open-alt" />
          )}

          {!group.iAmAdmin && !group.settings.isPublic && (
            <i className="fas fa-lock-alt" />
          )}
        </>
      )
    },

    { // NAME
      content: (
        <Link to={`${group_dashboard}/${group.id}`}>
          <div>{group.name}</div>
        </Link>
      )
    },

    { // MEMBERS
      content: (
        <div>
          {((group.iAmAdmin || group.settings.showUsers) && group.memberCount) &&
            <span>{group.memberCount} member{group.memberCount !== 0 && "s"}</span>
          }
        </div>
      )
    },

    { // STARTUPS
      content: (
        <div>
          {group.startupCount} startup{group.startupCount !== 0 && "s"}
        </div>
      )
    },

    { // SETTINGS
      content: (
        <div
          onMouseLeave={() =>
            setBrowseDropDownState(false)
          }
        >
          <span
            onClick={() => {
              browseDropDownState === group.id
                ? setBrowseDropDownState(undefined)
                : setBrowseDropDownState(group.id);
            }}
          >
            <i
              className="fal fa-cog"
              style={{ cursor: "pointer"}}
            />
            {browseDropDownState === group.id && (
              <div className="data__browse__dropdown">
                <BrowseDropDown
                  open={open}
                  setOpen={() => setBrowseDropDownState(group.id)}
                  items={getBrowseItems(group)}
                />
              </div>
            )}
          </span>
        </div>
      )
    }

  ])

  return (
    <>
      {/* BREADCRUMBS */}
      <BreadCrumbs routePage={`Groups`} />

      <div className="groups-container">

        <CardComponent
          title="Groups"
          titleSize="large"
          padding="large"
          headerStyle={{flexWrap: "wrap"}}
          headerRight={
            <NotataButton
              size="large"
              bgcolor="primary_color"
              startIcon="add"
              datatut="create-new-group"
              text="CREATE NEW GROUP"
              onClick={() => setCreateGroupModal(true)}
            />
          }
          content={
            <>


              {/*<CardMessageContainer*/}
              {/*  title="Join some groups 🎉"*/}
              {/*  notice*/}
              {/*>*/}
              <div className="group-page-notifications">
                <GroupInvitations/>
              </div>

              {/*</CardMessageContainer>*/}


              {groups?.length === 0 && (
                <div className="row groups-container__no-group">
                  <div className="col-sm-12 col-xs-12">
                    <div className="groups-container__no-group__header">
                      You are not member of any groups.
                    </div>
                    <div className="groups-container__no-group__body">
                      In groups you can share startups and evaluations with
                      anyone you like. It's completely safe, and you can choose
                      how much you want to share.
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="create-group-container">
                <NotataButton
                  size="large"
                  bgcolor="primary_color"
                  startIcon="add"
                  datatut="create-new-group"
                  text="CREATE NEW GROUP"
                  onClick={() => setCreateGroupModal(true)}
                />
              </div> */}

              <div className="groups-container__list">
                <NotataList
                  header={tableHeader}
                  body={tableBody}
                />
              </div>

            </>
          }
        />
        {/* MODALS */}
        {deleteModal && (
          <DeleteGroupModal
            group={deleteModal}
            close={() => setDeleteModal(undefined)}
          />
        )}

        {leaveModal && (
          <LeaveGroupModal
            group={leaveModal}
            close={() => setLeaveModal(undefined)}
          />
        )}

        {settingsModal && (
          <GroupSettingsModal
            group={settingsModal}
            close={() => setSettingsModal(undefined)}
          />
        )}

        {createGroupModal && (
          <CreateGroup
            close={() => setCreateGroupModal(undefined)}
            history={history}
          />
        )}
      </div>
    </>
  );
}
