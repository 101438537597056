import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

// API STUFF
import { useMutation, useLazyQuery } from "@apollo/client";
import { evaluationTemplateGet } from "private/Apollo/Queries";
import { evaluationTemplateUpdate } from "private/Apollo/Mutations";

// UI COMPONENTS
import {
  TextBox,
  NotataButton,
  GhostLoader,
  BreadCrumbs,
  Card,
} from "Components/NotataComponents";

// STYLES
import "../SectionPage.scss";

// CONSTANTS
import { ICONPOSITION } from "../../constants";

// DEFINITIONS
import {
  evaluation_template_page,
  evaluation_templates_page,
  settings,
} from "definitions";

//MODALS
import ImportSectionModal from "./EvaluationTemplatePageModals/ImportSectionModal";
import AddSectionModal from "./EvaluationTemplatePageModals/AddSectionModal";
import DeleteSectionModal from "./EvaluationTemplatePageModals/DeleteSectionModal";
import CloneSectionModal from "./EvaluationTemplatePageModals/CloneSectionModal";
import TemplatePopup from "./EvaluationTemplatePageModals/TemplatePopup";

// MAIN FUNCTION
export const EvaluationTemplatePage = ({ match, history }) => {
  const hist = useHistory();
  const popup = useRef();

  // Constants
  const id = match?.params?.id;

  // States
  const [viewDropdown, setViewDropdown] = useState(true);

  const [importSectionModal, setImportSectionModal] = useState(false);
  const [addSectionModal, setAddSectionModal] = useState(false);

  const [deleteModalForSection, setDeleteModalForSection] = useState(undefined);
  const [cloneModalForSection, setCloneModalForSection] = useState(undefined);

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);

  // Queries
  const [getTemplate, getTemplateRes] = useLazyQuery(evaluationTemplateGet);

  // Mutations
  const [updateTemplate /* updateTemplateRes */] = useMutation(
    evaluationTemplateUpdate
  );

  // Data maps
  const template = getTemplateRes?.data?.evaluationTemplateGet || {};

  // Effects
  useEffect(() => {
    if (id) {
      getTemplate({ variables: { id } });
    }
  }, [id]);

  useEffect(() => {
    if (template) {
      setDescription(template?.description);
      setName(template?.name);
    }
  }, [template]);

  // Other

  // Effects
  useEffect(() => {
    const handleGlobalEvent = e =>
      !e.path.includes(popup.current) && viewDropdown
        ? setViewDropdown(null)
        : null;
    window.addEventListener("click", handleGlobalEvent);
    return e => {
      window.removeEventListener("click", handleGlobalEvent);
    };
  });

  const onSubmit = async data => {
    // const resp = await updateTemplate({
    //   variables: {
    //     id: template?.id,
    //     input: {
    //       name,
    //       description,
    //     },
    //   },
    // });
  };

  if (!getTemplateRes?.data && getTemplateRes.loading) {
    return <GhostLoader />;
  }

  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[
          {
            seperator: ">",
            page1: "EVALUATION TEMPLATES",
            route: evaluation_templates_page,
          },
          { seperator: ">", page1: `${template?.name}` },
        ]}
      />
      <Card containerClassName="add-section-conatiner">
        {/* HEADER */}
        <div className="text-container">
          <form className="templateform ">
            <i
              className="fa fa-chevron-left"
              onClick={() => hist.goBack()}
              style={{
                color: "var(--ui-color-primary-green-dark2)",
                transform: "scale(1.4)",
                cursor: "pointer",
              }}
            />

            <div>
              <TextBox
                name="name"
                defaultValue={name}
                onChange={e => setName(e.target.value)}
                placeholder="Template Name"
                onBlur={e => {
                  updateTemplate({
                    variables: {
                      id: template?.id,
                      input: {
                        name: e.target.value,
                      },
                    },
                  });
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
              <textarea
                style={{ padding: "12px 15px" }}
                name="description"
                onChange={e => setDescription(e.target.value)}
                value={description}
                rows="4"
                cols="50"
                placeholder="Template Description"
                onBlur={e => {
                  updateTemplate({
                    variables: {
                      id: template?.id,
                      input: {
                        description: e.target.value,
                      },
                    },
                  });
                }}
              />
            </div>
            {/* </div> */}
          </form>

          <div className="section_btn">
            <NotataButton
              size="large"
              bgcolor="primary_color"
              startIcon="add"
              text="ADD NEW SECTION"
              iconPosition={ICONPOSITION.START}
              datatut="add-new-section"
              onClick={() => {
                onSubmit();
                setAddSectionModal(true);
              }}
            />
            <NotataButton
              size="large"
              bgcolor="secondary_color"
              text="Import section"
              datatut="import-section"
              onClick={() => setImportSectionModal(true)}
            />
          </div>
        </div>
        <div className="evaluation-templates-container__data-container">
          {template?.sections?.map((section, index) => {
            return (
              <div
                className="evaluation-templates-container__data-container__sectionData"
                key={`row-id-${section.id}`}
                data-tut={index === 0 ? "section-0" : ""}
              >
                <Link
                  to={`${evaluation_template_page}/${id}/section/${section.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="template-name">{section.name}</div>
                </Link>
                <div className="sections">
                  {section?.questions?.length || 0} questions
                </div>
                <div className="group-name">
                  {section?.possibleScore || 0} Points
                </div>
                <div
                  className="evaluation-templates-container__data-container__sectionData__browse"
                  onMouseLeave={() => setViewDropdown(null)}
                >
                  <span
                    className="material-icons"
                    onClick={() => {
                      setViewDropdown(
                        viewDropdown === section.id ? null : section.id
                      );
                    }}
                  >
                    <i class="fal fa-cog" style={{ fontSize: "20px" }}></i>
                  </span>
                  {viewDropdown === section.id && (
                    <div className="evaluation-templates-container__data-container__sectionData__browse">
                      <TemplatePopup
                        open={viewDropdown}
                        setOpen={() => setViewDropdown(!viewDropdown)}
                        section={section}
                        setCloneModalForSection={setCloneModalForSection}
                        setDeleteModalForSection={setDeleteModalForSection}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Card>

      {importSectionModal && (
        <ImportSectionModal
          history={history}
          templateId={template.id}
          close={() => setImportSectionModal(false)}
        />
      )}

      {addSectionModal && (
        <AddSectionModal
          history={history}
          templateId={template.id}
          close={() => setAddSectionModal(false)}
        />
      )}

      {deleteModalForSection && (
        <DeleteSectionModal
          section={deleteModalForSection}
          templateId={template.id}
          history={history}
          close={() => setDeleteModalForSection(undefined)}
        />
      )}

      {cloneModalForSection && (
        <CloneSectionModal
          templateId={template.id}
          section={cloneModalForSection}
          history={history}
          close={() => setCloneModalForSection(undefined)}
        />
      )}
    </>
  );
};
