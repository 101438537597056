import React from "react";
import "./TextField.scss";

export const TextField = ({ title, description, placeholder }) => 
  <div className="text-parent">
    <div>
      <h4>{title}</h4>
      <p>{description}</p>
      <textarea
        placeholder={placeholder}
        className="text-box"
        rows="6"
        cols="50"
        name="comment"
      >
        {}
      </textarea>
    </div>
  </div>

export default TextField;
