import React from 'react';
import { filter } from 'lodash';

import { Card } from 'Components/NotataComponents';


//STYLES
import './StartupInfoSections.scss';

export default function StartupInfoSection({section}){


  const getAnswer = (question) => {

    return question?.answers?.length
      ? question.answers.map((ans, index) => <div key={index} className="question__answer">{ans}</div>)
      : <div className="not_answer">Not answered</div>
  }

  return(
    <Card
      containerClassName="card answer-container aswer_data"
      heading={section.sectionName}
      >
      {section?.questions.map((question,index)=>{
        return (
          <div
            className="question"
            key={`${question.id}-${index}`}
            >
            <div className="question__heading">{question.questionName}</div>

            {getAnswer(question)}

          </div>
        )
      })}

    </Card>
  )
}