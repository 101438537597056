import React from "react";
import "./Sidebar.scss";

export default function SecondarySidebar({ children, title, icon, close }) {
  return (
    <div className='sidebar'>
      <div className='sidebar__Toggler'>
        <i className="fas fa-chevron-right symbolColor" onClick={() => close(false)}/>
        <span className='padLeft' onClick={() => close(false)}>{title}</span>
      </div>
      <div className='sidebar__Main'>{children}</div>
    </div>
  );
}