import React from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { tagGroupsGet } from "../../../../Apollo/Queries";
import { tagDelete } from "../../../../Apollo/Mutations";

// MODALS
import { Modal } from "Components/NotataComponents/Modal/Modal";


// MAIN FUNCTION
export default function DeleteTagModal({ tag, setDeleteTagModal }) {
    // MUTATION
    const [deleteTag, deleteTagRes] = useMutation(tagDelete, {
      refetchQueries: [{ query: tagGroupsGet }],
    });
  
    return (
      <Modal
        title="Delete tag"
        loading={deleteTagRes.loading}
        submit={async () => {
          if (deleteTagRes.loading) {
            return;
          }
  
          let variables = {
            id: tag.id,
          };
          try {
            await deleteTag({ variables });
          } catch (error) {
            console.log("error", error);
          }
          setDeleteTagModal(undefined);
        }}
        close={() => {
          setDeleteTagModal(undefined);
        }}
        submitTxt="Delete"
        closeTxt="Cancel"
        submitButtonStyle="secondary"
        children={
          <div className="delete-group-modal-container">
            <div className="description">
              Are you sure you want to delete this tag permanently?
            </div>
            <div className="remember">Remember:</div>
            <div className="options">
              - Tag will be removed from all startups
              <br />
              - You cannot undo this change
              <br />
            </div>
          </div>
        }
      />
    );
}