import React, { useState, useEffect } from "react";

// COMPONENTS
import AddStartupModal from "../Modals/AddStartupModal";
import { getHasFilters } from "../getHasFilters";
import { NotataButton } from "Components/NotataComponents";
import TabSection from "./TabSection/TabSection";

// OTHERS

import Filterr from "assets/images/filter.svg";
import Column from "assets/images/column.svg";

//STYLES
import styles from "./RightSidebar.module.scss";
import "./RightSidebar.module.scss";


function SearchComponent({filters, setFilters}) {

  const [filterValue, setFilterValue] = useState();

  // company Search field
  const handleSearch = e => {
    let newValue = e.target.value
    // Set input value
    setFilterValue(newValue);
    // Execute if empty
    if (newValue === "") {
      setFilters({ ...filters, search: "" })
    }
  };

  const executeSearch = e => {
    if (e?.key === "Enter" || !e?.key) {
      setFilters({ ...filters, search: filterValue })
    }
  };


  return (
    <div
      className={styles.tableSearch}
      style={{ marginLeft: "30px" }}
      data-tut="search-startup"
    >
      <input
        type="text"
        value={filterValue || ""}
        onChange={handleSearch}
        onKeyPress={executeSearch}
        autoComplete="off"
      />
      <button
        className={styles.searchButton}
        onClick={() => executeSearch()}
      >
        <span>Search</span>
        <span>S</span>
      </button>
      <i className="far fa-search search" />
    </div>
  )

}


export default function NavigationBar({

   funnelGroups,

   filters,
   setFilters,

   tabValue,
   setTabValue,

   selectedFunnelGroup,
   setSelectedFunnelGroup,

   viewSidebar,
   setViewSidebar

  }) {

  // STATES
  const [ newStartupModal, setNewStartupModal] = useState(false)
  const [clearFilterTxt, setClearFilterTxt] = useState(undefined);

  // handle connections get query with filters
  useEffect(() => {
    setClearFilterTxt(getHasFilters(filters));
  }, [filters]);


  return (
    <>
      <div className={styles.override}>
        <div className={`${styles.container} ${clearFilterTxt ? styles.pb_sm : styles.pb_lg}`}>
          <div className={styles.footer}>
            <div className={styles.filter_container}>


              {/* SECTION #1: ADD STARTUP + SEARCH */}
              <div className={styles.table_headerChild + " " + styles.table_headerChildFirst}>
                <div className={styles.table_headerInner} style={{ width: "100%" }}>

                  {/* ADD NEW STARTUP */}
                  <NotataButton
                    size="big_wide"
                    datatut="add-new-startup"
                    bgcolor="primary_color"
                    startIcon="add"
                    text="Add new startup"
                    onClick={setNewStartupModal}
                  />

                  {/* SEARCH */}
                  <SearchComponent
                    filters={filters}
                    setFilters={setFilters}
                  />

                </div>
              </div>


              {/* SECTION #2: TABS – FUNNEL & LIST VIEW */}
              <div
                className={`
                  ${styles.table_headerChild} 
                  ${styles.table_headerChildMiddle} 
                  table_headerChildMiddle
                `}
                >
                <TabSection
                  funnelGroups={funnelGroups}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  selectedFunnelGroup={selectedFunnelGroup}
                  setSelectedFunnelGroup={setSelectedFunnelGroup}
                />
              </div>


              {/* SECTION #3: PANEL OPENERS */}
              <div
                className={styles.table_headerChild + " " + styles.table_headerChildLast}
                style={{ width: "20%" }}
              >
                <div style={{ width: "100%" }}>

                  <div>
                    <div className={styles.filterContainer}>
                      <button
                        data-tut ="manage-columns"
                        className={styles.filterButton + " " + styles.manageButton}
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          if (viewSidebar !== "column") {
                            setViewSidebar("column")
                          }
                        }}
                      >
                        <img src={Column} alt="" />
                        <span>Manage Columns</span>
                      </button>

                      <button
                        data-tut ="startup-filter"
                        className={styles.filterButton}
                        onClick={() => {
                          if (viewSidebar !== "filter") {
                            setViewSidebar("filter")
                          }
                        }}
                      >
                        <img src={Filterr} alt="" /> <span>Filter</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      {newStartupModal && (
        <AddStartupModal
          closeModal={() => setNewStartupModal(false)}
          setFilters={() => setFilters({
            sortBy: "UPDATED_AT",
            sortDirection: "DESC"
          })}
        />
      )}

    </>
  );
}


