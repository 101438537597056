import gql from "graphql-tag";
import { publicSharingFragments } from "../Fragments";

export default gql`
  query publicSharingGet($id: ID!) {
    publicSharingGet(id: $id) {
      ...publicSharingFields
    }
  }
  ${publicSharingFragments}
`;
