import React, { useEffect, useState } from "react";

// API STUFF
import { useQuery, useMutation } from "@apollo/client";
import { groupUserInvite } from "private/Apollo/Mutations";

// UI COMPONENTS
import { NotataButton } from "Components/NotataComponents";
import { groupGetV2 } from "../../../Apollo/Queries";
import { Loader } from "../../../../Components/NotataComponents";

// MAIN FUNCTION
export default function GroupMembersImport({
    toGroupId,
    fromGroupId,
    // group,
    canInvite,
    preMembers
  }) {


  const [ loadingButtons, setLoadingButtons ] = useState({})

  // Queries
  let {data, error, loading} = useQuery(groupGetV2, {variables: { id: fromGroupId }})

  // Mutations
  const [userInvite, userInviteRes] = useMutation(groupUserInvite);

  // Maps and reducers
  const group = data?.groupGetV2;

  // Functions
  const inviteMember = async (email) => {
    if (!canInvite) {
      return;
    }
    let variables = {
      groupId: toGroupId,
      email: email.toLocaleLowerCase().trim(),
    };
    try {
      await userInvite({ variables });
    } catch (error) {
      console.log("error", error);
    }
  }


  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">
        Import users
      </span>

      <div className="invite-member-modal__member-container__data">


        {
          (loading && !data) && (
            <Loader
              style={{
                position: "relative",
                top: "0",
                left: "0",
                paddingTop: "20px",
                paddingBottom: "20px"
              }}
            />
          )
        }

        {
          (
            data && group?.members?.length === 0
          ) && (
            <p className="message--not-found" >
              This group has no members...
            </p>
          )
        }

        {
          group?.members?.map(({ user }, index) => {

            let isAlreadyInvited = preMembers?.some(email => email === user.email);

            return (
              <div
                className="invite-member-modal__member-container__member"
                key={`group-memebr-key-${index}`}
                >

                <div className="invite-member-modal__member-container__member__info">
                  <div className="invite-member-modal__member-container__member__info__name">
                    {user.email}
                  </div>
                </div>

                <NotataButton
                  text={isAlreadyInvited ? 'invited' : "invite"}
                  bgcolor={isAlreadyInvited ? "outlined" : "primary_color"}
                  size="medium invite-member-modal__member-container__member__invite-btn"
                  loading={loadingButtons[user.email]}
                  onClick={async () => {
                    if (isAlreadyInvited) {
                      return
                    }
                    setLoadingButtons({ ...loadingButtons, [user.email]: true })
                    await inviteMember(user.email)
                    setLoadingButtons({ ...loadingButtons, [user.email]: false })
                  }}
                />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}