import React from "react";

// Styles
import "./CardMessageContainer.scss";

// Main function
export default function CardMessageContainer({
  error,
  danger,
  success,
  notice,
  title,
  message,
  children,
  containerClass,
  containerStyle
}) {
  return (
    <div
      className={`card-message ${containerClass && containerClass}`}
      style={containerStyle}
      >
      <div
        className={`card-message__content ${
          (danger && "danger") ||
          (error && "error") ||
          (success && "success") ||
          (notice && "notice") ||
          (error && "error") ||
          "regular"
        }`}
      >
        <span className="material-icons icon">
          {(notice && "info") ||
            (error && "error") ||
            (danger && "error_outline") ||
            (error && "error_outline") ||
            (success && "check_circle") ||
            "warning_amber"}
        </span>
        {/* card title */}
        {title && <div className="card-message__content__title">{title}</div>}

        {/* card message */}
        {message && (
          <div className="card-message__content__message">{message}</div>
        )}

        {/* childrens */}
        {children &&
          <div className="card-message__content__children">{children}</div>
        }
      </div>
    </div>
  );
}
