import React from "react";
import { Card, NotataButton } from "Components/NotataComponents";
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";
import { getScore, getEvaluated } from "utils/groupSharing";
import { cloneDeep } from "lodash";

export default function ListView({
  group,
  setSubjectiveModal,
  setSelectedStartUp,
  setEvaluateModal,
  setViewStartup
}) {

  const groupId = group.id;
  const { evaluationTemplate, publicSettings } = group || {};
  const { withEvaluationTemplate, withSubjectiveScores } = publicSettings || {};
  const showSubjectiveScore = withSubjectiveScores;
  const showEvaluation = withEvaluationTemplate && evaluationTemplate;

  let startups = cloneDeep(group?.startups);
  startups = startups?.sort((a, b) => {
    if(a?.name?.trim().toLowerCase() < b?.name?.trim().toLowerCase()) { return -1; }
    if(a?.name?.trim().toLowerCase() > b?.name?.trim().toLowerCase()) { return 1; }
    return 0;
  })

  return (
    <div className="group-share-container__content__startups group-share-container__no_margin">
      <Card containerClassName="group-share-container__content__startups__list-view">

        <div className="group-share-container__content__startups__list-view__header">

          <div className="group-share-container__content__startups__list-view__header__item">
            Company Name{" "}
            {/*<i className="fas fa-sort-alt"/>*/}
          </div>

          {
            showSubjectiveScore && (
              <div className="group-share-container__content__startups__list-view__header__item ml-auto score-header">
                Score{" "}
                {/*<i className="fas fa-sort-alt"/>*/}
              </div>
            )
          }

          { showEvaluation && (
            <div className="group-share-container__content__startups__list-view__header__item ml-auto evaluate-header">
              Evaluate{" "}
              {/*<i className="fas fa-sort-alt"/>*/}
            </div>
          )}

        </div>
        {startups?.map((startup) => {
            const isEvaluated = getEvaluated(startup, groupId);
            const score = getScore(startup, groupId);
            return (
              <div
                key={startup?.id}
                className="group-share-container__content__startups__list-view__row"
                >
                <div
                  className="group-share-container__content__startups__list-view__row__startup-container"
                  onClick={() => {
                    setViewStartup(startup.id)
                  }}
                >

                  <div className="group-share-container__content__startups__list-view__row__startup-container__startup_logo">

                    <StartupLogo
                      startupName={startup?.name}
                      size="mini"
                      logoStyle={{cursor: "pointer"}}
                      url={startup?.logo}
                    />

                  </div>

                  <div className="group-share-container__content__startups__list-view__row__startup-container__startup-name">
                    {startup?.name}
                  </div>


                </div>

                <div className="group-share-container__content__startups__list-view__row__buttons-container">
                {showSubjectiveScore && (
                  <NotataButton
                    bgcolor={!score && "primary_color"}
                    buttonStyle={score ? 'outlined medium' : 'medium'}
                    text={score || "Score"}
                    onClick={() => {
                    setSubjectiveModal(true);
                    setSelectedStartUp(
                      {
                        ...startup,
                        subjectiveScores: [
                          {
                            isMe: true,
                            score: score
                          }
                        ]
                      }
                    )
                  }} />
                )}
                {showEvaluation && (
                  <NotataButton
                    bgcolor={!isEvaluated && "primary_color"}
                    buttonStyle={isEvaluated ? 'outlined medium' : 'medium'}
                    onClick={() => {
                      setEvaluateModal(true);
                      setSelectedStartUp(startup);
                    }}
                    text={isEvaluated ? "Evaluated" : "Evaluate"}
                  />
                )}
                </div>
              </div>)
            }
          )}
        </Card>
      </div>
  )
}