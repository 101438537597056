import React from "react";

// COMPONENTS
import PopupMenu from "./PopupMenu";

// OTHERS
import Column from "assets/images/column_row.svg";
import KanbanIcon from "assets/images/KanbanIcon.svg";

export const TabArrValue = (
    setKanbanPopup,
    kanbanPopup,
    kanbanDropDown,
    setSelectedFunnelGroup,
    selectedFunnelGroup,
    activeTab
  ) => {
    return [
      {
        value: "kanban",
        text: (
          <div
            data-tut="kanban-tab"
          >
            <img
              style={{
                width: 15,
                height: 15,
                marginRight: "4px",
                transform: "rotateZ(360deg)",
                opacity: activeTab === "kanban" ? 1 : 0.5,
              }}
              src={KanbanIcon}
              alt=""
            />
            <span>FUNNEL</span>
            <i
              onClick={() => setKanbanPopup(!kanbanPopup)}
              style={{ marginLeft: "5px" }}
              className="fas fa-chevron-down"
            />
            <PopupMenu
              title="FUNNEL"
              items={kanbanDropDown}
              isOpen={kanbanPopup}
              setSelectedfunnelGroup={setSelectedFunnelGroup}
              selectedfunnelGroup={selectedFunnelGroup}
              setIsOpen={setKanbanPopup}
            />
          </div>
        ),
      },
      {
        value: "spreadsheet",
        text: (
          <div data-tut="list-tab">
            <img
              style={{
                width: 15,
                height: 15,
                marginRight: "4px",
                opacity: activeTab === "kanban" ? 0.5 : 1,
              }}
              src={Column}
              alt=""
            />
            <span>LIST</span>
          </div>
        ),
      },
    ];
  };