import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";

// API Queries
import { userGet, notificationsGet } from "private/Apollo/Queries";
import { notificationsMarkAllAsSeen } from "private/Apollo/Mutations"
 
// Custom Components
import { BrowseDropDown } from "Components/NotataComponents";
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";
import TopMenuNotifications from "../../TopMenuLinksFolder/TopMenuNotifications/TopMenuNotifications";

import {
  settings,
  signOut,
  setting_profile,
  your_team,
  notification,
} from "definitions.js";

// Styles
import "./NotataHeader.scss"

// Others
import { NOTIFICATIONS_POLL_INTERVAL } from "private/Pages/constants";



// Main Function
export function NotataHeader({
     history,
     setTourOpen,
     isTourOpen,
     tourCaller,
     withBanner
   }) {


  // QUERIES  
  const { data } = useQuery(userGet);
  let user = data?.userGet || {};

  // MUTATIONS
  const [markAllNotificationsAsSeen] = useMutation(notificationsMarkAllAsSeen, {
    refetchQueries: [{ query: userGet }],
  });

  const { data: nData } = useQuery(notificationsGet, {
    pollInterval: NOTIFICATIONS_POLL_INTERVAL,
    fetchPolicy: "network-only"
  });
  let notifications = nData?.notificationsGet || [];

  let unseen = notifications.filter(({ seen }) => !seen);

  const [listOpen, setListOpen] = useState(false);
  const [notificationsOpen, setNotificatonsOpen] = useState(false);
  const [showCircle, setShowCircle] = useState(true);

  // DropDown Handler
  const handleDropDown = () => setListOpen(!listOpen);

  const markAllSeen = async () => {
    try {
      setShowCircle(false);
      await markAllNotificationsAsSeen();
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (history.location?.pathname?.indexOf('/startup_page/settings/notifications') > -1 && unseen?.length > 0) {
      markAllSeen();
    }
  }, [history?.location])
  
  return (
    <>

      <div
        className="notata-header__container"
        style={{
          top: withBanner ? "30px" : "0px"
        }}
      >




        <span style={{ paddingTop: unseen.length !== 0 ? 3 : 3 }}>
          <i data-tut="tour-icon" className={`${tourCaller ? '' : 'disssable-tour'} fas fa-question-circle question_circle ${isTourOpen && tourCaller ? 'link-color' : ''}`} onClick={tourCaller ? setTourOpen : null} />
        </span>
        <span style={{ marginTop:unseen.length == 0 && 4  }}>
          <span
            onClick={() => setNotificatonsOpen(!notificationsOpen)}
            onMouseOver={() => setNotificatonsOpen(true)}
            onMouseLeave={() => setNotificatonsOpen(false)}
          >
            <Link to={notification} className="link-color" >
              <i
                data-tut="notification-icon"
                className={`fas fa-bell notifiction_icon ${notificationsOpen ? "notification_icon_selected" : ""}`}
              >
              </i>
            </Link>
            {notificationsOpen && <div className="hide-notification">
              <TopMenuNotifications />
            </div>}
            {(unseen.length !== 0 && showCircle) && <div className="circle" />}
          </span>
        </span>
        <div className="profile" data-tut="profile-icon">
          <div className="profile__picture__img">
            <StartupLogo
              url={user?.logo}
              startupName={user?.given_name}
              size={"small"}
            />
          </div>
          <p className="profile__name">
            {user.given_name} {user.family_name}
          </p>
          <div
            role="button"
            onClick={() => setListOpen(!listOpen)}
          >
            <span className="angle_icon">
              <i className={`fal fa-angle-${listOpen ? "up" : "down"}`} />
            </span>
          </div>
        </div>
      </div>
      <div className="drop_down" >
        <BrowseDropDown
          open={listOpen}
          setOpen={handleDropDown}
          items={[
            { text: " user profile", icon: "", link: setting_profile, callBack: () => { } },
            { text: " team ", icon: "", link: your_team, callBack: () => { } },
            { text: " settings ", icon: "", link: settings, callBack: () => { } },
            { text: " log out", icon: "", link: signOut, callBack: () => { } },
          ]}
        />
      </div>
    </>
  );
}
