import React from "react";
import { Link } from "react-router-dom";

// API STUFF
import { useQuery } from "@apollo/client";
import { reportsGet } from "private/Apollo/Queries";

//CUSTOM COMPONENTS
import ReportAnalyticChart from "./ReportAnalyticsChart";
import {
  Loader,
  NotataButton,
  CardComponent,
} from "Components/NotataComponents";

//DEFINITION
import { reports } from "definitions";

//Main Function
export default function DashboardReports({ history, reportsSettingTagGroup }) {
  // Queries
  const { data, loading } = useQuery(reportsGet);

  // Maps and Reducers
  const report = data?.reportsGet || {};
  const { connectionCount = 0, groupStats, funnelStats, tagStats } = report;
  const { peopleInGroups = 0 } = groupStats || {};

  return (
    <CardComponent
      containerClassName="dashboard-container__reports-and-analytics"
      padding="small"
      data-tut="reports-analytics"
      title="Reports and analytics"
      titleSize="small"
      content={
        <>
          {!data && loading && <Loader />}
          <div className="dashboard-container__reports-and-analytics__container">
            <div className="dashboard-container__reports-and-analytics__scores-container">
              <div className="dashboard-container__reports-and-analytics__scores-container__score">
                <div className="dashboard-container__reports-and-analytics__scores-container__score__heading">
                  All startups
                </div>
                <div className="dashboard-container__reports-and-analytics__scores-container__score__value-container">
                  <div className="dashboard-container__reports-and-analytics__scores-container__score__value-container__value">
                    {connectionCount}
                  </div>
                </div>
              </div>
              <div className="dashboard-container__reports-and-analytics__scores-container__score">
                <div className="dashboard-container__reports-and-analytics__scores-container__score__heading">
                  People in your network
                </div>
                <div className="dashboard-container__reports-and-analytics__scores-container__score__value-container">
                  <div className="dashboard-container__reports-and-analytics__scores-container__score__value-container__value">
                    {peopleInGroups === null ? 0 : peopleInGroups}
                  </div>
                </div>
              </div>
            </div>
            {tagStats && funnelStats && (
              <div className="dashboard-container__reports-and-analytics__chart-funnel-container">
                <div className="dashboard-container__reports-and-analytics__chart-funnel-container__chart">
                  <ReportAnalyticChart
                    tags={tagStats}
                    reportsSettingTagGroup={reportsSettingTagGroup}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      }
      footer={
        <Link to={reports} className="link_underline">
          <NotataButton
            color="primary"
            text="View Reports"
            buttonStyle="text_button"
          />
        </Link>
      }
    />
  );
}
