import React, { useEffect } from "react";

// API STUFF
import { useQuery, useLazyQuery } from "@apollo/client";
import { connectionGet, accountGet, creativeTemplateGet } from "private/Apollo/Queries";
import { startup_page } from "definitions";

// GENERAL COMPONENTS
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { GhostLoader, BreadCrumbs } from "Components/NotataComponents";

// COMPONENTS IMPORTED FROM OTHER PAGES

// CUSTOM COMPONENTS
import Overview from "./Tabs/Overview/Overview";
import StartupInfo from "./Tabs/StartupInfo/StartupInfo";
import Evaluations from "./Tabs/Evaluations/Evaluations";
import Sharing from "./Tabs/Sharing/Sharing";

// STYLES
import "../public.scss";
import "./index.scss"

// HELPERS
import queryString from "query-string";
import { userGet } from "../../Apollo/Queries";

// OTHER / CONSTANTS
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const tabs = ["STARTUP INFO", "OVERVIEW", "EVALUATIONS", "SHARING", "MATERIALS"]
export const StartupPage = ({ match, history, location, }) => {
  // Url stuff
  const { id } = match?.params;

  // States
  const [tab, setTab] = React.useState(0);


  // Queries
  const userQuery = useQuery(userGet);
  const accountQuery = useQuery(accountGet);
  const [getConnection, getConnectionRes] = useLazyQuery(connectionGet,{
      fetchPolicy:"network-only"
  });
  const creativeTemplateGetQuery = useQuery(creativeTemplateGet);
  const {loading} = useQuery(userGet);

  // Execute query
  useEffect(() => {
    if (id) {
      getConnection({ variables: { id } });
    }
  }, [id]);

  // Set tab from url
  useEffect(() => {
    const { tab } = queryString.parse(location.search);
    if (tab) {
      try {
        setTab(parseInt(tab));
      } catch (error) {
        console.log(error);
      }
    }
  }, [location]);

  // Data maps
  let connection = getConnectionRes?.data?.connectionGet;
  let account = accountQuery?.data?.accountGet;
  let creativeTemplate = creativeTemplateGetQuery?.data?.creativeTemplateGet;

  let isLoading = getConnectionRes?.loading || accountQuery?.loading || creativeTemplateGetQuery?.loading

  let user = userQuery?.data?.userGet;

  // Update tab in url
  const handleChange = (event, newValue) => {
    let pathName = `${match.url}?tab=${newValue}`;
    history.push(pathName);
  };

  if (isLoading) {
    return <GhostLoader />;
  }


  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(startup_page)}
        routePage={`My Startups`}
        history={history}
        pages={[{ seperator: ">", page1: `${connection?.creative.name} : ${tabs[tab]}` }]}
      />

      <div
        style={{
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        <div className="col-12 startup-container">
          <Tabs value={tab} onChange={handleChange}>
            <Tab data-tut="startup-info" label="STARTUP INFO" {...a11yProps(0)} />
            <Tab data-tut="overview" label="OVERVIEW" {...a11yProps(1)} />
            <Tab data-tut="evaluations" label="EVALUATIONS" {...a11yProps(2)} />
            <Tab data-tut="groups" label="SHARING" {...a11yProps(3)} />
          </Tabs>
        </div>

        <TabPanel value={tab} index={0}>
          <StartupInfo connection={connection} history={history} creativeTemplate={creativeTemplate}/>
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <Overview
            connection={connection}
            history={history}
            user={user}
            account={account}
            refetch={getConnectionRes.refetch}
          />

        </TabPanel>

        <TabPanel value={tab} index={2}>
          <Evaluations
            history={history}
            connection={connection}
            account={account}
          />
        </TabPanel>

        <TabPanel value={tab} index={3}>
          <Sharing
            connection={connection}
            history={history}
          refetch={getConnectionRes.refetch}
          />
        </TabPanel>
      </div>
    </>
  );
};
