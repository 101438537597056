import gql from "graphql-tag";

export default gql`
  fragment userAnalyticsFields on UserAnalytics {
    usersTotal
    usersSignupCount
    usersSignupList {
      email
      given_name
      family_name
    }
    usersActivityCount
    usersActivityList {
      email
      given_name
      family_name
    }
    isSuperAdmin
  }
`;
