import React, { useState, useEffect } from "react";

// Styles
import "./RadioButton.scss";

export const RadioButton = ({
  name,
  label,
  value,
  id,
  checked,
  defaultChecked,
  defaultValue,
  styles,
  handleChange,
  ...props
}) =>
    <label className="radio-button-container">
      <span className="radio-button-container__radio-button-label"  >
        {label}
      </span>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        name={name}
        defaultValue={defaultValue}
        {...props}
        styles={styles}
        onChange={handleChange}
      />
      <span className="checkmark"></span>
    </label>


export function RadioButtons({ name, data, getValue, setValue }) {
  const [checked, setChecked] = useState(data.length ? data[0].value : "");

  useEffect(() => {
    if (setValue) {
      setChecked(setValue.toLowerCase());
      if (getValue) {
        getValue(setValue.toLowerCase());
      }
    }
  }, [setValue]);

  function handleChange({ target }) {
    const { value } = target;
    if (getValue) {
      getValue(value);
    }
    setChecked(value);
  }

  return (
    <>
      {data.map((d, i) => (
        <RadioButton
          key={`${d.id}`}
          id={`${d.id}`}
          label={d.label}
          value={d.value}
          name={name}
          checked={checked === d.value}
          handleChange={handleChange}
        />
      ))}
    </>
  );
}
