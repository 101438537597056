import React, { useEffect, useState } from "react";

//API STUFF
import { useMutation } from "@apollo/client";
import { reportSettingsSet } from "private/Apollo/Mutations";

// CUSTOM COMPONENTS
import ReportBarChart from "../../../Reports/ReportCharts/ReportBarChart";
import ReportPieChart from "../../../Reports/ReportCharts/ReportPieChart";
import ReportsColumnChart from "../../../Reports/ReportCharts/ReportsColumnChart";
import { BrowseDropDown } from "Components/NotataComponents";

// OTHERS
import { REPORTCHARTS } from "../../../constants";
import { find } from "lodash";

// ASSETS
import ghraphChart from "assets/images/bar_graph.svg";
import selectedBarGraphChart from "assets/images/selected-bar-graph.svg";
import selectedColumnGraph from "assets/images/column-graph-selected.svg";
import pieChart from "assets/images/pie-chart.svg";
import selectedPieChart from "assets/images/pie-chart-selected.svg";
import columnChart from "assets/images/column-chart.svg";

// STYLES
import "./ReportAnalyticsChart.scss";
import "../../../Reports/ReportCharts/ChartTile.scss";

const filterTagsData = tag => {
  // filter out the zero values
  // Sort by connection count
  let tagsData = tag.tags
    ?.filter(item => item.connectionCount > 0)
    .sort(function (a, b) {
      return b.connectionCount - a.connectionCount;
    });

  // Slice top 9 and add others to the last
  if (tagsData.length > 10) {
    let others = tagsData.slice(9, tagsData.length);
    tagsData = tagsData.slice(0, 9);
    tagsData.push({
      tagName: "Others",
      id: "xxxx",
      connectionCount:
        others.length > 0
          ? others.reduce((a, b) => ({
              connectionCount: a.connectionCount + b.connectionCount,
            })).connectionCount
          : 0,
    });
  }
  return tagsData;
};

//MAIN FUNCTION
export default function ReportAnalyticChart({ tags, reportsSettingTagGroup }) {
  // STATE
  const [selectedChart, setSelectedChart] = useState(REPORTCHARTS.PIE);
  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [totalConnectionCount, setTotalConnectionCount] = useState(null);
  const tagInitial = find(tags, ["tagGroupId", reportsSettingTagGroup]);
  const [tagGroup, setTagGroup] = useState(
    tagInitial ? tagInitial : tags ? tags[0] : null
  );

  //Mutation
  const [updateReportsSettings] = useMutation(reportSettingsSet);

  // EFFECTS
  //Sets tags data to the state
  useEffect(() => {
    setTagsData(filterTagsData(tagGroup));
  }, [tagGroup]);

  // total connectionCount
  useEffect(() => {
    setTotalConnectionCount(
      tagsData.length
        ? tagsData.reduce((a, b) => ({
            connectionCount: a.connectionCount + b.connectionCount,
          })).connectionCount
        : 0
    );
  }, [tagsData]);

  return (
    <div className="reports-analytics-chart">
      <div className="reports-analytics-chart__top-bar ">
        <div className="reports-analytics-chart__top-bar__tags ">
          <span className="reports-analytics-chart__top-bar__tags__heading">
            TAGS:
          </span>
          <div
            className="reports-analytics-chart__top-bar__tags__select-tag"
            onClick={() => setDropDownOpen(!isDropDownOpen)}
            onMouseLeave={() => setDropDownOpen(false)}
          >
            {tagGroup.tagGroupName}
            <i className={`fa fa-chevron-${isDropDownOpen ? "up" : "down"}`}>
              {isDropDownOpen && (
                <div className="reports-analytics-chart__dropDown">
                  <BrowseDropDown
                    open={isDropDownOpen}
                    setOpen={() => setDropDownOpen(!isDropDownOpen)}
                    items={tags?.map(item => {
                      return {
                        text: item.tagGroupName,
                        callBack: () => {
                          setTagGroup(item);
                          const variables = {
                            input: {
                              dashboardTagGroup: item.tagGroupId,
                            },
                          };
                          updateReportsSettings({ variables });
                        },
                        selected: item.tagGroupId === tagGroup.tagGroupId,
                      };
                    })}
                  />
                </div>
              )}
            </i>
          </div>
        </div>

        <div className="reports-analytics-chart__top-bar__chart-types">
          <img
            alt=""
            src={
              selectedChart === REPORTCHARTS.BAR
                ? selectedBarGraphChart
                : ghraphChart
            }
            className={`${
              selectedChart === REPORTCHARTS.BAR ? "selected" : ""
            }`}
            onClick={() => setSelectedChart(REPORTCHARTS.BAR)}
          />
          <img
            alt=""
            src={
              selectedChart === REPORTCHARTS.COLUMN
                ? selectedColumnGraph
                : columnChart
            }
            className={`${
              selectedChart === REPORTCHARTS.COLUMN ? "selected" : ""
            }`}
            onClick={() => setSelectedChart(REPORTCHARTS.COLUMN)}
          />
          <img
            alt=""
            src={
              selectedChart === REPORTCHARTS.PIE ? selectedPieChart : pieChart
            }
            className={`${
              selectedChart === REPORTCHARTS.PIE ? "selected" : ""
            }`}
            onClick={() => setSelectedChart(REPORTCHARTS.PIE)}
          />
        </div>
      </div>
      <div className={`chart-tile-container`}>
        {selectedChart === REPORTCHARTS.COLUMN && (
          <ReportsColumnChart tagsData={tagsData} />
        )}
        {selectedChart === REPORTCHARTS.PIE && (
          <ReportPieChart
            tagsData={tagsData}
            totalConnectionCount={totalConnectionCount}
          />
        )}
        {selectedChart === REPORTCHARTS.BAR && (
          <ReportBarChart
            tagsData={tagsData}
            totalConnectionCount={totalConnectionCount}
          />
        )}
      </div>
    </div>
  );
}
