import React, { useState } from "react";

// STYLES
import "./SubjectiveScoresComp.scss";

// CUSTOM COMPONENTS
import SubjectiveScoreModal from "../../../../MyStartups/Modals/SubjectiveScoreModal";
import { SubjectiveScore } from "../../../../MyStartups/Modals/SubjectiveScore";

// OTHERS
import moment from "moment";

// Main function
export default function SubjectiveScoresComp({
  connection,
  account,
  isPublic,
  score,
}) {
  const [showSubjectiveScore, setShowSubjectiveScore] = useState();
  const { subjectiveScores } = connection;

  return (
    <>
      <div className="card-empty-text" style={{ paddingTop: "0px" }}>
        On a scale from 1 to 10, how would you score this company?
      </div>

      <div>
         <div className="overview-container__scores__selector">
            <div className="sub_scores">
              {/* SubjectiveScore */}
                <SubjectiveScore
                  connection={connection}
                  isPublic={isPublic}
                  score={score}
                />
            </div>
        </div>

        {!isPublic &&
          subjectiveScores?.map((score, indx) => {
            return (
              <div
                key={indx}
                className={`overview-container__subjective-scores__submission`}
              >
                <div className="overview_bg_color ">
                  <div className="col-sm-5 col-xs-5 score-name score_in_mobile">
                    {score?.createdByUser?.given_name}{" "}
                    {score?.createdByUser?.family_name}
                  </div>
                  <div className="col-sm-4 col-xs-4 submissions">
                    {moment(score.updatedAt).format("ll")}
                  </div>
                  <div className="col-sm-3 col-xs-3 scorePer">
                    <div className="score-content">{score.score}</div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {/* showSubjectiveScore */}
      {showSubjectiveScore && (
        <SubjectiveScoreModal
          connection={connection}
          close={() => setShowSubjectiveScore(undefined)}
        />
      )}
    </>
  );
}
