import React, { useState } from 'react';
import { Card, NotataButton } from "Components/NotataComponents";
import CardMessageContainer from 'private/Pages/Group/Components/CardMessageContainer';
import "./PinAuthenticator.scss";
import { useLazyQuery } from "@apollo/client";
import { publicGroupTestPin } from "public/Apollo/Queries";

export default function PinAuthenticator({ groupId, success }) {

  const [pin, setPin] = useState({
    one: undefined,
    two: undefined,
    three: undefined,
    four: undefined,
    five: undefined,
    six: undefined
  })

  const queryOptions = { context: { clientName: "public" } };
  const [testPinWithAPI, { error, data, called, loading }] = useLazyQuery(publicGroupTestPin, queryOptions);

  // It's a success
  if (!error && called && data) {
    success(parseInt(Object.values(pin).join("")))
  }

  // Test pin with API
  const testPin = async (pin) => {
    try {
      let variables = {
        groupId: groupId,
        pin: parseInt(pin)
      };
      testPinWithAPI({ variables });
    } catch (error) {
      console.log("error", error);
    }
  }

  const selectOnFocus = (e) => {
    e.target.select();
  }

  const handlePinChange = (e) => {
    const { name, value } = e.target;
    setPin(value)


    // let newPin = {
    //   ...pin,
    //   [name]: value
    // }
    // setPin(newPin)
    // const current = e.target.closest('div');
    // const focus = current?.nextElementSibling?.firstElementChild?.focus();
    // }
  }

  return (
    <div className="pin-authenticator-container">
      <Card
        containerClassName="pin-authenticator-container__content"
        headerClassName="pin-authenticator-container__content__header"
      >

        {
          !error && (
            <CardMessageContainer
              title="Heads up!"
              message={`This group is protected by a PIN code`}
              notice
            />
          )
        }

        {
          error && (
            <CardMessageContainer
              title="Access Denied"
              message={'Incorrect PIN'}
              error
            />
          )
        }

        <div className="pin-authenticator-container__content__pin-container">
          <div className="pin-authenticator-container__content__pin-container__digit">
            <input
              name="one"
              onFocus={selectOnFocus}
              onChange={handlePinChange}
              value={pin.one}
              type="text"
              maxLength="5"
              autoComplete="off"
            />
          </div>


        </div>
        <div className="pin-authenticator-container__content__submit">
          <NotataButton
            loading={loading}
            onClick={() => {
              testPin(Object.values(pin).join(""));
            }}
            text="Next" buttonStyle="primary_color next_button medium" />
        </div>
      </Card>
    </div>
  )



}