import React, { Suspense, useEffect } from 'react';

// Library Components
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// CUSTOM COMPONENTS
import { TourSteps } from './NotataTourConfig';

// STYLES
import './NotataTour.scss';

// Main Function
export default function NotataTour({ caller, isOpen, onClose }) {   

    // Effects
    useEffect(() => {
        document.documentElement.style.overflowX = 'inherit';
        document.documentElement.style.scrollBehavior = 'inherit';
    }, [isOpen]);
    
    // Functions
    const disableBody = target => disableBodyScroll(target)
    const enableBody = target => enableBodyScroll(target)

    return (
        caller ?
            <Suspense fallback={<React.Fragment />}>
                <Tour
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}
                    steps={TourSteps[caller]}
                    isOpen={isOpen}
                    onRequestClose={() => onClose()}
                    inViewThreshold={300}
                    accentColor={"#53cab2"}
                />
            </Suspense>
            :
            <></>
    )
}