import React from 'react';

// UI COMPONENT
import { BrowseDropDown } from "Components/NotataComponents";

export default function TemplatePopup({
    setCloneModalForSection,
    setDeleteModalForSection,
    section,
    open,
    setOpen
  }) {
  
    return (
      <div className="evaluation-templates-container__data-container__data__browse__eve-drop-dwon">
        <BrowseDropDown
          open={open}
          setOpen={setOpen}
          items={[
            { text: "CLONE", icon: <i class="far fa-clone"/>, leave: false, callBack: () => setCloneModalForSection(section) },
            { text: "Delete", icon: <i className="fa fa-trash"/>, leave: true, callBack: () => setDeleteModalForSection(section) },
          ]}
        />
      </div>
    );
  }