import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import {
  groupSubjectiveScoreAdd,
  connectionCreate,
  evaluationUpdate,
  evaluationCreate,
  groupEvaluationAdd
} from "private/Apollo/Mutations";
import { groupGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { CardComponent, NotataButton } from "Components/NotataComponents";

// COMMON COMPONENTS
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";

// CUSTOM COMPONENTS
import CardMessageContainer from "../../../Components/CardMessageContainer";
import EvaluationSummaries from "./Components/EvaluationSummaries";
import EvaluationDetails from "./Components/EvaluationDetails";
import StartupComments from "./Components/StartupComments";

//CONSTANTS
import {startup_page} from "../../../../../../definitions"

// STYLES
import "./StartupCardFullView.scss";
import SubjectiveScoreModal from "../../../../MyStartups/Modals/SubjectiveScoreModal";
import EvaluationSummaryModal from "../../../../EvaluatePage/EvaluationSummaryModal/EvaluationSummaryModal";
import EvaluateModal from "../../../../EvaluatePage/EvaluateModal/EvaluateModal";

// MAIN FUNCTION
export default function StartupCardFullView({
    group,
    startup,
    adminView,
    refetch,
    history,
  }) {


  const [ evaluateModalProperties, setEvaluateModalProperties ] = useState(undefined)
  const [ evaluationLoading, setEvaluationLoading ] = useState(false)

  const [ summaryModalContent, setSummaryModalContent ] = useState(undefined)

  const [ subjectiveScoreStartup, setSubjectiveScoreStartup ] = useState(undefined);
  const [ subjectiveScoreModal, setSubjectiveScoreModal ] = useState(false);

  // MUTATIONS
  const mutationOptions = {
    refetchQueries: [
      {
        query: groupGetV2,
        variables: { id: group.id },
      },
    ],
    awaitRefetchQueries: true,
  }
  const [shareScoreAdd, shareScoreAddRes] = useMutation(groupSubjectiveScoreAdd, mutationOptions);
  const [createConnection, createConnectionRes] = useMutation(connectionCreate, mutationOptions);

  const [mutateEvaluationUpdate] = useMutation(evaluationUpdate, mutationOptions);
  const [mutateEvaluationCreate] = useMutation(evaluationCreate, mutationOptions);
  const [evaluationAddToGroup] = useMutation(groupEvaluationAdd, mutationOptions);


  async function saveEvaluation(newAnswers) {

    setEvaluationLoading(true)

    let evaluation = evaluateModalProperties?.evaluation || {}

    // Maintain state
    setEvaluateModalProperties({
      ...evaluateModalProperties,
      evaluation: {
        ...evaluation,
        answers: newAnswers
      }
    })

    // Save evaluation
    let savedEvaluation;

    // Update existing
    if (evaluation.id) {
      try {
        let variables = {
          id: evaluation.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationUpdate({variables})
        savedEvaluation = res?.data?.evaluationUpdate;
      } catch (error) {
        console.log('error', error)
      }
    }

    // Create new
    if (!evaluation.id) {

      // ... create new evaluation
      try {
        let variables = {
          connectionId: evaluateModalProperties?.connection?.id,
          templateId: evaluateModalProperties?.template?.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationCreate({variables})
        savedEvaluation = res?.data?.evaluationCreate;
      } catch (error) {
        console.log('error', error)
      }

      // ... add evaluation to group
      try {
        let variables = {
          evaluationId: savedEvaluation?.id,
          groupId: group.id,
          creativeId: evaluateModalProperties?.creative?.id,
        };
        await evaluationAddToGroup({ variables });
      } catch(error) {
        console.log("error", error);
      }

    }

    setEvaluationLoading(false)

    // Hide edit modal
    setEvaluateModalProperties(undefined)

    // Open summary modal
    // setSummaryModalContent(savedEvaluation)

  }



  // MAPS AND REDUCERS
  let oneLiner;
  if (startup?.creative?.answers) {
    let _oneLiner = startup?.creative?.answers?.find(
      ({ questionId }) => questionId === "q01_section_info"
    );
    if (_oneLiner) oneLiner = _oneLiner.val;
  }

  let settings = group?.settings;
  let hideDetails = !settings?.showScores && !adminView;
  let hideSummaries = !settings?.showSummaries && !adminView;
  let evaluationTemplate = group?.evaluationTemplates && group?.evaluationTemplates[0];
  let sharedSubjectiveScore = startup?.subjectiveScores?.find(({ isMe }) => isMe);
  let iHaveEvaluated = startup?.evaluations?.find(evaluation =>
    evaluation?.createdByUser?.isMe &&
    evaluation?.templateId === evaluationTemplate?.id
  )

  return (
    <>
      <div className="">
        <CardComponent
          padding="large"
          title={startup.creative?.name}
          titleSize="medium"
          titleLink={
            startup.isInMyDealFlow
            ? `${startup_page}/company/${startup.connection?.id}`
            : ""
          }
          history={history}
          headerIcon={
            <span onClick={() => startup.isInMyDealFlow ? history.push(`${startup_page}/company/${startup.connection?.id}`): {}}>
              <StartupLogo
                url={startup.creative?.logo}
                startupName={startup.creative?.name}
                size={"mini"}
              />
            </span>
          }
        >
          <>
            {
              oneLiner && (
                <div className="startup-card-container__description">
                  {oneLiner}
                </div>
              )
            }

            {
              !startup.isInMyDealFlow && (
                <CardMessageContainer
                  title="Heads up!"
                  message={`This startup has not been added to your dealflow yet. In order for you to work with this company you have to add it.`}
                  notice
                >
                  <NotataButton
                    size="large"
                    bgcolor="primary_color"
                    startIcon="add"
                    text="Add to deal flow"
                    buttonStyle="startup-card-container__header__add-to-deal-flow"
                    loading={createConnectionRes.loading}
                    onClick={() => {
                      if (createConnectionRes.loading) {
                        return;
                      }
                      let variables = {
                        groupId: group.id,
                        creativeId: startup?.creative?.id,
                      };
                      createConnection({ variables });
                    }}
                  />
                </CardMessageContainer>
              )
            }

            {
              (settings?.addEvaluation && startup.isInMyDealFlow) && (

                <CardMessageContainer
                  title="Requested Evaluations"
                  message={`The administrator of this group has kindly requested that you evaluate this startup.`}
                  notice
                  containerStyle={{marginLeft: "30px"}}
                  >

                  <div
                    className={'startup-card-container__buttons-container'}
                    style={{
                      position: "relative",
                      border: "none",
                      maxWidth: "500px",
                      paddingTop: "30px",
                      paddingLeft: "30px"
                    }}
                  >

                    {
                      evaluationTemplate && (
                        <>
                          {
                            iHaveEvaluated && (
                              <NotataButton
                                text={`${iHaveEvaluated?.summary?.scorePercent || '0'}%`}
                                buttonStyle="evaulation-btn outlined-green big"
                                onClick={() => {
                                  let modalProperties = {
                                    connection: startup?.connection,
                                    creative: startup?.creative,
                                    template: evaluationTemplate,
                                    evaluation: iHaveEvaluated,
                                    group: group
                                  }
                                  setEvaluateModalProperties(modalProperties)
                                }}
                              />
                            )
                          }

                          {
                            !iHaveEvaluated && (
                              <NotataButton
                                bgcolor="primary_color evaulation-btn"
                                text="Evaluate"
                                onClick={() => {
                                  let modalProperties = {
                                    connection: startup?.connection,
                                    creative: startup?.creative,
                                    template: evaluationTemplate,
                                    group: group
                                  }
                                  setEvaluateModalProperties(modalProperties)
                                }}
                              />
                            )
                          }
                        </>
                      )
                    }

                    {
                      sharedSubjectiveScore?.score && (
                        <NotataButton
                          bgcolor="outlined score-btn"
                          text={sharedSubjectiveScore.score}
                          onClick={() => {
                            setSubjectiveScoreStartup(startup);
                            setSubjectiveScoreModal(true);
                          }}
                        />
                      )
                    }

                    {
                      !sharedSubjectiveScore?.score && (
                        <NotataButton
                          bgcolor="primary_color score-btn"
                          text="score"
                          onClick={() => {
                            setSubjectiveScoreStartup(startup);
                            setSubjectiveScoreModal(true);
                          }}
                        />
                      )
                    }

                  </div>

                </CardMessageContainer>
              )
            }


            {
              !hideSummaries && (
                <EvaluationSummaries
                  startup={startup}
                  group={group}
                  adminView={adminView}
                />
              )
            }

            {
              !hideDetails && (
                <EvaluationDetails
                  startup={startup}
                  group={group}
                  adminView={adminView}
                  setSummaryModalContent={setSummaryModalContent}
                  setEvaluateModalProperties={setEvaluateModalProperties}
                />
              )
            }

            {
              group?.settings?.chat && (
                <StartupComments
                  startup={startup}
                  group={group}
                  adminView={adminView}
                  refetch={refetch}
                />
              )
            }
          </>
        </CardComponent>

      </div>


      {
        subjectiveScoreModal && (
          <SubjectiveScoreModal
            connection={subjectiveScoreStartup?.connection}
            close={() => setSubjectiveScoreModal(false)}
            loading={shareScoreAddRes.loading}
            submit={async () => {
              let haveShareScore = subjectiveScoreStartup?.subjectiveScores?.find(({ isMe }) => isMe);
              if (!haveShareScore) {
                if (shareScoreAddRes.loading) {
                  return;
                }
                let variables = {
                  groupId: group.id,
                  creativeId: subjectiveScoreStartup?.creative?.id,
                };
                await shareScoreAdd({ variables });
                setSubjectiveScoreModal(false)
              } else {
                setSubjectiveScoreModal(false)
              }
            }}
          />
        )
      }

      {
        summaryModalContent && (
          <EvaluationSummaryModal
            evaluation={summaryModalContent}
            close={() => setSummaryModalContent(undefined)}
          />
        )
      }


      {/* EVALUATE MODAL */}
      {
        evaluateModalProperties && (
          <EvaluateModal
            creative={evaluateModalProperties?.creative}
            template={evaluateModalProperties?.template}
            evaluation={evaluateModalProperties?.evaluation}
            connectionId={evaluateModalProperties?.connection?.id}
            close={() => setEvaluateModalProperties(undefined)}
            save={saveEvaluation}
            loading={evaluationLoading}
          />
        )
      }

    </>


  );
}


