import gql from "graphql-tag";

export default gql`
  query connectionsGet($filters: ConnectionFilters, $LastEvaluatedId: String) {
    connectionsGet(filters: $filters, LastEvaluatedId: $LastEvaluatedId) {
      count
      records{
        id
        name
        createdAt
        updatedAt
        starred
        creativeId
        avgSubjectiveScore
        subjectiveScores {
          createdBy
          createdAt
          score
          isMe
        }

        creative {
          id
          name
          logo
          canEdit
          answers {
            questionName
            questionId
            val
          }
        }

        tags {
          tagGroupId
          id
          name
          group {
            id
            name
          }
        }

        funnelTags {
          id
          funnelGroupId
          name
          index
          color
          group {
            id
            name
            funnelTags {
              id
              index
              name
              color
            }
          }
        }
        evaluationSummaries {
          templateId
          templateName
          averagePercentageScore
        }
        groupSharingInfo {
          group {
            id
            name
          }
        }
      }
    }
  }
`;
