import gql from "graphql-tag";

import { userStatsFragments} from "../Fragments";

export default gql`
  query userStatsGet($email: String!, $days: Float) {
    userStatsGet(email: $email, days: $days) {
      ...userStatsFields
    }
  }
  ${userStatsFragments}
`;