import React, { useState, useEffect } from "react";

// API STUFF
import { useQuery } from "@apollo/client";
import { accountGet } from "../../../Apollo/Queries";

// UI COMPONENTS
import {
  Modal,
  BreadCrumbs,
  Loader,
  NotataButton,
  TextBox,
  CardComponent,
} from "Components/NotataComponents";

// OTHER COMPONENTS
import SettingsFunnel from "./SettingsFunnel";

// DEFINITIONS
import { settings } from "definitions";

// MODALS
import SettingsEditFunnel from "./settingsFunnelModals/SettingsEditFunnel";

// STYLES
import "./SettingsFunnels.scss";

//Main Function
export default function SettingsFunnels({ history }) {
  // States
  const [createModal, setCreateModal] = useState(false);
  const [newFunnel, setNewFunnel] = useState("");
  const [newFunnelVal, setNewFunnelVal] = useState("");
  const [isEditing, setIsEditing] = useState({});
  const [funnels, setFunnels] = useState([]);

  // Queries
  const { data, loading } = useQuery(accountGet);
  let funnelGroups = data?.accountGet?.funnelGroups;

  useEffect(() => {
    if (funnelGroups) {
      setFunnels(funnelGroups);
    }
  }, [funnelGroups]);

  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[{ seperator: ">", page1: "FUNNEL" }]}
      />

      <div className="settingsFunnels-container">
        <CardComponent
          containerClassName="settingsFunnels-container__card col-md-12"
          padding="large"
          title="Funnels"
          titleSize="medium"
          backLink={() => history.push(settings)}
          content={
            <>
              {/* FUNNELS */}
              <div className="settingsFunnels-container__funnels">
                {!data && loading && <Loader />}
                {funnels.map((funnelGroup, index) => {
                  if (!isEditing[funnelGroup.id]) {
                    return (
                      <SettingsFunnel
                        key={funnelGroup.id}
                        funnelGroup={funnelGroup}
                        setEdit={() => {
                          setIsEditing({
                            ...isEditing,
                            [funnelGroup.id]: true,
                          });
                        }}
                        dataTut={index === 0 ? "funnel-browse-0" : ""}
                      />
                    );
                  }

                  if (isEditing[funnelGroup.id]) {
                    return (
                      <SettingsEditFunnel
                        key={funnelGroup.id}
                        funnelGroup={funnelGroup}
                        save={() => {
                          setIsEditing({
                            ...isEditing,
                            [funnelGroup.id]: false,
                          });
                          setNewFunnelVal("");
                          setNewFunnel("");
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>

              {/* NEW FUNNEL */}
              {newFunnel && (
                <SettingsEditFunnel
                  funnelGroup={{
                    name: newFunnel,
                    funnelTags: [],
                    isNew: true,
                  }}
                  save={() => {
                    setIsEditing({});
                    setNewFunnelVal("");
                    setNewFunnel("");
                  }}
                />
              )}
            </>
          }
          footer={
            <NotataButton
              size="large"
              bgcolor="primary_color"
              startIcon="add"
              text="CREATE NEW FUNNEL"
              datatut="create-new-group"
              onClick={() => {
                if (newFunnel) {
                  return;
                }
                setCreateModal(true);
              }}
            />
          }
        />
      </div>

      {createModal && (
        <Modal
          title="Create new funnel"
          submit={() => {
            if (newFunnelVal?.length) {
              setNewFunnel(newFunnelVal);
            }
            setNewFunnelVal("");
            setCreateModal(false);
          }}
          close={() => {
            setNewFunnelVal("");
            setNewFunnel("");
            setCreateModal(false);
          }}
          submitTxt="Create"
          closeTxt="Cancel"
          children={
            <TextBox
              textBoxStyle="settingsFunnels-container__create-new-funnel-text"
              type="text"
              placeholder="Funnel Name"
              value={newFunnelVal}
              onChange={e => setNewFunnelVal(e.target.value)}
              autoComplete="off"
            />
          }
        />
      )}
    </>
  );
}
