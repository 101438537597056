import React from 'react'

import { sortArr, SvgComp } from "../../../commonFunctions";
import { Loader } from '../../../../../Components/NotataComponents';

import "./FunnelFilter.scss"

export default function FunnelFilter({funnelGroupArray, setFilters, filters}) {
  return (
    <>
      <form>
        <div className="right-sidebar-funnel-filter">
          <ul className='funnelUl'>
            {funnelGroupArray.length !== 0 && (
              funnelGroupArray.map((item,indx) =>
                item.funnelTags.length !== 0 && (
                  <div key={item.name} className="right-sidebar-funnel-filter__funnel-container">
                    {/* TODO: What do you mean "h6" - no one uses this */}
                    <h6 className="funnel_name right-sidebar-funnel-filter__funnel-container__funnel-name">
                      {item.name}
                    </h6>
                    {item.funnelTags.length &&
                    sortArr(item.funnelTags).map((data, index) => (
                      <li key={index}>
                        <div className="funnel_name right-sidebar-funnel-filter__funnel-container__item">
                          <input
                            type="radio"
                            name={data.name}
                            onChange={() =>
                              setFilters({ ...filters, funnelTag: data.id })
                            }
                            checked={data.id === filters?.funnelTag}
                            id={data.name}
                          />
                          <label for={data.name} />
                          <SvgComp index={index} color={data.color} />
                          <label for={data.name} className="right-sidebar-funnel-filter__stage-name" >
                            {data.name}
                          </label>
                        </div>
                      </li>
                    ))}
                  </div>
                )
              )
            )}
          </ul>
        </div>
      </form>
    </>
  )
}
