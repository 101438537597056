import React, {useState}  from 'react'

// API STUFF
import { useMutation } from "@apollo/client";
import { groupUserInvitationRemove, groupSettingsSet } from "private/Apollo/Mutations";
import { groupsGetV2 } from "private/Apollo/Queries";

import { omit } from "lodash";



async function removePublicUserInvitation({email, group, mutation}) {

  email = email.toLocaleLowerCase().trim()

  const [mutate, { loading }] = mutation;

  let publicSettings = {
    ...(publicSettings || {}),
    invitedUsers:
      (group?.publicSettings?.invitedUsers || []).filter(uMail => uMail !== email),
  }

  if (loading) return;

  try {
    let variables = {
      groupId: group.id,
      settings: omit(group?.settings, ["__typename", "isOpen"]),
      publicSettings: omit(publicSettings, [
        "__typename",
        "evaluationTemplate",
        "link",
        "qr_code",
        "groupId"
      ]),
    };
    await mutate({ variables });
  } catch (error) {
    console.log("error", error);
  }

}

// MAIN FUNCTION
export default function PendingPublicMembers({
    pendingMembers,
    group,
    canRemove,
    header,
    isPublic
  }) {


  // Mutations
  const mutation = useMutation(groupSettingsSet, {
    refetchQueries: [{ query: groupsGetV2 }],
    awaitRefetchQueries: true,
  });


  // STATES
  const [isRemovingInv, setIsRemovingInv] = useState({});

  // MUTATIONS
  const [invitationRemove] = useMutation(groupUserInvitationRemove);

  const removeInvitation = async(email) =>{

    if (isRemovingInv[email]) {
      return;
    }

    setIsRemovingInv({
      ...isRemovingInv,
      [email]: true,
    });

    try {
      await removePublicUserInvitation({ email, group, mutation });
    } catch (error) {
      console.log("error", error);
    }

    setIsRemovingInv({
      ...isRemovingInv,
      [email]: false,
    });

  }
  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">{header}</span>
      <div className="invite-member-modal__member-container__data">
        {pendingMembers?.map((member, index) => {
          let email = isPublic ? member : member.email;
          return (
            <div className="invite-member-modal__member-container__member" key={`group-memebr-key-${index}`}>
              {canRemove && (
                <i
                  className={`${isRemovingInv[email]
                    ? "fa fa-spinner fa-spin icon"
                    : "fas fa-trash-alt"
                  }`
                  }
                  aria-hidden="true"
                  onClick={() => removeInvitation(email)}
                />
              )}
              <div className="invite-member-modal__member-container__member__info">
                <div className="invite-member-modal__member-container__member__info__name">{email}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}