import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// API STUFF
import { useMutation } from "@apollo/client";
import {
  groupLogCreate,
  groupLogDelete,
  groupLogUpdate,
  groupLogMarkConversationAsSeen,
} from "private/Apollo/Mutations";

// COMMON COMPONENTS
import CommentBox from "Components/Common/CommentBox/CommentBox";

// CommentSection Function
export function CommentSection({ startup, group }) {

  // STATES
  const [comments, setComments ] = useState([]);

  // MUTATIONS
  const [addComment, { loading: createLoader }] = useMutation(groupLogCreate);
  const [deleteCommentMut] = useMutation(groupLogDelete);
  const [editComment, { loading : editLoader }] = useMutation(groupLogUpdate, { fetchPolicy: 'no-cache' });
  const [markConversationAsSeen] = useMutation(groupLogMarkConversationAsSeen);

  // FORMS
  const { setValue } = useForm();

  // EFFECTS
  useEffect(()=>{
    if(startup?.log){
      setComments(startup?.log);
    }
  }, [startup]);

  useEffect(() => {

    if (startup) {
      let variables = {
        groupId: group.id,
        creativeId: startup.creative.id,
      };
      markConversationAsSeen({ variables });
    }

  }, [startup])

  // Submit comment function
  async function submit(text) {
    // If loading or text is empty, return
    if (!text.length || createLoader) {
      return;
    }

    // Mutation variables
    let variables = {
      groupId: group.id,
      creativeId: startup.creative.id,
      input: {
        logType: "COMMENT",
        dataPairs: [
          {
            key: "text",
            val: text,
          },
        ],
      },
    };

    // Clear input field
    setValue("text", "");

    let {data:{groupLogCreate}} = await addComment({ variables });
      if(groupLogCreate){
        setComments(prevState=>([
          ...prevState,
          groupLogCreate
        ]))
      }
  }

  // Delete Comment
  const deleteComment = async (deleteId, index) => {
    if (!deleteId) {
      return;
    }
    try {
      let variables = {
        id: deleteId,
      };

      let {data : {groupLogDelete : {message} } } =  await deleteCommentMut({ variables });
      if(message){
        let commentCopy = [...comments];
        commentCopy.splice(index, 1);
        setComments(commentCopy);
      }
    } catch (error) {
      return console.log("error", error);
    }
  };

  // Update Comment
  const updateComment = async (commentId, newVal, index) => {
    let variables = {
      id: commentId,
      input: {
        logType: "COMMENT",
        dataPairs: [
          {
            key: "TEXT",
            val: newVal,
          },
        ],
      },
    };
    try {
      let {data : {groupLogUpdate : updatedComment }} = await editComment({ variables });
      if(updatedComment){
        let commentCopy = [...comments];
        commentCopy[index] = updatedComment;
        setComments(commentCopy);
      }
    } catch (error) {
      return console.log("error", error);
    }
  };
  return (
    <CommentBox
      comments={comments}
      loading={false}
      createLoader={createLoader}
      editLoader={editLoader}
      deleteComment={deleteComment}
      createComment={submit}
      updateComment={updateComment}
      inputTxt="commentTxt"
      coomentBox={comments.length<=1?"cmnt_sm":comments.length===2?"cmnt_md":comments.length===3?"cmnt_bg":comments.length>=4?"cmnt_lg":"cmnt_lg"}
    />
  )
}

// MAIN FUNCTION
export default function StartupComments({ startup, group, refetch }) {

  // STATES
  const [showCommentSection, setShowCommentSection] = useState(false);

  // MUTATIONS
  const [markConversationAsSeen] = useMutation(groupLogMarkConversationAsSeen);

  // MAPS AND REDUCERS
  let unreadComments = startup?.log?.filter(({ seen }) => !seen);


  return (
    <div className="group-startup-card__comment-section">
      <div className="startup-card-container__section_header">
        <i
          className={`startup-card-container__section_header__icon ${
            showCommentSection ? "fa fa-chevron-down" : "fa fa-chevron-up"
          }`}
          aria-hidden="true"
          onClick={async() => {
            if (!showCommentSection) {
              let variables = {
                groupId: group.id,
                creativeId: startup.creative.id,
              };
              const resp = await markConversationAsSeen({ variables });

              if(resp){
                refetch();
              }
            }
            setShowCommentSection(!showCommentSection);
          }}
        />
        <div className="group-startup-card__comment-section__heading">
          <span>{startup.log.length} comments – ({unreadComments?.length || 0} unread)</span>
        </div>
      </div>
      {/* CommentSection */}
      {showCommentSection && 
        <div className="startup-card-container__section">  
          <CommentSection startup={startup} group={group}  />
        </div>
      }
    </div>
  );
}