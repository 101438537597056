import React from "react";
import { useHistory } from "react-router-dom";


import "./TopMenuNotifications.scss";

import { useQuery } from "@apollo/client";
import { notificationsGet } from "private/Apollo/Queries";


import { Loader } from "../../NotataComponents";
import Notifications from "../../Common/Notifications/Notifications";

import { notification } from "definitions";

//Main Function
export default function TopMenuNotifications() {
  // QUERIES
  const { data, loading } = useQuery(notificationsGet);

  // DATA MAPS
  let notifications = data?.notificationsGet || [];
  const history = useHistory();



  return (
    <div className="notification-menu__dropdown" style={{ backgroundColor: "" }}>
        <div className="notification-menu__dropdown__sub_dropdown">
            <div className="notifications">
            <div className="notifications">
              {!data && loading && <Loader />}

              {!notifications.length && (
                <div className="no_notification">
                  You have no notifications
                </div>
              )}
              <Notifications
                notifications={notifications.slice(0, 4)}
                history={history}
              />
            </div>
          </div>
          <div
            className="notification-menu__dropdown__footer"
            onClick={() => history.push(notification)}
          >
            VIEW NOTIFICATIONS
          </div>
       
        </div>     
    </div>
  );
}
