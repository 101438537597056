import React, { useState, useEffect } from "react";

// UI COMPONENTS
import { Content } from "Components/NotataComponents/";

// STYLES
import "./Profile.scss";

// CUSTOM COMPONENTS
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";

// OTHERS
import { useTransition } from "react-spring";


import { dashboard, payment_page } from "definitions.js";
import { useHistory } from "react-router-dom";

// MAIN FUNCTIONS
export function ProfileContent({ skipLast }) {

  const history = useHistory()

  // STATES
  const [page, setPage] = useState(1);
  const [extraInputs, setExtraInputs] = useState({ interests: [], skills: [] });
  const [render, setRender] = useState(false);
  const [showData,setShowData] = useState(true)

  const transition = useTransition(page, {
    from: { x: -110, y: 0, opacity: 0, height: "auto" },
    enter: { x: 0, y: 0, opacity: 1 },
  });

  // EFFECTS
  useEffect(() => {
    setRender(!render);
  }, [page]);

  // // Function
  // const plusData = (addTags,tagValue) =>{
  //   return <button
  //   type="button"
  //   className="buttongreen_tags"
  //   onClick={() => addTags(tagValue)}
  // >
  //   <i className="fas fa-plus-circle" />
  // </button>
  // }


  return (
    <>
      {!skipLast &&
        <div className='tabs_section'>
          <div
            className={
              page === 1
                ? 'tabs_section_child_active'
                : 'tabs_section_child'
            }
            onClick={() => setPage(1)}
          />
          <div
            className={
              page === 2
                ? 'tabs_section_child_active'
                : 'tabs_section_child'
            }
            onClick={() => setPage(2)}
          />
          <div
            className={
              page === 3
                ? 'tabs_section_child_active'
                : 'tabs_section_child'
            }
            onClick={() => setPage(3)}
          />
        </div>
      }
      <div>
        {transition((style, item) =>
          item === 1 ? (
            <Page1
              setPage={setPage}
              skipLast
            />
          ) : item === 2 ? (
            <Page2
              skipLast={skipLast}
              setPage={no => {
                if (no === 3) {
                  let code = window.localStorage.getItem("app_sumo_code")
                  history.push(code ? payment_page : dashboard)
                } else {
                  setPage(no)
                }
              }}
            />
          ) : (
            item === 3 && (
              <Page3
                extraInputs={extraInputs}
                setExtraInputs={setExtraInputs}
                setPage={setPage}
                history={history}
              />
            )
          )
        )}
      </div>
    </>
  );
}

// Main function
export function PreProfile() {
  return (
    <div className='profile_onboard'>
      <div className='strip'>Great! You are in</div>
      <Content
        maxWidth={600}
        style={{
          padding: "20px",
          marginTop: "-20px",
        }}
      >
      <ProfileContent />
      </Content>
    </div>
  );
}
