import React from "react";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { groupsOpenGet, userGet } from "private/Apollo/Queries";
import { groupOpenJoin } from "private/Apollo/Mutations";
import { groupLeave } from "private/Apollo/Mutations";

// UI COMPONENTS
import { NotataButton, Loader } from "Components/NotataComponents";

// STYLES
import "./Group.scss";

// MAIN FUNCTION
export default function Group() {

  // Queries
  const openGroupsQuery = useQuery(groupsOpenGet);
  const userQuery = useQuery(userGet);
  const [leaveGroup, { loading }] = useMutation(groupLeave, {
    refetchQueries: [{ query: groupsOpenGet }],
    awaitRefetchQueries: true,
  });
  
  // Mutations
  const [joinOpenGroup, joinOpenGroupRes] = useMutation(groupOpenJoin);
  
  // Maps
  let openGroupsFromServer = openGroupsQuery?.data?.groupsPublicGet || [];
  let openGroups = openGroupsFromServer;
  let hasAllData = openGroupsQuery?.data && userQuery?.data;
  let isLoading = openGroupsQuery?.loading || userQuery?.loading;
  let showLoader = joinOpenGroupRes?.loading || (!hasAllData && isLoading) || loading;
  
  // FUNCTIONS
  async function leaveGroupNow(group) {
    if (loading) {
      return;
    }
    try {
      await leaveGroup({ variables: { id: group.id } });
    } catch (error) {
      console.log("error", error);
    }
  }
  
  return (
    <div className='group'>
      {showLoader && <Loader noPadding={true} />}
      {openGroups.map((group,indx) => (
        <div key={indx}>
          <div className='group_top'>
            <div className='button_container'>
              <NotataButton
                text={"+"}
                size="small1"
                bgcolor={group.iAmMember ? "primary_color" : "outlined"}
                onClick={() => {
                  if(group.iAmMember) {
                    leaveGroupNow(group)
                  } else {
                    let variables = {
                      id: group.id,
                    };
                    joinOpenGroup({ variables });
                  }
                  
                }}
              />
            </div>
            <div className='info_container'>
              <h2>{group.name}</h2>
              <p>
                by:{" "}
                <span>
                  {group.createdByUser.company ? 
                  group.createdByUser.company :
                  group.createdByUser.given_name+group.createdByUser.family_name  }
                  
                </span>
              </p>
            </div>
          </div>
          <div className='group_bottom'>
          </div>
        </div>
      ))}
    </div>
  );
}