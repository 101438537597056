import React from "react";

// UI COMPONENT
import { BreadCrumbs, CardComponent } from "Components/NotataComponents";

// COMPONENTS IMPORTED FROM OTHER PAGES
import { ProfileContent } from "../../LoginPages/PreProfile/Profile";

// DEFINITIONS
import { settings } from "../../../../definitions";

// STYLES
import "./SettingsProfile.scss";
import Page1 from "../../LoginPages/PreProfile/Page1";
import Page2 from "../../LoginPages/PreProfile/Page2";

// MAIN FUNCTION
export default function SettingsProfile({ history }) {
  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[{ seperator: ">", page1: "USER PROFILE" }]}
      />

      <div
        className="settingsProfile-container"
      >

        <div style={{maxWidth: "700px"}}>

          <CardComponent
            containerClassName="settingsProfile-container__card"
            padding="large"
            title="User profile"
            titleSize="medium"
            backLink={() => history.push(settings)}
          />

          <CardComponent
            containerClassName="settingsProfile-container__card"
            padding="large"
            titleSize="medium"
            // backLink={() => history.push(settings)}
            content={
              <Page1 skipLast />
            }
          />

          <CardComponent
            containerClassName="settingsProfile-container__card"
            padding="large"
            titleSize="medium"
            // backLink={() => history.push(settings)}
            content={
              <Page2 skipLast />
            }
          />

        </div>


      </div>


    </>
  );
}
