import { useEffect } from "react";
import { withRouter } from "react-router-dom";

let disableOn = ['/public/help_material']
export const ScrollToTop = props => {
  useEffect(() => {
    let disable = disableOn.some(d => d == props?.location?.pathname);
    if (!disable) {
      window.scrollTo(0, 0);
    }
  }, [props.location]);

  return props.children;
};

export default withRouter(ScrollToTop);
