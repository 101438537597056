import gql from "graphql-tag";

export default gql`
    fragment EvaluationAnswerFields on EvaluationV2Answer {
      inputType
      questionId
      sectionId
      sid
      val
    }
`