import React, { useEffect, useState } from "react";

// API STUFF
import { useQuery } from "@apollo/client";
import { publicGroupGet } from "public/Apollo/Queries";
import { userGet } from "private/Apollo/Queries"

// COMPONENTS
import { GhostLoader } from "Components/NotataComponents";
import StartupComponent from "./StartupComponent";
import GridView from "./GridView";
import AccessDenied from "./AccessDenied";

export default function Content({groupId}) {

  const [selectedStartUp, setSelectedStartUp] = useState({});
  const [viewStartup, setViewStartup] = useState(undefined)

  // Queries
  const queryOptions = { context: { clientName: "public" } };

  // Get user
  const userResp = useQuery(userGet);

  // Get group
  const { data, loading, error } = useQuery(publicGroupGet, {
    ...queryOptions,
    variables: { groupId }
  });


  // Maps
  const group = data?.publicGroupGet;

  // User
  const user = userResp?.data?.userGet;


  useEffect(() => {
    if (!user) { return }

    console.log('user is logged in... get group query from private API')

  }, [user])


  console.log('userResp', userResp)


  if (loading && !data) {
    return (
      <GhostLoader/>
    )
  }

  if (error) {
    return (
      <AccessDenied/>
    )
  }

  if (viewStartup) {
    window.scrollTo(0, 0);
    return (
      <StartupComponent
        group={group}
        creativeId={viewStartup}
        close={() => setViewStartup(undefined)}
      />
    )
  }

  return (
    <>
      <div className="group-plugin">
        <div className="group-plugin__content">
          <GridView
            setSelectedStartUp={setSelectedStartUp}
            group={group}
            user={user}
            setViewStartup={creativeId => {
              setSelectedStartUp(group.startups.find(({id}) => id === creativeId))
              setViewStartup(creativeId);
            }}
          />
        </div>
      </div>


    </>
  )
}