/* eslint-disable */
import React, { useState } from "react";

// UI COMPONENTS
import {NotataButton} from "Components/NotataComponents";

// STYLES
import "../LoginPages.scss";

// ASSETS
import notata from "assets/images/auth-logo.svg";
import { useHistory } from "react-router-dom";

// MAIN FUNCTION
export function Message({ heading, subHead1, subHead2, path, image, buttonText }) {

  const history = useHistory()

  // Navigation Function
  const redirectlogin = () => {

    if (!path) {
      return window.location.href = "https://notata.io";
    }

    if (path === "/login") {
      return window.location.href = path;
    }

    history.push(path);

  };

  return (
    <>
      <div className='auth_structure'>
        <div className='auth_structure__left'>
          <div className='auth_structure__left__mainContent'>
            <div className='logoContainer'>
              {/* logo */}
              <img
                src={notata}
                style={{ width: "40px", height: "40px", marginBottom: "15px" }}
                alt="logo"
              />
              <h1>{heading}</h1>
              <p> {subHead1} </p>
              <p
                style={{
                  margin: "25px 0",
                  lineHeight: "18.05px",
                  fontSize: "15px",
                  color: "#969BA3",
                }}
              >
                {subHead2}
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
            <NotataButton
                size="xx_large"
                bgcolor="primary_color"
                text={buttonText || "Next"}
                buttonType="submit"
                onClick={redirectlogin}
              />
            </div>
          </div>
        </div>
        <div className='auth_structure__right'>
          <img src={image} alt="man_standing" />
        </div>
      </div>
    </>
  );
}