import React, { useState } from "react";

// Library Components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Styles
import "./Tabsection.scss";
import "../../../styles/style.scss";

export function Tabsection({ tabValue, tabArr, tabFuc }) {
  const [value, setValue] = useState(tabValue);

  const handleTabChange = val => {
    setValue(val);
    if (tabFuc) {
      tabFuc(val);
    }
  };

  return (
    <Tabs
      value={value}
      onChange={handleTabChange}
      className={"tab-overflow"}
      aria-label="disabled tabs example"
    >
      {tabArr.length &&
        tabArr.map((item, index) => (
          <Tab
            key={index}
            onClick={() => handleTabChange(item.value)}
            value={item.value}
            label={item.text}
            style={{ color: item.value === tabValue ? "#969BA3" : "#c9ccd1" }}
          />
        ))}
    </Tabs>
  );
}
