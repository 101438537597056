import React, {useState} from "react";

// API STUFF
import { useQuery, useMutation } from "@apollo/client";
import { evaluationTemplatesGet } from "private/Apollo/Queries";
import { groupTemplateAdd, groupTemplateRemove } from "private/Apollo/Mutations";

// UI COMPONENTS
import { Dropdown, Modal, Loader } from "Components/NotataComponents";

// STYLES
import "./ManageTemplatesModal.scss";

// EachTemplate function
function EachTemplate({ template, groupId, canRemoveTemplate }) {

  // MUTATIONS
  let [mutate, { loading }] = useMutation(groupTemplateRemove);

  return (
    <div className="group-template">
      {canRemoveTemplate && (
        <i
          className={loading ? "fa fa-spinner fa-spin" : "fa fa-times"}
          aria-hidden="true"
          onClick={() => {
            if (loading) {
              return;
            }
            mutate({
              variables: {
                groupId,
                templateId: template.id,
              },
            });
          }}
        />
      )}
      {template.name}
    </div>
  );
}

// MAIN FUNCTION
export default function ManageTemplatesModal({ group, close }) {

  const [selected, setSelected] = useState({})

  // CONSTANTS
  let canAddTemplate = group.iAmAdmin;
  let canRemoveTemplate = group.iAmAdmin;

  // Queries
  let { data, loading } = useQuery(evaluationTemplatesGet);

  // Mutations
  let [templateAdd, addRes] = useMutation(groupTemplateAdd);

  // MAPS AND REDUCERS
  let groupTemplates = group?.evaluationTemplates || [];
  let myTemplates = data?.accountGet?.evaluationTemplates || [];
  // Remove already added templates from array
  myTemplates = myTemplates.filter(
    ({ id }) => !groupTemplates.some(g => g.id === id)
  );


  return (
    <Modal
      title="Manage Templates"
      submit={async ()=>{

        if (!selected) {
          return
        }

        if (groupTemplates.find(({id}) => id === selected?.id)) {
          return
        }

        await templateAdd({
          variables: {
            groupId: group.id,
            templateId: selected.id,
          },
        });
        setSelected({})
        close()
      }}
      close={close}
      loading={addRes.loading}
      submitTxt="OK"
      closeTxt="CLOSE"
      children={
        <div className="row manage-template-container">

          {
            (!data && loading) && (
              <div className="col-sm-12">
                <Loader/>
              </div>
            )
          }

          {
            !(!data && loading) &&
            groupTemplates.length !== 0 && (
              <div className="col-sm-12 selected-templates">

                <div className="heading">Group templates</div>


                {
                  !(!data && loading) && (
                    <>
                      {groupTemplates.map(template => {
                        return (
                          <div className="group-templ">
                            <EachTemplate
                              key={template.id}
                              template={template}
                              groupId={group.id}
                              canRemoveTemplate={canRemoveTemplate}
                            />
                          </div>
                        );
                      })}
                    </>
                  )
                }

              </div>
            )
          }


          {
            !(!data && loading) &&
            groupTemplates.length === 0 && (
              <div className="col-sm-12 add-tenmplate">
                <div className="heading add-head">Add Template</div>
                <Dropdown
                  title="Template Name"
                  placeholder = "Select Template"
                  items={myTemplates}
                  setSelectedItem={item => {
                    // let variables = {
                    //   groupId: group.id,
                    //   templateId: selected.id,
                    // }
                    // templateAdd({variables});
                    setSelected(item)
                  }}
                />
              </div>
            )}
        </div>
      }
    />
  );
}