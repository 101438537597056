import gql from "graphql-tag";

export default gql`
  fragment publicSharingFields on PublicSharing {
        id
        sharedWithEmail
        message
        createdAt
        creativeTemplate {
          id
          name
          description
          headerMessageInvited
          headerMessageWebForm
          successMessageInvited
          successMessageWebForm
          sections {
            id
            noEdit
            index
            name
            description
            questions {
              id
              noEdit
              index
              name
              description
              inputType
              options {
                noEdit
                index
                val
                sid
              }
            }
          }
        }
        creative {
          id
          name
          accountId
          sharedWithEmail
          logo
          canEdit
          answers {
            inputType
            sectionId
            sectionName
            questionId
            questionName
            questionId
            val
            sid
          }
        }
        sharedByUser{
            email
            given_name
            isMe
            family_name
        }
        publicEvaluation{
          id
          createdAt
          isMe
        }
        sharedEvaluation{
            id
            templateId
            template{
                name
                sections {
                  name
                }
            }
            newAnswers{
              sectionName
              questions {
                  questionName
                  answers
                  comment
              }
            }
            answers {
              id
              questionName
              val
            }
            summary {
              sections {
                sectionId
                sectionName
              }
            }
        }
        evaluationTemplate{
          name
          id
        }
        opened
        lastOpened
  }
`;
