import React, { useState } from "react";
import { Link } from "react-router-dom";

// UI COMPONENTS
import { NotataButton } from "Components/NotataComponents";

// Custom Component
import ArchiveModal from "../OverviewModals/ArchiveModal";
import DeleteModal from "../OverviewModals/DeleteModal";

// STYLES
import "./ArchiveAndDeleteSection.scss";
import "./ArchiveAndDelete.scss";

//DEFINITIONS
import { archive_page } from "definitions";
import { startup_print_page } from "definitions";

// MAIN FUNCTION
export default function ArchiveAndDeleteSection({
  connection,
  history,
  containerClass,
}) {
  // STATES
  const [archiveModal, setArchiveModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <>
      <div className={`archive_and_delete__container ${containerClass}`}>
        {/* Archive Button */}
        <NotataButton
          size="x_large--res"
          bgcolor="primary_color"
          text="ARCHIVE STARTUP"
          onClick={() => setArchiveModal(true)}
        />

        {/* Open Archive Button */}
        <div className="open-archive">
          <Link to={`${archive_page}`} className="open_archive_decoration">
            <NotataButton
              size="x_large--res"
              bgcolor="primary_color"
              text="Open Archive"
            />
          </Link>
        </div>

        {/* Delete Startup Permanently Button */}
        <NotataButton
          size="x_large--res"
          bgcolor="secondary_color"
          text="DELETE STARTUP PERMANENTLY"
          onClick={() => setDeleteModal(true)}
        />

        <div>
          <Link
            to={`${startup_print_page}/${connection.id}`}
            target="_blank"
          >
            View printable version <i className="fal fa-print"/>
          </Link>
        </div>

      </div>




      {/* Archive Modals */}
      {archiveModal && (
        <ArchiveModal
          connection={connection}
          history={history}
          close={() => {
            setArchiveModal(false);
          }}
        />
      )}

      {/* Delete Modals */}
      {deleteModal && (
        <DeleteModal
          connection={connection}
          history={history}
          close={() => {
            setDeleteModal(false);
          }}
        />
      )}
    </>
  );
}
