import gql from "graphql-tag";

export default gql`
  query publicSharingGet($id: ID!, $email: String!) {
    publicSharingGet(id: $id, email: $email) {
        id
        sharedWithEmail
        message
        createdAt
        
        creativeTemplate {
          id
          name
          description
          headerMessageInvited
          headerMessageWebForm
          successMessageInvited
          successMessageWebForm
          sections {
            id
            noEdit
            index
            name
            description
            questions {
              id
              noEdit
              index
              name
              description
              inputType
              options {
                noEdit
                index
                val
                sid
              }
            }
          }
        }
        publicEvaluation{
          id
          name
          description
          createdAt
          createdBy
          templateId
          answers{
            inputType
            questionId
            questionName
            sectionId
            sectionName
            sid
            val
          }
          template{
            id
            name
            description
          }
          createdByUser{
            email
            given_name
            family_name
            company
          } 
        }
    
        sharedByUser {
          email
          given_name
          family_name
        }
        
        
        evaluationTemplate {
          name
          id
        }
        
        
        creative {
          id
          name
          sharedWithEmail
          answers {
            inputType
            sectionId
            sectionName
            questionId
            questionName
            questionId
            val
            sid
          }
        }
    
        sharedEvaluation{
          id
          templateId
          template{
              name
              sections {
                name
              }
          }
          answers {
            id
            questionName
            val
          }
            
          newAnswers {
              sectionName
              questions {
                  questionName
                  answers
                  comment
              }
          }
      }
    }
  }
`;
