import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

// CUSTOM COMPONENTS
import { NotataList } from "Components/NotataComponents";

// DEFINITIONS
import { evaluation_summary_page } from "definitions";

// STYLING
import "./EvaluationListByTemplate.scss";


export default function PublicEvaluationListByTemplate({
    group,
    summary,
    evaluations,
    connection,
    setSummaryModalContent
  }) {

  const history = useHistory()

  let tableHeader = [

    { // Template name
      content: (
        <div>{summary?.templateName}</div>
      )
    },

    { // Submissions
      style: {
        width: "150px",
        textAlign: "center"
      },
      content: (
        <div>
          {summary?.submissions} Submissions
        </div>
      )
    },

    { // Score
      style: {
        width: "70px",
        textAlign: "center"
      },
      content: (
        <div>
          {summary?.averagePercentageScore}%
        </div>
      )
    },

    { // Icon
      style: {
        width: "30px",
        color: "var(--ui-color-primary-green-dark4)",
        cursor: "pointer"
      },
      content: (
        <span/>
      )
    }
  ]

  let tableBody = (evaluations || []).map(evaluation => [
    {
      content: (
        <div>
          {evaluation?.createdByEmail}
        </div>
      )
    },
    {
      content: (
        <div>
          {moment(evaluation.createdAt).format("ll")}
        </div>
      )
    },
    {
      content: (
        <div>{evaluation?.summary?.scorePercent || 0}%</div>
      )
    },
    {
      content: (
        <div>
          <i
            className="fa fa-eye green-eye"
            aria-hidden="true"
            style={{cursor: "pointer"}}
            onClick={() => {
              setSummaryModalContent(evaluation)
            }}
          />
        </div>
      )
    }

  ])

  return (
    <div className="evaluation-list">
      <NotataList
        header={tableHeader}
        body={tableBody}
      />
    </div>
  );
}