import React, { useEffect, useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { evaluationTemplateUpdate } from "private/Apollo/Mutations";

// COMPONENTS
import {TextBox, Modal} from "Components/NotataComponents";


// MAIN FUNCTION
export default function EditTemplateModal({ close, template }) {
  // STATES
  const [name, setName] = useState("");

  // UpdateEvaluate Functions
  const updateEvaluate = async () => {
    try {
      let variables = {
        id: template?.id,
        input: { name },
      };
      await updateTemplate({ variables });
    } catch (error) {
      console.log("error", error);
    }
    close();
  }

  // EFFECTS
  useEffect(() => {
    if (template.name) {
      setName(template.name);
    }
  }, [template]);

  // MUTAIONS
  const [updateTemplate, updateTemplateRes] = useMutation(
    evaluationTemplateUpdate
  );

  return (
    <Modal
      title="Update Evaluation Template"
      loading={updateTemplateRes.loading}
      submit={updateEvaluate}
      close={close}
      submitTxt="Save"
      closeTxt="Cancel"
      children={
        <TextBox
          name="name"
          value={name}
          textBoxStyle="funnels-container__create-new-funnel-text"
          onChange={e => setName(e.target.value)}
          placeholder="Evaluation Template Name"
          onKeyDown={e=>{
            if(e.key === "Enter"){
              updateEvaluate();
            }
          }}
        />
      }
    />
  );
}
