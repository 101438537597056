import React, { useState, useEffect } from "react";
import "./InputForm.scss";
import classnames from "classnames";
import { getErrorMessage, storngPwd } from "./validation";

// Main function
export function InputForm({
  fullWidth,
  type,
  label,
  placeholder,
  name,
  required,
  position,
  setNextFlag,
  validate,
  reference,
  disabled,
  passwordConfirm,
  errorMessage,
  handleInputChange,
  handleKeyDown,
  primaryPwdVal,
  storngPswd,
  value,
  defaultValue
}) {
  // States
  const [error, setError] = useState(false);
  const [passFlag, setPassFlag] = useState(false);
  const [passStyle, setPassStyle] = useState(false);
  const [placeholderVal, setPlaceholderVal] = useState(
    placeholder || "Say something..."
  );

  const [shouldValidate, setShouldValidate] = useState(false);

  const inputRef = reference;

  useEffect(() => {
    if (position && position === type) {
    }
  }, [position, type]);

  useEffect(() => {
    if (validate) {
    }
  }, [validate]);

  // Blur function (form function)
  function handleBlur(e) {
    setPlaceholderVal(placeholder || "placeholder...");
    if (e.target.value === "") setPassStyle(false);
    validateFormInput(e.target.value.trim());
    // setShouldValidate(true);
  }

  // Change function (form function)
  function handleChange(e) {
    // passwordConfirm match testing
    if (handleInputChange) {
      handleInputChange(e.target.value, e.target.name);
      if (e.target.value === "") setPassStyle(false)
    }

    if (shouldValidate) {
      validateFormInput(e.target.value.trim());
    }
    if (type === "password" && !passFlag) setPassStyle(true);
  }

  //KeyDown
  async function handleKeyDown(e) {
    if (e.keyCode === 13 && handleKeyDown) {
      if (
        getErrorMessage({
          value: e.target.value.trim() || "",
          type,
          required,
          passwordConfirm,
          primaryPwdVal
        }) === false &&
        error === false
      )
        if (setNextFlag) {
          setNextFlag(type);
        } else validateFormInput(e.target.value.trim());
    } else {
    }
  }

  function keyPress(e) {
    if (e.keyCode === 13) return true;
    const charCode = e.which ? e.which : e.keyCode;
    if (type === "number") {
      if (charCode <= 32 || charCode === 43 || (charCode > 47 && charCode < 58))
        return true;
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  // Validate form input
  const validateFormInput = value => {
    // Check for error
    let errorMessage = getErrorMessage({
      value,
      type,
      required,
      passwordConfirm,
      primaryPwdVal,
    });

    // If error, set error message
    setError(errorMessage);
  };

  return (
    <div className="login-container">
      {error === storngPwd && type === "password" && storngPswd && !passwordConfirm && (
        <p className={true ? "login-container__inputError" : "inputGrayError"}>
          <i className="fa fa-exclamation-circle"></i>
          <span>{error}</span>
        </p>
      )}
      {label && <label className="login-container__input_label">{label}</label>}      <div
        className={classnames(
          fullWidth
            ? "login-container__input_container input_log_cont"
            : "login-container__inputDefaultWidth",
          "login-container__input_container input_log_cont",
          error && "login-container__redStyle",
          !passFlag && passStyle && "input_pass_div",
          disabled && "input_disable"
        )}
      >
        <input
          type={
            passFlag || type === "email" || type === "number" || type === "url"
              ? "text"
              : type
          }
          className={classnames(
            "login-container__input_class",
            passStyle && "input_pass"
          )}
          name={name}
          placeholder={placeholderVal}
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onKeyPress={keyPress}
          onFocus={() => setPlaceholderVal("")}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          disabled={disabled}
          value={value}
          defaultValue={defaultValue}
        />

        {type === "password" && (
          <i
            style={{ fontSize: "15px", color: "#c4c4c4" , cursor: "pointer"}}
            className={classnames(
              "fal",
              passFlag && "fa-eye-slash",
              !passFlag && "fa-eye"
            )}
            onClick={() => {
              setPassFlag(!passFlag);
            }}
          />
        )}
      </div>
      {(error || errorMessage) && (
        <p
          className={"login-container__valError"}
        >
          {errorMessage ? errorMessage : error === storngPwd ? "" : error}
        </p>
      )}
    </div>
  );
}
