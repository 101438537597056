import React from "react";

// UI COMPONENTS
import { BreadCrumbs, Card, CardComponent } from "Components/NotataComponents";

// STYLES
import "./Settings.scss";
import "../public.scss";

// DEFINITIONS
import {
  evaluation_templates_page,
  tags1,
  funnels1,
  web_form,
  your_team,
  notification,
  setting_profile, payment_page
} from "../../../definitions";

// ASSETS
import evaluationTemplate from "assets/images/settings_evaluations-template.svg";
import notifications from "assets/images/settings_notifications.svg";
import webForm from "assets/images/settings_web-form.svg";
import tags from "assets/images/settings_tags.svg";
import yourProfile from "assets/images/settings_your-profile.svg";
import yourTeam from "assets/images/settings_your-team.svg";
import { Link } from "react-router-dom";

// MAIN FUNCTION
export default function Settings() {
  const datas = [
    {
      img: evaluationTemplate,
      data_tut: "evaluation-templates",
      link: evaluation_templates_page,
      name: "Evaluation templates",
    },
    {
      img: tags,
      data_tut: "setting-tags",
      link: tags1,
      name: "Tags",
    },
    {
      img: tags,
      data_tut: "setting-funnels",
      link: funnels1,
      name: "Funnels",
    },
    {
      img: webForm,
      data_tut: "setting-web-form",
      link: web_form,
      name: "Web Form",
    },
    {
      img: yourTeam,
      data_tut: "setting-your-team",
      link: your_team,
      name: "Your Team",
    },
    {
      img: yourProfile,
      data_tut: "setting-user-profile",
      link: setting_profile,
      name: "User Profile",
    },
    {
      img: evaluationTemplate,
      data_tut: "settings-plans-and-billing",
      link: payment_page,
      name: "Plans and Billing",
    },
    {
      img: notifications,
      data_tut: "setting-notifications",
      link: notification,
      name: "Notifications",
    },
  ];
  return (
    <>
      <BreadCrumbs routePage={`settings`} />
      <div className="settings-container">
        <CardComponent
          containerClassName="settings-container__card"
          title="Settings"
          titleSize="large"
          padding="large"
          content={
            <div>
              <div className="settings-container__card__separator" />
              <div className="settingsMenu-container">
                {datas.map((item, index) => {
                  return (
                    <div
                      className="settingsMenu-container__menu"
                      data-tut={item.data_tut}
                      key={index}
                    >
                      <img src={item.img} alt="" />
                      <div className="settingsMenu-container__menu__name">
                        <Link to={item.link}>{item.name}</Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}
