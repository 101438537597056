import React, { useState } from "react";
import './Admin.scss';
import { NotataDatepicker, NotataButton, InputForm } from "Components/NotataComponents";
import { useQuery, useLazyQuery } from "@apollo/client";
import { userAnalyticsGet, userStatsGet } from "../../Apollo/Queries";

import moment from "moment";
import { useForm } from "react-hook-form";

// Main Function
export default function Admin() {

    const { register, handleSubmit } = useForm();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [email, setEmail] = useState("");

    const userAnalytics = useQuery(userAnalyticsGet, {
        variables: { fromDate: startDate, toDate: endDate }
    });

    const [getUserStats, { called, data, loading, error }] = useLazyQuery(userStatsGet);

    const { usersActivityCount = 0, usersActivityList, usersSignupCount = 0, usersSignupList, usersTotal = 0 } = userAnalytics?.data?.userAnalyticsGet || {};
    const [isCalendarVisible, setCalendarVisible] = useState(false);
    const [userLoggedInList, setUserLoggedInList] = useState(false);
    const [userSignupList, setUserSignupList] = useState(false);
    const onChange = dates => {
        const [start, end] = dates;
        let fromDate = start ? moment(start).format("x") : "";
        let toDate = end ? moment(end).format("x") : "";

        if (fromDate && toDate) {
            //setFilters({ ...filters, fromDate, toDate });
        }
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setCalendarVisible(false);
        }
    };
    const dateRange = (dates, range) => {
        let fromDate = range[0] ? moment(range[0]).format("x") : "";
        let toDate = range[1] ? moment(range[1]).format("x") : "";
        if (fromDate && toDate) {
            //setFilters({ ...filters, fromDate, toDate });
        }
        setStartDate(range[0]);
        setEndDate(range[1]);
        if (fromDate && toDate) {
            setCalendarVisible(false);
        }
    };

    const { given_name } = data?.userStatsGet?.user || {};

    const userStats = data?.userStatsGet;

    async function onSubmit(e) {

        await getUserStats({ variables: { email } })
    }

    // const handleInputChange = (e) => {
    //     let val = e.target.value?.toLocaleLowerCase().trim();
    //     setEmail(val)
    // }
    const handleInput = val => {
        setEmail(val);
    }

    return (
        <div className="admin-container">
            <h4 className="admin-container__heading">The Secret Side</h4>
            <div className="row">
                <div className="card">
                    <div className="card-heading">GENERAL REPORT</div>
                    <div className="admin-container__fromToDate">
                        <h2>DATE</h2>
                        <NotataDatepicker
                            selected={startDate}
                            setCalendarVisible={setCalendarVisible}
                            onChange={onChange}
                            dateRange={dateRange}
                            isCalendarVisible={isCalendarVisible}
                            startDate={startDate}
                            endDate={endDate}
                            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
                        />
                    </div>
                    <div className="admin-container__stats">
                        <div className="admin-container__stats__heading">Total users in database (from begining of time)</div>
                        <div className="admin-container__stats__value">{usersTotal}</div>
                    </div>
                    <div className="admin-container__stats">
                        <div className="admin-container__stats__heading">New user signed up</div>
                        <div className="admin-container__stats__value">{usersSignupCount}</div>
                        <div className="admin-container__stats__list">
                            <div className="admin-container__stats__list__view" onClick={() => setUserSignupList(!userSignupList)}>
                                <span><i className={`${userSignupList ? 'fa fa-angle-up' : 'fa fa-angle-right'}`} aria-hidden="true" /> View</span>
                            </div>
                            {userSignupList &&
                                <div className="admin-container__stats__list__data">
                                    {usersSignupList?.map((activity, index) => {
                                        return <div key={`activity-key-${index}`} className="admin-container__stats__list__data__user"> {activity.given_name} {activity.family_name} ({activity.email})</div>
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="admin-container__stats">
                        <div className="admin-container__stats__heading">Users logged in</div>
                        <div className="admin-container__stats__value">{usersActivityCount}</div>
                        <div className="admin-container__stats__list">
                            <div className="admin-container__stats__list__view" onClick={() => setUserLoggedInList(!userLoggedInList)}>
                                <span><i className={`${userLoggedInList ? 'fa fa-angle-up' : 'fa fa-angle-right'}`} aria-hidden="true" /> View</span>
                            </div>
                            {userLoggedInList &&
                                <div className="admin-container__stats__list__data">
                                    {usersActivityList?.map((activity, index) => {
                                        return <div key={`activity-key-${index}`} className="admin-container__stats__list__data__user"> {activity.given_name} {activity.family_name} ({activity.email})</div>
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="card">
                    <div className="card-heading">SPECIFIC REPORT</div>
                    <div className="admin-container__email">
                        {/* <div className="admin-container__stats__heading">Email</div> */}


                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div className='input-div'>
                                {/* <input
                                    className='admin-input'
                                    type="text"
                                    ref={register}
                                    name="email"
                                    onChange={e => {
                                        let val = e.target.value?.toLocaleLowerCase().trim();
                                        setEmail(val)
                                    }}
                                /> */}
                                <InputForm
                                    label="Email"
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    handleInputChange={(value) =>
                                        handleInput(value)
                                    }
                                    reference={register({ required: true })}
                                />
                                <div className='go-btn'>
                                    <NotataButton
                                        size="medium"
                                        bgcolor="primary_color"
                                        text={loading ? <i className="fa fa-spinner fa-spin search-spinner" /> : "GO!"}
                                        buttonType="submit"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                    {
                        (called && !loading && !userStats) && (
                            <div>
                                User not found
                            </div>
                        )
                    }

                    {
                        userStats && (
                            <>
                                <div className="admin-container__stats">
                                    <div className="admin-container__stats__heading">Last login</div>
                                    <div className="admin-container__stats__value">{userStats.lastLogin || ""}</div>
                                </div>
                                <div className="admin-container__stats">
                                    <div className="admin-container__stats__heading">{given_name} has this many <span className="strong">members</span> in his/her team</div>
                                    <div className="admin-container__stats__value">{userStats.membersOnAccountCount || 0}</div>
                                </div>
                                <div className="admin-container__stats">
                                    <div className="admin-container__stats__heading">{given_name} has this many <span className="strong">startups</span> in his/her deal flow</div>
                                    <div className="admin-container__stats__value">{userStats.connectionAccountOfUserCount || 0}</div>
                                </div>
                                <div className="admin-container__stats">
                                    <div className="admin-container__stats__heading">{given_name} has <span className="strong">invited</span> this many startups to fill out their <span className="strong">company information</span></div>
                                    <div className="admin-container__stats__value">{userStats.startupsInvitationCount || 0}</div>
                                </div>
                                <div className="admin-container__stats">
                                    <div className="admin-container__stats__heading">{given_name} has <span className="strong">joined</span> this many <span className="strong">groups</span></div>
                                    <div className="admin-container__stats__value">{userStats.joinedGroupsByUserCount || 0}</div>
                                </div>
                                <div className="admin-container__stats">
                                    <div className="admin-container__stats__heading">{given_name} has <span className="strong">created </span>this many <span className="strong">groups</span></div>
                                    <div className="admin-container__stats__value">{userStats.createdGroupsByUserCount || 0}</div>
                                </div>
                                <div className="admin-container__stats">
                                    <div className="admin-container__stats__heading">{given_name} has <span className="strong">invited</span> this many people to join his/her <span className="strong">groups</span></div>
                                    <div className="admin-container__stats__value">{userStats.peopleInvitedToGroupByUserCount || 0}</div>
                                </div>
                            </>
                        )
                    }



                </div>
            </div>
        </div>
    )
}