import gql from "graphql-tag";

export default gql`
  fragment reportSettingsFields on ReportSettings {
    dashboardFunnel
        dashboardTagGroup
        
        funnelGroup
        tagGroups {
            groupId
            graphType
        }
        
  }
  `;