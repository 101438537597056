import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import {
  connectionsGet
} from "private/Apollo/Queries";
import moment from "moment";
import jsonToCsv from "utils/jsonToCsv";
import downloadAsFile from "utils/downloadAsFile";
import "./DownloadAsCsv.scss"

export default function DownloadAsCsv({columnValues, filters}) {


  const [ triggered, setTriggered ] = useState(false)

  const variables = {
    filters: {
    ...filters,
        limit: 10000
    }
  }
  const {refetch} = useQuery(connectionsGet, {
    variables,
    skip: !triggered
  })

  async function initiateDownload() {

    // Stop if we are already running
    if (triggered) { return }

    // Set trigger to true
    setTriggered(true)

    // Records
    let records = [];

    // Get data from server
    try {
      let res = await refetch(variables);
      records = res?.data?.connectionsGet?.records || [];
    } catch (error) {
      console.log('error', error)
      setTriggered(false)
      return
    }

    // Compile...
    let rdata = [];
    for (let record of records) {
      let item = {
        ["Name"]: record.creative.name,
        ["Starred"]: record.starred === true ? "true" : "false"
      }

      if (columnValues.groups) {
        item["Groups"] = record.groupSharingInfo.map(({group}) => group.name)
      }

      if (columnValues.createdAt) {
        item["Created at"] = moment(record.createdAt).format("ll")
      }

      if (columnValues.updatedAt) {
        item["Updated at"] = moment(record.updatedAt).format("ll")
      }

      if (columnValues.funnels) {

        let tagsByGroup = {}

        for (let fTag of record.funnelTags) {
          tagsByGroup[fTag.group.name] = tagsByGroup[fTag.group.name] || [];
          tagsByGroup[fTag.group.name].push(fTag)
        }

        for (let groupName in tagsByGroup) {
          const tags = tagsByGroup[groupName];

          // Get the highest index
          const max = Math.max(...tags.map(tag => tag.index));

          // Get the item with the highest index
          const highest = tags.find(tag => tag.index === max);
          item[`Funnel: ${groupName}`] = highest.name
        }

      }

      if (columnValues.tags) {
        item["Tags"] = record.tags.map(t => `${t.group.name}: ${t.name}`)
      }

      if (columnValues.subjectiveScore) {
        item["Subjective score"] = record.avgSubjectiveScore
      }

      if (columnValues.tagGroups?.length !== 0) {
        for (let tagGroupId of columnValues.tagGroups) {
          let tags = record.tags.filter(it => it.tagGroupId === tagGroupId);
          if (tags.length !== 0) {
            item[`Tag: ${tags[0].group.name}`] = tags.map(({ name }) => name)
          }
        }
      }

      if (columnValues.evaluationTemplates?.length !== 0) {
        for (let templateId of columnValues.evaluationTemplates) {
          let template = record.evaluationSummaries.find(s => s.templateId === templateId);
          if (template) {
            item[`Evaluation: ${template.templateName}`] = template.averagePercentageScore
          }
        }
      }

      rdata.push(item)

    }

    // Find all unique keys
    let allKeys = {};

    rdata.forEach(d => {
      for (let key in d) {
        allKeys[key] = true
      }
    })

    // Make sure that all items have all keys
    rdata = rdata.map(d => {
      let n = {};
      for (let key in allKeys) {
        n[key] = d[key]
      }
      return n;
    })

    let csv = jsonToCsv(rdata)
    downloadAsFile(
      csv,
      `${new Date().getTime()}.csv`
    )

    setTriggered(false)
  }


  return (
    <div>
      <div
        onClick={initiateDownload}
        className="download-as-svg"
      >
        {
          !triggered && (
            <i
              className="fal fa-cloud-download download-as-svg__cloud-button"
            />
          )
        }

        {
          triggered && (
            <i
              className="fa fa-spinner fa-spin download-as-svg__loader-button"
            />
          )
        }

      </div>
    </div>
  )


}