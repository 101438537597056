import React from "react";

// Styles
import "./Content.scss"

// Main Function
export const Content = ({ maxWidth, center, className, ...children }) => 
    <div
      className={`
        content__container
        ${center && "content__center_contents"},
        ${className && className}
      `}
      style={{ maxWidth: `${maxWidth}px` }}
    >
      <div className="content__contents" {...children} />
    </div>