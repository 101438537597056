import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import { groupGetV2 } from "private/Apollo/Queries";
import {
  evaluationUpdate,
  evaluationCreate,
  groupEvaluationAdd
} from "private/Apollo/Mutations";

// UI COMPONENTS
import {
  BrowseDropDown,
  NotataButton,
  CardComponent,
  Modal
} from "Components/NotataComponents";

// COMMON COMPONENTS
import CreateGroup from "Components/Common/Modals/CreateGroup/CreateGroup";

// CUSTOM COMPONENTS
import CardMessageContainer from "../../../Components/CardMessageContainer";

// MODALS
import DeleteGroupModal from "../../../Modals/DeleteGroupModal";
import LeaveGroupModal from "../../../Modals/LeaveGroupModal";
import GroupSettingsModal from "../../../Modals/GroupSettingsModal";
import EditGroupModal from "../../../Modals/EditGroupModal";
import SyncModal from "../../../Modals/SyncModal";

// DEFINITIONS
import { group as group_path } from "definitions";

// COMMON FUNCTION
import getTasks from "../../../../commonFunctions";
import EvaluateModal from "../../../../EvaluatePage/EvaluateModal/EvaluateModal";
import pluralize from "../../../../../../utils/pluralize";

import ReactMarkdown from 'react-markdown'


// Main Function
export default function InfoSection({ group, refetch, history }) {

  // States
  const [ evaluateModalProperties, setEvaluateModalProperties ] = useState(undefined)
  const [ evaluationLoading, setEvaluationLoading ] = useState(false)

  const [dropDownState, setDropDownState] = useState(false);
  const [syncModal, setSyncModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [editGroupModal, setEditGroupModal] = useState(false);
  const [cloneGroupModal, setCloneGroupModal] = useState(false);
  const [viewTasksModal, setViewTasksModal] = useState(false);

  // MUTATIONS
  const mutationOptions = {
    refetchQueries: [
      {
        query: groupGetV2,
        variables: { id: group.id },
      },
    ],
    awaitRefetchQueries: true,
  }

  const [mutateEvaluationUpdate] = useMutation(evaluationUpdate, mutationOptions);
  const [mutateEvaluationCreate] = useMutation(evaluationCreate, mutationOptions);
  const [evaluationAddToGroup] = useMutation(groupEvaluationAdd, mutationOptions);

  // Maps and Reducers
  let notInMyDealFlow = group?.startups?.filter(
    ({ isInMyDealFlow }) => !isInMyDealFlow
  );

  let tasks = getTasks({group});

  async function saveEvaluation(newAnswers) {

    setEvaluationLoading(true)

    let evaluation = evaluateModalProperties?.evaluation || {}

    // Maintain state
    setEvaluateModalProperties({
      ...evaluateModalProperties,
      evaluation: {
        ...evaluation,
        answers: newAnswers
      }
    })

    // Save evaluation
    let savedEvaluation;

    // Update existing
    if (evaluation.id) {
      try {
        let variables = {
          id: evaluation.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationUpdate({variables})
        savedEvaluation = res?.data?.evaluationUpdate;
      } catch (error) {
        console.log('error', error)
      }
    }

    // Create new
    if (!evaluation.id) {

      // ... create new evaluation
      try {
        let variables = {
          connectionId: evaluateModalProperties?.connection?.id,
          templateId: evaluateModalProperties?.template?.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationCreate({variables})
        savedEvaluation = res?.data?.evaluationCreate;
      } catch (error) {
        console.log('error', error)
      }

      // ... add evaluation to group
      try {
        let variables = {
          evaluationId: savedEvaluation?.id,
          groupId: group.id,
          creativeId: evaluateModalProperties?.creative?.id,
        };
        await evaluationAddToGroup({ variables });
      } catch(error) {
        console.log("error", error);
      }

    }

    setEvaluationLoading(false)

    // Hide edit modal
    setEvaluateModalProperties(undefined)

    // Open summary modal
    // setSummaryModalContent(savedEvaluation)

  }

  return (
    <>
      {
        <CardComponent
          padding="large"
          title={group.name}
          titleSize="medium"
          headerIcon={
            <span className="material-icons person-icon">
              {/* TODO: Who writes like this? */}
              {
                group.iAmAdmin &&
                  <i className="fas fa-user-crown data__admin" /> ||
                  group.settingssettings?.isPublic ? "lock_open" : "lock"
              }
            </span>
          }
          headerRight={
            <span
              className="group__settings"
              onClick={() => setDropDownState(!dropDownState)}
              onMouseLeave={() => setDropDownState(false)}
              data-tut="info-browse"
            >

              <i
                className="fal fa-gear group__settings__icon"
              />

              {
                // Dropdown menu
                dropDownState && (
                  <>
                    <div className="material-icons group__settings__sub-group-drop-down">
                      {group.iAmAdmin && (
                        <BrowseDropDown
                          open={dropDownState}
                          setOpen={() => setDropDownState(!dropDownState)}
                          items={[
                            {
                              text: "SETTINGS",
                              icon: <i className="fal fa-cog"/>,
                              leave: false,
                              callBack: () => setSettingsModal(true),
                            },
                            {
                              text: "edit",
                              icon: <i className="fal fa-edit"/>,
                              leave: false,
                              callBack: () => setEditGroupModal(true),
                            },
                            {
                              text: "CLONE GROUP",
                              icon: <i className="fal fa-group"/>,
                              leave: false,
                              callBack: () => setCloneGroupModal(true),
                            },
                            {
                              text: "delete",
                              icon: <i className="fa fa-trash"/>,
                              leave: true,
                              callBack: () => setDeleteModal(true),
                            },
                          ]}
                        />
                      )}

                      {!group.iAmAdmin && (
                        <>
                          <BrowseDropDown
                            open={dropDownState}
                            setOpen={() => setDropDownState(!dropDownState)}
                            items={[
                              {
                                text: " Leave group",
                                icon: <i className="fal fa-sign-out"/>,
                                leave: true,
                                callBack: () => setLeaveModal(true),
                              },
                            ]}
                          />
                        </>
                      )}
                    </div>
                  </>
                )
              }
            </span>
          }
        >

          {/* Group Description */}

          <div className="group__markdown">
            <ReactMarkdown>
              {group.description}
            </ReactMarkdown>
          </div>

          {
            notInMyDealFlow?.length !== 0 && (
              <CardMessageContainer
                title="Get in sync"
                message={`This group has ${notInMyDealFlow?.length} ${pluralize('startup', notInMyDealFlow)} that's not in your dealflow.`}
                notice
              >
                <div className="row ">
                  <div className="col-sm-12 sync-btn">
                    <NotataButton
                      size="big"
                      bgcolor="primary_color"
                      endIcon="add"
                      text="Add all"
                      onClick={() => {
                        setSyncModal(true);
                      }}
                    />
                  </div>
                </div>
              </CardMessageContainer>
            )
          }

          {
            tasks.length !== 0 && (
              <CardMessageContainer
                title="Group tasks"
                notice
                message={`There are ${tasks.length} ${pluralize('startup', tasks)} with uncompleted tasks in this group.`}
              >
                <div className="row ">
                  <div className="col-sm-12 sync-btn">
                    <NotataButton
                      size="big"
                      bgcolor="primary_color"
                      text="View"
                      onClick={() => {
                        setViewTasksModal(!viewTasksModal)
                      }}
                    />
                  </div>
                </div>
              </CardMessageContainer>
            )
          }

        </CardComponent>
      }

      {/* MODALS */}
      {deleteModal && (
        <DeleteGroupModal
          group={group}
          close={() => setDeleteModal(false)}
          submitCallBack={() => history.push("/startup_page/group")}
        />
      )}

      {leaveModal && (
        <LeaveGroupModal
          group={group}
          close={() => {
            setLeaveModal(false);
            history.push(group_path);
          }}
        />
      )}

      {settingsModal && (
        <GroupSettingsModal
          group={group}
          close={() => setSettingsModal(false)}
        />
      )}

      {editGroupModal && (
        <EditGroupModal group={group} close={() => setEditGroupModal(false)} />
      )}

      {cloneGroupModal && (
        <CreateGroup
          group={group}
          close={() => setCloneGroupModal(false)}
          history={history}
        />
      )}

      {syncModal && (
        <SyncModal
          groupId={group.id}
          startups={notInMyDealFlow}
          refetch={refetch}
          close={() => setSyncModal(false)}
        />
      )}

      {/* EVALUATE MODAL */}
      {
        evaluateModalProperties && (
          <EvaluateModal
            creative={evaluateModalProperties?.creative}
            template={evaluateModalProperties?.template}
            evaluation={evaluateModalProperties?.evaluation}
            connectionId={evaluateModalProperties?.connection?.id}
            close={() => setEvaluateModalProperties(undefined)}
            save={saveEvaluation}
            loading={evaluationLoading}
          />
        )
      }

      {/* ... tasks modal */}
      {
        viewTasksModal && (
          <Modal
            title="View tasks"
            loading={false}
            close={() => {
              setViewTasksModal(undefined);
            }}
            closeTxt="Close"
            submitButtonStyle="secondary"
          >
            <div className="group-task-container__group-task-container__group-task">
              {
                tasks.map(d => (
                  <div
                    key={`item-${d.creativeId}`}
                    className="group-task-container__group-task__each-line"
                  >

                    <div
                      className="group-task-container__group-task__each-line__startup-name"
                    >
                      {d.creativeName}
                    </div>

                    <div
                      className="group-task-container__group-task__each-line__buttons-container"
                    >
                      {d.templates
                      .filter(({sharedEvaluation}) => !sharedEvaluation)
                      .map(t => (
                        <div
                          key={`${d.creativeId}-${t.templateId}`}
                          style={{margin: "2px"}}
                        >
                          <NotataButton
                            buttonStyle="group-task-container__group-task__each-line__buttons-container__button"
                            size="x_small"
                            bgcolor={t.sharedEvaluation ? "grey_color" : "primary_color"}
                            text={t.name}
                            onClick={() => {
                              let template = group?.evaluationTemplates?.find(({id}) => id === t.templateId)
                              let modalProperties = {
                                connection: {
                                  id: d.connectionId
                                },
                                creative: {
                                  id: d.creativeId,
                                  name: d.creativeName
                                },
                                template,
                                group
                              }
                              setViewTasksModal(undefined)
                              setEvaluateModalProperties(modalProperties)
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              }
            </div>
          </Modal>
        )
      }


    </>
  );
}
