import React, { useState } from "react";
//COMPONENTS
import { BrowseDropDown, Card } from "Components/NotataComponents";
import ReportPieChart from "./ReportPieChart";
import ReportsColumnChart from "./ReportsColumnChart";
import ReportBarChart from "./ReportBarChart";
// STYLES
import "./ChartTile.scss";
// OTHERS
import { REPORTCHARTS } from "../../constants";
import ghraphChart from "assets/images/bar_graph.svg";
import selectedBarGraphChart from "assets/images/selected-bar-graph.svg";
import selectedColumnGraph from "assets/images/column-graph-selected.svg";
import pieChart from "assets/images/pie-chart.svg";
import selectedPieChart from "assets/images/pie-chart-selected.svg";
import columnChart from "assets/images/column-chart.svg";



export default function ChartTile({
  tileHeading,
  selectedChartType = REPORTCHARTS.COLUMN,
  isDropDownOption = false,
  tags,
  onGraphChange,
  dataTut
}) {
  // filter out the zero values
  // Sort by connection count
  let tagsData = tags?.filter(item => item.connectionCount > 0)
    .sort(function (a, b) {
      return b.connectionCount - a.connectionCount;
    });
  // Slice top 9 and add others to the last
  if (tagsData.length > 10) {
    let others = tagsData.slice(10, tagsData.length - 1);
    tagsData = tagsData.slice(0, 9);
    tagsData.push({
      tagName: "Others",
      id:"xxxx",
      connectionCount: others.length > 0 ? others.reduce((a, b) => ({
        connectionCount: a.connectionCount + b.connectionCount,
      })).connectionCount : 0,
    });
  }

  // total connectionCount
  const totalConnectionCount = tagsData.length ? tagsData.reduce((a, b) => ({
    connectionCount: a.connectionCount + b.connectionCount,
  })).connectionCount : [];
  const [selectedChart, setSelectedChart] = useState(selectedChartType);
  const [dropDown, setDropDown] = useState(false);

  const handleName = (name) => {
    setDropDown(name)
  }
  const headerIcons = () =>
      <div className="chart-tile-container__header__icons">
        <img
          alt=""
          src={
            selectedChart === REPORTCHARTS.BAR
              ? selectedBarGraphChart
              : ghraphChart
          }
          className={`${selectedChart === REPORTCHARTS.BAR ? "selected" : ""}`}
          onClick={() => {
            setSelectedChart(REPORTCHARTS.BAR);
            onGraphChange(REPORTCHARTS.BAR)
          }}
        />
        <img
          alt=""
          src={
            selectedChart === REPORTCHARTS.COLUMN
              ? selectedColumnGraph
              : columnChart
          }
          className={`${selectedChart === REPORTCHARTS.COLUMN ? "selected" : ""}`}
          onClick={() => {
            setSelectedChart(REPORTCHARTS.COLUMN);
            onGraphChange(REPORTCHARTS.LIST);
          }}
        />
        <img
          alt=""
          src={
            selectedChart === REPORTCHARTS.PIE ? selectedPieChart : pieChart
          }
          className={`${selectedChart === REPORTCHARTS.PIE ? "selected" : ""}`}
          onClick={() => {
            setSelectedChart(REPORTCHARTS.PIE);
            onGraphChange(REPORTCHARTS.PIE)
          }}
        />
      </div>
  
  return (
    <Card containerClassName="chart-tile-container"  size="small" data-tut={dataTut} 
      heading={tileHeading}
      headerClassName="chart-tile-container__header"
      nextToHeaderComponent={
        isDropDownOption &&
          <span onMouseLeave={() => setDropDown(false)} >
            <i
              className={`fa ${dropDown ? "fa-chevron-up" : "fa-chevron-down"
                } chart-tile-container__header__downup`}
              aria-hidden="true"
              onClick={() => setDropDown(!dropDown)}

            > </i>
            {dropDown && (
              <div
                className="chart-tile-container__header__dropdown"
              >
                <BrowseDropDown
                  open={dropDown}
                  setOpen={handleName}
                  items={[
                    { text: "Item 1", callBack: () => { },link:"" },
                    { text: "Item 2", callBack: () => { },link:"" },
                  ]}
                />
              </div>
            )}
          </span>
      }
      lastToHeaderComponent={ headerIcons() }
    >
      {selectedChart === REPORTCHARTS.COLUMN && tagsData.length > 0 && (
        <ReportsColumnChart tagsData={tagsData} />
      )}
      {selectedChart === REPORTCHARTS.PIE && (
        <ReportPieChart
          tagsData={tagsData}
          totalConnectionCount={totalConnectionCount}
        />
      )}
      {selectedChart === REPORTCHARTS.BAR && (
        <ReportBarChart
          tagsData={tags}
          totalConnectionCount={totalConnectionCount}
          tileHeading={tileHeading}
        />
      )}
    </Card>

  );
}
