import React, { useEffect, useState } from "react";

// API STUFF
import { groupSettingsSet } from "private/Apollo/Mutations";
import { groupsGetV2 } from "private/Apollo/Queries";
import { useMutation } from "@apollo/client";

// UI COMPONENTS
import { Modal } from "Components/NotataComponents";
import Settings from "Components/Common/Modals/CreateGroup/Tabs/Settings";

// STYLES
import "./GroupSettings.scss";

// CUSTOM COMPONENTS
import defaultData from "../_defaultGroupData";

// OTHERS
import { omit } from "lodash";

// MAIN FUNCTION
export default function GroupSettingsModal({ group, close }) {
  
  // STATES
  const [settings, setSettingsState] = useState(defaultData);

  // MUTATIONS
  const [mutate, { loading }] = useMutation(groupSettingsSet, {
    refetchQueries: [{ query: groupsGetV2 }],
    awaitRefetchQueries: true,
  });

  // EFFECTS
  useEffect(() => {
    if (group?.settings) {
      setSettingsState(omit(group, "__typename"));
    }
  }, [group.settings]);

  // SAVE
  async function save() {
    let { settings: generalSettings, publicSettings } = settings;
    if (loading) return;
    try {
      let variables = {
        groupId: group.id,
        settings: omit(generalSettings, ["__typename"]),
        publicSettings: omit(publicSettings, [
          "__typename",
          "evaluationTemplate",
          "link",
          "qr_code",
          "groupId"
        ]),
      };
      await mutate({ variables });
    } catch (error) {
      console.log("error", error);
    }
    close();
  }

  return (
    <Modal
      title="Settings"
      loading={loading}
      submit={save}
      close={close}
      submitTxt="Save"
      closeTxt="Cancel"
      children={
        <Settings
          group={group}
          data={settings}
          setData={setSettingsState}
        />
      }
    />
  );
}