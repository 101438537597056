import React from "react";

import CommentBoxLogic from "./CommentBoxLogic";
import { Card, CardComponent } from "Components/NotataComponents";



export default function CommentBox({ connectionId,commentBox }) {
    return(
        <div className="col-sm-12 col-md-4 discussion-container">
          <CardComponent
            containerClassName="discussion-card"
            title={"Notes"}
            titleSize={"small"}
            padding={"small"}
            content={
              <div className="discussions-contianer">
                <div className="discussions-contianer__sub-heading">
                  Notes from you and your team
                </div>
                <CommentBoxLogic coomentBox={commentBox} connectionId={connectionId} />
              </div>
            }
          />
        </div>

    )
}