import gql from "graphql-tag";

export default gql`
  mutation publicGroupSetSubjectiveScore($groupId: ID!, $creativeId: ID!, $email: String!, 
    $score: Float!, $family_name: String, $given_name: String, $pin: Float) {
    publicGroupSetSubjectiveScore(groupId: $groupId, creativeId: $creativeId, email: $email,
        score: $score, family_name: $family_name,given_name: $given_name,pin: $pin) {
          message
      }
  }
`;
