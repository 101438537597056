import React from "react";
import { NotataButton, CardComponent } from "Components/NotataComponents";

export default function SortHeader({
     adminView,
     sortField,
     setSortField,
     sortByCategory,
     setSortByCategory,
     group,
   }) {

  let settings = group?.settings;
  let hideSummaries = !settings?.showSummaries && !adminView;
  let evaluationTemplate = group?.evaluationTemplates && group?.evaluationTemplates[0];
  let withEvaluation = settings?.addEvaluation && evaluationTemplate;
  let withPublicSubjectiveScore = group?.publicSettings?.withSubjectiveScores;
  let withPublicEvaluation = group?.publicSettings?.withEvaluationTemplate && group?.publicEvaluationTemplate;

  let sortButtons = [
    {
      name: "Name",
      value: "alpha",
      defaultValue: true
    }
  ]

    if (
      settings?.addEvaluation || withPublicSubjectiveScore
    ) {
      sortButtons.push(
        {
          name: "Subjective Score",
          value: "subjectiveScore"
        }
      )
    }

    if (
      withEvaluation || withPublicEvaluation
    ) {
      sortButtons.push({
        name: "Evaluation",
        value: "evaluation"
      })
    }


  function getButtonStyleForCategory(val) {
    return sortByCategory === val
      ? "primary_color"
      : "white_color outlined"
  }

  function getButtonStyle(button) {
    let isSelected = sortField === button.value || sortField?.split(":")[0] === button.value
    let isDefault = button.defaultValue && !sortField
    if (isDefault) {
      return "primary_color"
    }
    return isSelected
      ? "primary_color"
      : "white_color outlined"
  }


  return (
    <CardComponent
      padding="large"
      title="Sort"
      titleSize="medium"
      content={
        // START OPEN REGION
        <>
          <div>
            {/* Sorting buttons */}
            <div className="sort_container">
              <div className="sort_container_inner">
                {
                  sortButtons.map((button, i) => {
                    return (
                      <NotataButton
                        key={`button-${button.value}`}
                        size="small"
                        buttonStyle="sort_button"
                        bgcolor={getButtonStyle(button)}
                        text={button.name}
                        onClick={() => {
                          console.log('button.name', button.name)
                          if (hideSummaries && sortByCategory !== 'me') {
                            setSortByCategory('me')
                          }
                          setSortField(button.value)
                        }}
                      />
                    )
                  })
                }
              </div>
            </div>
          </div>

          {
            (sortField === 'subjectiveScore' || sortField === 'evaluation') && (
              <div
                style={{
                  paddingTop: "10px",
                  marginTop: "10px"
                }}
              >
                <div className="sort_container">
                <div className="sort_container_inner">

                  { // EVALUATION
                    sortField === 'evaluation' && (
                      <>
                        {
                          !hideSummaries &&
                          (withEvaluation && withPublicEvaluation) && (
                            <>
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("private")}
                                text={"Private"}
                                onClick={() => {
                                  setSortByCategory('private')
                                }}
                              />
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("public")}
                                text={"Public"}
                                onClick={() => {
                                  setSortByCategory('public')
                                }}
                              />
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("me")}
                                text={"Me"}
                                onClick={() => {
                                  setSortByCategory('me')
                                }}
                              />
                            </>
                          )
                        }

                        {
                          !hideSummaries &&
                          (withEvaluation && !withPublicEvaluation) && (
                            <>
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("private")}
                                text={"Everyone"}
                                onClick={() => {
                                  setSortByCategory('private')
                                }}
                              />
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("me")}
                                text={"me"}
                                onClick={() => {
                                  setSortByCategory('me')
                                }}
                              />
                            </>
                          )
                        }
                      </>
                    )
                  }

                  { // SUBJECTIVE SCORE
                    sortField === 'subjectiveScore' && (
                      <>
                        {
                          !hideSummaries &&
                          settings?.addEvaluation &&
                          withPublicSubjectiveScore && (
                            <>
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("private")}
                                text={"Private"}
                                onClick={() => {
                                  setSortByCategory('private')
                                }}
                              />
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("public")}
                                text={"Public"}
                                onClick={() => {
                                  setSortByCategory('public')
                                }}
                              />
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("me")}
                                text={"Me"}
                                onClick={() => {
                                  setSortByCategory('me')
                                }}
                              />
                            </>
                          )
                        }

                        {
                          !hideSummaries &&
                          settings?.addEvaluation &&
                          !withPublicSubjectiveScore && (
                            <>
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("private")}
                                text={"Everyone"}
                                onClick={() => {
                                  setSortByCategory('private')
                                }}
                              />
                              <NotataButton
                                size="small"
                                buttonStyle="sort_button"
                                bgcolor={getButtonStyleForCategory("me")}
                                text={"Me"}
                                onClick={() => {
                                  setSortByCategory('me')
                                }}
                              />
                            </>
                          )
                        }
                      </>
                    )
                  }
                </div>
              </div>
              </div>
            )
          }

          </>
      }
    />
    
  )
}