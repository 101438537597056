import React from "react";
import "./TextBox.scss";

export function TextBox({ 
  placeholder, 
  maxWidth, 
  textBoxStyle, 
  inputval, 
  onBlur, 
  reference, 
  name, 
  onKeyDown,
  ...props }) {
  return (
    <input
      className={maxWidth ? `max-text-box text-box ${textBoxStyle}`  : `text-box ${textBoxStyle}`}
      autoComplete="off"
      type="text"
      defaultValue={inputval}
      ref={reference}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      {...props}
    />
  );
}
