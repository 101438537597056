import React from "react";

//Assets
import like from "assets/images/like.svg";
import dislike from "assets/images/dislike.svg";

// UI COMPONENTS
import { Card } from "Components/NotataComponents";

//Others
import moment from "moment";
import ReactMarkdown from "react-markdown";

export function Preview({ data, user }) {
  return (
      <Card containerClassName="news-container__card" heading={data.title}>
        {data.image && (
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4 col-sm-push-8 col-md-push-8">
              <div className="news-container__card__news__img">
                <img src={data.image} alt="" />
              </div>
            </div>
            <div className="col-xs-12 col-sm-8 col-md-8 col-sm-pull-4 col-md-pull-4">
              <p className="news-container__card__news news-container__card__news__partition">
                <ReactMarkdown>
                  {data.content}
                </ReactMarkdown>
              </p>
            </div>
          </div>
        )}

        {!data.image && (
          <p className="news-container__card__news news-container__card__news__partition">
            {data.content}
          </p>
        )}

        <div className="news-container__card__footer">
          <div className="news-container__card__footer__like-dislike">
            <div className="news-container__card__footer__like-dislike__text">
              Did you find this article useful?
            </div>
            <div className="news-container__card__footer__like-dislike__icons">
              <img src={like} alt="" />
              <img src={dislike} alt="" />
            </div>
          </div>
          <div className="news-container__card__footer__news-by">
            <div className="news-container__card__footer__news-by__date">
              {moment().format("ll")}
            </div>
            <div className="news-container__card__footer__news-by__name">
              {user?.given_name} {user?.family_name}
            </div>
          </div>
        </div>
    </Card>
  );
}