import React from "react";

export default function ManageColumnCheckBox({
  checked,
  name,
  onChange,
  value
}) {
  return (
    <label className='customCheck'>
      <input
        checked={checked}
        name={name}
        onChange={onChange}
        type="checkbox"
        id={value}
        value={value}
      />
      <span className='checkmark' id={value} />
    </label>
  );
}