export const getNewValues = ({
  e,
  columnValues,
  evaluationTemplates,
  tagGroups
}) => {

  // columnName is related to evaluation or tags section
  let { checked, name, value } = e.target;

  // SHOW ALL CHECKBOX
  if (name === "showAll") {

    let allEvaluationIds = checked
      ? evaluationTemplates?.map(({id}) => id) || []
      : []

    let allTagIds = checked
      ? tagGroups?.map(({id}) => id) || []
      : []

    return  {
      groups: checked,
      funnels: checked,
      tags: checked,
      subjectiveScore: checked,
      createdAt: checked,
      updatedAt: checked,
      evaluationTemplates: allEvaluationIds,
      tagGroups: allTagIds
    }

  }

  // ALL EVALUATIONS
  if (name === "allEvaluations") {
    return {
      evaluationTemplates: checked
        ? evaluationTemplates.map(({id}) => id) || []
        : []
    }
  }

  // ALL TAG GROUPS
  if (name === "allTagGroups") {
    return {
      tagGroups: checked
        ? tagGroups.map(({id}) => id) || []
        : []
    }
  }

  // EACH EVALUATION
  if (name === "evaluationTemplates") {
    let evaluationTemplates = checked
      ? [...columnValues.evaluationTemplates, value]
      : columnValues.evaluationTemplates.filter(item => item !== value)
    return {evaluationTemplates}
  }

  // EACH TAG GROUP
  if (name === "tagGroup") {
    let tagGroups = checked
      ? [...columnValues.tagGroups, value]
      : columnValues.tagGroups.filter(item => item !== value)
    return {tagGroups}
  }


  return {[name]: checked}

};
