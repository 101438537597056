import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";

// Library Components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// API Queries
import { groupCreate, groupStartupsAdd, groupUsersInvite} from "private/Apollo/Mutations";


// CUSTOM COMPONENTS
import { Modal } from "Components/NotataComponents";
import General from "./Tabs/General";
import Settings from "./Tabs/Settings";
import Startups from "./Tabs/Startups";
import Members from "./Tabs/Members";

// STYLES
import "./GroupSettings.scss";

// Others
import { group_dashboard } from "definitions";

// TabPanel Function
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

// a11yProps Function
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

// defaultData Function
const defaultData ={
    general: {},
    startups: {},
    members: [],
    settings: {
      chat: true,
      isPublic: false,
      showUsers: true,
      showScores: true,
      showSummaries: true,
      addStartup: false,
      addUser: false,
    },
};

// Main Function
export default function CreateGroup({ group, close, history }) {

  // States
  const [data, setData] = useState(defaultData);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [isValidGroup, setIsValidGroup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // Mutations
  const [createGroup] = useMutation(groupCreate);
  const [addMembers] = useMutation(groupUsersInvite);
  const [addStartups] = useMutation(groupStartupsAdd);

  // Effects
  useEffect(() => {
    if (data?.general?.name?.length > 0) {
      return setIsValidGroup(true);
    }
    return setIsValidGroup(false);
  }, [data])

  useEffect(() => {
    if (group) {
      let newData = {
        general: {
          name: group.name + " - COPY",
          description: group.description,
        },
        settings: group.settings,
        publicSettings: group.publicSettings,
        members: group.members
          ?.filter(({ user }) => !user.isMe)
          .map(({ user }) => user.email),
        startups: {},
      };
      for (let { creative } of group.startups || []) {
        newData.startups[creative.id] = creative;
      }
      setData(newData);
    }
  }, [group]);

  // Functions
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  async function saveData() {
    if (isLoading) return;


    if (!data?.general?.name) {
      setShowAlert(true);
      setTab(0);
    }

    setIsLoading(true);
    let group;
    // Create group with name, description and setting
    try {
      let variables = {
        name: data.general.name,
        description: data.general.description,
      };
      let res = await createGroup({ variables });
      group = res.data?.groupCreate;
    } catch (error) {
      setIsLoading(false);
      return console.log(error);
    }

    // Add startups to group
    let creativeIds = Object.keys(data.startups);

    if (creativeIds.length) {
      let variables = {
        groupId: group.id,
        creativeIds: creativeIds,
      };

      try {
        await addStartups({ variables });
      } catch (error) {
        setIsLoading(false);
        return console.log(error);

      }
    }
    // Invite members
    if (data?.members?.length) {
      let variables = {
        groupId: group.id,
        emails: data?.members,
      };

      try {
        await addMembers({ variables });
      } catch (error) {
        setIsLoading(false)
        return console.log(error);
      }
    }

    history.push(`${group_dashboard}/${group.id}`);
    setIsLoading(false);
  }

  return (
    // Create New Group Modals
    <Modal
      title="Create new group"
      submit={(e)=>{
        if(e.key==='Enter'){
          return null
        }else { return saveData()}
      }}
      loading={isLoading}
      close={close}
      submitTxt="Create"
      closeTxt="Cancel"
      alertText={!isValidGroup && showAlert ? 'You have to give the group a name!' : ''}
      children={
        <>

        {/* Tabs */}
          <div className="col-12 create-new-group-modal-container new-group-modal">
            <Tabs value={tab} onChange={handleChange} className="cntent" >
              <Tab label="GENERAL" {...a11yProps(0)} />
              <Tab label="SETTINGS" {...a11yProps(1)} />
              <Tab label="STARTUPS" {...a11yProps(2)} />
              <Tab label="MEMBERS" {...a11yProps(3)} />
            </Tabs>
          </div>

          {/*  General Tab  */}
          <TabPanel value={tab} index={0} style={{backgoundColor:"red"}}>
            <General saveData={saveData} data={data} setData={setData} />
          </TabPanel>

          {/*  Settings Tab */}
          <TabPanel value={tab} index={1}>
            <Settings data={data} setData={setData} />
          </TabPanel>

          {/* Startups Tab */}
          <TabPanel value={tab} index={2}>
            <Startups data={data} setData={setData} />
          </TabPanel>

          {/* Members Tab */}
          <TabPanel value={tab} index={3}>
            <Members data={data} setData={setData} />
          </TabPanel>
        </>
      }
    />
  );
}
