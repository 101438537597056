import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// CUSTOM COMPONENTS
import EvaluationListByTemplate from "./EvaluationListByTemplate/EvaluationListByTemplate";
import SubjectiveScoreList from "./SubjectiveScoreList";

// HELPERS
import {
  getEvaluationsByTemplate,
  getSubjectiveScoreSummary,
} from "../../../../../commonFunctions";
import PublicSubjectiveScoreList from "./PublicSubjectiveScoreList";
import PublicEvaluationListByTemplate from "./EvaluationListByTemplate/PublicEvaluationListByTemplate";

// MAIN FUNCTION
export default function EvaluationDetails({
    startup,
    group,
    adminView,
    setSummaryModalContent,
    setEvaluateModalProperties
  }) {


  // CONSTANTS
  let evaluationsByTemplate = getEvaluationsByTemplate(startup);
  let subjectiveScoreSummary = getSubjectiveScoreSummary(startup);
  let settings = group?.settings;

  // STATES
  const [viewDetails, setViewDetails] = useState(false);

  // History
  const history = useHistory()

  let withPublicSubjectiveScore = group?.publicSettings?.withSubjectiveScores
  let withPublicEvaluation = group?.publicSettings?.withEvaluationTemplate && group?.publicEvaluationTemplate

  // Return if it's not enabled in Settings
  if (!settings?.showUsers && !adminView) {
    return <span />;
  }

  return (
    <>
      <div className="startup-card-container__section_header">
        <i
          className={`startup-card-container__section_header__icon ${
            viewDetails ? "fa fa-chevron-down" : "fa fa-chevron-up"
          }`}
          aria-hidden="true"
          onClick={() => setViewDetails(!viewDetails)}
        />
        <span className="startup-card-container__section_header__heading">Evaluation Details</span>
      </div>

      {viewDetails && (
        <>
          <div className="startup-card-container__section">

            {/* SubjectiveScoreList */}
            <SubjectiveScoreList
              scores={startup.subjectiveScores}
              average={subjectiveScoreSummary?.average}
            />

          </div>

            { // Public subjective score list
              withPublicSubjectiveScore && (
                <div className="startup-card-container__section">
                  <div className="startup-card-container__section__mini-title">
                    PUBLIC
                  </div>
                  <PublicSubjectiveScoreList
                    average={startup?.publicAvgSubjectiveScore}
                    scores={startup?.publicSubjectiveScores}
                  />
                </div>
              )
            }

          {evaluationsByTemplate?.map(({ summary, evaluations }, i) => (
            <EvaluationListByTemplate
              key={i}
              summary={summary}
              evaluations={evaluations}
              connection={startup.connection}
              history={history}
              group={group}
              startup={startup}
              setSummaryModalContent={setSummaryModalContent}
              setEvaluateModalProperties={setEvaluateModalProperties}
            />
          ))}

          {
            withPublicEvaluation && (
              <div className="startup-card-container__section">
                <div className="startup-card-container__section__mini-title">
                  PUBLIC
                </div>
                <PublicEvaluationListByTemplate
                  summary={
                    startup?.publicEvaluationSummaries
                      ? startup?.publicEvaluationSummaries[0]
                      : undefined
                  }
                  evaluations={startup?.publicEvaluations}
                  connection={startup.connection}
                  history={history}
                  group={group}
                  startup={startup}
                  setSummaryModalContent={setSummaryModalContent}
                />
              </div>
            )
          }

        </>
      )}
    </>
  );
}