import React, { useState, useEffect } from "react";

// Custom Components
import {Tag, InputFormWithButton} from "../"

//scss file 
import "./FormTagSelector.scss"

// Main Function
export const FormTagSelector = ({
      items = [],
      optionalTxt,
      suggested,
      heading,
      setSelectedTags,
      selectedTags,
      setTags,
      maxLength,
   }) => {

  // States
  const [tagValue, setTagValue] = useState('')

  // Effects
  useEffect(() => {
    if (setTags) {
      let tags = [];
      setTags.forEach(el => {
        tags.push({
          name: el,
          id: Math.floor(Math.random() * 100000).toString(),
        });
      });
      setSelectedTags(tags);
    }
  }, [setTags]);


  // Remove tag
  function removeTagByIndex(index) {
    let newTags = selectedTags.filter(i => i.id !== index);
    setSelectedTags(newTags);
  }

  // Add tag
  function addNewTag(value) {

    if (value === "") {
      return;
    }

    //New tag
    const newTag = {
      id: new Date().getTime(),
      name: value.split(",")[0],
    };

    let newTags = [...selectedTags, newTag];
    setSelectedTags(newTags);
    setTagValue("")

  }

  //Keyboard Enter function to add tag
  function handleUserEnter(e) {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      addNewTag(tagValue)
    } else {
      return null;
    }
  }

  return (
    <div className="tag_wrapper">
      {heading && <div className="tag_title">TAGS</div>}

      {/* SELECTED TAGS */}
      {
        setSelectedTags.length !== 0 && (
          <div  className = "tag__mb" >
            {
              selectedTags.map((tag, index) => (
                <Tag
                  key={index}
                  customtagstyle={"tag_data"}
                  color="primary_tag"
                  size="md_tag"
                  label={`${tag.name}`}
                  killButton={()=>removeTagByIndex(tag.id)}
                />
              ))
            }
          </div>
        )
      }

      {
        selectedTags.length !== maxLength && (
          <div>
            {/* TAG INPUT FORM */}
            <div className="login-container">

              <label className="login-container__input_label">
                {optionalTxt ? optionalTxt : ""}
              </label>

              <InputFormWithButton
                placeholder='E.g. "Marketing"'
                name="tag_selector"
                type="tag_selector"
                fullWidth
                value={tagValue}
                btntext="use"
                btn={!!tagValue.length}
                setBtn={(info) => console.log('setBtn', info)}
                loading={false}
                onClick={() => {
                  addNewTag(tagValue)
                }}
                onKeyPress={handleUserEnter}
                handleChange={e => {
                  setTagValue(e.target.value)
                }}
              />

            </div>

            {/* SUGGESTED TAGS */}
            {
              suggested && items.length !== 0 && (
                <div
                className = "tag__mt"
                >
                  <div 
                  >
                    {items.map(item => (
                      <Tag
                        color="primary_tag"
                        size="md_tag"
                        label={`${item.name}`}
                        key={item.id}
                        customtagstyle="tag_margin"
                        onClick={() => {
                          setSelectedTags([...selectedTags, item]);
                        }}
                      >
                      </Tag>
                    ))}
                  </div>
                </div>
              )
            }
          </div>
        )
      }

    </div>
  );
};