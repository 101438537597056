import img1 from "./1.png";
import img2 from "./2.png";
import img3 from "./3.png";
import img4 from "./4.png";
import img5 from "./5.png";
import img6 from "./6.png";
import img7 from "./7.png";
import img8 from "./8.png";
import img9 from "./9.png";
import img10 from "./10.png";
import img11 from "./11.png";
import img12 from "./12.png";
import img13 from "./13.png";
import img14 from "./14.png";
import img15 from "./15.png";
import img16 from "./16.png";
import img17 from "./17.png";

export default [
  {
    src: img1,
    key: "1",
  },
  {
    src: img2,
    key: "2",
  },
  {
    src: img3,
    key: "3",
  },
  {
    src: img4,
    key: "4",
  },
  {
    src: img5,
    key: "5",
  },
  {
    src: img6,
    key: "6",
  },
  {
    src: img7,
    key: "7",
  },
  {
    src: img8,
    key: "8",
  },
  {
    src: img9,
    key: "9",
  },
  {
    src: img10,
    key: "10",
  },
  {
    src: img11,
    key: "11",
  },
  {
    src: img12,
    key: "12",
  },
  {
    src: img13,
    key: "13",
  },
  {
    src: img14,
    key: "14",
  },
  {
    src: img15,
    key: "15",
  },
  {
    src: img16,
    key: "16",
  },
  {
    src: img17,
    key: "17",
  },
];
