import React from "react";

// STYLES
import "./DeleteStartup.scss"

// MAIN FUNCTION
export default function DeleteStartup() {
  return (
    <div className="delete-group-modal-container">
      <div className="description">
        Are you sure you want to delete this startup permanently
      </div>
      <div className="remember">Remember:</div>
      <div className="options">
        - Startups will be removed from group <br />
        - Comments will be deleted <br />
        - Evaluations will be removed from group <br />
      </div>
      <div className="archive-startup">
        You can also archive the startup instead.
      </div>
      <div className="archive-startup">
      When archiving a startup it will not longer be visible in your deal flow (my startups), but it will show up in your reports. If you want to remove the startup completely, you will have to delete it.
      </div>
    </div>
  );
}
