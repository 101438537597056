import gql from "graphql-tag";

import SimpleUserFields from "../NewFragments/User/SimpleUserFields"
import CreativeFields from "../NewFragments/Creative/CreativeFields";
import SubjectiveScoreFields from "../NewFragments/Connection/SubjectiveScoreFields";

import EvaluationFields from "../NewFragments/Evaluation/EvaluationFields";
import EvaluationTemplateFields from "../NewFragments/EvaluationTemplate/EvaluationTemplateFields";
import EvaluationSummaryFields from "../NewFragments/Evaluation/EvaluationSummaryFields";

export default gql`
    
    
    
  query groupGetV2($id: ID!) {
    groupGetV2(id: $id) {
      id
      pin
      name
      iAmAdmin
      description
      createdAt
      updatedAt
        
      createdByUser {
          ...SimpleUserFields
      }

      members {
        role
        joinedDate
        latestActivity
        user {
          ...SimpleUserFields
        }
      }

      pendingInvitations {
        createdAt
        email
        invitedByUser {
          ...SimpleUserFields
        }
      }

      settings {
        chat
        isPublic
        isOpen
        showUsers
        showScores
        showSummaries
        addStartup
        addUser
        addEvaluation
      }

      publicSettings {
        withSubjectiveScores
        pinRequired
        invitedOnly
        invitedUsers
        withEvaluationTemplate
        evaluationTemplate {
          ...EvaluationTemplateFields
        }
        link
        qr_code
      }

      publicUsers {
        email
        given_name
        family_name
        consentToTerms
      }

        
      evaluationTemplates {
          ...EvaluationTemplateFields
      }

      publicEvaluationTemplate {
          ...EvaluationTemplateFields
      }

      startups {

        createdAt
        seen
        isInMyDealFlow
        
        sharedByUser {
            ...SimpleUserFields
        }

        creative {
          ...CreativeFields
        }

        connection {
          id
          avgSubjectiveScore
          subjectiveScores {
            createdBy
            createdAt
            score
            isMe
          }
          creative {
            ...CreativeFields
          }
          evaluationSummaries {
            ...EvaluationSummaryFields
          }
            
          evaluations {
              ...EvaluationFields
          }
            
        }

        evaluations {
            ...EvaluationFields
        }
        publicEvaluations {
            ...EvaluationFields
        }

        publicEvaluationSummaries {
            ...EvaluationSummaryFields
        }

        publicAvgSubjectiveScore

        publicSubjectiveScores {
            score
            email
            family_name
            given_name
            createdAt
        }
          
        avgSubjectiveScore
          
        subjectiveScores {
          ...SubjectiveScoreFields
        }

        log {
          id
          groupId
          creativeId
          createdByUser {
              ...SimpleUserFields
          }
          createdAt
          updatedAt
          logType
          notifyUsers
          seenBy {
              ...SimpleUserFields
          }
          reference {
            key
            val
          }
          dataPairs {
            key
            val
          }
          creative {
            id
            name
          }
          seen
        }

        evaluationSummaries {
            ...EvaluationSummaryFields
        }
      }
    }
  }

  ${SimpleUserFields}
  ${CreativeFields}
  ${SubjectiveScoreFields}
  ${EvaluationFields}
  ${EvaluationTemplateFields}
  ${EvaluationSummaryFields}

`;
