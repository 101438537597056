import React from "react";

//Common UI components
import { NotataList } from "Components/NotataComponents";

// COMMON FUNCTION
// TODO: relocate
import { getExternalEvaluationsByTemplate } from "../../../../commonFunctions";

//Others
import moment from "moment";
import { Link } from "react-router-dom";

//Main function
export default function ExternalEvaluations({ connection, setSummaryModalContent }) {
  
  //Get data
  let evaluationsByTemplate = getExternalEvaluationsByTemplate(connection);

  return (
    <div className={`col-sm-12 details`}>
      {evaluationsByTemplate?.map(({ summary, evaluations }) => {

        // Table header
        let tableHeader = [
          {
            className: "eval-score-heading",
            content: <div className="external-temp-name">{summary?.templateName} </div>,
          },
          {
            className: "heading-submissions hide-on-mobile",
            content: <div className="external-submission-date">{summary?.submissions} Submissions</div>,
          },
          {
            className: "heading-score",
            content: <div className="external-average-percentage">{summary?.averagePercentageScore}%</div>,
          },
          {
            className: "heading-right-icons",
            content: <span/>,
          },
        ]

        // Table body
        let tableBody = evaluations.map(evaluation => [

          {
            content:
              <div className="external-temp-name">
                {evaluation?.createdByEmail}
              </div>
          },
          {
            content:
              <div className="external-submission-date hide-on-mobile">
                {moment(evaluation.updatedAt).format("ll")}
              </div>
          },

          {
            content: (
              <div className="external-average-percentage">{evaluation?.summary?.scorePercent || 0}%</div>
            )
          },

          {
            content: (
              <div>
                <i
                  className="fa fa-eye green-eye"
                  aria-hidden="true"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    setSummaryModalContent(evaluation)
                  }}
                />
              </div>
            )
          },
        ])

        return (
          <NotataList
            header={tableHeader}
            body={tableBody}
          />
        );
      })}
    </div>
  );
}