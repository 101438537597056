import gql from "graphql-tag";

import EvaluationTemplateQuestionFields from "./EvaluationTemplateQuestionFields"

export default gql`
    fragment EvaluationTemplateSectionFields on EvaluationTemplateSection {
        id
        createdAt
        updatedAt
        createdBy
        name
        description
        questions {
            ...EvaluationTemplateQuestionFields
        }
    }
    ${EvaluationTemplateQuestionFields}
`