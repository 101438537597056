import React, { useState } from "react";
import "./Card.scss";

export const Card = ({
     containerClassName,
     size,
     heading,
     children,
     headerClassName,
     beforeHeaderComponent,
     nextToHeaderComponent,
     lastToHeaderComponent,
     ...rest
   }) => {

  return (
    <div
      className={
        `card ${size === "small" ? "small__card" : "large__card"} ${containerClassName}`
      }
      {...rest} // TODO: <- WTF
    >
      {
        heading && (
          <div className={`card__heading ${headerClassName}`}>
            {beforeHeaderComponent}
            {heading}
            {nextToHeaderComponent}
            {lastToHeaderComponent}
          </div>
        )
      }
      {children}
    </div>
  )

}


export const CardComponent = ({
    containerClassName,
    title,
    titleLink,
    titleSize,
    titleColor,
    headerRight,
    headerStyle,
    headerIcon,
    subHeading,
    padding,
    collapasable,
    content,
    backLink,
    footer,
    style,
    history,
    ...children
  }) => {

  const [collapse, setCollapse] = useState(false)

  // Handles collapse
  const collapseCard = () => {
    setCollapse(!collapse)
  }

  // Title onClick handler
  const titleClickHandler = () => {
    titleLink && history.push(titleLink)
  }

  return(
    <div
      className={`card ${"padding-"+padding} ${containerClassName}`}
      style={style}
    >

      {
        title && (
          <div className="card__header" style={headerStyle}>
        <div className="card__heading1">

          {
            backLink && (
              <i
                className="fa fa-chevron-left"
                aria-hidden="true"
                onClick={backLink}
              />
            )
          }

          {
            collapasable && (
              <i
                className={`card__collapse-icon fa ${collapse
                  ? "fa-chevron-up"
                  : "fa-chevron-down"
                }`}
                onClick={collapseCard}
              />
            )
          }

          {
            headerIcon && (
              <span className="card__header-icon">
                {headerIcon}
              </span>
            )
          }

          {
            (title && titleSize) && (
              <h1
                className={
                  // TODO: this is messy
                  `card__title card__title--${titleSize} 
                    ${titleLink && "card__title--underline"}
                    ${titleColor && `card__title--${titleColor}`}`
                }
                onClick={titleClickHandler}
              >
              {title}
            </h1>
            )
          }


        </div>

        {
          headerRight && (
            <span className="card__header-right">
              {headerRight}
            </span>
          )
        }

      </div>
        )
      }


      <div className={collapse && "card__collapse"}>

        {
          subHeading && (
            <h4 className="card__sub-heading">
              {subHeading}
            </h4>
          )
        }

        <div className="card__content-container">
          {content}
        </div>

        {
          children && (
            <div {...children}/>
          )
        }

        {
          footer && (
            <div className="card__footer">
              {footer}
            </div>
          )
        }

      </div>
    </div>
  )

}

