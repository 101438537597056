import React, { useEffect } from "react";

// Custom components
import {NotataButton} from "Components/NotataComponents";

// Styles
import "./Modal.scss";

// Main Function
export function Modal({
  close,
  noKill,
  submit,
  submitbig,
  submitbigtxt,
  title,
  disableFoot,
  loading,
  loadings,
  cancelLoading,
  showScrollBar,
  submitTxt,
  submitButtonSize,
  closeTxt,
  submitButtonStyle,
  submitButtonIcon,
  closeButtonSize,
  intermidate,
  intermidateTxt,
  disabled,
  intermidateStyle,
  lastButtonStyle,
  innerClassName,
  alertText,
  submtTxt,
  submtButtonIcon,
  ...children
  
}) {

  useEffect(() => {
    function downHandler(e) {
      e.key === "Escape" && close(e);
      e.key === "Enter" && !loading && submit && submit(e);
    }
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [close, loading, submit,intermidate]);

  return (
    <div className="modal-container">
      <div className="ghost" />
      <div
        style={{ overflow: showScrollBar ? "hidden" : "auto" }}
        className="content"
        onClick={event => {
          if (event.target === event.currentTarget) {
            close(event);
          }
        }}
      >
        <div className={`inner ${innerClassName || ""}`}>
          {title && (
            <div className="modal_header">
              {title && <div className="modal_title">{title}</div>}

              {!noKill && (
                <div onClick={close} className="close_modal">
                  <i className="fal fa-times" />
                </div>
              )}
            </div>
          )}

          <div
            style={{
              overflowY: showScrollBar ? "scroll" : "hidden",
              maxHeight: showScrollBar ? "60vh" : "auto",
            }}
            className={`${showScrollBar && "scrollbar"} main_content`}
            {...children}
          />

          {(close || submit || intermidate ) && !disableFoot && (
            <div className="modal_footer">
              {alertText && <span className="alert_text">{alertText}</span>}
              {close && closeTxt && (
                <NotataButton
                  size={closeButtonSize? closeButtonSize :"small"}
                  text={closeTxt}
                  buttonStyle="outlined"
                  onClick={close}
                  loading={cancelLoading}
                />
              )}
              {intermidate && intermidateTxt && (
                <NotataButton
                  size="small"
                  text={intermidateTxt}
                  bgcolor={intermidateStyle ? intermidateStyle :"primary_color"}
                  onClick={intermidate}
                  loading={loadings}
                />
              )}
              {submit && submitTxt && (
                <NotataButton
                  size={submitButtonSize? submitButtonSize : "small"}
                  text={submitTxt}
                  bgcolor={lastButtonStyle ? lastButtonStyle :"primary_color"}
                  disabled={disabled || loading ? disabled : false}
                  buttonType={submitButtonIcon || ""}
                  loading={loading}
                  onClick={submit}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}