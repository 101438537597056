import React, { useEffect } from "react";

// API STUFF
import { useLazyQuery,/* useMutation  , useQuery */ } from "@apollo/client";
import { /* newsGet,  */ newsGetOne } from "../../../Apollo/Queries";
// import { newsSet } from "private/Apollo/Mutations";
// 
//UI Components
import { GhostLoader } from "Components/NotataComponents";

//Styles
import "../News.scss";

//Assets
// import like from "../../../assets/images/like.png";
// import dislike from "../../../assets/images/dislike.png";

//Helper Components
import { EachNews } from "./EachNews"


//Main Function
export default function NewsViewOne({ history, match }) {
  const [getOneNews, { data /* , loading  */ }] = useLazyQuery(newsGetOne);
  let news = data?.newsGetOne;


  useEffect(() => {
    if (match?.params?.id) {
      getOneNews({ variables: { id: match.params.id } });
    }
  }, [match]);

  if (!news) {
    return <GhostLoader />;
  }

  return (
    <div className="news-container">
      <div className="news-container__main">
        <EachNews id={news?.id} data={news} history={history} />
      </div>
    </div>
  );
}
