import React from 'react';

// Custom Components
import { 
    Tags, 
    Dropdown, 
    InputForm, 
    Blocks, 
    RadioButton, 
    CheckBox, 
    BrowseDropDown,
    NotataButton
} from "Components/NotataComponents";
import TextField from '../TextField/TextField';

// Styles
import './UILibrary.scss'

// Others
import chatIcon from "assets/images/chatIcon.svg";
import user_plus_icon from 'assets/images/user-plus-solid.svg'

// Main Function
function UILibrary() {
    const tabArr = [
        {
            text: 'Group Updates',
            value: "Group Updates"
        },
        {
            text: 'Team Updates',
            value: "Team Updates"
        }
    ];
    const contentArr = [
        {
            value: "Group Updates",
            text: 'Stephanie Wykoff accepted your invitation to join (as a member) group Band of Angels',
            image: user_plus_icon
        },
        {
            value: "Group Updates",
            text: 'Stephanie Wykoff posted a new comment in group Great Startup 1',
            image: chatIcon
        },
        {
            value: "Team Updates",
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            image: user_plus_icon
        },
        {
            value: "Team Updates",
            text: 'Stephanie Wykoff posted a new comment in group Great Startup 1',
            image: chatIcon
        }
    ];

    const callBack = () => true;

    return (
        <div className='content-container' >

            {/* Buttons */}
            <div className='component-container' >
                <h4 className='heading__h4'>BUTTONS</h4>
                <div className='buttons-container' >
                    <div className='btn' >
                        <NotataButton
                            size='x_large'
                            bgcolor='secondary_color'
                            text='Add all startups'
                        >
                        </NotataButton>
                    </div>

                    <div className='btn' >
                        <NotataButton
                            size='large'
                            bgcolor='secondary_color'
                            text='Add all startups'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='small'
                            bgcolor='secondary_color'
                            endIcon='chevron_right'
                            text='Next'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='x_small'
                            bgcolor='secondary_color'
                            startIcon='done'
                            text='save'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='small'
                            color='secondary'
                            endIcon='chevron_right'
                            text='More News'
                        >
                        </NotataButton>
                    </div>
                </div>

                <div className='buttons-container' >
                    <div className='btn' >
                        <NotataButton
                            size='x_large'
                            bgcolor='primary_color'
                            text='Add all startups'
                        >
                        </NotataButton>
                    </div>

                    <div className='btn' >
                        <NotataButton
                            size='large'
                            bgcolor='primary_color'
                            text='Add all startups'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='small'
                            bgcolor='primary_color'
                            endIcon='chevron_right'
                            text='Next'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='x_small'
                            bgcolor='primary_color'
                            startIcon='done'
                            text='save'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='small'
                            color='primary'
                            endIcon='chevron_right'
                            text='More News'
                        >
                        </NotataButton>
                    </div>
                </div>

                <div className='buttons-container' >
                    <div className='btn' >
                        <NotataButton
                            size='x_large'
                            bgcolor='grey_color'
                            text='Add all startups'
                        >
                        </NotataButton>
                    </div>

                    <div className='btn' >
                        <NotataButton
                            size='large'
                            bgcolor='grey_color'
                            text='Add all startups'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='small'
                            bgcolor='grey_color'
                            endIcon='chevron_right'
                            text='Next'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='x_small'
                            bgcolor='grey_color'
                            startIcon='done'
                            text='save'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='small'
                            color='grey'
                            endIcon='chevron_right'
                            text='More News'
                        >
                        </NotataButton>
                    </div>
                </div>

                <div className='buttons-container' >
                    <div className='btn' >
                        <NotataButton
                            size='x_large'
                            text='Add all startups'
                            buttonStyle='outlined'
                        >
                        </NotataButton>
                    </div>

                    <div className='btn' >
                        <NotataButton
                            size='large'
                            text='Add all startups'
                            buttonStyle='outlined'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='small'
                            endIcon='chevron_right'
                            text='Next'
                            buttonStyle='outlined'
                        >
                        </NotataButton>
                    </div>
                    <div className='btn' >
                        <NotataButton
                            size='x_small'
                            startIcon='done'
                            text='save'
                            buttonStyle='outlined'
                        >
                        </NotataButton>
                    </div>

                </div>

                <div className='buttons-container'>
                    <div style={{ marginRight: "20px" }}>
                        <h5 className='heading__h5 ml-md'>hover</h5>
                        <div className='btn'>
                            <NotataButton
                                size='large'
                                bgcolor='secondary_color--dark'
                                text='Add all startups'
                            />

                            <NotataButton
                                size='large'
                                bgcolor='primary_color--dark'
                                buttonStyle='ml-md'
                                text='Add all startups'
                            />
                        </div>
                        <div className='btn'>
                            <NotataButton
                                size='large'
                                bgcolor='grey_color--dark'
                                text='Add all startups'
                            />
                            <NotataButton
                                size='large'
                                color='grey--dark'
                                buttonStyle='outlined ml-md'
                                text='Add all startups'
                            />
                        </div>
                    </div>
                    <div>
                        <h5 className='heading__h5 ml-md'>loading</h5>
                        <div className='btn'>
                            <NotataButton
                                size='large'
                                bgcolor='secondary_color'
                                text='Add all startups'
                                loading={true}
                            />
                            <NotataButton
                                size='large'
                                bgcolor='primary_color'
                                buttonStyle='ml-md'
                                loading={true}
                            />
                        </div>
                        <div className='btn'>
                            <NotataButton
                                size='large'
                                bgcolor='grey_color'
                                loading={true}
                            />
                            <NotataButton
                                size='large'
                                buttonStyle='outlined ml-md'
                                loading={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Input Field  */}
            <div className='component-container sub-container' >
                <div style={{ width: '340px' }}>
                    <h4 className='heading__h4' >INPUT</h4>
                    <InputForm
                        type='email'
                        label="Email"
                        name="email"
                        placeholder="Email"
                    />
                    <InputForm type='email' label="Email" name="email" placeholder="|" />
                    <InputForm
                        type='email'
                        label="Email Or Phone Number"
                        name="email"
                        placeholder="Ana@LeverageUX.com"
                    />
                    <InputForm
                        type='email'
                        label="Email"
                        name="email"
                        placeholder="Ana@@LeverageUX.com"
                    />
                </div>
                <div style={{ width: '340px', marginRight: '150px' }}>
                    <h4 className='heading__h4'>Dropdown</h4>
                    <div className='mb-md'>
                        <Dropdown
                            title="Title"
                            items={[{ name: 'USA' }, { name: 'Europe' }, { name: 'Norway' }]}
                        />
                    </div>
                    <div>
                        <Dropdown
                            title="Title"
                            items={[{ name: 'Everywhere' }, { name: 'USA' }, { name: 'Europe' }, { name: 'Norway' }, { name: 'USA' }, { name: 'Europe' }, { name: 'Norway' }]}
                        />
                    </div>
                </div>
            </div>


            <div className='component-container sub-container'>
                <div style={{ width: '340px' }}>
                    <h4 className='heading__h4'>Blocks</h4>
                    <Blocks
                        tabValue="Group Updates"
                        tabArr={tabArr}
                        contentArr={contentArr}
                        width='340'
                    />
                </div>
                <div style={{ width: '340px', marginRight: '150px' }}>

                    <h4 className='heading__h4'>Tags</h4>
                    <Tags
                        optionalTxt="write or choose up to 3 tags"
                        suggested={true}
                        heading={false}
                        // reference={register({ required: true })}
                        title="domain"
                        items={[
                            { name: "Software", id: "4" },
                            { name: "Finance", id: "23" },
                            { name: "B2B", id: "34" },
                            { name: "Growth", id: "17" },
                        ]}
                    />

                </div>
            </div>

            <div className='component-container sub-container'>
                <div style={{ width: '340px' }}>
                    <h4 className='heading__h4'>Radio Button</h4>
                    <div>
                        <RadioButton
                            name="eval-types"
                            label="Incubator"
                            id="first-impression"
                            checked={true}
                        ></RadioButton>
                        <RadioButton
                            name="eval-types"
                            label="accelerator"
                            id="before-pitching"
                        ></RadioButton>
                    </div>
                </div>
                <div style={{ width: '340px', marginRight: '150px' }}>
                    <h4 className='heading__h4'>Checkbox</h4>
                    <CheckBox
                        name="Pre-seed"
                        label="accelerator"
                        id="before-pitching"
                    />
                    <CheckBox
                        name="Seed"
                        label="accelerator"
                        id="before-pitching"
                    />
                </div>
            </div>

            <div className='component-container sub-container'>
                <div style={{ width: '340px' }}>
                    <h4 className='heading__h4'>Drop Menu</h4>
                    <div style={{ width: "215px", position: "absolute", left: 0 }}>
                        <BrowseDropDown
                            items={[
                                { text: 'Request Evaluation', icon: 'person_search',link:"", callBack },
                                { text: 'Archive Startup', icon: 'archive',link:"", callBack },
                                { text: 'Remove Startup', icon: 'delete', leave: true,link:"", callBack }
                            ]}
                            open={true}
                            setOpen={() => false}
                        />
                    </div>
                </div>
                <div style={{ width: '340px', marginRight: '150px' }}>
                    <h4 className='heading__h4'>Text Field</h4>
                    <TextField
                        title='Problem'
                        description='What is the problem that is being solved?'
                        placeholder='Write your answer...'
                    />
                </div>
            </div>

            {/* Pop-up */}
            <div className='component-container'>
                <h4 className='heading__h4'>Pop-Up</h4>
                {/* ToDo: Need to add a pop here. */}
            </div>


        </div>
    )
}

export default UILibrary;