import React from "react";
import moment from "moment";

import "./FilterBar.scss";

import { Tag } from "Components/NotataComponents/"

const FilterBar = ({
     filters,
     setFilters,
     funnelGroups,
     tagGroups,
     defaultFilters
  }) => {


  function getFunnelTag(id) {
    for (let funnelGroup of funnelGroups) {
      for (let funnelTag of funnelGroup?.funnelTags || []) {
        if (funnelTag.id === id) {
          return funnelTag
        }
      }
    }
  }

  // Removes tag from filters
  function removeTag(tagId) {
    let newFilters = { ...filters, tags: filters?.tags.filter(({id}) => id !== tagId) }
    setFilters(newFilters);
  }


  if (!funnelGroups || !tagGroups) {
    return <span/>
  }

  return(
    <div className="filter_bar_card">
      <div className="filter_tab_container">

        {
          Object.keys(filters)
            .filter(key => {
              if (
                key === 'sortBy' ||
                key === 'sortDirection' ||
                key === 'sortByVal'
              ) {
                return false
              }
              return filters[key]
            })
            .map((key, index) => {

              // TAGS
              if (key === "tags" && key?.length !== 0) {
                return (
                  <>
                    {
                      filters[key].map(tag => {
                        return (
                          <Tag
                            color="color_default"
                            size="md_tag"
                            key={`${index}-${tag.id}`}
                            label={
                              <>
                                <span>{tag?.group?.name}</span>
                                <span>: {tag?.name}</span>
                              </>
                            }
                            killButton={() => removeTag(tag.id)}
                          />
                        );
                      })
                    }
                  </>
                )
              }

              // FUNNEL TAG
              if (key === "funnelTag") {
                let funnelTag = getFunnelTag(filters[key]);
                return (
                  <Tag
                    color="color_default"
                    size="md_tag"
                    key={`${index}-ft`}
                    label={
                      <>
                        <span>{funnelTag?.group?.name}</span>
                        <span>: {funnelTag?.name}</span>
                      </>
                    }
                    killButton={() => setFilters({ ...filters, funnelTag: undefined})}
                  />
                )
              }

              // STARRED
              if (key === "starred") {
                return (
                  <Tag
                    color="color_default"
                    size="md_tag"
                    key={`${index}-starred`}
                    label={
                      <span>Starred</span>
                    }
                    killButton={() => setFilters({ ...filters, starred: false })}
                  />
                )
              }

              // FROM DATE
              if (key === "fromDate") {
                return (
                  <Tag
                    color="color_default"
                    size="md_tag"
                    key={`${index}-starred`}
                    label={
                      <>
                        <span>From</span>
                        <span>: {moment(parseInt(filters[key])).format("ll")}</span>
                      </>
                    }
                    killButton={() =>  setFilters({ ...filters, [key]: "" })}
                  />
                )
              }

              // TO DATE
              if (key === "toDate") {
                return (
                  <Tag
                    color="color_default"
                    size="md_tag"
                    key={`${index}-starred`}
                    label={
                      <>
                        <span>To</span>
                        <span>: {moment(parseInt(filters[key])).format("ll")}</span>
                      </>
                    }
                    killButton={() => setFilters({ ...filters, [key]: "" })}
                  />
                )
              }

              // OTHERS
              return (
                <Tag
                  color="color_default"
                  size="md_tag"
                  key={`${index}-starred`}
                  label={
                    <>
                      <span>{key}</span>
                      <span>: {filters[key]}</span>
                    </>
                  }
                  killButton={() => setFilters({ ...filters, [key]: "" })}
                />
              )
            }
          )
        }

        <Tag
          color="pale-gray"
          size="md_tag"
          label={
            <span>clear all filters</span>
          }
          onClick={() => setFilters(defaultFilters)}
        />

      </div>
    </div>
  )
}

export default FilterBar;