import React, { useState } from "react";

// Custom Components
import { Tag } from "..";
import { NotataButton } from "Components/NotataComponents";

//input size
function getInputSize(size) {
    switch (size) {
      case "bigInput":
        return "bigInput";
      case "smallInput":
        return "smallInput";
      default:
        return "bigInput";
    }
  } 

  //Tag size
  function getULSize(ulSize) {
    switch (ulSize) {
      case "bigULSize":
        return "bigTagUL";
      case "smallULSize":
        return "smallTagUL";
      default:
        return "bigTagUL";
    }
  }

//   Main Function
export default function TagsInput({
    selectTags,
    selectedTags,
    size,
    ulSize,
    handleSearch,
    placeholder,
    addNewTagModal,
    label,
    showData,
    showAddNewBtn
  }) {
    // State
    const [tagValue, setTagValue] = useState('')
  
    //remove tag
    function removeTagByIndex(index) {
      let newTags = selectedTags.filter(i => i.id !== index);
      selectTags(newTags);
    }
  
    //Add tag
    function addTags(value) {
      if (value === "") {
        return;
      }
      const newTag = {
        id: new Date().getTime(),
        name: value.split(",")[0],
      };
  
      let newTags = [...selectedTags, newTag];
      selectTags(newTags);
      setTagValue("")
    }
  
    function handleUserEnter(e) {
      if (e.key === "Enter" || e.key === ",") {
        e.preventDefault();
        addTags(e.target.value);
      } else {
        return null;
      }
    }
  
    function handleOnChange(e) {
      e.preventDefault()
      handleSearch(e.target.value)
      setTagValue(e.target.value)
    }
  
    return (
      <>
        <div
          className={getInputSize(size)}
        >
          <div
            className={getULSize(ulSize)}
            style={{paddingLeft:selectedTags.length===0?"":5,paddingTop:selectedTags.length===0?"":5}}
          >
            {selectedTags.map((tag, index) => (
              <Tag
                key={index}
                customtagstyle={"tag_data"}
                color="primary_tag"
                size="md_tag"
                label={`${tag.name}`}
                killButton={()=>removeTagByIndex(tag.id)}
                // tagmargin="tag_margin"
              />
            ))}
            <div className="tableSearch">
              <input
                value={tagValue}
                style={{
                  marginTop: selectedTags.length > 0 ? "3px" : "2px",
                  fontSize: 13,
                  border: "none",
                }}
                className="tagInput"
                placeholder={placeholder}
                onKeyPress={handleUserEnter}
                autoComplete="off"
                onChange={handleOnChange}
              />
              <input
                style={{
                  marginTop: selectedTags.length > 0 ? "3px" : "2px",
                  fontSize: 13,
                  minWidth: selectedTags.length > 0 ? "35%" : "10%",
                  border: "none",
                }}
                className="mobile-tagInput"
                placeholder={placeholder}
                onKeyPress={handleUserEnter}
                autoComplete="off"
                onChange={handleOnChange}
              />
              {showAddNewBtn && <div className="names">
                <NotataButton
                  size="big_wide"
                  bgcolor="primary_color"
                  startIcon="add"
                  text="Add new tag"
                  onClick={() => addNewTagModal(true)}
                />
              </div>}
              {showData && label(addTags, tagValue)}
            </div>
  
          </div>
        </div>
      </>
    );
  }