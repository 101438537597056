import React from "react";

// UI COMPONENTS
import { NotataButton } from "Components/NotataComponents";

// STYLES
import "./EvaluationsModal.scss";

// DEFINITIONS
import { evaluate_page } from "definitions";

// OTHERS
import { Link } from "react-router-dom";

// MAIN FUNCTION
export default function EvaluateStartupSelector({
  connection,
  account,
  setSelectedTemplate
}) {

  // maps and reducers
  const evaluationTemplates = account?.evaluationTemplates;

  // get evaluation function
  const getEvaluations = (templateId, myEvaluations) => {
    return (
      connection?.evaluations?.filter(
        data => data?.templateId === templateId && data.isMe === myEvaluations
      ) || []
    );
  };

  return (
    <div className="evaluate-startup-container">
      {evaluationTemplates &&
        evaluationTemplates?.map(
          template =>
            getEvaluations(template.id, true).length === 0 && (
              <div
                className="evaluate-startup-container__data"
                key={template.id}
              >
                <div className="eval-type-heading">{template.name}</div>
                <div className="evaluate-startup-container__evaluations">
                  <div className="new-evaluation">
                    <NotataButton
                      size="onlyIcon"
                      bgcolor="primary_color"
                      startIcon="add"
                      onClick={() => setSelectedTemplate(template)}
                    />
                  </div>
                </div>
              </div>
            )
        )}
      {connection?.evaluations.length === evaluationTemplates?.length && (
        <div>You don't have any Startup to Evaluate</div>
      )}
    </div>
  );
}