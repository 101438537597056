import gql from "graphql-tag";

export default gql`
  mutation connectionExternalResourceRemove($connectionId: String!, $resourceId: String!) {
    connectionExternalResourceRemove(connectionId: $connectionId, resourceId: $resourceId) {
        id
        creative{
            name
        }
        externalResources {
            label
            link
            id
            createdAt
        }    
    }
  }
`;
