import React from "react";
import moment from "moment";

//STYLES
import "./EvaluationListByTemplate/EvaluationListByTemplate.scss";
import { NotataList } from "../../../../../../../Components/NotataComponents";

export default function SubjectiveScoreList({ scores, average }) {

  let tableHeader = [
    {
      content: (
        <div>Subjective Scores</div>
      )
    },
    {
      style: {
        width: "150px",
        textAlign: "center"
      },
      content: (
        <div>
          {
            scores?.length === 1
              ? scores?.length + " Entry"
              : scores?.length || 0 + " Entries"
          }
        </div>
      )
    },
    {
      style: {
        width: "70px",
        textAlign: "center"
      },
      content: (
        <div>{average}</div>
      )
    },
    {
      style: {
        width: "30px"
      },
      content: <span/>
    }
  ]

  let tableBody = (scores || []).map(score => [
    {
      content: (
        <div>
          {score?.createdByUser?.given_name}{" "}
          {score?.createdByUser?.family_name}
          {score?.createdByUser?.isMe && " (you)"}
        </div>
      )
    },
    {
      content: (
        <div>
          {moment(score.createdAt).format("ll")}
        </div>
      )
    },
    {
      content: (
        <div>{score.score}</div>
      )
    },
    {
      content: <span/>
    }


  ])

  return (
    <>
      <div className="evaluation-list">
        <NotataList
          header={tableHeader}
          body={tableBody}
        />
      </div>
    </>
  );
}
