import gql from "graphql-tag";
import { publicSharingFragments } from "../Fragments";

export default gql`
  query publicSharingsGet($connectionId: ID!) {
    publicSharingsGet(connectionId: $connectionId) {
      ...publicSharingFields
    }
  }
  ${publicSharingFragments}
`;
