import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { userUpdate } from "private/Apollo/Mutations";
import { userGet } from "private/Apollo/Queries";

// UI COMPONENT
import { CheckBoxes, RadioButtons, Tags, NotataButton } from "Components/NotataComponents";

// STYLES
import "./Profile.scss";
import { FormTagSelector } from "../../../../Components/NotataComponents";

// MAIN FUNCTION
export default function Page2({
  setPage,
  skipLast,
}) {

  // STATES 
  const [successMsg, setSuccessMsg] = useState("")
  const [investment, setInvestment] = useState([]);
  const [geography, setGeography] = useState([]);
  const [stages, setStages] = useState([]);

  const [domain, setDomain] = useState([]);
  const [role, setRole] = useState([]);

  // Forms
  const { handleSubmit, register, formState } = useForm();
  const { isSubmitting } = formState;

  // QUERIES
  const userQuery = useQuery(userGet);

  // MUTATIONS
  const [mutate] = useMutation(userUpdate);

  // Maps
  const user = userQuery.data?.userGet || {};

  const getId = () => Math.floor(Math.random() * 1000).toString();

  // EFFECTS
  useEffect(() => {
    if (user && user.q3_investment) {
      let investmentItems = user.q3_investment.map(el => ({
        id: getId(),
        name: el,
      }));
      setInvestment(investmentItems);
    }

    if (user && user.q4_geography) {
      let geographyItems = user.q4_geography.map(el => ({
        id: getId(),
        name: el,
      }));
      setGeography(geographyItems);
    }

    if (user && user.q5_stage) {
      let stageItems = user.q5_stage.map(name => name);
      setStages(stageItems);
    }
  }, [user]);

  // functions

  // handleBack function
  function handleBack(e) {
    e.preventDefault();
    if (setPage) {
      setPage(1);
    }

  }


  // onSubmit function
  const onSubmit = async (data, event) => {

    setSuccessMsg("")
    event.preventDefault();

    let q3 = [];
    let q4 = [];
    let whoareyou = role;
    let expertise = [];

    investment.forEach(el => {
      q3.push(el.name);
    });

    geography.forEach(el => {
      q4.push(el.name);
    });

    domain.forEach(el => {
      expertise.push(el.name);
    });

    const input = {
      q3_investment: q3,
      q4_geography: q4,
      q5_stage: stages,
    };

    if (expertise && expertise.length) {
      input.q1_expertise = [...expertise];
    }

    if (whoareyou && whoareyou.length) {
      input.q2_whoAreYou = whoareyou;
    }

    try {
      await mutate({ variables: { input } });
      setSuccessMsg("Successfully saved!")
    } catch (error) {
      console.log("error", error);
    }

    if (!skipLast) {
      if (setPage) {
        setPage(3);
      }
    }
  };

  return (
    <div className='profile3'>
      <h1>What are you looking for?</h1>

      <form
        className="profile_form"
        onSubmit={handleSubmit(onSubmit)}
        // style={{ marginBottom: "20px" }}
      >

        {/* DOMAIN EXPERTISE */}
        <div style={{marginTop: "30px", paddingBottom: "20px"}}>
          <h4 className="profile_tags">What is your domain expertise?</h4>
          <div className="tag_input">

            {/* Tags */}
            <FormTagSelector
              optionalTxt="Choose up to 3 tags"
              suggested={true}
              // onchange={() => { setShowData(true) }}
              onchange={() => {  }}
              heading={false}
              reference={register({ required: true })}
              title="domain"
              selectedTags={domain}
              setSelectedTags={setDomain}
              setTags={user?.q1_expertise ? user?.q1_expertise : null}
              items={[
                {
                  name: "Software", id: "4"
                },
                {
                  name: "Finance",
                  id: "23"
                },
                {
                  name: "B2B",
                  id: "34"
                },
                {
                  name: "Growth",
                  id: "17"
                },
              ].filter(it => !domain.some(({ name }) => name === it.name))}
              maxLength={3}
            />

          </div>
        </div>

        {/* INVESTMENT OPPORTUNITIES */}
        <div style={{marginTop: "25px", paddingBottom: "20px"}}>
          <h4 className="profile_tags">What investment opportunities are you looking for?</h4>
          <div className="tag_input">
            <FormTagSelector
              optionalTxt="Choose up to 3 tags"
              suggested={true}
              onchange={() => { }}
              heading={false}
              title="domain"
              selectedTags={investment}
              setSelectedTags={setInvestment}
              setTags={user?.q3_investment ? user?.q3_investment : null}
              items={[
                { name: "MedTech", id: "4" },
                { name: "Female Founders", id: "23" },
                { name: "B2B", id: "34" },
              ].filter(it => !investment.some(({ name }) => name === it.name))}
              maxLength={3}
            />
          </div>
        </div>

        {/* GEOGRAPHY */}
        <div style={{marginTop: "25px", paddingBottom: "20px"}}>
          <h4 className="profile_tags">What geography are you mainly interested in?</h4>
          <div className="tag_input">
            <FormTagSelector
              optionalTxt="Choose up to 3 tags"
              suggested={true}
              onchange={() => { }}
              heading={false}
              title="domain"
              selectedTags={geography}
              setSelectedTags={setGeography}
              setTags={user?.q4_geography ? user?.q4_geography : null}
              items={[
                { name: "Norway", id: "4" },
                { name: "Sweden", id: "23" },
                { name: "Oslo", id: "34" },
              ].filter(it => !geography.some(({ name }) => name === it.name))}
              maxLength={3}
            />
          </div>
        </div>

        {/* CHECKBOXES */}
        <div style={{
          marginTop: "20px",
          paddingBottom: "10px"
        }}>
          <h4 className="profile_tags">What stages are you mostly interested in?</h4>
          <div style={{marginTop: "30px"}}>
            <CheckBoxes
              getSelectedBox={setStages}
              data={[
                { id: 1, value: "Pre seed", label: "Pre seed" },
                { id: 2, value: "Seed", label: "Seed" },
                { id: 3, value: "Series A+", label: "Series A+" },
              ].map(it => ({
                ...it,
                checked: stages.some(name => name === it.value),
              }))}
            />
          </div>
        </div>

        {/* RADIO BUTTONS */}
        <div style={{marginTop: "20px"}}>
          <h4 className="profile_radio">Which one of these categories do you represent?</h4>

          <RadioButtons
            name="whoare"
            getValue={setRole}
            setValue={user?.q2_whoAreYou ? user?.q2_whoAreYou : null}
            data={[
              { id: 1, value: "investor", label: "Investor" }, { id: 2, value: "incubator", label: "Incubator" },
              { id: 3, value: "accelerator", label: "Accelerator" }, { id: 4, value: "hub", label: "Hub" },
              { id: 5, value: "venturecapital", label: "Venture Capital" }, { id: 6, value: "other", label: "Other" },
            ]}
          />
        </div>

        {/* NOTATA BUTTONS */}
        <div className='button_container_justify'>


          {
            setPage && (
              <NotataButton
                size="medium"
                text="Back"
                buttonStyle="outlined"
                onClick={handleBack}
              />
            )
          }


          <div style={{position: "relative"}}>
            <NotataButton
              size="medium"
              bgcolor="primary_color"
              endIcon={
                (!skipLast && setPage)
                  ? !isSubmitting ? "chevron_right" : ""
                  : ""
              }
              text={skipLast ? "Save" : "Next"}
              buttonType="submit"
              loading={isSubmitting}
            />
            <p className="success_msg" >
              {successMsg}
            </p>
          </div>
        </div>


      </form>
    </div>
  );
}
