import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Styles
import "./routes.css";

// HELPERS
import ScrollToTop from "./ScrollToTop";

// Main Page
import { FrontPage } from "FrontPage/FrontPage";

//Signup
import { Signup } from "private/Pages/LoginPages/SignUp/SignUp";
// import { Awaiting } from "Containers/Auth/Awaiting/Awaiting";

//Login
import { Login } from "private/Pages/LoginPages/Login/Login";
import { ForgotPassword } from "private/Pages/LoginPages/ForgotPassword/ForgotPassword";

//SignOut
import { SignOut } from "private/Pages/LoginPages/SignOut/SignOut";

//Profile
import { PreProfile } from "private/Pages/LoginPages/PreProfile/Profile";

//Private Router
import { LoggedInRouter } from "./private/PrivateRouter";

//Public Router
import { PublicRouter } from "./public/PublicRouter";

// // Import Kanban View
// import { KanbanPage } from "./private/Pages/MyStartups/Kanban/KanbanPage";

// SHARING
import { LinkBridge } from "Components/Shared/LinkBridge/LinkBridge";

import MailingList from "private/Pages/LoginPages/MailingList/MailingList"


import {

  //Mainpage
  frontpage,

  //Signup
  signup,
  // awaiting,

  //Login
  login,
  forgotPassword,
  waiting_list,

  //Signup
  signOut,

  //Progile
  pre_profile,
  link_bridge,

  //Startup_page
  startup_page,

  startup_print_page,

  //Public router
  public_pages,


  app_sumo_signup

  // kanban
} from "./definitions";
import { AppSumoSignUp } from "./private/Pages/LoginPages/AppSumoSignUp/AppSumoSignUp";



export const Routes = () => (
  <Router basename="/">
    <ScrollToTop>
      <Switch>

        {/* Frontpage */}
        <Route exact path={frontpage} component={FrontPage} />

        {/* Signup */}
        <Route exact path={signup} component={Signup} />

        <Route exact path={app_sumo_signup} component={AppSumoSignUp} />

        {/* <Route exact path={awaiting} component={Awaiting} /> */}

        {/*  Login */}
        <Route exact path={login} component={Login} />
        <Route exact path={forgotPassword} component={ForgotPassword} />
        <Route exact path={waiting_list} component={MailingList} />

        {/* Signout */}
        <Route exact path={signOut} component={SignOut} />

        {/* Profile */}
        <Route exact path={pre_profile} component={PreProfile} />

        {/* Startup_page */}
        <Route path={startup_page} component={LoggedInRouter} />
        <Route path={startup_print_page} component={LoggedInRouter} />

        {/* PublicRoutes */}
        <Route path={public_pages} component={PublicRouter} />


        {/* <Route path={kanban} component={KanbanPage} /> */}
        <Route path={link_bridge} component={LinkBridge} />

        {/* Error Route */}
        <Route render={() => <div>404</div>} />
      </Switch>
    </ScrollToTop>
  </Router>
);
