import React, { useState, useRef } from "react";
import { useQuery } from "@apollo/client";

// API Queries
import { userGet } from "private/Apollo/Queries";

//Definitions
import {
  // startup pages
  startup_page,
  // Settings paths
  tags1,
  funnels1,
  notification,
  web_form,
  your_team,
  setting_profile,
  evaluation_templates_page,
  evaluation_template_page,
} from "definitions.js";

// Styles
import './NotataMenu.scss'

// OTHERS
import { menuListArr } from "../../../private/Pages/commonFunctions";
import { NavLink, matchPath } from "react-router-dom";
import authLogo from "assets/images/auth-logo.svg";
import mobile_toggler from "assets/images/mobile_toggler.svg";
import { nav_setting } from "./NotataMenuIcons"
import { signOut, dashboard } from "definitions.js";


// * MAIN FUNCTION *
export function NotataMenu({ history, withBanner }) {
  //Const
  const sidebarr = useRef(0);
  const floatingButton = useRef(0);
  let menuList = menuListArr;

  // STATES
  const [listOpen, setListOpen] = useState(false);
  const [isMobileList, setMobileList] = useState(false);

  // QUERIES
  const { data } = useQuery(userGet);
  let user = data?.userGet || {};

  return (
    <div className="notata-menu__main">

      <div
        ref={sidebarr}

        className={`
          ${!listOpen ? "notata-menu__container" : "notata-menu__container1"}
          ${isMobileList
          ? "mobile-container"
          : "mobile_container--hide"}
        `}
      >
        <div
          className="notata-menu__containerInner"
          style={{
            paddingTop: withBanner ? "35px" : "0px"
          }}
        >

          <div
            className="notata-menu__container1__m"
            // style={{
            //   paddingTop: withBanner ? "35px" : "0px"
            // }}
          >

            <NavLink to={dashboard} >
              <img
                style={{ width: "40px", height: "40px" }}
                src={authLogo}
                alt="logo"
                className="logo"
              />
              <div className={"brand"}>notata</div>
            </NavLink>

            <div
              className="mobile__togglerInSidebar"
              onClick={() => setMobileList(false)}
            >
              <i className={`fal fa-chevron-left`} />
            </div>
          </div>

          {/* Toggle open/close */}
          <div
            role="button"
            onClick={() => {
              setListOpen(!listOpen);
              setMobileList(false);
            }}
            className="desktop_Collapse"
            style={{ position: "relative" }}
          >
            <span
              className={listOpen ? "right_icon" : "left_icon"}
            >
              <i className={`fal fa-chevron-${listOpen ? "right" : "left"}`} />
            </span>
          </div>

          <div>
            <div
              style={{
                top: withBanner ? "40px" : "13px"
              }}
              className={`
                mobile__toggler
                 ${isMobileList ? "floating-button-display" : "floating-button-hide" }
              `}
              ref={floatingButton}
              onClick={() => {
                setMobileList(true);
                setListOpen(false);
              }}
            >
              {" "}
              <img src={mobile_toggler} alt="" />
            </div>
          </div>
          {/* Main navigation icons */}
          <div className="menu_lists">
            {menuList.map((item, i) => (
              <React.Fragment key={i}>
                <div className="menu_list">
                  <NavLink
                    exact={true}
                    to={item.link}
                    onClick={() => setMobileList(false)}
                    isActive={(match, location) => {
                      let isMatch = match ? true : false;
                      if (!isMatch) {
                        for (var i = 0; i < item.subPaths.length; i++) {
                          let matchPathResult = matchPath(location.pathname, {
                            path: item.subPaths[i],
                          });
                          isMatch = matchPathResult
                            ? matchPathResult.isExact
                            : false;
                          if (isMatch) break;
                        }
                      }
                      return isMatch;
                    }}
                    activeClassName={!listOpen ? "active__open" : "active__close"}
                    data-tut={item.dataTut}
                  >
                    <div className='icons' style={item.iconStyle}>
                      {/* <img src={item.iconClass} className="" alt="" style={{color:"red"}} /> */}
                      <span className="image">{item.iconClass}</span>
                    </div>
                    <p className="list">{item.label}</p>
                  </NavLink>
                </div>
              </React.Fragment>
            ))}

            <div className="menu_list menu_list--settings">
              <NavLink
                exact={true}
                to={`${startup_page}/settings`}
                onClick={() => setMobileList(false)}
                activeClassName={!listOpen ? 'active__open' : "active__close"}
                isActive={(match, location) => {
                  let isMatch = match ? true : false;
                  let subPaths = [
                    tags1,
                    funnels1,
                    notification,
                    web_form,
                    your_team,
                    setting_profile,
                    signOut,
                    evaluation_templates_page,
                    evaluation_template_page,
                    `${evaluation_template_page}/:id`,
                    `${evaluation_template_page}/:id/section/:sectionId`
                  ];
                  if (!isMatch) {
                    for (var i = 0; i < subPaths.length; i++) {
                      let matchPathResult = matchPath(location.pathname, {
                        path: subPaths[i],
                      });
                      isMatch = matchPathResult
                        ? matchPathResult.isExact
                        : false;
                      if (isMatch) break;
                    }
                  }
                  return isMatch;
                }}
                data-tut={"settings-menu"}
              >
                <div className="icons" style={{ marginTop: "-1px" }}>
                  {/* <img src={nav_setting} className="" alt="" /> */}
                  <span className="image">{nav_setting()}</span>
                </div>
                <p className="list">Settings</p>
              </NavLink>
            </div>
            {isMobileList && (
              <>
                <div
                  className="profile_item"
                  style={{ paddingTop: "30px", borderTop: "1px solid #BFBFBF" }}

                >
                  <NavLink
                    exact={true}
                    to={setting_profile}
                    onClick={() => setMobileList(false)}
                    activeClassName={!listOpen ? "active__open" : "active__close"}
                    style={{ display: "flex" }}
                  >

                    <div className="icons">
                      <div className="name">
                        <span  >{user?.given_name?.charAt(0)}</span>
                      </div>

                    </div>
                    <p className="list">
                      {user.given_name} {user.family_name}
                    </p>
                  </NavLink>
                </div>
                <div
                  className="profile_item"
                  style={{ paddingTop: "30px" }}
                >
                  <NavLink
                    exact={true}
                    to={signOut}
                    onClick={() => setMobileList(false)}
                    activeClassName={!listOpen ? "active__open" : "active__close"}
                    style={{ display: "flex" }}
                  >

                    <div className="icons">
                      <div className="name" style={{ visibility: "hidden" }}>
                        <span>L</span>
                      </div>

                    </div>
                    <p className="list">
                      Log Out
                    </p>
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}