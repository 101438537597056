import gql from "graphql-tag";
import { publicSharingFragments } from "../Fragments";

export default gql`
  mutation publicSharingCreate($connectionId : String!, $input: PublicSharingInput!) {
    publicSharingCreate(connectionId: $connectionId, input: $input) {
      ...publicSharingFields
    }
  }
  ${publicSharingFragments}
`;
