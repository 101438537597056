import React, { useState } from 'react';
import { useMutation } from "@apollo/client";
import StartupCard from "private/Pages/StartupPage/CommonComponents/StartupCard";
import SubjectiveScoresComp from "private/Pages/StartupPage/Tabs/Overview/components/SubjectiveScoresComp";
import CardMessageContainer from 'private/Pages/Group/Components/CardMessageContainer';
import { Card, NotataButton } from "Components/NotataComponents";
import "./Startup.scss";
import Evaluate from "../Components/Evaluate";
import ThankYou from "./ThankYou";
import { getScore, getEvaluated, saveScoreToLocalDB, saveEvaluationToLocalDB, getStoredDetails } from "utils/groupSharing";
import { publicGroupSetSubjectiveScore, publicGroupEvaluationCreate } from "public/Apollo/Mutations";

export default function Startup({ match, history }) {

  const [thankYouModal, setThankYouModal] = useState(false);

  const { group, creativeId } = history?.location?.state;
  const { evaluationTemplate, publicSettings } = group || {};
  const { withEvaluationTemplate, withSubjectiveScores } = publicSettings || {};
  const showSubjectiveScore = withSubjectiveScores;
  const showEvaluation = withEvaluationTemplate && evaluationTemplate;

  const groupId = group.id;
  const creative = group?.startups?.find(({id}) => id === creativeId)

  const queryOptions = { context: { clientName: "public" } };
  const [publicGroupEvaluationCreateMutation, { loading: publicGroupEvaluationCreateLoading }] = useMutation(publicGroupEvaluationCreate, queryOptions);
  const [publicGroupSetSubjectiveScoreMutation, { data: publicGroupSetSubjectiveScoreData, loading: publicGroupSetSubjectiveScoreLoading }] = useMutation(publicGroupSetSubjectiveScore, queryOptions);

  const isEvaluated = getEvaluated(creative, groupId);
  const score = getScore(creative, groupId);
  const [evaluateModal, setEvaluateModal] = useState(false);

  const sectionAnswers = creative?.newAnswers
  const publicGroup = getStoredDetails(groupId);

  const evaluateSave = async (answers) => {
    saveEvaluationToLocalDB(groupId, answers, creative?.id)
    let resp = await publicGroupEvaluationCreateMutation({
      variables: {
        groupId: groupId,
        creativeId: creative.id,
        email: publicGroup.member.email,
        input: {
          name: 'evaluation',
          description: 'evaluation-dec',
          answers
        },
        family_name: publicGroup.member.family_name,
        given_name: publicGroup.member.given_name,
        pin: publicGroup.pin
      }
    })
    if (resp?.data?.publicGroupEvaluationCreate?.message) {
      setEvaluateModal(false);
      setThankYouModal(true);
    }
  }
  const hideEvaluationModel = () => {
    setEvaluateModal(!evaluateModal);
  }
  const saveScore = async (score) => {
    saveScoreToLocalDB(groupId, score, creative.id);
    await publicGroupSetSubjectiveScoreMutation({
      variables: {
        groupId: groupId,
        creativeId: creative.id,
        email: publicGroup?.member?.email,
        score,
        family_name: publicGroup?.member?.family_name,
        given_name: publicGroup?.member?.given_name,
        pin: publicGroup?.pin
      }
    })
  }






  if (thankYouModal) {
    return <ThankYou content={
      <>
        <div className="button-style">
          <NotataButton
            buttonStyle="primary_color big"
            startIcon="chevron_left"
            text="Go Back to Group"
            onClick={() => history.go(-1)}
          />
        </div>
        <div className="button-style">
          <NotataButton
            buttonStyle="primary_color big"
            startIcon="chevron_left"
            text="Go Back to Startup"
            onClick={() => setThankYouModal(false)}
          />
        </div>
      </>
    } />
  }

  if (evaluateModal) {
    const evaluation = publicGroup?.evaluations?.find(evaluation => evaluation.creativeId === creative?.id) || [];
    return (
      <div
        className="startup-container"
        style={{ width: "100%" }}
      >

        <div
          style={{
            marginBottom: "20px"
          }}
        >
          <div
            className="back-button"
            onClick={() => history.go(-1)}
            style={{
              marginBottom: "10px"
            }}
            >
            <span className="material-icons back-icon">
              arrow_back_ios
            </span>
            <span className="page-heading">
              GO BACK TO GROUP
            </span>
          </div>
          <div className="back-button" onClick={hideEvaluationModel}>
            <span className="material-icons back-icon">
              arrow_back_ios
            </span>
            <span className="page-heading">
              GO BACK TO STARTUP
            </span>
          </div>
        </div>

        <div className="startup-container__content">
          <Card
            heading={`Evaluate ${creative.name}`}
          >
            <Evaluate
              template={evaluationTemplate}
              loading={publicGroupEvaluationCreateLoading}
              setAnswers={evaluateSave}
              history={history}
              answers={evaluation?.answers || []}
              onCancel={hideEvaluationModel}
            />
          </Card>
        </div>

      </div>
    )
  }

  return (
    <div
      className="startup-container"
      style={{ width: "100%" }}
    >

      <div
        className="back-button"
        onClick={() => history.go(-1)}
        style={{marginBottom: "20px"}}
      >
        <span className="material-icons back-icon">
          arrow_back_ios
        </span>
        <span className="page-heading">
          GO BACK TO GROUP
        </span>
      </div>

      <div className="startup-container__content">

        <StartupCard
          connection={{ creative: creative }}
        />

        {showSubjectiveScore && (
          <Card
            heading="Subjective Score"
            style={{padding: "30px"}}
            >

            <div
              style={{
                fontSize: "14px",
                color: "#969BA3",
                marginTop: "20px"
              }}
            >
              On a scale from 1 to 10, how would you score this company?
            </div>

            <SubjectiveScoresComp
              isPublic={true}
              score={saveScore}
              connection={{
                ...creative,
                subjectiveScores: [
                  {
                    isMe: true,
                    creativeId: creative.id,
                    score
                  }
                ]
              }}
            />
          </Card>
        )}
        {showEvaluation && (
          <Card
            style={{padding: "30px"}}
          >
            <CardMessageContainer
              title="Heads up!"
              message={`The moderator of this group has invited you to evaluate this company.`}
              children={
                <NotataButton
                  onClick={() => setEvaluateModal(true)}
                  text={isEvaluated ? "Evaluated" : "Evaluate"}
                  buttonStyle="primary_color mb-big"
                />
              }
              notice
            />
          </Card>
        )}
        {
          sectionAnswers.length !== 0 &&
          <>
            {sectionAnswers.map((answer, sectionIndex) =>
              <Card
                key={`${answer.sectionName}-section-id-${sectionIndex}`}
                heading={answer.sectionName}
                containerClassName="startup-container__content__answers"
                style={{padding: "30px"}}
                >
                {
                  answer.questions.map((questionAnswer, index) =>
                    <div key={`${answer.sectionName}-question-id-${index}`}>
                      <div className="startup-container__content__answers__module-heading">
                        {questionAnswer.questionName}
                      </div>
                      <div className="startup-container__content__answers__module-description">
                        {questionAnswer.answers.length === 0 && (
                          <span className="not_answer">
                            Not answered
                          </span>
                        )}
                        {questionAnswer.answers.length !== 0 && (
                          questionAnswer.answers.map((answerVals, answerIndex) => {
                            return (
                              <p
                                key={`${answer.sectionName}-question-id-${index}-answer-id-${answerIndex}`}
                                className="preview-evaluation-questions__question__answerColor">{answerVals}
                              </p>
                            )
                          })
                        )}
                      </div>
                      {questionAnswer.comment && <CardMessageContainer
                        message={questionAnswer.comment}
                      />}
                    </div>
                  )
                }

              </Card>
            )}
          </>
        }
      </div>
    </div>
  )
}