import React from "react";
import "./DisplayFormQuestion.scss";

export default function DisplayFormQuestion({
   name,
   score,
   possibleScore,
   answer,
   comment,
 }) {

  return (
    <div className="display-question">

      {/* Question name*/}
      <div className="display-question__label">
        <p>{name}</p>
        {/* Question description?? */}

        { // Display conditional score
          !!(score && possibleScore) && (
            <div>
              {score + " of " + possibleScore + " points"}
            </div>
          )
        }
      </div>


      {/* Answers */}
      <div className="display-question__answers">

        {/* Answer */}
        <div className={
          answer
            ? "display-question__answers__answer"
            : "display-question__answers__not_answer"
        }>
          {answer || "Not answered" }
        </div>

        {/* Comment */}
        {
          comment && (
            <div className="display-question__answers__comment">
              <div className="display-question__answers__comment__label">
                Comment:
              </div>
              <div className="display-question__answers__comment__value">
                "{comment}"
              </div>
            </div>
          )
        }
      </div>

    </div>
  )
}