import React from "react";

// COMPONENTS
import PopupDropdown from "assets/images/PopupDown.svg";
// STYLES
// import styles from "./styles.module.css";

import styles from "./Table.module.css";

import _ from 'lodash';

export default function TableHeader(props) {

  // Constant
  const {
    columnValues,
    filters,
    setFilters,
    evaluationTemplates,
    setCurrentPage,
    handlePopup,
    tagGroups
  } = props;


  return (
    <>
      <thead>
        <tr>


          {/* STAR */}
          <td className={styles.tableHeaderCheckmark}>
            <div>
              <div onClick={handlePopup} className={styles.selectDropdown}>
                <img src={PopupDropdown} alt="" />
              </div>
              <div className={styles.customStar}
                onClick={() => {
                  setFilters({ ...filters, starred: !filters.starred })
                  setCurrentPage({pageNo: 1})
                }}
              >
                <span  className={`fas fa-star`} style={{color:filters.starred?"orange":"#d7d7d7"}}/>
              </div>
            </div>
          </td>



          {/* NAME */}
          <td className={`
              ${styles.columnType_name}
              ${filters?.sortBy === "ALPHA" && styles.sortingColumn}
            `}
              style={{cursor: "pointer"}}
            onClick={() => {
              let sortBy = "ALPHA";
              let sortDirection =
                filters.sortBy === "ALPHA" && filters.sortDirection !== "DESC"
                  ? "DESC"
                  : "ASC";
              setFilters({
                ...filters,
                sortBy,
                sortDirection,
              });
              setCurrentPage({pageNo: 1})
            }}
            // onClick={()=>sortingFunction("name")}
          >
            COMPANY NAME
            <i
              className={
                filters.sortBy !== "ALPHA"
                  ? `fas fa-sort-alt`
                  : filters.sortDirection === "ASC"
                    ? `fas fa-arrow-down`
                    : `fas fa-arrow-up`
              }
            />
            {/* <i
              className={
               order == "default"
                  ? `fas fa-sort-alt`
                  : order === "ASC"
                    ? `fas fa-arrow-down`
                    : `fas fa-arrow-up`
              }
            /> */}
          </td>


          {/* GROUPS */}
          {
            columnValues.groups && (
              <td className={`
                ${styles.columnType_groups}
                ${filters?.sortBy === "GROUP" && styles.sortingColumn}
                `}>
                Groups
              </td>
            )
          }

          {/* FUNNELS */}
          {
            columnValues.funnels && (
              <td
                className={`
                  ${styles.columnType_funnels}
                  ${filters?.sortBy === "FUNNEL" && styles.sortingColumn}
                `}
              >
                FUNNEL STAGE
              </td>
            )
          }


          {/* TAG GROUPS */}
          {
            columnValues.tagGroups.map(id => {
              let item = tagGroups.find(it => it.id === id);
              return (
                <td
                  style={{cursor: "pointer"}}
                  key={id}
                >
                  {item?.name}
                </td>
              )
            })
          }


          {/*{tagGroupsArraySorted?.map(({ name, id }) =>*/}
          {/*  columnValues?.tagColumns?.includes(id) && (*/}
          {/*      <td*/}
          {/*        style={{cursor: "pointer"}}*/}
          {/*        key={id}*/}
          {/*      >*/}
          {/*        {name}*/}
          {/*      </td>*/}
          {/*    )*/}
          {/*)}*/}

          {/* TAGS */}
          {
            columnValues.tags && (
              <td
                className={`
                  ${styles.columnType_tags}
                  ${filters?.sortBy === "TAG" && styles.sortingColumn}
                `}
              >
                TAGS
              </td>
            )
          }

          {/* SUBJECTIVE SCORE */}
          {columnValues.subjectiveScore && (
            <td
              className={`
                ${styles.columnType_subjectiveScore}
                ${filters?.sortBy === "SUBJECTIVE_SCORE" && styles.sortingColumn}
              `}
              style={{cursor: "pointer"}}
              onClick={() => {
                let sortBy = "SUBJECTIVE_SCORE";
                let sortDirection =
                  filters.sortBy === "SUBJECTIVE_SCORE" &&
                  filters.sortDirection !== "DESC"
                    ? "DESC"
                    : "ASC";
                setFilters({
                  ...filters,
                  sortBy,
                  sortDirection,
                });
                setCurrentPage({pageNo: 1})
              }}
              >
              SUBJECTIVE SCORE
              <i
                className={
                  filters.sortBy !== "SUBJECTIVE_SCORE"
                    ? `fas fa-sort-alt`
                    : filters.sortDirection === "ASC"
                      ? `fas fa-arrow-down`
                      : `fas fa-arrow-up`
                }
              />
            </td>
          )}

          {/* UPDATED AT */}
          {columnValues.updatedAt && (
            <td className={`
                  ${styles.columnType_timeStamp}
                  ${filters?.sortBy === "UPDATED_AT" && styles.sortingColumn}
                `}
                style={{cursor: "pointer"}}
                onClick={() => {
                  let sortBy = "UPDATED_AT";
                  let sortDirection =
                    filters.sortBy === "UPDATED_AT" &&
                    filters.sortDirection !== "DESC"
                      ? "DESC"
                      : "ASC";
                  setFilters({
                    ...filters,
                    sortBy,
                    sortDirection,
                  });
                  setCurrentPage({pageNo: 1})
                }}
            >
              UPDATED AT
              <i
                className={
                  filters.sortBy !== "UPDATED_AT"
                    ? `fas fa-sort-alt`
                    : filters.sortDirection === "ASC"
                      ? `fas fa-arrow-down`
                      : `fas fa-arrow-up`
                }
              />
            </td>
          )}

          {/* CREATED AT */}
          {columnValues.createdAt && (
            <td className={`
                  ${styles.columnType_timeStamp}
                  ${filters?.sortBy === "CREATED_AT" && styles.sortingColumn}
                `}
                style={{cursor: "pointer"}}
                onClick={() => {
                  let sortBy = "CREATED_AT";
                  let sortDirection =
                    filters.sortBy === "CREATED_AT" &&
                    filters.sortDirection !== "DESC"
                      ? "DESC"
                      : "ASC";
                  setFilters({
                    ...filters,
                    sortBy,
                    sortDirection,
                  });
                  setCurrentPage({pageNo: 1})
                }}
            >
              CREATED AT
              <i
                className={
                  filters.sortBy !== "CREATED_AT"
                    ? `fas fa-sort-alt`
                    : filters.sortDirection === "ASC"
                      ? `fas fa-arrow-down`
                      : `fas fa-arrow-up`
                }
              />
            </td>
          )}



          {/* EVALUATION TEMPLATES */}
          {evaluationTemplates?.map(({ name, id }) =>
            columnValues.evaluationTemplates.includes(id) && (
              <td
                className={`
                  ${styles.columnType_evaluation}
                  ${filters?.sortBy === "EVALUATION" && filters.sortByVal === id && styles.sortingColumn}                    
                `}
                style={{cursor: "pointer"}}
                key={id}
                onClick={() => {
                  let sortBy = "EVALUATION";
                  let sortDirection =
                    filters.sortBy === "EVALUATION" &&
                    filters.sortDirection !== "DESC" &&
                    filters.sortByVal === id
                      ? "DESC"
                      : "ASC";

                  setFilters({
                    ...filters,
                    sortBy,
                    sortDirection,
                    sortByVal: id,
                  });
                  setCurrentPage({pageNo: 1})
                }}
              >
                {name}
                <i
                  className={
                    (filters.sortBy !== "EVALUATION" && filters.sortByVal !== id)
                      ? `fas fa-sort-alt`
                      : filters.sortDirection === "ASC"
                        ? `fas fa-arrow-down`
                        : `fas fa-arrow-up`
                  }
                />
              </td>
            )
          )}
        </tr>
      </thead>

    </>
  );
}