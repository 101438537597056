import React from "react";
import { Link } from "react-router-dom";

// API STUFF
import { useQuery } from "@apollo/client";
import { notificationsGet } from "../../../Apollo/Queries";

// CUSTOM COMPONENTS
import {
  Loader,
  NotataButton,
  CardComponent,
} from "Components/NotataComponents";
import Notifications from "Components/Common/Notifications/Notifications";

// DEFINITIONS
import { notification } from "definitions";

// OTHERS
import { NOTIFICATIONS_POLL_INTERVAL } from "../../constants";

// MAIN FUNCTION
export default function DashboardNotifications({ history }) {
  // QUERIES
  const { data, loading } = useQuery(notificationsGet, {
    pollInterval: NOTIFICATIONS_POLL_INTERVAL,
    fetchPolicy: "network-only",
  });

  // DATA MAPS
  let notifications = data?.notificationsGet || [];

  return (
    <CardComponent
      containerClassName="dashboard-container__notification"
      padding="small"
      data-tut="notifications"
      title="Notifications"
      titleSize="small"
      content={
        <>
          {!data && loading && <Loader />}
          <div className="dashboard-container__notification__container">
            {!notifications.length && (
              <div className="dashboard-container__notification__container__no-notifications">
                You have no notifications
              </div>
            )}

            <Notifications
              notifications={notifications.slice(0, 4)}
              history={history}
            />
          </div>
        </>
      }
      footer={
        <Link to={notification} className="link_underline">
          <NotataButton
            color="primary"
            text="More Updates"
            buttonStyle="text_button"
          />
        </Link>
      }
    />
  );
}
