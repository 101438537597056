import React from "react";
import "./NotataList.scss";
// Main Function
export function NotataList({ header, body }) {
  return (
    <table className="notata-list-table">
      {header && header.length !== 0 && (
        <thead>
          <tr className="notata-list-table__row">
            {header?.map((header_columns, index) => (
              <td
                style={header_columns?.style}
                className={header_columns?.className}
                key={`tHead-${index}`}
              >
                {header_columns?.content}
              </td>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {body?.map((rowData, row_index) => (
          <tr key={`bData-${row_index}`} className="notata-list-table__row">
            {rowData?.map((column_data, column_index) => (
              <td
                style={{
                  // Get optional style from header
                  ...(header && header[column_index]?.style),
                  // Override with column style
                  ...column_data?.style
                }}
                className={
                  "notata-list-table__data " +
                  header && header[column_index]?.className + " " +
                  column_data?.className
                }
                key={`bData-${column_index}-tData-${column_index}`}
              >
                {column_data?.content}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
