import React from 'react';

import BarIcon1 from "../../assets/images/Bar_Icon_01.svg";
import BarIcon2 from "../../assets/images/Bar_Icon_02.svg";
import BarIcon3 from "../../assets/images/Bar_Icon_03.svg";
import BarIcon4 from "../../assets/images/Bar_Icon_04.svg";
import BarIcon5 from "../../assets/images/Bar_Icon_05.svg";

import img1 from "../../assets/images/redBar.png";
import img2 from "../../assets/images/greenBar.png";
import img3 from "../../assets/images/violetBar.png";
import img4 from "../../assets/images/yellowBar.png";
import img5 from "../../assets/images/grassBar.png";

// Menu icons
import { 
  nav_group, 
  nav_bar, 
  nav_suitcase, 
  nav_newsicon, 
  nav_report 
} from "../../Components/Navigation/NotataMenu/NotataMenuIcons";

// Definitions
import {
    dashboard,
    group,
    group_dashboard,
    startup_page,
    startup_company_profile,
    reports,
    news,
    evaluate_page,
    evaluation_summary_page,
    //evaluation_summary
} from "definitions.js";

export const dynamicIcons = (index, option, color) => {
  const position = index + 1;
  if (option) {
    return position === 1
      ? img1
      : position === 2
      ? img2
      : position === 3
      ? img3
      : position === 4
      ? img4
      : img5;
  } else {
    return position === 1
      ? BarIcon1
      : position === 2
      ? BarIcon2
      : position === 3
      ? BarIcon3
      : position === 4
      ? BarIcon4
      : BarIcon5;
  }
};

export const sortArr = arr => {
  let sortedColumn = [];
  for (const data of Object.keys(arr ||[])) {
    sortedColumn.push(arr[data]);
  }
  sortedColumn.sort((a, b) => (a.index > b.index ? 1 : -1));
  return sortedColumn || [];
};

export const arrayPositionSort = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

// Get answer by questionId
export const getAnswer = (creative, questionId) => {
  let items = creative.answers?.filter(answer => {
    return answer.questionId === questionId;
  });
  if (!items?.length) {
    return;
  }
  let results = items.map(({ val }) => val);

  return results.join(",\n");
}

export const SvgComp = ({index, color}) => {

  let funnelColor = ''

  switch(color) {
    case "red":
      funnelColor =  "#FF7979"
      break;
    case "blue":
      funnelColor =  "#53CAB2"
      break;
    case "purple":
      funnelColor =  "#8A70F1"
      break;
    case "orange":
      funnelColor =  "#FFC244"
      break;
    case "green":
      funnelColor =  "#a6f376"
      break;
    default:
      funnelColor =  "#FF7979"
  }

  if(index === 0) {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="12" width="6" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="3" y="9" width="8" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="2" y="6" width="10" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="1" y="3" width="12" height="2" rx="1" fill="#C4C4C4"/>
        <rect width="14" height="2" rx="1" fill={funnelColor}/>
      </svg>
    )
  }
  if(index === 1) {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="12" width="6" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="3" y="9" width="8" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="2" y="6" width="10" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="1" y="3" width="12" height="2" rx="1" fill={funnelColor}/>
        <rect width="14" height="2" rx="1" fill={funnelColor}/>
      </svg>
    )
  }
  if(index === 2) {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="12" width="6" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="3" y="9" width="8" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="2" y="6" width="10" height="2" rx="1" fill={funnelColor}/>
        <rect x="1" y="3" width="12" height="2" rx="1" fill={funnelColor}/>
        <rect width="14" height="2" rx="1" fill={funnelColor}/>
      </svg>
    )
  }
  if(index === 3) {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="12" width="6" height="2" rx="1" fill="#C4C4C4"/>
        <rect x="3" y="9" width="8" height="2" rx="1" fill={funnelColor}/>
        <rect x="2" y="6" width="10" height="2" rx="1" fill={funnelColor}/>
        <rect x="1" y="3" width="12" height="2" rx="1" fill={funnelColor}/>
        <rect width="14" height="2" rx="1" fill={funnelColor}/>
      </svg>
    )
  }
  if(index === 4) {
    return (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="12" width="6" height="2" rx="1" fill={funnelColor}/>
        <rect x="3" y="9" width="8" height="2" rx="1" fill={funnelColor}/>
        <rect x="2" y="6" width="10" height="2" rx="1" fill={funnelColor}/>
        <rect x="1" y="3" width="12" height="2" rx="1" fill={funnelColor}/>
        <rect width="14" height="2" rx="1" fill={funnelColor}/>
      </svg>
    )
  }

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="12" width="6" height="2" rx="1" fill={funnelColor}/>
      <rect x="3" y="9" width="8" height="2" rx="1" fill={funnelColor}/>
      <rect x="2" y="6" width="10" height="2" rx="1" fill={funnelColor}/>
      <rect x="1" y="3" width="12" height="2" rx="1" fill={funnelColor}/>
      <rect width="14" height="2" rx="1" fill={funnelColor}/>
    </svg>
  )

}

// Components-->Common -->Notifications
export const getVal = (n, k) =>
  n?.references?.find(({ key }) => key === k)?.val;
  
// Array of menu list
export const menuListArr = [
    {
      label: "Dashboard",
      iconClass: nav_bar(),
      iconStyle: {},
      link: dashboard,
      subPaths: [`${startup_page}/dashboard`],
      dataTut:"dashboard-menu"
    },
    {
      label: "My Startups",
      iconClass: nav_suitcase(),
      iconStyle: { paddingTop: "1px" },
      // link: startup_page,
      link: `${startup_page}`,
      subPaths: [
        // startup_page, 
        `${startup_page}`,
        startup_company_profile, 
        `${startup_page}/archive`,
        `${evaluate_page}/:connectionId/:templateId`,
        `${evaluate_page}/:connectionId/:templateId/:evaluationId`,
        `${evaluation_summary_page}/:connectionId/:templateId/:evaluationId`,
        `${evaluation_summary_page}/:connectionId/:templateId/:evaluationId}`,
        //`${evaluation_summary}`
      ],
      dataTut:"my-startups-menu"
    },
    {
      label: "Groups",
      iconClass: nav_group(),
      iconStyle: {},
      link: group,
      subPaths: [group, `${group_dashboard}/:groupId`],
      dataTut:"groups-menu"
    },
    {
      label: "Reports",
      iconClass: nav_report(),
      iconStyle: {},
      link: reports,
      subPaths: [reports],
      dataTut:"reports-menu"
    },
    {
      label: "News",
      iconClass: nav_newsicon(),
      iconStyle: { paddingTop: "2px" },
      link: news,
      subPaths: [news],
      dataTut:"news-menu"
    },
    
  ];
// getAllUsedTemplates function
export function getAllUsedTemplates(startups) {
  /*

    Returns array of all evaluation templates used in this group.
    Takes groupGetV2.startups and returns an array like this:

    [
      {
        templateId: String,
        templateName: String
      }
    ]

  */
  
  // MAPS AND REDUCERS
  if (!startups || !startups.length) return [];

  let obj = {};
  for (let item of startups) {
    let evaluationSummaries = item?.evaluationSummaries || [];
    for (let t of evaluationSummaries) {
      obj[t?.templateId] = t?.templateName;
    }
  }

  let arr = [];
  for (let templateId in obj) {
    arr.push({ value: templateId, label: obj[templateId] });
  }

  return arr;
}

// getSubjectiveScoreSummary FUNCTION
export function getSubjectiveScoreSummary(startup) {
  // Gets subjective score summary for each StartupPage.

  // MAPS AND REDUCERS
  let scores = startup?.subjectiveScores?.map(({ score }) => score);
  if (!scores || !scores.length) return;

  let min = Math.min(...scores);
  let max = Math.max(...scores);
  let average = (scores.reduce((a, b) => a + b, 0) / scores.length).toFixed(1);
  let submissions = scores.length;

  return {
    min,
    max,
    average,
    submissions,
  };
}

// getEvaluationsByTemplate FUNCTION
export function getEvaluationsByTemplate(startup) {
  /*

    Groups Evaluations by evaluation template for each StartupPage

    [
      {
        summary: Summary,          <- templateId, templateName, etc...
        Evaluations: [Evaluation]  <- each evaluation
      }
    ]

  */

  // MAPS AND REDUCERS
  return startup?.evaluationSummaries?.map(summary => ({
    summary,
    evaluations: startup.evaluations?.filter(
      evaluation => evaluation?.templateId === summary?.templateId
    ),
  }));
}


// getEvaluationsByTemplate FUNCTION
export function getExternalEvaluationsByTemplate(startup) {

  let res = startup?.externalEvaluationSummaries?.map(summary => ({
    summary,
    evaluations: startup?.externalEvaluations?.filter(
      evaluation => evaluation?.template?.id === summary?.templateId
    )
  }))
  // MAPS AND REDUCERS
  return res;
}

// getTasks from a startup
export function getTasksForStartup({startup, group}){
  let data = [];
  // If the startup has a creative (should always do)
  if (startup?.creative) {

    // Get my actual evaluations
    let myEvaluations = startup?.connection?.evaluations?.filter(({ isMe }) => isMe)


    // Get my SHARED evaluations
    let mySharedEvaluations = startup?.evaluations?.filter(
      ({ createdByUser }) => createdByUser.isMe
    )

    // Get the latest timestamp for when we shared
    let latestTimeStamp = Math.max( ...mySharedEvaluations.map(({updatedAt}) => updatedAt) )


    let object = {
      creativeName: startup?.creative?.name,
      creativeId: startup?.creative?.id,
      connectionId: startup?.connection?.id,
      latestTimeStamp: isFinite(latestTimeStamp) && latestTimeStamp,
      templates: [],
    }


    // Go over the requested evaluations
    for (let requestedEvaluation of group?.evaluationTemplates) {

      // AddStartupModal cuts
      let { id, name } = requestedEvaluation;

      // Match my shared evaluations with requested evaluations
      let userHaveSharedEvaluation = mySharedEvaluations?.find(({ templateId }) => id === templateId)
      let userHaveEvaluated = myEvaluations?.find(({ templateId }) => id === templateId)

      // Define the item
      let item = {
        templateId: id,
        name: name,
        sharedEvaluation: !!userHaveSharedEvaluation,
        haveEvaluated: !!userHaveEvaluated,
        evaluationId: userHaveEvaluated?.id
      }

      object.templates.push(item)

    }

    let hasCompletedAllTasks = object.templates.every(({sharedEvaluation}) => sharedEvaluation);

    data.push({ ...object, hasCompletedAllTasks })

  }
  return data;
}

// getTasks from a group
export default function getTasks({group}) {

  let data = [];

  // Go over all the startups in the group
  for (let startup of (group?.startups || [])) {

    // If the startup has a creative (should always do)
    if (startup?.creative) {

      // Get my actual evaluations
      let myEvaluations = startup?.connection?.evaluations?.filter(({ isMe }) => isMe)


      // Get my SHARED evaluations
      let mySharedEvaluations = startup?.evaluations?.filter(
        ({ createdByUser }) => createdByUser.isMe
      )

      // Get the latest timestamp for when we shared
      let latestTimeStamp = Math.max( ...mySharedEvaluations.map(({updatedAt}) => updatedAt) )

      let object = {
        creativeName: startup?.creative?.name,
        creativeId: startup?.creative?.id,
        connectionId: startup?.connection?.id,
        latestTimeStamp: isFinite(latestTimeStamp) && latestTimeStamp,
        templates: [],
      }

      // Go over the requested evaluations
      for (let requestedEvaluation of group?.evaluationTemplates) {

        // AddStartupModal cuts
        let { id, name } = requestedEvaluation;

        // Match my shared evaluations with requested evaluations
        let userHaveSharedEvaluation = mySharedEvaluations?.find(({ templateId }) => id === templateId)
        let userHaveEvaluated = myEvaluations?.find(({ templateId }) => id === templateId)

        // Define the item
        let item = {
          templateId: id,
          name: name,
          sharedEvaluation: !!userHaveSharedEvaluation,
          haveEvaluated: !!userHaveEvaluated,
          evaluationId: userHaveEvaluated?.id
        }

        object.templates.push(item)

      }

      let hasCompletedAllTasks = object.templates.every(({sharedEvaluation}) => sharedEvaluation);

      data.push({ ...object, hasCompletedAllTasks })

    }

  }

  // Sort the data
  data.sort((a, b) => a.latestTimeStamp - b.latestTimeStamp)


  // Startups with tasks
  let res = data
  // Don't display if all tasks are completed
  .filter(({hasCompletedAllTasks}) => !hasCompletedAllTasks)
  // Don't display if startup is not in our dealflow
  .filter(({connectionId}) => connectionId)

  // Return startups with tasks
  return res;

}