import React from 'react'

// Styles
import "./StartupLogo.scss"

// Main Function
export const StartupLogo = ({ url, size, startupName, reSize, logoStyle }) => {
    return (
        <div className={`logo logo__${size} ${logoStyle} ${reSize} ${!url && 'logo-bg'}`} >
            {url ? <img src={url} alt='' /> : <span>{startupName?.charAt(0).toUpperCase()}</span>}
        </div>
    )
}
 