import React from 'react';
import { filter } from 'lodash';

//STYLES
import './Answers.scss';
import { Card } from 'Components/NotataComponents';

export default function Answers({questions, sectionName, answers}){
  const getAnswer = (question) => {
    let allAnswers = filter(answers,['questionId', question.id]);
    return allAnswers.length
      ? allAnswers.map((ans, index) => <div key={index} className="question__answer">{ans.val}</div>)
      : <div className="not_answer">Not answered</div>
  }
  return(
    <Card containerClassName="card answer-container aswer_data" heading={sectionName}>

      {questions.map((question,index)=>{
        return <div className="question" key={`${question.id}-${index}`}>
          <div className="question__heading">{question.name}</div>
          <div className="question__description">{question.description}</div>
          {getAnswer(question)}
        </div>
      })}

    </Card>
  )
}