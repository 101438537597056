import React, { useState } from "react";

//styles
import "./Dropdown.scss";

// Main function
export function Dropdown({ items = [], setSelected, setSelectedItem, selectedDefaultItem}) {

  // States
  const [isListOpen, setListOpen] = useState(false);
  const [name, setName] = useState("Select...");

  const selectedOption = item => {
    setName(item.name);
    if (setSelected) {
      setSelected(item.id);
    }
    if (setSelectedItem) {
      setSelectedItem(item);
    }
    setListOpen(!isListOpen);
  };

  return (

    <div className="dd_wrapper">

      {items.length === 1 || selectedDefaultItem && (
        <>
          <div
            role="button"
            className="dd_header"
            onClick={() => {
              setListOpen(!isListOpen);
            }}
          >
          <div className="dd_header_title">{selectedDefaultItem ? selectedDefaultItem.name : items[0].name}</div>
          </div>
          {isListOpen && (
            <div role="list" className="dd_list">
              {items?.map(item => (
                <button
                  className={
                    item.name === name
                      ? "selected_bg dd_list_item"
                      : "dd_list_item"
                  }
                  key={item.id}
                  type="button"
                  onClick={() => {
                    selectedOption(item);
                  }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}
        </>
      )}


      {
        items.length !== 0 && !selectedDefaultItem && (
        <>
          <div
            role="button"
            className="dd_header"
            onClick={() => {
              setListOpen(!isListOpen);
            }}
          >
            <div className="dd_header_title">{name}</div>

            {isListOpen ? (
              <i className="fal fa-angle-up  i_icon" />
            ) : (
              <i className="fal fa-angle-down i_icon" />
            )}
          </div>
          {isListOpen && (
            <div role="list" className="dd_list">
              {items?.map(item => (
                <button
                  className={
                    item.name === name
                      ? "selected_bg" + " " + "dd_list_item"
                      : "dd_list_item"
                  }
                  key={item.id}
                  type="button"
                  onClick={() => {
                    selectedOption(item);
                  }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}
        </>

      )}

    </div>
  );
}
