import React, { useEffect, useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import {connectionPut } from "private/Apollo/Mutations";

// ASSETS
import images from "./Images";

// MAIN FUNCTION
export default function ImpactGoalsComp({ connection }) {

// STATES
const [activeImage, setActiveImage] = useState([]);

// MUTATION
let [setGoals] = useMutation(connectionPut);

// EFFECTS
useEffect(() => {
  if (!connection?.impactGoals) {
    return;
  }
  setActiveImage(connection?.impactGoals.map(({ key }) => key));
}, [connection]);

return (
  <div >
    <div className="tags-container__sub-heading">
      What UN impact goals fit for this startup?
    </div>
    <div>
      {images.map(item => {
        return (
          <div
            key={item.key}
            onClick={() => {
              let isSelected = activeImage?.find(key => key === item.key);

              let selected;

              // remove
              if (isSelected) {
                selected = activeImage.filter(key => key !== item.key);
              }

              // add
              if (!isSelected) {
                selected = [...new Set([...activeImage, item.key])];
              }

              setActiveImage(selected);

              let variables = {
                id: connection.id,
                input: {
                  impactGoals: selected.map(n => ({ key: n, val: n })),
                },
              };

              setGoals({ variables });
            }}
            className={`col-sm-4 col-md-3 col-lg-2 col-xs-4 img-col ${
              activeImage.includes(item.key) ? "active-img-col" : ""
            }`}            
          >
            <img src={item.src} alt="" />
          </div>
        );
      })}
    </div>
  </div>
);
}