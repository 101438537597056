import React, { useEffect, useState } from "react";
import { RadioButton, InputCheckBox, InputFormWithButton } from "Components/NotataComponents";
import "./Settings.scss";
import { Dropdown } from "../../../../NotataComponents";
import { accountGet } from "../../../../../private/Apollo/Queries";
import { useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import validateEmail from "../../../../../utils/validateEmail";

import { CodeSnippet } from "Components/NotataComponents";

function EmailInput({emails, setEmails}) {

  const [isEmail, setIsEmail] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  // useForm
  const { register, handleSubmit, formState:{errors}, setValue } = useForm({
    resolver:  yupResolver(
      yup.object().shape({
        email: yup.string().email().required(),
      })
    ),
  });

  // ONSUBMIT FUNCTION
  async function onSubmit({ email }) {


    setEmails([
      ...emails,
      email.toLowerCase().trim()
    ])

    setValue("email", "");
    setIsEmail(false);
  }

  return (
    <form
      className="search_form"
      onSubmit={handleSubmit(onSubmit)}>
      <div className="search">

        {/* search-box */}
        
        <InputFormWithButton
          type="email"
          name="email"
          placeholder="name@email.com"
          fullWidth
          isEmail
          reference={register}
          handleChange={(e)=>{
            setIsEmail(validateEmail(e.target.value));
            setIsDuplicate(emails?.some(email => email === e.target.value));
          }}
          btn={isEmail && !isDuplicate}
          setBtn={setIsEmail}
          btntext="add"
          buttonType="submit"
        />
      {/* <InputForm
          type="email"
          name="email"
          placeholder="name@email.com"
          reference={register}
          handleInputChange={(value) =>{
            setIsEmail(validateEmail(value));
            setIsDuplicate(emails?.some(email => email === value));
          }}
        /> */}
      </div>

      {isDuplicate && (
        <div className="is-duplicate">
          That user is already in the list
        </div>
      )}

      {/* {isEmail && !isDuplicate && (
        <div className="btnctn">
          <div className="submit-button-container">
            <NotataButton
              size="x_small"
              bgcolor="primary_color"
              buttonType="submit"
              text="add"
            />
          </div>
        </div>
      )} */}
    </form>
  )



}


// Main Function
const Settings = ({ group, data, setData }) => {

  // Queries
  const accountQuery = useQuery(accountGet);

  // Data maps
  let templates = accountQuery?.data?.accountGet?.evaluationTemplates || [];

  // Effects
  useEffect(() => {
    if (group?.settings !== data.settings) {
      for (let key in group?.settings) {
        setSettings(
          {key: group?.settings[key]}
        );
      }
    }
  }, [group?.settings]);

  // Effects
  useEffect(() => {
    if (group?.publicSettings !== data.publicSettings) {
      for (let key in group?.publicSettings) {
        setPublicSettings(
          { key: group?.publicSettings[key] }
        )
      }
    }
  }, [group?.publicSettings]);

  // Functions
  const setSettings = newSettings => {
    let settingData = {
      ...data,
      settings: {
        ...data.settings,
        ...newSettings
      },
    }
    setData(settingData);
  }

  // Functions
  const setPublicSettings = newSettings => {
    setData({
      ...data,
      publicSettings: {
        ...data.publicSettings,
        ...newSettings
      },
    });
  }

  return (
    <div className="settings-containers">
      {(!group || group.iAmAdmin) && (
        <>

          <form>


            {/* VISIBILITY */}
            <div>

              {/* ... visibility header */}
              <div className="header">
                <span className="material-icons">visibility</span>
                <span className="heading">Allow members to see ...</span>
             </div>

              <div className="options">

                {/* ... show summaries */}
                <div className="option">
                  <InputCheckBox
                    name="showSummaries"
                    value="showSummaries"
                    onChange={() => {
                      if (data?.settings?.showSummaries) {
                        setSettings({
                            "showSummaries": false,
                            "showScores": false
                          });
                      } else {
                        setSettings(
                          { "showSummaries": true }
                        );
                      }
                    }}
                    checked={!!data?.settings?.showSummaries}
                    label="score summaries"
                  />
                </div>


                {/* ... show scores */}
                {
                  data?.settings?.showSummaries && (
                    <div className="option">
                      <InputCheckBox 
                        name="showScores"
                        value="showScores"
                        onChange={() => {
                          setSettings(
                            { "showScores": !data?.settings?.showScores }
                          );
                        }}
                        checked={!!data?.settings?.showScores}
                        label="each other, including individual scores."
                      />
                    </div>
                  )
                }

              </div>
            </div>


            {/* SHARING */}
            <div>

              {/* ... sharing header */}
              <div className="header">
                <span className="material-icons">share</span>
                <span className="heading">Allow members to share ...</span>
              </div>

              <div className="options">

                {/* ... comments */}
                <div className="option">
                  <InputCheckBox
                    name="chat"
                    value="chat"
                    onChange={() => {
                      setSettings(
                        { "chat": !data?.settings?.chat }
                      );
                    }}
                    checked={!!data?.settings?.chat}
                    label="comments"
                  />
                </div>

                {/* ... evaluation */}
                <div className="option">
                  <InputCheckBox
                    name="addEvaluation"
                    value="addEvaluation"
                    onChange={() => {
                      setSettings(
                        { "addEvaluation": !data?.settings?.addEvaluation }
                      );
                    }}
                    checked={!!data?.settings?.addEvaluation}
                    label="evaluations"
                  />
                </div>

                {/* ... share startup */}
                <div className="option">
                  <InputCheckBox
                    name="addStartup"
                    value="addStartup"
                    onChange={() => {
                      setSettings(
                        {"addStartup": !data?.settings?.addStartup}
                      );
                    }}
                    checked={!!data?.settings?.addStartup}
                    label="startups"
                  />
                </div>
              </div>
            </div>

            {/* PRIVACY */}
            <div>

              {/* ... privacy header */}
              <div className="header">
                <span className="material-icons">lock</span>
                <span className="heading">Privacy</span>
              </div>

              <div className="options privacy_option">




                {/* ... private */}
                <div className="option">
                  <RadioButton
                    name="isOpen"
                    value="isOpen"
                    label="PRIVATE: everybody has to be invited"
                    id="privacy-private"
                    checked={data?.settings?.isOpen === false}
                    handleChange={() => {
                      setSettings(
                        {"isOpen": false}
                      )
                    }}
                  />
                </div>


                {/* ... open */}
                <div className="option">
                  <RadioButton
                    name="isOpen"
                    value="isOpen"
                    label="OPEN: anyone on notata can join"
                    id="privacy-open"
                    checked={data?.settings?.isOpen === true}
                    handleChange={() => {
                      setSettings(
                        {"isOpen": true}
                      )
                    }}
                  />
                </div>


                {/* ... public */}
                <div className="option">

                  <InputCheckBox
                    name="isPublic"
                    value="isPublic"
                    label="PUBLIC: anyone with the link can see group"
                    id="privacy-public"
                    checked={data?.settings?.isPublic === true}
                    onChange={() => {
                      let isPublic = data?.settings?.isPublic === true
                      setSettings(
                        {"isPublic": !isPublic}
                      )
                    }}
                  />
                </div>












                {/*/!* ... private *!/*/}
                {/*<div className="option">*/}
                {/*  <RadioButton*/}
                {/*    name="isPublic"*/}
                {/*    value="isPublic"*/}
                {/*    label="PRIVATE: everybody has to be invited"*/}
                {/*    id="privacy-private"*/}
                {/*    checked={data?.settings?.isPublic === false}*/}
                {/*    handleChange={() => {*/}
                {/*      setSettings(*/}
                {/*        {"isPublic": false}*/}
                {/*      )*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</div>*/}

                {/*/!* ... public *!/*/}
                {/*<div className="option">*/}
                {/*  <RadioButton*/}
                {/*    name="isPublic"*/}
                {/*    value="isPublic"*/}
                {/*    label="PUBLIC: anyone with the link can see group"*/}
                {/*    id="privacy-public"*/}
                {/*    checked={data?.settings?.isPublic === true}*/}
                {/*    handleChange={() => {*/}
                {/*      setSettings(*/}
                {/*        {"isPublic": true}*/}
                {/*      )*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</div>*/}


                {/*/!* ... open *!/*/}
                {/*<div className="option">*/}
                {/*  <InputCheckBox*/}
                {/*    name="showSummaries"*/}
                {/*    value="showSummaries"*/}
                {/*    onChange={() => {*/}
                {/*      let isOpen = data?.settings?.isOpen === true*/}
                {/*      setSettings(*/}
                {/*        {"isOpen": !isOpen}*/}
                {/*      )*/}
                {/*    }}*/}
                {/*    checked={data?.settings?.isOpen === true}*/}
                {/*    label="OPEN: anyone on notata can join"*/}
                {/*  />*/}
                {/*</div>*/}


              </div>
            </div>




            {
              data?.settings?.isPublic === true && (
                <div>
                  <div className="header">
                    <span className="material-icons">public</span>
                    <span className="heading">Public Group Settings</span>
                  </div>
                  <div className="border">
                  <div className="options">

                    <div className="option">
                      <InputCheckBox
                        name="pinRequired"
                        value="pinRequired"
                        onChange={() => {
                          setPublicSettings(
                            {"pinRequired": !data?.publicSettings?.pinRequired}
                          );
                        }}
                        checked={!!data?.publicSettings?.pinRequired}
                        label="Protect with PIN code"
                      />
                    </div>

                    {
                      !!data?.publicSettings?.pinRequired && (
                        <div className="pin-sequence">
                          {data?.pin?.toString().split("").map((p, i) => (
                            <span key={`${p}-${i}`}>{p}</span>
                          ))}
                        </div>
                      )
                    }

                    <div className="option">
                      <InputCheckBox
                        name="withSubjectiveScores"
                        value="withSubjectiveScores"
                        onChange={() => {
                          setPublicSettings(
                            {"withSubjectiveScores": !data?.publicSettings?.withSubjectiveScores}
                          )
                        }}
                        checked={!!data?.publicSettings?.withSubjectiveScores}
                        label="Enable subjective score"
                      />
                    </div>

                    <div className="option">
                      <InputCheckBox
                        name="withEvaluationTemplate"
                        value="withEvaluationTemplate"
                        onChange={() => {
                          setPublicSettings(
                            {"withEvaluationTemplate": !data?.publicSettings?.withEvaluationTemplate}
                          )
                        }}
                        checked={!!data?.publicSettings?.withEvaluationTemplate}
                        label="Enable evaluation"
                      />
                    </div>

                    <div className="setting_invite">
                      {
                        !!data?.publicSettings?.withEvaluationTemplate && (
                          <Dropdown
                            title="Evaluation Template"
                            placeholder="Select Evaluation Template"
                            items={templates}
                            selectedDefaultItem={data?.publicSettings?.evaluationTemplate}
                            setSelected={item => {
                              setPublicSettings(
                                {"evaluationTemplateId": item}
                              )
                            }}
                          />
                        )
                      }
                    </div>

                    <div className="option">
                      <InputCheckBox
                        name="invitedOnly"
                        value="invitedOnly"
                        onChange={() => {
                          setPublicSettings(
                            {"invitedOnly": !data?.publicSettings?.invitedOnly}
                          );
                        }}
                        checked={!!data?.publicSettings?.invitedOnly}
                        label="By invitation only"
                      />
                    </div>


                    {
                      !!data?.publicSettings?.invitedOnly && (
                        <div className="setting_invite">

                          <div className="invited_emails">
                            {
                              data?.publicSettings?.invitedUsers?.map((email, i) => (
                                <div key={`${i}-${email}`} className={"invited_emails_container"}>
                                  <span
                                    onClick={() => {
                                      setPublicSettings(
                                        {
                                          "invitedUsers": data?.publicSettings?.invitedUsers?.filter(invited => invited !== email)
                                        }
                                      )
                                    }}
                                  >
                                    <i className="fal fa-trash-alt"/>
                                  </span>
                                  <span>{email}</span>
                                </div>
                              ))
                            }
                          </div>

                          <EmailInput
                            emails={data?.publicSettings?.invitedUsers || []}
                            setEmails={emails => {
                              setPublicSettings(
                                {"invitedUsers": emails}
                              );
                            }}
                          />
                        </div>
                      )
                    }

                    <div className="settings_url">
                     <CodeSnippet
                        heading="This is your public link:"
                        content={data?.publicSettings?.link}
                        contentType="Link"
                      />
                    </div>

                    <div className="settings_url">
                      <CodeSnippet
                        heading="This is you embeddable link (paste it where you want to show this list on your site):"
                        content={
                          `<div id="notata-group-plugin" dataVal="${group.id}"></div><script type="text/javascript" src="https://notata.io/public_group_plugin.js"></script>`
                        }
                        contentType="Link"
                      />
                    </div>

                  </div>
                </div>
              </div>
            )
          }

        </form>
        </>
      )}
    </div>
  );
};

export default Settings;
