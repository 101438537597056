import React, { useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";

// UI COMPONENTS
import { InputForm, RadioButton } from "Components/NotataComponents";

// STYLES
import './ShareStartup.scss';


// MAIN FUNCTION
export default function ShareStartup({
    connection, 
    createPublicSharing, 
    createShareInput, 
    setCreateShareInput, 
    evaluationTemplates,
    setTextError,
    textError,
    email,
    message,
    register
}) {
   
    // STATES
    const [emails, setEmail] = useState("");

    const {handleSubmit} = useForm({
        defaultValues: {
            email: createShareInput?.sharedWithEmail ?? "",
            message: createShareInput?.message ?? "",
        }
    })
    
    // Filter admin Evaluations
    let myEvaluations = connection?.evaluations?.filter(({ isMe, template }) => isMe && template?.name);

    // Handlers
    const onSubmit = (e) => {
        createPublicSharing({
            connectionId : connection.id,
            sharedWithEmail:  email
        })
    }

    const handleInput = val => {
        setEmail(val);
        setCreateShareInput({
            ...createShareInput,
            sharedWithEmail : val
        })
    }
    const handleEvaluationChange = id => {
        setCreateShareInput({
            ...createShareInput,
            sharedEvaluation:{
                ...createShareInput?.sharedEvaluation,
                id
            }
        })
    }
    const handleTemplateChange = id => {
        setCreateShareInput({
            ...createShareInput,
            evaluationTemplate:{
                ...createShareInput?.evaluationTemplate,
                id
            }
        })
    }

    return (
        <div className="share-evaluation-container">
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className='share-evaluation-container__input-div'>
                    <InputForm
                        label={`Email`}
                        type="email"
                        name={email}
                        placeholder="Email"
                        defaultValue={createShareInput?.sharedWithEmail ?? ""}
                        handleInputChange={(value) =>
                            handleInput(value)
                        }
                        fullWidth={true}
                        disabled={createShareInput?.id}
                        reference={register({ required: true })}
                    />
                </div>
                <div className="share-evaluation-container__heading">Message <span className="req_star">*</span></div>
                <textarea
                    placeholder="Message"
                    rows={5}
                    defaultValue={createShareInput?.message ?? ""}
                    name={message}
                    ref={register({required:true})}
                    className={textError?"text_box_err":""}
                    onBlur={(e)=>{
                    if(e.target.value==="") {
                        setTextError(true)
                    }else{
                        setTextError(false)
                    }
                    }}                          
                    onChange={e =>{                  
                        setCreateShareInput({
                            ...createShareInput,
                            message : e.target.value
                        })
                    }
                    }
                />
                <p style={{color:"red"}}>{textError ? "Text field cannot be blank":""}</p>
                <div className="share-evaluation-container__heading">Share evaluation</div>
                {myEvaluations.length<1 && <span className="my_evaluation">You have not evaluated this company yet</span>}
                {
                    myEvaluations?.map((evaluation, i) => (
                       <div className="radio_btn">
                             <RadioButton
                            key={evaluation.id} 
                            name="evaluation"
                            value={createShareInput?.sharedEvaluation?.id}
                            label={`${evaluation?.template?.name} - ${moment(evaluation.createdAt).format("ll")}`}
                            id="evaluation"
                            checked={createShareInput?.sharedEvaluation?.id === evaluation.id}
                            handleChange={() => {
                                handleEvaluationChange(evaluation.id)
                            }}
                        />
                       </div>
                    ))
                }
                <div className="share-evaluation-container__heading">Request evaluation</div>
                {
                    evaluationTemplates?.map((evaluation, i) => (
                        <div className="radio_btn">
                            <RadioButton
                            key={evaluation.id} 
                            name="template"
                            value={createShareInput?.evaluationTemplate?.id}
                            label={evaluation?.name}
                            id="template"
                            checked={createShareInput?.evaluationTemplate?.id === evaluation.id}
                            handleChange={() => {
                                handleTemplateChange(evaluation.id)
                            }}
                        />
                        </div>
                    ))
                }
            </form>
        </div>
    );
}