import React from "react";

// CUSTOM COMPONENTS
import DashboardRecentlyAddedStartups from "./DashboardComponents/DashboardRecentlyAddedStartups";
import DashboardGroupInvitations from ".//DashboardComponents/DashboardGroupInvitations";
import DashboardMyStartups from "./DashboardComponents/DashboardMyStartups";
import DashboardNews from "./DashboardComponents/DashboardNews";
import DashboardNotifications from "./DashboardComponents/DashboardNotifications";
import DashboardReports from "./DashboardComponents/DashboardReports/DashboardReports";
import { useQuery } from "@apollo/client";
import { reportSettingsGet } from "../../Apollo/Queries";

// STYLES
import "./Dashboard.scss";

// MAIN FUNCTION
export default function Dashboard({ history }) {
  //Query
  const reportsSettings = useQuery(reportSettingsGet);
  const reportsSettingData = reportsSettings?.data?.reportSettingsGet;
  const reportsSettingTagGroup = reportsSettingData?.dashboardTagGroup;
  return (
    <>
      <div className="dashboard-container">
        <div className="row dashboard">
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 dashboard_card">
            <DashboardRecentlyAddedStartups history={history} />
          </div>

          <div className="col-lg-4 col-md-6  col-sm-12 col-xs-12 dashboard_card">
            <DashboardGroupInvitations history={history} />
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 dashboard_card">
            <DashboardMyStartups history={history} />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 dashboard_card">
            <DashboardNews history={history} />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 dashboard_card">
            <DashboardNotifications history={history} />
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 dashboard_card">
            <DashboardReports
              history={history}
              reportsSettingTagGroup={reportsSettingTagGroup}
            />
          </div>
        </div>
      </div>
    </>
  );
}
