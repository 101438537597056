import React from "react";
//  import "./TrafficLights.scss";

export default function TrafficLights({
  templateId,
  section,
  question,
  answers,
  setAnswers,
}) {
  // Get current answer from array of answers
  const answer = answers?.find(
    ({ inputType, questionId }) =>
      inputType === question.inputType && questionId === question.id
  );

  function handleSubmit(color) {
    // Skip if answer don't exist, and text field is empty
    // (if it does exist we need to be able to save an empty field)
    // if (!answer) {
    //   return;
    // }

    console.log('handle traffic light')

    // Create answer object
    let answerObject = {
      sectionId: section.id,
      questionId: question.id,
      sectionName: section.name,
      questionName: question.name,
      inputType: question.inputType,
      sid: "",
      val: color,
    };

    // Remove current answer from list of answers
    let otherAnswers = answers.filter(
      ({ inputType, questionId }) =>
        !(inputType === question.inputType && questionId === question.id)
    );

    // let comments = answers.filter(a => a.inputType === "COMMENT");
    // console.log("answerObject", answerObject);

    // Join the data
    let newAnswers = [
      ...otherAnswers,
      // ...comments,
      answerObject
    ];

    console.log('newAnswers.length', newAnswers.length)

    // Set data
    setAnswers(newAnswers);
  }

  return (
    <div className="traffic-lights-container">
      <div
        className="option-container text-center"
        onClick={() => handleSubmit("red")}
      >
        <div className="">
          <div
            className={`traffic-light ${answer?.val === "red" ? "redActive" : "inactive"
              }`}
          >
            <div className="highlighter red" >
              <div className="text">RED</div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="option-container text-center"
        onClick={() => handleSubmit("yellow")}
      >
        <div className="">
          <div
            className={`traffic-light ${answer?.val === "yellow" ? "yellowActive" : "inactive"
              }`}
          >
            <div className="highlighter yellow">
              <div className="text">YELLOW</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="option-container text-center"
        onClick={() => handleSubmit("green")}
      >
        <div className="">
          <div
            className={`traffic-light ${answer?.val === "green" ? "grenActive" : "inactive"
              }`}
          >
            <div className="highlighter green">
              <div className="text">GREEN</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
