import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { publicGroupGet } from "public/Apollo/Queries";
import { publicGroupSetSubjectiveScore } from "public/Apollo/Mutations";
import { Card, NotataButton } from "Components/NotataComponents";
import GridView from "./GridView";
import ListView from "./ListView";
import ThankYou from "./ThankYou";
import Footer from "./Footer";
import SubjectiveScoreModal from "private/Pages/MyStartups/Modals/SubjectiveScoreModal";
import EvaluateComponent from "./EvaluateComponent";
import { VIEWS } from "private/Pages/constants";
import { getStoredDetails, saveScoreToLocalDB } from "utils/groupSharing";
import { useHistory } from "react-router-dom";
import { GhostLoader } from "../../../Components/NotataComponents";
import StartupComponent from "./StartupComponent";
import ReactMarkdown from 'react-markdown';
export default function Content({groupId}) {

  const history = useHistory()
  const storedData = getStoredDetails(groupId);

  // States
  const [ subjectiveScoreSuccess, setSubjectiveScoreSuccess ] = useState(false)

  const [view, setView] = useState(VIEWS.LIST);
  const [subjectiveModal, setSubjectiveModal] = useState(false);
  const [evaluateModal, setEvaluateModal] = useState(false);
  const [thankYouModal, setThankYouModal] = useState(false);
  const [selectedStartUp, setSelectedStartUp] = useState({});
  const [viewStartup, setViewStartup] = useState(undefined)


  // Queries
  const queryOptions = { context: { clientName: "public" } };

  // Get group
  const { data, loading, error } = useQuery(publicGroupGet, {
    ...queryOptions,
    variables: {
      groupId,
      pin: storedData?.pin,
      ...storedData?.member
    }
  });

  // Mutation
  const [setSubjectiveScore] = useMutation(publicGroupSetSubjectiveScore, queryOptions);

  // Maps
  const group = data?.publicGroupGet;

  const saveScore = async (score) => {
    saveScoreToLocalDB(groupId, score, selectedStartUp.id);

    let variables = {
      groupId: groupId,
      creativeId: selectedStartUp.id,
      email: storedData?.member?.email,
      score,
      family_name: storedData?.member?.family_name,
      given_name: storedData?.member?.given_name,
      pin: storedData?.pin
    }

    try {
      await setSubjectiveScore({ variables })
    } catch (error) {
      console.log('error\n', error)
    }

  }

  if (loading && !data) {
    return (
      <GhostLoader/>
    )
  }

  if (thankYouModal) {
    window.scrollTo(0, 0);
    return (
      <ThankYou content={
        <div className="button-style">
          <NotataButton
            buttonStyle="primary_color big"
            startIcon="chevron_left"
            text="Go Back to Group"
            onClick={() => {
              setThankYouModal(false);
              setSelectedStartUp({})
              setViewStartup(undefined)
            }}
          />
        </div>
      }/>
    )
  }

  if (evaluateModal) {
    window.scrollTo(0, 0);
    return (
      <EvaluateComponent
        group={group}
        selectedStartUp={selectedStartUp}
        storedData={storedData}
        setEvaluateModal={setEvaluateModal}
        setViewStartup={setViewStartup}
        setThankYouModal={setThankYouModal}
      />
    )
  }

  if (viewStartup) {
    window.scrollTo(0, 0);
    return (
      <StartupComponent
        group={group}
        creativeId={viewStartup}
        close={() => setViewStartup(undefined)}
        setEvaluateModal={() => setEvaluateModal("STARTUP_VIEW")}
      />
    )
  }

  return (
    <>
      <div className="group-share-container">
        <div className="group-share-container__content">

          {/*HEADER SECTION*/}
          <Card
            containerClassName="group-share-container__content__card"
            heading={group?.name}
            >

            {
              group?.administrators && (
                <div className="group-share-container__content__card__description">
                  By: {group?.administrators[0]?.company || 'NA'}
                </div>
              )
            }

            {group?.tags && (
              <div className="group-share-container__content__card__tags">
                {group?.tags?.map(tag =>
                  <div
                    className="group-share-container__content__card__tags__tag"
                    key={tag}
                  >
                    {tag}
                  </div>
                )}
              </div>
              )}
            {/*{group?.description && (*/}
            {/*  <CardMessageContainer*/}
            {/*    message={group?.description}*/}
            {/*    notice*/}
            {/*  />*/}
            {/*  // pølse*/}
            {/*  */}
            {/*)}*/}

            {group?.description && (
              <div
                className="group-share-container__markdown"
              >
                <ReactMarkdown>
                  {group?.description}
                </ReactMarkdown>
              </div>

            )}
          </Card>

          {/*VIEW TYPE*/}
          <Card containerClassName="group-share-container__content__view-variants">
            <NotataButton
              startIcon="format_list_bulleted"
              text="List view"
              buttonStyle={`outlined ${view === VIEWS.LIST ? "selected" : ""}`}
              onClick={() => setView(VIEWS.LIST)}
            />
            <NotataButton
              startIcon="apps"
              text="Grid view"
              buttonStyle={`outlined ${view === VIEWS.GRID ? "selected" : ""}`}
              onClick={() => setView(VIEWS.GRID)}
            />
          </Card>

          {
            view === VIEWS.LIST &&
            <ListView
              setSelectedStartUp={(startup) => {
                setSelectedStartUp(startup);
              }}
              setSubjectiveModal={setSubjectiveModal}
              setEvaluateModal={() => setEvaluateModal("GROUP_VIEW")}
              history={history}
              group={group}
              setViewStartup={creativeId => {
                setSelectedStartUp(group.startups.find(({id}) => id === creativeId))
                setViewStartup(creativeId);
              }}
            />
          }

          {
            view === VIEWS.GRID &&
            <GridView
              setSelectedStartUp={(startup) => {
                setSelectedStartUp(startup);
              }}
              setSubjectiveModal={setSubjectiveModal}
              setEvaluateModal={() => {
                setEvaluateModal("GROUP_VIEW");
              }}
              history={history}
              group={group}
              setViewStartup={creativeId => {
                setSelectedStartUp(group.startups.find(({id}) => id === creativeId))
                setViewStartup(creativeId);
              }}
            />
          }
        </div>
        </div>

      {
        subjectiveModal && (
          <SubjectiveScoreModal
            score={(d) => {
              console.log('score')
              setSubjectiveScoreSuccess(true)
              saveScore(d);
            }}
            isPublic={true}
            connection={selectedStartUp}
            close={() => {
              console.log('close')
              setSubjectiveScoreSuccess(false)
              setSubjectiveModal(false);
              setSelectedStartUp({});
            }}
            submit={() => {
              console.log('submit')
              setSubjectiveScoreSuccess(false)
              setSubjectiveModal(false);
              setSelectedStartUp({});
            }}
            isSuccess={subjectiveScoreSuccess}
          />
        )
      }

      <Footer/>

    </>
  )
}