import React, { useState } from "react";

// API STUFF
import { useQuery } from "@apollo/client";
import { publicSharingsGet } from "private/Apollo/Queries";

// COMPONENTS
import { NotataButton, Card, Modal, Loader } from "Components/NotataComponents";
import PreviewStartup from "Components/Common/PreviewStartup/PreviewStartup";
import SharableLinkModal from "./modals/SharableLinkModal";
import ShareStartupModal from "./modals/ShareStartupModal";

// OTHER
import timeStamp from "utils/timeStamp";
import moment from "moment";
import { omit } from "lodash";
import "./PublicSharing.scss";


// MAIN FUNCTION
const PublicSharings = ({ connection }) => {

  // STATES: modals
  const [previewStartupModal, setPreviewStartupModal] = useState(false);
  const [shareStartupModal, setShareStartupModal] = useState(false);
  const [sharableLinkModal, setSharableLinkModal] = useState(false);

  // STATES: not modals
  const [sharingObject, setSharingObject] = useState(undefined);

  // Queries
  const publicSharingsQuery = useQuery(publicSharingsGet, {
    variables: { connectionId: connection?.id }
  });

  // MAPS AND REDUCERS
  const publicSharings = publicSharingsQuery?.data?.publicSharingsGet || []

  return (
    <>
      <Card containerClassName="" heading="Share with individuals">

        {
          publicSharingsQuery.loading && !publicSharingsQuery.data && (
            <Loader/>
          )
        }

        {/* If the list is empty */}
        {publicSharings.length === 0 && (
          <div className="card-empty-text">
            Here you can share with individuals one by one.
          </div>
        )}

        {publicSharings?.map((sharedObj, index) => {

          const evaluationIsRequested = sharedObj?.evaluationTemplate;
          const evaluationIsCompleted = sharedObj?.publicEvaluation;
          const hasSharedEvaluation = sharedObj?.sharedEvaluation;

          return (
            <div key={index}>
              <div className={`row col-md-12 ${index === 0 ? "" : "shared_border"}`} />

              <div className={`row share-container`}  >

                {/* Shared by section */}
                <div className="col-sm-3 col-xs-12">

                  <div className="shared-user-name" >
                    {sharedObj?.sharedWithEmail}
                  </div>

                  <div className="shared-user-name-mobile" >
                    {sharedObj?.sharedWithEmail}
                  </div>

                  <div className="shared-date-time">
                    <div>Shared:</div>
                    <div>{moment(sharedObj?.createdAt).format("lll")}</div>
                  </div>

                  <div className="last-opepned">
                    <div>Last Opened:</div>
                    <div>{timeStamp(sharedObj?.lastOpened)}</div>
                  </div>

                </div>

                {/* Requested evaluation */}
                <div className="col-sm-3 col-xs-12 ">

                  <div className={"evaluation-template"}>
                    Requested evaluation:
                  </div>


                  { // if evaluation has been requested
                    evaluationIsRequested && (
                      <div className={
                        evaluationIsCompleted
                          ? "evaluation-template__inrequest-complete"
                          : "evaluation-template__inrequest-uncomplete"
                      }>
                        {sharedObj?.evaluationTemplate?.name}
                        {evaluationIsCompleted && " (completed)"}
                        {(evaluationIsRequested && !evaluationIsCompleted) && " (not completed)"}
                      </div>
                    )
                  }

                  { // if evaluation has NOT been requested
                    !evaluationIsRequested && (
                      <div className={"share"}>
                        no evaluation requested
                      </div>
                    )
                  }


                </div>


                {/* shared_evaluation */}
                <div className="col-sm-3 col-xs-12 ">
                  <div className={`evaluation-template`}>
                    Shared evaluation:
                  </div>
                  <div className="templates ">
                    <div className="share">
                      {
                        hasSharedEvaluation && (
                          sharedObj?.sharedEvaluation?.template?.name
                        )
                      }
                      {
                        !hasSharedEvaluation && (
                          "no evaluation shared"
                        )
                      }
                    </div>
                  </div>
                </div>

                {/* Buttons */}
                <div className="col-sm-3 sharing-options">
                  <NotataButton
                    size="big"
                    bgcolor="primary_color"
                    startIcon="share"
                    text="Sharing options"
                    onClick={() => {
                      setShareStartupModal(true)
                      setSharingObject(sharedObj);
                    }}
                  />
                  <NotataButton
                    size="big"
                    bgcolor="outlined"
                    text="preview"
                    onClick={() => {
                      setPreviewStartupModal(true)
                      setSharingObject(sharedObj);
                    }}
                  />
                  <NotataButton
                    size="big"
                    bgcolor="outlined"
                    text="get sharable link"
                    onClick={() => {
                      setSharableLinkModal(true);
                      setSharingObject(sharedObj);
                    }}
                  />
                  <NotataButton
                    size="big"
                    startIcon="content_copy"
                    bgcolor="empty"
                    text="Duplicate sharing"
                    onClick={() => {
                      let omitFields = ['id', 'sharedWithEmail'];
                      setSharingObject(omit(sharedObj, omitFields));
                      setShareStartupModal(true);
                    }}
                  />
                </div>

              </div>
            </div>
          )
        })}

        <div className="button" style={{marginTop:"3%"}}>
          <NotataButton
            size="x_large"
            bgcolor="primary_color"
            startIcon="add"
            text="share with new individual"
            datatut="groups-add-new-group"
            onClick={() => {
              setShareStartupModal(true);
              setSharingObject(undefined);
            }}
          />
        </div>

      </Card>

      {/* Modals */}

      {
        sharableLinkModal && (
          <SharableLinkModal
            sharingObject={sharingObject}
            intermediate={() => {
              setShareStartupModal(false);
              setPreviewStartupModal(true);
            }}
            close={() => {
              setSharableLinkModal(false);
              setSharingObject(undefined);
            }}
          />
        )
      }

      {
        shareStartupModal && (
          <ShareStartupModal
            connection={connection}
            sharingObject={sharingObject}
            setSharingObject={setSharingObject}
            setSharableLinkModal={setSharableLinkModal}
            setShareStartupModal={setShareStartupModal}
            setPreviewStartupModal={setPreviewStartupModal}
          />
        )
      }

      {
        previewStartupModal && (
          <Modal
            title="Preview"
            submit={() => setPreviewStartupModal(false)}
            close={() => setPreviewStartupModal(false)}
            submitTxt="OK"
            innerClassName="large-modal"
            children={
              <PreviewStartup
                previewStartupModal={() => setPreviewStartupModal(false)}
                sharingObject={sharingObject}
              />
            }
          />
        )
      }

    </>
  )
}

export default PublicSharings;