import React, { useState, useEffect } from 'react';
// COMPONENTS
import { Modal, TagsModal } from "../../../../../Components/NotataComponents";
//IMAGES
import More from "assets/images/more.svg";

export default function TagFilter({ filters, setFilters }) {

    const [showModal, setShowModal] = useState(false);
    const [preSelectedTags, setPreSelectedTags] = useState(filters?.tags?.length > 0 ? filters.tags : []);
  
    // update preselectedtags filter if filters.tags is changed 
    useEffect(()=>{
      setPreSelectedTags(filters?.tags?.length > 0 ? filters.tags : []);
    },[filters])
  
    async function addTag(tag) {
      let tags = [...preSelectedTags, tag];
      setPreSelectedTags(tags);
      setFilters({ ...filters, tags});
    }
  
    async function removeTag(tagId) {
      let tags = preSelectedTags.filter(({ id }) => id !== tagId);
      setPreSelectedTags(tags);
      setFilters({ ...filters, tags });
    }

    return (
      <>
        <div className="addTagMain">
          <div className="row tags-container overview-tags">
            <div className="tags-container__heading">
              <h2>Tags</h2>
            </div>
            <div className="tags-container__sub-heading">
            <span className="tags-container__sub-heading__choose-tags">choose tags</span>
              {/* Write or choose tags */}
            </div>
            <div className='tags__placeholder'>
              
              {preSelectedTags?.length > 0
                && preSelectedTags?.slice(0, 2).map(el => (
                  <span
                    style={{
                      height: "100%",
                      color: "white",
                      padding: "2px 10px",
                      backgroundColor: "#555",
                      borderRadius: 15,
                      fontSize: 10,
                      marginTop: 1,
                      marginRight: 7,
                    }}
                    key={el.id}
                  >
                    {el?.group?.name}: {el?.name}
                  </span>
                ))
                }
              {preSelectedTags?.length > 2 && <img src={More} alt="" />}
              {/* <i
                className="fa fa-plus"
                aria-hidden="true"
                onClick={() => setShowModal(true)}
              /> */}
              <i className="fas fa-plus-circle" onClick={() => setShowModal(true)}/>
            </div>
          </div>
        </div>
  
        {showModal && (
          <Modal
            title="Add Tags"
            disableFoot={true}
            submit={() => {
              setShowModal(false);
            }}
            close={() => {
              setShowModal(false);
            }}
            submitTxt="Save"
            closeTxt="Cancel"
            children={
              <TagsModal
                addTag={addTag}
                removeTag={removeTag}
                preSelectedTags={preSelectedTags}
              />
            }
          />
        )}
      </>
    );
  }