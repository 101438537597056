import React, { useState } from "react";

// API
import { useQuery } from "@apollo/client";
import { funnelGroupGet } from "private/Apollo/Queries";

// COMPONENTS
import { Dropdown, Loader } from "Components/NotataComponents";

// STYLES
import styles from "../Spreadsheet/Table/styles.module.css";

//OTHERS
import Funnel from "../../StartupPage/Tabs/Overview/components/Funnel";


export default function AddFunnel({ connection }) {

  // States
  const [selectedGroupId, setSelectedGroupId] = useState("");

  // Queries
  const { data, loading } = useQuery(funnelGroupGet);

  // Data maps
  let funnelGroups = data?.accountGet?.funnelGroups || [];

  // Make sure we don't have any empty groups
  funnelGroups = funnelGroups.filter(items => items.funnelTags.length > 0)


  let selectedGroup;

  // Select group [0] if there is only one group
  if (funnelGroups.length === 1) {
    selectedGroup = funnelGroups[0]
  }

  // Select group from dropdown
  if (selectedGroupId) {
    selectedGroup = funnelGroups?.find(({ id }) => id === selectedGroupId)
  }


  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.group}>
      {
        !funnelGroups.length && (
          <p>You have no funnel groups</p>
        )
      }

      {
        selectedGroup && (
          <Funnel
            connection={connection}
            funnelGroup={selectedGroup}
          />
        )
      }

      {
        (funnelGroups.length > 1 && !selectedGroup) && (
          <div
            className={styles.groupChild}
            style={{ width: "100%" }}
          >
            <h2>Select funnel</h2>
            <div className={styles.groupDropContainer}>
              <Dropdown items={funnelGroups} setSelected={setSelectedGroupId} />
            </div>
          </div>
        )
      }


    </div>
  );
}
