function getLatestTimeStamp(startup, isMe) {

  let { evaluations, subjectiveScores, log } = startup;

  if (isMe) {
    evaluations = evaluations?.filter(({createdByUser}) => createdByUser.isMe)
    subjectiveScores = subjectiveScores?.filter(({isMe}) => isMe)
    log = log?.filter(({isMe}) => isMe)
  }

  let highestEvaluation = Math.max(evaluations?.map(({updatedAt}) => updatedAt))
  let highestSubjectiveScore = Math.max(subjectiveScores?.map(({createdAt}) => createdAt))
  let highestLog = Math.max(log?.map(({updatedAt}) => updatedAt))

  // Get the highest time stamps
  let highArray = [
    highestEvaluation,
    highestSubjectiveScore,
    highestLog
  ]

  // Flatten array of arrays
  highArray = highArray.flat()

  // Remove undefined
  highArray = highArray.filter(x => x)

  // Get the highest time stamp
  let highest = highArray.length && Math.max(...highArray) || 0

  return highest;
}

// Sort by startup added
function sortByName({startups}) {
  return startups?.sort((a, b) => {
    if(a?.creative?.name < b?.creative?.name) { return -1; }
    if(a?.creative?.name > b?.creative?.name) { return 1; }
    return 0;
  })
}

// Sort by startup added
function sortByAdded({startups}) {
  return startups?.sort((a, b) => b?.createdAt - a?.createdAt)
}

// Sort by subjective score
function sortStartupsBySubjectiveScore({startups}) {
  return startups?.sort((a, b) =>
    b?.avgSubjectiveScore - a?.avgSubjectiveScore
  )
}

// Sort by latest group activity
function sortByLatestActivity({startups}) {
  return startups?.sort((a, b) =>
    getLatestTimeStamp(b) - getLatestTimeStamp(a)
  )
}

// Sort by my latest activity
function sortByMyLatestActivity({startups}) {
  return startups?.sort((a, b) =>
    getLatestTimeStamp(b, true) - getLatestTimeStamp(a, true)
  )
}

function sortByMyEvaluation({templateId, startups}) {
  return startups?.sort((a, b) => {
    let evalA = a?.evaluations?.find(evaluation =>
      evaluation?.createdByUser?.isMe &&
      evaluation?.templateId === templateId
    )
    let evalB = b?.evaluations?.find(evaluation =>
      evaluation?.createdByUser?.isMe &&
      evaluation?.templateId === templateId
    )
    let summaryA = evalA?.summary?.scorePercent || 0;
    let summaryB = evalB?.summary?.scorePercent || 0;
    return summaryB - summaryA
  })

}

function sortByPrivateEvaluation({templateId, startups}) {
  return startups?.sort((a, b) => {
    let objB = b?.evaluationSummaries?.find(s => s.templateId === templateId);
    let objA = a?.evaluationSummaries?.find(s => s.templateId === templateId);
    let summaryA = objA?.averagePercentageScore || 0;
    let summaryB = objB?.averagePercentageScore || 0;
    return summaryB - summaryA
  })
}

function sortByPublicEvaluation({templateId, startups}) {
  return startups?.sort((a, b) => {
    let objB = b?.publicEvaluationSummaries?.find(s => s.templateId === templateId);
    let objA = a?.publicEvaluationSummaries?.find(s => s.templateId === templateId);
    let summaryA = objA?.averagePercentageScore || 0;
    let summaryB = objB?.averagePercentageScore || 0;
    return summaryB - summaryA
  })
}

export default function sortStartups({
  sortField,
  sortByCategory,
  group,
  startups,
  adminView
  }) {

  let settings = group?.settings;
  let hideSummaries = !settings?.showSummaries && !adminView;
  let evaluationTemplate = group?.evaluationTemplates && group?.evaluationTemplates[0];
  let withEvaluation = settings?.addEvaluation && evaluationTemplate;
  let withPublicSubjectiveScore = group?.publicSettings?.withSubjectiveScores;
  let withPublicEvaluation = group?.publicSettings?.withEvaluationTemplate && group?.publicEvaluationTemplate;


  if (sortField === "alpha" || !sortField) {
    return startups?.sort((a, b) => {
      if(a?.creative?.name?.trim().toLowerCase() < b?.creative?.name?.trim().toLowerCase()) { return -1; }
      if(a?.creative?.name?.trim().toLowerCase() > b?.creative?.name?.trim().toLowerCase()) { return 1; }
      return 0;
    })
  }

  if (sortField === "evaluation") {

    // SORT BY PRIVATE EVALUATION
    if (sortByCategory === 'private' && withEvaluation) {
      let sorted = sortByPrivateEvaluation({templateId: evaluationTemplate?.id, startups})
      return sorted;
    }

    // SORT BY ME
    if (sortByCategory === 'me' && withEvaluation) {
      let sorted = sortByMyEvaluation({templateId: evaluationTemplate?.id, startups})
      return sorted;
    }


    // SORT BY PUBLIC EVALUATION
    if (sortByCategory === 'public' && withPublicEvaluation) {
      return sortByPublicEvaluation({templateId: group?.publicEvaluationTemplate?.id, startups})
    }

  }

  if (sortField === "subjectiveScore") {

    // SORT BY MY SUBJECTIVE SCORE
    if (sortByCategory === 'me' && settings?.addEvaluation) {
      return startups?.sort((a, b) =>
        (b?.subjectiveScores?.find(({isMe}) => isMe)?.score || 0) - (a?.subjectiveScores?.find(({isMe}) => isMe)?.score || 0)
      )
    }

    // SORT BY PRIVATE SUBJECTIVE SCORE
    if (sortByCategory === 'private' && settings?.addEvaluation) {
      return startups?.sort((a, b) =>
        b?.avgSubjectiveScore - a?.avgSubjectiveScore
      )
    }

    // SORT BY PUBLIC SUBJECTIVE SCORE
    if (sortByCategory === 'public' && withPublicSubjectiveScore) {
      return startups?.sort((a, b) =>
        b?.publicAvgSubjectiveScore - a?.publicAvgSubjectiveScore
      )
    }

  }


  return startups

}