import gql from "graphql-tag";

export default gql`
    fragment CreativeAnswerFields on CreativeAnswer {
        inputType
        sectionId
        sectionName
        questionId
        questionName
        questionId
        val
        sid
    }
`



