import gql from "graphql-tag";
import { reportSettingsFragments } from "../Fragments";

export default gql`
  query reportSettingsGet{
    reportSettingsGet {
      ...reportSettingsFields
    }
  }
  ${reportSettingsFragments}
`;
