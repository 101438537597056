//Main Route
export const frontpage = "/";

// SHARING
export const link_bridge = `/link`;

//Signup
export const signup = "/signup";
export const awaiting = "/awaiting";

//login
export const login = "/login";
export const forgotPassword = "/forgotPassword";
export const waiting_list = "/waiting_list";


//signout
export const signOut = "/signout";

//Profile
export const pre_profile = "/profile";



//MyStartup

export const startup_print_page = "/startup_print_page";
export const startup_page = "/startup_page";
export const startup_company_profile = `${startup_page}/company/:id`;
export const archive_page = `${startup_page}/archive`;
export const evaluate_page = `${startup_page}/evaluate`;
export const evaluation_summary_page = `${startup_page}/summary`;
//export const evaluation_summary = `${startup_page}/evaluation/summary`
export const sharing_preview = `${startup_page}/preview`

//Dashboard
export const dashboard = `${startup_page}/dashboard`;

//Group
export const group = `${startup_page}/groups`;
export const group_new = `${startup_page}/group_new`;
export const group_dashboard = `${startup_page}/group/group_dashboard`;
export const group_qr = `${startup_page}/group/group_qr`;

//Reports
export const reports = `${startup_page}/reports`;

//News
export const news = `${startup_page}/news`;
export const edit_news = `${startup_page}/edit_news`;
export const new_news = `${startup_page}/new_news`;
export const news_view_one = `${startup_page}/view`;

//Settings
export const settings = `${startup_page}/settings`;
export const tags1 = `${startup_page}/settings/tags`;
export const funnels1 = `${startup_page}/settings/funnels`;
export const notification = `${startup_page}/settings/notifications`;
export const web_form = `${startup_page}/settings/web_form`;
export const your_team = `${startup_page}/settings/your_team`;
export const setting_profile = `${startup_page}/settings/profile`;
export const evaluation_templates_page = `${startup_page}/settings/templates`;
export const evaluation_template_page = `${startup_page}/settings/template`;

//TopMenu
//Remainder
export const reminders = `${startup_page}/reminders`;

//Fake
export const fake_page = `${startup_page}/fake`;

//Documentation
export const documentation = `${startup_page}/docs`;

// PUBLIC ROUTES
export const public_pages = `/public`;

// export const public_evaluation = `${public_pages}/evaluation`;

export const public_creative = `${public_pages}`;
export const public_creative_new = `${public_pages}/creative_new`;
export const public_group_share = `${public_pages}/group_share`;


export const terms = `${public_pages}/terms`


export const payment_page = `${startup_page}/payment`


/* // KANBAN VIEW
export const kanban = "/kanban"; */

// export const add_section_dev = `${startup_page}/settings/evaluation-templates/add-section`;

// // JØRGEN's NEW ROUTES
export const notifications = `${startup_page}/notifications`;

// ADMIN
export const admin = `${startup_page}/admin`;

// SHARING RECIEVER VIEW
export const reciever_sharing = `${public_pages}/share/:id`;

export const group_plugin = `${public_pages}/group_plugin`

export const app_sumo_signup = "/app_sumo_signup";

export const help_material = `${public_pages}/help_material`;