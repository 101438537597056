import gql from "graphql-tag";

import {
  ConnectionBasicFields,
  SimpleUserFields,
  EvaluationFields,
  CreativeFields,
  SubjectiveScoreFields,
  ExternalResourceFields,
  EvaluationSummaryFields,
  TagFields,
  FunnelTagFields,
  LogFields,
  GroupSharingInfoFields
} from "../NewFragments";

export default gql`
    
    query connectionGet($id: ID!) {
        
        connectionGet(id: $id) {
            ...ConnectionBasicFields

            subjectiveScores {
                ...SubjectiveScoreFields
            }

            creative {
                ...CreativeFields
            }

            tags {
                ...TagFields
            }

            funnelTags {
                ...FunnelTagFields
            }

            externalResources {
                ...ExternalResourceFields
            }

            log {
                ...LogFields
            }

            groupSharingInfo {
                ...GroupSharingInfoFields
            }

            evaluations {
                ...EvaluationFields
            }

            externalEvaluations {
                ...EvaluationFields
            }

            evaluationSummaries {
                ...EvaluationSummaryFields
            }

            externalEvaluationSummaries {
                ...EvaluationSummaryFields
            }
        }
    }

    ${ConnectionBasicFields}
    ${SimpleUserFields}
    ${EvaluationFields}
    ${CreativeFields}
    ${SubjectiveScoreFields}
    ${ExternalResourceFields}
    ${EvaluationSummaryFields}
    ${TagFields}
    ${FunnelTagFields}
    ${LogFields}
    ${GroupSharingInfoFields}

`;
