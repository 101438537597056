import gql from "graphql-tag";

import { userAnalyticsFragments} from "../Fragments";

export default gql`
  query userAnalyticsGet($fromDate: String, $toDate: String) {
    userAnalyticsGet(fromDate: $fromDate, toDate: $toDate) {
      ...userAnalyticsFields
    }
  }
  ${userAnalyticsFragments}
`;