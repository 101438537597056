import gql from "graphql-tag";

export default gql`
  mutation connectionExternalResourceAdd($connectionId: String!, $link: String!, $label: String!) {
    connectionExternalResourceAdd(
        connectionId: $connectionId,
        input :{
            link : $link,
            label : $label
        }
        )
        {
            id
            creative{
                name
            }
        }
    }
`;
