import React from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { groupLeave } from "private/Apollo/Mutations";
import { groupsGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { Modal } from "Components/NotataComponents/Modal/Modal";

// MAIN FUNCTION
export default function LeaveGroupModal({ group, close }) {
  
  // MUTATIONS
  const [leaveGroup, { loading }] = useMutation(groupLeave, {
    refetchQueries: [{ query: groupsGetV2 }],
    awaitRefetchQueries: true,
  });

  // SAVE FUNCTION
  async function save(e) {
    e.preventDefault()
    if (loading) {
      return;
    }
    try {
      await leaveGroup({ variables: { id: group.id } });
    } catch (error) {
      console.log("error", error);
    }
    close();
  }

  return (
    <Modal
      title="Leave group"
      loading={loading}
      submit={save}
      close={close}
      submitTxt="Leave"
      closeTxt="Cancel"
      submitButtonStyle="secondary"
      children={
        <div className="delete-group-modal-container">
          <div className="description">
            Are you sure you want to leave this group?
          </div>
          <div className="leave-modal-options">
            - Your added startups will be removed from group <br />
            - Comments will be deleted <br />
            - Your evaluations will be removed from group <br />
          </div>
        </div>
      }
    />
  );
}