import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";

// API Queries	
import { groupUserInvitationResponse } from "private/Apollo/Mutations";
import { groupInvitationsGet, userGet } from "private/Apollo/Queries";
import notificationMarkAsResolved from "private/Apollo/Mutations/notificationMarkAsResolved";

// Custom Component
import { NotataButton } from "Components/NotataComponents";

// Others
import { getVal } from "private/Pages/commonFunctions";
import { useHistory } from "react-router-dom";
import { group_dashboard } from "../../../../definitions";

//Main Function
export default function GroupInvitation({ notification }) {

  // OTHER
  const history = useHistory()

  // STATES
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  // QUERIES
  let { data } = useQuery(userGet)
   
  let user = data?.userGet;

   //Query
   const { data:groupInvitationsData } = useQuery(groupInvitationsGet,{
    pollInterval:15000
  })

  // MUTATIONS
  const [respond, respondRes] = useMutation(groupUserInvitationResponse, {
    refetchQueries: [{ query: groupInvitationsGet }],
  });

  const [markNotification] = useMutation(notificationMarkAsResolved);
  let groupId = getVal(notification, "groupId");


  return (
    <>
      <div className="notifications-container-new__notification__action-buttons">
        {/* Reject Button */}
        <NotataButton
          size="x_small"
          bgcolor="secondary_color"
          text="reject"
          onClick={async () => {
            if (rejecting) return;
            setRejecting(true);
            let variables = {
              groupId: groupId,
              email: user?.email,
              response: "REJECT",
            };

            try {
               await respond({ variables });
            } catch (error) {
            }

            try {
              let variables = { id: notification.id };
              await markNotification({ variables });
            } catch (error) {
              return;
            }

            setRejecting(false);
          }}
        />

        {/* Accept Button */}
        <NotataButton
          size="x_small"
          bgcolor="primary_color"
          text="accept"
          loading={accepting}
          onClick={async () => {
            if (accepting) return;

            setAccepting(true);

            let variables = {
              groupId: groupId,
              email: user?.email,
              response: "ACCEPT",
            };

            try {
              await respond({ variables });
            } catch (error) {
              console.log("error", error);
            }

            try {
              let variables = { id: notification.id };
              await markNotification({ variables });
            } catch (error) {
              console.log("error", error);
              return;
            }

            setAccepting(false);

            let path = `${group_dashboard}/${groupId}`;
            history.push(path)

          }}
        />
      </div>
    </>
  );
}
