import gql from "graphql-tag";

export default gql`
    fragment ExternalResourceFields on ExternalResource {
        id
        link
        label
        createdAt
    }
`




