import React, { useEffect } from "react";

// ASSETS
import man_standing from "../../../../assets/images/man_standing.svg";

// CUSTOM COMPONENTS
import { Message } from "../Message/Message";

// definitions
import { login } from "definitions.js";

// OTHERS
import { Auth } from "aws-amplify";

// MAIN FUNCTION
export function SignOut({ history }) {

  // EFFECTS
  useEffect(() => {
    Auth.signOut()
      .then(() => {

      })
      .catch(() => {
        console.log("error");
      });
  }, []);

  return (
    <Message
      heading={"Bye bye 😭"}
      subHead1={"You have been logged out!."}
      image={man_standing}
      history={history}
      path={login}
    />
  );
}
