import React, { useState } from "react";
import { useForm } from "react-hook-form";

// UI COMPONENTS
import { InputForm, NotataButton } from "Components/NotataComponents";
import FloatingLoginButtons from "./FloatingLoginButtons/FloatingLoginButtons";

// STYLES
import "../LoginPages.scss";

// Assets
import man_standing from "assets/images/man_standing.svg";
import notata from "assets/images/auth-logo.svg";

// CUSTOM COMPONENTS
import { ResetPassword } from "./ResetPassword";

//Main Function
export function Instructor({ email, history, handleResend, loader }) {
  // Constants
  const data = { email: email };

  // STATES
  const [code, setCode] = useState("");

  // FORMS
  const { register, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  // FUNCTIONS
  const onSubmit = async data => {
    setCode(data.code);
  };

  const handleBack = () => {
    setCode('')
  }
  return (
    <>
      {code ? (
        <ResetPassword
          handleBack={onSubmit}
          history={history}
          email={email}
          code={code}
          handleBack={handleBack}
        />
      ) : (
        <div className="auth_structure">
          <div className='auth_structure__left'>
            <div className='auth_structure__left__mainContent'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='logoContainer'>
                  <img
                    style={{
                      width: "40px",
                      height: "40px",
                      marginBottom: "15px",
                    }}
                    src={notata}
                    alt="logo"
                    className='logo'
                  />
                  <h1>Email sent</h1>
                  <p
                    style={{
                      margin: "25px 0",
                      lineHeight: "18.05px",
                      fontSize: "15px",
                      color: "#969BA3",
                    }}
                  >
                    please check your email (
                    <span className='instructorEmail'>{email}</span>) and
                    follow the instructions to vertify it. if you did not
                    receive an email or if it expired , you can resend one.
                  </p>
                  <p
                    style={{
                      margin: "25px 0",
                      lineHeight: "18.05px",
                      fontSize: "15px",
                      color: "#969BA3",
                    }}
                  >
                    Make sure to check your junk or spam folder.
                  </p>
                </div>
                <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                  <InputForm
                    label="CODE"
                    type="text"
                    name="code"
                    placeholder="Code"
                    required
                    reference={register({ required: true })}
                  />
                  <NotataButton
                    size="xx_large"
                    bgcolor="primary_color"
                    text="Next"
                    buttonType="submit"
                    loading={isSubmitting}
                  />
                </div>
              </form>
              <NotataButton
                size="xx_large"
                text="RESEND"
                buttonStyle="outlined"
                buttonType="button"
                loading={loader}
                onClick={() => handleResend(data)}
              />
            </div>
          </div>
          <div className='auth_structure__right'>
            <img src={man_standing} alt="man_standing" />
          </div>
          {/*<FloatingLoginButtons />*/}
        </div>
      )}
    </>
  );
}