import React, { useEffect, useRef } from "react";
import { SvgComp } from "../../commonFunctions";

export const FunnelPopup = ({
    tags,
    id,
    index,
    setFunnel,
    updateFunnelTag,
    sortArr,
    styles,
    setFunnelDropDownId,
    clientX,
    clientY
  }) => {

  // CONST

  const popup = useRef();
  const tagSort = sortArr(tags);

  // EFFECT
  useEffect(() => {
    window.addEventListener("click", handleGlobalEvent);

    return () => {
      window.removeEventListener("click", handleGlobalEvent);
    };
  });

  // FUNCTIONS

  const handleGlobalEvent = e => {
    var check = !e.path.includes(popup.current)
    if(check) {
      setFunnel(false);
    }
  }

  const updateFunnelTagForConnection = funnelTagId => {
    // setFunnelDropDownId(funnelTagId)
    updateFunnelTag(funnelTagId, id);
    // setFunnel(false);
  };
  return (
    <div
      // ref={popup}
      className={styles.funnelPopup}
      style={(clientX && clientY) && {
        top: clientY,
        left: clientX
      }}
    >
      <ul>

        {tagSort?.map((tag, index) => (
          <li key={tag.id} onClick={() => updateFunnelTagForConnection(tag.id)}>
            <SvgComp index={index} color={tag.color} />{tag.name}
          </li>
        ))}
      </ul>
    </div>
  );
};