import React ,{useEffect, useState} from 'react';
import './EvaluationSummaryPage.scss';
import moment from 'moment';
import {sumBy} from 'lodash';
import { evaluate_page, startup_page, group_dashboard } from "definitions";
import { evaluationDelete } from "private/Apollo/Mutations";
import { connectionGet, evaluationGet as evaluatoinGetQuery } from "private/Apollo/Queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GhostLoader, Loader, Modal, NotataButton, ModalErrorMessage,Card, CardComponent } from "Components/NotataComponents";
import queryString from 'query-string';

export default function EvaluationSummaryPage({ history, match }) {

  const { evaluationId, connectionId } = match.params;
  let groupId = null;

  if (history.location?.search) {
    const searchObj = queryString.parse(history.location?.search);
    groupId = searchObj.groupId;
  }


  const [getConnection, connectionRes] = useLazyQuery(connectionGet, {
    // TODO: this should be cache, but it doesn't work
    fetchPolicy:"network-only"
  })
  const [getEvaluation, evaluationRes] = useLazyQuery(evaluatoinGetQuery)

  let connection = connectionRes?.data?.connectionGet;
  let creative = connection?.creative || {};
  let evaluation = evaluationRes?.data?.evaluationGet;



  useEffect(() => {
    if (connectionId) {
      let variables = {
        id: connectionId
      }

      console.log('variables', variables)

      // get connection
      getConnection({variables})
    }
  }, [connectionId])

  useEffect(() => {
    if (evaluationId) {

      let variables = {
        id: evaluationId
      }
      // get evaluation
      getEvaluation({variables})
    }
  }, [evaluationId])


  // Mutations
  const [mutateEvaluationDelete, deleteRes] = useMutation(evaluationDelete);

  const {createdByUser, createdAt, summary, template, answers } = evaluation || {};
  const { isMe, given_name, family_name } = createdByUser || {};
  const { sections } = summary || {};
  const { name } = template || {};

  // States
  const [deleteModal, setDeleteModal] = useState(false);
  const [collapseDetailList, setCollapseDetailList] = useState({});

  useEffect(()=>{
    let sectionDetails = {};
    let sec = sections?.map(item => {
      sectionDetails[item.sectionId] = "";
    });
    setCollapseDetailList(sectionDetails);
  }, [sections])

  const getComment = (id) =>{
    const comment = answers?.find(
      ({ questionId, inputType }) =>
        questionId === id &&
        inputType === "COMMENT"
    );
    return comment ? comment.val : ""
  }

  const deleteEvaluation = async () => {
    let variables = { id: evaluationId };
    try {
      await mutateEvaluationDelete({ variables });
      if(groupId){
        history.push(`${group_dashboard}/${groupId}`)
      }
      else{
        history.push(`${startup_page}/company/${connection?.id}?tab=2`);
      }
    } catch (error) {
      return console.log("error", error);
    }
  };






  const isLoading = connectionRes.loading || evaluationRes.loading;

  if (isLoading) {
    return (
      <GhostLoader/>
    )
  }

  const getScoreCount = (sectionId) => {
    let multilineAns = {};
    let checkAns = {};
    let answers = evaluation?.answers?.filter(item=> item.sectionId === sectionId);
    for(let ans of answers){
      if(ans.inputType === "INPUT_MUTLIPLE_LINES" && ans.sectionId === sectionId){
        multilineAns[ans.questionId] = ans.questionId;
      }
    }
    for(let ans of answers){
      if(ans.inputType === "CHECK" && ans.sectionId === sectionId){
        multilineAns[ans.questionId] = ans.questionId;
      }
    }
    const otherAnsLength = answers?.filter(ans => ans.sectionId === sectionId
      && ans.inputType !== "CHECK" && ans.inputType !== "COMMENT"
      && ans.inputType !== "INPUT_MUTLIPLE_LINES")?.length || 0;

    const multilineAnsLength = Object.keys(multilineAns).length;
    const checkAnsLength = Object.keys(checkAns).length;
    return otherAnsLength + multilineAnsLength + checkAnsLength;
  };



  return(
    <div className="evaluation-details">
      <div className="evaluation-detail-container col-md-12">

        <div
          style={{
            textTransform: "uppercase",
            fontSize: "20px",
            cursor: "pointer",
            color: "#53cab2"
          }}
          onClick={() => {
            let path = groupId
              ? `${group_dashboard}/${groupId}`
              : `${startup_page}/company/${connectionId || connection?.id}?tab=2`
            history.push(path)
          }}
          >
          <i
            className={"fa fa-chevron-left"}
            aria-hidden="true"
          />
          <span> go back</span>
        </div>

        {evaluationRes.loading && (<Loader/>)}
        <Card ContainerclassName="card" >
          <div className="evaluation-detail-container__heading">
            <i
              className={"fa fa-chevron-left"}
              aria-hidden="true"
              onClick={() => groupId ? history.push(`${group_dashboard}/${groupId}`)
                : history.push(`${startup_page}/company/${connectionId || connection?.id}?tab=2`)}
            />
            {creative.name}
          </div>
          <div className="evaluation-detail-container__summary">


            <div className="evaluation-detail-container__summary__template-name">{name}</div>
            <div className="evaluation-detail-container__summary__template-by">
              {evaluation?.createdByEmail && (
                <span>Evaluation by {evaluation?.createdByEmail}</span>
              )}
              {
                !evaluation?.createdByEmail && (
                  <span>Evaluation by {given_name} {family_name}</span>
                )
              }
            </div>
            <div className="evaluation-detail-container__summary__template-by">{`Last updated at ${moment(createdAt).format('ll')}`}</div>
            <div className="evaluation-detail-container__summary__heading">Summary</div>
            {sections?.map(section=>{
              return(
                <div key={section.sectionId} className="evaluation-detail-container__summary__section">
                  <div className="evaluation-detail-container__summary__section__name">{section.sectionName}</div>
                  <div className="evaluation-detail-container__summary__section__points">{`${sumBy(section.scorePerAnswer, 'score')} of ${sumBy(section.scorePerAnswer, 'possibleScore')} points`}</div>
                </div>
              )
            })
            }
          </div>
        </Card>
        {sections?.map(section => {
          return (
            <Card ContainerclassName="card" key={section.sectionId}>
              <div className="detail-type">
                <div className="detail-heading">
                  <i
                    className={`fa ${collapseDetailList[section.sectionId] === ""
                      ? "fa-chevron-up"
                      : "fa-chevron-down"
                    }`}
                    aria-hidden="true"
                    onClick={() => {
                      let collapseList = { ...collapseDetailList };
                      collapseList[section.sectionId] = collapseList[section.sectionId] === "" ? "collapse" : "";
                      setCollapseDetailList(collapseList);
                    }}
                  />
                  {section.sectionName}
                </div>
                <div className="detail-score">
                  {`${getScoreCount(section.sectionId)} of ${section.scorePerAnswer.length} questions answered`}
                </div>
              </div>
              <div className={`${collapseDetailList[section.sectionId]} detail-list`} >
                {section?.scorePerAnswer?.map((score, index) => {
                  return (
                    score.questionName &&
                    <div className="detail-list__question" key={`${section.sectionId}_${index}`}>
                      <div className="detail">
                        <p>{score.questionName}</p>
                        <div className="detail-score">
                          {score.score + " of " + score.possibleScore + " points"}
                        </div>
                      </div>
                      <div className="answers">
                        <div className={score.answer ? "question__answer" : "not_answer"}>{score.answer ? score.answer : "Not answered" }</div>
                        {getComment(score.questionId) && <div className="question_comment">{getComment(score.questionId)}</div>}
                      </div>

                    </div>
                  );
                })}
              </div>
            </Card>
          );
        })}
        {isMe &&
        <div className="evaluation-detail-container__summary__evaluation-actions">
          <NotataButton
            size="big"
            bgcolor="primary_color"
            text="edit evaluation"
            datatut="evaluation-startup"
            buttonStyle="evaluate-startup-btn"
            onClick={() => {
              history.push(
                `${evaluate_page}/${connection.id}/${evaluation?.templateId}/${evaluation.id}`
              );
            }}
          />
          <NotataButton
            size="big"
            bgcolor="secondary_color"
            text="delete permanently"
            datatut="evaluation-startup"
            buttonStyle="delete-permanently-btn"
            onClick={() => {
              setDeleteModal(true);
            }}
          />
        </div>
        }

        {deleteModal && (
          <Modal
            title="Delete evaluation"
            submit={deleteEvaluation}
            loading={deleteRes.loading}
            close={() => {
              setDeleteModal(false);
            }}
            submitTxt="Delete"
            closeTxt="Cancel"
            lastButtonStyle="secondary_color"
            submitButtonStyle="secondary"
            children={
              <div className="modal-content-description">
                <ModalErrorMessage msg="Are you sure you want to delete this evaluation?"/>
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}