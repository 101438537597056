import React from 'react';
import { Modal } from "Components/NotataComponents";
import { pick } from "lodash";
import "./EvaluateModal.scss";
import TemplatedForm from "../../../../Components/Forms/TemplatedForm";

const selectedFields = [
  'inputType',
  'questionId',
  'sectionId',
  'sid',
  'val'
]

export default function EvaluateModal({
  creative,
  template,
  evaluation,
  close,
  save,
  loading
}) {

  return(
    <Modal
      title={`Evaluate ${creative?.name}`}
      submit={save}
      close={close}
    >
      <TemplatedForm
        containerStyle={{maxWidth: "100%"}}
        template={{ sections: template?.sections }}
        location={{}}
        loading={loading}
        content={evaluation?.answers?.map(obj => pick(obj, selectedFields)) || []}
        submit={save}
        onCancel={close}
        showComments={true}
      />
    </Modal>
  )
}