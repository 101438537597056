import React, { useState } from "react";
import { Link } from "react-router-dom";

// API STUFF
import {
  groupSubjectiveScoreAdd,
  connectionCreate,
  evaluationUpdate,
  evaluationCreate,
  groupEvaluationAdd
} from "private/Apollo/Mutations";

import { groupGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { Card, NotataButton } from "Components/NotataComponents";
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";
import SubjectiveScoreModal from "../../../../MyStartups/Modals/SubjectiveScoreModal";



//CONSTANTS
import { startup_page } from "../../../../../../definitions";

//STYLING
import "./StartupCard.scss";
import "./StartupGridView.scss";

// HELPERS
import { useMutation } from "@apollo/client";
import ScoreSummary from "Components/NotataComponents/ScoreSummary/ScoreSummary";
import EvaluateModal from "../../../../EvaluatePage/EvaluateModal/EvaluateModal";



// MAIN FUNCTION
export default function StartupGridView({
    group,
    adminView,
    history,
    setSelectedStartup,
    setShowComment,
    startups,
    sortByCategory
  }) {


  const [ evaluateModalProperties, setEvaluateModalProperties ] = useState(undefined)
  const [ evaluationLoading, setEvaluationLoading ] = useState(false)

  const [ addingToDealflow, setAddingToDealflow] = useState({})
  const [ subjectiveScoreStartup, setSubjectiveScoreStartup ] = useState(undefined);
  const [ subjectiveScoreModal, setSubjectiveScoreModal ] = useState(false);

  const mutationOptions = {
    refetchQueries: [
      {
        query: groupGetV2,
        variables: { id: group.id },
      },
    ],
    awaitRefetchQueries: true,
  }

  const [shareScoreAdd, shareScoreAddRes] = useMutation(groupSubjectiveScoreAdd, mutationOptions);
  const [createConnection, createConnectionRes] = useMutation(connectionCreate, mutationOptions);

  const [mutateEvaluationUpdate] = useMutation(evaluationUpdate, mutationOptions);
  const [mutateEvaluationCreate] = useMutation(evaluationCreate, mutationOptions);
  const [evaluationAddToGroup] = useMutation(groupEvaluationAdd, mutationOptions);


  const checkIfCommentUnread = (startup) =>{
    let unreadComments = startup?.log?.filter(({ seen }) => !seen);
    return unreadComments.length > 0 ? true : false
  }

  const getDescription = (startup) =>{
    let oneLiner= "";
    if (startup?.creative?.answers) {
      let _oneLiner = startup?.creative?.answers?.find(
        ({ questionId }) => questionId === "q01_section_info"
      );
      if (_oneLiner) oneLiner = _oneLiner.val;
    }
    return oneLiner;
  }

  async function saveEvaluation(newAnswers) {

    setEvaluationLoading(true)

    let evaluation = evaluateModalProperties?.evaluation || {}

    // Maintain state
    setEvaluateModalProperties({
      ...evaluateModalProperties,
      evaluation: {
        ...evaluation,
        answers: newAnswers
      }
    })

    // Save evaluation
    let savedEvaluation;

    // Update existing
    if (evaluation.id) {
      try {
        let variables = {
          id: evaluation.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationUpdate({variables})
        savedEvaluation = res?.data?.evaluationUpdate;
      } catch (error) {
        console.log('error', error)
      }
    }

    // Create new
    if (!evaluation.id) {

      // ... create new evaluation
      try {
        let variables = {
          connectionId: evaluateModalProperties?.connection?.id,
          templateId: evaluateModalProperties?.template?.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationCreate({variables})
        savedEvaluation = res?.data?.evaluationCreate;
      } catch (error) {
        console.log('error', error)
      }

      // ... add evaluation to group
      try {
        let variables = {
          evaluationId: savedEvaluation?.id,
          groupId: group.id,
          creativeId: evaluateModalProperties?.creative?.id,
        };
        await evaluationAddToGroup({ variables });
      } catch(error) {
        console.log("error", error);
      }

    }

    setEvaluationLoading(false)

    // Hide edit modal
    setEvaluateModalProperties(undefined)

    // Open summary modal
    // setSummaryModalContent(savedEvaluation)

  }












  let settings = group?.settings;
  let hideDetails = !settings?.showUsers && !adminView;
  let hideSummaries = !settings?.showSummaries && !adminView;
  let evaluationTemplate = group?.evaluationTemplates && group?.evaluationTemplates[0];

  return (

    <>
      <div className="startup-grid-view-container">
      {startups
      .map((startup, i) => {

        // let subjectiveScoreSummary = getSubjectiveScoreSummary(startup);
        let evaluationSummary = startup?.evaluationSummaries?.find(({templateId}) => templateId === evaluationTemplate?.id)

        let sharedSubjectiveScore = startup?.subjectiveScores?.find(({ isMe }) => isMe);
        let iHaveEvaluated = startup?.evaluations?.find(evaluation =>
          evaluation?.createdByUser?.isMe &&
          evaluation?.templateId === evaluationTemplate?.id
        )

        return (
          <Card
            key={`startup-grid-view-key-${i}`}
            containerClassName="startup-grid-view-container__startup"
            heading={
              <div
                onClick={() => {
                  if (startup.isInMyDealFlow) {
                    history.push(`${startup_page}/company/${startup.connection?.id}`)
                  }
                }}
                className={`startup-grid-view-container__startup__header__heading ${startup.isInMyDealFlow && 'startup-grid-view-container__startup__header__heading__underlined'}`}>
                {startup.creative?.name}
              </div>
            }
            headerClassName="startup-grid-view-container__startup__header"
            beforeHeaderComponent={
              <span onClick={() => {
                if (startup.isInMyDealFlow) {
                  history.push(`${startup_page}/company/${startup.connection?.id}`)
                }
              }}>
                <StartupLogo
                  url={startup.creative?.logo}
                  startupName={startup.creative?.name}
                  size="mini"
                />
              </span>
            }
            lastToHeaderComponent={

              settings?.chat && (
                <i className={
                  checkIfCommentUnread(startup)
                    ? "fa fa-comment unread-message"
                    : "far fa-comment no-message"
                }
                   onClick={() => {
                     setShowComment(true);
                     setSelectedStartup(startup);
                   }}
                />
              )
            }
          >

            <div className="startup-grid-view-container__startup__description">
              <div className="startup-grid-view-container__startup__description__clipper">
                {getDescription(startup)}
              </div>
            </div>

            <div className="startup-grid-view-container__startup__more-info">
              {
                startup.connection?.id && (
                  <Link to={`${startup_page}/company/${startup.connection?.id}`} >
                    More info
                  </Link>
                )
              }
            </div>

            { // EVALUATION SUMMARY
              (
                (
                  !hideSummaries
                  && sortByCategory !== "public"
                  && group?.settings?.addEvaluation
                  && evaluationTemplate
                ) ||
                (
                  !hideSummaries
                  && sortByCategory === "public"
                  && group?.publicSettings?.withEvaluationTemplate)
              ) &&
              (

                <>
                  {
                    sortByCategory === "public" && (



                      <ScoreSummary
                        title={
                          <span>PUBLIC:<br/>
                            {
                              startup?.publicEvaluationSummaries &&
                              startup?.publicEvaluationSummaries[0]?.templateName
                            }
                          </span>
                        }
                        submissions={
                          startup?.publicEvaluations?.length
                        }
                        score={
                          startup?.publicEvaluationSummaries &&
                          startup?.publicEvaluationSummaries[0]?.averagePercentageScore
                        }
                        highest={
                          startup?.publicEvaluationSummaries &&
                          startup?.publicEvaluationSummaries[0]?.highestScore
                        }
                        lowest={
                          startup?.publicEvaluationSummaries &&
                          startup?.publicEvaluationSummaries[0]?.lowestScore
                        }
                      />
                    )
                  }

                  {
                    sortByCategory !== "public" && (
                      <ScoreSummary
                        title={
                          evaluationTemplate?.name
                        }
                        submissions={
                          evaluationSummary?.submissions
                        }
                        score={
                          evaluationSummary?.averagePercentageScore
                        }
                        highest={
                          evaluationSummary?.highestScore
                        }
                        lowest={
                          evaluationSummary?.lowestScore
                        }
                      />
                    )
                  }

                </>
              )
            }







            {
              // SUBJECTIVE SCORE SUMMARY
              (
                (
                  !hideSummaries
                  && sortByCategory !== "public"
                  && group?.settings?.addEvaluation
                ) ||
                (
                  !hideSummaries
                  && sortByCategory === "public"
                  && group?.publicSettings?.withSubjectiveScores
                )
              ) &&
              (

                <>

                  {
                    sortByCategory !== "public" && (
                      <ScoreSummary
                        title={
                          "subjective score"
                        }
                        submissions={
                          startup?.subjectiveScores?.length
                        }
                        score={
                          startup?.avgSubjectiveScore?.toFixed(1)
                        }
                        highest={
                          Math.max(...startup?.subjectiveScores?.map(b => b.score || 0) || [0])
                        }
                        lowest={
                          Math.min(...startup?.subjectiveScores?.map(b => b.score || 0) || [0])
                        }
                        decimals
                      />
                    )
                  }

                  {
                    sortByCategory === "public" && (
                      <ScoreSummary
                        title={
                          <div>PUBLIC:<br/>subjective score</div>
                        }
                        submissions={
                          startup?.publicSubjectiveScores?.length
                        }
                        score={
                          startup?.publicAvgSubjectiveScore?.toFixed(1)
                        }
                        highest={
                          Math.max(...startup?.publicSubjectiveScores?.map(b => b.score || 0) || [0])
                        }
                        lowest={
                          Math.min(...startup?.publicSubjectiveScores?.map(b => b.score || 0) || [0])
                        }
                        decimals
                      />
                    )
                  }
                </>

              )
            }


            { // EVALUATE && SCORE BUTTONS
              (settings?.addEvaluation && startup.isInMyDealFlow) && (
                <div className="startup-grid-view-container__startup__buttons-container">

                  {
                    evaluationTemplate && (
                      <>
                        {
                          iHaveEvaluated && (
                            <NotataButton
                              text={`${iHaveEvaluated?.summary?.scorePercent || '0'}%`}
                              buttonStyle="evaulation-btn outlined-green big"
                              onClick={() => {

                                let modalProperties = {
                                  connection: startup?.connection,
                                  creative: startup?.creative,
                                  template: evaluationTemplate,
                                  evaluation: iHaveEvaluated,
                                  group: group
                                }
                                setEvaluateModalProperties(modalProperties)

                              }}
                            />
                          )
                        }

                        {
                          !iHaveEvaluated && (
                            <NotataButton
                              bgcolor="primary_color evaulation-btn"
                              text="Evaluate"
                              onClick={() => {
                                let modalProperties = {
                                  connection: startup?.connection,
                                  creative: startup?.creative,
                                  template: evaluationTemplate,
                                  group: group
                                }
                                setEvaluateModalProperties(modalProperties)
                              }}
                            />
                          )
                        }
                      </>
                    )
                  }

                  {
                    sharedSubjectiveScore?.score && (
                      <NotataButton
                        bgcolor="outlined score-btn"
                        text={sharedSubjectiveScore.score}
                        onClick={() => {
                          setSubjectiveScoreStartup(startup);
                          setSubjectiveScoreModal(true);
                        }}
                      />
                    )
                  }

                  {
                    !sharedSubjectiveScore?.score && (
                      <NotataButton
                        bgcolor="primary_color score-btn"
                        text="score"
                        onClick={() => {
                          setSubjectiveScoreStartup(startup);
                          setSubjectiveScoreModal(true);
                        }}
                      />
                    )
                  }

                </div>
              )
            }

            {
              // ADD TO DEALFLOW
              !startup.isInMyDealFlow && (
                <div className="startup-grid-view-container__startup__buttons-container">
                  <div className="startup-list-view-container__row__button-container__buttons">
                    <NotataButton
                      size="large"
                      bgcolor="primary_color big"
                      startIcon="add"
                      text="Add to deal flow"
                      loading={addingToDealflow[startup?.creative?.id]}
                      style={{width: "200px"}}
                      onClick={async () => {

                        if (addingToDealflow[startup?.creative?.id]) {
                          return;
                        }

                        setAddingToDealflow({
                          ...addingToDealflow,
                          [startup?.creative?.id]: true
                        })

                        let variables = {
                          groupId: group.id,
                          creativeId: startup?.creative?.id,
                        };
                        await createConnection({ variables });

                        setAddingToDealflow({
                          ...addingToDealflow,
                          [startup?.creative?.id]: false
                        })

                      }}
                    />
                  </div>
                </div>
              )
            }

          </Card>
        )}
      )}

      {
        subjectiveScoreModal && (
          <SubjectiveScoreModal
            connection={subjectiveScoreStartup?.connection}
            close={() => setSubjectiveScoreModal(false)}
            loading={shareScoreAddRes.loading}
            submit={async () => {
              let haveShareScore = subjectiveScoreStartup?.subjectiveScores?.find(({ isMe }) => isMe);
              if (!haveShareScore) {
                if (shareScoreAddRes.loading) {
                  return;
                }
                let variables = {
                  groupId: group.id,
                  creativeId: subjectiveScoreStartup?.creative?.id,
                }
                await shareScoreAdd({ variables });
                setSubjectiveScoreModal(false)
              } else {
                setSubjectiveScoreModal(false)
              }
            }}
          />
        )
      }

    </div>

      {/* EVALUATE MODAL */}
      {
        evaluateModalProperties && (
          <EvaluateModal
            creative={evaluateModalProperties?.creative}
            template={evaluateModalProperties?.template}
            evaluation={evaluateModalProperties?.evaluation}
            connectionId={evaluateModalProperties?.connection?.id}
            close={() => setEvaluateModalProperties(undefined)}
            save={saveEvaluation}
            loading={evaluationLoading}
          />
        )
      }

    </>


  )
}