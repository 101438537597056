import React, { useEffect, useState, useRef } from "react";

// Components
import Question from "./Question";

// Styles
import "./TemplatedForm.scss";

// *****************
// * Main function *
// *****************
export default function Section({ section, answers, setAnswers, open, showComments }) {

  // States
  const [isOpen, setIsOpen] = useState(true);

  const myRef = useRef(null);

  // If opened from outside of component
  useEffect(() => {
    if (open) {
      setIsOpen(open);
      // Scroll to element
      let el = myRef.current;
      let offset = 45;
      let pos = el.getBoundingClientRect().top;
      let offsetPosition = pos - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [open]);

  // Definitions
  const { name, description } = section;
  const questions = section.questions || [];

  return (
    <>
    <div className="section_container" ref={myRef}>
      <div className="section_title__container">
        <div
          className="section_title__icon"
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className={`fa fa-chevron-${isOpen ? "up" : "down"}`} />
        </div>

        <div
          className="section_title"
          onClick={() => setIsOpen(!isOpen)}
        >
          {name}
        </div>
      </div>

      {isOpen && (
        <div className="section_content">
          {/* Section description */}
          {description && (
            <div className="section_description">{description}</div>
          )}


          {/* List of questions */}
          {questions.map(question => (
            <Question
              key={question.id}
              section={section}
              question={question}
              answers={answers}
              setAnswers={setAnswers}
              showComments={showComments}
            />
          ))}
        </div>
      )}
    </div>
    </>
  );
}
