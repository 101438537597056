import React, { useEffect, useState } from "react";

// LODASH METHODS
import { omit } from "lodash";

// UI COMPONENTS
import { TextBox, Card } from "Components/NotataComponents";

// OTHERS
import Scrollspy from "react-scrollspy";
import FileUploader from "./FileUploader";
import TemplatedForm from "Components/Forms/TemplatedForm";

// STYLES
import "./EditStartupContent.scss";

// MAIN FUNCTION
export default function EditStartupContent({
  backToInfoPage,
  template,
  creative,
  saveCreative,
  disableNavigation,
  history,
}) {

  // STATES
  const [collapseList, setCollapseList] = useState({});
  const [name, setName] = useState("");
  const [logo, setLogo] = useState(undefined);
  const [answers, setAnswersState] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [saved, setSaved] = useState(false);
  const [invalid, setInvalid] = useState("");

  // EFFECTS
  useEffect(() => {
    if (creative?.answers) {
      let cleanAnswers = creative?.answers?.map(answer =>
        omit(answer, "__typename")
      );
      cleanAnswers = cleanAnswers?.filter(
        ans => ans?.questionId !== "q01_section_terms"
      );
      setAnswers(cleanAnswers);
    }
    if (creative?.name) {
      setName(creative?.name || "");
    }
  }, [creative]);

  // Sets answers
  function setAnswers(newAnswers) {
    setInvalid("");
    setAnswersState(newAnswers);
  }

  // Checks user agreed for or not and validate
  const checkIfAgreedForTerms = answers => {
    let ans = answers?.filter(ans => ans?.questionId === "q01_section_terms");
    if (ans.length > 0) {
      setInvalid("");
      return true;
    }
    setInvalid("Please Accept the terms and conditions!");
    return false;
  };

  const multiChoiceInput = async answers => {
    if (!checkIfAgreedForTerms(answers)) {
      return;
    }
    setInvalid("");
    setSaveLoader(true);
    try {
      let input = { answers, name };
      if (logo) {
        input.logo = logo;
      }
      await saveCreative(input);
      const cleanAnswers = answers?.filter(
        ans => ans?.questionId !== "q01_section_terms"
      );
      setAnswers(cleanAnswers);
    } catch (error) {
      return console.log("error", error);
    }
    setSaveLoader(false);
    setSaved(true);
  };

  return (
    <Card containerClassName="share-startup-container" size="large">
      {!disableNavigation && (
        <div>
          <div>
            {backToInfoPage && (
              <div onClick={backToInfoPage}>
                <span className="material-icons back-icon">
                  arrow_back_ios
                </span>
                <span className="page-heading">{creative?.name}</span>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className={
          disableNavigation
            ? "share-startup__info--with-menu"
            : "share-startup__info"
        }
      >
        {!disableNavigation && (
          <div className="menu-container">
            <Scrollspy
              offset={-300}
              items={template?.sections || []}
              currentClassName="is-current"
            >
              {template?.sections?.map(({ id, name }) => (
                <li key={id}>
                  <a
                    href={`#${name}`}
                    onClick={() => {
                      setCollapseList({
                        ...collapseList,
                        [id]: "",
                      });
                    }}
                  >
                    {name}
                  </a>
                </li>
              ))}
            </Scrollspy>
          </div>
        )}

        <div
          className={
            disableNavigation
              ? "startup-details-container"
              : "startup-details-container"
          }
        >
          <div className="logo-uploader-container">
            <div className="logo-uploader-logo-container">
              {!creative?.logo && !logo && (
                <i className="camera fa fa-camera" aria-hidden="true" />
              )}

              {(logo || creative?.logo) && (
                <div className="logo-no-bg">
                  <img
                    key={`${logo || creative?.logo}?${Date.now()}`}
                    src={`${logo || creative?.logo}?${Date.now()}`}
                    alt=""
                  />
                </div>
              )}
            </div>

            <FileUploader
              creative={creative}
              saveCreative={saveCreative}
              setLogo={setLogo}
            />
          </div>

          <div className={`company-name`}>
            <div className="heading">Company name</div>
            <div>
              <div style={{ maxWidth: "500px" }}>
                <TextBox
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Company name"
                />
              </div>
            </div>
          </div>
          <TemplatedForm
            template={{sections: template?.sections}}
            location={history?.location}
            loading={saveLoader}
            content={answers}
            submit={answers => {
              setAnswers(answers);
              multiChoiceInput(answers);
            }}
            onCancel={backToInfoPage}
          />
          <div className="error-msg__container">
            {invalid && <p className="error-msg">{invalid}</p>}
          </div>
          <div className="footer1">
            {saved && !invalid && (
              <span className="save_sccess ">Successfully saved</span>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
