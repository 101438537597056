import React, { useState } from "react";

// COMPONENTS
import { Loader,Tag } from "Components/NotataComponents";
// import { FunnelPopup, StartupPreview } from "../Popups";

// STYLES
import styles, { activePopup } from "./Table.module.css";

// OTHER
import moment from "moment";
import classnames from "classnames";
import { startup_page } from '../../../../../definitions'

import {
  SvgComp
} from "../../../commonFunctions";
import { group_dashboard,/* evaluation_template_page,*/ evaluate_page } from "definitions";
import { useHistory } from "react-router-dom";

import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo"

import { groupBy, maxBy } from 'lodash';
import { Link } from "react-router-dom";
import _ from 'lodash';

export default function TableBody(props) {

  //props Value
  const {
    setStarMutation,
    columnValues,
    connections,
    funnelLoad,
    evaluationTemplates,
    setShowFunnelScoreForId,
    setShowTagGroupForId,
    setShowStartUpForId,
    setShowSubjectiveScoreForId,
    ButtonGreen,
    checkAll,
    checked,
    setChecked,
    setActiveFunnel,
    setPopupClientX,
    setPopupClientY,
    setFunnelPopupVisible,
    funnelPopupVisible,
    activeFunnel,
    tagGroups
  } = props;

  //STATE
  const history = useHistory();

  const [funnel, setFunnel] = useState();
  const [funnelId, setFunnelId] = useState();
  const [activeRow, setActiveRow] = useState(0);

  // function
  const handleFunnelPopup = (index, funnelTag) => {
    let funnelIndex = funnel && funnel === index;
    setFunnel(funnelIndex ? null : index);
    setFunnelId(funnelTag.id);
  };

  const newDatas = (item, field) => {
    let date = moment().diff(moment(item[field]), 'months');
    return (
      <ul className={styles.dateList}>
        <li className={styles.dateListItem}>
          {date < 3 && <Tag size="md_tag" color="lightBlue" label={`${moment(item[field]).format("ll")}`}/>}
          {(date >= 3 && date <= 6) && <Tag size="md_tag" color="secondary-yellow" label={`${moment(item[field]).format("ll")}`} />}
          {date > 6 && <Tag size="md_tag" color="yellow-dark" label={`${moment(item[field]).format("ll")}`}/>}
         </li>
      </ul>)

  }
  return (
    <tbody>
      {
        // connections && !loading &&
        connections
          .filter(({ creative }) => creative)
          .map((item, index) => {
            let {
              funnelTags,
              creative,
              groupSharingInfo,
              evaluationSummaries,
              starred,
              id,
              avgSubjectiveScore,
              subjectiveScores
            } = item;

            let tagSet = funnelTags.length > 0 ? groupBy(funnelTags, "group.id") : null;
            let subjectiveScoreValAvg = avgSubjectiveScore || undefined;
            let addedScore = subjectiveScores?.find(
              score => score.isMe === true
            )
            let oneLiner = item.creative?.answers?.find(
              ({ questionId }) => questionId === "q01_section_info"
            );
            let problems = item.creative?.answers?.find(
              ({ questionId }) => questionId === "q02_section_info"
            )
            // let filterTags = []
            // item.tags.filter(tags => {
            //   if (columnValues.tagGroups.includes(tags.tagGroupId)) {
            //     filterTags.push(tags)
            //   }
            // })

            let filterTags = item?.tags || [];

            let filterTagCols = [];
            item.tags.filter(tags => {
              if (columnValues.tagColumns?.includes(tags.tagGroupId)) {
                filterTagCols.push(tags)
              }
            })
            filterTagCols = _.sortBy(filterTagCols, o=>o.tagGroupId);

            // const tagByGroup = columnValues?.tagColumns?.map(id =>
            //   (filterTagCols || []).filter(
            //     tag => tag.tagGroupId === id
            //   )
            // );

            return (
              <tr key={`${index}-${id}`}>


                {
                  /*
                    COLUMN #1

                    This is a fixed column
                    Contains checkbox and star
                  */
                }

                <td
                  className={styles.dealflowColumnHead}
                  style={{ paddingTop: "-10px" }}
                >
                  {/*Checkbox*/}
                  <div className={styles.columnHead}>
                    <label
                      className={styles.customCheck}
                    >
                      <input
                        type="checkbox"
                        checked={!!checkAll || !!checked[item.id]}
                        autoComplete="off"
                        onChange={() =>
                          setChecked({
                            ...checked,
                            [item.id]: !checked[item.id],
                          })
                        }
                      />
                      <span
                        className={styles.checkmark}
                      />
                    </label>
                    {/*Star*/}
                    <div
                      style={{ marginTop: "-5px" }}
                      className={styles.favStartup}
                      onClick={() => {
                        setStarMutation({
                          variables: { id },
                          optimisticResponse: {
                            connectionSetStar: {
                              __typename: "Mutation",
                              ...item,
                              starred: !starred,
                            },
                          },
                        });
                      }}
                    >
                      <i
                        style={{
                          color: starred ? "orange" : "lightgray",
                        }}
                        className="fas fa-star"
                      />
                    </div>
                  </div>
                </td>
                {
                  /*
                    COLUMN #2

                    This is a fixed column
                    Contains company logo and name
                  */
                }
                <td className={styles.columnType_name}>


                  {/* COMPANY LOGO */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      // onMouseOver={(event) => {
                      //   if (oneLiner && problems) {
                      //     setPreview(true);
                      //     setPreviewItem(item);
                      //     setPopupClientX(event.pageX - (window.innerWidth > 786 ? 110 : 0) - 150);
                      //     setPopupClientY(event.pageY + 15);
                      //     setFunnelPopupVisible(false);
                      //     setActiveRow(null);
                      //     setActiveFunnel(null);
                      //   }
                      // }}
                      // onMouseLeave={() => {
                      //   setPreview(false);
                      //   setPreviewItem(null);
                      //   setPopupClientX(null);
                      //   setPopupClientY(null);
                      //   setFunnelPopupVisible(false);
                      // }}
                      // onClick={() => handleCompany(item)}
                    >
                      <Link to={`${startup_page}/company/${id}`} style={{ textDecoration: 'none' }}>
                        <StartupLogo
                          url={creative.logo}
                          startupName={creative.name}
                          size={"small"}
                          logoStyle={creative.canEdit ? "green-border" : "orange-border"}
                        />
                      </Link>
                    </span>


                    {/* COMPANY NAME */}
                    <Link to={`${startup_page}/company/${id}`} style={{ textDecoration: 'none' }}>
                      <span
                        // onMouseOver={(event) => {
                        //   if (oneLiner && problems) {
                        //     setPreview(true);
                        //     setPreviewItem(item);
                        //     setPopupClientX(event.pageX - (window.innerWidth > 786 ? 110 : 0) - 150);
                        //     setPopupClientY(event.pageY + 15);
                        //     setFunnelPopupVisible(false);
                        //     setActiveRow(null);
                        //     setActiveFunnel(null);
                        //   }
                        // }}
                        // onMouseLeave={() => {
                        //   setPreview(false);
                        //   setPreviewItem(null);
                        //   setPopupClientX(null);
                        //   setPopupClientY(null);
                        //   setFunnelPopupVisible(false);
                        // }}
                        className={styles.company_name}
                        // onClick={() => handleCompany(item)}
                      >

                        {item.creative.name}


                      </span>
                    </Link>
                  </div>
                </td>


                {/* GROUPS */}
                {columnValues.groups && (
                  <td className={styles.columnType_groups}>
                    <ul className={styles.groupList}>
                      {groupSharingInfo?.map(({ group }, i) => (
                        <li
                          key={`${group.id}-${i}`}
                          className={styles.groupListItem}
                          onClick={() => {
                            // setFilters({
                            //   ...filters,
                            //   sortBy: "GROUP",
                            //   sortByVal: group.id
                            // })
                            history.push(`${group_dashboard}/${group.id}`);
                          }}
                        >
                          <Tag
                            label={`${group.name}`}
                            color="color_oragne"
                            size="md_tag"
                          />
                        </li>
                      ))}
                      <li>

                        <button
                          onClick={() => setShowTagGroupForId(item.id)}
                          className={styles.buttongreen}
                          style={{ marginLeft: 0 }}
                        >
                          <i className="fas fa-plus-circle" />
                        </button>
                      </li>
                    </ul>

                  </td>
                )}

                {/* FUNNELS */}
                {columnValues.funnels && (
                  <td className={styles.columnType_funnels}>
                    <div className={styles.startupStatus}>
                      {funnelLoad && activeRow === index ? (
                        <Loader />
                      ) : tagSet ? (
                        Object.keys(tagSet).map(funnelTagGroup => {
                          let maxFunnel = maxBy(tagSet[funnelTagGroup], 'index');
                          return <div key={`${index}-${maxFunnel.id}`} className={styles.funnelTag}
                            onClick={(event) => {
                              setActiveFunnel(funnelPopupVisible ? null : { ...maxFunnel, rowId: id });
                              // {(window.innerWidth > 786 ? 110 : 0)} = if its a mobile window we are not subtracting left menu width
                              // 150 = width of the popup
                              setPopupClientX(event.pageX - (window.innerWidth > 786 ? 110 : 0) - 170);
                              setPopupClientY(event.pageY - 5);
                              setActiveRow(funnelPopupVisible ? null : index);
                              setFunnelPopupVisible(!funnelPopupVisible);
                            }
                            }
                          >
                            {/* <div className={styles.funnel_icon}> */}
                            <div className={styles.funnel_icon} ><SvgComp index={maxFunnel.index} color={maxFunnel.color}/></div>
                            <p>{maxFunnel.name}</p>
                            <span
                              className={classnames(
                                activeFunnel && activeRow === index && activeFunnel.id === maxFunnel.id ? activePopup : ""
                              )}
                            >
                              <i className={classnames("fas fa-chevron-down")} />
                            </span>
                          </div>
                        })
                      ) : (
                        <span
                          onClick={() => {
                            setShowFunnelScoreForId(item.id);
                            setFunnelId(index);
                          }}
                        >
                          <ButtonGreen />
                        </span>
                      )}
                    </div>
                  </td>
                )}

                {
                  columnValues.tagGroups.map(tagGroupId => {
                    let tags = item.tags.filter(({ group }) => group.id === tagGroupId)
                    return (
                      <td key={`${tagGroupId}`}>
                        {
                          tags?.length > 0 ? tags.map(tag => (
                            <Tag
                              key={tag.id}
                              label={`${tag.name}`}
                              color="primary_tag"
                              size="md_tag"
                            />
                          )) : (
                            <span style={{ color: "var(--color-gray-light)" }}>N/A</span>
                          )
                        }
                      </td>
                    )
                  })
                }



                {/* TAGS */}
                {columnValues.tags && (
                  <td className={styles.columnType_tags}>
                    <ul className={styles.tagList}>
                      {(filterTags || [])
                        .slice(0, 2)
                        .map(({ name, id, group }) => (
                          <li
                            key={`${id}`}
                            className={styles.TagListItem}
                          >
                            <Tag
                              label={`${group.name}: ${name}`}
                              color="primary_tag"
                              size="md_tag"
                              />
                           </li>
                        ))}
                      <li>&nbsp;</li>
                      <li>
                        {filterTags.length > 2 ? (
                          <>
                            <p
                              style={{ fontWeight: 500 }}
                            >+{filterTags.length - 2}</p>
                          </>

                        ) : (
                          <></>
                        )}
                      </li>
                      <li>
                        <button
                          onClick={() => setShowStartUpForId(item.id)}
                          className={styles.buttongreen}
                          style={{ marginLeft: 0 }}
                        >
                          <i className="fas fa-plus-circle" />
                        </button>
                      </li>
                    </ul>

                    {/* style={{alignItems: "center" ,display:"inline-block"}} */}

                  </td>
                )}



                {/* SUBJECTIVE SCORE */}
                {columnValues.subjectiveScore && (
                  <td className={styles.columnType_subjectiveScore}>
                    {/* 1. if only the admin has given the score, average score will be integer.
                        2. if the admin and other member has given the score,
                           average score will be in decimal.
                        3. if other members has given the score except admin,
                           average score will be in decimal. 
                    */}
                    {subjectiveScores?.length === 1 && addedScore ?
                      subjectiveScoreValAvg : (subjectiveScoreValAvg?.toString().includes(".")) ?
                        subjectiveScoreValAvg : subjectiveScoreValAvg && subjectiveScoreValAvg + ".0"
                    }
                    {/* ButtonGreen will only display if average score is null
                        or admin has given the score
                    */}
                    {(!subjectiveScoreValAvg || !addedScore) && (
                      <span
                        className={subjectiveScoreValAvg ? styles.add_score : ""}
                        onClick={() => setShowSubjectiveScoreForId(item.id)}
                      >
                        <ButtonGreen />
                      </span>
                    )}
                    {/* Edit icon will only display if average score is not null 
                        and admin has given the score
                    */}
                    {subjectiveScoreValAvg && addedScore && (
                      <span
                        className={styles.edit_score}
                        onClick={() => setShowSubjectiveScoreForId(item.id)}
                      >
                        <i className="fas fa-pen" />
                      </span>
                    )}
                  </td>
                )}

                {/* UPDATED AT */}
                {
                  columnValues.updatedAt && (
                    <td className={styles.columnType_timeStamp}>
                      {newDatas(item, 'updatedAt')}
                    </td>
                  )
                }

                {/* CREATED AT */}
                {
                  columnValues.createdAt && (
                    <td className={styles.columnType_timeStamp}>
                      {newDatas(item, 'createdAt')}
                    </td>
                  )
                }

                {/* Iterate over the headers */}
                {evaluationTemplates
                  .filter(({ id }) =>
                    (columnValues.evaluationTemplates || []).some(
                      etID => etID === id
                    )
                  )
                  .map((evaluationTemplate, i) => {
                    // Find evaluation summary matching header
                    let summary;
                    if (evaluationSummaries?.length) {
                      summary = evaluationSummaries?.find(
                        ({ templateId }) => templateId === evaluationTemplate?.id
                      );
                    }

                    return (
                      <td
                        key={`${evaluationTemplate?.id}-${i}`}
                        className={styles.columnType_evaluation}
                      >
                        {summary ? (
                          // If summary exists, display it
                          <span>{summary.averagePercentageScore}%</span>
                        ) : (
                          // If not, display a plus button
                          <Link to={`${evaluate_page}/${id}/${evaluationTemplate?.id}`}>
                            <ButtonGreen />
                          </Link>
                        )}
                      </td>
                    );
                  })}
              </tr>
            );
          })}
    </tbody>
  );
}
