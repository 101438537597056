import React, { useState } from "react";

// Library Components
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

// Custom Components
import { Tabsection } from "Components/NotataComponents"

// Styles
import "./Blocks.scss";

// Main Function
export function Blocks({ tabValue, tabArr, contentArr, children, width }) {
  const [currentTab, setCurrentTab] = useState(tabValue);

  const tabFuc = val => {
    setCurrentTab(val);
  };

  return (
    <Card className={width ? width : "blockWidth"}>
      <CardHeader
        title={
          <Tabsection
            tabFuc={val => tabFuc(val)}
            tabValue={currentTab}
            tabArr={tabArr}
          />
        }
      />
      <CardContent className="blockWidth__contentScroll">
        {contentArr.length &&
          contentArr.map(
            (item, index) =>
              currentTab === item.value && (
                <div key={index}>
                  <div className="blockWidth__contentScroll__cardContent">
                    <img
                      src={item.image}
                      alt={item.imageAlt}
                      width="50"
                      height="40"
                    ></img>
                    <div className="blocks_text">
                      {item.text}
                    </div>
                  </div>
                  <hr className="blockWidth__underline" />
                </div>
              )
          )}
        {children && children}
      </CardContent>
    </Card>
  );
}
