import React, { useState } from "react";

// API
import { useQuery } from "@apollo/client";
import { groupsGetV2 } from "../../../Apollo/Queries";

// COMPONENTS
import { Dropdown } from "Components/NotataComponents";
import { Loader } from "Components/NotataComponents";

// STYLES
import styles from "./modal.module.css";

export default function AddGroup({ connection, select }) {
  // Queries
  const {data, loading, called} = useQuery(groupsGetV2);
  let groups = data?.groupsGetV2 || [];

  // Remove groups we are already part of
  const filteredGroups = groups.filter(
    ({ id }) => !connection.groupSharingInfo.some(info => info.group.id === id)
  );


  const noGroups = !groups.length && called && !loading
  const noFilteredGroups = !filteredGroups.length && called && !loading

  if (loading) {
    return <Loader addPadding={true}/>
  }

  return (
    <div className={styles.group}>

      <div className={styles.groupChild}>
        {
          noGroups && <div className={styles.nogroups}>You are in no groups</div>
        }
        {noFilteredGroups && !noGroups && <div className={styles.noFilteredGroups}>This startup is already in all available groups. Click "create new group" button to create a new group.</div> }
        {
          !noGroups && !noFilteredGroups && (
            <>
              <div style={{marginBottom:"10px"}} >
                <h2>Select group from list below</h2>
              </div>
              <div className={styles.groupDropContainer}>
                <Dropdown items={filteredGroups} setSelectedItem={select} />
              </div>
            </>
          )
        }
      </div>
    </div>
  );
}
