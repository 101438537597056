import React from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { tagGroupDelete } from "../../../../Apollo/Mutations";
import { tagGroupsGet } from "../../../../Apollo/Queries";

// MODALS
import { Modal } from "Components/NotataComponents/Modal/Modal";


// MAIN FUNCTION
export default function DeleteTagGroupModal({ tagGroup, setDeleteTagGroupModal }) {
    // MUTATION
    const [deleteTagGroup, deleteTagGroupRes] = useMutation(tagGroupDelete, {
      refetchQueries: [{ query: tagGroupsGet }],
    });

    let isZeroTagsGroup = tagGroup?.tags?.length === 0;

    return (
      <Modal
        title="Delete tag group"
        loading={deleteTagGroupRes.loading}
        submit={async () => {
          if (deleteTagGroupRes.loading) {
            return;
          }
  
          let variables = {
            id: tagGroup.id,
          };
          try {
            await deleteTagGroup({ variables });
          } catch (error) {
            console.log("error", error);
          }
          setDeleteTagGroupModal(undefined);
        }}
        close={() => {
          setDeleteTagGroupModal(undefined);
        }}
        submitTxt={`${isZeroTagsGroup ? "Delete" : ""}`}
        closeTxt={`${isZeroTagsGroup ? "Cancel" : "Ok"}`}
        submitButtonStyle="secondary"
        children={
          <div className="delete-group-modal-container">
            <div className="description">
              {isZeroTagsGroup ? "Are you sure you want to delete this tag group permanently?" : 
              "Group has multiple tags, Please delete all the tags to delete the group."}
            </div>
          </div>
        }
      />
    );
}