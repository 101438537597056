import React from "react";

// API STUFF
import { useQuery } from "@apollo/client";
import { newsGet } from "private/Apollo/Queries";

// CUSTOM COMPONENTS
import {
  NotataButton,
  Loader,
  CardComponent,
} from "Components/NotataComponents";

// DEFINITIONS
import { news, news_view_one } from "definitions";

// OTHERS
import moment from "moment";
import { Link } from "react-router-dom";

// MAIN FUNCTION
export default function DashboardNews() {
  // QUERIES
  const { data, loading } = useQuery(newsGet);
  const allNews = data?.newsGet || [];
  return (
    <CardComponent
      containerClassName="dashboard-container__news"
      padding="small"
      title="News"
      titleSize="small"
      content={
        <>
          {!data && loading && <Loader />}
          <div className="dashboard-container__news__container">
            {allNews &&
              allNews
                .slice()
                .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                .slice(0, 3)
                .map(newsItem => (
                  <div key={newsItem.id}>
                    <div className="dashboard-container__news__date">
                      {moment(newsItem.createdAt).format("ll")}
                    </div>
                    <div className="dashboard-container__news__news">
                      <div className="dashboard-container__news__news__heading">
                        {newsItem.title}
                      </div>
                      {newsItem.image && (
                        <div className="dashboard-container__news__news__image">
                          <img src={newsItem.image} alt="" />
                        </div>
                      )}
                      <div className="dashboard-container__news__news__detail">
                        {newsItem.content.substring(0, 100)}...
                      </div>
                    </div>
                    <div className="dashboard-container__news__footer">
                      <div className="dashboard-container__news__footer__name">
                        {newsItem.createdByUser?.given_name}{" "}
                        {newsItem.createdByUser?.family_name}{" "}
                      </div>
                      <div className="dashboard-container__news__footer__learn-more">
                        <Link
                          to={`${news_view_one}/${newsItem.id}`}
                          className="link_underline"
                        >
                          <NotataButton
                            color="primary"
                            text="Learn more"
                            endIcon="chevron_right"
                            buttonStyle="text_button"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </>
      }
      footer={
        <Link to={news} className="link_underline">
          <NotataButton
            color="primary"
            text="More news"
            buttonStyle="text_button"
          />
        </Link>
      }
    />
  );
}
