import React from "react";
import "./Terms.scss";
import { Card } from "../../../Components/NotataComponents";

export default function PublicGroupTerms() {
  return (
    <Card
      heading="Terms of use"
      >
      <div
        className="terms-section"
        >
        <h2>1. Contractual Relationship</h2>

        <p>These Terms of Use (the "Terms") sets out the rules governing:</p>

        <p>A. Use of the the website at https://notata.io and any successor website, and the services available on that website or any successor website (the "Services"); and B. the transmission, storage and processing of content by you, or by any person on your behalf, using the Services ("Content").</p>

        <p>Your agreement with us includes these Terms and our Privacy Policy.</p>

        <p>References in these Terms to "you" are to any customer for the Services and any individual user of the Services (and "your" should be construed accordingly); and references in this Policy to "us" are NOTATA AS, company No 925141070, Tordenskiolds gate 2, 0160 Oslo, Norway (and "we" and "our" should be construed accordingly).</p>

        <h2>2. Acceptance of the Terms</h2>
        <p>By accessing content, using the Services or registering an account, you agree to the rules set out in these Terms.</p>

        <p>We will ask for your express agreement to the terms of this Policy before you upload or submit any Content or otherwise use the Services. By accepting these terms and conditions you accept that all communication and information from your invested company/companies will be distributed solely through the platform. It is solely your responsibility as a user to ensure logging in and accessing the relevant information and/or documents at any relevant time.</p>

        <h2>3. Description of the Services</h2>
        <p>Notata delivers an electronic platform for investor relations for a company to communicate with and keep control of its shareholders. The platform will be in continuous development, its main purpose is to provide tools and features so the company can keep its shareholders up to date and engaged in the company's development.</p>

        <h2>4. User Account</h2>
        <p>In order to use the Services, you must register for and maintain an active personal user Services account ("Account"). You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18), to obtain an Account.</p>

        <p>We will provide login information by e-mail to the e-mail address provided by the company where the user owns shares.</p>

        <p>You agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account information, may result in your inability to access or use the Services. You are responsible for all activity that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and password at all times.</p>

        <h2>5. Use of the Service</h2>
        <p>General usage rules</p>
        <p>You must not use the Services in any way that causes, or may cause, damage to the Services or impairment of the availability or accessibility of the Services.</p>

        <p>You must not use the Service:</p>
        <p>A. in any way that is unlawful, illegal, fraudulent or harmful; or</p>
        <p>B. in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. You must ensure that all Content complies with the provisions of these Terms.</p>

        <p>User Content</p>
        <p>Your Content is defined as any information you add in the platform including data, text, audio, video or images. Except as provided in this Section 5, we obtain no rights under this Agreement from you (or your licensors) to Your Content. You consent to our use of Your Content to provide the Service Offerings to you and any Company Users.</p>

        <p>Unlawful Content</p>
        <p>Content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable law). You must ensure that Content is not and has never been the subject of any threatened or actual legal proceedings or other similar complaint.</p>

        <p>Graphic Material</p>
        <p>Content must be appropriate for all persons who have access to or are likely to access the Content in question.</p>

        <p>Factual Accuracy</p>
        <p>Content must not be untrue, false, inaccurate or misleading.</p>

        <p>Etiquette</p>
        <p>Content must be appropriate, civil and tasteful, and accord with generally accepted standards of etiquette and behavior on the internet.</p>
        <p>You must ensure that Content is appropriately categorized.</p>
        <p>You should use appropriate and informative titles for all Content.</p>
        <p>You must at all times be courteous and polite to other users of the Services.</p>

        <p>Harmful Software</p>
        <p>The Content must not contain or consist of, and you must not promote or distribute by means of the Services, any viruses, worms, spyware, adware or other harmful or malicious software, programs, routines, applications or technologies.</p>

        <p>The Content must not contain or consist of, and you must not promote or distribute by means of the Services, any software, programs, routines, applications or technologies that will or may have a material negative effect upon the performance of a computer or introduce material security risks to a computer.</p>

        <h2>6. Blocking and Removal of Content</h2>
        <p>We condition the right to remove any content posted to the portal without the users consent if we find them to be in violation with section 5.</p>

        <h2>7. License</h2>
        <p>Notata or its licensors holds all right, title, and interest in and to the Services and all related technology. Subject to these Terms, Notata grants you a limited, revocable, non-exclusive, non-sublicensable, non-transferable license to do the following: (a) access and use the Services solely in accordance with these Terms; and (b) copy and use the Content solely in connection with your permitted use of the Services.</p>

        <p>The Customer shall not use the Services in any manner or for any purpose other than as expressly permitted by these Terms or attempt to (a) modify, distribute, alter, tamper with, repair, or otherwise create derivative works of any Content included in the Services (b) reverse engineer, disassemble, or decompile the Service or apply any other process or procedure to derive the source code of any software included in the Service (except to the extent applicable law doesn’t allow this restriction), (c) access or use the Service in a way intended to avoid incurring fees or exceeding usage limits or quotas, or (d) resell or sublicense the Service Offerings.</p>

        <h2>8. Disclaimer</h2>
        <p>The services are provided "as is" and "as available." Notata disclaims all representations and warranties, express, implied, or statutory, not expressly set out in these terms, including the implied warranties of merchantability, fitness for a particular purpose and non-infringement.</p>

        <p>In addition, Notata makes no representation, warranty, or guarantee regarding the reliability, timeliness, quality, suitability, or availability of the services or any services or goods requested through the use of the services, or that the services will be uninterrupted or error-free. Notata does not guarantee the quality, suitability, safety or ability of third party providers. You agree that the entire risk arising out of your use of the services, and any service or good requested in connection therewith, remains solely with you, to the maximum extent permitted under applicable law.</p>

        <h2>9. Limitation of Liability</h2>
        <p>Notata shall not be liable for indirect, incidental, special, exemplary, punitive, or consequential damages, including lost profits, lost data, personal injury, or property damage related to, in connection with, or otherwise resulting from any use of the services or relating to any content, regardless of the negligence (either active, affirmative, sole, or concurrent) of Notata, even if Notata has been advised of the possibility of such damages.</p>

        <p>Notata shall not be liable for any damages, liability or losses arising out of: (i) Your use of or reliance on the services or your inability to access or use the services; or any transaction or relationship between you and any third party provider, even if Notata has been advised of the possibility of such damages. Notata shall not be liable for delay or failure in performance resulting from causes beyond Notata reasonable control.</p>

        <h2>10. Limitation of Liability</h2>
        <p>You agree to indemnify and hold Notata and its affiliates and their officers, directors, employees, and agents harmless from any and all claims, demands, losses, liabilities, and expenses (including attorneys' fees), arising out of or in connection with: (i) your use of the Services or services or goods obtained through your use of the Services; (ii) your breach or violation of any of these Terms; (iii) Notata' use of your User Content; or (iv) your violation of the rights of any third party, including Third Party Providers.</p>

        <h2>11. Changes to the Services, Terms or Prices</h2>
        <p>The effective version of the Terms can be found on our website.</p>

        <p>We may at any time and at our sole discretion make changes to the Services without notice. We may also amend the Terms or prices. If these changes are material we will inform you either by e-mail or by posting a revised version on the Notata portal.</p>

        <p>Please therefore make sure you read any such notice carefully. If you do not wish to continue using the Service under the new version of the Agreements, you may terminate the Agreements by contacting us through the Notata portal.</p>

        <h2>12. Term and Termination</h2>
        <p>These terms will continue to apply for as long as you use the Services and will remain in effect until terminated by either you or Notata. We may terminate this agreement or suspend your access to the Services at any time, including in the event of your actual or suspected unauthorised use of the Services and/or Content, or non-compliance with these Terms.</p>
        <p>We may terminate your user account at any time for any reason, providing you either a notice in the portal or by a written message to your registered e-mail address. In such a case you will no longer be entitled to use the services or access content.</p>
        <p>Sections 5 through 9, and 12 herein, as well as any other sections these Terms that, either explicitly or by their nature, must remain in effect even after termination of the Agreements, shall survive termination.</p>

        <h2>13. Governing Law and Venue</h2>
        <p>These Terms and any dispute or claim arising out of or in connection with them or its subject matter of formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with Norwegian law.</p>

        <p>The Parties irrevocably agree that the courts of Norway shall have non-exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims) with the Oslo District Court as the agreed venue.</p>

      </div>
    </Card>
  )
}
