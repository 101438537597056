import React from "react";

// Styles
import "./Tag.scss"

// Main Function
export const Tag = ({
  label,
  color,
  size,
  killButton,
  onClick,
  tagmargin,
  customtagstyle
}) =>
  <ul className={`tag-container ${customtagstyle}`}>
    <li
      className={`tag-items  ${color} ${size} ${tagmargin} ${killButton && "kill-padding"}`}
      style={
        onClick && {cursor: "pointer"}
      }
      onClick={onClick}
    >
      <span>
        {label}
      </span>
      {killButton &&
        <>
          <span  className="fal fa-times tag-times" aria-hidden="true" onClick={killButton} />
        </>
      }
    </li>
  </ul>
  