import React from "react";

// API STUFF
import { useQuery } from "@apollo/client";
import { newsGet, userGet } from "../../Apollo/Queries";

// UI COMPONENTS
import { GhostLoader } from "Components/NotataComponents";

//HELPER COMPONENT
import { EachNews } from "./NewsHelpers/EachNews"

//STYLES
import "./News.scss";

//Definitions
import { new_news } from "definitions";

//Others
import { Link } from "react-router-dom";


//Main Function
export default function News({ history }) {

  // Queries
  const userQuery = useQuery(userGet);
  const { data, loading } = useQuery(newsGet);

  // Maps and Reducers
  let news = data?.newsGet || [];


  if (!data && loading) {
    return <GhostLoader />;
  }
  let canEditNews = userQuery?.data?.userGet?.canEditNews;


  return (
    <>
      <div className="header-routing">
        <span className="header-routing__highlight">News</span>
      </div>
      <div className="news-container">
        <div className="news-container__main">
          <div className="news-container__heading">
            <span>News</span>
            {canEditNews && (
              <Link to={`${new_news}`} style={{ textDecoration: 'none' }}>
                <i className="fas fa-plus-circle new-icon" />
              </Link>
            )}
          </div>

          {[].concat(news)
            .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
            .map((data) =>
              <EachNews key={data.id} id={data.id} data={data} history={history} />
            )}

        </div>
      </div>
    </>
  );
}
