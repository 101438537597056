import React from 'react';
import { Card, NotataButton } from "Components/NotataComponents";
import CardMessageContainer from 'private/Pages/Group/Components/CardMessageContainer';

export default function ThankYou({ content }) {
  return (
    <div className="thank-you-container">
      <Card
        heading="Evaluation submitted"
        containerClassName="thank-you-container__content"
        headerClassName="thank-you-container__content__header"
      >
        <CardMessageContainer
          title="Thank you!"
          message={`Your evaluation has been successfully submitted!`}
          notice
          children={
            <div className="thank-you-container__button-container">
              {content}
            </div>
          }
        />
      </Card>
    </div>
  )
}