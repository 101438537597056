import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

import ShareStartup from "./ShareStartup";

import { accountGet, publicSharingsGet } from "../../../../../../Apollo/Queries";
import {
  publicSharingCreate,
  publicSharingUpdate,
  publicSharingDelete
} from "../../../../../../Apollo/Mutations";

import { Modal } from "../../../../../../../Components/NotataComponents";

export default function ShareStartupModal({
  connection,
  sharingObject,
  setSharingObject,
  setSharableLinkModal,
  setShareStartupModal,
  setPreviewStartupModal
}) {

  // STATES
  const [textError, setTextError] = useState(false)

  // OTHER CONSTANTS
  const mutationOptions = {
    refetchQueries: [
      {
        query: publicSharingsGet,
        variables: { connectionId: connection?.id }
      }
    ],
    awaitRefetchQueries: true
  }

  // QUERIES
  const accountQuery = useQuery(accountGet);

  // MUTATIONS
  const [publicSharingCreateMutation, createRes] = useMutation(publicSharingCreate, mutationOptions);
  const [publicSharingUpdateMutation, updateRes] = useMutation(publicSharingUpdate, mutationOptions);
  const [publicSharingDeleteMutation, deleteRes] = useMutation(publicSharingDelete, mutationOptions);

  // MAPS
  const evaluationTemplates = accountQuery?.data?.accountGet?.evaluationTemplates;

  // OTHER...
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required(),
        message: yup.string().required()
      })
    )
  });


  // Create public sharing
  const createPublicSharing = (reqObj) => {
    // TODO: Amruth – is this ever used???
    const variables = {
      connectionId: reqObj.connectionId,
      input: {
        sharedWithEmail: reqObj.sharedWithEmail
      }
    }
    publicSharingCreateMutation({ variables })
  }

  // Save sharing???
  const saveShare = async () => {
    if (!sharingObject?.id) {
      const variables = {
        connectionId: connection.id,
        input: {
          sharedWithEmail: sharingObject.sharedWithEmail,
          message: sharingObject.message,
          sharedEvaluationId: sharingObject.sharedEvaluation?.id,
          evaluationTemplateId: sharingObject?.evaluationTemplate?.id
        }
      }
      const res = await publicSharingCreateMutation({ variables })
      setSharingObject(res?.data?.publicSharingCreate);
      setShareStartupModal(false);
      setSharableLinkModal(true);
    }
  }

  // Update sharing
  async function updateFunction() {
    if (sharingObject?.id) {
      const variables = {
        id: sharingObject.id,
        input: {
          sharedWithEmail: sharingObject.sharedWithEmail,
          message: sharingObject.message,
          sharedEvaluationId: sharingObject.sharedEvaluation?.id,
          evaluationTemplateId: sharingObject?.evaluationTemplate?.id
        }
      }
      const res = await publicSharingUpdateMutation({ variables })
      setSharingObject(res?.data?.publicSharingUpdate);
      setShareStartupModal(false);
      setSharableLinkModal(true);
    }
  }

  // Delete sharing
  async function deleteFunction(e) {
    if (e.target.tagName === 'DIV' && sharingObject?.id) {
      const variables = {
        id: sharingObject?.id
      }
      await publicSharingDeleteMutation({ variables })
    }
    setShareStartupModal(false);
    setSharingObject(undefined);
    setTextError(false)
  }

  return (
    <form onSubmit={handleSubmit(saveShare)}>
      <Modal
        title="Share"
        submtButtonIcon="submit"
        submit={updateFunction}
        close={deleteFunction}
        loading={createRes.loading || updateRes.loading}
        cancelLoading={deleteRes.loading}
        intermidate={() => {
          setShareStartupModal(false)
          setPreviewStartupModal(true)
        }}
        intermidateTxt={sharingObject?.id && "PREVIEW"}
        intermidateStyle="outlined"
        submitTxt="SAVE"
        closeTxt={sharingObject?.id ? "DELETE" : "CANCEL"}
        children={
          <ShareStartup
            connection={connection}
            createPublicSharing={createPublicSharing}
            createShareInput={sharingObject}
            textError={textError}
            setTextError={setTextError}
            email={"email"}
            message={"message"}
            setCreateShareInput={setSharingObject}
            evaluationTemplates={evaluationTemplates}
            register={register}
          />
        }
      />
    </form>
  )
}