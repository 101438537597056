import React, { useEffect, useState } from "react";

// API STUFF
import { useLazyQuery } from "@apollo/client";
import { groupGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { GhostLoader,BreadCrumbs, Card, NotataButton } from "Components/NotataComponents";

// STYLES
import "./GroupHome.scss";

// CUSTOM COMPONENTS
import InfoSection from "./Components/InfoSection/InfoSection";
import ManageResources from "./Components/ManageResources/ManageResources";
import StartupList from "./Components/StartupList/StartupList";

// DEFINITIONS
import { group } from "definitions";
import { VIEWS, MANAGERESOURCESVIEW } from "private/Pages/constants";

// MAIN FUNCTION
export default function GroupPage({ match, history }) {

  // States
  const [ view, setView ] = useState(VIEWS.GRID);
  const [ manageResourceView, setManageResourceViewState] = useState(MANAGERESOURCESVIEW.MEMBER);

  // Queries
  let [groupGet, { data, refetch }] = useLazyQuery(groupGetV2, {
    pollInterval: 5000
  });

  // Execute lazy query
  useEffect(() => {
    if (match.params.groupId) {
      let variables = {
        id: match.params.groupId,
      };
      groupGet({ variables });
    }
  }, [match]);


  // Get view from local storage
  useEffect(() => {
    let _view = localStorage.getItem(`groupView:${group.id}`)
    setView(_view || VIEWS.LIST)
  }, [localStorage])

  // Get admin view from local storage
  useEffect(() => {
    let _view = localStorage.getItem(`groupAdminView:${group.id}`)
    setManageResourceViewState(_view || MANAGERESOURCESVIEW.MEMBER)
  }, [localStorage])

  // Set admin view to local store and state
  function setManageResourceView(newState) {
    localStorage.setItem(`groupAdminView:${group.id}`, newState)
    setManageResourceViewState(newState)
  }

  // Define group
  let group1 = data?.groupGetV2 || {};

  // Load if API is called, loading, and had not received data
  if (
    // called &&
    // loading &&
    !data) {
    return <GhostLoader />;
  }

  let adminView = group1?.iAmAdmin && manageResourceView === MANAGERESOURCESVIEW.ADMIN;


  return (
    <>
      {/* BREADCRUMBS */}
      <BreadCrumbs
        onClick={() => history.push(group)}
        routePage ={`Group`}
        history ={history}
        pages =  {[{seperator:">",page1 : `${group1?.name}`}]}
      />
      <div className="group">
        <div
          className="row"
          style={{
            maxWidth: "1100px",
            margin: "auto",
          }}
        >
          <div className="col-md-12 col-sm-12 col-xs-12 nopadding-left">


            {
              group1?.iAmAdmin && (
                <div className="row">
                  <div className="group__member-admin-view">
                    <NotataButton
                      buttonStyle={`${manageResourceView !== MANAGERESOURCESVIEW.MEMBER ? 'unnselected':""}`}
                      bgcolor="group__member-admin-view__member medium primary_color" text="Member"
                      onClick={() => setManageResourceView(MANAGERESOURCESVIEW.MEMBER)}
                    />
                    <NotataButton
                      buttonStyle={`${manageResourceView !== MANAGERESOURCESVIEW.ADMIN ? 'unnselected':""}`}
                      bgcolor="group__member-admin-view__admin medium primary_color" text="Admin"
                      onClick={() => setManageResourceView(MANAGERESOURCESVIEW.ADMIN)}
                    />
                  </div>
                </div
                >
              )
            }

            <div className="row group__header-row">
              {/* Company name and description */}
              <div className="col-sm-12 col-md-7">
                <InfoSection
                  group={group1}
                  refetch={refetch}
                  history={history}
                  adminView={adminView}
                />
              </div>
              {/* Manage resources Modal */}

              <div className="col-sm-12 col-md-5 nopadding-left">
                <ManageResources
                  group={group1}
                  adminView={adminView}
                />

              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Card containerClassName="group__view-variants">
                  <NotataButton
                    startIcon="format_list_bulleted"
                    text="List view"
                    buttonStyle={`outlined ${view === VIEWS.LIST ? "selected" : ""}`}
                    onClick={() => {
                      localStorage.setItem(`groupView:${group.id}`, VIEWS.LIST)
                      setView(VIEWS.LIST);
                    }}
                  />
                  <NotataButton
                    startIcon="apps"
                    text="Grid view"
                    buttonStyle={`outlined ${view === VIEWS.GRID ? "selected" : ""}`}
                    onClick={() => {
                      localStorage.setItem(`groupView:${group.id}`, VIEWS.GRID)
                      setView(VIEWS.GRID);
                    }}
                  />
                  <NotataButton
                    startIcon="apps"
                    text="full view"
                    buttonStyle={`outlined ${view === VIEWS.FULL ? "selected" : ""}`}
                    onClick={() => {
                      localStorage.setItem(`groupView:${group.id}`, VIEWS.FULL)
                      setView(VIEWS.FULL);
                    }}
                  />
                </Card>
              </div>
            </div>
            <StartupList
              group={group1}
              history={history}
              adminView={adminView}
              refetch={refetch}
              view={view}
            />
          </div>
        </div>
      </div>
    </>
  );
}


// import React, { useEffect, useState } from "react";
//
// // API STUFF
// import { useLazyQuery } from "@apollo/client";
// import { groupGetV2 } from "private/Apollo/Queries";
//
// // UI COMPONENTS
// import { GhostLoader,BreadCrumbs, CardComponent, NotataButton } from "Components/NotataComponents";
//
// // STYLES
// import "./GroupHome.scss";
//
// // CUSTOM COMPONENTS
// import InfoSection from "./Components/InfoSection/InfoSection";
// import ManageResources from "./Components/ManageResources/ManageResources";
// import StartupList from "./Components/StartupList/StartupList";
//
// // DEFINITIONS
// import { group } from "definitions";
// import { VIEWS } from "private/Pages/constants";
//
// // MAIN FUNCTION
// export default function GroupPage({ match, history }) {
//   // States
//   const [viewAsMember, setViewAsMember] = useState(false);
//   const [ view, setView ] = useState(VIEWS.GRID);
//
//   // Queries
//   let [groupGet, { data, loading, called, refetch }] = useLazyQuery(groupGetV2, {
//     pollInterval: 5000
//   });
//
//   // Execute lazy query
//   useEffect(() => {
//     if (match.params.groupId) {
//       let variables = {
//         id: match.params.groupId,
//       };
//       groupGet({ variables });
//     }
//   }, [match]);
//
//
//   useEffect(() => {
//     let _view = localStorage.getItem(`groupView:${group.id}`)
//     setView(_view || VIEWS.LIST)
//   }, [localStorage])
//
//   // Define group
//   let group1 = data?.groupGetV2 || {};
//
//
//   // Load if API is called, loading, and had not received data
//   if (
//     loading && !data) {
//     return <GhostLoader />;
//   }
//
//   let adminView = group1?.iAmAdmin && !viewAsMember;
//
//   return (
//     <>
//       {/* BREADCRUMBS */}
//       <BreadCrumbs
//       onClick={() => history.push(group)}
//       routePage ="Group"
//       history ={history}
//       pages =  {[{seperator:">",page1 : `${group1?.name}`}]}
//       />
//
//       <div className="group-dashboard-container">
//         <div
//           className="row"
//           style={{
//             maxWidth: "1100px",
//             margin: "auto",
//           }}
//         >
//
//
//
//
//
//           <div className="col-md-12 col-sm-12 col-xs-12 nopadding-left">
//
//             <div className="row group-dashboard-container__header-row">
//
//               {/* Company name and description */}
//               <div className="col-sm-12 col-md-7">
//                 <InfoSection
//                   group={group1}
//                   refetch={refetch}
//                   history={history}
//                   adminView={adminView}
//                 />
//               </div>
//
//               {/* Manage resources Modals */}
//               <div className="col-sm-12 col-md-5 nopadding-left">
//                 <ManageResources
//                   group={group1}
//                   setViewAsMember={setViewAsMember}
//                   adminView={adminView}
//                 />
//               </div>
//
//             </div>
//
//
//             <CardComponent
//               containerClassName="group-dashboard-container__view-variants"
//               padding="large"
//               content={
//                 <>
//                   <NotataButton
//                     startIcon="format_list_bulleted"
//                     text="List view"
//                     buttonStyle={`outlined ${view === VIEWS.LIST ? "selected" : ""}`}
//                     onClick={() => {
//                       localStorage.setItem(`groupView:${group.id}`, VIEWS.LIST)
//                       setView(VIEWS.LIST);
//                     }}
//                   />
//                   <NotataButton
//                       startIcon="apps"
//                       text="Grid view"
//                       buttonStyle={`outlined ${view === VIEWS.GRID ? "selected" : ""}`}
//                       onClick={() => {
//                         localStorage.setItem(`groupView:${group.id}`, VIEWS.GRID)
//                         setView(VIEWS.GRID);
//                       }}
//                   />
//                   <NotataButton
//                     startIcon="apps"
//                     text="full view"
//                     buttonStyle={`outlined ${view === VIEWS.FULL ? "selected" : ""}`}
//                     onClick={() => {
//                       localStorage.setItem(`groupView:${group.id}`, VIEWS.FULL)
//                       setView(VIEWS.FULL);
//                     }}
//                   />
//                 </>
//               }
//             />
//             <StartupList
//               group={group1}
//               history={history}
//               adminView={adminView}
//               refetch={refetch}
//               view={view}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
