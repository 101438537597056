import React from "react";

// Library Components
import { Link } from "react-router-dom";

// CUSTOM COMPONENTS
import { NotataButton } from "Components/NotataComponents";

// Others
import { startup_page } from "definitions";
import { getVal } from "private/Pages/commonFunctions";

//Main Function
export default function ConnectionCreate({ notification }) {

  // Constants
  let connectionId = getVal(notification, "connectionId");
  return (
    <>
      <div className="notifications-container-new__notification__action-buttons">
        <Link className="link_text" to={`${startup_page}/company/${connectionId}?tab=1`}>
          <NotataButton
            size="x_small"
            bgcolor="primary_color"
            text="view"
          />
        </Link>        
      </div>
    </>
  );
}
