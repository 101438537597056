import gql from "graphql-tag";

export default gql`
  query publicGroupSettingsGet($groupId: ID!) {
    publicGroupSettingsGet(groupId: $groupId) {
        pinRequired
        invitedOnly
        withSubjectiveScores
        withEvaluationTemplate
    }
  }
`;
