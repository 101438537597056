import React, { useEffect } from "react";

// API STUFF
import { useLazyQuery } from "@apollo/client";
import { groupGetV2 } from "private/Apollo/Queries";

import { CardComponent } from "Components/NotataComponents";

import "./QRCode.scss"


const QRCode = ({match}) => {
    // Queries
    let [groupGet, { data }] = useLazyQuery(groupGetV2, {
    pollInterval: 5000
    });

    // Execute lazy query
    useEffect(() => {
    if (match.params.groupId) {
        let variables = {
        id: match.params.groupId,
        };
        groupGet({ variables });
    }
    }, [match]);

    let group = data?.groupGetV2 || {};

    console.log("group", group);

    return(
        <div className="qr-code-container">
            <CardComponent
              containerClassName="qr-code__card"
              style={{background: '#fafafa'}}
              padding="large"
              content={
                  <div className="qr-code-sub-container">
                      <h1 className="qr-code__title">{group.name}</h1>
                      <div className="qr-code__qr">
                          <img src={group?.publicSettings?.qr_code}/>
                      </div>
                      {
                        !!group?.publicSettings?.pinRequired && (
                          <div className="qr-code__pin-sequence">
                            {group?.pin?.toString().split("").map((p, i) => (
                              <span key={`${p}-${i}`}>{p}</span>
                            ))}
                          </div>
                        )
                      }
                  </div>
              }
            />
        </div>
    )
}

export default QRCode;