/* eslint-disable */
import React, { useState } from "react";

// UI COMPONENTS
import { InputForm ,NotataButton } from "Components/NotataComponents";

// STYLES
import "../LoginPages.scss";

// Assets
import man_standing from "assets/images/man_standing.svg";
import notata from "assets/images/auth-logo.svg";

// CUSTOM COMPONENTS
import { Instructor } from "./Instructor";
import FloatingLoginButtons from "./FloatingLoginButtons/FloatingLoginButtons";

// OTHERS
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";

//Main Function
export function ForgotPassword({ history }) {  

 // STATES
  const [emailSent, SetEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loader, setLoader] = useState(false);

  // FORMS
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required().trim(),
      })
    ),
  });
  const { isSubmitting } = formState;

  // Functions

  // OnSubmit function
  const onSubmit = async data => {
    const { email } = data;
    setLoader(true);
    try {
      await Auth.forgotPassword(email);
      SetEmail(email);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setErrorMessage(error.message);
    }
  };
  
  // handleInputChange function
  const handleInputChange = (val, name) => {
    if (name === "email") {
      setErrorMessage("");
    }
  };

  return (
    <>
      {emailSent ? (
        <Instructor
          loader={loader}
          handleResend={onSubmit}
          email={emailSent}
          history={history}
        />
      ) : (
        // ForgotPassword
        <div className='auth_structure'>
          <div className='auth_structure__left'>
            <div className="auth_structure__left__mainContent">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='logoContainer'>
                  <div className="logo_content">
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          marginBottom: "15px",
                        }}
                        src={notata}
                        alt="logo"
                        className='logo'
                      />
                  </div>
                  
                  <h1>Forgot your password?</h1>
                  <p
                    style={{
                      margin: "25px 0",
                      lineHeight: "18.05px",
                      fontSize: "15px",
                      color: "#969BA3",
                    }}
                  >
                    Please enter your email and we'll find your account and then
                    you will receive instructions on your email to rest your
                    password
                  </p>
                </div>
                <div style={{ marginTop: "20px", width: "100%" }}>
                  
                  {/* Email input field */}
                  <InputForm
                    label="Email"
                    type="email"
                    errorMessage={errorMessage}
                    name="email"
                    placeholder="Email"
                    handleInputChange={(value, name) =>
                      handleInputChange(value, name)
                    }
                    required
                    reference={register({ required: true })}
                  />

                  {/* Submit NotataButton */}
                  <NotataButton
                    size="xx_large"
                    bgcolor="primary_color"
                    text="SEND INSTRUCTIONS"
                    buttonType="submit"
                    loading={isSubmitting}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className='auth_structure__right'>
            <img src={man_standing} alt="man_standing" />
          </div>
          {/*<FloatingLoginButtons />*/}
        </div>
      )}
    </>
  );
}