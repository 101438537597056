import React from "react";

// STYLES
import "./TrafficLights.scss"


// MAIN FUNCTION
export default function TrafficLights({ questions, question, setQuestions }) {
  // STATES
  //  const [redCount, setRedCount] = useState(0);
  //   const [yellowCount, setYellowCount] = useState(0);
  //    const [greenCount, setGreenCount] = useState(0);

  return (
    <div className="traffic-lights-container">
      <div className="option-container text-center">
        <div className="traffic-light">
          <div className="highlighter red">
            <div className="text">RED</div>
          </div>
        </div>
      </div>
      <div className="option-container text-center">
        <div className="traffic-light">
          <div className="highlighter yellow">
            <div className="text">YELLOW</div>
          </div>
        </div>
      </div>
      <div className="option-container text-center">
        <div className="traffic-light">
          <div className="highlighter green">
            <div className="text">GREEN</div>
          </div>
        </div>
      </div>
    </div>
  );
}
