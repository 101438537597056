import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

// API Queries
import { tagGroupsGet } from "private/Apollo/Queries";

// Custom Components
import { NotataButton, Loader, Tags, Tag } from "Components/NotataComponents";

// Styles
import "./TagsModal.scss";

// Main Function
export function TagsModal({ preSelectedTags = [], addTag, removeTag, addNewTagModal, addingTag, setGroup, group, setInput, showError, parentLoading }) {


  // Good :D


  // States
  const [tagsStates, setTagsStates] = useState({});
  const [selectedTag, setSelectedTag] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showAddNewBtn, setShowAddNewBtn] = useState(true);

  // Queries
  const { data, loading, error } = useQuery(tagGroupsGet);
  const tagGroups = data?.tagGroupsGet || [];
  const removeTagFn = tagId => {
    const removeTagId = selectedTag.filter(item => item !== tagId);
    setSelectedTag(removeTagId.length ? removeTagId : []);
    removeTag(tagId);
  };

  const addTagFn = tag => {
    setSelectedTag(oldArray => [...oldArray, tag.id]);
    let doubleClick = selectedTag.filter(data => data === tag.id);
    if (doubleClick.length !== 0) {
      return;
    }
    addTag(tag);
  };

  useEffect(() => {
    if (!addingTag) {
      setSearchValue("");
      setShowAddNewBtn(false);
    }
  }, [addingTag])

  function handleSearch(value) {
    if (value && value !== " " && value.length !== 0) {
      setSearchValue(value);
      const isTagPresent = tagGroups.some(tagGroup => tagGroup.tags
        .some(({ name }) =>
          name
            .toLowerCase()
            .includes(value.toLowerCase())
        ));
      setShowAddNewBtn(!isTagPresent);
    } else {
      setShowAddNewBtn(false);
      setSearchValue("");
    }
  }

  if (error) return <pre>{error.message}</pre>; //if query has issue

  if (!data && loading) {
    return (
      <div className="tags-container">
        <Loader addPadding={true} />
      </div>
    );
  }
  if (addingTag) {
    return (
      <div className="tags-container">
        <div>
        <Tag
         color="primary_tag"
         size="md_tag"
         label={`${group.name ? `${group.name} : ${searchValue}` : searchValue}`}
        />
        </div>

        <div className="tags-container__select_group">Select group</div>
        {tagGroups?.map(tagGroup =>
          <div key={tagGroup.id} style={{ padding: '8px 0px 8px 0px' }}>
            <NotataButton
              size="large"
              bgcolor="primary_color"
              text={tagGroup.name}
              onClick={() => {
                setGroup(tagGroup);
                setInput(searchValue);
              }}
            />
          </div>
        )}
        {showError && <div className="tags-container__error">Please select group to add tag</div>}
      </div>
    )
  }


  return (
    <>
    


      {/* {!loading && ( */}
      <div className="tags-container">
      {(loading || parentLoading) && <Loader size="small" noPadding={true} />}
      {preSelectedTags.length > 0 && (
        <>
          <div className="tags-container__sub-heading" style={{ marginBottom: 10 }}>
            Selected Tags
          </div>
          <div className="selectedTags">
            {preSelectedTags?.map(tag => {
              return (
                <Tag
                  key={tag.id}
                  label={`${tag?.group?.name}: ${tag?.name}`}
                  color="primary_tag"
                  size="md_tag"
                  tagmargin="tag_margin"
                  killButton={()=>removeTagFn(tag.id)}
                />
              );
            })}
          </div>
        </>
      )}

      <div className="tags-container__sub-heading"/>
      <Tags
        onchange={handleSearch}
        addNewTagModal={addNewTagModal}
        showAddNewBtn={showAddNewBtn}
        placeholder={"Find Tags"}
      />

      <div className="tags-container__dropdown" >
      
        {
          tagGroups.map(tagGroup => {
            if (tagGroup?.tags?.length) {
              return (
                <div className="row" key={tagGroup.id}>
                  <div className="col-sm-10 col-xs-10 section-heading"
                      onClick={() => {
                        setTagsStates({
                          ...tagsStates,
                          [tagGroup.id]: !tagsStates[tagGroup.id],
                        });
                      }}
                  >
                    {tagGroup.name}
                  </div>
                  <div className="col-sm-2 col-xs-2 expand-collapse-icon">
                    <i
                      className={`fa ${tagsStates[tagGroup.id]
                        ? "fa-chevron-up"
                        : "fa-chevron-down"
                        }`}
                      aria-hidden="true"
                      onClick={() => {
                        setTagsStates({
                          ...tagsStates,
                          [tagGroup.id]: !tagsStates[tagGroup.id],
                        });
                      }}
                    />
                  </div>
                  <div
                    className={`col-sm-12 col-xs-12 ${tagsStates[tagGroup.id] || searchValue.length
                      ? ""
                      : "collapse"
                      }`}
                  >
                    <div className="type-tags-container">
                      {!searchValue.length && (
                        <>
                          {
                            tagGroup.tags.every(tag =>
                              preSelectedTags.some(data => data.id === tag.id)
                            ) ? <div className="no_tags">No tags to select</div>
                              : tagGroup.tags.map((tag, index) => {
                                let filterData = preSelectedTags.filter(
                                  data => data.id === tag.id
                                );
                                if (filterData.length === 0) {
                                  return (
                                    <div
                                    className="select-tags"
                                      key={tag.id}
                                    >
                                      <Tag
                                        label={tag.name}
                                        color="primary_tag"
                                        size="md_tag"
                                        onClick={()=> addTagFn(tag)}
                                      />
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                        </>
                      )}

                      {!!searchValue.length && (
                        <>
                          {tagGroup.tags
                            .filter(({ name }) => name.toLowerCase()
                              .includes(searchValue.toLowerCase()))
                            .map((tag) => {
                              let filterData = preSelectedTags.filter(
                                ({ id }) => id === tag.id
                              );

                              if (filterData.length === 0) {
                                return (
                                  <div
                                    className="select-tags"
                                    key={tag.id}
                                  >
                                   <Tag
                                      label={tag.name}
                                      color="primary_tag"
                                      size="md_tag"
                                      onClick={()=> addTagFn(tag)}/>
                                  </div>
                                  
                                );
                              } else {
                                return null;
                              }
                            })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        }
      </div>

      </div>
    </>
  );
}
