import React, { useState } from 'react';
import { useMutation } from "@apollo/client";
import StartupCard from "private/Pages/StartupPage/CommonComponents/StartupCard";
import SubjectiveScoresComp from "private/Pages/StartupPage/Tabs/Overview/components/SubjectiveScoresComp";
import CardMessageContainer from 'private/Pages/Group/Components/CardMessageContainer';
import { Card, NotataButton } from "Components/NotataComponents";
import "./Startup.scss";
import Evaluate from "../Components/Evaluate";
import { getScore, getEvaluated, saveScoreToLocalDB, getStoredDetails } from "utils/groupSharing";
import { publicGroupSetSubjectiveScore } from "public/Apollo/Mutations";

import StartupInfoSection from "./StartupInfoSection";
import Footer from "./Footer";

export default function StartupComponent({ group, creativeId, close, setEvaluateModal }) {

  const { evaluationTemplate, publicSettings } = group || {};

  const { withEvaluationTemplate, withSubjectiveScores } = publicSettings || {};
  const showSubjectiveScore = withSubjectiveScores;
  const showEvaluation = withEvaluationTemplate && evaluationTemplate;

  const groupId = group.id;
  const creative = group?.startups?.find(({id}) => id === creativeId)

  const queryOptions = { context: { clientName: "public" } };
  const [setSubjectiveScore] = useMutation(publicGroupSetSubjectiveScore, queryOptions);


  const isEvaluated = getEvaluated(creative, groupId);
  const score = getScore(creative, groupId);

  const sectionAnswers = creative?.newAnswers
  const storedData = getStoredDetails(groupId);

  const saveScore = async (score) => {
    saveScoreToLocalDB(groupId, score, creative.id);
    setSubjectiveScore({
      variables: {
        ...storedData?.member,
        groupId: groupId,
        creativeId: creative.id,
        pin: storedData?.pin,
        score,
      }
    })
  }

  return (
    <div
      className="startup-container"
      style={{ width: "100%" }}
    >

      <div
        className="back-button"
        onClick={close}
        style={{marginBottom: "20px"}}
      >
        <span className="material-icons back-icon">
          arrow_back_ios
        </span>
        <span className="page-heading">
          GO BACK TO GROUP
        </span>
      </div>

      <div className="startup-container__content">

        <StartupCard
          connection={{ creative: creative }}
        />

        {showSubjectiveScore && (
          <Card
            heading="Subjective Score"
            style={{padding: "30px"}}
          >
            <SubjectiveScoresComp
              isPublic={true}
              score={saveScore}
              connection={{
                ...creative,
                subjectiveScores: [
                  {
                    isMe: true,
                    creativeId: creative.id,
                    score
                  }
                ]
              }}
            />
          </Card>
        )}
        {showEvaluation && (
          <Card
            style={{padding: "30px"}}
          >
            <CardMessageContainer
              title="Heads up!"
              message={`The moderator of this group has invited you to evaluate this company.`}
              children={
                <NotataButton
                  onClick={setEvaluateModal}
                  text={isEvaluated ? "Evaluated" : "Evaluate"}
                  buttonStyle={`mb-big ${isEvaluated && 'outlined medium white_color' || 'primary_color'}`}
                />
              }
              notice
            />
          </Card>
        )}

        {
          sectionAnswers.length !== 0 &&
          creative?.newAnswers?.map(section => (
          <StartupInfoSection
            section={section}
            key={section.name}
          />
        ))}

      </div>

      <Footer/>

    </div>
  )
}