import React ,{ useState } from 'react'

// API STUFF
import { useMutation } from "@apollo/client";
import { groupStartupRemove } from "private/Apollo/Mutations";

// MAIN FUNCTION
export default function ManageStartups({group, canRemove }) {
  // STATES
  let [isRemoving, setIsRemoving] = useState({});
  // MUTATIONS
  let [removeStartupMutation] = useMutation(groupStartupRemove);
  const removeStartup = async(id) =>{
    if (isRemoving[id]) {
      return;
    }

    setIsRemoving({
      ...isRemoving,
      [id]: true,
    });

    let variables = {
      groupId: group?.id,
      creativeId: id,
    };
    try {
      await removeStartupMutation({ variables });
    } catch (error) {
      console.log("error");
    }

    setIsRemoving({
      ...isRemoving,
      [id]: false,
    });
  }
  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">STARTUPS</span>
      <div className="invite-member-modal__member-container__data">

        {
          group?.startups?.length === 0 && (
            <p className="message--not-found" >
              This group has no startups...
            </p>
          )
        }



        {group.startups?.map((startup, index) => {
          let { creative } = startup;
          return (
            <div className="invite-member-modal__member-container__member" key={`group-memebr-key-${index}`}>
              {
                canRemove && (
                  <i
                    className={
                      isRemoving[creative?.id]
                        ? "fa fa-spinner fa-spin"
                        : "fas fa-trash-alt"
                    }
                    aria-hidden="true"
                    onClick={async () => await removeStartup(creative?.id)}
                  />
                )
              }

              <div className="invite-member-modal__member-container__member__info">

                <div className="invite-member-modal__member-container__member__info__name">
                  {creative?.name}
                </div>

                <div className="invite-member-modal__member-container__member__info__email">
                  Shared by: {startup?.sharedByUser?.family_name}{" "}
                  {startup?.sharedByUser?.given_name}
                </div>

              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}