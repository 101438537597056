import React from "react";
import { useHistory } from "react-router-dom";

// Styles
import "./BrowseDropDown.scss";

// Main Function
export function BrowseDropDown({ items, open, setOpen }) {
  const history = useHistory();

  return (
    <>
      {open && (
        <div className="browse__drop-down" onClick={() => setOpen(!open)}>
          <div className="browse__drop-down__content">
            {items.map((item, index) => {
              return (
                <div
                  className={`browse__drop-down__content__item  ${item?.clasName}`}
                  style={item?.style}
                  key={`browse-drop-down-key-${index}`}
                  onClick={() => {
                    if (item.link) {
                      history.push(item.link);
                    }
                    if (item.callBack) {
                      item.callBack();
                    }
                  }}
                >
                  {item.icon && <span className="icon">{item.icon}</span>}

                  <span className={`text ${item.selected && "selected"}`}>
                    {item.text}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
