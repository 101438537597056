import React  from "react";

// OTHERS
import GroupsCard from "./Groups/GroupsCard"
import PublicSharings from "./PublicSharings/PublicSharings"
import StartupCard from "../../CommonComponents/StartupCard";
import CommentBox from "../../CommonComponents/CommentBox";

// STYLES
import "./Sharing.scss";

// MAIN FUNCTION
export default function Sharing({ connection }) {

  return (
    <div className="row tab-panel-container group-individual-container">
      <div className="col-sm-12 col-md-8">
        <StartupCard connection={connection} />
        <GroupsCard connection={connection} />
        <PublicSharings connection={connection} />
      </div>
      <CommentBox commentBox={"comment_box"} connectionId={connection?.id} />
    </div>
  );
}