import React from "react";

// COMPONENTS
import { Modal } from "Components/NotataComponents";
import { InputForm, CodeSnippet } from "Components/NotataComponents";

function SharabaleLink({sharingObject}){

  // State
  const email = sharingObject?.sharedWithEmail || "";
  const url = `${window.location.origin}/public/share/${sharingObject?.id}?sharing=${email}`;

  

  return(
    <div className="share-evaluation-container sharable_link" >
      <div className='share-evaluation-container__input-div'>
        <InputForm
          label="Email"
          type="email"
          name="email"
          disabled={true}
          placeholder={email}
          fullWidth={true}
        />
      </div>
      <CodeSnippet 
          heading="Link"
          content={url}
          contentType="Link"
        />
      
    </div>
  )
}

export default function SharableLinkModal({sharingObject, close, intermediate}) {

  return (
    <Modal
      title="Sharable link"
      innerClassName="sharable-link-model"
      close={close}
      intermidate={intermediate}
      intermidateTxt="PREVIEW"
      intermidateStyle="outlined"
      closeTxt="CANCEL"
      children={
        <SharabaleLink
          sharingObject={sharingObject}
        />
      }
    />
  )

}