import React from "react";

// API STUFF
import { useQuery } from "@apollo/client";
import { notificationsGet } from "private/Apollo/Queries";

// UI COMPONENTS
import {
  Loader,
  BreadCrumbs,
  CardComponent,
} from "Components/NotataComponents";

// COMMON COMPONENTS
import Notifications from "Components/Common/Notifications/Notifications";

// DEFINITIONS
import { settings } from "definitions";

// STYLES
import "./SettingsNotifications.scss";

import { NOTIFICATIONS_POLL_INTERVAL } from "../../constants";

// MAIN FUNCTION
export default function SettingsNotifications({ history }) {
  // QUERIES
  const { data, loading } = useQuery(notificationsGet, {
    pollInterval: NOTIFICATIONS_POLL_INTERVAL,
    fetchPolicy: "network-only",
  });

  // DATA MAPS
  let notifications = data?.notificationsGet || [];


  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[{ seperator: ">", page1: "NOTIFICATIONS" }]}
      />

      <div className="settingsNotifications-container">
        <CardComponent
          containerClassName="settingsNotifications-container__card col-md-12"
          padding="large"
          title="Notifications"
          titleSize="medium"
          backLink={() => history.push(settings)}
          content={
            <div className="settingsNotifications">
              {!data && loading && <Loader />}

              {!notifications.length && (
                <div className="settingsNotifications__no-notifications">
                  You have no notifications
                </div>
              )}
              <Notifications notifications={notifications} history={history} />
            </div>
          }
        />
      </div>
    </>
  );
}
