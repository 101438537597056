import gql from "graphql-tag";
import { userFragments } from "../Fragments";

export default gql`
    mutation userV3update($email: String!) {
        userV3update(email: $email) {
            ...userFields
        }
    }
    ${userFragments}
`;
