import React, { useState } from "react";

// API STUFF
import { useLazyQuery, useQuery } from "@apollo/client";
import { connectionAutoCompleteName, groupsGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { Modal, Dropdown, InputFormWithButton } from "Components/NotataComponents";
import ManageStartups from "./ManageStartups";
import GroupStartupsImport from "./GroupStartupsImport";
import GroupStartupsSearchResults from "./GroupStartupsSearchResults";


function SearchStartups({group}) {

  // States
  const [ searchString, setSearchString ] = useState("");

  // Queries
  let [getSearchResults, { data, called, loading }] = useLazyQuery(connectionAutoCompleteName);


  //  MAPS AND REDUCERS
  let suggestions = data?.connectionAutoCompleteName || [];

  // // Remove if already in group
  // suggestions = suggestions.filter(
  //   ({ creativeId }) => !group.startups?.some(({ creative }) => creativeId === creative?.id)
  // )

  // Max length = 6
  suggestions = suggestions.filter((_, i) => i < 6);


  return (
    <div>

      <div className="invite-member-modal__search-header">
        Add startups to group
      </div>

      <div className="invite-member-modal__search">
        <div className="inputform">
          <InputFormWithButton
            type="name"
            name="name"
            placeholder="Search..."
            loading={loading}
            handleChange={(e) => {
              setSearchString(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                getSearchResults({variables: {search: searchString}})
              }
            }}
            btn={true}
            buttonType={"submit"}
            buttonStyle="search-with-button"
            btntext="search"
            setBtn={() => null}
            onClick={() => {
              getSearchResults({variables: {search: searchString}})
            }}
            isValid={true}
          />
        </div>

        {
          (!loading && called && suggestions.length === 0) && (
            <div className="invite-member-modal__member-container">
              <span className="invite-member-modal__member-container__heading">
                SEARCH RESULTS
              </span>
              <p className="message--not-found" >
                No results...
              </p>
            </div>
          )
        }

      </div>

      {
        suggestions.length !== 0 && (
          <GroupStartupsSearchResults
            group={group}
            preStartups={group.startups}
            data={suggestions}
          />
        )
      }

    </div>
  )
}





// MAIN FUNCTION
export default function ManageStartupsModal({ group, close }) {

  // CONSTANTS
  let canRemove = group.iAmAdmin;

  const [selectedGroupId, setSelectedGroupId] = useState();

  let groupsQuery = useQuery(groupsGetV2);
  let groups = groupsQuery?.data?.groupsGetV2 || [{name:"--Select Group--", id:"0"}];

  return (
    <Modal
      title="Manage Startups"
      submit={({ key }) => key !== "Enter" && close()}
      close={close}
      submitTxt="OK"
      innerClassName="add-startup-modal"
    >

      <div className="invite-member-modal-container">


        <div className="row invite-row">

          {/* SEARCH STARTUPS */}
          <div
            className="col-sm-12"
            style={{ marginTop: "20px" }}
          >
            <SearchStartups group={group} />
          </div>



          {/* SELECT FROM EXISTING GROUP */}
          <div
            className="col-sm-12"
            style={{ marginTop: "20px" }}
          >
            <div className="invite-member-modal__search-header">
              Import startups from existing group
            </div>
            <div className="invite-member-modal__select-group">
              <Dropdown
                title="Group"
                items={groups || []}
                setSelectedItem={item => {
                  setSelectedGroupId(item.id);
                }}
              />
            </div>

            {
              selectedGroupId && (
                <GroupStartupsImport
                  toGroupId={group.id}
                  fromGroupId={selectedGroupId}
                  preStartups={group.startups}
                />
              )
            }

          </div>





          {/* STARTUPS IN GROUP */}
          <div
            className="col-sm-12"
            style={{ marginTop: "20px" }}
          >
            <ManageStartups
              group={group}
              canRemove={canRemove}
            />
          </div>

        </div>
      </div>
    </Modal>
  );
}