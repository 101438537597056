import React, { useState, useEffect } from "react";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { userUpdate } from "private/Apollo/Mutations";
import { userGet } from "private/Apollo/Queries";

// COMPONENTS
import Sidebar from "../Sidebar";
import ManageColumnCheckBox from "./ManageColumnCheckBox"

// STYLES
import "../Sidebar.scss";

// OTHERS
import { getNewValues } from "./getNewValues.js";
import { defaultColumnValues, columnOptions } from "../../constants";
import { omit } from "lodash";

function getIsShowingAll({columnValues, evaluationTemplates, tagGroups}) {
  return (columnValues.evaluationTemplates.length === evaluationTemplates.length ||
      evaluationTemplates.length === 0) &&
    (columnValues.tagGroups.length === tagGroups.length ||
      tagGroups.length === 0) &&
    columnValues.groups &&
    columnValues.funnels &&
    columnValues.tags &&
    columnValues.subjectiveScore &&
    columnValues.createdAt &&
    columnValues.updatedAt
}


export default function ColumnSidebar({
                                        close,
                                        evaluationTemplates,
                                        tagGroups,
                                        columnValues,
                                        setColumnValues
                                      }) {


  // MUTATIONS
  const [updateUser] = useMutation(userUpdate);

  // MAPS & REDUCERS
  const isShowingAllEvaluations = evaluationTemplates.length === columnValues.evaluationTemplates.length;
  const isShowingAllTags = tagGroups.length === columnValues.tagGroups.length;
  const isShowingAll = getIsShowingAll({
    columnValues,
    evaluationTemplates,
    tagGroups
  })



  // FUNCTIONS
  const handleNewValue = async (e) => {

    // Get updated values
    let newValues = getNewValues({
      e,
      columnValues,
      evaluationTemplates,
      tagGroups
    });

    // Join old data with new data
    let columnSettings = {
      ...columnValues,
      ...newValues
    }

    // Set state
    setColumnValues(columnSettings)

    // Update user object
    updateUser({
      variables: { input: { columnSettings } }
    });

  };

  return (

    <Sidebar
      title={
        <span className='sidebarHeading'>
          Manage Columns
        </span>
      }
      close={close}
    >

      <div className='manage'>

        {/* SHOW ALL */}
        <ul>
          <li className='manage__firstOrderManage'>
            <ManageColumnCheckBox
              checked={!!isShowingAll}
              name="showAll"
              value="showAll"
              onChange={handleNewValue}
            />
            <p>
              <label
                className="chek_box_text"
                htmlFor={"showAll"}
              >
                Show All
              </label>
            </p>
          </li>
        </ul>

        {/* OTHER OPTIONS */}
        <ul>
          {columnOptions.map(item => (
            <li key={item.name}>
              <ManageColumnCheckBox
                checked={!!columnValues[item.name]}
                name={item.name}
                value={item.name}
                onChange={handleNewValue}
              />
              <p>
                <label
                  className="chek_box_text"
                  htmlFor={item.name}
                >
                  {item.value}
                </label>
              </p>
            </li>
          ))}

          {/* TAG GROUPS */}
          {(tagGroups.length !== 0) && (
            <li>


              <label className='customCheck'>
                <input
                  checked={false}
                  name="disabled-box"
                  onChange={() => console.log('do nothing')}
                  type="checkbox"
                  disabled
                />
                <span className='disabled-checkmark' id="disabled-box" />
                <p>Tag Groups</p>
              </label>

              {/* EACH TAG GROUP */}
              <ul>
                {tagGroups.map(tagGroup => (
                  <li key={tagGroup.id}>
                    <ManageColumnCheckBox
                      checked={
                        !!columnValues?.tagGroups?.includes(
                          tagGroup.id
                        )
                      }
                      value={tagGroup.id}
                      name="tagGroup"
                      onChange={handleNewValue}
                    />
                    <p><label className="chek_box_text" htmlFor={tagGroup.id} >{tagGroup.name}</label></p>
                  </li>
                ))}
              </ul>

            </li>
          )}

          {/* EVALUATION TEMPLATES */}
          {evaluationTemplates.length !== 0 && (
            <li>
              <ManageColumnCheckBox
                checked={!!isShowingAllEvaluations}
                name={"allEvaluations"}
                value="allEvaluations"
                onChange={handleNewValue}
              />
              <p>
                <label
                  className="chek_box_text"
                  htmlFor={"allEvaluations"}
                >
                  Evaluation
                </label>
              </p>

              {/* EACH EVALUATION TEMPLATE */}
              <ul>
                {evaluationTemplates.map(summary => {
                  return (
                    <li key={summary.id}>
                      <ManageColumnCheckBox
                        name="evaluationTemplates"
                        value={summary.id}
                        checked={
                          !!columnValues?.evaluationTemplates?.includes(
                            summary.id
                          )
                        }
                        onChange={handleNewValue}
                      />
                      <p>
                        <label
                          className="chek_box_text"
                          htmlFor={summary.id}
                        >
                          {summary.name}
                        </label>
                      </p>
                    </li>
                  );
                })}
              </ul>

            </li>

          )}

        </ul>

      </div>

    </Sidebar>

  );
}