import gql from "graphql-tag";
import { creativeFragments } from "../Fragments";

export default gql`
    mutation creativeCreate($input: CreativeInput!) {
        creativeCreate(input: $input) {
            ...creativeFields
        }
    }
    ${creativeFragments}
`;
