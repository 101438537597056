import React, { useEffect, useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import { Route, matchPath } from "react-router";

//AWS
import { Auth } from "aws-amplify";

// API
import { useQuery, useMutation } from "@apollo/client";
import { userGet, accountGet } from "private/Apollo/Queries";
import { userUpdate } from "private/Apollo/Mutations";

// ROUTES
import {

  //Signup and login
  signup,
  pre_profile,

  //Dashboard
  dashboard,

  //Mystartup
  archive_page,
  evaluation_templates_page,
  evaluation_template_page,
  evaluate_page,
  evaluation_summary_page,
  startup_page,
  startup_company_profile,
  //evaluation_summary,

  // sharing_preview
  sharing_preview,

  //Groups
  group,
  group_new,
  group_dashboard,
  group_qr,

  //Reports
  reports,

  //News
  news,
  news_view_one,
  new_news,
  edit_news,

  //Settings
  setting_profile,
  settings,
  your_team,
  web_form,
  funnels1,
  tags1,
  notification,

  //Top Menu
  reminders,

  //Documentation
  documentation,

  //Fake
  fake_page,
  payment_page,

  // Admin
  // admin

  //This is created by Jorgen
  // notifications

  startup_print_page,
  waiting_list

} from "../definitions";


// Loader
import { GhostLoader } from "Components/NotataComponents";
import { ErrorBoundary } from "Components/ErrorBoundary";
import NotataTour from "Components/Common/NotataTour/NotataTour";

//Navigation
import Navigation from "../Components/Navigation/Navigation";


// New MyStartups Info
import Dashboard from "./Pages/Dashboard/Dashboard";

//Mystartup
import { StartupPage as StartupInfo } from "./Pages/StartupPage/index";
import MyStartups from "./Pages/MyStartups/MyStartups";
//import EvaluationDetail from "./Pages/EvaluatePage/EvaluationSummaryPage/EvaluationDetail";
import EvaluationSummaryPage from "./Pages/EvaluatePage/EvaluationSummaryPage/EvaluationSummaryPage"
import { EvaluationTemplatesPage } from "./Pages/EvaluationTemplates/EvaluationTemplatesPage/EvaluationTemplatesPage";
import { EvaluationTemplatePage } from "./Pages/EvaluationTemplates/EvaluationTemplatePage/EvaluationTemplatePage";
import { EvaluationTemplateSectionPage } from "./Pages/EvaluationTemplates/EvaluationTemplateSectionPage/EvaluationTemplateSectionPage";
import ArchivePage from "./Pages/ArchivePage/ArchivePage.js";

//Groups
import Groups from "./Pages/Group/GroupsHome/GroupsHome";
import GroupHome from "./Pages/Group/GroupHome/GroupHome";
import QRCode from "./Pages/Group/QRCode/QRCode";

//Reports
import Reports from "./Pages/Reports/Reports";

//News
import News from "./Pages/News/News";
import NewsViewOne from "./Pages/News/NewsHelpers/NewsViewOne";
import NewsEdit from "./Pages/News/NewsHelpers/NewsEdit";

//Settings
import Settings from "./Pages/Settings/Settings";
import SettingsyourTeam from "./Pages/Settings/SettingsYourTeam/SettingsYourTeam";
import SettingswebForm from "./Pages/Settings/SettingsWebForm/SettingsWebForm";
import SettingsTags from "./Pages/Settings/SettingsTags/SettingsTags";
import SettingsFunnels from "./Pages/Settings/SettingsFunnels/SettingsFunnels";
import SettingsNotifications from "./Pages/Settings/SettingsNotifications/SettingsNotifications";
import SettingsProfile from "./Pages/Settings/SettingsProfile/SettingsProfile";
import EvaluatePage from "./Pages/EvaluatePage/Evaluate/Evaluate";
// import EvaluationSummaryPage from "./Pages/EvaluatePage/EvaluationSummaryPage/EvaluationSummaryPage";
import Payment from "./Pages/LoginPages/Payment/Payment";

// Admin
import Admin from "./Pages/Admin/Admin";
//Top Menu
import TopMenuReminders from "../Components/TopMenuLinksFolder/TopMenuRemainders/TopMenuReminders";

//Documentation
import Documentation from "./Pages/Documentation/Documentation";




//Fake
import FakePage from "./Pages/Fake/FakePage";

import Printable from "./Pages/StartupPage/Printable/Printable";



//UI Library
import UILibrary from "Components/NotataComponents/UILibrary/UILibrary"

// Constants
import { NOTATATOURCALLERS } from './Pages/constants';
import PreviewStartup from "Components/Common/PreviewStartup/PreviewStartup";
/* // New Jørgen import
import Notifications from "../Components/Commom_Components/Notifications/Notifications"; */
import queryString from "query-string";


export const RouterComponent = ({ history }) => {
  return (
    <Switch>

      {/* Top Menu */}
      <Route eaxct path={reminders} component={TopMenuReminders} />

      {/* MyStartups */}
      <Route exact path={startup_page} component={MyStartups} />

      {/*  Dashboard  */}
      <Route exact path={dashboard} component={Dashboard} tourCaller={NOTATATOURCALLERS.DASHBOARD} />

      {/* { Preview_startup} */}

      <Route history={history} exact path={sharing_preview} component={PreviewStartup} />

      <Route exact path={archive_page} component={ArchivePage} />
      <Route exact path={startup_company_profile} component={StartupInfo} />

      <Route
        exact
        path={`${startup_page}/UI_Library`}
        component={UILibrary}
      />


      {/* <Route
        exact
        path={`${startup_page}/:connectionId/evaluation_summary/:evaluationId`}
        component={EvaluationSummary}
      /> */}

      {/* Group */}
      <Route exact path={`${group}`} component={Groups} />
      <Route exact path={`${group_new}`} component={Groups} />
      <Route exact path={`${group_dashboard}/:groupId`} component={GroupHome} />
      <Route exact path={`${group_qr}/:groupId`} component={QRCode} />

      {/* Reports */}
      <Route exact path={reports} component={Reports} />

      {/* News */}
      <Route eaxct path={news} component={News} />
      <Route eaxct path={`${news_view_one}/:id`} component={NewsViewOne} />
      <Route eaxct path={new_news} component={NewsEdit} />
      <Route eaxct path={`${edit_news}/:id`} component={NewsEdit} />

      {/* Settings  */}
      <Route exact path={settings} component={Settings} />
      <Route exact path={your_team} component={SettingsyourTeam} />
      <Route exact path={web_form} component={SettingswebForm} />
      <Route exact path={tags1} component={SettingsTags} />
      <Route exact path={funnels1} component={SettingsFunnels} />
      <Route exact path={notification} component={SettingsNotifications} />
      <Route exact path={setting_profile} component={SettingsProfile} />
      <Route
        exact
        path={[
          `${evaluate_page}/:connectionId/:templateId/:evaluationId`,
          `${evaluate_page}/:connectionId/:templateId`,
        ]}
        component={EvaluatePage}
      />
      <Route
        exact
        path={`${evaluation_summary_page}/:connectionId/:templateId/:evaluationId`}
        component={EvaluationSummaryPage}
      />

      <Route
        exact
        path={evaluation_templates_page}
        component={EvaluationTemplatesPage}
      />

      <Route
        exact
        path={`${evaluation_template_page}/:id`}
        component={EvaluationTemplatePage}
      />

      <Route
        exact
        path={`${evaluation_template_page}/:id/section/:sectionId`}
        component={EvaluationTemplateSectionPage}
      />

      {/* Documentation */}
      <Route exact path={documentation} component={Documentation} />

      {/* Fake */}
      <Route exact path={fake_page} component={FakePage} />
      {/* <Route exact path={`${fake_page}/:id`} component={FakePage2} /> */}

      {/* Admin */}
      <Route exact path={`${startup_page}/admin`} component={Admin} />
      <Route exact path={payment_page} component={Payment} />
      {/* Error route */}
      <Route render={() => <div>404</div>} />

      {/* <Route eaxct path={notifications} component={Notifications} /> */}
    </Switch>
  );
};

const getCaller = (pathname, search, isSignupTour) => {
  if (pathname === dashboard) {
    if (isSignupTour) {
      return NOTATATOURCALLERS.DASHBOARDHOME
    }
    return NOTATATOURCALLERS.DASHBOARD;
  }
  else if (pathname === startup_page) {
    return NOTATATOURCALLERS.MYSTARTUPS;
  }
  else if (pathname.startsWith("/startup_page/company")) {
    let tab = queryString.parse(search);
    if (tab.tab === "0" || tab.tab === undefined) {
      return NOTATATOURCALLERS.COMPANYPAGESTATRUPINFO;
    } else if (tab.tab === "1") {
      return NOTATATOURCALLERS.COMPANYPAGEOVERVIEW;
    } else if (tab.tab === "2") {
      return NOTATATOURCALLERS.COMPANYPAGEEVALUATION;
    } else if (tab.tab === "3") {
      return NOTATATOURCALLERS.COMPANYPAGEGROUPS;
    }
  }
  else if (pathname === group) {
    return NOTATATOURCALLERS.GROUPS;
  }
  else if (pathname.startsWith("/startup_page/group/group_dashboard")) {
    return NOTATATOURCALLERS.GROUPSDASHBOARD;
  }
  else if (pathname === reports) {
    return NOTATATOURCALLERS.REPORTS
  }
  else if (pathname === evaluation_templates_page) {
    return NOTATATOURCALLERS.EVALUATIONTEMPLATES
  }
  else if (pathname.startsWith(evaluation_template_page)) {
    return NOTATATOURCALLERS.CREATEEVALUATIONTEMPLATES
  }
  else if (pathname === funnels1) {
    return NOTATATOURCALLERS.SETTINGSFUNNEL
  }
  else if (pathname === settings) {
    return NOTATATOURCALLERS.SETTINGS
  }
  else if (pathname === tags1) {
    return NOTATATOURCALLERS.SETTINGSTAGS
  }
  else if (pathname === your_team) {
    return NOTATATOURCALLERS.SETTINGSYOURTEAM
  }
  else if (pathname === web_form) {
    return NOTATATOURCALLERS.SETTINGSWEBFORM
  }
}

const WithNavigationWrapper = ({...props}) => {

  const [ withBanner, setWithBanner ] = useState(true)
  const { data, loading, error } = useQuery(userGet);
  const accountRes = useQuery(accountGet);

  useEffect(() => {
    if (accountRes?.data?.accountGet?.stripeInfo) {
      setWithBanner(false)
    }
  }, [accountRes])


  const [userTourUpdate] = useMutation(userUpdate);
  const [stepPosition, resetTourCounter] = useState(0);
  const [isTourOpen, setTourOpen] = useState(data?.userGet?.startTour ? data?.userGet?.startTour : false);
  const tourCaller = getCaller(props.location.pathname, props.location.search, data?.userGet?.startTour)

  const setTourToRestart = () => {
    setTourOpen(true);
    resetTourCounter(0);
  }
  const onTourClose = () => {
    setTourOpen(false);
    if (data?.userGet?.startTour === true) {
      userTourUpdate({
        variables: {
          input: {
            startTour: false
          }
        }
      })
    }
    return true;
  }

  return (

    <div
      className="page_container"
      style={{
        display: "flex",
        position: "relative",
        paddingTop: withBanner ? "30px" : "0px"
      }}>

      <NotataTour
        caller={tourCaller}
        isOpen={isTourOpen}
        setTourOpen={setTourOpen}
        onClose={onTourClose}
      />

      <Navigation
        withBanner={withBanner}
        history={props.history}
        setTourOpen={setTourToRestart}
        isTourOpen={isTourOpen}
        tourCaller={tourCaller}
      />

      <div
        className={`logged_in_page_content ${!isTourOpen || (isTourOpen && !tourCaller) ? "logged_in_overflow" : ""} ${matchPath(props.location.pathname, {
          path: [`${startup_page}/:id`],
          exact: true,
          strict: false,
        })
          ? "show_right_activity"
          : ""
        }`}
      >

        <ErrorBoundary>
          <RouterComponent {...props} />
        </ErrorBoundary>

      </div>
    </div>
  )
}

const WrapperComponent = ({ location }) => {


  if (window.Intercom) {
    window.Intercom('update', {
      "hide_default_launcher": false
    });
  }


  const [userLoggedIn, setUserLoggedIn] = useState(undefined);
  const { data, loading, error } = useQuery(userGet);


  useEffect(() => {
    Auth.currentAuthenticatedUser()
    .then(() => setUserLoggedIn(true))
    .catch(() => setUserLoggedIn(false));
  }, []);

  if (userLoggedIn === false) {

    // Get path we are trying to access
    localStorage.setItem("access_point", location.pathname)

    let path = location.pathname.split('/')
    path.pop()
    path = path.join('/')

    if (
      path === '/startup_page/group/group_dashboard' ||
      location.pathname === '/startup_page/payment'
    ) {
      return <Redirect to={signup} />
    }

    return <Redirect to={waiting_list} />;
  }

  if (loading && !data) return <GhostLoader />;

  if (!loading && !error && data) {

    let user = data.userGet || {};
    if (user.email === null && !user.userNeedsToBeFixed) {
      return <Redirect to={pre_profile} />;
    }

    window.Intercom("boot", {
      app_id: window.intercomSettings.app_id,
      name: `${user.given_name} ${user.family_name}`,
      email: user.email,
      userId: user.cognitoIdentityId,
      accountId: user.accountId
    });

    // Once logged in, get the path we attempted to access
    let try_path = localStorage.getItem("access_point");

    if (try_path) {
      window.location = `${window.location.protocol}//${window.location.host}${try_path}`;
      localStorage.removeItem("access_point")
    }


  }


  return (

    <Switch>

      <Route
        path={`${startup_print_page}/:id`}
        component={Printable}
      />

      <Route
        path={startup_page}
        component={WithNavigationWrapper}
      />
    </Switch>


  );
};

export const LoggedInRouter = WrapperComponent;