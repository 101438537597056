import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// API STUFF
import { useLazyQuery, useMutation } from "@apollo/client";
import { userUpdate, appSumoAssignCode } from "private/Apollo/Mutations";
import { userGet } from "private/Apollo/Queries";

// UI COMPONENTS
import { InputForm, NotataButton, GhostLoader } from "Components/NotataComponents";

// STYLES
import "./Profile.scss";

// OTHERS
import { omit } from "lodash";
import { Auth } from "aws-amplify";
import { FormTagSelector } from "../../../../Components/NotataComponents";

// MAIN FUNCTION
export default function Page1({ setPage }) {

  // STATES
  const [cognitoUser, setCognitoUser] = useState();
  const [cognitoEmail, setEmail] = useState();
  const [successMsg, setSuccessMsg] = useState("")

  // Queries
  const [getUserData, userQuery] = useLazyQuery(userGet)
  useEffect(() => {
    getUserData({ variables: { email: cognitoEmail } })
  }, [
    cognitoEmail
  ])

  // Mutations
  const [mutate] = useMutation(userUpdate);


  // Forms
  const { register, handleSubmit, formState, setValue } = useForm();
  const { isSubmitting } = formState;

  // Maps and Reducers
  const user = userQuery.data?.userGet || {};

  // Effects
  useEffect(() => {
    Auth.currentAuthenticatedUser().then(cognitoUser => {
      setCognitoUser(cognitoUser);
      Auth.userAttributes(cognitoUser).then(userAttributes => {
        let ua = {};
        for (let attrib of userAttributes) {
          ua[attrib.Name] = attrib.Value;
        }
        for (let a in ua) {
          setValue(`${a}`, ua[a]);
        }
        setEmail(ua['email'])
      });
    });

    setValue("company", user?.company);
  }, [setValue, userQuery.loading]);

  // FUNCTIONS
  // onsubmit functions
  const onSubmit = async data => {
    setSuccessMsg("")
    let input = {
      family_name: data.family_name,
      given_name: data.given_name,
      email: cognitoEmail || data.email
    };

    if (data.company && data.company.length) {
      input.company = data.company;
    }

    try {
      await Auth.updateUserAttributes(
        cognitoUser,
        omit(input, ["email", "company", "q1_expertise", "q2_whoAreYou"])
      );
    } catch (error) {
      console.log("error", error);
    }
    try {
      await mutate({ variables: { input } });
    } catch (error) {
      console.log("error", error);
    }

    {
      setPage && setPage(2)
    }

    setSuccessMsg("Successfully saved!")


  };

  if (userQuery.loading) {
    return (
      <div style={{ maxWidth: "500px", margin: 'auto', position: "relative" }}>

        <div className="timer_loader">
          <div
            className="timer_header"
          >Hold tight!</div>

          <div>We are rebuilding your user profile.</div>
          <div>This might take a few seconds...</div>
        </div>
        <GhostLoader />
      </div>
    )
  }




  // className="profil_heading"
  return (
    <div className='profile3'>
      <h1> Personal info </h1>
      <p className="profile_subhead">
        We want to know a little bit more about you.
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="profile_form"
        >

        {/* Input Field */}
        <InputForm
          label="Email"
          name="email"
          placeholder="Cannot edit"
          disabled={true}
          reference={register({ required: true })}
        />
        <InputForm
          label="First Name"
          type="text"
          name="given_name"
          placeholder="First Name"
          required
          reference={register({ required: true })}
        />
        <InputForm
          label="Family Name"
          type="text"
          name="family_name"
          placeholder="Family Name"
          required
          reference={register({ required: true })}
        />

        <InputForm
          name="company"
          label="Company"
          type="text"
          placeholder="Company"
          reference={register}
        />


        <div
          className='button_container'
          style={{
            marginTop: "25px",
            position: "relative"
          }}
          >
          <NotataButton
            size="medium"
            bgcolor="primary_color"
            endIcon={
              (!isSubmitting && setPage)
                ? "chevron_right"
                : ""
            }
            text={setPage ? "Next" : "Save"}
            buttonType="submit"
            loading={isSubmitting}
          />
          <p className="success_msg" >
            {successMsg}
          </p>
        </div>

      </form>
    </div>
  );
}