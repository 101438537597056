import React from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { connectionPut, connectionDelete } from "private/Apollo/Mutations";

// UI COMPONENTS
import { Modal } from "Components/NotataComponents";

// CUSTOM COMPONENTS
import DeleteStartup from "../components/DeleteStartup";

// DEFINITIONS
import { archive_page, startup_page } from "definitions";

// MAIN FUNCTION
export default function DeleteModal({ connection, close, history }) {
  
  // MUTATIONS
  const [archiveConnection, archiveConnectionRes] = useMutation(connectionPut);
  const [deleteConnection, deleteConnectionRes] = useMutation(connectionDelete);

  // Functions
  const deleteModal = async () => {
    let variables = {
      id: connection.id,
    };

    try {
      await deleteConnection({ variables });
    } catch (error) {
      return console.log("error", error);
    }

    history.push(startup_page);
  }

  // ArchiveModal
  const ArchiveModal = async () => {
    let variables = {
      id: connection.id,
      input: {
        archived: true,
      },
    };
    try {
      await archiveConnection({ variables });
    } catch (error) {
      return console.log("error", error);
    }

    history.push(archive_page,connection.id);
  }

  return (
    <Modal
      title="Delete startup"
      loading={archiveConnectionRes.loading}
      loadings={deleteConnectionRes.loading }
      close={close}
      submit={(e)=>{
        if(e.key==="Enter"){
          return null
        }else{
          ArchiveModal()
        }
      }}
      intermidate={(e)=>{
        if(e.key==="Enter"){
          return null
        }else{
          deleteModal();
        }
      }}      
      intermidateTxt="Delete"
      submitTxt="Archive"
      closeTxt="CANCEL" 
      intermidateStyle="secondary_color"
      submitButtonStyle="secondary"         
      children={<DeleteStartup />}
    />
  );
}
