import React, { useState, useEffect } from "react";

// Styles
import "./TemplatedForm.scss";

const COMMENT = "COMMENT";
// *****************
// * Main function *
// *****************

export function Comments({ question, answers, section, setAnswers }) {
  const [showInput, setShowInput] = useState(false);
  const [showCommentIcon, setShowCommentIcon] = useState(true);
  const [inputEntered, setInputEntered] = useState(false);

    const answer = answers?.find(
      ({ inputType, questionId: id }) =>
        inputType === COMMENT && id === question.id
    );

    const handleBodyClick = () =>{
      if(!inputEntered){
        setShowInput(false);
        setShowCommentIcon(true)
      }
    }

    useEffect(()=>{
      window.addEventListener("click", handleBodyClick);
      return ()=>{
        window.removeEventListener("click", handleBodyClick);
      }
    }, [])

    function handleChange(event){
      if (!event.target.value) {
        setShowInput(false);
        setInputEntered(false);
        setShowCommentIcon(true);
        return;
      }
      setShowCommentIcon(false);
    }
  function handleSubmit(event) {
    // Skip if answer don't exist, and text field is empty
    // (if it does exist we need to be able to save an empty field)
    if (!answer && !event.target.value) {
      setInputEntered(false);
      return;
    }
    setInputEntered(true);
    // Create answer object
    let answerObject = {
      sectionId: section.id,
      questionId: question.id,
      sectionName: section.name,
      questionName: question.name,
      inputType: COMMENT,
      sid: "",
      val: event.target.value,
    };

    // Remove current answer from list of answers
    let otherAnswers = answers.filter(
      ({ inputType, questionId }) =>
        !(inputType === COMMENT && questionId === question.id)
    );

    // Join the data
    let newAnswers = [...otherAnswers, answerObject];

    // Set data
    setAnswers(newAnswers);
  }


  return (
    <div className="comment_form comments__container">
      <div>
        <div className="add-comment">
          Comment {(showCommentIcon && !answer?.val) &&
            <i className="fa fa-plus-circle comments__icon" onClick={(e)=>{
                e.stopPropagation();
                setShowCommentIcon(false); 
                setShowInput(true)
              }}/>}
          {(showInput || answer?.val) && <div className="textbox">
            <textarea
              name={COMMENT}
              defaultValue={answer && answer.val}
              onKeyUp={handleSubmit}
              onBlur={handleChange}
              onClick={(e)=>e.stopPropagation()}
              placeholder="Say something..."
            />
          </div>}
        </div>
      </div>
    </div>
  );
}
