import gql from "graphql-tag";
import creativeFragments from "../Fragments/creativeFragments";
import evaluationTemplateFragments from "../Fragments/evaluationTemplateFragments";
import evaluationTemplateSectionFragments from "../Fragments/evaluationTemplateSectionFragments";
import evaluationTemplateQuestionFragments from "../Fragments/evaluationTemplateQuestionFragments";

export default gql`
  query publicGroupGet($groupId: ID!, $email: String, $pin: Float) {
    publicGroupGet(groupId: $groupId, email: $email, pin: $pin) {
        id
        name
        description
        tags
        publicSettings {
          pinRequired
          invitedOnly
          withSubjectiveScores
          withEvaluationTemplate            
        }
        
        administrators{
          family_name
          given_name
          email
          company
        }
        startups{
            ...creativeFields,
            newAnswers{
              sectionName
              questions{
                questionName
                comment
                answers
              }
            }
        }
        evaluationTemplate{
          ...evaluationTemplateFields
          sections {
            ...evaluationTemplateSectionFields
            questions {
              ...evaluationTemplateQuestionFields
            }
          }
        }
    }
  }
  ${creativeFragments}
  ${evaluationTemplateFragments}
  ${evaluationTemplateSectionFragments}
  ${evaluationTemplateQuestionFragments}
`;