import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { connectionCreate } from "private/Apollo/Mutations";

// UI COMPONENTS
import { Modal } from "Components/NotataComponents/Modal/Modal";

// STYLES
import "./SyncModal.scss";

// MAIN FUNCTION
export default function SyncModal({ startups, refetch, groupId, close }) {

  // STATES
  const [loading, setLoading] = useState(false);
  const [isMutating, setIsMutating] = useState({});

  // MUTATIONS
  const [mutate] = useMutation(connectionCreate);

  // SAVE FUNCTION
  async function save() {
    setLoading(true);
    let promises = startups.map(async ({ creative }) => {
      setIsMutating({
        ...isMutating,
        [creative.id]: true,
      });
      let variables = { creativeId: creative.id };
      try {
        await mutate({ variables });
      } catch (error) {
        console.log("error", error);
      }
      setIsMutating({
        ...isMutating,
        [creative.id]: false,
      });
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.log(error);
    }

    try {
      let variables = {
        id: groupId,
      };
      await refetch({ variables });

    } catch (error) {
      console.log("error", error);
    }

    setLoading(false);

    close();
  }

  return (
    <Modal
      title="Add all to dealflow"
      submit={save}
      loading={loading}
      close={() => {
        if (loading) {
          return;
        }
        close();
      }}
      submitTxt="Add"
      closeTxt="Cancel"
      children={
        <div className="sync-modal">
          <div className="sync-modal__title">
            These startups are not in your dealflow yet. By clicking "sync" we
            will add them for you. (It might take a minute).
          </div>

          {startups.map(({ creative }) => {
            return (
              <div key={creative?.id} className="sync-modal__record">
                <span>{creative?.name}</span>
              </div>
            );
          })}

          {
            loading && (
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "20px",
                  fontSize: "13px",
                  color: "#53cab2"
                }}
              >
                Stay put! This might take a minute.
              </div>
            )
          }

        </div>
      }
    />
  );
}