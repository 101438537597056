import React, { useEffect, useState } from "react";

//  CUSTOM COMPONENTS
import StartupCardFullView from "./StartupCardFullView";

// OTHERS
import { cloneDeep } from 'lodash';
import "./StartupCard.scss";
import sortStartups from "./sortStartups";
import SortHeader from "./SortHeader";
import StartupListView from "./StartupListView";
import StartupGridView from "./StartupGridView";

//CONSTANTS
import { VIEWS } from "../../../../constants";

// UI COMPONENTS
import { Modal } from "Components/NotataComponents";
import { CommentSection } from "./Components/StartupComments";

// MAIN FUNCTION
export default function StartupList({ group, adminView, history, refetch, view }) {

  const [ showComment, setShowComment] = useState(false);
  const [ selectedStartup, setSelectedStartup ] = useState(null);
  const [ startups, setStartups ] = useState([])
  const [ searchValue, setSearchValue ] = useState(undefined)
  const [ sortView, setSortView ] = useState("One Evaluation");
  const [ sortField, setSortField ] = useState(undefined)
  const [ sortByCategory, setSortByCategory ] = useState(undefined)

  // Populate startups array from server
  useEffect(() => {
    if (group.startups) {
      setStartups(cloneDeep(group.startups))
    }
  }, [group])


  useEffect(() => {

    if (!group && sortByCategory) {
      return;
    }

    let settings = group?.settings || {};
    let publicSettings = group?.publicSettings || {};

    let publicDefault = publicSettings?.withSubjectiveScores || publicSettings?.withEvaluationTemplate
    let privateDefault = settings?.addEvaluation;

    let defaultSortByCategory =
      (publicDefault && !privateDefault) ? "public" : "private"

    setSortByCategory(defaultSortByCategory)

  }, [group])

  useEffect(() => {
    let _sortByCategory = localStorage.getItem(`groupSortByCategory:${group?.id}`)
    let _groupSorting = localStorage.getItem(`groupSorting:${group?.id}`)
    setSortField(_groupSorting)
    setSortByCategory(_sortByCategory)

    sortStartups({
      sortField: _groupSorting,
      sortByCategory: _sortByCategory,
      group,
      adminView,
      startups
    })

  }, [localStorage, group])


  function getDefaultSortByCategory() {
    let settings = group?.settings || {};

    let hideSummaries = !settings?.showSummaries && !adminView;
    if (hideSummaries) {
      return 'me';
    }

    let publicSettings = group?.publicSettings || {};
    let publicDefault = publicSettings?.withSubjectiveScores || publicSettings?.withEvaluationTemplate
    let privateDefault = settings?.addEvaluation;
    let defaultSortByCategory = (publicDefault && !privateDefault) ? "public" : "private"
    return defaultSortByCategory
  }



  sortStartups({
    sortField,
    sortByCategory,
    group,
    adminView,
    startups
  })

  // useEffect(() => {
  //   console.log('sortField', sortField)
  //   console.log('sortByCategory', sortByCategory)
  // }, [sortField, sortByCategory])



  let filteredStartups = startups
    .filter(({creative}) => {
      if (!creative) { return false }
      if (!searchValue || searchValue === "") { return true }
      return creative?.name?.toLowerCase().includes(searchValue.toLowerCase())
    })

  return (
    <>
      <SortHeader
        adminView={adminView}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        sortField={sortField}
        view={sortView}
        setView={setSortView}
        setSortField={sortField => {

          if (sortField === 'alpha') {
            setSortByCategory(getDefaultSortByCategory())
            localStorage.removeItem(
              `groupSortByCategory:${group.id}`
            );
          }

          if (sortField === 'subjectiveScore' && !sortByCategory) {

            setSortByCategory(getDefaultSortByCategory())

            localStorage.setItem(
              `groupSortByCategory:${group.id}`, getDefaultSortByCategory()
            );
          }

          if (sortField === 'evaluation') {
            if (
              sortByCategory !== 'private' &&
              sortByCategory !== 'public'
            ) {
              setSortByCategory(getDefaultSortByCategory())
              localStorage.setItem(
                `groupSortByCategory:${group.id}`, getDefaultSortByCategory()
              );
            }
          }

          localStorage.setItem(
            `groupSorting:${group.id}`, sortField
          );
          setSortField(sortField)
        }}
        sortByCategory={sortByCategory}
        setSortByCategory={sbc => {
          localStorage.setItem(
            `groupSortByCategory:${group.id}`, sbc
          );
          setSortByCategory(sbc)
        }}
        group={group}
        startups={startups}
      />
      {view === VIEWS.LIST &&
        <StartupListView
          group={group}
          startups={filteredStartups}
          history={history}
          adminView={adminView}
          refetch={refetch}
          setSelectedStartup={setSelectedStartup}
          setShowComment={setShowComment}
          sortByCategory={sortByCategory}
        />
      }
      {view === VIEWS.GRID &&
        <StartupGridView
          startups={filteredStartups}
          setSelectedStartup={setSelectedStartup}
          setShowComment={setShowComment}
          group={group}
          history={history}
          adminView={adminView}
          refetch={refetch}
          sortByCategory={sortByCategory}
        />
      }
      { view === VIEWS.FULL &&
        filteredStartups.map((startup, i) => {
          return (
            <StartupCardFullView
              key={`startup-${i}`}
              group={group}
              startup={startup}
              history={history}
              adminView={adminView}
              refetch={refetch}
              dataTut={i===0? 'startup-0':''}
            />
          )
        })
      }
      {showComment && (
        <Modal
          title={`Comments for ${selectedStartup.creative?.name}`}
          innerClassName=""
          close={() => {
            setShowComment(false);
          }}
          children={
            <CommentSection
              startup={selectedStartup}
              group={group}
            />
          }
        />
      )}
    </>
  );
}
