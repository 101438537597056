import React from 'react';
import { NotataButton, CodeSnippet } from "Components/NotataComponents";
import './StartupInvited.scss';

// CUSTOM COMPONENTS
import CardMessageContainer from "../../../../Group/Components/CardMessageContainer";
import { Card } from "Components/NotataComponents";

// API STUFF
import { creativeUpdate } from "private/Apollo/Mutations";
import { useMutation } from "@apollo/client";

function getPublicShareUrl(creative) {
    let url =
      `${window.location.protocol}//` +
      `${window.location.host}/` +
      `public/creative_new/` +
      `${creative?.accountId}/` +
      `${creative?.id}` +
      `&email=${creative?.sharedWithEmail}`;
    return url;
  }

export default function StartupInvited({creative}){
    // let url =
    // `${window.location.protocol}//` +
    // `${window.location.host}/` +
    // `public/creative_new/` +
    // `${creative?.accountId}/` +
    // `${creative?.id}` +
    // `&email=${creative?.sharedWithEmail}`;

      // Mutations
    const [mutateCreativeUpdate, { loading }] = useMutation(creativeUpdate);
      // Submit function with mutations
    const revoke = async () => {
        let variables = {
            id: creative?.id,
            input: {
                sharedWithEmail: creative?.sharedWithEmail,
                removeSharing: true,
            },
        };

        await mutateCreativeUpdate({
            variables,
        });
    };

    return(
        <Card containerClassName="card startup-invitation">
            <div>
                <CardMessageContainer
                    title="Heads up!"
                    notice
                    message={`You have shared access to this information with ${creative?.sharedWithEmail}. No email has been send from notata, but you can copy the link below and send it by email.`}
                    children={
                        <div>
                            <CodeSnippet 
                                content={getPublicShareUrl(creative)}
                                contentType="Link"
                                contentBackground="white"
                            />
                        
                            {creative?.canEdit &&
                                <div className="startup-invitation__revoke">
                                    <NotataButton
                                        size="big"
                                        bgcolor="primary_color"
                                        text="Revoke access"
                                        loading={loading}
                                        onClick={revoke}
                                    />
                                </div>
                            }
                        </div>
                    }
                />
            </div>
        </Card>
    )
}