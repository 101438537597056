import React from "react";

export const StartupPreviewPopup = ({
     no,
     companyName,
     oneLiner,
     problem,
     styles,
     clientX,
     clientY
   }) => (
  <div className={styles.startupPreview}
       style={clientX && clientY ? {
         top: clientY,
         left: clientX
       }: {}}>
    <h1>{companyName}</h1>
    {oneLiner && (
      <>
        <h3>{oneLiner.questionName}</h3>
        <p>{oneLiner.val}</p>
      </>
    )}
    {problem && (
      <>
        <h3>{problem.questionName}</h3>
        <p>{problem.val}</p>
      </>
    )}
  </div>
);