import React from "react";
import { Switch, withRouter } from "react-router-dom";
import { Route } from "react-router";

// ROUTES
import {
  public_creative_new,
  reciever_sharing,
  public_group_share,
  group_plugin,
  help_material,
  terms
} from "../definitions";

import { PublicCreativeNew } from "./pages/ExternalFormNew/PublicCreativeNew/PublicCreativeNew";
import GroupSharing from "./pages/GroupSharing/GroupSharing";
import Startup from "./pages/GroupSharing/Startup";
import PinAuthenticator from "./pages/GroupSharing/PinAuthenticator";
import Thankyou from "./pages/GroupSharing/ThankYou";
import MemberDetails from "./pages/GroupSharing/MemberDetails";

import HelpMaterial from "./pages/HelpMaterial/HelpMaterial"

// SHARING A STARTUP
import StartupSharing from "./pages/StartupSharing/StartupSharing";
import PublicGroupTerms from "./pages/Terms/PublicGroupTerms";
import GroupPlugin from "./pages/GroupPlugin/GroupPlugin";

export const RouterComponent = ({ history }) => {
  return (
    <Switch>




      <Route
        exact
        path={`${public_creative_new}/:accountId/:id}`}
        component={PublicCreativeNew}
      />
      <Route
        exact
        path={[
          `${public_creative_new}/:accountId/:id`,
          `${public_creative_new}/:accountId/form.html`,
          `/public/:accountId/form.html`,
        ]}
        component={PublicCreativeNew}
      />

      <Route exact path={reciever_sharing} component={StartupSharing}  />
      <Route exact path={`${public_group_share}/:id`} component={GroupSharing}  />
      <Route exact path={`${public_group_share}/startup/:groupId/:creativeId`} component={Startup} />
      <Route exact path={`${public_group_share}/pin-authenticator`} component={PinAuthenticator} />
      <Route exact path={`${public_group_share}/thankyou`} component={Thankyou} />
      <Route exact path={`${public_group_share}/member-details`} component={MemberDetails} />
      <Route exact path={`${group_plugin}/:id`} component={GroupPlugin} />
      <Route exact path={`${terms}`} component={PublicGroupTerms} />
      <Route exact path={`${terms}/public-group`} component={PublicGroupTerms} />

      <Route exact path={help_material} component={HelpMaterial} />

      <Route render={() => <div>404</div>} />
    </Switch>
  );
};

const WrapperComponent = ({ ...props }) => {

  if (window.Intercom) {
    window.Intercom('update', {
      "hide_default_launcher": true
    });
  }


  return (
    <div className="public_shared_page_content">
      <RouterComponent {...props} />
    </div>
  );
};

export const PublicRouter = withRouter(WrapperComponent);
