import gql from "graphql-tag";

export default gql`
    fragment EvaluationSummaryFields on EvaluationSummaries {
        templateId
        templateName
        averagePercentageScore
        highestScore
        lowestScore
        submissions
    }
`




