export const getStoredDetails = (groupId) => {
  return JSON.parse(localStorage.getItem(`publicGroup:${groupId}`));
}
export const putItemToDB = (groupId, item) => {
  localStorage.setItem(`publicGroup:${groupId}`, JSON.stringify(item));
}
export const getScore = (connection, groupId) => {
  const publicGroup = getStoredDetails(groupId);
  if (getStoredDetails(groupId)) {
    let creative = publicGroup?.subjectiveScores?.find(score => score.creativeId === connection?.id);
    return creative?.score
  }
}

export const getEvaluated = (connection, groupId) => {
  const publicGroup = getStoredDetails(groupId);
  if (publicGroup) {
    let creative = publicGroup?.evaluations?.find(e => e.creativeId === connection?.id);
    return creative?.answers ? true : false;
  }
  return false;
}

export const saveScoreToLocalDB = (GROUP_ID, score, connectionId) => {
  const publicGroup = getStoredDetails(GROUP_ID);
  if (publicGroup) {
    let subjectiveScores = publicGroup?.subjectiveScores?.filter(score => score.creativeId !== connectionId) || [];
    const item = {
      ...publicGroup,
      subjectiveScores: [
        ...subjectiveScores,
        {
          creativeId: connectionId,
          score
        }
      ]
    }
    localStorage.setItem(`publicGroup:${GROUP_ID}`, JSON.stringify(item));
  }
}

export const saveEvaluationToLocalDB = (GROUP_ID, answers, connectionId) => {
  const publicGroup = getStoredDetails(GROUP_ID);
  if (publicGroup) {
    let evaluations = publicGroup?.evaluations?.filter(evaluation => evaluation.creativeId !== connectionId) || [];
    const item = {
      ...publicGroup,
      evaluations: [
        ...evaluations,
        {
          creativeId: connectionId,
          answers
        }
      ]
    }
    localStorage.setItem(`publicGroup:${GROUP_ID}`, JSON.stringify(item));
  }
}