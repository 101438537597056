import gql from "graphql-tag";

export default gql`
    query userExists($email: String!) {
        userExists(email: $email) {
            email
            given_name
            family_name
            userExists
        }
    }
`;
