
import React, { useState, useEffect } from "react";

// Custom Components
import TagsInput from "./TagsInput"
import {Tag} from "./Tag"

// Main Function
export const Tags = ({
    items = [],
    size,
    tagSize,
    tagName,
    closeIcon,
    ulSize,
    optionalTxt,
    suggested,
    heading,
    getSelectedTag,
    setTags,
    onchange,
    placeholder,
    addNewTagModal,
    label,
    showData,
    showAddNewBtn
  }) => {



    // States
    const [selectedTags, setSelectedTags] = useState([]);
    
    // Effects
    useEffect(() => {
      if (setTags) {
        let tags = [];
        setTags.forEach(el => {
          tags.push({
            name: el,
            id: Math.floor(Math.random() * 100000).toString(),
          });
        });
        setSelectedTags(tags);
      }
    }, [setTags]);


    // Select tags
    function selectTags(tags) {
      setSelectedTags(tags);
      if (getSelectedTag) {
        getSelectedTag(tags);
      }
    }
  
    return (
      <div className="tag_wrapper">
        {heading && <div className="tag_title">TAGS</div>}

        <div className='tag_sub_title2'>
          {optionalTxt ? optionalTxt : ""}
        </div>

        <div>
          <TagsInput
            selectTags={selectTags}
            selectedTags={selectedTags}
            size={size}
            tagSize={tagSize}
            tagName={tagName}
            closeIcon={closeIcon}
            ulSize={ulSize}
            handleSearch={onchange}
            placeholder={placeholder}
            addNewTagModal={addNewTagModal}
            showAddNewBtn={showAddNewBtn}
            label={label}
            showData={showData}
          />
        </div>
        {suggested && !!items.length && (
          <div className="tag_sub_title3">Suggested tags</div>
        )}
  
        <div>
          {items.map(item => (
            <Tag
              color="primary_tag"
              size="md_tag"
              label={`${item.name}`}
              key={item.id}
              customtagstyle="tag_margin"
              onClick={() => {
                selectTags([...selectedTags, item]);
              }}
            />
          ))}
        </div>
      </div>
    );
  };