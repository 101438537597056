import React, { useEffect, useState, useRef } from "react";
import "./HelpMaterial.scss";
import help_content from "./help_content";
import TableOfContent from "./TableOfContent";
import { useHistory, useLocation } from "react-router-dom";


function RenderContent({content, isSmallScreen}) {

  function getIframeProperties(c) {
    if (!isSmallScreen) {
      return c.properties;
    }
    if (isSmallScreen) {
      return {
        ...c.properties,
        width: 400,
        height: 260,
      }
    }
  }

  function getImageProperties(c) {
    if (!isSmallScreen) {
      return c.properties
    }
    if (isSmallScreen) {
      return {
        ...c.properties,
        style: {
          width: "auto",
          height: "auto",
          maxWidth: "100%",
          maxHeight: "600px"
        }
      }
    }
  }


  return (
    <div className="help-container__chapter__section__content">
      {content?.map((c, i) => {

        return (
          <span key={i}>

            {c.type === "p" && (
              <p {...c.properties}>{c.content}</p>
            )}

            {c.type === "ul" && (
              <ul {...c.properties}>
                {
                  c.content?.map((it, i) => (
                    <li key={i}>{it}</li>
                  ))
                }
              </ul>
            )}

            {c.type === "a" && (
              <a {...c.properties}>{c.content}</a>
            )}

            {
              c.type === "img" && (
                <img {...getImageProperties(c)} />
            )}

            {c.type === "iframe" && (
              <div>
                <iframe {...getIframeProperties(c)} />
              </div>
            )}

            {
              c.caption && (
                <div className="help-container__caption">{c.caption}</div>
              )
            }

          </span>
        )
      })}
    </div>
  )
}


function RenderSections({sections, i_arr, help_content, refs, isSmallScreen}) {

  return (
    <>
      {
        sections?.map((section, ii) => {

          let parents = i_arr.map((i, index) => {
            if (index === 0) {
              return `${i} ${help_content[i-1]?.title}`
            }
            if (index === 1) {
              return `${i_arr[index-1]}.${i} ${help_content[i_arr[index-1]-1]?.sections[i-1]?.title}`
            }
          })

          let id = `${i_arr?.join('-')}-${ii+1}`

          return (
            <div
              key={id}
              className={"help-container__chapter__section"}
              >

              {
                parents.length !== 0 && (
                  <div className="help-container__parents">
                    {parents.join(" > ")}
                  </div>
                )
              }

              <h3
                id={`${id}`}
                className={i_arr.length >= 2 && "sub-sub"}
                ref={el => refs.current.push({id: id, el})}
              >
                {i_arr?.map((i, o) => <span key={o}>{i}.</span>)}{ii+1} {section.title}
              </h3>

              <RenderContent
                content={section.content}
                isSmallScreen={isSmallScreen}
              />

              <RenderSections
                sections={section.sections}
                i_arr={[...(i_arr || []), ii+1]}
                help_content={help_content}
                refs={refs}
                isSmallScreen={isSmallScreen}
              />

            </div>
          )
        })
      }
    </>
  )
}


function HelpContent({help_content, refs, isSmallScreen}) {

  return (
    <>
      {
        help_content?.map((chapter, i) => {

          return (
            <div
              className="help-container__chapter"
              key={`chapter-${i+1}`}
            >
              <h2
                id={`${i}`}
                ref={el => refs.current.push({id: `${i}`, el})}
                // onClick={() => setIsOpen({...isOpen, [i+1]: !isOpen[i+1]})}
                >
                {i+1} {chapter.title}
              </h2>

              <RenderContent
                help_content={help_content}
                content={chapter.content}
                isSmallScreen={isSmallScreen}
              />
              <RenderSections
                help_content={help_content}
                sections={chapter.sections}
                i_arr={[i+1]}
                refs={refs}
                isSmallScreen={isSmallScreen}
              />
            </div>
          )
        })
      }
    </>
  )

}


export default function HelpMaterial() {

  const history = useHistory()

  const [ isSmallScreen, setIsSmallScreen ] = useState(false);
  const [ tops, setTops ] = useState({})
  const [ topVals, setTopVals ] = useState([])
  const [ prevScrollPos, setPrevScrollPos ] = useState(0);
  const [ scrollDirection, setScrollDirection ] = useState("down")
  const [ currentInView, setCurrentInView ] = useState(undefined)

  const [ isScrolling, setIsScrolling ] = useState(false)

  useEffect(() => {

    if (isScrolling) {
      setTimeout(() => {
        setIsScrolling(false)
      }, 200)
    }

  }, [isScrolling])



  const refs = useRef([])
  let { hash } = useLocation()

  useEffect(() => {
    let [,id] = hash.split('pos-');
    let ref = refs.current.find(c => c.id === id);
    if (ref && !isScrolling) {
      console.log('pos from href', ref.el.id)
      setTimeout(() => {
        ref.el.scrollIntoView({ block: "start"})
      }, 10)
    }
  }, [hash])

  useEffect(() => {

    setTimeout(() => {
      let tmpTops ={}
      for (let {el} of refs.current) {
        if (el) {
          let top = Math.ceil(el.getBoundingClientRect().top);
          let topVal = top + window.scrollY + 40;
          tmpTops[topVal] = el
        }
      }
      setTops(tmpTops)
      setTopVals(Object.keys(tmpTops).map(n => parseInt(n)))
    }, 50)

  }, [refs])


  // Scroll
  useEffect(() => {

    const onScroll = () => {

      setIsScrolling(true)

      let scrollPos = window.pageYOffset;
      setPrevScrollPos(scrollPos);

      let newScrollDirection = scrollPos > prevScrollPos ? "down" : "up";
      if (newScrollDirection !== scrollDirection) {
        setScrollDirection(newScrollDirection)
      }

      if (topVals && topVals.length) {
        let closest = topVals.reduce((a, b) => {
          return Math.abs(b - scrollPos) < Math.abs(a - scrollPos) ? b : a;
        });

        let closestElement = tops[closest];
        if (currentInView !== closestElement) {
          setCurrentInView(closestElement)
          history.push(`#pos-${closestElement.id}`)
        }
      }
    };

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [prevScrollPos]);



  // Init widow size
  let smallScreen = window.innerWidth < 860;
  if (smallScreen !== isSmallScreen) {
    setIsSmallScreen(smallScreen)
  }

  // Resizing
  useEffect(() => {
    const onResize = () => {
      let smallScreen = window.innerWidth < 860;
      if (smallScreen !== isSmallScreen) {
        setIsSmallScreen(smallScreen)
      }
    }
    window.removeEventListener('resize', onResize);
    window.addEventListener('resize', onResize, { passive: true });
    return () => window.removeEventListener('resize', onResize);

  }, [])




  return (

    <>
      <TableOfContent help_content={help_content} />

      <div className="help-container">

        {/*<h1>Help content</h1>*/}

        <HelpContent
          help_content={help_content}
          refs={refs}
          isSmallScreen={isSmallScreen}
        />

      </div>

    </>
  )
}