import React , {useState, useEffect} from 'react';

// API STUFF
import {  publicEvaluationCreate  } from "public/Apollo/Mutations";
import {  publicSharingGet  } from "public/Apollo/Queries";
import { useLazyQuery, useMutation } from "@apollo/client";

// COMPONENTS
import PreviewStartup from '../../../Components/Common/PreviewStartup/PreviewStartup';
import { GhostLoader, Modal, NotataButton } from "Components/NotataComponents";
import EvaluatePage from "private/Pages/EvaluatePage/Evaluate/Evaluate";

// OTHER
import './StartupSharing.scss';
import { omit } from "lodash";


export default function StartupSharing({history, match}){

  // STATES: modals
  const [thankYouModal, setThankYouModal] = useState(false);
  const [evaluateModal, setEvaluateModal] = useState(false);

  // STATES: other
  const [requestedUser, setRequestedUser] = useState("");
  const [sharingObject, setSharingObject] = useState(undefined);

  // API CONTEXT
  const queryOptions = { context: { clientName: "public" } };

  // QUERIES
  let [getSharing, sharingRes] = useLazyQuery(publicSharingGet, queryOptions);

  // MUTATIONS
  const [mutatePublicEvaluationCreate, evaluationRes] = useMutation(publicEvaluationCreate, queryOptions);

  // EFFECTS
  useEffect(()=>{
    if( match?.params?.id && history?.location?.search){
      const email = new URLSearchParams(history.location.search).get('sharing');
      let variables = { id: match.params.id, email }
      getSharing({variables})
    }
  }, [match?.params, history])

  useEffect(()=>{
    if (sharingRes.data){
      setSharingObject(sharingRes.data?.publicSharingGet)
    }
  }, [sharingRes])



  // FUNCTIONS
  const saveEvaluation = async (answers) => {
    let omitForAnswer = ["__typename", "id"];
    let updatedAns = answers.map(ans=>omit(ans, omitForAnswer))
    try{
      let variables = {
        publicSharingId: sharingObject?.id,
        input:{
          name:"dummy name",
          description:"dummy description",
          answers:updatedAns,
        }
      };
      await mutatePublicEvaluationCreate({ variables });
      setEvaluateModal(false);
      setThankYouModal(true);
    } catch (error) {
      console.log("error", error);
      setEvaluateModal(false);
    }
  };


  if (!sharingObject) {
    return <GhostLoader />
  }

  // EVALUATION PAGE
  if (evaluateModal) {
    return (
      <EvaluatePage
        templId={sharingObject?.evaluationTemplate?.id}
        saveEvaluation={saveEvaluation}
        sharedId={sharingObject?.id}
        publicLoading={evaluationRes.loading}
        evaluateModel ={()=>setEvaluateModal(false)}
        location={history.location}
        history={history}
        match={match}
      />
    )
  }


  return(
    <div
      className="reciever-sharing-container"
    >
      <PreviewStartup
        sharingObject={sharingObject}
        onEvaluate={(sharingDetails) => {
          setEvaluateModal(true);
          setSharingObject(sharingDetails);
          const sharedByUser = sharingDetails?.sharedByUser;
          const sharedByName = `${sharedByUser?.given_name} ${sharedByUser?.family_name}`
          setRequestedUser(sharedByName);
        }}
        match={match}
        history={history}
        evaluationCreated={evaluationRes.data}
      />

      <div style={{
        maxWidth: "1200px",
        margin: "auto",
        textAlign: "center",
        lineHeight: 2
      }}>
        <div style={{marginLeft:16}}>
          <p className="reciever-sharing-container__separator-text">saparator</p>

          <div className="public-group-footer">
            This is a public sharing powered by notata. Notata is a tool for investors, by investors.<br/>Like what you see? Sign up to a <a href="https://notata.io/signup">free account here</a>.
          </div>
        </div>
      </div>

      {thankYouModal &&
        <Modal
          title="Thank you"
          close={() => {
            setThankYouModal(false);
            setRequestedUser("");
          }}
          closeTxt="OK"
          children={
            <div className="thank-you-text">
              Thank you for submitting your evaluation.
              <br/>
              {requestedUser && `${requestedUser} has been notified.`}
            </div>
          }
        />
      }
    </div>

  )

}