import React, { useState } from "react";
import moment from "moment";

// Library Components
import { TextField } from "@material-ui/core";
import DatePicker from "react-datepicker";

// Styles
import "./DatePickers.scss";
import "react-datepicker/dist/react-datepicker.css";

// Main Function
export const DatePickers = ({ id, label, selectedDate, handleDateChange }) => 
    <TextField
      id={id}
      label={label}
      type="date"
      onChange={handleDateChange}
      defaultValue={selectedDate}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        disableUnderline: true,
        className: "datePicker",
      }}
    />

export const NotataDatepicker = ({
  isCalendarVisible,
  dateRange,
  setCalendarVisible,
  selected,
  onChange,
  startDate,
  endDate,
  formatWeekDay,
}) => {
  const [, /* daterange */ setDaterange] = useState([null, null]);

  const setRange = range => {
    if (range[1]) {
      range[1] = moment(range[1]).endOf("day").toDate();
    }
    setDaterange(range);
    dateRange("dateRange", range);
  };

  // date picker range for date wise 
  const dateRanger = (dateValue) => {
    return new Date(Date.now() - dateValue * 24 * 60 * 60 * 1000)
  }

  const presetDateValues = () => {
    const toDate = moment().endOf("day").toDate();
    const lastYear = new Date();
    var pastYear = lastYear.getFullYear() - 1;
    lastYear.setFullYear(pastYear);

    const selectDateRange = [
      {
        label: "Last 7 Days",
        range: [dateRanger(7), toDate],
      },
      {
        label: "Last 14 Days",
        range: [dateRanger(14), toDate],
      },
      {
        label: "Last 30 Days",
        range: [dateRanger(30), toDate],
      },
      {
        label: "Last 90 Days",
        range: [dateRanger(90), toDate],
      },
      {
        label: "Last Year",
        range: [lastYear, toDate],
      },
    ];
    return selectDateRange.map((value, index) => {
      return (
        <div
          key={index}
          onClick={() => setRange(value.range)}
          className="datepicker-container__last-day-container__last-day"
        >
          {value.label}
        </div>
      );
    });
  };

  return (
    <>
      <div className="datepicker-container ">
        <div className="datepicker-container__date-time">
          <div className="datepicker-container__from-to">
            <div className="datepicker-container__from-to__header">From</div>
            <div
              className="datepicker-container__from-to__datePicker"
              onClick={() => setCalendarVisible(true)}
            >
              <div>
                {startDate
                  ? startDate.toLocaleDateString("en-US")
                  : "mm/dd/yyyy"}
                <i className="fa fa-calendar" aria-hidden="true" />
              </div>
            </div>
          </div>
          <div className="datepicker-container__from-to">
            <div className="datepicker-container__from-to__header">To</div>
            <div
              className="datepicker-container__from-to__datePicker"
              onClick={() => setCalendarVisible(true)}
            >
              <div>
                {endDate ? endDate.toLocaleDateString("en-US") : "mm/dd/yyyy"}
                <i className="fa fa-calendar" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
        <div className="datepicker-container__last-day-container">
          {presetDateValues()}
        </div>
        {isCalendarVisible && (
          <div className="date-container" >
            <DatePicker
              selected={selected}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              formatWeekDay={formatWeekDay}
              selectsRange
              inline
            />
          </div>
        )}
      </div>
    </>
  );
};
