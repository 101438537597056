import React, { useState } from "react";
import { Card } from "Components/NotataComponents";
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";
import sortAlphabeticallyByField from "../../../utils/sortAlphabeticallyByField";
import "./GridView.scss"
import { Tag } from "../../../Components/NotataComponents";


export default function GridView({
  group,
  setViewStartup,
  user
 }) {

  // States
  const [ hearted, setHearted ] = useState({})

  let startups = sortAlphabeticallyByField({
    array: group?.startups,
    field: "name",
    clone: true
  })

  return (
    <div
      className="grid-view"
    >
      {startups?.map(startup => {
        return (
          <div
            key={startup?.id}
            className="grid-view__startup"
          >
            <Card size="small">

              <div
                className="grid-view__startup__header"
                onClick={() => setViewStartup(startup.id)}
              >
                <StartupLogo
                  size="mini"
                  startupName={startup?.name}
                  url={startup?.logo}
                />
                {startup?.name}
              </div>

              <div className="grid-view__startup__tags">
                {
                  startup?.tags?.map(tag => (
                    <Tag
                      key={tag.id}
                      size="md_tag"
                      color="color_oragne"
                      label={tag.name}
                    />
                  ))
                }
              </div>

              <div className="grid-view__startup__description">
                <div className="grid-view__startup__description__clipper">
                  {startup?.description}
                </div>
              </div>

              <div className="grid-view__startup__more-info">
                <div
                  onClick={() => {
                    setViewStartup(startup.id)
                  }}
                >More info</div>
              </div>

              <div className="grid-view__startup__button-container">
                <span/>
                {
                  hearted[startup?.id] && (
                    <div
                      className="grid-view__startup__button-container__selected-heart"
                      onClick={() => {
                        setHearted({...hearted, [startup?.id]: false})
                      }}
                    >
                      <i className="fas fa-heart"/>
                    </div>
                  )
                }
                {
                  !hearted[startup?.id] && (
                    <div
                      onClick={() => {
                        setHearted({...hearted, [startup?.id]: true})
                      }}
                      >
                      <i className="fal fa-heart"/>
                    </div>
                  )
                }
              </div>
            </Card>
          </div>
        )}
      )}

    </div>
  )
}