import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import {
  groupsOpenGet,
  groupInvitationsGet,
  notificationsGet,
  userGet,
} from "private/Apollo/Queries";
import {
  groupUserInvitationResponse,
  groupOpenJoin,
} from "private/Apollo/Mutations";
import notificationMarkAsResolved from "private/Apollo/Mutations/notificationMarkAsResolved";

// CUSTOM COMPONENTS
import {
  NotataButton,
  Loader,
  CardComponent,
} from "Components/NotataComponents";
import CreateGroup from "Components/Common/Modals/CreateGroup/CreateGroup";

//DEFINITIONS
import { group, group_dashboard } from "definitions";

// OTHERS
import { getVal } from "private/Pages/commonFunctions";
import { GROUP_INVITATION } from "private/Pages/constants";


export function CardContent() {

  const history = useHistory()

  // States
  const [loadingStateForGroup, setLoadingStateForGroup] = useState(undefined);

  // Queries
  const openGroupsQuery = useQuery(groupsOpenGet);
  const groupInvitationsQuery = useQuery(groupInvitationsGet);
  const userQuery = useQuery(userGet);
  const notificationQuery = useQuery(notificationsGet);

  const [respond] = useMutation(groupUserInvitationResponse, {
    refetchQueries: [{ query: groupInvitationsGet }],
  });

  const [markNotification] = useMutation(notificationMarkAsResolved);
  const [joinOpenGroup] = useMutation(groupOpenJoin);

  // Maps
  let groupInvitations = groupInvitationsQuery?.data?.groupInvitationsGet || [];
  let user = userQuery?.data?.userGet || {};
  let openGroups = openGroupsQuery?.data?.groupsOpenGet || [];
  let notifications = notificationQuery?.data?.notificationsGet || [];
  let hasAllData = openGroupsQuery?.data && groupInvitationsQuery?.data && userQuery?.data;

  //Loader
  let isLoading =
    openGroupsQuery?.loading ||
    groupInvitationsQuery?.loading ||
    userQuery?.loading;

  let showLoader = !hasAllData && isLoading;

  //Get notifications functions
  function getNotification({ groupId }) {
    let notification = notifications?.find(not => {
      if (
        not.notificationType === GROUP_INVITATION &&
        getVal(not, "groupId") === groupId
      ) {
        return not;
      }
      return false;
    });
    return notification;
  }
  let openGroupsThatImNotMemberOf = openGroups.filter(
    ({ iAmMember }) => !iAmMember
  );

  return (
    <>
        {showLoader && <Loader />}
        {
          (
            openGroupsThatImNotMemberOf.length === 0 &&
            groupInvitations.length === 0 &&
            !showLoader
          ) && (
          <div>
            <div className="demo-msg">You have no invitations</div>
          </div>
        )}

        {/* INVITATIONS */}
        {groupInvitations?.map(group => (
          <div
            className="dashboard-container__invited-to-groups-card__invite-container"
            key={group?.id}
          >
            <div className="dashboard-container__invited-to-groups-card__invite-container__invite">
              <div className="invite-member">
                    <span className="invitation-text">
                      You have been invited to join a group
                    </span>
              </div>
              <div className="dashboard-container__invited-to-groups-card__data-container__data-entry">
                <div className="group-name">{group?.name}</div>
              </div>
            </div>
            <div className="dashboard-container__invited-to-groups-card__invite-container__action-buttons">
              <NotataButton
                size="small"
                buttonStyle="group-action-button"
                text="join"
                loading={loadingStateForGroup === `${group.id}::accept`}
                onClick={async () => {
                  if (loadingStateForGroup === `${group.id}::accept`) {
                    return;
                  }
                  setLoadingStateForGroup(`${group.id}::accept`);
                  let variables = {
                    groupId: group.id,
                    email: user.email,
                    response: "ACCEPT",
                  };
                  await respond({ variables });
                  let notification = getNotification({ groupId: group.id });
                  if (notification) {
                    let variables = { id: notification?.id };
                    await markNotification({ variables });
                  }
                  setLoadingStateForGroup(undefined);
                  history.push(`${group_dashboard}/${group.id}`);
                }}
              />

              <NotataButton
                size="small"
                buttonStyle="group-action-button"
                text="reject"
                loading={loadingStateForGroup === `${group.id}::reject`}
                onClick={async () => {
                  if (loadingStateForGroup === `${group.id}::reject`) {
                    return;
                  }
                  setLoadingStateForGroup(`${group.id}::reject`);
                  let variables = {
                    groupId: group.id,
                    email: user.email,
                    response: "REJECT",
                  };
                  await respond({ variables });

                  let notification = getNotification({ groupId: group.id });
                  if (notification) {
                    let variables = { id: notification?.id };
                    await markNotification({ variables });
                  }
                  setLoadingStateForGroup(undefined);
                }}
              />
            </div>
          </div>
        ))}

        {/* OPEN GROUPS */}
        {openGroupsThatImNotMemberOf.length !== 0 && (
          <div>
            {openGroupsThatImNotMemberOf.map(openGroup => {
              return (
                <div
                  className="dashboard-container__invited-to-groups-card__invite-container"
                  key={openGroup?.id}
                >
                  <div className="dashboard-container__invited-to-groups-card__invite-container__invite">
                    <div className="invite-member">
                          <span className="invitation-text">
                            Join open group
                          </span>
                    </div>
                    <div className="dashboard-container__invited-to-groups-card__data-container__data-entry">
                      <div className="group-name">{openGroup?.name}</div>
                    </div>
                  </div>
                  <div className="dashboard-container__invited-to-groups-card__invite-container__action-buttons">
                    <NotataButton
                      size="small"
                      buttonStyle="group-action-button"
                      text="join"
                      loading={
                        loadingStateForGroup === `${openGroup.id}::join`
                      }
                      onClick={async () => {
                        if (
                          loadingStateForGroup === `${openGroup.id}::join`
                        ) {
                          return;
                        }
                        setLoadingStateForGroup(`${openGroup.id}::join`);
                        let variables = { id: openGroup.id };
                        await joinOpenGroup({ variables });
                        setLoadingStateForGroup(undefined);
                        history.push(`${group_dashboard}/${openGroup.id}`);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
    </>
  );

}

function CardFooter() {

  const history = useHistory()

  // States
  const [createGroupModal, setCreateGroupModal] = useState(false);

  return (
    <>
      <div className="footer_btns">
        <Link className="footer_btns__link" to={group}>
          <NotataButton
            color="white"
            text="View Groups"
            buttonStyle="text_button"
          />
        </Link>
        <NotataButton
          color="white"
          text="Create Group"
          buttonStyle="text_button"
          onClick={() => setCreateGroupModal(true)}
        />
      </div>
      {createGroupModal && (
        <CreateGroup
          close={() => setCreateGroupModal(undefined)}
          history={history}
        />
      )}
    </>
  )
}


// MAIN FUNCTION
export default function DashboardGroupInvitations() {

  return (
    <CardComponent
      containerClassName="dashboard-container__invited-to-groups-card"
      padding="small"
      title="Group invitations"
      titleSize="small"
      titleColor="white"
      data-tut="group-invitations"
      content={
        <div className="dashboard-container__invited-to-groups-card__data-container">
          <CardContent/>
        </div>
      }
      footer={<CardFooter/>}
    />
  );
}
