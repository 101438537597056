import React, { useState } from 'react';
import { NotataButton, Loader } from "Components/NotataComponents";
import './CodeSnippet.scss'


export const CodeSnippet = ({heading, content, contentType, contentBackground, loading}) => {
    const [success, setSuccess] = useState()

    function copyToClipboard() {
        navigator.clipboard.writeText(content);
        setSuccess(true);
    }
    
    return(
        <div className="code-snippet">
            { heading &&
                <h3 className="code-snippet__heading">
                    {loading && <Loader noPadding={true} />}
                    {heading}
                </h3>
            }
            <p className={`code-snippet__content ${"code-snippet__content--bg-"+contentBackground}`}>{content}</p>
            <NotataButton
                text={`${success ? contentType+" copied" : "copy "+contentType}`}
                color="primary"
                startIcon="content_copy"
                buttonStyle="text_button"
                onClick={() => copyToClipboard()}
            />
        </div>
    )
}
