import React, { useState } from "react";

// MODALS
import InviteStartupModal from "./StartupInfoModal/InviteStartupModal";

//COMPONENTS
import { Card, NotataButton } from "Components/NotataComponents";

// CARDS
import StartupCard from "../../CommonComponents/StartupCard";
import CommentBox from "../../CommonComponents/CommentBox";
import StartupInvited from './cards/StartupInvited';
import Answers from './cards/Answers';

// OTHERS
import EditStartupInfo from "./SubPages/EditStartupInfo";
import ArchiveAndDeleteSection from "../Overview/components/ArchiveAndDeleteSection";
import { groupBy } from 'lodash'

// STYLES
import "./StartupInfo.scss";

// MAIN FUNCTION
export default function StartupInfo({ connection, history, creativeTemplate }) {

  // STATES
  const [inviteStartUpModal, setInviteStartUpModal] = useState(false);
  const [shareStartup, setShareStartup] = useState(false);
  if (shareStartup) {
    return (
      <EditStartupInfo
        backToInfoPage={() => {
          // Remove hash
          history.push(`${history.location.pathname}${history.location.search}`)
          setShareStartup(false);
        }}
        connection={connection}
        history={history}
      />
    );
  }

  const answers = groupBy(connection?.creative?.answers , 'sectionName');
  return (
    <>

      <div className="row tab-panel-container startup-info-container">

        <div className="col-sm-12 col-md-8">
          <StartupCard
            connection={connection}
          />
          {(connection?.creative?.sharedWithEmail && connection?.creative?.canEdit) &&
            <StartupInvited
              creative={connection?.creative || {}}
            />
          }
          {(connection?.creative?.canEdit) &&
            <Card containerClassName="card invite-edit-container">
              {!connection?.creative?.sharedWithEmail &&
                <NotataButton 
                  size="xx_large"
                  bgcolor="primary_color"
                  buttonStyle="card invite-edit-container__share-button"
                  text="invite startup to fill in this information"
                  onClick={() => setInviteStartUpModal(true)}
                />
              }   
              {connection?.creative?.canEdit &&
                <NotataButton 
                  size="big"
                  buttonStyle="card invite-edit-container__edit-button"
                  bgcolor="primary_color"
                  text="edit startup info"
                  onClick={() => setShareStartup(true)}
                />
              }
            </Card>
          }
          {creativeTemplate?.sections?.map(section => {
            return (
              <div
                className="starup_info_answers"
                key={`ans-${section.id}`}
                >
                <Answers
                  questions={section.questions}
                  answers={answers[section.name]}
                  sectionName={section.name}
                  key={section.name}
                />
              </div>
            )
          })}
          <ArchiveAndDeleteSection connection={connection} history={history} />



        </div>
       
        {/* Comment Box */}
        <CommentBox
          commentBox={"comment_box"}
          connectionId={connection?.id}
        />

      </div>

      {inviteStartUpModal && (
        <InviteStartupModal
          connection={connection}
          close={() => {
            setInviteStartUpModal(false);
          }}
        />
      )}
    </>
  );
}
