import React from 'react';
import StartupCard from "private/Pages/StartupPage/CommonComponents/StartupCard";
import "./Startup.scss";

import StartupInfoSection from "./StartupInfoSection";

export default function StartupComponent({ group, creativeId, close }) {

  const creative = group?.startups?.find(({id}) => id === creativeId)
  const sectionAnswers = creative?.newAnswers

  return (
    <div
      className="startup-container"
      style={{ width: "100%" }}
    >

      <div
        className="back-button"
        onClick={close}
        style={{marginBottom: "20px"}}
      >
        <span className="material-icons back-icon">
          arrow_back_ios
        </span>
        <span className="page-heading">
          GO BACK TO GROUP
        </span>
      </div>

      <div className="startup-container__content">

        <StartupCard
          connection={{ creative: creative }}
        />
        {
          sectionAnswers.length !== 0 &&
          creative?.newAnswers?.map(section => (
          <StartupInfoSection
            section={section}
            key={section.name}
          />
        ))}

      </div>

    </div>
  )
}