import React from "react";

// OTHERS
import TemplatedForm from "Components/Forms/TemplatedForm"

// STYLES
import "./Evaluate.scss";

export default function Evaluate({
    template,
    history,
    loading,
    answers,
    setAnswers,
    onCancel
}) {
    return (
        <div className="edit-evaluation">
            <TemplatedForm
                template={template}
                location={history.location}
                loading={loading}
                content={answers}
                submit={answers => {
                    setAnswers(answers);
                }}
                onCancel={onCancel}
                showComments={true}
            />
        </div>
    )
}
