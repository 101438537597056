import React from "react";
import data from "./data";

import "./Documentation.scss";

export default function Documentation() {
  return (
    <div style={{ maxWidth: "700px", margin: "auto" }}>
      {data.map((d, i) => (
        <div className='container' key={i}>
          <div className='container__header'>
            <div className='label'>Table name:</div>
            <div className='table_name_title'>{d.tableName}</div>
          </div>

          <div className='header'>
            <div className='label'>Type name:</div>
            <div className='table_name'>{d.typeName}</div>
          </div>

          <div className='container__description'>{d.description}</div>

          <div className='container__sub_header'>
            <div className='label'>Key attributes:</div>
            {d.keyAttributes.map(attribute => (
              <div key={`${i}-${attribute}`} className='sub_title'>
                {attribute}
              </div>
            ))}
          </div>

          <div className='sub_header'>
            <div className='label'>Key schema:</div>
            {d.keySchema.map(schema => (
              <div key={`${i}-${schema}`} className='sub_title'>
                {schema}
              </div>
            ))}
          </div>

          {d.globalSecondaryIndexes.map((gsi, o) => (
            <div className='sub_header' key={`${i}-${o}`}>
              <div className='label'>Global Secondary Index:</div>
              <div className='sub_title'>
                Index name: {gsi.indexName}
              </div>
              <div className='sub_title'>
                Key schema: {gsi.keySchema}
              </div>
            </div>
          ))}

          <div className='sub_header'>
            <div className='label'>Related queries:</div>
            {d.relatedQueries.map(query => (
              <div key={`${i}-${query}`} className='sub_title'>
                {query}
              </div>
            ))}
          </div>

          <div className='sub_header'>
            <div className='label'>Related sub queries:</div>
            {d.relatedSubQueries.map(query => (
              <div key={`${i}-${query}`} className='sub_title'>
                {query}
              </div>
            ))}
          </div>
        </div>
      ))}

      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
}
