import React from "react";
import { Card, NotataButton } from "Components/NotataComponents";
import "./Payment.scss";

const Product = ({
    callBack,
    loading,
    meta,
    plan,
    interval,
    isSelectedPlan,
    isCanceled,
    priceAmount,
    price_id
  }) => {



  return (

      <div className="card product-card">

        <div className="product-card__top-section">

          {
            (isSelectedPlan && !isCanceled) && (
              <div className="product-card__top-section__banner">
                CURRENT PLAN
              </div>
            )
          }

          {
            (isSelectedPlan && isCanceled) && (
              <div className="product-card__top-section__banner">
                <span style={{color: "#C80000"}}>CANCELED&nbsp;&nbsp;</span>
              </div>
            )
          }

          <div className="product-card__top-section__price">
            <span className="unit">$</span>
            <span className="amount">{priceAmount}</span>
            <span className="interval">user / {interval}</span>
          </div>

          <div className="product-card__top-section__plan">
            {plan}
          </div>

        </div>

        <div className="product-card__content">
          <div className="product-card__content__description">


            {
              meta.map((item, index) =>
                <div
                  className="product-card__content__description__line"
                  key={`meta-${index}`}
                >
                  {
                    item.label && (
                      <div className="product-card__content__description__line__label">
                        {item.label}
                      </div>
                    )
                  }
                  <div className="product-card__content__description__line__text">
                    {item.text}
                  </div>
                </div>
              )
            }

          </div>
        </div>

        <div className="product-card__footer">

          <NotataButton
            size="large"
            bgcolor={(isSelectedPlan && !isCanceled) ? "grey_color" : "primary_color"}
            disabled={isSelectedPlan && !isCanceled}
            loading={loading}
            text={
              (isSelectedPlan && isCanceled)
                ? "renew"
                : (isSelectedPlan ? "Current plan" : "Select plan")
            }
            onClick={() => {
              !loading && callBack(price_id);
            }}
          />

        </div>

      </div>
  )
};
export default Product;