import React from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  // Sector,
  Label
} from "recharts";
import { CHARTCOLORS } from "../../constants";
import { sumBy } from 'lodash';

// Custom tooltip for Bar Charts
const CustomToolTip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0]?.payload?.tagName} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

export default function ReportPieChart({ tagsData, totalConnectionCount, stylingForDashboard }) {
  let tags = tagsData?.filter(item => item.id !== 'xxxx');
  let otherTag = tagsData?.filter(item => item.id === 'xxxx');
  otherTag = otherTag.length > 0 ? otherTag[0] : null;
  let sumExceptFirst2 = sumBy(tags.sort(function (a, b) {
    return b.connectionCount - a.connectionCount;
  }).slice(2, tags.length), 'connectionCount');
  if (sumExceptFirst2 > 0) {
    if (otherTag) {
      otherTag.connectionCount = otherTag.connectionCount + sumExceptFirst2;
    }
    else {
      otherTag = {
        tagName: "Others",
        id: "xxxx",
        connectionCount: sumExceptFirst2,
      }
    }
  }
  let a = tags.sort(function (a, b) {
    return b.connectionCount - a.connectionCount;
  }).slice(0,2).map((tag, index) => {
    return Math.round(tag.connectionCount * 100 / totalConnectionCount)
  })

  let b = a[0]+a[1]
  const PercentageFunction = (per) =>{
   return per
  }
  return (
    <div className="row">
      <div className={`${stylingForDashboard ? 'col-sm-12' : 'col-lg-5 col-md-12 col-sm-5 col-sm-push-7 col-md-push-0 col-lg-push-7 col-xs-12'}`}>
        <div className={`pie-legends ${stylingForDashboard ? 'dashboard-legends' : ''}`}>
          {tags.sort(function (a, b) {
            return b.connectionCount - a.connectionCount;
          }).map((tag, index) => {
            
            if (index < 2) {
              return <div className="legend" key={`tag-legend-${index}`}>
                <div className="legend-icon icon-0"
                  style={{
                    backgroundColor: CHARTCOLORS[index].colorStart
                  }}></div>
                <div className="legend-text">{tag.tagName}</div>
                <div className={`legend-count ${index === 0 ? 'max' : ''}`}>
                  {PercentageFunction(Math.round(tag.connectionCount * 100 / totalConnectionCount))}%
                  </div>
              </div>
            }
          })}
          {otherTag &&
            <div className="legend" key={`tag-legend-${2}`}>
              <div className="legend-icon icon-0"
                style={{
                  backgroundColor: CHARTCOLORS[3].colorStart
                }}></div>
              <div className="legend-text">{otherTag.tagName}</div>
              <div className={`legend-count`}>
                {Math.round(otherTag.connectionCount * 100 / totalConnectionCount)+b===100 &&
                Math.round(otherTag.connectionCount * 100 / totalConnectionCount)
                }
                {Math.round(otherTag.connectionCount * 100 / totalConnectionCount)+b>100 &&
                Math.round(otherTag.connectionCount * 100 / totalConnectionCount)-1
                }
                {Math.round(otherTag.connectionCount * 100 / totalConnectionCount)+b<100 &&
                Math.round(otherTag.connectionCount * 100 / totalConnectionCount)+1
                }
                %
              </div>
            </div>
          }
        </div>
      </div>
      <div
        className={`chart-tile-container__pie ${stylingForDashboard ? 'col-sm-12' : 'col-lg-7 col-md-12 col-sm-7 col-md-pull-0 col-sm-pull-5 col-lg-pull-5 col-xs-12'} `}
      >
        <ResponsiveContainer>
          <PieChart>
            <Pie
              activeIndex={0}
              data={tagsData}
              dataKey="value"
              innerRadius={40}
              outerRadius={74}
              // activeShape={renderActiveShape}
              startAngle={-60}
              endAngle={300}
              paddingAngle={5}
              labelKey="tagName"
              dataKey="connectionCount"
            >
              {tagsData.map((entry, index) => (
                <Cell fill={`url(#gradient${index + 1})`} key={index}></Cell>
              ))}
              <Label value="Total" dy={-3} position="centerBottom" className='total' />
              <Label value={totalConnectionCount} dy={3} position="centerTop" className='count' />
            </Pie>
            <Tooltip content={<CustomToolTip />} />
            <defs>
              {CHARTCOLORS.map((chartColor, index) => {
                return (
                  <linearGradient
                    key={`key-gradieant-${chartColor.colorStart}`}
                    id={`gradient${index + 1}`}
                  >
                    <stop
                      offset={chartColor.offsetStart}
                      stopColor={chartColor.colorStart}
                    />
                    <stop
                      offset={chartColor.offsetStop}
                      stopColor={chartColor.colorStop}
                    />
                  </linearGradient>
                );
              })}
            </defs>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
