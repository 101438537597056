import { cloneDeep } from "lodash";

export default function sortAlphabeticallyByField({ array, field, clone, reverse }) {

  if (clone) {
    array = cloneDeep(array)
  }

  let sorted = array?.sort((a, b) => {
    if(a?.[field]?.trim().toLowerCase() < b?.[field]?.trim().toLowerCase()) { return -1; }
    if(a?.[field]?.trim().toLowerCase() > b?.[field]?.trim().toLowerCase()) { return 1; }
    return 0;
  })

  return reverse ? sorted.reverse() : sorted;
}