import React from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { connectionFunnelTagAdd, connectionFunnelTagRemove } from "private/Apollo/Mutations";

// UI COMPONENTS
import { Loader } from "Components/NotataComponents";

// COMMON FUNCTIONS
import { sortArr } from "../../../../commonFunctions";

import "./Funnels.scss"

// VARIABLE
let colorArray = ["red", "blue", "purple", "orange", "green"];

export default function Funnel({ connection, funnelGroup }) {

  // Mutations
  const [addTag, addTagRes] = useMutation(connectionFunnelTagAdd);
  // Mutations
  const [removeTag, removeTagRes] = useMutation(connectionFunnelTagRemove);

  let selectedFunnelTags = sortArr(connection?.funnelTags);


  // MAP AND REDUCERS
  if (!funnelGroup?.funnelTags?.length) {
    return <span />;
  }

  // Functions
  // addTag function
  async function addTagFn(tag, isSelected) {
    let variables = {
      connectionId: connection.id,
      funnelTagId: tag.id,
    };

    try {
      if(!isSelected)
        await addTag({ variables });
      else{
        await removeTag({ variables });
      }

    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div className="funnels-container__funnels__funnel no-margin">
      {(addTagRes.loading || removeTagRes.loading) && <Loader noPadding={true} />}

      <div className="funnels-container__funnels__funnel__heading">
        {funnelGroup.name}
      </div>

      <div className="parts">
        {!!funnelGroup?.funnelTags?.length &&
        funnelGroup.funnelTags.map((tag, index) => {
          // Max width of bar
          let max = 180;

          // Min width of bar
          let min = 20;

          // Difference
          let diff = max - min;

          // Increments
          let inc = Math.round(diff / funnelGroup.funnelTags.length);

          // Max with - increment multiplied by index
          let width = `${max - inc * index}px`;

          let isSelected = selectedFunnelTags.some(({ id }) => id === tag.id);

          let color = !isSelected
            ? "gray"
            : tag.color || colorArray[index] || "red";

          return (
            <div
              className="parts__part"
              key={tag.id}
              onClick={() => {
                addTagFn( tag, isSelected);
              }}
            >
              <div className="parts__part__heading">{tag.name}</div>
              <div className="parts__part__color_block">
                <div
                  className={`parts__part__funnel ${color}`}
                  style={{ width }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}