import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { omit } from "lodash";

// API STUFF
import { useMutation, useLazyQuery } from "@apollo/client";

import { evaluationTemplateSectionGet } from "private/Apollo/Queries";
import { evaluationTemplateSectionUpdate } from "private/Apollo/Mutations";

// UI COMPONENTS
import {
  TextBox,
  NotataButton,
  GhostLoader,
  BreadCrumbs
} from "Components/NotataComponents";

// STYLES
import "../SectionPage.scss";

// DEFINITIONS
import { evaluation_template_page, evaluation_templates_page, settings } from "definitions";

// OTHERS
import QuestionCard from "./QuestionCard";

// Helpers
const getId = () => Math.round(Math.random() * 10000).toString();

export const EvaluationTemplateSectionPage = ({ match, history }) => {
  const hist = useHistory();

  // URL Stuff
  const templateId = match?.params?.id;
  const sectionId = match?.params?.sectionId;


  // CONSTANTS
  const getDefaultQuestion = (questionNumber) => {
    let newId = `tmp//${getId()}`;
    return {
      id: newId,
      name: '',
      description: "",
      inputType: "RADIO",
      options: [
        {
          questionId: newId,
          sid: `tmp//${getId()}`,
          val: "",
          score: 0,
        },
      ],
    };
  };
  const defaultQuestion = getDefaultQuestion(1);
  const defaultOption = defaultQuestion.options[0];

  // States
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [questions, setQuestions] = useState([defaultQuestion]);
  const [options, setOptions] = useState([defaultOption]);
  const [checkInvalidQuestion, setCheckInvalidQuestion] = useState(false);

  // Queries
  const [getSection, { data, loading }] = useLazyQuery(evaluationTemplateSectionGet);

  // Mutations
  const [updateSectionMutation, updateSectionMutationRes] = useMutation(
    evaluationTemplateSectionUpdate
  );

  // Data maps
  const section = data?.evaluationTemplateSectionGet || {};


  // EFFECTS

  // Get section
  useEffect(() => {
    if (sectionId) {
      getSection({ variables: { id: sectionId } });
    }
  }, [sectionId]);

  // Set questions and options from server
  useEffect(() => {
    if (!section) return;

    // Set name and description after getting response from server
    setName(section.name || "");
    setDescription(section.description || "");

    // Set questions
    if (section?.questions?.length > 0) {
      section?.questions && setQuestions(section.questions);
    }
  }, [section]);

  const updateSection = async () => {
    setCheckInvalidQuestion(false);
    let isInvalidQuestionsPreset = questions?.some(question => question?.options?.length > 0 && (question.inputType === "RADIO" || question.inputType === "CHECK") && (!question?.options[0]?.val || !question?.options[1]?.val));
    let isOptionsAreEmpty = questions?.some(question => question?.options?.length === 0);
    let isQuestionNameIsEmpty = questions?.some(question => !question?.name);
    if (isInvalidQuestionsPreset || isOptionsAreEmpty || isQuestionNameIsEmpty) {
      setCheckInvalidQuestion(true);
      return;
    }

    let updatedQuestions = questions?.map(question => {
      // Get if it's a temporary question or not
      const questionTmpId = question.id.substring(0, 3) === "tmp";

      // Default omit typename
      let omitForQuestion = ["__typename"];

      // If it's temporary, get rid of temp id
      if (questionTmpId) {
        omitForQuestion.push("id");
      }

      // Define new options
      let updatedOptions = question?.options?.map(option => {
        // Get if option is temporary
        const optionTmpId = option?.sid?.substring(0, 3) === "tmp";

        // Default omit typename
        let omitForOption = ["__typename"];

        // If it's temporary, get rid of temp id
        if (optionTmpId) {
          omitForOption.push("sid");
          omitForOption.push("questionId");
        }

        return omit(option, omitForOption);
      });

      // Define new question
      let updatedQuestion = {
        ...omit(question, omitForQuestion),
        options: updatedOptions,
      };

      return updatedQuestion;
    });
    let updatedSection = {
      ...omit(section, ["id", "__typename", "templateId", "templateName"]),
      name,
      description,
      questions: updatedQuestions,
    };

    try {
      let variables = {
        id: sectionId,
        input: updatedSection,
      };
      await updateSectionMutation({ variables });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  if (!data && loading) {
    return <GhostLoader />;
  }

  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[
          { seperator: ">", page1: "EVALUATION TEMPLATES", route: evaluation_templates_page },
          { seperator: ">", page1: `${section?.templateName}`, route: `${evaluation_template_page}/${templateId}` },
          { seperator: ">", page1: `${section?.name}` }
        ]}
      /> 

      <div className="add-section-conatiner">
        {/* HEADER: NAME AND DESCRIPTION */}
        <div className="row">
          <div className="col-sm-12 text-container1">
            <form className="sectionform">
              <i
                className="fa fa-chevron-left add-section-conatiner__back-template "
                onClick={() => hist.goBack()}
                style={{
                  color: "var(--ui-color-primary-green-dark2)",
                  transform: "scale(1.4)",
                  cursor: "pointer",
                }}
              ></i>
              <div>
                <TextBox
                  name="sectionName"
                  defaultValue={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Section Name"
                />
                <textarea
                  style={{ padding: "12px 15px" }}
                  name="sectionDescription"
                  onChange={e => setDescription(e.target.value)}
                  value={description}
                  rows="4"
                  cols="50"
                  placeholder="Section Description"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="evaluation-templates-container__data-container">
          {questions?.map(question => {
            return (
              <QuestionCard
                key={question.id}
                section={section}
                questions={questions}
                question={question}
                setQuestions={setQuestions}
                isInValidQuestion={checkInvalidQuestion}
                updateSection={updateSection}
              />
            );
          })}

          <div className="row">
            <div className="col-sm-12 evaluation-templates-container__data-container__actions" style={{ marginTop: "20px" }} >
              <NotataButton
                size="medium"
                bgcolor="primary_color"
                text="New Question"
                onClick={() => {
                  let q = getDefaultQuestion(questions?.length + 1);
                  setQuestions([...questions, q]);
                  setOptions([...options, ...q.options]);
                  setCheckInvalidQuestion(false);
                }}
              />
              <div className="evaluation-templates-container__data-container__actions__save-cancel">
                <NotataButton
                  size="medium"
                  buttonStyle="outlined"
                  text="Cancel"
                  onClick={() => {
                    history.push(`${evaluation_template_page}/${templateId}`);
                  }}
                />
                <NotataButton
                  size="medium"
                  bgcolor="primary_color"
                  text={
                    updateSectionMutationRes.loading ? <i className={"fa fa-spinner fa-spin"} /> : "Save Changes"
                  }
                  onClick={updateSection}
                />
              </div>
            </div>
          </div>

          <div className="btn-container">
            {updateSectionMutationRes.data &&
              !updateSectionMutationRes.loading && (
                <div className="success-message">Successfully saved</div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
