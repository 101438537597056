import React from "react";

// UI COMPONENTS
import { Tag } from "Components/NotataComponents/Tag/Tag";

// MODALS
import AddTagsForConnectionModal from "../OverviewModals/AddTagsForConnectionModal";

// MAIN FUNCTION
export default function TagsComp({
  connection,
  showTagsModal,
  setShowTagsModal,
}) {
  return (
    <>
      <div>
        <div className="tags-container__sub-heading">
          Adding tags makes it easier to filter, find similar startups, and
          makes great analytics
        </div>
        <div className="tags-container__placeholder">
          {connection?.tags?.map(el => (
            <span key={el.id} className="" className="tags_margin">
              <Tag
                color="primary_tag"
                size="md_tag"
                label={`${el.group.name}: ${el.name}`}
                tagmargin="tag_margin"
              />
            </span>
          ))}
          <i
            className="fa fa-plus"
            aria-hidden="true"
            onClick={() => setShowTagsModal(true)}
          />
        </div>
      </div>

      {/* showTagsModal */}
      {showTagsModal && (
        <AddTagsForConnectionModal
          connection={connection}
          close={() => setShowTagsModal(false)}
        />
      )}
    </>
  );
}
