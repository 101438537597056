import React from "react";

// CUSTOM COMPONENTS
import Notification from "./Notification";

// STYLES
import "./Notitifications.scss";

// Main Function
 const Notifications = ({ notifications, history }) => {
  return (
    <div className="notifications-container-new">
      {notifications.map(notification => (
        // Notification
        <Notification
          key={`notification-${notification.id}`}
          notification={notification}
          history={history}
        />
      ))}
    </div>
  );
}

export default Notifications