import React, { useState, useEffect } from "react";

// Components
import { NotataButton } from "Components/NotataComponents";
import Section from "./Section";

// Styles
import "./TemplatedForm.scss";

// *****************
// * Main function *
// *****************
export default function TemplatedForm({
  template,
  content,
  submit,
  loading,
  location,
  showComments,
  onCancel,
  containerStyle
}) {

  let { hash } = location;

  // State: form answers
  const [answers, setAnswers] = useState(content || []);

  // Populate answers from server
  useEffect(() => {
    if (content) {
      setAnswers(content);
    }
  }, [content]);

  // Get sections from creative template
  let sections = template.sections || [];

  // Roll out the sections
  return (

    <div className="form_container" style={containerStyle}>
      {/* Form title */}
      {template.title && (
        <div className="form_title">{template.title}</div>
      )}

      {/* Form description */}
      {template.description && (
        <div className="form_description">{template.description}</div>
      )}

      {/* Sections */}
      {sections.map(section => ( 
        <Section
          key={section.id}
          section={section}
          answers={answers}
          setAnswers={setAnswers}
          open={`#${section.name}` === hash}
          showComments={showComments}
        />
      ))}

      {/* Submit form */}
      <div className="save-cancel_button__container">
        <NotataButton
          size="small"
          buttonStyle="outlined"
          text="cancel"
          onClick={() => onCancel()}
        />
        <NotataButton
          size="small"
          bgcolor="primary_color"
          text="Save"
          loading={loading}
          onClick={() => {
            submit(answers);
          }}
        />
      </div>
    </div>
  );
}
