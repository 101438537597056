import React from "react";

// Components: unique
import { GeneralInput } from "./Inputs/GeneralInput";
import { Comments } from "./Comments";

// Styles
import "./TemplatedForm.scss";

// *****************
// * Main function *
// *****************
export default function Question({ question, section, answers, setAnswers, showComments }) {

  return (
    <div className="question__container">
      {/* Question name */}
      <div className="question__title">{question.name}</div>

      {/* Question description */}
      {question.description && (
        <div className="question__description">
          {question.description}
        </div>
      )}

      {/* Each input */}
      <div className="question__options">
        <GeneralInput
          section={section}
          question={question}
          answers={answers}
          setAnswers={setAnswers}
        />
      </div>

      {/* Comment section */}
      {showComments && (
        <Comments
          section={section}
          question={question}
          answers={answers}
          setAnswers={setAnswers}
        />
      )}

    </div>
  );
}
