import React, { useState, useEffect } from 'react';
import { useLazyQuery } from "@apollo/client";
import { GhostLoader } from "Components/NotataComponents";
import Content from "./Content";

import './GroupSharing.scss';
import { publicGroupSettingsGet } from "public/Apollo/Queries";

import PinAuthenticator from "./PinAuthenticator";
import MemberDetails from "./MemberDetails";

import { getStoredDetails, putItemToDB } from "utils/groupSharing";
import { error } from "react-beautiful-dnd/src/dev-warning";


export default function GroupSharing({ match }) {

  const groupId = match?.params?.id;

  // BARRIERS
  const [isPinRequired, setIsPinRequired] = useState(false);
  const [isInvitedOnly, setIsInvitedOnly] = useState(false);

  //Queries
  const queryOptions = { context: { clientName: "public" } };
  const [getPublicGroupSettingData, { data, loading }] = useLazyQuery(publicGroupSettingsGet, queryOptions);

  // MAPS
  const groupSetting = data?.publicGroupSettingsGet;
  const storedData = getStoredDetails(groupId);

  // Get settings
  useEffect(() => {
    if (groupId) {
      getPublicGroupSettingData({
        variables: {
          groupId: groupId
        }
      })
    }
  }, [groupId])

  // BARRIER #1: Set pin is required
  useEffect(() => {
    if (
      groupSetting?.pinRequired && !storedData?.pin) {
      setIsPinRequired(true);
    }
  }, [groupSetting])


  // BARRIER #2: Set if invited only
  useEffect(() => {

    let requestRegister = groupSetting?.invitedOnly
      || groupSetting?.withEvaluationTemplate
      || groupSetting?.withSubjectiveScores

    if (requestRegister && !storedData?.member?.email
    ) {
      setIsInvitedOnly(true);
    }
  }, [groupSetting])


  if (!data || loading) {
    return <GhostLoader />
  }

  // 001 - BARRIER #1
  if (isPinRequired) {
    return (
      <PinAuthenticator
        groupId={groupId}
        success={pin => {
          const item = {
            ...storedData,
            pin
          }

          console.log('YOLO', item)

          putItemToDB(groupId, item)
          setIsPinRequired(false);
        }}
      />
    )
  }

  // 001 - BARRIER #2
  if (isInvitedOnly) {
    return (
      <MemberDetails
        groupId={groupId}
        settings={groupSetting}
        success={member => {
          const item = {
            ...storedData,
            member
          }
          putItemToDB(groupId, item)
          setIsInvitedOnly(false)
        }}
      />
    )
  }

  return (
    <Content groupId={groupId}/>
  )


}