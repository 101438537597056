import React from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { minBy, maxBy } from "lodash";

// Custom tooltip for Bar Charts
const CustomToolTip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0]?.payload?.tagName} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};
const tickFormatter = (value, index) => {
  const limit = 8; // put your maximum character
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};

export default function ReportsColumnChart({ tagsData }) {
  let minValue = minBy(tagsData, 'connectionCount')?.connectionCount;
  minValue = minValue <= 5 ? 0 : minValue;
  let maxValue = maxBy(tagsData, 'connectionCount')?.connectionCount;
  return (
    // style={{backgroundColor:"red"}}
    <div className={`chart-tile-container__chart chart-tile-container__column`} >
      <ResponsiveContainer className="column" >
        <BarChart
          data={tagsData}
          margin={{
            top: 5,
            right: 20,
            left: 0,
            bottom: 15,
          }}
        >
          <CartesianGrid
            strokeDasharray="0"
            stroke="#F1F1F1"
            vertical={false}
          />
          <XAxis
            dataKey="tagName"
            tick={{
              fontSize: 8,
              fontWeight: "bold",
              letterSpacing: "0.08em",
              fontFamily: "Proxima Nova",
              // color: "#969BA3", <= it's called "fill" in SVG
              angle: 60,

              // OBS, use this!
              textAnchor: "start",

              // This attribute does not get added to SVG
              // we need something like this:
              // <text ... transform-origin="8 -18" text-anchor="start" />
              transformOrigin: "8 -18"
            }}
            axisLine={false}
            dx={5}
            dy={0}
            interval={0}
            minTickGap={-200}
            tickFormatter={tickFormatter}
          />
          <YAxis
            tick={{
              fontSize: 10,
              fontWeight: "bold",
              letterSpacing: "0.08em",
              fontFamily: "Proxima Nova",
              color: "#969BA3",
            }}
            minTickGap={5}
            axisLine={false}
            type="number"
            tickCount={4}
            // domain={[0, maxValue]}
          />
          <Tooltip content={<CustomToolTip />} cursor={{ fill: "#f4f4f4" }} />
          <Bar
            dataKey="connectionCount"
            fill="#94E3D3"
            barSize={20}
            radius={[10, 10, 10, 10]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
