import gql from "graphql-tag";

export default gql`
    fragment TagFields on Tag {
        tagGroupId
        id
        name
        group {
            id
            name
        }
    }  
`




