import React from "react";

import {NotataButton, Card} from "Components/NotataComponents";
import { Link } from "react-router-dom";


export default function GroupsInfoCards({ heading, noOfRecords, img, linkTo, link, onClick, dataTut }) {
  return (
    <Card containerClassName="group-info-card" size="small" data-tut={dataTut} heading={heading}>
      <div className="my-groups-container__data">
        <div className="my-groups-container__noOfRecords">{ noOfRecords===null ? 0 : noOfRecords }</div>
        <img src={img} alt="" />
        {linkTo && (
          <div className="my-groups-container__footer-link">
            <Link className="underline" to={link}>
              <NotataButton
              onClick= {onClick}
              color="primary"
              text={linkTo}
              buttonStyle="text_button"
              endIcon="chevron_right"
            />
            </Link>
          </div>
        )}
      </div>
    </Card>
  );
}
