import React, { useState } from "react";
import { useForm } from "react-hook-form";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { groupUserInvite, groupSettingsSet } from "private/Apollo/Mutations";
import { groupsGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { Modal, InputFormWithButton, Dropdown } from "Components/NotataComponents";

// CUSTOM COMPONENTS
import PublicMembers from "./PublicMembers";
import PendingPublicMembers from "./PendingPublicMembers";

import CardMessageContainer from "../Components/CardMessageContainer";
import GroupPublicMembersImport from "./GroupPublicMembersImport";

// Validations
import validateEmail from "../../../../utils/validateEmail";

// OTHERS--form
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { omit } from "lodash";





async function addPublicUserInvitation({email, group, mutation}) {

  email = email.toLocaleLowerCase().trim()

  const [mutate, { loading }] = mutation;

  let publicSettings = {
    ...(publicSettings || {}),
    invitedUsers: [
      ...(group?.publicSettings?.invitedUsers || []),
      email
    ]
  }

  if (loading) return;

  try {
    let variables = {
      groupId: group.id,
      settings: omit(group?.settings, ["__typename", "isOpen"]),
      publicSettings: omit(publicSettings, [
        "__typename",
        "evaluationTemplate",
        "link",
        "qr_code",
        "groupId"
      ]),
    };
    await mutate({ variables });
  } catch (error) {
    console.log("error", error);
  }

}






export default function ManagePublicMembersModal({ group, close }) {

  // CONSTANTS
  let canInvite = group?.iAmAdmin || group?.settings?.addUser;
  let canRemove = group?.iAmAdmin;

  // States
  const [ invitationLoading, setInvitationLoading ] = useState(false)
  const [isEmail, setIsEmail] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState();

  // Queries
  let groupsQuery = useQuery(groupsGetV2);

  // Mutations
  const mutation = useMutation(groupSettingsSet, {
    refetchQueries: [{ query: groupsGetV2 }],
    awaitRefetchQueries: true,
  });

  // Data maps
  let groups = groupsQuery?.data?.groupsGetV2 || [{name:"--Select Group--", id:"0"}];

  // Forms
  const { register, handleSubmit, formState:{errors}, setValue } = useForm({
    resolver:  yupResolver(
      yup.object().shape({
        email: yup.string().email().required(),
      })
    ),
  });

  // ONSUBMIT FUNCTION
  async function onSubmit({ email }) {

    if (!canInvite || invitationLoading) {
      return;
    }

    setInvitationLoading(true)

    try {
      await addPublicUserInvitation({email, group, mutation})
    } catch (error) {
      console.log("error", error);
    }

    setValue("email", "");
    setIsEmail("")
    setInvitationLoading(false)

  }

  return (
    <Modal
      title="Manage Public Members"
      submit={(e)=>{
        if(e.key==="Enter") return null;
        else return close()
      }}
      close={close}
      submitTxt="OK"
      innerClassName="invite-member-modal"
    >

      <div className="invite-member-modal-container">
        <div className="row invite-row" >
          <CardMessageContainer
            title="Heads up!"
            message="Public members have limited access to this group, but they don’t have to sign up to notata in order to join."
            notice
          />
          <div>

            {canInvite && (
              <div>

                <div className="col-sm-12" style={{ marginTop: "20px" }}>

                  <div className="invite-member-modal__search-header">
                    Invite user to join group
                  </div>

                  <div className="invite-member-modal__search"  >
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                    >
                      <div className="inputform">
                        <InputFormWithButton
                          type="email"
                          name="email"
                          placeholder="Email"
                          handleChange={(e) =>{
                            setIsEmail(validateEmail(e.target.value));
                            setIsDuplicate(
                              group.members?.some(({ user }) => user?.email === e.target.value)
                            )
                          }}
                          btn={true}
                          loading={invitationLoading}
                          buttonType={"submit"}
                          buttonStyle={`search-with-button ${!isEmail || isDuplicate ? ' search-with-button__not-validated' : ''}`}
                          btntext="invite"
                          setBtn={setIsEmail}
                          reference={register({ required: true })}
                          isValid={isEmail}
                        />
                      </div>

                      {isDuplicate && (
                        <div className="is-duplicate">
                          That user is already member of this group
                        </div>
                      )}
                    </form>
                  </div>

                </div>


                <div className="col-sm-12" style={{ marginTop: "20px" }}>

                  <div className="invite-member-modal__search-header">
                    Import users from existing group
                  </div>

                  <div className="invite-member-modal__select-group">
                    <Dropdown
                      title="Group"
                      items={groups}
                      setSelectedItem={item => {
                        setSelectedGroupId(item.id);
                      }}
                    />
                  </div>

                  {
                    selectedGroupId && (
                      <GroupPublicMembersImport
                        fromGroupId={selectedGroupId}
                        canInvite={canInvite}
                        addPublicUser={email => addPublicUserInvitation({email, group, mutation})}
                        preMembers={
                          group?.publicSettings?.invitedUsers
                        }
                      />
                    )
                  }
                </div>

              </div>

            )}
          </div>

        </div>


      {
        (
          group?.publicSettings?.invitedUsers &&
          group?.publicSettings?.invitedUsers?.length !== 0
        ) && (
            <PendingPublicMembers
              pendingMembers={group?.publicSettings?.invitedUsers}
              group={group}
              canRemove={canRemove}
              header="INVITED USERS"
              isPublic={true}
            />
          )
        }

        <PublicMembers
          canInvite={canInvite}
          group={group}
          canRemove={canRemove}
        />

      </div>
    </Modal>
  );
}