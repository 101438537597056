import gql from "graphql-tag";

export default gql`
  mutation publicGroupEvaluationCreate($groupId: ID!, $creativeId: ID!, $email: String!, 
    $input: PublicEvaluationInput!, $family_name: String, $given_name: String, $pin: Float) {
        publicGroupEvaluationCreate(groupId: $groupId, creativeId: $creativeId, email: $email,
            input: $input, family_name: $family_name,given_name: $given_name,pin: $pin) {
          message
      }
  }
`;
