import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import {
  connectionCreate,
  groupSubjectiveScoreAdd,
  evaluationUpdate,
  evaluationCreate,
  groupEvaluationAdd
} from "private/Apollo/Mutations";
import { groupGetV2 } from "private/Apollo/Queries";

// UI COMPONENTS
import { CardComponent, NotataButton } from "Components/NotataComponents";
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";
import SubjectiveScoreModal from "../../../../MyStartups/Modals/SubjectiveScoreModal";

// OTHER COMPONENTS
import EvaluateModal from "../../../../EvaluatePage/EvaluateModal/EvaluateModal";

//CONSTANTS
import { startup_page } from "../../../../../../definitions";

//STYLES
import "./StartupListView.scss";
import "./StartupCard.scss";



// MAIN FUNCTION
export default function StartupListView({
    group,
    adminView,
    history,
    setSelectedStartup,
    setShowComment,
    startups,
    sortByCategory
  }) {

  const [ evaluateModalProperties, setEvaluateModalProperties ] = useState(undefined)
  const [ evaluationLoading, setEvaluationLoading ] = useState(false)

  const [ addingToDealflow, setAddingToDealflow] = useState({})
  const [ subjectiveScoreStartup, setSubjectiveScoreStartup ] = useState(undefined);
  const [ subjectiveScoreModal, setSubjectiveScoreModal ] = useState(false);

  // MUTATIONS
  const mutationOptions = {
    refetchQueries: [
      {
        query: groupGetV2,
        variables: { id: group.id },
      },
    ],
    awaitRefetchQueries: true,
  }

  const [shareScoreAdd, shareScoreAddRes] = useMutation(groupSubjectiveScoreAdd, mutationOptions);
  const [createConnection, createConnectionRes] = useMutation(connectionCreate, mutationOptions);

  const [mutateEvaluationUpdate] = useMutation(evaluationUpdate, mutationOptions);
  const [mutateEvaluationCreate] = useMutation(evaluationCreate, mutationOptions);
  const [evaluationAddToGroup] = useMutation(groupEvaluationAdd, mutationOptions);

  const checkIfCommentUnread = (startup) =>{
    let unreadComments = startup?.log?.filter(({ seen }) => !seen);
    return unreadComments.length > 0
  }

  let settings = group?.settings;
  let hideSummaries = !settings?.showSummaries && !adminView
  let evaluationTemplate = group?.evaluationTemplates && group?.evaluationTemplates[0]

  async function saveEvaluation(newAnswers) {

    setEvaluationLoading(true)

    let evaluation = evaluateModalProperties?.evaluation || {}

    // Maintain state
    setEvaluateModalProperties({
      ...evaluateModalProperties,
      evaluation: {
        ...evaluation,
        answers: newAnswers
      }
    })

    // Save evaluation
    let savedEvaluation;

    // Update existing
    if (evaluation.id) {
      try {
        let variables = {
          id: evaluation.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationUpdate({variables})
        savedEvaluation = res?.data?.evaluationUpdate;
      } catch (error) {
        console.log('error', error)
      }
    }

    // Create new
    if (!evaluation.id) {

      // ... create new evaluation
      try {
        let variables = {
          connectionId: evaluateModalProperties?.connection?.id,
          templateId: evaluateModalProperties?.template?.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationCreate({variables})
        savedEvaluation = res?.data?.evaluationCreate;
      } catch (error) {
        console.log('error', error)
      }

      // ... add evaluation to group
      try {
        let variables = {
          evaluationId: savedEvaluation?.id,
          groupId: group.id,
          creativeId: evaluateModalProperties?.creative?.id,
        };
        await evaluationAddToGroup({ variables });
      } catch(error) {
        console.log("error", error);
      }

    }

    setEvaluationLoading(false)

    // Hide edit modal
    setEvaluateModalProperties(undefined)

    // Open summary modal
    // setSummaryModalContent(savedEvaluation)

  }

  return (
    <>
      <div className="">
        <CardComponent
          style={{
            padding: "0",
            overflow: "hidden",
            overflowX: "scroll"
          }}
        >
          <div className="startup-list-view-container">
            {/*HEADER*/}
            <div className="startup-list-view-container__header">
              <div className="startup-list-view-container__header__company_name">Company name</div>
              <div
                className="startup-list-view-container__header__header-labels"
              >
                {
                  (
                    (
                      !hideSummaries
                      && sortByCategory !== "public"
                      && group?.settings?.addEvaluation
                    ) ||
                    (
                      !hideSummaries
                      && sortByCategory === "public"
                      && group?.publicSettings?.withSubjectiveScores
                    )
                  ) &&
                  (
                    <div className="startup-list-view-container__header__avg_score">
                      {sortByCategory === "public" && (
                        <div>Public</div>
                      )}
                      <div>avg. score</div>
                    </div>
                  )
                }
                {
                  (
                    (
                      !hideSummaries
                      && sortByCategory !== "public"
                      && group?.settings?.addEvaluation && evaluationTemplate
                    ) ||
                    (
                      !hideSummaries
                      && sortByCategory === "public"
                      && group?.publicSettings?.withEvaluationTemplate
                    )
                  ) &&
                  (
                    <div className="startup-list-view-container__header__evaluation">
                      {sortByCategory === "public" && (
                        <div>Public</div>
                      )}
                      <div>avg. evaluation</div>
                    </div>
                  )
                }
              </div>
            </div>

            {
              startups.map((startup, i) => {

                  let matched = group?.startups?.find(({creative}) => creative?.id === startup?.creative?.id)

                  let iHaveEvaluated = matched?.evaluations?.find(evaluation =>
                    evaluation?.createdByUser?.isMe &&
                    evaluation?.templateId === evaluationTemplate?.id
                  )
                  let sharedSubjectiveScore = matched?.subjectiveScores?.find(({ isMe }) => isMe);
                  let evaluationSummary = startup?.evaluationSummaries?.find(({templateId}) => templateId === evaluationTemplate?.id)

                  return (
                    <div
                      key={`startup-list-name-${i}`}
                      className="startup-list-view-container__row"
                    >
                      <div className="startup-list-view-container__row__startup-name-container">
                        <span
                          className="startup-list-view-container__row__startup-name-container__logo"
                          onClick={() => {
                            if (startup.isInMyDealFlow) {
                              history.push(`${startup_page}/company/${startup.connection?.id}`)
                            }
                          }}>
                          <StartupLogo
                            url={startup.creative?.logo}
                            startupName={startup.creative?.name}
                            size="mini"
                          />
                        </span>
                        <div
                          className={`startup-list-view-container__row__startup-name-container__startup-name ${startup.isInMyDealFlow && 'startup-list-view-container__row__startup-name-container__startup-name__underlined'}`}
                          onClick={() => {
                            if (startup.isInMyDealFlow) {
                              history.push(`${startup_page}/company/${startup.connection?.id}`)
                            }
                          }}
                        >
                          {startup.creative?.name}
                        </div>

                        { // WITH CHAT
                          settings?.chat && (
                            <i
                              className={
                                checkIfCommentUnread(startup)
                                  ? "fa fa-comment unread-message"
                                  : "far fa-comment no-message"
                              }
                              aria-hidden="true"
                              onClick={() =>{
                                setShowComment(true);
                                setSelectedStartup(startup);
                              }}
                            />
                          )
                        }

                      </div>



                      <div className="startup-list-view-container__row__button-container">



                        {
                          // ADD TO DEALFLOW
                          !startup.isInMyDealFlow && (
                            <div className="startup-list-view-container__row__button-container__buttons">
                              <NotataButton
                                size="large"
                                bgcolor="primary_color big"
                                startIcon="add"
                                text="Add to deal flow"
                                loading={addingToDealflow[startup?.creative?.id]}
                                style={{width: "300px"}}
                                onClick={async () => {

                                  if (addingToDealflow[startup?.creative?.id]) {
                                    return;
                                  }

                                  setAddingToDealflow({
                                    ...addingToDealflow,
                                    [startup?.creative?.id]: true
                                  })

                                  let variables = {
                                    groupId: group.id,
                                    creativeId: startup?.creative?.id,
                                  };
                                  await createConnection({ variables });

                                  setAddingToDealflow({
                                    ...addingToDealflow,
                                    [startup?.creative?.id]: false
                                  })

                                }}
                              />
                            </div>
                          )
                        }

                        { // EVALUATE && SCORE BUTTONS
                          (settings?.addEvaluation && startup.isInMyDealFlow) && (
                            <div className="startup-list-view-container__row__button-container__buttons">

                              {
                                evaluationTemplate && (
                                  <>
                                    {
                                      iHaveEvaluated && (
                                        <NotataButton
                                          text={`${iHaveEvaluated?.summary?.scorePercent || '0'}%`}
                                          buttonStyle="outlined-green big"
                                          onClick={() => {

                                            let modalProperties = {
                                              connection: startup.connection,
                                              creative: startup.creative,
                                              template: evaluationTemplate,
                                              evaluation: iHaveEvaluated
                                            }
                                            setEvaluateModalProperties(modalProperties)
                                          }}
                                        />
                                      )
                                    }

                                    {
                                      !iHaveEvaluated && (
                                        <NotataButton
                                          bgcolor="primary_color big"
                                          text="Evaluate"
                                          onClick={() => {
                                            let modalProperties = {
                                              connection: startup.connection,
                                              creative: startup.creative,
                                              template: evaluationTemplate,
                                            }
                                            setEvaluateModalProperties(modalProperties)
                                          }}
                                        />
                                      )
                                    }
                                  </>
                                )
                              }



                              {
                                sharedSubjectiveScore?.score && (
                                  <NotataButton
                                    bgcolor="outlined score-btn medium"
                                    text={sharedSubjectiveScore.score}
                                    onClick={() => {
                                      setSubjectiveScoreStartup(startup);
                                      setSubjectiveScoreModal(true);
                                    }}
                                  />
                                )
                              }

                              {
                                !sharedSubjectiveScore?.score && (
                                  <NotataButton
                                    bgcolor="primary_color medium" text="score"
                                    onClick={() => {
                                      setSubjectiveScoreStartup(startup);
                                      setSubjectiveScoreModal(true);
                                    }}
                                  />
                                )
                              }
                            </div>
                          )
                        }

                        { // EVALUATION SUMMARIES
                          !hideSummaries &&
                          (
                            <div className="startup-list-view-container__row__button-container__scores">

                              {
                                (
                                  (
                                    !hideSummaries
                                    && sortByCategory !== "public"
                                    && group?.settings?.addEvaluation
                                  ) ||
                                  (
                                    !hideSummaries
                                    && sortByCategory === "public"
                                    && group?.publicSettings?.withSubjectiveScores
                                  )
                                ) && (
                                  <div className="startup-list-view-container__row__button-container__scores__avg-score">
                                    <div className="startup-list-view-container__row__button-container__scores__avg-score__score">

                                      {
                                        sortByCategory === "public" && (
                                          <span>
                                            {startup?.publicAvgSubjectiveScore?.toFixed(1) || '0.0'}
                                            <span style={{color: "silver"}}>
                                              {" / "}
                                              {startup?.publicSubjectiveScores?.length || '0'}
                                            </span>
                                          </span>
                                        )
                                      }

                                      {
                                        sortByCategory !== "public" && (
                                          <span>
                                        {startup?.avgSubjectiveScore?.toFixed(1) || '0.0'}
                                      </span>
                                        )
                                      }

                                    </div>
                                  </div>
                                )
                              }


                              {
                                (
                                  (
                                    sortByCategory !== "public"
                                    && group?.settings?.addEvaluation && evaluationTemplate) ||
                                  (
                                    sortByCategory === "public" && group?.publicSettings?.withEvaluationTemplate)
                                ) && (
                                  <div className="startup-list-view-container__row__button-container__scores__evaluation">
                                    <div className="startup-list-view-container__row__button-container__scores__evaluation__score">

                                      {
                                        sortByCategory === "public" && (
                                          <span>
                                        {
                                          startup?.publicEvaluationSummaries &&
                                          startup?.publicEvaluationSummaries[0]?.averagePercentageScore || '0'}%
                                      </span>
                                        )
                                      }


                                      {
                                        sortByCategory !== "public" && (
                                          <span>
                                        {evaluationSummary?.averagePercentageScore || '0'}%
                                      </span>
                                        )
                                      }

                                    </div>
                                  </div>
                                )
                              }




                            </div>
                          )
                        }

                      </div>

                    </div>
                  )
                }
              )}
          </div>
        </CardComponent>
      </div>



      {/* SUBJECTIVE SCORE MODAL */}
      {
        subjectiveScoreModal && (
          <SubjectiveScoreModal
            connection={subjectiveScoreStartup?.connection}
            close={() => setSubjectiveScoreModal(false)}
            loading={shareScoreAddRes.loading}
            submit={async () => {
              let haveShareScore = subjectiveScoreStartup?.subjectiveScores?.find(({ isMe }) => isMe);
              if (!haveShareScore) {
                if (shareScoreAddRes.loading) {
                  return;
                }
                let variables = {
                  groupId: group.id,
                  creativeId: subjectiveScoreStartup?.creative?.id,
                };
                await shareScoreAdd({ variables });
                setSubjectiveScoreModal(false)
              } else {
                setSubjectiveScoreModal(false)
              }
            }}
          />
        )
      }


      {/* EVALUATE MODAL */}
      {
        evaluateModalProperties && (
          <EvaluateModal
            creative={evaluateModalProperties?.creative}
            template={evaluateModalProperties?.template}
            evaluation={evaluateModalProperties?.evaluation}
            connectionId={evaluateModalProperties?.connection?.id}
            close={() => setEvaluateModalProperties(undefined)}
            save={saveEvaluation}
            loading={evaluationLoading}
          />
        )
      }
    </>
  );
}