import gql from "graphql-tag";

export default gql`
    query connectionGet($id: ID!) {
        connectionGet(id: $id) {
            id
            name
            createdAt
            updatedAt
            starred
            creativeId

            impactGoals {
                key
                val
            }
            avgSubjectiveScore
            subjectiveScores {
                createdByUser {
                    family_name
                    given_name
                    email
                    isMe
                }
                createdBy
                createdAt
                score
                isMe
            }

            creative {
                id
                name
                accountId
                sharedWithEmail
                logo
                canEdit
                answers {
                    inputType
                    sectionId
                    sectionName
                    questionId
                    questionName
                    questionId
                    val
                    sid
                }
            }

            tags {
                tagGroupId
                id
                name
                group {
                    id
                    name
                }
            }

            funnelTags {
                id
                funnelGroupId
                name
                index
                group {
                    id
                    name
                    funnelTags {
                        id
                        name
                        index
                        color
                    }
                }
            }
            evaluationSummaries {
                templateId
                templateName
                averagePercentageScore
                highestScore
                lowestScore
                submissions
            }

            evaluations {
                id
                isMe
                createdAt
                templateId
                template {
                    id
                    name
                    description
                    sections {
                        id
                        name
                        description
                        questions {
                            id
                            name
                            description
                        }
                    }
                }
                createdByUser {
                    family_name
                    given_name
                    email
                    isMe
                }
                answers {
                    inputType
                    questionId
                    sectionId
                    sid
                    val
                }

                summary {
                    scorePercent
                    sections {
                        sectionId
                        sectionName
                        scorePercent
                        scorePerAnswer {
                            questionName
                            questionId
                            possibleScore
                            score
                            answer
                        }
                    }
                }

            }

            externalEvaluationSummaries {
                templateId
                templateName
                submissions
                averagePercentageScore
            }

            externalEvaluations {
                id
                createdByEmail
                template {
                    id
                    name
                    description
                    sections {
                        id
                        name
                        description
                        questions {
                            id
                            name
                            description
                        }
                    }
                }
                summary {
                    scorePercent
                    sections {
                        sectionId
                        sectionName
                        scorePercent
                        scorePerAnswer {
                            questionName
                            questionId
                            possibleScore
                            score
                            answer
                        }
                    }
                }
            }
            groupSharingInfo {
                iAmOwner
                iAmAdmin
                iHaveSharedStartup

                sharedBy {
                    isMe
                    family_name
                    given_name
                    email
                }

                group {
                    id
                    name
                }

                evaluationSummaries {
                    templateId
                    templateName
                    submissions
                    averagePercentageScore
                }


                evaluations {
                    id
                    isMe
                    createdAt
                    templateId
                    template {
                        id
                        name
                        description
                        sections {
                            id
                            name
                            description
                            questions {
                                id
                                name
                                description
                            }
                        }
                    }
                    createdByUser {
                        family_name
                        given_name
                        email
                        isMe
                    }
                    answers {
                        inputType
                        questionId
                        sectionId
                        sid
                        val
                    }

                    summary {
                        scorePercent
                        sections {
                            sectionId
                            sectionName
                            scorePercent
                            scorePerAnswer {
                                questionName
                                questionId
                                possibleScore
                                score
                                answer
                            }
                        }
                    }
                }


                subjectiveScores {
                    isMe
                }
            }
            log {
                id
                isMe
                createdAt
                updatedAt
                createdByUser {
                    family_name
                    given_name
                }
                logType
                dataPairs {
                    val
                    key
                }
            }
            externalResources{
                id
                link
                label
                createdAt
            }
        }
    }
`;
