import React from 'react';
import { Card } from "Components/NotataComponents";
import CardMessageContainer from 'private/Pages/Group/Components/CardMessageContainer';
import "./AccessDenied.scss";

export default function AccessDenied(){
    return(
      <div className="access-denied">
        <Card
          containerClassName="access-denied__content"
          headerClassName="access-denied__content__header"
        >
          <CardMessageContainer
            title="Access Denied"
            message={`We’re sorry, but this group is no longer available.`}
            error
          />
        </Card>
      </div>
    )
}