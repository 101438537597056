import gql from "graphql-tag";
import { reportSettingsFragments } from "../Fragments";

export default gql`
  mutation reportSettingsSet($input: ReportSettingsInput!) {
    reportSettingsSet(input: $input) {
      ...reportSettingsFields
    }
  }
  ${reportSettingsFragments}
`;