import gql from "graphql-tag";

export default gql`
  query {
    groupsGetV2 {
      id
      name
      iAmAdmin
      description
      createdAt
      updatedAt
      createdByUser {
        email
        given_name
        family_name
      }
      memberCount
      startupCount
      settings {
        chat
        isPublic
        showUsers
        showScores
        showSummaries
        addStartup
        addUser
        addEvaluation
      }
    }
  }
`;
