import React, {useState, useEffect} from "react";

// API STUFF
import { connectionTagAdd, connectionTagRemove, tagCreate } from "private/Apollo/Mutations";
import { useMutation } from "@apollo/client";

// UI COMPONENTS
import { Modal, TagsModal } from "Components/NotataComponents";

// MAIN FUNCTION
export default function AddTagsForConnectionModal({ connection, close }) {
 
  //States
  const [addingTagsModal, setAddingTagsModal] = useState(false);
  const [group, setGroup] = useState({});
  const [input, setInput] = useState(undefined);
  const [showError, setShowError] = useState(false);

  // Mutations
  const [addTagMutation, addTagMutationResponse] = useMutation(connectionTagAdd);
  const [removeTagMutation, removeTagMutationResponse] = useMutation(connectionTagRemove);
  const [tagCreateMutation, { loading } ] = useMutation(tagCreate);

  // Effects
  useEffect(()=>{
    if(group?.name){
      setShowError(false);
    }
  }, [group])

  // removeTag Function
  async function removeTag(tagId) {
    let variables = {
      connectionId: connection.id,
      tagId: tagId,
    };
    let optimisticResponse = {
      __typename: "Mutation",
      connectionTagRemove: {
        ...connection,
        tags: [
          ...connection.tags
            .filter(({ id }) => id !== tagId)
            .map(t => ({
              ...t,
              index: null,
              description: null,
              createdBy: "tmp",
              createdAt: 0,
            })),
        ],
        __typename: "Connection",
      },
    };
    removeTagMutation({ variables });
  }

   // addTag Function
  async function addTag(tag) {
    let variables = {
      connectionId: connection.id,
      tagId: tag.id,
    };

    let optimisticResponse = {
      __typename: "Mutation",
      connectionTagAdd: {
        ...connection,
        tags: [...connection.tags, tag],
        __typename: "Connection",
      },
    };

    addTagMutation({
      variables
    });
  }

  // closeModal Function
  const closeModal = () =>{
    if(addingTagsModal){
      setGroup({});
      setShowError(false);
      return setAddingTagsModal(false);
    }
    close();
  }
 
  // createNewTag Function
  const createNewTag = async () =>{
    if(group?.id && input){
      let variables = {
        tagGroupId: group.id,
        input: {
          name: input,
        },
      };
      try {
        let {data : { tagCreate } } = await tagCreateMutation({variables});
        if(tagCreate){
          addTag(tagCreate);
        }
      } catch (error) {
        console.log("error", error);
      }
      setAddingTagsModal(false);
      setGroup({});
      setInput(undefined);
    } else {
      setShowError(true);
    }
  }

  // handleSubmit Function
  const handleSubmit = () =>{
    if(addingTagsModal){
      return createNewTag();
    }
    close();
  }

  // Constants
  let title = 'Add Tags';
  let submitTxt = 'Save';

  if(addingTagsModal){
    title = 'Adding new tag';
    submitTxt = 'Create';
  }

  return (
    <Modal
      title={title}
      submit={(e)=>{
        if(e.key==="Enter"){
            return null
        }else{ return handleSubmit()}
      }}
      close={closeModal}
      loading={loading}
      submitTxt={submitTxt}
      disableFoot={false}
      closeTxt="Cancel"
      children={
        <TagsModal
          preSelectedTags={connection?.tags || []}
          removeTag={removeTag}
          addTag={addTag}
          addNewTagModal={setAddingTagsModal}
          addingTag={addingTagsModal}
          setGroup={setGroup}
          group={group}
          setInput={setInput}
          showError={showError}
          parentLoading={addTagMutationResponse.loading || removeTagMutationResponse.loading}
        />
      }
    />
  );
}
