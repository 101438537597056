import React, { useState } from "react";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { accountGet, accountInvitationsGet } from "../../../Apollo/Queries";
import {
  accountInviteCreate,
  accountInviteDelete,
  accountUserRemove,
} from "../../../Apollo/Mutations";

// UI COMPONENTS
import {
  BreadCrumbs,
  Loader,
  InputFormWithButton,
  CardComponent,
} from "Components/NotataComponents";

// HELPER COMPONENTS
import YourTeamInvitations from "./SettingsYourTeamInvitations";
import TeamMembers from "./SettingsYourTeamMembers";

// STYLES
import "./SettingsYourTeam.scss";

// DEFINITIONS
import { settings } from "definitions";

// OTHERS
import validateEmail from "../../../../utils/validateEmail";

// MAIN FUNCTION
export default function YourTeam({ history }) {
  // States
  const [isEmail, setIsEmail] = useState(false);
  const [email, setEmail] = useState("");

  const [showBtn, setShowBtn] = useState(false);
  const [teamLoaders, setTeamLoaders] = useState({});
  const [pendingLoaders, setPendingLoaders] = useState({});


  // Queries
  const accountQuery = useQuery(accountGet);
  const invitationsQuery = useQuery(accountInvitationsGet);

  const [inviteUser, inviteUserRes] = useMutation(accountInviteCreate, {
    refetchQueries: [{ query: accountInvitationsGet }],
  });



  // invite function
  const inviteHandle = async e => {
    e.preventDefault();
    if (!isEmail || !email) {
      return;
    }

    if (inviteUserRes.loading) {
      return;
    }

    let variables = { email: email };
    try {
      await inviteUser({ variables });
    } catch (error) {
      console.log("error", error);
    }

    setEmail("");
    setIsEmail(false);
    setShowBtn(false);
  };

  // Data maps
  let account = accountQuery?.data?.accountGet || {};
  let invitations = invitationsQuery?.data?.accountInvitationsGet || [];

  let isLoading =
    (!accountQuery.data && accountQuery.loading) ||
    (!invitationsQuery.data && invitationsQuery.loading);

  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[{ seperator: ">", page1: "YOUR TEAM" }]}
      />

      <div className="settingsYour-team-container">
        <CardComponent
          containerClassName="settingsYour-team-container__card col-md-12"
          padding="large"
          title="Your team"
          titleSize="medium"
          backLink={() => history.push(settings)}
          content={
            <div className="settingsYour-team">

              {isLoading && <Loader />}

              <div data-tut="team-members">
                <TeamMembers
                  teamMembers={account.members}
                />
              </div>

              <YourTeamInvitations
                invitations={invitations}
              />


              <hr />
            </div>
          }
          footer={
            <div data-tut="invite-new-member ">
              <div className="settingsYour-team__email">Invite new member</div>
              <form onSubmit={inviteHandle}>
                <div className="settingsYour-team__pending-invite-container">
                  <InputFormWithButton
                    placeholder="name@email.com"
                    name="email"
                    type="email"
                    isEmail
                    fullWidth
                    inputformstyle="settingsYour-team__pending-invite-container__inputfield"
                    value={email}
                    btntext="invite"
                    icon={"add"}
                    btn={showBtn}
                    setBtn={setShowBtn}
                    buttonType={"submit"}
                    loading={inviteUserRes.loading}
                    handleChange={e => {
                      setIsEmail(validateEmail(e.target.value));
                      setEmail(e.target.value);
                    }}
                    isValid={isEmail}
                  />
                </div>
              </form>
            </div>
          }
        />
      </div>
    </>
  );
}
