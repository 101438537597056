import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { evaluationTemplateCreate } from "private/Apollo/Mutations";

// UI COMPONENTS
import {TextBox, Modal} from "Components/NotataComponents";

// DEFINITIONS
import { evaluation_template_page } from "definitions";


// MAIN FUNCTION
export default function CreateNewTemplateModal({ history, close }) {
  // STATES
  const [name, setName] = useState('');
  // MUTAIONS
  const [createTemplate, createTemplateRes] = useMutation(
    evaluationTemplateCreate
  );

  const submit = async () =>{
    let res;
    try {
      let variables = { input: { name } };
      res = await createTemplate({ variables });
    } catch (error) {
      console.log("error", error);
    }

    let id = res?.data?.evaluationTemplateCreate?.id;

    if (id) {
      history.push(`${evaluation_template_page}/${id}`);
    } else {
      close();
    }
  }

  return (
    <Modal
      title="New Evaluation Template"
      loading={createTemplateRes.loading}
      submit={() => {
        submit();
      }}
      close={close}
      submitTxt="Save"
      closeTxt="Cancel"
      children={
        <TextBox
          name="name"
          textBoxStyle="funnels-container__create-new-funnel-text"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Evaluation Template Name"
          onKeyDown={e=>{
            e.stopPropagation();
            if(e.key === "Enter"){
              submit();
            }
          }}
        />
      }
    />
  );
}
