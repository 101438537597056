import React, { useState } from "react";

import { useQuery } from "@apollo/client";

// Library Components
import { useForm } from "react-hook-form";

// API Queries		
import { groupsGetV2 } from "private/Apollo/Queries";

// CUSTOM COMPONENTS
import { Dropdown, NotataButton, InputForm, InputFormWithButton } from "Components/NotataComponents";

// Others
import validateEmail from "../../../../../utils/validateEmail";

// Main Function
export default function Members({ data, setData }) {
  // States
  const [isEmail, setIsEmail] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [selected, setSelected] = useState();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false)

  // Queries
  let groupsQuery = useQuery(groupsGetV2);

  // Data maps
  let items = groupsQuery?.data?.groupsGetV2 || [];

  // Form stuff
  const { register, handleSubmit, setValue } = useForm();
 
  
  // Functions
  async function onSubmit({ email }) {
    console.log(isEmail,email)
    if (!isEmail ) {
      return;
    }
    setData({
      ...data,
      members: [...data.members, email.toLocaleLowerCase().trim()],
    });
    setValue("email", "");
    setIsEmail(false);
    setLoading(true)
    setIsDuplicate(false)
  }

  return (
    <div className="startup-container">
      {/* add-startups */}
        <div className="add-startups row">
          {/* add-text */}
          <div className="col-sm-12 col-xs-12 add-text">
            Add members from an existing group:
          </div>
          {/* drop-down */}
          <div className="col-sm-12 col-xs-12 group-drop-down">
            <Dropdown
              title="Group"
              items={items}
              setSelectedItem={item => {
                setSelected(item);
              }}
            />
          </div>
        </div>

        <div className="startup-list">
          {selected?.members?.map(({ user }) => (
            <div className="row startup" key={user.email}>
              <div className="col-sm-8 col-xs-8 email">
                {/*<i className="fal fa-times" />*/}
                <div>
                  {user.given_name} {user.family_name}
                </div>
                <div className="user-email">{user.email}</div>
              </div>
              <div
                className="col-sm-4 col-xs-4 button invite-button"
                onClick={() => {
                  setData({
                    ...data,
                    members: [...data.members, user.email],
                  });
                }}
              >
                {data?.members?.some(m => m === user.email)
                  ? "invited"
                  : "+ Invite"}
              </div>
            </div>
          ))}
        </div>

        <form className="search_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="search">
            
            {/* search-box */}
            {/* <InputForm 
              type="email"
              name="email"
              placeholder="name@email.com"
              reference={register}
              handleInputChange={(value, name) =>{
                  setIsEmail(validateEmail(value));
                  setIsDuplicate(data?.members?.some(member => member === value));
              }
              }  
            /> */}
            <InputFormWithButton
              type="email"
              name="email"
              placeholder="name@email.com"
              isEmail
              reference={register({required:true})}
              handleChange={(value, name) =>{
                  setIsEmail(validateEmail(value.target.value));
                  setIsDuplicate(data?.members?.some(member => member === value.target.value));
              }
              } 
              btn={isEmail}
              setBtn={setIsEmail}
              btntext={"invite"}
              // loading={loading}
              buttonType="submit"
            />
            {/* {showError && !isEmail && (
              <div className="invalid-email">
                Please enter valid email
              </div>
            )} */}
          </div>

          {isDuplicate && (
            <div className="is-duplicate">
              That user is already member of this group
            </div>
          )}

          {/* {isEmail && !isDuplicate && (
            <div className="btnctn">
              <div className="submit-button-container">
                <NotataButton
                  size="x_small"
                  bgcolor="primary_color"
                  loading={loading}
                  buttonType="submit"
                  text="invite"
                />
              </div>
            </div>
          )} */}
        </form>
        <div className="startup-list">
        <hr />
        {data?.members.map(email => {
          return (
            <div className="row startup" key={email}>
              <div className="col-sm-8 col-xs-8 name">
                <i
                  className="fal fa-times"
                  onClick={() => {
                    setData({
                      ...data,
                      members: data?.members.filter(member => member !== email),
                    });
                  }}
                />
                <span>{email}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
