import React from "react";

// Styles
import "./Loader.scss";

// Main Function
export const Loader = ({ style, size, noPadding, addPadding }) => {

  return (
    <div
      style={style}
      className={`
        loader__main
        loader__${size ? size : "small"}
        ${addPadding && "loader__with_margin"}
        ${noPadding && "loader__no_margin"}
      `}
    >
      <i className={`fa fa-spinner fa-spin`} />
    </div>
  );
};
