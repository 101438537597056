import React  from 'react';
import Content from "./Content";
import './GroupPlugin.scss';

export default function GroupPlugin({ match }) {
  return (
    <Content
      groupId={match?.params?.id}
    />
  )
}