import React, { useState } from 'react'
import "./InputFormWithButton.scss"
import classnames from "classnames";
import { NotataButton } from '..';
import { email } from 'utils/regex';

export  function InputFormWithButton({
     name,
     value,
     placeholder,
     type,
     buttonType,
     onClick,
     fullWidth,
     handleChange,
     onKeyPress,
     loading,
     btntext,
     icon,
     btn,
     reference,
     setBtn,
     inputformstyle,
     isValid,
     isEmail
   }) {
  const [error, setError] = useState(false);

  async function onChangeHandler(e) {
    if(handleChange) {
      handleChange(e)
    }

    if (!isEmail) {
      return
    }

    if(e.target.value.match(email)){
      setError("")
      setBtn(true)
    }else if (!e.target.value) {
      setError("")
    } else {
      setError("Please enter a valid email")
      setBtn(false)
    }
  }

  return (
    <>
      <div className={`input-with-button ${inputformstyle}`}>
        <div
          className={classnames(
            fullWidth
              ? "input-with-button__input_container input_log_cont"
              : "input-with-button__inputDefaultWidth",
            "input-with-button__input_container input_log_cont",
            error && "input-with-button__redStyle"
          )} >
          <input
            type={type}
            className={classnames( "input-with-button__input_class")}
            name={name}
            placeholder={placeholder}
            autoComplete="off"
            value={value}
            onChange={onChangeHandler}
            onKeyPress={onKeyPress}
            ref={reference}
          />
        </div>

        {
          error && (
            <p className="input-with-button__valError" >
              {error}
            </p>
          )
        }


        {
          btn && (
            <NotataButton
              size="input_with_button"
              bgcolor={isValid ? "primary_color" : "grey_color"}
              loading={loading}
              buttonType={buttonType}
              startIcon={icon}
              text={btntext}
              onClick={onClick}
              disabled={!isValid}
            />
          )
        }
      </div>
    </>
  )
}
