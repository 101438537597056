import React, { useEffect, useState } from "react";
import { TabArrValue } from "./TabArrValue";
import { Tabsection as TabsComponent } from "Components/NotataComponents";

export default function TabSection({
    funnelGroups,
    tabValue,
    setTabValue,
    selectedFunnelGroup,
    setSelectedFunnelGroup
  }) {

  const [kanbanDropDown, setKanbanDropDown] = useState([]);
  const [optionalFilter, setOptionalFilter] = useState();
  const [kanbanPopup, setKanbanPopup] = useState(undefined);

  const tabArr = TabArrValue(
    setKanbanPopup,
    kanbanPopup,
    kanbanDropDown,
    setSelectedFunnelGroup,
    selectedFunnelGroup,
    tabValue
  );

  // Kanban funnels dropdown
  useEffect(() => {
    const groupArr = [];
    for (let funnelGroup of funnelGroups || []) {
      if (funnelGroup?.funnelTags?.length > 0) {
        groupArr.push(funnelGroup?.name);
      }
    }
    setKanbanDropDown(groupArr);
  }, [funnelGroups]);


  //handle Tab change
  const handleTab = val => {
    setTabValue(val);
    if (val === "spreadsheet") {
      setKanbanPopup(false)
    }
    if (val === "kanban") {
      setOptionalFilter("")
    }
  };

  return (
    <TabsComponent
      style={{overflow: "visible"}}
      tabFuc={val => handleTab(val)}
      tabArr={tabArr}
      tabValue={tabValue || tabArr[1]?.value}
    />
  )

}