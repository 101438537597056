import moment from "moment";

const timeStamp = unixTime => {
  return (
    unixTime
      ? `${moment(unixTime, 'x').format('lll')}`
      : 'never'
  )
}

export default timeStamp;