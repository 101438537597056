import React from "react";

// UI Components
import { NotataButton } from "Components/NotataComponents";

// OTHERS
import { startup_page } from "definitions";
import { getVal } from "../../../../private/Pages/commonFunctions";

// Main Function
export default function PublicCreativeUpdate({ notification, history }) {
  let connectionId = getVal(notification, "connectionId");

  return (
    <>
      <div className="notifications-container-new__notification__action-buttons">
        {/* View Button */}
        <NotataButton
          size="x_small"
          bgcolor="primary_color"
          text="view"
          onClick={() => {
            history.push(`${startup_page}/company/${connectionId}?tab=1`);
          }}
        />
      </div>
    </>
  );
}
