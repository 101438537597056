import React, { useEffect } from "react";


export const FrontPage = () => {


  useEffect(() => {
    window.location.href = "https://site.notata.io";
  }, []);

  return (
    <div>
      
    </div>
  );
};
