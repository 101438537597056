import gql from "graphql-tag";

export default gql`
    fragment ConnectionBasicFields on Connection {
        id
        name
        createdAt
        updatedAt
        starred
        creativeId
        impactGoals {
            key
            val
        }
        avgSubjectiveScore
    }
`




