import React, { useState } from "react";
import { Link } from "react-router-dom";

// API STUFF
import { useQuery } from "@apollo/client";
import { connectionsGet } from "private/Apollo/Queries";

// MAIN COMPONENTS
import SubjectiveScoreModal from "../../MyStartups/Modals/SubjectiveScoreModal";
import {
  NotataButton,
  Loader,
  CardComponent,
} from "Components/NotataComponents";

// DEFINITIONS
import { startup_page } from "definitions";

// OTHERS
import { SvgComp } from "../../commonFunctions";

// MAIN FUNCTION
export default function DashboardMyStartups({ history }) {
  // STATES
  const [editScoreForConnection, setEditScoreForConnection] = useState(
    undefined
  );

  let variables = {
    filters: {
      limit: 3,
      sortBy: "UPDATED_AT",
      sortDirection: "DESC",
    },
  };
  // QUERIES
  const { data, loading } = useQuery(connectionsGet, { variables });

  // DATA MAPS
  let connections = data?.connectionsGet?.records || [];

  return (
    <CardComponent
      containerClassName="dashboard-container__my-startups"
      padding="small"
      data-tut="my-startups"
      title="My startups"
      titleSize="small"
      content={
        <>
          {!data && loading && <Loader />}
          <div className="dashboard-container__my-startups__data-container">
            <div className="dashboard-container__my-startups__data-container__data data-entry-heading">
              <div className="dashboard-container__my-startups__data-container__data__heading">
                Name
              </div>
              <div className="dashboard-container__my-startups__data-container__data__heading">
                Stage
              </div>
              <div className="dashboard-container__my-startups__data-container__data__heading">
                Subjective Score
              </div>
            </div>

            {connections.map(connection => {
              let scores = connection.subjectiveScores || [];
              let score = "n/a";

              if (scores.length === 1) {
                score = scores[0].score.toFixed(1);
              }

              if (scores && scores.length && scores.length !== 1) {
                score = (
                  scores.reduce((a, b) => a.score + b.score) / scores.length
                ).toFixed(1);
              }
              let tagSet;
              if (connection.funnelTags.length) {
                let highest = connection.funnelTags.reduce(
                  (max, tag) => (tag.index > max ? tag.index : max),
                  connection.funnelTags[0].index
                );
                tagSet = connection?.funnelTags?.find(
                  ({ index }) => index === highest
                );
              }
              return (
                <div
                  className="dashboard-container__my-startups__data-container__data-entry"
                  key={connection.id}
                >
                  <div className="dashboard-container__my-startups__data-container__data-entry__startup">
                    <Link
                      to={`${startup_page}/company/${connection.id}`}
                      className="link_underline"
                    >
                      <div className="dashboard-container__my-startups__data-container__data-entry__startup__name">
                        <div className="big-card">
                          {connection?.creative?.name}
                        </div>
                        <div className="small-card">
                          {connection?.creative?.name?.length < 8
                            ? connection?.creative?.name
                            : `${connection?.creative?.name?.slice(0, 9)} ...`}
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="dashboard-container__my-startups__data-container__data-entry__funnels-entry">
                    <div className="dashboard-container__my-startups__data-container__data-entry__funnels-entry__data">
                      {tagSet ? (
                        <SvgComp index={tagSet.index} color={tagSet.color} />
                      ) : (
                        ""
                      )}
                      <div className="dashboard-container__my-startups__data-container__data-entry__funnels-entry__data__funnel-type">
                        <div className="small-card">
                          {tagSet
                            ? tagSet.name.length < 5
                              ? tagSet.name
                              : `${tagSet.name.slice(0, 7)} ...`
                            : "n/a"}
                        </div>
                        <div className="big-card">
                          {tagSet ? tagSet.name : "n/a"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard-container__my-startups__data-container__data-entry__subjective-score-container">
                    <div className="dashboard-container__my-startups__data-container__data-entry__subjective-score-container__data">
                      <div className="dashboard-container__my-startups__data-container__data-entry__subjective-score-container__data__score">
                        {score ? score : "n/a"}
                      </div>
                      <div className="dashboard-container__my-startups__data-container__data-entry__subjective-score-container__data__change">
                        <NotataButton
                          size="x_small_slim"
                          bgcolor="primary_color"
                          text="set"
                          onClick={() => setEditScoreForConnection(connection)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      }
      footer={
        <>
          <Link className="link_underline" to={startup_page}>
            <NotataButton
              color="primary"
              text="More STARTUPS"
              buttonStyle="text_button"
            />
          </Link>
          {editScoreForConnection && (
            <SubjectiveScoreModal
              connection={editScoreForConnection}
              close={() => setEditScoreForConnection(undefined)}
            />
          )}
        </>
      }
    />
  );
}
