import React, { useEffect, useState } from "react";

// API
import { useLazyQuery, useQuery } from "@apollo/client";
import { connectionPagesGet } from "private/Apollo/Queries";
import {omit} from "lodash";

//STYLES
import styles from "./Paginator.module.css";


function getCleanFilterData(filters) {
  let clean = {};
  for (let key in filters) {
    if (
      (filters[key] && filters[key].length) ||
      (typeof filters[key] === "boolean" && filters[key])
    ) {
      clean[key] = filters[key];
    }
  }
  // just to keep filters clean with ids only
  if (filters?.tags && filters?.tags.length > 0) {
    clean.tags = filters.tags.map(tag => tag?.id);
  }
  return clean;
}

export default function Paginator({ currentPage, setCurrentPage, filters }) {

  let omitFields = [
    "funnelTag",
    "sortBy",
    "sortDirection",
    "tags"
  ];

  let cleanFilters = {};
  if (filters) {
    cleanFilters = getCleanFilterData(filters)
  }

  const [getData, { data, loading }] = useLazyQuery(connectionPagesGet);

  useEffect(() => {
    if (filters) {
      let variables = {
        filters: cleanFilters
      }
      getData({variables})
    }
  }, [filters])

  /* Updates pageNo on currentPage change */
  useEffect(() => {
    if(currentPage) {
      setEditedPage(currentPage.pageNo)
    }
  }, [currentPage])

  const currentPageNo = (currentPage && currentPage.pageNo) || 1;
  const [editedPage, setEditedPage] = useState(currentPageNo);


  // Loader
  if (loading) {
    return <span />;
  }

  // Data
  let pages = data?.connectionPagesGet || [];

  const handleChange = (e) =>{
    const value = parseInt(e.target.value);
    if(value > pages?.length || value <= 0 || isNaN(value) || !value){
      return setEditedPage(pages?.length);
    }
    setEditedPage(value)
  }

  const handlePage = (page) => {
    localStorage.setItem("paginator", JSON.stringify(page))
    setCurrentPage(page);
  }

  return (
    <div className={styles.container}>
      <span>PAGE</span>
      <i
        className={`fa fa-chevron-left ${styles.leftArrow}`}
        onClick={() => {
          let prevPage = pages?.find(
            ({ pageNo }) => pageNo === editedPage - 1
          );
          if(editedPage > 1){
            setEditedPage(editedPage - 1);
            handlePage(prevPage)
          }
        }}
      />
      <input
        type="text"
        autoComplete="off"
        className={styles.paginatorInput}
        value={editedPage}
        onFocus={e => e.target.select()}
        onChange={e=>handleChange(e)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            let selectedPage = pages?.find(
              ({ pageNo }) => pageNo === parseInt(editedPage)
            );
            if (selectedPage) {
              handlePage(selectedPage)
            } else {
              handlePage(undefined);
            }
          }
        }}
        onBlur={() => {
          // let selectedPage = pages.find(
          //   ({ pageNo }) => pageNo === parseInt(editedPage)
          // );
          // if (selectedPage) {
          //   handlePage(selectedPage)
          // } else {
          //   handlePage(undefined);
          // }
        }}
      />

      <span> of </span>
      <span style={{ marginLeft: "10px" }}>{pages.length}</span>
      <i
        className={`fa fa-chevron-right ${styles.rightArrow}`}
        onClick={() => {
          let nextPage = pages?.find(
            ({ pageNo }) => pageNo === editedPage + 1
          );
          if(editedPage < pages.length){
            setEditedPage(editedPage + 1);
            nextPage && handlePage(nextPage);
          }
        }}
      />
    </div>
  );
}
