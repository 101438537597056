import gql from "graphql-tag";
import { grouplogItemFragments } from "../Fragments";

export default gql`
  mutation groupLogUpdate($id: ID!, $input: GroupLogInput!) {
    groupLogUpdate(id: $id, input: $input) {
      ...grouplogItemFragments
    }
  }
  ${grouplogItemFragments}
`;
