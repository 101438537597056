import gql from "graphql-tag";

import SimpleUserFields from "../User/SimpleUserFields";
import EvaluationSummaryFields from "../Evaluation/EvaluationSummaryFields";
import EvaluationFields from "../Evaluation/EvaluationFields";

export default gql`
    fragment GroupSharingInfoFields on GroupSharingInfo {
        iAmOwner
        iAmAdmin
        iHaveSharedStartup

        sharedBy {
            ...SimpleUserFields
        }

        group {
            id
            name
        }

        evaluationSummaries {
            ...EvaluationSummaryFields
        }

        evaluations {
            ...EvaluationFields
        }

        subjectiveScores {
            isMe
        }
    }

    ${SimpleUserFields}
    ${EvaluationSummaryFields}
    ${EvaluationFields}
    
`




