import React  from "react";

//UI component
import { NotataList } from "Components/NotataComponents";


//others
import moment from "moment";

//Main Function
export default function MyEvaluations({ connection, setEvaluateModalContent, setSummaryModalContent }) {


  //Get data
  let myEvaluations = connection?.evaluations?.filter(
    ({ createdByUser, template }) => createdByUser?.isMe && template?.name
  );


  let tableHead = [
      {
        className: "eval-score-heading"
      },
      {
        className: "heading-submissions hide-on-mobile"
      },
      {
        className: "heading-score"
      },
      {
        className: "heading-right-icons"
      },
    ]

  // Table body
  let tableBody = myEvaluations?.map(evaluation => [

    // Name
    {
      content: (
        <div>
          <span>
            {evaluation?.template?.name}{" "}
          </span>
          <i
            className="fa fa-pen green-eye"
            aria-hidden="true"
            style={{cursor: "pointer"}}
            onClick={() => {
              setEvaluateModalContent(evaluation)
            }}
          />
        </div>
      )
    },

    // Submissions
    {
      content: (
        <div className="submissions hide-on-mobile">
          {moment(evaluation.createdAt).format("ll")}
        </div>
      )
    },

    // Percentage score
    {
      content: (
        <div>{evaluation?.summary?.scorePercent || 0}%</div>
      )
    },

    // Icons
    {
      content: (
        <div>
          <i
            className="fa fa-eye green-eye"
            aria-hidden="true"
            style={{cursor: "pointer"}}
            onClick={() => {
              setSummaryModalContent(evaluation)
            }}
          />
        </div>
      )
    }

  ])

  return (
    <>
      <div className={`col-sm-12 details`}>
        <NotataList
          header={tableHead}
          body={tableBody}
        />
      </div>
    </>
  );
}


