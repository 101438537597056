import React from "react";
import { useMutation } from "@apollo/client";

// API Queries	
import { connectionCreate, creativeDelete } from "private/Apollo/Mutations";
import notificationMarkAsResolved from "private/Apollo/Mutations/notificationMarkAsResolved";

// CUSTOM COMPONENTS
import { NotataButton } from "Components/NotataComponents";

// OTHERS
import { startup_page } from "definitions";
import { getVal } from "private/Pages/commonFunctions";

// Main Function
export default function PublicCreativeCreate({ notification, history }) {

  // Constants
  let creativeId = getVal(notification, "creativeId");

  // MUTATIONS
  const [createConnection, createConnectionRes] = useMutation(connectionCreate);
  const [deleteCreative, deleteCreativeRes] = useMutation(creativeDelete);
  const [markNotification /* markNotificationRes */] = useMutation(
    notificationMarkAsResolved
  );

  return (
    <>
      <div className="notifications-container-new__notification__action-buttons">
        {/* Delete Button */}
        <NotataButton
          size="x_small"
          bgcolor="secondary_color"
          text="delete"
          loading={deleteCreativeRes.loading}
          onClick={async () => {
            if (deleteCreativeRes.loading) {
              return;
            }

            try {
              let variables = { id: creativeId };
              await deleteCreative({ variables });
            } catch (error) {
              console.log("error");
              return;
            }

            try {
              let variables = { id: notification.id };
              await markNotification({ variables });
            } catch (error) {
              console.log("error", error);
              return;
            }
          }}
        />
        {/* Save Button */}
        <NotataButton
          size="x_small"
          bgcolor="primary_color"
          text="Save"
          loading={createConnectionRes.loading}
          onClick={async () => {
            if (createConnectionRes.loading) {
              return;
            }

            let connection;
            try {
              let variables = { creativeId };
              let res = await createConnection({ variables });
              connection = res?.data?.connectionCreate;
            } catch (error) {
              console.log("error");
              return;
            }

            try {
              let variables = { id: notification.id };
              await markNotification({ variables });
            } catch (error) {
              console.log("error", error);
              return;
            }
            history.push(`${startup_page}/company/${connection?.id}?tab=1`);
          }}
        />
      </div>
    </>
  );
}
