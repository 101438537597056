import gql from "graphql-tag";

import CreativeAnswerFields from "./CreativeAnswerFields";

export default gql`

    fragment CreativeFields on Creative {
        id
        name
        accountId
        sharedWithEmail
        logo
        canEdit
        answers {
            ...CreativeAnswerFields
        }
    }
    ${CreativeAnswerFields}
  
`



