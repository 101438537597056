import React from "react";

// Styles
import "./ScoreSummary.scss";

// Main functions
export default function ScoreSummary({
  title,
  score,
  highest,
  lowest,
  submissions,
  decimals
}) {
  return (
    <div className="score-summary">
      <div className="title">{title}</div>

      <div className="entries-line">
        {submissions || "0"}
        {submissions === 1 ? " entry" : " entries"}
      </div>

      <div className="scores-container">
        {/* score */}
        <div className="score">
          {isFinite(score) && score || '0'}{!decimals && "%"}
        </div>

        <div className="highest-lowest-score">
          <div className="record">
            {/* highest */}
            <div className="score highest">
              {isFinite(highest) && highest || '0'}
            </div>
            <div className="score-label">
              highest
            </div>
          </div>

          <div className="record">
            {/* lowest */}
            <div className="score lowest">
              {isFinite(lowest) && lowest || '0'}
            </div>
            <div className="score-label">
              lowest
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
