// REACT STUFF
import React from "react";
import { useForm } from "react-hook-form";

// UI COMPONENTS
import { NotataButton } from "Components/NotataComponents";

// STYLES
import "./Profile.scss";

// CUSTOM COMPONENTS
import Group from "./Group/Group";

//definitiona
import { dashboard, payment_page } from "definitions.js";

// MAIN FUNCTION
export default function Page3({ setPage, extraInputs, history }) {

  // FORMS
  const { /*  register, */ handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  // FUNCTIONS
  // onSubmit function
  const onSubmit = async () => {

    let code = window.localStorage.getItem("app_sumo_code")

    if (code) {
      return history.push(payment_page)
    }

    history.push(dashboard);
  };
  return (
    <form
      className="notata_form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginBottom: "20px" }}
    >
      <div className='profile3'>
        <h1
          style={{
            marginBottom: "40px",
          }}
        >
          Do you want to be a part of these groups?
        </h1>
        <h6>Click on &nbsp;<span>+</span>&nbsp; button to join the group</h6>
        <Group />

        {/* NotatButton */}
        <div className='button_container_justify'>
          <div className="left_btn">
            <NotataButton
              size="medium"
              text="Back"
              buttonStyle="outlined"
              onClick={() => setPage(2)}
            />
          </div>
         <div className="right_btn">
          <NotataButton
              size="medium"
              bgcolor="primary_color"
              endIcon={!isSubmitting ? "chevron_right" : "chevron_right"}
              text="Next"
              buttonType="submit"
              loading={isSubmitting}
            />
         </div>
        </div>
      </div>
    </form>
  );
}
