import gql from "graphql-tag";

export default gql`
  query connectionPagesGet($filters: ConnectionFilters) {
    connectionPagesGet(filters: $filters) {
      pageNo
      LastEvaluatedId
    }
  }
`;
