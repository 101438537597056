import gql from "graphql-tag";
import { publicSharingFragments } from "../Fragments";

export default gql`
  mutation publicSharingUpdate($id : ID!, $input: PublicSharingInput!) {
    publicSharingUpdate(id: $id, input: $input) {
      ...publicSharingFields
    }
  }
  ${publicSharingFragments}
`;
