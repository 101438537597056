import React, { useState, useEffect } from "react";

// COMPONENTS
// import FilterSideBar from "Components/secondarySidebar/RightSidebarFilter/FilterSidebar";
// API QUERIES/MUTATIONS
import { useQuery, useMutation } from "@apollo/client";
import { reportsGet, reportSettingsGet } from "../../Apollo/Queries";
import { reportSettingsSet } from "../../Apollo/Mutations";

// OTHERS
import { isEmpty, orderBy, find } from "lodash";

//DEFINITIONS
import { startup_page, group } from "../../../definitions"

// COMPONENTS
import ChartTile from "./ReportCharts/ChartTile";
import GroupsInfoCards from "./ReportsScoringTiles/GroupInfo";
import { BrowseDropDown, GhostLoader, Card } from "Components/NotataComponents";

import groupImg from "../../../assets/images/group.svg";
import business from "assets/images/business.svg";
import { REPORTCHARTS } from "../constants";

// STYLES
import "./Reports.scss";

// Constant
const defaultFilters = {
  tags: [],
  funnelTag: [],
  fromDate: null,
  toDate: null,
};

// clear Button
export const handleclearTxt = filters => {
  const hideTxt =
    (filters.tags && filters.tags.length > 0) ||
    (filters.funnelTag && filters.funnelTag.length > 0) ||
    filters.search ||
    filters.starred ||
    (filters.fromDate && filters.toDate);

  return !!hideTxt
};

function getCleanFilterData(filters) {
  let clean = {};
  for (let key in filters) {
    if (
      (filters[key] && filters[key].length) ||
      (typeof filters[key] === "boolean" && filters[key])
    ) {
      clean[key] = filters[key];
    }
  }
  // just to keep filters clean with ids only 
  if (filters?.tags && filters?.tags.length > 0) {
    clean.tags = filters.tags.map(tag => tag?.id);
  }
  return clean;
}


export default function Reports({ history }) {
  // Queries
  const { data, loading, refetch } = useQuery(
    reportsGet
  );
  const reportsSettings = useQuery(reportSettingsGet);
  const reportsSettingData = reportsSettings?.data?.reportSettingsGet;

  // Mutation
  const [updateReportsSettings] = useMutation(reportSettingsSet);

  // Good :D
  const report = data?.reportsGet || {};
  const { groupStats, funnelStats = [], tagStats = [] } = report;
  const { numberOfGroups = 0, peopleInGroups = 0, startupsInGroups = 0 } =
    groupStats || {};

  const { connectionCount = 0 } = report;
  const colors = ["red", "blue", "purple", "orange", "green"];

  // STATES
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [clearFilterTxt, setClearFilterTxt] = useState(undefined);
  const [filters, setFilterState] = useState(defaultFilters);
  const [mount, setIsMount] = useState(false);

  // BY DEFAULT ZEROTH INDEX VALUE
  const [funnelGroup, setfunnelGroup] = useState({});
  const [funnelTag, setFunnelTag] = useState({});
  const [isFunnelDropDown, setFunnelDropDown] = useState(false);

  // EFFECTS

  // Load filters from local store
  useEffect(() => {
    let f;
    try {
      f = JSON.parse(localStorage.getItem("reportFilters"));
    } catch (error) { }

    if (f) {
      setFilterState(f);
    }
    setIsMount(true)
  }, []);

  useEffect(() => {
    let selectedFunnel = find(funnelStats, ['funnelGroupId', reportsSettingData?.funnelGroup]) || funnelStats[0]
    setfunnelGroup(selectedFunnel);

    setFunnelTag(selectedFunnel?.funnelTags[0]);
  }, [report, reportsSettingData]);

  useEffect(() => {
    let variables = { filters: getCleanFilterData(filters) }
    if (!isEmpty(variables.filters)) {
      refetch(
        variables
      );
    }
    setClearFilterTxt(handleclearTxt(filters));

  }, [filters]);

  // Function
  // Setting filters: save to local store
  function setFilters(filterData) {
    localStorage.setItem("reportFilters", JSON.stringify(filterData));
    setFilterState(filterData);
  }

  return (
    mount &&
    (
      <div className="report-container">
        {loading ?
          <GhostLoader />
          :
          <>
            <div className="col-md-12 col-lg-6 col-xs-12 funnel-parent">
              <Card containerClassName="funnnel-card" data-tut="funnels" size="small" heading="Funnels">
                <div className="col-sm-10 col-lg-6 col-md-7 reports-funnels-container">
                  {funnelGroup && (
                    <>
                      <div className="reports-funnels-container__filter">
                        <div
                          className="reports-funnels-container__filter__stages"
                          onClick={() => setFunnelDropDown(!isFunnelDropDown)}
                        >
                          {funnelGroup?.funnelGroupName}
                          <i
                            className={`fa ${isFunnelDropDown
                              ? "fa-chevron-up"
                              : "fa-chevron-down"
                              } reports-funnels-container__filter__stages__downup`}
                            aria-hidden="true"
                          ></i>
                          {isFunnelDropDown && (
                            <div
                              className="reports-funnels-container__filter__stages__dropdown"
                            >
                              <BrowseDropDown
                                open={isFunnelDropDown}
                                setOpen={() => setFunnelDropDown(!isFunnelDropDown)}
                                items={
                                  funnelStats?.map(item => {
                                    return {
                                      text: item.funnelGroupName, callBack: () => {
                                        setfunnelGroup(item);
                                        setFunnelTag(item.funnelTags[0]);
                                        const variables =
                                        {
                                          input: {
                                            funnelGroup: item.funnelGroupId
                                          }
                                        }
                                        updateReportsSettings({ variables })
                                      },
                                      selected: item.funnelGroupId === funnelGroup.funnelGroupId
                                    }
                                  }
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="reports-funnels-container__funnels">
                        {orderBy(funnelGroup?.funnelTags, ['index'], ['asc']).slice(0, 5).map((item, index) => {
                          return (
                            <div key={index}
                              className="reports-funnels-container__funnels__funnel"
                              onClick={() => setFunnelTag(item)}
                            >
                              <div className="score">{item.connectionCount}</div>
                              <div className="funnel-chart">
                                <div className={`${item.color || colors[index]} bar-${index}`} />
                              </div>
                              <div className="funnel-type">
                                {item.funnelTagName}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </div>
                <div className="col-sm-10 col-lg-6 col-md-5 reports-funnels-container__data-container">
                  <div className="row reports-funnels-container__data-container__data-summary">
                    <div className="col-sm-12 col-xs-12 reviewed">
                      {funnelTag?.funnelTagName}
                    </div>
                  </div>
                  <div className="reports-funnels-container__data-container__data-headers">
                    {funnelTag?.records && (
                      <>
                        <div className="row">
                          <div className="col-sm-6 col-xs-6 name">Name</div>
                          <div className="col-sm-6 col-xs-6 added">Added</div>
                        </div>
                        {funnelTag?.records?.map((record, index) => {
                          // ONLY 8 RECORDS SHOULD RENDER
                          if (index >= 8) return null;
                          return (
                            <div key={`data-record-id-${index}`}>
                              <div className="row reports-funnels-container__data-container__data-record">
                                <div className="col-sm-6 col-xs-6 funnel-name">
                                  {record.creativeName}
                                </div>
                                {/* HARD CODE DATE FOR NOW */}
                                <div className="col-sm-6 col-xs-6 funnel-date">
                                  Feb 16, 2021
                                </div>
                              </div>
                              <div className="separator" />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-12 col-lg-6 col-xs-12 group-parent">
              <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6 my-groups-container left_card_data">
                <GroupsInfoCards
                  heading="My Groups"
                  noOfRecords={numberOfGroups}
                  img={groupImg}
                  linkTo={"Go to my groups"}
                  onClick={() => history.push(group)}
                  dataTut="my-groups"
                />
              </div>
              <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6 my-groups-container right_card_data">
                <GroupsInfoCards
                  heading="All Startups"
                  noOfRecords={connectionCount}
                  img={business}
                  linkTo={"Go to my startups"}
                  // onClick={() => <Redirect to={startup_page} /> }
                  link={startup_page}
                  dataTut="all-startups"
                />
              </div>
              <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6 my-groups-container left_card_data">
                <GroupsInfoCards
                  heading="People in your network"
                  noOfRecords={peopleInGroups}
                  img={groupImg}
                  linkTo={null}
                  dataTut="people-in-your-network"
                />
              </div>
              <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6 my-groups-container right_card_data">
                <GroupsInfoCards
                  heading="Group startups"
                  noOfRecords={startupsInGroups}
                  img={business}
                  linkTo={null}
                  dataTut="group-startups"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 tags-container-heading">Tags</div>
            <div className="tags-super-parent">
              {tagStats?.map((tag, index) => {
                let tagInitial = find(reportsSettingData?.tagGroups, ['groupId', tag.tagGroupId])
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 col-xs-12 tags-container-parent"
                    key={`chart-id-${index}`}
                  >
                    <ChartTile
                      tileHeading={tag.tagGroupName}
                      selectedChartType={tagInitial?.graphType === REPORTCHARTS.LIST ? REPORTCHARTS.COLUMN : tagInitial?.graphType || REPORTCHARTS.COLUMN}
                      isDropDownOption={false}
                      tags={tag?.tags}
                      dataTut={index === 0 ? 'tag-0' : ''}
                      onGraphChange={(graphType) => {
                        const variables = {
                          input: {
                            tagGroups: {
                              groupId: tag.tagGroupId,
                              graphType: graphType
                            }
                          }
                        }
                        updateReportsSettings({ variables })
                      }}
                    />
                  </div>
                );
              })}
            </div>
            {/*{isFilterVisible &&*/}
            {/*  <FilterSideBar*/}
            {/*    clearFilterTxt={clearFilterTxt}*/}
            {/*    defaultFilters={defaultFilters}*/}
            {/*    close={setFilterVisible}*/}
            {/*    filters={filters}*/}
            {/*    setFilters={setFilters}*/}
            {/*    reportFilter={true}*/}
            {/*  />*/}
            {/*}*/}
          </>
        }
      </div>
    )

  )
}