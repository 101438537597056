import React, { useState } from "react";

//API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { connectionsGet } from "private/Apollo/Queries";
import { connectionDelete, connectionPut } from "private/Apollo/Mutations";

// UI COMPONENTS
import {
  NotataButton,
  GhostLoader,
  CardComponent,
} from "Components/NotataComponents";

// Definition
import { startup_page } from "definitions";

//STYLES
import "./ArchivePage.scss";

//Main function
export default function ArchivePage({ history }) {
  // States
  const [archiveId, setArchiveId] = useState("");

  // Queries
  const { data, loading } = useQuery(connectionsGet, {
    variables: { filters: { archived: true } },
    fetchPolicy: "network-only",
    awaitRefetchQueries: true,
  });

  // Mutations
  const [archiveConnection, archiveConnectionRes] = useMutation(connectionPut, {
    refetchQueries: [
      { query: connectionsGet, variables: { filters: { archived: true } } },
    ],
  });
  const [deleteConnection, deleteConnectionRes] = useMutation(
    connectionDelete,
    {
      refetchQueries: [
        { query: connectionsGet, variables: { filters: { archived: true } } },
      ],
    }
  );

  //Get startups data
  let archivedConnections = data?.connectionsGet?.records || [];

  //routing
  let arch = archivedConnections.some(e => e.id === history.location.state);

  //loader
  if (loading) {
    return <GhostLoader />;
  }

  return (
    <div className="tab-panel-container archive-list-container">
      <CardComponent
        containerClassName="archive-list__card"
        padding="large"
        title="Archive"
        titleSize="medium"
        backLink={() => {
          if (!loading) {
            if (arch) {
              history.push(startup_page);
            } else {
              history.goBack();
            }
          }
        }}
        content={
          <div>
            <div className="startups">Startups</div>
            <div className="archive-list-data">
              {/* No archieved startup */}
              {!archivedConnections?.length && (
                <div>
                  <div className="row">
                    <div className="col-sm-4 col-xs-12 startup-name">
                      Archive list is empty!!!
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <NotataButton
                        size="small"
                        bgcolor="secondary_color"
                        text="go back"
                        onClick={() => history.goBack()}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* if there are archieved startups */}
              {!!archivedConnections?.length &&
                archivedConnections?.map((connection, index) => (
                  <div
                    className="archive-list-data__item"
                    key={`row-id-${index}`}
                  >
                    <div className="startup-name">
                      {connection?.creative?.name}
                    </div>
                    <div className="archive-list-data__item__btn">
                      {/* unarchive button */}
                      <NotataButton
                        size="big_slim"
                        bgcolor="primary_color"
                        text="UNARCHIVE"
                        loading={
                          archiveConnectionRes.loading &&
                          archiveId === connection.id
                        }
                        onClick={async () => {
                          if (archiveConnectionRes.loading) {
                            return;
                          }
                          setArchiveId(connection.id);

                          try {
                            let variables = {
                              id: connection.id,
                              input: {
                                archived: false,
                              },
                            };
                            await archiveConnection({ variables });
                          } catch (error) {
                            return console.log(error);
                          }
                        }}
                      />

                      {/* delete permanently button */}
                      <NotataButton
                        size="big_slim"
                        bgcolor="secondary_color"
                        text="DELETE PERMANENTLY"
                        loading={
                          deleteConnectionRes.loading &&
                          archiveId === connection.id
                        }
                        onClick={async () => {
                          if (deleteConnectionRes.loading) {
                            return;
                          }
                          setArchiveId(connection.id);
                          try {
                            let variables = {
                              id: connection.id,
                            };
                            await deleteConnection({ variables });
                          } catch (error) {
                            return console.log(error);
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        }
      />
    </div>
  );
}
