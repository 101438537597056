import React, { useState } from "react";

// UI COMPONENTS
import { InputForm, NotataButton } from "Components/NotataComponents";
import FloatingLoginButtons from "./FloatingLoginButtons/FloatingLoginButtons";

// STYLES
import "../LoginPages.scss";

// Assets
import man_standing from "assets/images/man_standing.svg";
import notata from "assets/images/auth-logo.svg";

// CUSTOM COMPONENTS
import { Message } from "../Message/Message";

// OTHERS
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

// Definitions
import { login, forgotPassword } from "definitions.js";

//Main Function
export function ResetPassword({ email, code, history, handleBack }) {
  
  // STATES
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const listForm = ["password", "pwd"];
  const [position, setPosition] = useState(4);
  const [validate /* setValidate */] = useState(false);
  const [password, setPassword] = useState("");

  // FORMS
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        password: yup.string().min(8).required(),
        confirmPassword: yup.string().oneOf([yup.ref("password")]).required(),
      })
    ),
  });
  const { isSubmitting } = formState;

  // FUNCTIONS

  // Next flag function
  const setNextFlag = index => {
    setPosition(index === "email" ? 1 : index === "password" ? 2 : 3);
  };

  // onSubmit function
  const onSubmit = async (data, event) => {
    const { password } = data;

    if(errorMessage) {
      handleBack()
      return 
    }

    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      setSuccessMessage("New password has been reset. You can now log in!");
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error.message);
      setSuccessMessage(null);
    }
  };

  // handleInput change
  const handleInputChange = (val, name) => {
    if (name === "password" && val === "") {
      setErrorMessage("");
    }
    if (name === "password") {
      setPassword(val);
    }
  };

  const data = { code: "" };

  return (
    <>
      {successMessage ? (
        
        // Error Message
        <Message
          heading={"Password Reset🚀"}
          subHead1={"Your password has been reset."}
          subHead2={"please, click next to login"}
          image={man_standing}
          history={history}
          path={login}
        />
      ) : (

        // Reset Password
        <div className='auth_structure'>
          <div className='auth_structure__left'>
            <div className='auth_structure__left__mainContent'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='logoContainer'>
                  {/* Logo */}
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={notata}
                    alt="logo"
                    className='logo'
                  />
                  <h1>Reset Password</h1>
                </div>
                <div style={{ marginTop: "20px" }}>
                  {/* Password InputField */}
                  <InputForm
                    label="Password"
                    type="password"
                    name="password"
                    placeholder="New Password"
                    position={listForm[position]}
                    setNextFlag={setNextFlag}
                    handleInputChange={(value, name) =>
                      handleInputChange(value, name)
                    }
                    validate={validate}
                    required
                    reference={register({ required: true })}
                    storngPswd={true}
                  />

                  {/* Confirm Password InputField */}
                  <InputForm
                    label="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    placeholder="Password"
                    position={listForm[position]}
                    setNextFlag={setNextFlag}
                    validate={validate}
                    passwordConfirm={true}
                    errorMessage={errorMessage}
                    primaryPwdVal={password}
                    reference={register({ required: true })}                    
                  />

                  {/* submit button  */}
                  <NotataButton
                    size="xx_large"
                    bgcolor="primary_color"
                    text={!errorMessage ? "SAVE" : "BACK"}
                    buttonType="submit"
                    loading={isSubmitting}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className='auth_structure__right'>
            <img src={man_standing} alt="man_standing" />
          </div>
          {/*<FloatingLoginButtons />*/}
        </div>
      )}
    </>
  );
}