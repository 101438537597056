import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { tagCreate } from "../../../Apollo/Mutations";
import { tagGroupsGet } from "../../../Apollo/Queries";

// UI COMPONENTS
import { BrowseDropDown, Tag, TextBox } from "Components/NotataComponents";

// MODALS
import DeleteTagModal from "./settingsTagsModals/DeleteTagModal";
import DeleteTagGroupModal from "./settingsTagsModals/DeleteTagGroupModal";
import EditTagModal from "./settingsTagsModals/EditTagModal";
import EditTagGroupModal from "./settingsTagsModals/EditTagGroupModal";

// MAIN FUNCTION
export default function SettingsTagGroup({ tagGroup, tagGroupIndex }) {
  const [deleteTagGroupModal, setDeleteTagGroupModal] = useState(false);
  const [deleteTagModal, setDeleteTagModal] = useState(undefined);
  const [editTagModal, setEditTagModal] = useState(undefined);
  const [editTagGroupModal, setEditTagGroupModal] = useState(undefined);

  const [newTag, setNewTag] = useState("");
  const [isTagDropDown, setIsTagDropDown] = useState(false);

  // Mutations
  const [createTag, createTagRes] = useMutation(tagCreate, {
    refetchQueries: [{ query: tagGroupsGet }],
  });

  // add new tag Functions
  const addNewTag = async () => {
    if (newTag === "") {
      return;
    }
    if (createTagRes.loading) {
      return;
    }
    let variables = {
      tagGroupId: tagGroup.id,
      input: {
        name: newTag,
      },
    };
    try {
      await createTag({ variables });
    } catch (error) {
      console.log("error", error);
    }
    setNewTag("");
  };

  return (
    <>
      <div className="settingsTags-container1__tag">
        <div
          className="settingsTags-container1__tag__heading"
          style={{ justifyContent: "space-between" }}
        >
          <span> {tagGroup.name} </span> &nbsp;&nbsp;
          <span
            className="material-icons settingsTags-container1__tag__heading__more"
            onMouseLeave={() => setIsTagDropDown(false)}
            onClick={() => setIsTagDropDown(!isTagDropDown)}
            data-tut={tagGroupIndex === 0 ? "tags-0-browse" : ""}
          >
            <i class="fal fa-cog" style={{ fontSize: "20px" }}></i>
            {isTagDropDown && (
              <div className="settingsTags-container1__tag__heading__dropdown">
                <BrowseDropDown
                  open={isTagDropDown}
                  setOpen={() => setIsTagDropDown(!isTagDropDown)}
                  items={[
                    {
                      text: "Edit",
                      icon: <i className="fal fa-edit"/>,
                      leave: false,
                      callBack: () => setEditTagGroupModal(true),
                    },
                    {
                      text: "DELETE TAG GROUP",
                      leave: true,
                      icon: <i className="fa fa-trash"/>,
                      callBack: () => setDeleteTagGroupModal(true),
                    },
                  ]}
                />
              </div>
            )}
          </span>
        </div>
        <div className="settingsTags-container1__tag__available-tags">
          {tagGroup?.tags?.map((tag, index) => {
            return (
              <Tag
                key={`available-tags-record-${tag.id}`}
                color="primary_tag"
                size="md_tag"
                label={`${tag.name}`}
                killButton={() => setDeleteTagModal(tag)}
                dataTut={index === 0 ? "tags-0-0" : ""}
                tagmargin="tag_margin"
              />
            );
          })}
        </div>
        <div className="settingsTags-container1__tag__add-new-tag">
          <div className="heading">Add New Tag</div>
          <div
            className="settingsTags-container1__tag__add-new-tag__input"
            style={{ opacity: createTagRes.loading ? 0.5 : 1 }}
            data-tut="add-new-tag"
          >
            <TextBox
              type="text"
              value={newTag}
              onChange={e => setNewTag(e.target.value)}
              autoComplete="off"
              onKeyDown={e => {
                if (e.key === "Enter") {
                  return addNewTag();
                }
              }}
            />
            <i
              className="fa fa-plus-circle"
              aria-hidden="true"
              onClick={addNewTag}
            />
          </div>
        </div>
      </div>

      {deleteTagModal && (
        <DeleteTagModal
          tag={deleteTagModal}
          setDeleteTagModal={setDeleteTagModal}
        />
      )}

      {editTagModal && (
        <EditTagModal tag={editTagModal} setEditTagModal={setEditTagModal} />
      )}

      {editTagGroupModal && (
        <EditTagGroupModal
          tagGroup={tagGroup}
          setEditTagGroupModal={setEditTagGroupModal}
        />
      )}

      {deleteTagGroupModal && (
        <DeleteTagGroupModal
          tagGroup={tagGroup}
          setDeleteTagGroupModal={setDeleteTagGroupModal}
        />
      )}
    </>
  );
}
