import React from "react";

// COMPONENTS
import AddFunnel from "./AddFunnel";
import { Modal } from "Components/NotataComponents";


export default function AddFunnelModal({ connection, close, updateFunnelTag }) {
  return (
    <Modal
      title="Set Funnel Stage"
      close={close}
      submit={close}
      submitTxt={"OK"}
      closeTxt="CLOSE"
    >
      <AddFunnel connection={connection}/>
    </Modal>
  );
}
