import React, { useEffect, useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { groupUpdate } from "private/Apollo/Mutations";

// UI COMPONENTS
import { Modal, TextBox } from "Components/NotataComponents";

// OTHERS
import defaultData from "../_defaultGroupData";

// MAIN FUNCTION
export default function EditGroupModal({ group, close }) {

  // STATES
  const [data, setData] = useState(defaultData);

  // MUTATIONS
  const [updateGroup, updateGroupRes] = useMutation(groupUpdate);

  // EFFECTS
  useEffect(() => {
    if (group) {
      setData({
        ...data,
        general: {
          name: group?.name,
          description: group?.description,
        },
      });
    }
  }, [group]);

  // SAVE FUNCTION
  async function save() {
    let variables = {
      id: group.id,
      name: data?.general?.name || group.name || "",
      description: data?.general?.description === "" ?  "" : data?.general?.description || group?.description || "",
    };
    await updateGroup({ variables });
    close();
  }

  return (
    <Modal
      title="Edit"
      submit={save}
      loading={updateGroupRes.loading}
      close={close}
      submitTxt="Save"
      closeTxt="Cancel"
      children={
        <div className="genral-contianer">
          <div className="group-name">
            Group Name<span className="asterik">*</span>
          </div>
          <div className="text">
            <TextBox
              placeholder="Group Name"
              value={data.general.name || ""}
              onChange={e =>
                setData({
                  ...data,
                  general: {
                    ...data.general,
                    name: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="group-name description">group Description</div>
          <textarea
            placeholder="Group Description"
            value={data.general.description || ""}
            onChange={e =>
              setData({
                ...data,
                general: {
                  ...data.general,
                  description: e.target.value,
                },
              })
            }
          />
        </div>
      }
    />
  );
}