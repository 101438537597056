//others
import { Storage } from "aws-amplify";

//main function
export async function uploadImagePromise(filename, file, setProgress) {
    return new Promise((resolve, reject) => {
      Storage.put(filename, file, {
        progressCallback(progress) {
          if (setProgress) {
            setProgress({
              loaded: progress.loaded,
              total: progress.total,
            });
          }
        },
        level: "public",
      })
        .then(resolve)
        .catch(reject);
    });
  }