import React, {useState, useEffect} from "react";

// API STUFF
import { useMutation, useQuery } from "@apollo/client";
import { groupStartupRemove, groupStartupAdd } from "private/Apollo/Mutations";
import { connectionGet } from "private/Apollo/Queries";

// CUSTOM COMPONENTS
import { NotataButton, Modal, ModalErrorMessage, Card } from "Components/NotataComponents";
import AddGroup from "../../../../MyStartups/Modals/addGroup";
import CreateGroup from "../../../../../../Components/Common/Modals/CreateGroup/CreateGroup";


// DEFINITIONS
import { group_dashboard } from "definitions";

// OTHERS
import RemoveFromGroup from "./RemoveFromGroup";
import SharingOptions from "./SharingOptions/SharingOptions";
import { groupsGetV2 } from "private/Apollo/Queries";
import { useHistory } from "react-router-dom";


// MAIN FUNCTION
const GroupsCard = ({ connection }) => {

  let history = useHistory()

  let data = [];

  for (let info of connection?.groupSharingInfo || []) {
    let creativeId = connection?.creative?.id;
    let creativeName = connection?.creative?.name;
    let sharedSubjectiveScore = info?.subjectiveScores?.some(
      ({ isMe }) => isMe
    );

    let sharedEvaluations = info?.evaluations?.filter(
      ({ createdByUser }) => createdByUser.isMe
    );

    let item = {
      group: info?.group,
      creativeId,
      creativeName,
      sharedSubjectiveScore,
      sharedEvaluations,
      sharedBy: info.sharedBy,
      iAmAdmin: info.iAmAdmin,
      iAmOwner: info.iAmOwner,
    };
    data.push(item);
  }


  // STATES
  const [deleteModal, setDeleteModal] = useState(undefined);
  const [isSharingSubjectiveScore, setIsSharingSubjectiveScore] = useState(false);
  const [sharingOptionsModal, setSharingOptionsModal] = useState(undefined);
  const [addToGroupModal, setAddToGroupModal] = useState(false);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [groupHasPermission, setGroupHasPermission] = useState(true);
  const [selected, setSelected] = useState(undefined);

  // QUERIES
  const groupsQuery = useQuery(groupsGetV2);

  // MUTATIONS
  const [removeStartup, removeStartupRes] = useMutation(groupStartupRemove, {
    refetchQueries: [
      {
        query: connectionGet,
        variables: { id: connection?.id },
      },
    ],
  });

  const [addStartupToGroupMutation, addStartupToGroupMutationRes] = useMutation(
    groupStartupAdd
  );

  // Handle Permission
  const handlePermission = (selectedGroup) => {
    selectedGroup = selectedGroup.filter(({ iAmAdmin,settings }) => iAmAdmin || settings.addStartup);
    selectedGroup ? setGroupHasPermission(true) : setGroupHasPermission(false)
  }

  // DATA MAPS
  const getItem = groupId => data?.find(({ group }) => group.id === groupId);
  let groups = groupsQuery?.data?.groupsGetV2 || []

  // Filter shared group
  groups = groups.filter(({ id }) => !connection?.groupSharingInfo.some(info => info.group.id === id))

  // EFFFECTS
  useEffect(()=>{
    if(selected) {
      handlePermission([selected])
    }
  }, [selected]);

  useEffect(()=>{
    if(groups && groups.length === 1) {
      setSelected(groups[0])
    }
  }, [groups]);

  useEffect(() => {
    if (groupsQuery?.data?.groupsGetV2) {
      let groups = groupsQuery?.data?.groupsGetV2;
      groups = groups.filter(({ iAmAdmin, settings }) => iAmAdmin || settings.isPublic);
      groups = groups.filter(
        ({ id }) => !connection?.groupSharingInfo.some(info => info.group.id === id)
      );
      if (groups?.length === 1) {
        setSelected(groups[0]);
      }
    }
  }, [groupsQuery])

  return (
    <Card heading="Groups">
      {data?.length === 0 && (
        <div className="card-empty-text">
          Here you can share deals, evaluate startups, chat and find co-investors.
        </div>
      )}


      {data?.map((item,index) => {
        return (
          <>
            <div className={`row col-md-12 ${index === 0 ?"":"border"}`}/>
            <div className="row group-container groups_details" key={`group-${item.group.id}`}>
              <div className="col-sm-4 col-xs-6">
                <div
                  className="group-name"
                  style={{cursor: "pointer"}}
                  onClick={() => history.push(`${group_dashboard}/${item.group.id}`)}
                >
                  {item?.group?.name}
                </div>
                <div
                  className="group-name-mobile"
                  onClick={() => {
                    history.push(`${group_dashboard}/${item.group.id}`);
                  }}
                >
                  {item?.group?.name?.length < 25
                    ?
                    item?.group?.name
                    :
                    `${item?.group?.name?.slice(0, 20)} ...`}
                </div>

                <div className="members">
                  Shared by: {item.sharedBy.given_name}{" "}
                  {item.sharedBy.family_name}{" "}
                  {item.sharedBy.isMe && " (you)"}
                </div>

                {item.sharedBy.isMe && (
                  <div
                    className="remove-from-group"
                    onClick={() => setDeleteModal(item.group.id)}
                  >
                    remove from group
                  </div>
                )}
              </div>
              {/* evaluations */}
              <div className="col-sm-4 col-xs-6 ">
                <div
                  className={
                    item.sharedEvaluations.length === 0
                    && !item.sharedSubjectiveScore
                      ? "evaluation-template fade-me"
                      : "evaluation-template"
                  }
                >
                  You share with this group:
                </div>
                <div className="templates">
                  {item.sharedSubjectiveScore &&
                  <div className="template">Subjective score</div>
                  }
                  {item.sharedEvaluations.map(evaluation => {
                    return (
                      <div
                        className="template"
                        key={`template-${item.group.id}-${evaluation?.id}`}
                      >
                        {evaluation?.template?.name}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-sm-4 col-xs-12 sharing-options">
                <NotataButton
                  size="big"
                  bgcolor="primary_color"
                  startIcon="share"
                  text="Sharing options"
                  onClick={() => setSharingOptionsModal(item.group.id)}
                />
              </div>



            </div>

          </>
        );
      })}
      <div className="button" style={{marginTop:"3%"}}>
        <NotataButton
          size="large"
          bgcolor="primary_color"
          startIcon="add"
          loading={addStartupToGroupMutationRes.loading}
          text="ADD TO NEW GROUP"
          datatut="groups-add-new-group"
          onClick={setAddToGroupModal}
        />
      </div>

      {/* Modals */}
      {/* Delete Modals */}
      {deleteModal && (
        <Modal
          title="Remove from group"
          loading={removeStartupRes.loading}
          submit={async () => {
            if (removeStartupRes.loading) {
              return;
            }

            let variables = {
              groupId: deleteModal,
              creativeId: connection.creative.id,
            };

            try {
              await removeStartup({ variables });
            } catch (error) {
              console.log("error", error);
            }

            setDeleteModal(undefined);
          }}
          close={() => {
            setDeleteModal(undefined);
          }}
          submitTxt="Remove"
          closeTxt="Cancel"
          children={<RemoveFromGroup />}
        />
      )}
      {/* Sharing options modal */}
      {sharingOptionsModal && (
        <Modal
          title="Sharing options"
          submit={() => {
            setSharingOptionsModal(undefined);
          }}
          close={() => {
            setSharingOptionsModal(undefined);
          }}
          submitTxt="OK"
          closeTxt="CLOSE"
          children={
            <SharingOptions
              groupId={getItem(sharingOptionsModal).group?.id}
              creativeId={getItem(sharingOptionsModal)?.creativeId}
              connection={connection}
              sharedSubjectiveScore={
                getItem(sharingOptionsModal)?.sharedSubjectiveScore
              }
              sharedEvaluations={
                getItem(sharingOptionsModal)?.sharedEvaluations
              }
              setIsSharingSubjectiveScore={setIsSharingSubjectiveScore}
            />
          }
        />
      )}

      {/* Add to group modal */}
      {addToGroupModal && (
        <Modal
          title="Add startup to group"
          loading={addStartupToGroupMutationRes.loading}
          submit={async () => {
            if(groups.length>0){
              if (!selected) return;

              if (addStartupToGroupMutationRes.loading) {
                return;
              }
              if(!selected?.settings?.addStartup && !selected?.iAmAdmin){
                setGroupHasPermission(false);
                return;
              }

              let variables = {
                groupId: selected.id,
                creativeId: connection?.creative?.id,
              };

              try {
                await addStartupToGroupMutation({ variables });
              } catch (error) {
                console.log("error", error);
              }

              setSelected(undefined);
              setAddToGroupModal(false);

            } else {
              setAddToGroupModal(false);
              setCreateGroupModal(true)
            }
          }}
          close={() => {
            setSelected(undefined);
            setAddToGroupModal(false);
          }}
          submitTxt={groups.length > 0 ? "Add" : "CREATE NEW GROUP"}
          submitButtonSize={groups.length > 0 ? "small" : "big"}
          closeTxt="Cancel"
          children={
            <>
              <AddGroup
                connection={connection}
                groups={groupsQuery?.data?.groupsGetV2}
                select={group => setSelected(group)}
              />
              {!groupHasPermission && <ModalErrorMessage msg="You don't have the right privileges to add startups to this group."/>}
            </>
          }
        />
      )}

      {/* Create group modal */}
      {createGroupModal && (
        <Modal
          title="Create new group"
          submit={() => setCreateGroupModal(false)}
          close={() => setCreateGroupModal(false)}
          submitTxt="OK"
          closeTxt="CLOSE"
          children={<CreateGroup close={() => setCreateGroupModal(false)} />}
        />
      )}
    </Card>
  )
}

export default GroupsCard;