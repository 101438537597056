import React from "react";

import { NotataMenu } from "./NotataMenu/NotataMenu";
import { NotataHeader } from "./NotataHeader/NotataHeader";
import { payment_page } from "definitions";



function PaymentNudge({ withBanner }) {

  if (!withBanner) {
    return (<span/>)
  }

  return (
    <div className="payment-banner">
      <div>
        <span className="hide-on-mobile">You are currently on a free account.{" "}</span>
        <a href={payment_page} target={"_self"}>Upgrade now</a> and save 67% the next 2 years!
      </div>
    </div>
  )
}


export default function Navigation({ history, setTourOpen, isTourOpen, tourCaller, withBanner }) {

  return (
    <React.Fragment>

      <PaymentNudge
        withBanner={withBanner}
      />

      <NotataMenu
        withBanner={withBanner}
      />
      <div>
        <NotataHeader
          withBanner={withBanner}
          history={history}
          setTourOpen={setTourOpen}
          isTourOpen={isTourOpen}
          tourCaller={tourCaller}
        />
      </div>


    </React.Fragment>
  );
}
