import React, { useState } from "react";

// COMPONENTS
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import SelectAllPopup from "../../PopUps/SelectAllPopup";
import ArchiveModal from "../../Modals/ArchiveModal";
import AddToGroupModal from "../../Modals/AddToGroupModal";
import AddTagsModal from "../../Modals/AddTagsModal";
import { Loader } from "Components/NotataComponents";

import { FunnelPopup } from "../../PopUps/FunnelPopup";
import { StartupPreviewPopup } from "../../PopUps/StartupPreviewPopup";

//ROUTER
import { startup_page } from "definitions";

// STYLES
import styles from "./Table.module.css";
import {
  sortArr,
  dynamicIcons,
} from "../../../commonFunctions";

export default function Table(props) {
  // Constant
  const {
    tagGroups,
    setStarMutation,
    columnValues,
    connections,
    filters,
    setFilters,
    funnelLoad,
    evaluationTemplates,
    loading,
    emptyLabel,
    history,
    refetch,
    setShowFunnelScoreForId,
    setShowTagGroupForId,
    setShowStartUpForId,
    setShowSubjectiveScoreForId,
    updateFunnelTag,
    setCurrentPage
  } = props;



  const popupItems = [
    {
      title: "Archive",
      nested: [],
    },
    {
      title: "Add To Group",
      nested: [],
      // nested: ["Group 1", "Group 2"],
    },
    {
      title: "Add Tags",
      nested: [],
      // nested: ["Tag 1", "Tag 2"],
    },
  ];

  // States
  const [preview, setPreview] = useState();
  const [checked, setChecked] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [viewModal, setViewModal] = useState(undefined);
  const [popup, setPopup] = useState(false);
  const [activeFunnel, setActiveFunnel] = useState(null);
  const [popupClientX, setPopupClientX] = useState(0);
  const [popupClientY, setPopupClientY] = useState(0);
  const [funnelPopupVisible, setFunnelPopupVisible] = useState(false);
  const [previewItem, setPreviewItem] = useState(false);
  //Function
  const handlePopup = () => {
    setPopup(!popup);
  };

  const ButtonGreen = () => (
    <button className={styles.buttongreen}>
      <i className="fas fa-plus-circle" />
    </button>
  );

  const handleCompany = connection => {
    history.push(`${startup_page}/company/${connection.id}`);
  };

  return (
    <>
      <div className={styles.tableOuterWrapper}>
        <div className={styles.tableWrapper} onScroll={() => setFunnelPopupVisible(false)} >
          <table className={styles.startupTable}>
            <TableHeader
              columnValues={columnValues}
              filters={filters}
              setFilters={setFilters}
              evaluationTemplates={evaluationTemplates}
              setCurrentPage={setCurrentPage}
              handlePopup={handlePopup}
              tagGroups={tagGroups}
            />

            <TableBody
              setStarMutation={setStarMutation}
              columnValues={columnValues}
              connections={connections}
              funnelLoad={funnelLoad}
              evaluationTemplates={evaluationTemplates}
              setShowFunnelScoreForId={setShowFunnelScoreForId}
              setShowTagGroupForId={setShowTagGroupForId}
              setShowStartUpForId={setShowStartUpForId}
              setShowSubjectiveScoreForId={setShowSubjectiveScoreForId}
              ButtonGreen={ButtonGreen}
              checkAll={checkAll}
              checked={checked}
              setChecked={setChecked}
              setActiveFunnel={setActiveFunnel}
              setPopupClientX={setPopupClientX}
              setPopupClientY={setPopupClientY}
              setFunnelPopupVisible={setFunnelPopupVisible}
              funnelPopupVisible={funnelPopupVisible}
              activeFunnel={activeFunnel}
              tagGroups={tagGroups}
            />
          </table>

          <SelectAllPopup
            items={popupItems}
            isOpen={popup}
            setIsOpen={setPopup}
            setSelect={item => {
              setViewModal(item.title);
            }}
          />

          {
            loading && <Loader noPadding={true} />
          }

          {!connections.length && (
            <div className={styles.empty_list}>
              {emptyLabel || "This list is empty"}
            </div>
          )}
        </div>
      </div>

      {funnelPopupVisible &&
      <FunnelPopup
        // setFunnelDropDownId={setFunnelDropDownId}
        styles={styles}
        tags={activeFunnel?.group?.funnelTags || []}
        index={activeFunnel?.index}
        updateFunnelTag={(funnelTagId, id) => {
          updateFunnelTag(funnelTagId, id);
          setFunnelPopupVisible(false);
        }}
        setFunnel={setFunnelPopupVisible}
        id={activeFunnel.rowId}
        sortArr={sortArr}
        clientX={popupClientX}
        clientY={popupClientY}
      />
      }

      {preview && previewItem &&
      <StartupPreviewPopup
        styles={styles}
        companyName={previewItem.creative.name}
        oneLiner={previewItem?.creative?.answers?.find(
          ({ questionId }) => questionId === "q01_section_info"
        )}
        problem={previewItem?.creative?.answers?.find(
          ({ questionId }) => questionId === "q02_section_info"
        )}
        clientX={popupClientX}
        clientY={popupClientY}
      />
      }
      {viewModal === "Archive" && (
        <ArchiveModal
          ids={
            checkAll ? connections.map(({ id }) => id) : Object.keys(checked)
          }
          close={() => {
            setCheckAll(false);
            setChecked({});
            setViewModal(undefined);
          }}
        />
      )}

      {viewModal === "Add To Group" && (
        <AddToGroupModal
          connections={connections.filter(connection =>
            checkAll ? true : !!checked[connection.id]
          )}
          refetch={refetch}
          close={() => {
            setCheckAll(false);
            setChecked({});
            setViewModal(undefined);
          }}
        />
      )}

      {viewModal === "Add Tags" && (
        <AddTagsModal
          ids={
            checkAll ? connections.map(({ id }) => id) : Object.keys(checked)
          }
          close={() => setViewModal(undefined)}
        />
      )}
    </>
  );
}