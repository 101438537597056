import React, { useState, useEffect } from "react";

// API
import { useMutation,useQuery } from "@apollo/client";
import { groupStartupAdd } from "private/Apollo/Mutations";
import { groupsGetV2 } from "private/Apollo/Queries";

// COMPONENTS
import AddGroup from "./addGroup";
import { Modal, ModalErrorMessage } from "Components/NotataComponents";
import CreateGroup from "../../../../Components/Common/Modals/CreateGroup/CreateGroup";

// MAIN FUNCTION
export default function AddToGroupModalNew({ close, success, connection,history }) {

  // States
  const [selected, setSelect] = useState(undefined);
  const [createGroupModal,setCreateGroupModal] = useState(false)
  const [groups, setGroups] = useState([]);
  const [groupHasPermission, setGroupHasPermission] = useState(true);

  // Mutations 
  const [addStartup, addStartupRes] = useMutation(groupStartupAdd);

  
  const {data, loading, called} = useQuery(groupsGetV2);

  const handlePermission = (selectedGroup) => {
    selectedGroup = selectedGroup.filter(({ iAmAdmin,settings }) => iAmAdmin || settings.addStartup);
    selectedGroup ? setGroupHasPermission(true) : setGroupHasPermission(false)
  }

  useEffect(()=>{
    if(selected) {
      handlePermission([selected])
    }
  }, [selected]);

  useEffect(()=>{
    if(groups && groups.length === 1) {
      setSelect(groups[0])
    }
  }, [groups]);
 
  useEffect(() => {
    
    if (data && data?.groupsGetV2) {
      let groups = data?.groupsGetV2;

      // Remove groups we are already part of
      groups = groups.filter(
        ({ id }) => !connection.groupSharingInfo.some(info => info.group.id === id)
      );

      if(groups?.length === 1){
        setSelect(groups[0]); 
      }
      setGroups(groups);
    }
 },[data, setGroups])
 
  return (
    <>
    <Modal
      title="Add startup to group"
      loading={addStartupRes.loading}
      submit={async () => {

        if(groups.length>0){

          if (!selected) return;

          if (addStartupRes?.loading) {
            return;
          }
          if(!selected?.settings?.addStartup && !selected?.iAmAdmin){
            setGroupHasPermission(false);
            return;
          }

          let variables = {
            groupId: selected.id,
            creativeId: connection?.creative?.id,
          };

          try {
            await addStartup({ variables });
          } catch (error) {
            console.log("error", error);
          }

          setSelect(undefined);

          success ? success() : close();

        } else {
          setCreateGroupModal(true)
        }
      }}
      
      submitButtonStyle={groups.length===0?"mb-big":null}
      close={() => {
        setSelect(undefined);
        close();
      }}

    

      submitTxt={groups.length>0? "ADD" : "CREATE NEW GROUP"}  
      submitButtonSize={groups.length>0? "small" : "mb-big"} 
      closeTxt="CLOSE"
      children={
        <>
          <AddGroup connection={connection} select={group => setSelect(group)} />
          {!groupHasPermission && <ModalErrorMessage msg="You don't have the right privileges to add startups to this group."/>}
        </>
      }
    />
        {createGroupModal && (
          <CreateGroup
            close={() => setCreateGroupModal(undefined)}
            history={history}
          />
        )}
    </>
  );
}
