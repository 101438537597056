import React, { useState, useEffect } from "react";
import "./TableOfContent.scss"
import { useHistory } from "react-router-dom";

function TableOfContentSections({
  sections,
  i_arr,
  help_content,
  active,
  setActive
  }) {

  return (
    <div className="toc__chapter__section_wrapper">
      {
        sections?.map((section, ii) => {

          let id = `${i_arr?.join('-')}-${ii+1}`

          return (
            <div
              key={id}
              className={`toc__chapter__section_wrapper__content`}
            >

              <h3
                id={`p-${id}`}
                onClick={() => setActive(id)}
                className={
                  `${active === id ? "active" : ""}  ${i_arr.length >= 2 && "sub-sub"}`
                }
              >

                <div>
                  {
                    i_arr.map((it, i) => (
                      <span key={i}>&nbsp;&nbsp;</span>
                    ))
                  }
                  {i_arr?.map((i, o) => <span key={o}>{i}.</span>)}{ii+1} {section.title}
                </div>

              </h3>


              {
                (section?.sections && section.sections.length !== 0) && (
                  <TableOfContentSections
                    sections={section.sections}
                    i_arr={[...(i_arr || []), ii+1]}
                    help_content={help_content}
                    active={active}
                    setActive={setActive}
                  />
                )
              }


            </div>
          )
        })
      }
    </div>
  )
}

export default function TableOfContent({ help_content }) {

  const history = useHistory()
  const [ active, setActiveState ] = useState(undefined)


  function setActive(id) {
    history.push(`#pos-${id}`)
    setActiveState(id)
  }

  useEffect(() => {
    let hash = history?.location?.hash;
    if (!hash) { return }
    let [, hashId] = hash.split('pos-')
    setActiveState(hashId)
  }, [history?.location?.hash])

  return (
    <div className="toc">
      <div className="toc__inner">
        {
          help_content?.map((chapter, i) => {
            return (
              <div
                className="toc__chapter"
                key={`chapter-${i+1}`}
              >
                <h2
                  onClick={() => setActive(`${i}`)}
                  className={
                    `${active == i ? "active" : ""}`
                  }
                >
                  <div>
                    {i+1} {chapter.title}
                  </div>
                </h2>

                {
                  chapter?.sections?.length !== 0 && (
                    <TableOfContentSections
                      sections={chapter.sections}
                      i_arr={[i+1]}
                      active={active}
                      setActive={setActive}
                    />
                  )
                }

              </div>
            )
          })
        }
      </div>
    </div>
  )
}