import React from "react";
import { Card, NotataButton } from "Components/NotataComponents";
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";
import { getScore, getEvaluated } from "utils/groupSharing";
import { cloneDeep } from "lodash";

export default function GridView({
     group,
     history,
     setSubjectiveModal,
     setSelectedStartUp,
     setEvaluateModal,
     setViewStartup
  }) {

  let groupId = group?.id;

  const { evaluationTemplate, publicSettings } = group || {};
  const { withEvaluationTemplate, withSubjectiveScores } = publicSettings || {};
  const showSubjectiveScore = withSubjectiveScores;
  const showEvaluation = withEvaluationTemplate && evaluationTemplate;

  let startups = cloneDeep(group?.startups);
  startups = startups?.sort((a, b) => {
    if(a?.name?.trim().toLowerCase() < b?.name?.trim().toLowerCase()) { return -1; }
    if(a?.name?.trim().toLowerCase() > b?.name?.trim().toLowerCase()) { return 1; }
    return 0;
  })

  return (
    <div
      className="group-share-container__content__startups group-share-container__content__startups"
    >
      {startups?.map(startup => {
          const isEvaluated = getEvaluated(startup, groupId);
          const score = getScore(startup, groupId);
          return (
            <div
              key={startup?.id}
              className="group-share-container__content__startups__startup"
            >
              <Card size="small">

                <div
                  className="group-share-container__content__startups__startup__header"
                  onClick={() => setViewStartup(startup.id)}
                >
                  <StartupLogo
                    size="mini"
                    startupName={startup?.name}
                    url={startup?.logo}
                  />
                  {startup?.name}
                </div>

                <div className="group-share-container__content__startups__startup__description">
                  {startup?.description}
                </div>
                <div className="group-share-container__content__startups__startup__more-info">
                  <div
                    onClick={() => {
                      setViewStartup(startup.id)
                    }}
                  >More info</div>
                </div>

                <div className="group-share-container__content__startups__startup__button-container">
                  {showEvaluation && (
                    <NotataButton
                      bgcolor={!isEvaluated && "primary_color"}
                      buttonStyle={isEvaluated && 'outlined medium'}
                      onClick={() => {
                        setEvaluateModal(true);
                        setSelectedStartUp(startup);
                      }}
                      text={isEvaluated ? "Evaluated" : "Evaluate"}
                    />
                  )}
                  {showSubjectiveScore && (
                    <NotataButton
                      bgcolor={!score && "primary_color"}
                      buttonStyle={score && 'outlined medium'}
                      text={score || "Score"}
                      style={
                        score && {color: "#C4A9FC"}
                      }
                      onClick={() => {
                        setSubjectiveModal(true)
                        setSelectedStartUp(
                          {
                            ...startup,
                            subjectiveScores: [
                              {
                                isMe: true,
                                score: score
                              }
                            ]
                          }
                        )
                      }}
                    />
                  )}
                </div>
              </Card>
            </div>
          )
        }
      )}

    </div>
  )
}