export const ICONPOSITION = { START: "START", END: "END", NONE: "NONE" };
// export const OVERVIEWPAGESTATE = {
//   OVERVIEW: "OVERVIEW",
//   SHARETEMPLATE: "SHARETEMPLATE",
//   ARCHIVElIST: "ARCHIVElIST",
// };
export const REPORTCHARTS = {
  BAR: "BAR",
  COLUMN: "COLUMN",
  PIE: "PIE",
  LIST: "LIST" // List and column are same
};

export const SETTINGSMENU = {
  HOME: "HOME",
  EVALUATIONTEMPLATE: "EVALUATIONTEMPLATE",
  TAGS: "TAGS",
  FUNNELS: "FUNNELS",
  YOURTEAM: "YOURTEAM",
  WEBFORM: "WEBFORM",
  USERPROFILE: "USERPROFILE",
  ACCOUNTSETTINGS: "ACCOUNTSETTINGS",
  NOTIFICATIONS: "NOTIFICATIONS",
};

export const CHARTCOLORS = [
  {
    colorStart: "#ff725e",
    offsetStart: "0%",
    colorStop: "#ffad88",
    offsetStop: "100%",
  },
  {
    colorStart: "#73c5b2",
    offsetStart: "0%",
    colorStop: "#96e4d5",
    offsetStop: "100%",
  },
  {
    colorStart: "#9e80dd",
    offsetStart: "0%",
    colorStop: "#c7adff",
    offsetStop: "100%",
  },
  {
    colorStart: "#f5c269",
    offsetStart: "0%",
    colorStop: "#ffd898",
    offsetStop: "100%",
  },
  {
    colorStart: "#90e074",
    offsetStart: "0%",
    colorStop: "#b3ff78",
    offsetStop: "100%",
  },
  {
    colorStart: "#FFAB87",
    offsetStart: "0%",
    colorStop: "#FF7560",
    offsetStop: "100%",
  },
  {
    colorStart: "#82E3D0",
    offsetStart: "20.62%",
    colorStop: "#5AB8A5",
    offsetStop: "83.68%",
  },
  {
    colorStart: "#FDC75E",
    offsetStart: "0%",
    colorStop: "#FFD89D",
    offsetStop: "100%",
  },
  {
    colorStart: "#000000",
    offsetStart: "0%",
    colorStop: "#e056fd",
    offsetStop: "100%",
  },
  {
    colorStart: "#fb7ba2",
    offsetStart: "0%",
    colorStop: "#fce043",
    offsetStop: "100%",
  },
];

export const NOTATATOURCALLERS = {
  DASHBOARD : "DASHBOARD",
  DASHBOARDHOME : "DASHBOARDHOME",
  /* MYSTARTUPS : "MYSTARTUPS", */
  COMPANYPAGESTATRUPINFO: "COMPANYPAGESTATRUPINFO",
  COMPANYPAGEOVERVIEW: "COMPANYPAGEOVERVIEW",
  COMPANYPAGEGROUPS: "COMPANYPAGEGROUPS",
  COMPANYPAGEEVALUATION: "COMPANYPAGEEVALUATION",
  GROUPS : "GROUPS",
  /* GROUPSDASHBOARD: "GROUPSDASHBOARD",  */
  REPORTS: "REPORTS",
  EVALUATIONTEMPLATES: "EVALUATIONTEMPLATES",
  CREATEEVALUATIONTEMPLATES : "CREATEEVALUATIONTEMPLATES",
  SETTINGSFUNNEL : "SETTINGSFUNNEL",
  SETTINGS : "SETTINGS",
  SETTINGSTAGS :"SETTINGSTAGS",
  SETTINGSYOURTEAM : "SETTINGSYOURTEAM",
  SETTINGSWEBFORM :"SETTINGSWEBFORM"
}

export const NOTIFICATIONS_POLL_INTERVAL = 15000;

// Components-->Common -->Notifications
export const TEAM_COMMENT = "TEAM_COMMENT";
export const TEAM_NEW_STARTUP = "TEAM_NEW_STARTUP";
export const TEAM_EVALUATION_CREATE = "TEAM_EVALUATION_CREATE";
export const GROUP_COMMENT = "GROUP_COMMENT";
export const GROUP_INVITATION = "GROUP_INVITATION";
export const GROUP_JOIN = "GROUP_JOIN";
export const GROUP_LEAVE = "GROUP_LEAVE";
export const GROUP_EVALUATION_ADD = "GROUP_EVALUATION_ADD";
export const GROUP_INVITATION_RESPONSE = "GROUP_INVITATION_RESPONSE";
export const GROUP_ROLE_CHANGE = "GROUP_ROLE_CHANGE";
export const GROUP_STARTUP_ADD = "GROUP_STARTUP_ADD";
export const GROUP_TEMPLATE_ADD = "GROUP_TEMPLATE_ADD";
export const ACCOUNT_INVITATION = "ACCOUNT_INVITATION";
export const ACCOUNT_INVITATION_NOTIFICATION ="ACCOUNT_INVITATION_NOTIFICATION";
export const ACCOUNT_INVITATION_RESPONSE = "ACCOUNT_INVITATION_RESPONSE";
export const ACCOUNT_USER_REMOVE = "ACCOUNT_USER_REMOVE";
export const PUBLIC_CREATIVE_CREATE = "PUBLIC_CREATIVE_CREATE";
export const PUBLIC_CREATIVE_UPDATE = "PUBLIC_CREATIVE_UPDATE";
export const CONNECTION_CREATE = "CONNECTION_CREATE";

export const ASC = 'ASC';
export const DESC = 'DESC';

export const VIEWS = {
  GRID :"GRID",
  LIST:"LIST",
  FULL : "FULL"
}

export const MANAGERESOURCESVIEW = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER"
}