import gql from "graphql-tag";
import { EvaluationFields } from "../NewFragments";

export default gql`
    
  mutation evaluationUpdate($id: String!, $answers: [EvaluationV2AnswerInput]) {
    evaluationUpdate(id: $id, answers: $answers) {
      ...EvaluationFields
    }
  }
  ${EvaluationFields}
`;
