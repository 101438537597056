import gql from "graphql-tag";

export default gql`
    fragment SimpleUserFields on SimpleUser {
      email
      given_name
      family_name
      company
      isMe
    }
`