import React, { useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

// UI COMPONENT
import { InputForm, NotataButton } from "Components/NotataComponents";

// STYLES
import "../LoginPages.scss";

// ASSETS
import man_standing from "assets/images/man_standing.svg";
import notata from "assets/images/auth-logo.svg";

// CUSTOM COMPONENTS
import { userLoggedIn } from "actions/user";
import { getUserIsLoggedIn } from "reducers/selectors/user";

// DEFINITIONS
import { startup_page, login } from "definitions.js";

// OTHERS
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { Message } from "../Message/Message";
import { Auth } from "aws-amplify";

function SignupComp({ history, userIsLoggedIn }) {

  // STATES
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState(4);
  const [validate, setValidate] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [password, setPassword] = useState("");

  // FORMS
  const listForm = ["email", "password", "confirmPassword"];
  const { register, handleSubmit, formState, errors } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required().trim(),
        password: yup.string().min(8).required(),
        confirmPassword: yup.string().oneOf([yup.ref("password")]).required(),
      })
    ),
  });
  const { isSubmitting } = formState;

  // NAVIGATION
  if (userIsLoggedIn) {
    history.push(startup_page);
  }

  // FUNCTIONS

  // onsubmit function
  function onSubmit(data) {
    console.log("data: ", data)
    let { email, password } = data;
    email = email.toLowerCase().trim();
    setIsLoading(true);
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: "",
        family_name: ""
      },
    })
      .then(res => {
        setRedirect(true);
        setIsLoading(false);
      })
      .catch(error => {
        setRedirect(false);
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  }

  // setnextflag function
  const setNextFlag = index => {
    setPosition(index === "email" ? 1 : index === "password" ? 2 : 3);
  };

  // handleInputChange function
  const handleInputChange = (val, name) => {
    if (name === "email") {
      setErrorMessage("");
    }
    if (name === "password") {
      setPassword(val);
    }
  };

  return (
    <>
      {redirect ? (
        <Message
          heading={"We have sent you an email 🚀"}
          subHead1={
            "To be able to log in you have to verify your identity by clicking the link in the email."
          }
          image={man_standing}
          history={history}
          path={login}
        />
      ) : (

        <div className='auth_structure'>

          <div className="auth_structure__left">
            <div className='auth_structure__left__mainContent'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='logoContainer'>
                  <div className="logo_content">
                    <img
                      src={notata}
                      alt="logo"
                      className='logo'
                    />
                  </div>
                  <h1>Sign up</h1>
                </div>

                {/* Input fields  */}
                <InputForm
                  label="Email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  position={listForm[position]}
                  setNextFlag={setNextFlag}
                  validate={validate}
                  required={true}
                  errorMessage={errorMessage}
                  handleInputChange={(value, name) =>
                    handleInputChange(value, name)
                  }
                  reference={register({ required: true })}
                />
                <InputForm
                  label="Password"
                  type="password"
                  placeholder="Password"
                  name="password"
                  position={listForm[position]}
                  setNextFlag={setNextFlag}
                  validate={validate}
                  storngPswd={true}
                  required
                  handleInputChange={(value, name) =>
                    handleInputChange(value, name)
                  }
                  reference={register({ required: true })}
                />
                <InputForm
                  label="Confirm Password"
                  type="password"
                  placeholder="Password"
                  name="confirmPassword"
                  handleInputChange={(value, name) =>
                    handleInputChange(value, name)
                  }
                  position={listForm[position]}
                  setNextFlag={setNextFlag}
                  passwordConfirm={true}
                  validate={validate}
                  primaryPwdVal={password}
                  required={true}
                  reference={register({ required: true })}
                />
                <NotataButton
                  size="xx_large"
                  bgcolor="primary_color"
                  text="SIGN UP"
                  buttonType="submit"
                  loading={isSubmitting || isLoading}
                  onClick={() => setValidate(true)}
                />
              </form>
              <div className="separator">OR</div>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <Link
                  to={login}
                  style={{ textDecoration: "none", color: "#969BA3" }}
                >
                  Already on Notata?{" "}
                  <u style={{ fontWeight: "600" }}>Sign in</u>
                </Link>
              </div>
            </div>
          </div>
          <div className='auth_structure__right'>
            <img src={man_standing} alt="auth_image" />
          </div>
        </div>
      )}
    </>
  );
}

// main function
export const Signup = connect(
  state => ({
    userIsLoggedIn: getUserIsLoggedIn(state),
  }),
  {
    userLoggedIn,
  }
)(SignupComp);