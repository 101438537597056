//eslint-disable-next-line
export const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//eslint-disable-next-line
export const url = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
//export const number = /^(\+\d{1,3}[- ]?)?\d{8,10}$/;
export const number = /\+\d{1,3}[^\S]\d{8,10}$/;
//export const number = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
//eslint-disable-next-line
export const password = /^(?=.{8})/;

//to validate url
export const urlPattern = /https?|ftp|smtp:\/\/w{0,3}\w*?\.(\w*?\.)?\w{2,3}\S*|www\.(\w*?\.)?\w*?\.\w{2,3}\S*|(\w*?\.)?\w*?\.\w{2,3}[\/\?]\S*/
