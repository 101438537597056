// Constant
export const defaultFilters = {
  search: "",
  tags: [],
  funnelTag: null,
  fromDate: null,
  toDate: null,
  starred: null,
  sortBy: null,
  sortDirection: null,
};

export const defaultColumnValues = {
  funnels: true,
  tags: true,
  subjectiveScore: true,
  createdAt: true,
  updatedAt: false,
  evaluationTemplates: [],
  tagGroups: []
}

export const columnOptions = [
  { value: "Groups", name: "groups" },
  { value: "Funnel Stage", name: "funnels" },
  { value: "Tags", name: "tags" },
  { value: "Subjective Score", name: "subjectiveScore" },
  { value: "Created at", name: "createdAt" },
  { value: "Updated at", name: "updatedAt" },
];