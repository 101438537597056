import React, {useState}  from 'react'

// API STUFF
import { useMutation } from "@apollo/client";
import { groupUserInvitationRemove } from "private/Apollo/Mutations";

// MAIN FUNCTION
export default function PendingMembers({ pendingMembers, group, canRemove, header, isPublic }) {
  // STATES
  const [isRemovingInv, setIsRemovingInv] = useState({});
  // MUTATIONS
  const [invitationRemove] = useMutation(groupUserInvitationRemove);
  const removeInvitation = async(email) =>{
    if (isRemovingInv[email]) {
      return;
    }
    setIsRemovingInv({
      ...isRemovingInv,
      [email]: true,
    });

    let variables = {
      groupId: group.id,
      email: email,
    };
    try {
      await invitationRemove({ variables });
    } catch (error) {
      console.log("error", error);
    }

    setIsRemovingInv({
      ...isRemovingInv,
      [email]: false,
    });
  }
  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">{header}</span>
      <div className="invite-member-modal__member-container__data">
        {pendingMembers?.map((member, index) => {
          let email = isPublic ? member : member.email;
          return <div className="invite-member-modal__member-container__member" key={`group-memebr-key-${index}`}>
            {canRemove && (
              <i
                className={`${isRemovingInv[email]
                  ? "fa fa-spinner fa-spin icon"
                  : "fas fa-trash-alt"
                }`
                }
                aria-hidden="true"
                onClick={async () => await removeInvitation(email)}
              />
            )}
            <div className="invite-member-modal__member-container__member__info">
              <div className="invite-member-modal__member-container__member__info__name">{email}</div>
            </div>
          </div>
        })}
      </div>
    </div>
  )
}