import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";


import { addToWaitingList } from "../../../../public/Apollo/Mutations"


// UI COMPONENT
import { InputForm, NotataButton } from "Components/NotataComponents";

// STYLES
import "../LoginPages.scss";

// ASSETS
import man_standing from "assets/images/man_standing.svg";
import notata from "assets/images/auth-logo.svg";


// DEFINITIONS
import { login } from "definitions.js";
import { useMutation } from "@apollo/client";
import { Message } from "../Message/Message";


export default function MailingList() {

  // STATES
  const [errorMessage, setErrorMessage] = useState();
  const [validate, setValidate] = useState(false);

  // FORMS
  const { register, handleSubmit } = useForm();


  // MUTATIONS
  const API_options = { context: { clientName: "public" } }
  const [addMutation, { loading, data }] = useMutation(addToWaitingList, API_options)


  // FUNCTIONS

  // onsubmit function
  async function onSubmit({email}) {
    email = email.trim().toLowerCase()
    let variables = { email }
    let res = await addMutation({variables})
    console.log("res", res)
  }


  // handleInputChange function
  const handleInputChange = (val, name) => {
    if (name === "email") {
      setErrorMessage("");
    }
  };


  if (data && !loading) {
    return (
      <Message
        heading={"Thank you!"}
        subHead1={
          "We have now put your email on our waiting list, and will get back to you when we open up for a new batch of users shortly! We can't wait to have you in our community!"
        }
        image={man_standing}
        buttonText={"OK"}
      />
    )
  }


  return (
    <>
      <div className='auth_structure'>
        <div className="auth_structure__left">
          <div className='auth_structure__left__mainContent'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='logoContainer'>
                <div className="logo_content">
                  <img
                    src={notata}
                    alt="logo"
                    className='logo'
                  />
                </div>
                <h1>Get on the list 🎉</h1>
                <p>Sign up to the waiting list to be the first in line when we open for signups.</p>
              </div>

              {/* Input fields  */}
              <InputForm
                type="email"
                name="email"
                placeholder="Email"
                validate={validate}
                required={true}
                errorMessage={errorMessage}
                handleInputChange={(value, name) =>
                  handleInputChange(value, name)
                }
                reference={register({ required: true })}
              />
              <NotataButton
                size="xx_large"
                bgcolor="primary_color"
                text="Join the list"
                buttonType="submit"
                loading={loading}
                onClick={() => setValidate(true)}
              />
            </form>

            <div className="separator">OR</div>
            <div
              style={{
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              <Link
                to={login}
                style={{ textDecoration: "none", color: "#969BA3" }}
              >
                Already on Notata?{" "}
                <u style={{ fontWeight: "600" }}>Sign in</u>
              </Link>
            </div>
          </div>

        </div>
        <div className='auth_structure__right'>
          <img src={man_standing} alt="auth_image" />
        </div>
      </div>
    </>
  );
}
