import React from "react"

  
export const nav_group = () => {
    return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="itm_1" d="M15.4018 9.60938H14.0098C14.1517 9.99785 14.2292 10.4171 14.2292 10.854V16.1152C14.2292 16.2974 14.1975 16.4722 14.1398 16.6349H16.4411C17.3008 16.6349 18.0002 15.9355 18.0002 15.0758V12.2077C18.0002 10.775 16.8346 9.60938 15.4018 9.60938Z" fill="#BFBFBF"/>
    <path id="itm_1" d="M3.77104 10.854C3.77104 10.4171 3.84856 9.99785 3.99045 9.60938H2.5984C1.16564 9.60938 0 10.775 0 12.2078V15.0759C0 15.9355 0.699365 16.6349 1.55904 16.6349H3.86044C3.80275 16.4722 3.77104 16.2974 3.77104 16.1152V10.854Z" fill="#BFBFBF"/>
    <path id="itm_1" d="M10.5914 8.25391H7.40895C5.97619 8.25391 4.81055 9.41955 4.81055 10.8523V16.1135C4.81055 16.4004 5.04321 16.6331 5.33023 16.6331H12.6701C12.9571 16.6331 13.1898 16.4005 13.1898 16.1135V10.8523C13.1898 9.41955 12.0241 8.25391 10.5914 8.25391Z" fill="#BFBFBF"/>
    <path id="itm_1" d="M8.9999 1.36719C7.27682 1.36719 5.875 2.76901 5.875 4.49213C5.875 5.6609 6.52008 6.68166 7.47275 7.21759C7.92461 7.47177 8.44556 7.61703 8.9999 7.61703C9.55424 7.61703 10.0752 7.47177 10.5271 7.21759C11.4798 6.68166 12.1248 5.66086 12.1248 4.49213C12.1248 2.76905 10.723 1.36719 8.9999 1.36719Z" fill="#BFBFBF"/>
    <path id="itm_1" d="M3.5128 4.27734C2.22414 4.27734 1.17578 5.32571 1.17578 6.61436C1.17578 7.90302 2.22414 8.95138 3.5128 8.95138C3.83968 8.95138 4.15096 8.88367 4.43372 8.76196C4.9226 8.55148 5.3257 8.17889 5.57514 7.71219C5.75022 7.38464 5.84981 7.01096 5.84981 6.61436C5.84981 5.32574 4.80145 4.27734 3.5128 4.27734Z" fill="#BFBFBF"/>
    <path id="itm_1" d="M14.4874 4.27734C13.1988 4.27734 12.1504 5.32571 12.1504 6.61436C12.1504 7.011 12.25 7.38467 12.4251 7.71219C12.6745 8.17893 13.0776 8.55151 13.5665 8.76196C13.8492 8.88367 14.1605 8.95138 14.4874 8.95138C15.7761 8.95138 16.8244 7.90302 16.8244 6.61436C16.8244 5.32571 15.7761 4.27734 14.4874 4.27734Z" fill="#BFBFBF"/>
    </svg>
  }
export const nav_newsicon = () => {
return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="itm_1" d="M0 8.47266H3.74013C3.77476 7.15236 3.9362 5.90854 4.20543 4.78125H1.09417C0.498691 5.89271 0.0778359 7.14516 0 8.47266H0Z" fill="#BFBFBF"/>
<path id="itm_1" d="M3.74013 9.52734H0C0.0778359 10.8548 0.498691 12.1073 1.09417 13.2188H4.20546C3.93623 12.0915 3.77476 10.8476 3.74013 9.52734Z" fill="#BFBFBF"/>
<path id="itm_1" d="M8.47234 8.47266V4.78125H5.29559C5.00696 5.89837 4.83216 7.15352 4.79395 8.47266H8.47234Z" fill="#BFBFBF"/>
<path id="itm_1" d="M8.47189 3.72656V0C7.31712 0.322523 6.29421 1.798 5.61914 3.72656H8.47189Z" fill="#BFBFBF"/>
<path id="itm_1" d="M8.47189 18V14.2734H5.61914C6.29421 16.202 7.31712 17.6775 8.47189 18Z" fill="#BFBFBF"/>
<path id="itm_1" d="M9.52734 0V3.72656H12.3801C11.705 1.798 10.6821 0.322523 9.52734 0Z" fill="#BFBFBF"/>
<path id="itm_1" d="M13.2057 8.47266C13.1675 7.15352 12.9927 5.89837 12.7041 4.78125H9.52734V8.47266H13.2057Z" fill="#BFBFBF"/>
<path id="itm_1" d="M8.47234 9.52734H4.79395C4.8322 10.8465 5.00696 12.1016 5.29559 13.2188H8.47234V9.52734Z" fill="#BFBFBF"/>
<path id="itm_1" d="M13.5006 3.72583H16.234C15.1424 2.23264 13.6057 0.971164 11.8154 0.376953C12.5035 1.1892 13.0789 2.41064 13.5006 3.72583Z" fill="#BFBFBF"/>
<path id="itm_1" d="M4.49902 14.2734H1.76562C2.85719 15.7666 4.39391 17.0281 6.1842 17.6223C5.49616 16.8101 4.92069 15.5886 4.49902 14.2734Z" fill="#BFBFBF"/>
<path id="itm_1" d="M4.49902 3.72583C4.92065 2.41064 5.49616 1.1892 6.18417 0.376953C4.39391 0.971164 2.85719 2.23264 1.76562 3.72583H4.49902Z" fill="#BFBFBF"/>
<path id="itm_1" d="M13.5006 14.2734C13.0789 15.5886 12.5034 16.8101 11.8154 17.6223C13.6057 17.0281 15.1424 15.7666 16.234 14.2734H13.5006Z" fill="#BFBFBF"/>
<path id="itm_1" d="M9.52734 9.52734V13.2188H12.7041C12.9927 12.1016 13.1675 10.8465 13.2057 9.52734H9.52734Z" fill="#BFBFBF"/>
<path id="itm_1" d="M13.7949 4.78125C14.0641 5.90854 14.2256 7.15236 14.2602 8.47266H18.0003C17.9225 7.14516 17.5017 5.89271 16.9062 4.78125H13.7949Z" fill="#BFBFBF"/>
<path id="itm_1" d="M9.52734 14.2734V18C10.6821 17.6775 11.705 16.202 12.3801 14.2734H9.52734Z" fill="#BFBFBF"/>
<path id="itm_1" d="M18.0003 9.52734H14.2602C14.2256 10.8476 14.0641 12.0915 13.7949 13.2188H16.9062C17.5017 12.1073 17.9225 10.8548 18.0003 9.52734Z" fill="#BFBFBF"/>
</svg>
}
export const nav_report = () => {
return  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* clip-path="url(#clip0)" */}
<g>
    <path id="itm_1" d="M3.16699 5.34375C3.16699 3.27038 4.85403 1.58333 6.92741 1.58333H14.1577C13.8972 0.672125 13.0668 0 12.0732 0H2.96908C1.76812 0 0.791992 0.976125 0.791992 2.17708V14.4479C0.791992 15.6489 1.76812 16.625 2.96908 16.625H3.16699V5.34375Z" fill="#BFBFBF"/>
    <path id="itm_1" d="M16.0312 3.16797H6.92708C5.72612 3.16797 4.75 4.14409 4.75 5.34505V16.8242C4.75 18.0252 5.72612 19.0013 6.92708 19.0013H16.0312C17.2322 19.0013 18.2083 18.0252 18.2083 16.8242V5.34505C18.2083 4.14409 17.2322 3.16797 16.0312 3.16797ZM14.4479 16.6263H8.51042C8.18267 16.6263 7.91667 16.3603 7.91667 16.0326C7.91667 15.7048 8.18267 15.4388 8.51042 15.4388H14.4479C14.7757 15.4388 15.0417 15.7048 15.0417 16.0326C15.0417 16.3603 14.7757 16.6263 14.4479 16.6263ZM14.4479 13.4596H8.51042C8.18267 13.4596 7.91667 13.1936 7.91667 12.8659C7.91667 12.5381 8.18267 12.2721 8.51042 12.2721H14.4479C14.7757 12.2721 15.0417 12.5381 15.0417 12.8659C15.0417 13.1936 14.7757 13.4596 14.4479 13.4596ZM14.4479 10.6888H8.51042C8.18267 10.6888 7.91667 10.4228 7.91667 10.0951C7.91667 9.76731 8.18267 9.50131 8.51042 9.50131H14.4479C14.7757 9.50131 15.0417 9.76731 15.0417 10.0951C15.0417 10.4228 14.7757 10.6888 14.4479 10.6888ZM14.4479 7.52214H8.51042C8.18267 7.52214 7.91667 7.25614 7.91667 6.92839C7.91667 6.60064 8.18267 6.33464 8.51042 6.33464H14.4479C14.7757 6.33464 15.0417 6.60064 15.0417 6.92839C15.0417 7.25614 14.7757 7.52214 14.4479 7.52214Z" fill="#BFBFBF"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="19" height="19" fill="red"/>
</clipPath>
</defs>
</svg>
}
export const nav_suitcase = () => {
return <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path id="itm_1" d="M9.20194 9.31445H7.79869C7.70453 9.31445 7.62793 9.39105 7.62793 9.48522V10.8948C7.62793 10.9889 7.70453 11.0655 7.79869 11.0655H9.20194C9.2961 11.0655 9.3727 10.9889 9.3727 10.8948V9.48522C9.3727 9.39105 9.2961 9.31445 9.20194 9.31445Z" fill="#BFBFBF"/>
<path id="itm_1" d="M14.3774 3.55201H11.4832V2.34099C11.4832 1.70817 10.9635 1.19336 10.3246 1.19336H6.67542C6.03653 1.19336 5.51677 1.70817 5.51677 2.34099V3.55204H2.62262C1.17652 3.55201 0 4.72853 0 6.17466V7.74653C0.0973516 8.22057 0.439609 8.61074 0.913252 8.76231L6.62851 10.1591V9.48551C6.62851 8.84044 7.15331 8.31563 7.79838 8.31563H9.20162C9.84669 8.31563 10.3715 8.84044 10.3715 9.48551V10.1591L16.0867 8.76231C16.5604 8.61074 16.9026 8.22057 17 7.74653V6.17466C17 4.72853 15.8235 3.55201 14.3774 3.55201ZM10.4841 3.55204H6.51591V2.34099C6.51591 2.25632 6.58448 2.19247 6.67542 2.19247H10.3246C10.4155 2.19247 10.4841 2.25632 10.4841 2.34099V3.55204Z" fill="#BFBFBF"/>
<path id="itm_1" d="M16.3685 9.72128C16.3589 9.72423 16.3493 9.72689 16.3396 9.72925L10.3317 11.1976C10.1981 11.6965 9.74216 12.0651 9.20162 12.0651H7.79838C7.25784 12.0651 6.80193 11.6965 6.66832 11.1976L0.660377 9.72925C0.650682 9.72686 0.641053 9.7242 0.63149 9.72128C0.40209 9.65039 0.190387 9.54716 0 9.41797V13.6644C0 14.8457 0.960998 15.8067 2.14227 15.8067H14.8577C16.039 15.8067 17 14.8457 17 13.6644V9.41797C16.8096 9.54716 16.5979 9.65039 16.3685 9.72128Z" fill="#BFBFBF"/>
</svg>
}
export const nav_bar = () => {
return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
{/* clip-path="url(#clip0)" */}
<g>
<path id="itm_1" d="M3.67352 7.33203H0.34018C0.15618 7.33203 0.00683594 7.48137 0.00683594 7.66538V15.6654C0.00683594 15.8494 0.15618 15.9987 0.34018 15.9987H3.67352C3.85752 15.9987 4.00687 15.8494 4.00687 15.6654V7.66534C4.00684 7.48134 3.85818 7.33203 3.67352 7.33203Z" fill="#BFBFBF"/>
<path id="itm_1" d="M9.65986 0H6.32651C6.14251 0 5.99316 0.149344 5.99316 0.333344V15.6667C5.99316 15.8507 6.14251 16 6.32651 16H9.65986C9.84386 16 9.99321 15.8507 9.99321 15.6667V0.333344C9.99321 0.149344 9.84386 0 9.65986 0Z" fill="#BFBFBF"/>
<path id="itm_1" d="M15.6598 3.33203H12.3265C12.1425 3.33203 11.9932 3.48137 11.9932 3.66537V15.6654C11.9932 15.8494 12.1425 15.9987 12.3265 15.9987H15.6598C15.8438 15.9987 15.9932 15.8494 15.9932 15.6654V3.66537C15.9932 3.48134 15.8438 3.33203 15.6598 3.33203Z" fill="#BFBFBF"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
}

export const nav_setting = () =>{
    return   <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="itm_1" d="M18.5606 11.9985L16.5561 10.4262C16.5941 10.1222 16.6226 9.81348 16.6226 9.49998C16.6226 9.18648 16.5941 8.87774 16.5561 8.57373L18.5654 7.00146C18.7458 6.85896 18.7981 6.60246 18.6794 6.39348L16.7793 3.10173C16.6606 2.89747 16.4136 2.81197 16.1999 2.89747L13.8344 3.85222C13.3451 3.47695 12.8084 3.15873 12.2289 2.91648L11.8726 0.399C11.8298 0.175765 11.6351 0 11.3976 0H7.59763C7.36014 0 7.16536 0.175765 7.12738 0.399L6.77113 2.91648C6.19164 3.15873 5.65487 3.47223 5.16565 3.85222L2.80015 2.89747C2.5864 2.81673 2.33938 2.89747 2.22066 3.10173L0.320605 6.39348C0.20184 6.59774 0.25412 6.85424 0.434605 7.00146L2.43909 8.57373C2.4011 8.87774 2.3726 9.18648 2.3726 9.49998C2.3726 9.81348 2.4011 10.1222 2.43909 10.4262L0.434605 11.9985C0.25412 12.141 0.20184 12.3975 0.320605 12.6065L2.22062 15.8982C2.33938 16.1025 2.58635 16.188 2.8001 16.1025L5.1656 15.1477C5.65487 15.523 6.1916 15.8412 6.77108 16.0835L7.12733 18.601C7.16532 18.8242 7.3601 19 7.59758 19H11.3976C11.6351 19 11.8298 18.8242 11.8678 18.601L12.2241 16.0835C12.8035 15.8412 13.3403 15.5277 13.8295 15.1477L16.195 16.1025C16.4088 16.1832 16.6558 16.1025 16.7745 15.8982L18.6745 12.6065C18.7934 12.4023 18.7411 12.1458 18.5606 11.9985ZM9.4976 12.825C7.65935 12.825 6.17259 11.3382 6.17259 9.49998C6.17259 7.66173 7.65935 6.17501 9.4976 6.17501C11.3358 6.17501 12.8226 7.66177 12.8226 9.50002C12.8226 11.3383 11.3358 12.825 9.4976 12.825Z" fill="#BFBFBF"/>
    </svg>
}