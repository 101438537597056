import React, { useEffect, useState } from "react";

// API STUFF
import { useLazyQuery, useMutation } from "@apollo/client";
import { groupUserInvite } from "private/Apollo/Mutations";

// UI COMPONENTS
import { NotataButton } from "Components/NotataComponents";
import { groupGetV2 } from "../../../Apollo/Queries";
import { omit } from "lodash";
import defaultData from "../_defaultGroupData";

// MAIN FUNCTION
export default function GroupPublicMembersImport({
     fromGroupId,
     canInvite,
     preMembers,
     addPublicUser
   }) {


  // States
  const [ loadingButtons, setLoadingButtons ] = useState({})

  // Queries
  let [getGroup, {data, error, loading}] = useLazyQuery(groupGetV2)


  // Effects
  useEffect(() => {
    if (fromGroupId) {
      let variables = { id: fromGroupId }
      getGroup({variables})
    }
  }, [fromGroupId])


  // Maps and reducers
  const group = data?.groupGetV2


  if (loading) {
    return (
      <span/>
    )
  }

  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">
        Import users
      </span>

      <div className="invite-member-modal__member-container__data">

        {
          (
            !group?.publicSettings?.invitedUsers ||
            group?.publicSettings?.invitedUsers?.length === 0
          ) && (
            <p className="message--not-found" >
              This group has no members...
            </p>
          )
        }

        {
          group?.publicSettings?.invitedUsers?.map((email, index) => {


            let isAlreadyInvited = preMembers?.some(pm => pm === email);

            return (
              <div
                className="invite-member-modal__member-container__member"
                key={`group-memebr-key-${index}`}
              >

                <div className="invite-member-modal__member-container__member__info">
                  <div className="invite-member-modal__member-container__member__info__name">
                    {email}
                  </div>
                </div>

                <NotataButton
                  text={isAlreadyInvited ? 'invited' : "invite"}
                  bgcolor={isAlreadyInvited ? "outlined" : "primary_color"}
                  size="medium invite-member-modal__member-container__member__invite-btn"
                  loading={loadingButtons[email]}
                  onClick={async () => {

                    if (isAlreadyInvited || !canInvite) {
                      return
                    }
                    setLoadingButtons({ ...loadingButtons, [email]: true })
                    await addPublicUser(email)
                    setLoadingButtons({ ...loadingButtons, [email]: false })
                  }}
                />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}