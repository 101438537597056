import React, { useState, useRef } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { connectionCreate, creativeCreate } from "private/Apollo/Mutations";
import { connectionAutoCompleteName } from "private/Apollo/Queries";

// COMPONENTS
import { useForm } from "react-hook-form";
import { InputForm } from "Components/NotataComponents/InputForm/InputForm";
import { NotataButton, CheckBox } from "Components/NotataComponents";

// DEFINITIONS
import { startup_page } from "definitions";

//OTHERS
import _ from "lodash";
import { appsyncClient } from "../../../../awsconfig";

// *****************
// * MAIN FUNCTION *
// *****************

export const AddStartup = ({ history, closeModal, styles, setFilters }) => {
  const inputRef = useRef(null);
  // States
  const [existedFlag, setExistedFlag] = useState(undefined);
  const [connectionId, setconnectionId] = useState("");
  const [createAnother, setCreateAnother] = useState(false);
  const [startupsList, setStartupsList] = useState([]);
  // Form
  const { register, handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  // Mutations
  const [mutateCreative] = useMutation(creativeCreate);
  const [mutateConnectionCreate] = useMutation(connectionCreate);

  const debounced = _.debounce(
    value => {
      appsyncClient
        .query({
          query: connectionAutoCompleteName,
          variables: {
            search: value,
          },
        })
        .then(result => {
          if (result?.data?.connectionAutoCompleteName) {
            setExistedFlag(
              result?.data?.connectionAutoCompleteName[0]?.creativeName
            );
            setconnectionId(
              result?.data?.connectionAutoCompleteName[0]?.connectionId
            );
          } else {
            setconnectionId("");
            setExistedFlag(undefined);
          }
        });
    },
    // delay in ms
    10
  );


  // Look for duplicate names
  const lookForDuplicateNames = value => {
    debounced(value);
  };


  // Submit function with mutations
  const onSubmit = async data => {

    try {

      // Create creative
      let creative_res = await mutateCreative(data);
      let creative = creative_res?.data?.creativeCreate;

      // Create connection
      let variables = { creativeId: creative.id };
      let res_connection = await mutateConnectionCreate({ variables });
      let connection = res_connection?.data?.connectionCreate;

      setFilters();
      if (createAnother) {
        setStartupsList([...startupsList, connection]);
        inputRef.current.reset();
        setExistedFlag(undefined);
      }
      else {
        // Go to StartupPage page
        let path = `${startup_page}/company/${connection.id}`;
        history.push(path);
      }

    } catch (error) {
      console.log("ERROR CREATING STARTUP", error);
    }
  };

  const handleRedirect = () => {
    if (connectionId) {
      history.push(`${startup_page}/company/${connectionId}`);
    }
  };

  const onCreateAnotherSelect = (e) => {
    setCreateAnother(!createAnother);
  }

  return (
    <form style={{paddingBottom: 10}} onSubmit={handleSubmit(onSubmit)} ref={inputRef}>
      <div>
        <div className={styles.short}>
          <div className={styles.sartupList}>
            {startupsList.map(item => {
              return (
                <div className={styles.sartupList_startup} key={item.id}>
                  <i className={`fa fa-check-circle ${styles.checkIcon}`} />
                  <span className={styles.startupName} onClick={() => history.push(`${startup_page}/company/${item.id}`)}>{item.creative.name}</span>
                  <span className={styles.message}> was successfully saved</span>
                </div>
              )
            })}

          </div>
          <div className={styles.inputContainer} style={{ marginTop: "20px" }}>
            <p style={{ marginLeft: 6 }}>Name</p>
            <InputForm
              type="text"
              fullWidth={true}
              name="variables.input.name"
              placeholder="For example: Money Press Inc."
              handleInputChange={value => lookForDuplicateNames(value)}
              reference={register({ required: true })}
            />
          </div>

          <p className={styles.doyoumean} >
            {existedFlag && (
              <>
                Do you mean{" "}
                <span
                  className={styles.companyLink}
                  onClick={() => handleRedirect()}
                >
                  {existedFlag}
                </span>{" "}
                ?
              </>
            )}
          </p>
        </div>
      </div>
      <div className={styles.footer}>

        <div className={`${styles.buttonContainer} ${styles.btnSpace} ${styles.createAnotherContainer}`}>
          <div className={styles.createAnotherContainer_checkbox_container}>
            <CheckBox label="Create another" onSelect={onCreateAnotherSelect} isSelected={createAnother} value={createAnother} />
          </div>
          <NotataButton
            size="small"
            bgcolor="primary_color"
            text="Save"
            loading={isSubmitting}
            buttonType="submit"
          />
        </div>

      </div>
    </form>
  );
};

