import React, { useState } from "react";

import { NotataList } from "Components/NotataComponents";
import { useMutation } from "@apollo/client";
import { accountInviteDelete } from "../../../Apollo/Mutations";
import { accountInvitationsGet } from "../../../Apollo/Queries";

export default function YourTeamInvitations({
    invitations,
  }) {

  const [pendingLoaders, setPendingLoaders] = useState({});

  const [removeInvitation] = useMutation(accountInviteDelete, {
    refetchQueries: [{ query: accountInvitationsGet }],
  });

  let tableHeader = [
    {
      className: "settingsYour-team__icon-container"
    },
    {
      className: "settingsYour-team__member",
      content: <div>Pending invitations</div>
    }
  ]

  let tableBody = invitations?.map(({ email }) => [

    {
      content: (
        <i
          className={
            pendingLoaders[email]
              ? "fa fa-spinner fa-spin"
              : "fa fa-trash-alt"
          }
          aria-hidden="true"
          onClick={async () => {
            if (pendingLoaders[email]) {
              return;
            }

            setPendingLoaders({
              ...pendingLoaders,
              [email]: true,
            });

            let variables = { email };
            try {
              await removeInvitation({ variables });
            } catch (error) {
              console.log("error", error);
            }

            setPendingLoaders({
              ...pendingLoaders,
              [email]: false,
            });
          }}
        />
      )
    },

    {
      content: (
        <div className="settingsYour-team__members__member">
          {email}
        </div>
      )
    }



  ])

  return (
    <>
      {invitations?.length !== 0 && (
        <div className="settingsYour-team__members__pending">
          <NotataList
            header={tableHeader}
            body={tableBody}
          />
        </div>
      )}
    </>
  );
}
