// REACT STUFF
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// UI COMPONENTS
import { InputForm, NotataButton } from "Components/NotataComponents";

// STYLES
import "../LoginPages.scss";

// ASSETS
import loginImg from "assets/images/login.svg";
import authLogo from "assets/images/auth-logo.svg";

// CUSTOM COMPONENTS
import { userLoggedIn } from "actions/user";
import { getUserIsLoggedIn } from "reducers/selectors/user";

// OTHERS
import { Auth } from "aws-amplify";
import queryString from "query-string";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// DEFINITIONS

import { dashboard, forgotPassword, signup, login } from "definitions.js";
import { useQuery } from "@apollo/client";

import { userExists } from "../../../../public/Apollo/Queries";
import man_standing from "../../../../assets/images/man_standing.svg";
import { Message } from "../Message/Message";

function LoginComp({ history, location, userLoggedIn, userIsLoggedIn }) {
  // STATES
  const [SMS_MFA, setSMS_MFA] = useState(false);
  const [signinUser, setSigninUser] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const listForm = ["email", "password"];
  const [position, setPosition] = useState(4);
  const [validate, setValidate] = useState(false);
  const [hasSentEmail, setHasSentEmail] = useState(false);

  // FORMS
  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: SMS_MFA
      ? undefined
      : yupResolver(
          yup.object().shape({
            email: yup.string().email().required().trim(),
            password: yup.string().required().trim(),
          })
        ),
  });
  const { isSubmitting, isSubmitted } = formState;
  const s = queryString.parse(location.search);

  // Effects
  useEffect(() => {
    const { email } = s;
    if (!isSubmitted && !isSubmitting && email) {
      setValue("email", email);
    }
  }, [s, setValue, isSubmitting, isSubmitted]);

  // FUNCTIONS

  // setNextflag
  const setNextFlag = index => {
    setPosition(index === "email" ? 1 : index === "password" ? 2 : 3);
  };

  // handleInputChange
  const handleInputChange = (val, name) => {
    // if (name === "email") {
    //   setErrorMessage("");
    // }
  };

  let { refetch } = useQuery(userExists, {
    context: { clientName: "public" },
    skip: true,
  });

  // Onsubmit
  const onSubmit = async (data, event) => {
    event.preventDefault();
    let email = data.email.trim().toLowerCase();

    if (signinUser && SMS_MFA) {
      try {
        const loggedUser = await Auth.confirmSignIn(
          signinUser,
          data.code.trim(),
          "SMS_MFA"
        );
        userLoggedIn(loggedUser);
      } catch (error) {
        console.log(error);
      }
    }

    if (!signinUser && !SMS_MFA) {
      try {
        let signinUser = await Auth.signIn(email, data.password);

        setSigninUser(signinUser);
        if (signinUser.challengeName === "SMS_MFA") {
          return setSMS_MFA(true);
        }
        userLoggedIn(signinUser);
      } catch (error) {
        let res = await refetch({ email });
        let userData = res?.data?.userExists || {};
        let { userExists } = userData;

        if (!userExists) {
          return setErrorMessage(
            "We couldn't find an account matching the email and password you entered. Please check your email and password and try again."
          );
        } else {
          try {
            // Cognito user does not exist BUT user exists in DynamoDB
            // Sign user up to Cognito
            let signUpParams = {
              username: userData.email,
              password: data.password,
              attributes: {
                family_name: userData.family_name,
                given_name: userData.given_name,
                email: userData.email,
              },
            };

            let signupUser = await Auth.signUp(signUpParams)
            setHasSentEmail(true)

          } catch (error) {
            return setErrorMessage(
              "We couldn't find an account matching the email and password you entered. Please check your email and password and try again."
            );
          }
        }
      }
    }
  };

  // navigation
  if (userIsLoggedIn) {
    history.push(dashboard);
  }

  if (hasSentEmail) {
    return (
      <Message
        heading={"We have sent you an email 🚀"}
        subHead1={
          "Due to some security updates we have sent you an email to re-verify your account and password. Please check your mail and follow the instructions."
        }
        image={man_standing}
        history={history}
        path={login}
      />
    );
  }

  return (
    <div className="auth_structure">
      <div className="auth_structure__left">
        <div className="auth_structure__left__mainContent">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="logoContainer">
              <div className="logo_content">
                <img
                  src={authLogo}
                  alt="logo"
                  className="logo"
                  style={{ alignItems: "center" }}
                />
              </div>
              <h1>Login</h1>
            </div>
            {errorMessage && (
              <p className="error">
                <span>
                  <i className="fa fa-exclamation-circle" />
                </span>
                <br />
                <span className="span">{errorMessage}</span>
              </p>
            )}
            <InputForm
              label="Email"
              type="email"
              name="email"
              placeholder="Email"
              handleInputChange={(value, name) =>
                handleInputChange(value, name)
              }
              position={listForm[position]}
              setNextFlag={setNextFlag}
              validate={validate}
              required
              reference={register({ required: true })}
            />
            <InputForm
              label="Password"
              type="password"
              name="password"
              placeholder="Password"
              position={listForm[position]}
              setNextFlag={setNextFlag}
              validate={validate}
              required
              reference={register({ required: true })}
            />
            <NotataButton
              size="xx_large"
              bgcolor="primary_color"
              text="login"
              buttonType="submit"
              loading={isSubmitting}
              onClick={() => setValidate(true)}
            />
          </form>
          <div className="forgot">
            <Link className="forgot_password" to={forgotPassword}>
              <u>Forgot Password?</u>
            </Link>
          </div>

          {/*<div className="signup" >*/}
          {/*  <div className="separator">OR</div>*/}
          {/*  <Link className="signup_member" to={signup}>*/}
          {/*    Not a member? <u>Sign up</u>*/}
          {/*  </Link>*/}
          {/*</div>*/}

          </div>
        </div>

        <div className="auth_structure__right">
          <img src={loginImg} alt="auth_image" />
        </div>
    </div>
  );
}
// Main Function
export const Login = connect(
  state => ({ userIsLoggedIn: getUserIsLoggedIn(state) }),
  { userLoggedIn }
)(LoginComp);
