import React from "react";

// COMMON COMPONENTS
import { StartupLogo } from "Components/Common/StartupLogo/StartupLogo";

// UI COMPONENTS
import { NotataButton, Card, CardComponent } from "Components/NotataComponents";
import { getAnswer } from "../../commonFunctions";

// MAIN FUNCTION
export default function StartupCard({
  connection
}) {



  let { starred } = connection || {};
  let creative = connection?.creative || {};


  let answers = creative?.answers || [];
  let website = answers?.find(i => i.questionId === "q06_section_info");
  let slideDeck = answers?.find(i => i.questionId === "q01_section_materials");

  const openNewWindow = (url) =>{
    if(url.includes("https://")){
      window.open(url)
    } else {
      window.open(`https://${url}`)
    }
  }
  
  return (
    <CardComponent
      padding="medium"
      content={
        <div className="startup-info-container__card__info">
        <StartupLogo
          url={creative?.logo}
          startupName={creative.name}
          logoStyle="startup-info-container__card__info__logo"
          size={"medium"} 
        />
       
        <div className="startup-info-container__card__info__description">
          <div className="startup-info-container__card__info__description__name-container">
            <div className="name ">
              {creative?.name}
            </div>
            <i
              style={{
                color: starred ? "orange" : "lightgray",
              }}
              className="fas fa-star fav-icon"
            />
            
          </div>
          <p>{getAnswer(creative, "q01_section_info") || "n/a"}</p>
          <div className="startup-info-container__card__info__description__slidedeck-container">
            <div className="slide_deck">
              {slideDeck?.val && (
                <NotataButton
                  size="medium"
                  bgcolor="primary_color"
                  text="Slide Deck"
                  endIcon="chevron_right"
                  onClick={() => window.open(slideDeck.val)}
                />
              )}
            </div>
            <div className="overviewWebsite"
            onClick={()=>openNewWindow(website?.val)}
            >
              <a href={"#"} >
                {website?.val}
              </a>
            </div>
          </div>
        </div>
      </div>
      }
    />
  );
}