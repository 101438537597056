import React ,{useEffect, useState} from 'react';
import moment from 'moment';
import {sumBy} from 'lodash';
import { evaluationDelete } from "private/Apollo/Mutations";
import { useMutation } from "@apollo/client";
import { Modal, NotataButton, ModalErrorMessage } from "Components/NotataComponents";
import DisplayFormQuestion from "Components/Common/DisplayFormQuestion/DisplayFormQuestion";

import "./EvaluationSummaryModal.scss";

function getComment({ questionId, answers }) {
  const comment = answers?.find(answer =>
    answer?.questionId === questionId &&
    answer?.inputType === "COMMENT"
  );
  return comment?.val || ""
}

const getScoreCount = ({ sectionId, evaluation }) => {
  let multilineAns = {};
  let checkAns = {};
  let answers = evaluation?.answers?.filter(item => item.sectionId === sectionId) || [];
  for ( let ans of answers ) {
    if ( ans.inputType === "INPUT_MUTLIPLE_LINES" && ans.sectionId === sectionId ) {
      multilineAns[ans.questionId] = ans.questionId;
    }
  }
  for ( let ans of answers ) {
    if ( ans.inputType === "CHECK" && ans.sectionId === sectionId ) {
      multilineAns[ans.questionId] = ans.questionId;
    }
  }
  const otherAnsLength = answers?.filter(ans => ans.sectionId === sectionId
    && ans.inputType !== "CHECK" && ans.inputType !== "COMMENT"
    && ans.inputType !== "INPUT_MUTLIPLE_LINES")?.length || 0;

  const multilineAnsLength = Object.keys(multilineAns).length;
  const checkAnsLength = Object.keys(checkAns).length;
  return otherAnsLength + multilineAnsLength + checkAnsLength;
};











export default function EvaluationSummaryModal({
    evaluation,
    close,
    edit
  }) {

  // STATES
  const [deleteModal, setDeleteModal] = useState(false);
  const [collapseDetailList, setCollapseDetailList] = useState({});

  // MUTATIONS
  const [mutateEvaluationDelete, deleteRes] = useMutation(evaluationDelete);

  // MAPS & REDUCERS
  const {createdByUser, createdAt, summary, template, answers } = evaluation || {};
  const { isMe, given_name, family_name } = createdByUser || {};
  const { sections } = summary || {};
  const { name } = template || {};

  // EFFECTS
  useEffect(()=>{
    let sectionDetails = {};
    for (let item of sections || []) {
      sectionDetails[item.sectionId] = "";
    }
    setCollapseDetailList(sectionDetails);
  }, [sections])

  // FUNCTIONS


  const deleteEvaluation = async () => {
    let variables = { id: evaluation.id };
    try {
      await mutateEvaluationDelete({
        variables,
        update(cache) {
          const normalizedId = cache.identify({ id: evaluation.id, __typename: 'Evaluation' });
          cache.evict({ id: normalizedId });
          cache.gc();
        }
      });
    } catch (error) {
      return console.log("error", error);
    }
    close()
  };


  console.log('evaluation', evaluation)



  return(

    <>
      <Modal
        close={close}
        submit={close}
        title="View evaluation"
        submitTxt="CLOSE"
      >

        <div className="evaluation-modal">


          <div className="evaluation-display col-md-12">


            {/* Summary */}
            <div className="evaluation-display__summary">

              {/* Summary header */}
              <div>

                <div className="evaluation-display__summary__template-name">
                  {name}
                </div>

                <div className="evaluation-display__summary__template-by">
                  {evaluation?.createdByEmail && (
                    <span>Evaluation by {evaluation?.createdByEmail}</span>
                  )}
                  {
                    !evaluation?.createdByEmail && (
                      <span>Evaluation by {given_name} {family_name}</span>
                    )
                  }
                </div>

                <div className="evaluation-display__summary__template-by">
                  {`Last updated at ${moment(createdAt).format('ll')}`}
                </div>

              </div>





              {/* Summary body */}
              <div>
                <div className="evaluation-display__summary__heading">
                  Summary
                </div>
                {
                  sections?.map(section => (
                    <div key={section.sectionId} className="evaluation-display__summary__section">
                      <div className="evaluation-display__summary__section__name">
                        {section.sectionName}
                      </div>
                      <div className="evaluation-display__summary__section__points">
                        {`${sumBy(section.scorePerAnswer, 'score')} of ${sumBy(section.scorePerAnswer, 'possibleScore')} points`}
                      </div>
                    </div>
                  ))
                }

              </div>

            </div> {/* <- end of summary*/}


            {/* Sections */}
            {
              sections?.map(section => {

                let scoreCount = getScoreCount({
                  sectionId: section.sectionId,
                  evaluation
                })

                return (
                  <div
                    key={section.sectionId}
                    className="evaluation-display__section"
                    >

                    <div className="evaluation-display__section__header">
                      <div className="evaluation-display__section__header__label">
                        <i
                          className={`fa ${collapseDetailList[section.sectionId] === ""
                            ? "fa-chevron-up"
                            : "fa-chevron-down"
                          }`}
                          aria-hidden="true"
                          onClick={() => {
                            let collapseList = { ...collapseDetailList };
                            collapseList[section.sectionId] = collapseList[section.sectionId] === "" ? "collapse" : "";
                            setCollapseDetailList(collapseList);
                          }}
                        />
                        {section.sectionName}
                      </div>
                      <div className="evaluation-display__section__header__score">
                        {`${scoreCount} of ${section.scorePerAnswer.length} questions answered`}
                      </div>
                    </div>

                    <div className={`evaluation-display__section__body ${collapseDetailList[section.sectionId]}`} >

                      {
                        section?.scorePerAnswer?.filter(({questionName}) => questionName).map((score, index) => (
                          <DisplayFormQuestion
                            key={`${section.sectionId}_${index}`}
                            name={score.questionName}
                            score={score.score}
                            possibleScore={score.possibleScore}
                            answer={score.answer}
                            comment={getComment({ questionId: score.questionId, answers })}
                          />
                        ))
                      }
                    </div>

                  </div>
                );
              })
            }

            {
              isMe && (
                <div className="evaluation-modal__action-buttons">

                  <NotataButton
                    color="primary"
                    text="edit evaluation"
                    buttonStyle="text_button"
                    onClick={edit}
                  />


                  <NotataButton
                    bgcolor="secondary"
                    text="delete permanently"
                    buttonStyle="text_button"
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                  />


                </div>
              )
            }
          </div>
        </div>
      </Modal>

      {deleteModal && (
        <Modal
          title="Delete evaluation"
          submit={deleteEvaluation}
          loading={deleteRes.loading}
          close={() => {
            setDeleteModal(false);
          }}
          submitTxt="Delete"
          closeTxt="Cancel"
          lastButtonStyle="secondary_color"
          submitButtonStyle="secondary"
          children={
            <div className="modal-content-description">
              <ModalErrorMessage msg="Are you sure you want to delete this evaluation?"/>
            </div>
          }
        />
      )}

    </>
  )
}