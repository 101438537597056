import React, { useState } from "react";

// API STUFF
import { funnelGroupDelete } from "../../../Apollo/Mutations";
import { useMutation } from "@apollo/client";
import { accountGet } from "../../../Apollo/Queries";

// UI COMPONENTS
import { BrowseDropDown, Modal } from "Components/NotataComponents";

// MAIN FUNCTION
export default function SettingsFunnel({ funnelGroup, setEdit, dataTut }) {
  // STATES
  const [openDropdown, setOpenDropdown] = useState(false);
  const [deleteModal, setDeleteModal] = useState(undefined);

  // VARIABLES
  let colorArray = ["red", "blue", "purple", "orange", "green"];

  // MUTATION
  const [deleteGroup, deleteGroupRes] = useMutation(funnelGroupDelete, {
    refetchQueries: [{ query: accountGet }],
  });

  return (
    <>
      <div className="settingsFunnels-container__funnels__funnel">
        <div className="settingsFunnels-container__funnels__funnel__heading">
          <p>{funnelGroup.name} &nbsp;&nbsp;</p>
          <span
            className="material-icons"
            style={{
              cursor: "pointer",
            }}
            onMouseLeave={() => setOpenDropdown(false)}
            onClick={() => setOpenDropdown(!openDropdown)}
            data-tut={dataTut}
          >

            <i class="fal fa-cog" style={{ fontSize: "20px" }}></i>
            {openDropdown && (
              <div className="settingsFunnels-container__funnels__funnel__heading__dropdown">
                <BrowseDropDown
                  open={openDropdown}
                  setOpen={() => setOpenDropdown(!openDropdown)}
                  items={[
                    {
                      text: "edit",
                      leave: false,
                      icon: <i className="fal fa-edit"/>,
                      callBack: () => {
                        setEdit(funnelGroup.id);
                      },
                    },
                    {
                      text: "delete",
                      leave: true,
                      icon: <i className="fa fa-trash"/>,
                      callBack: () => setDeleteModal(funnelGroup.id),
                    },
                  ]}
                />
              </div>
            )}
          </span>
        </div>

        <div className="parts">
          {funnelGroup.funnelTags?.map((tag, index) => {
            // Max width of bar
            let max = 180;

            // Min width of bar
            let min = 20;

            // Difference
            let diff = max - min;

            // Increments
            let inc = Math.round(diff / funnelGroup.funnelTags.length);

            // Max with - increment multiplied by index
            let width = `${max - inc * index}px`;

            return (
              <div className="parts__part" key={tag.id}>
                <div className="parts__part__heading">{tag.name}</div>

                <div className="parts__part__color_block">
                  <div
                    className={`parts__part__funnel ${
                      tag.color || colorArray[index] || "red"
                    }`}
                    style={{ width }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {deleteModal && (
        <Modal
          title="Delete funnel"
          loading={deleteGroupRes.loading}
          submit={async () => {
            let variables = {
              id: funnelGroup.id,
            };
            try {
              await deleteGroup({ variables });
            } catch (error) {
              console.log("error", error);
            }
            setDeleteModal(false);
          }}
          close={() => {
            setDeleteModal(false);
          }}
          submitTxt="Delete"
          closeTxt="Cancel"
          children={
            <div className="delete-group-modal-container">
              <div className="description">
                Are you sure you want to delete this funnel permanently?
              </div>
              <div className="remember">Remember:</div>
              <div className="options">
                - All funnel stages will be removed from startups
                <br />
                - There is no undo function <br />
              </div>
            </div>
          }
        />
      )}
    </>
  );
}
