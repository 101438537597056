import React from 'react';

// COMPONENTS
import { NotataButton, Card } from "Components/NotataComponents";
import { getAnswer } from "private/Pages/commonFunctions";
import StartupCard from "private/Pages/StartupPage/CommonComponents/StartupCard";
import Answers from "private/Pages/StartupPage/Tabs/StartupInfo/cards/Answers";

// OTHER
import { groupBy } from 'lodash'
import './PreviewStartup.scss';


// Main Function
export default function PreviewStartup({
    sharingObject,
    onEvaluate,
    evaluationCreated,
    previewStartupModal
 }) {

  // DEFINITIONS
  const sharingDetails = sharingObject;
  const answers = groupBy(sharingDetails?.creative?.answers , 'sectionName');
  const sharedByUser = sharingDetails?.sharedByUser;
  const sharedByName = `${sharedByUser?.given_name} ${sharedByUser?.family_name}`
  const sharedByEmail = sharedByUser?.email;
  const sectionAnswers = sharingDetails?.sharedEvaluation?.newAnswers || [];

  const sharingNothing = !sharingDetails?.evaluationTemplate && sectionAnswers?.length === 0;

  return (
    <div className="preview-startup">
      <div className="preview-startup-container startup-info-container col-md-12">

        <div className="row row-flex">
          <div className={
            sharingNothing
              ? "col-sm-12 col-md-12 col-flex"
              : "col-sm-12 col-md-8 col-flex"
            }>

            <div className="card card_padding">
              <div className="preview-startup-container__user-message">
                {previewStartupModal &&
                  <i className="fa fa-chevron-left"
                     aria-hidden="true"
                     onClick={previewStartupModal}
                  />
                }
                <span className="preview-startup-container__user-name">{sharedByName}</span> ({sharedByEmail}) has been shared startup with you
              </div>
              <div className="preview-startup-container__message">
                <p>
                    {sharingDetails?.message}
                </p>
              </div>
            </div>
          </div>


          { sharingDetails?.evaluationTemplate && (
              <div className="col-sm-12 col-md-4 col-flex">
                <Card containerClassName="preview-startup-container__evaluation_request">
                  <div className="preview-startup-container__evaluation_request__heading">
                    Evaluation request
                  </div>
                  <p className="preview-startup-container__evaluation_request__message">{sharedByName} has requested an evaluation of this startup for you</p>
                  <NotataButton
                    size="medium"
                    endIcon={evaluationCreated || sharingDetails?.publicEvaluation?.id ? '' : "add"}
                    bgcolor={evaluationCreated || sharingDetails?.publicEvaluation?.id ? 'primary_color' : "primary_color"}
                    text={evaluationCreated || sharingDetails?.publicEvaluation?.id ? "View " : "Evaluate "}
                    onClick={()=>{
                      if (!previewStartupModal) {
                        onEvaluate(sharingDetails);
                      }
                    }}
                  />
                </Card>
              </div>
            )
          }

        </div>

        <div className="row">


          <div className={
            sharingNothing
              ? "col-md-12 col-lg-12 col-sm-12 col-flex"
              : "col-md-8 col-lg-8 col-sm-12 col-flex"
            }>
            <StartupCard
              getAnswer={getAnswer}
              connection={sharingDetails}
            />
            {sharingDetails?.creativeTemplate?.sections?.map(section => (
              <Answers
                questions={section.questions}
                answers={answers[section.name]}
                sectionName={section.name}
                key={section.name}
              />
            ))}
          </div>


          {
            sectionAnswers?.length !== 0 && (
              <div className="col-sm-12 col-md-4 col-lg-4">
                <Card containerClassName="card preview-evaluation__card">
                  <div className="answer-container__heading">Evaluation by {sharedByName}</div>
                  <div className="evaluation-section-template"> {sharingDetails?.evaluationTemplate?.name}</div>
                  <div className="preview-evaluation-questions">
                    <div className="col-sm-12 evaluation-section">
                      {sectionAnswers?.map((answer, sectionIndex) => {
                        return <div className="preview-evaluation-questions__section" key={`${answer.sectionName}-section-id-${sectionIndex}`}>
                          <div className="preview-evaluation-questions__heading">{answer.sectionName}</div>
                          {answer.questions.map((questionAnswer, index) => {
                            return <div key={`${answer.sectionName}-question-id-${index}`}>
                              <p className="preview-evaluation-questions__question">{questionAnswer.questionName}</p>
                              {questionAnswer.answers.length===0 && <span className ="not_answer">Not answered</span>}
                              {questionAnswer.answers.length!==0 && (
                                questionAnswer.answers.map((answerVals, answerIndex) => {
                                  return <p key={`${answer.sectionName}-question-id-${index}-answer-id-${answerIndex}`} className="preview-evaluation-questions__question__answerColor">{answerVals}</p>
                                })
                              )}
                              {questionAnswer.comment &&
                              <p className="preview-evaluation-questions__question__commentColor">{questionAnswer.comment}</p>
                              }
                            </div>
                          })}
                        </div>
                      })}

                    </div>
                  </div>
                </Card>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )

}