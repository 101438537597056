import gql from "graphql-tag";

import EvaluationTemplateSectionFields from "./EvaluationTemplateSectionFields"

export default gql`
  fragment EvaluationTemplateFields on EvaluationTemplate {
    id
    name
    description
    createdBy
    createdAt
    updatedAt
    sections {
      ...EvaluationTemplateSectionFields
    }
  }
  ${EvaluationTemplateSectionFields}
`