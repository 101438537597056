import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { Modal } from "Components/NotataComponents";
import { accountUserRemove } from "../../../../Apollo/Mutations";

export default function DeleteMemberModal({
  member,
  close
}) {
  // Mutations
  const [removeUser, removeUserRes] = useMutation(accountUserRemove);

  return (
    <Modal
      title="Delete member from your team"
      loading={removeUserRes.loading}
      submit={async () => {
        if (removeUserRes?.loading) {
          return;
        }
        let variables = { email: member.email };
        try {
          await removeUser({ variables });
        } catch (error) {
          console.log("error", error);
        }
        close();
      }}
      close={close}
      submitTxt="Delete"
      closeTxt="Cancel"
      submitButtonStyle="secondary"
      children={
        <p className="delete-member-modal-from-team">
          {`Are you sure you want to delete the member ${member.email} from your
          team?`}
        </p>
      }
    />
  );
}
