import React, { useState } from "react";

// STYLES
import "./GroupsComp.scss"

// DEFINITIONS
import { group_dashboard } from "definitions";

// OTHERS
import AddToGroupModalNew from "../../../../MyStartups/Modals/AddToGroupModalNew";

import { Link } from 'react-router-dom'

// MAIN FUNCTION
export default function GroupsComp({ refetch, connection, history }) {
  
  // STATES
  const [addToGroupModal, setAddToGroupModal] = useState(false);

  return (
    <>

      {
        !connection?.groupSharingInfo?.length && (
          <div
            className="card-empty-text"
            style={{paddingTop: "0px"}}
          >
            Here you can share deals, evaluate startups, chat and find co-investors.
          </div>
        )
      }

      <div>
        {/* Groups */}
        <div className="overview-groups-container__groups">
          {connection?.groupSharingInfo?.map(info => (
            <Link to={`${group_dashboard}/${info?.group?.id}`} key={info?.group?.id} >
              <div
                className="group-name"
                key={info?.group?.id} >
                {info?.group?.name}
              </div>
            </Link>
          ))}
        </div>

        <div className="overview-groups-container__add-button">
          <i
            className="fa fa-plus"
            aria-hidden="true"
            onClick={() => setAddToGroupModal(true)}
          />
        </div>
      </div>
      
      {/* addToGroupModal */}
      {addToGroupModal && (
        <AddToGroupModalNew
          connection={connection}
          history={history}
          close={() => setAddToGroupModal(false)}
          success={() => {
            refetch();
            setAddToGroupModal(false);
          }}
        />
      )}
    </>
  );
}
