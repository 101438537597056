import React, {useState} from "react";

// CUSTOM COMPONENTS
import { CardComponent, Card } from "Components/NotataComponents";
import SimilarStartupsCard from "./components/SimilarStartupsCard";
import ArchiveAndDeleteSection from "./components/ArchiveAndDeleteSection";
import CommentBox from "../../CommonComponents/CommentBox";
import StartupCard from '../../CommonComponents/StartupCard';
import SubjectiveScoresComp from "./components/SubjectiveScoresComp";
import EvaluationSummariesComp from "./components/EvaluationSummariesComp";
import TagsComp from "./components/TagsComp";
import FunnelsComp from "./components/FunnelsComp";
import GroupsComp from "./components/GroupsComp";
import ImpactGoalsComp from "./components/ImpactGoalsComp";
import ExternalResources from "./components/ExternalResources";

//OTHERS
import { getAnswer } from "../../../commonFunctions";

// STYLES
import "./Overview.scss";

// MAIN FUNCTION
export default function Overview({
  connection,
  user,
  account,
  history,
  refetch,
}) {
  const [showTagsModal, setShowTagsModal] = useState(false);
  return (
    <>
      <div className="row tab-panel-container overview-container">
        <div className="col-sm-12 col-md-8">
          <StartupCard
            connection={connection}
          />

          
          {/*SUBJECTIVE SCORE*/}
          <CardComponent
            containerClassName="overview-container__scores"
            data-tut="subjective-score"
            title="Subjective scores"
            padding="medium"
            titleSize="medium"
            content={
              <SubjectiveScoresComp
              connection={connection}
              account={account}
              user={user}
            />
            }
          />

          {/*EVALUATION SUMMARIES*/}
          {!!connection?.evaluationSummaries?.length &&
          <CardComponent
            containerClassName="overview-container__scores"
            title="Evaluation summaries"
            padding="medium"
            titleSize="medium"
            content={
              <EvaluationSummariesComp 
              connection={connection}
              user={user}
            />
            }
          />
          }


          {/*TAGS*/}
          <CardComponent
            containerClassName="tags-container overview-tags"
            title="Tags"
            padding="medium"
            titleSize="medium"
            content={
              <TagsComp connection={connection} showTagsModal={showTagsModal} setShowTagsModal={setShowTagsModal}/>
            }
          />

          {/*FUNNELS*/}
          <CardComponent
            containerClassName="funnel-summary-container" 
            title="Funnels"
            titleSize="medium"
            padding="medium"
            content={
              <FunnelsComp connection={connection} />
            }
          />


          {/*GROUPS*/}
          <CardComponent
            containerClassName="overview-groups-container"
            title="Groups"
            titleSize="medium"
            padding="medium"
            content={
              <GroupsComp connection={connection}
              goToPath={path => history.push(path)}
              refetch={refetch}
              history ={history}
            />
            }
          />
          {/*IMPACT GOALS*/}
          <CardComponent
              containerClassName="impact-goals-container" 
              title="Impact Goals"
              titleSize="medium"
              padding="medium"
              content={
                 <ImpactGoalsComp connection={connection} />
              }
            
          />
          
          {/*EXTERNAL RESOURCES*/}
          <CardComponent
            containerClassName="overview-container__scores"
            title="External Resources"
            titleSize="medium"
            padding="medium"
            content={
              <ExternalResources
              connection={connection}
            />
            }
          />

          {/*SIMILAR STARTUPS*/}
          <SimilarStartupsCard connection={connection} history={history} />

          <ArchiveAndDeleteSection connection={connection} history={history} />
        </div>

        {/* Comment Box */}
        <CommentBox commentBox={"comment_box"} connectionId={connection?.id} />
      </div>
    </>
  );
}
