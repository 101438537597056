import React, { useState, useEffect } from "react";

// API STUFF
import {
  funnelTagCreate,
  funnelTagUpdate,
  funnelTagDelete,
  funnelGroupCreate,
  funnelGroupUpdate,
} from "../../../../Apollo/Mutations";
import { useMutation } from "@apollo/client";
import { accountGet } from "../../../../Apollo/Queries";

// UI COMPONENTS
import { Modal, NotataButton, TextBox } from "Components/NotataComponents";

// MAIN FUNCTION
export default function SettingsEditFunnel({ funnelGroup, save }) {
  // STATES
  let [funnel, setFunnel] = useState({});
  let [newFunnel, setNewFunnel] = useState(getDefaultFunnel());
  const [updateModal, setUpdateModal] = useState(undefined);
  const [isSaving, setIsSaving] = useState(false);

  // VARIABLE
  let colorArray = ["red", "blue", "purple", "orange", "green"];

  function getDefaultFunnel() {
    return {
      id: `${Math.floor(Math.random() * 1000).toString()}`,
      name: "",
      color: "red",
      isNew: true,
    };
  }

  // Refetch query object
  const mutOption = {
    refetchQueries: [{ query: accountGet }],
  };

  // MUTATIONs
  const [createGroup] = useMutation(funnelGroupCreate, {
    refetchQueries: [{ query: accountGet }],
  });
  const [updateTagMutation] = useMutation(funnelTagUpdate);
  const [createTagMutation] = useMutation(funnelTagCreate, mutOption);
  const [deleteTagMutation] = useMutation(funnelTagDelete, mutOption);

  const [updateGroup, updateGroupRes] = useMutation(
    funnelGroupUpdate,
    mutOption
  );

  useEffect(() => {
    if (funnelGroup) {
      setFunnel(funnelGroup);
    }
  }, [funnelGroup]);

  //Updating tag
  function updateTag(tag) {
    setFunnel({
      ...funnel,
      funnelTags: funnel.funnelTags.map(ft => (ft.id === tag.id ? tag : ft)),
    });
  }

  return (
    <>
      <div className="settingsFunnels-container__new-funnel">
        <div className="settingsFunnels-container__new-funnel__text">
          <div>
            {funnel.name}{" "}
            <span
              className="material-icons settingsFunnels-container__edit"
              onClick={() => setUpdateModal(true)}
            >
              edit
            </span>
          </div>
          <NotataButton
            size="small"
            bgcolor="primary_color"
            loading={isSaving === (funnelGroup?.id || "tmp")}
            text={"Save"}
            onClick={async () => {
              if (isSaving === (funnelGroup?.id || "tmp")) {
                return;
              }

              let funnelGroupId = funnelGroup.id;
              setIsSaving(funnelGroup?.id || "tmp");

              // CREATE NEW GROUP
              if (funnelGroup.isNew) {
                try {
                  let variables = {
                    input: {
                      name: funnelGroup.name,
                    },
                  };
                  let res = await createGroup({ variables });
                  funnelGroupId = res?.data?.funnelGroupCreate?.id;
                } catch (error) {
                  return console.log("error", error);
                }
              }

              // UPDATE
              let updateItems = funnel.funnelTags?.filter(
                ({ isNew }) => !isNew
              );
              if (updateItems.length) {
                let promises = updateItems.map((it, index) => {
                  let variables = {
                    id: it.id,
                    input: {
                      name: it.name,
                      index:
                        it.index === 0
                          ? it.index
                          : it.index || funnel.funnelTags.length + 1,
                      color: it.color,
                    },
                  };
                  return updateTagMutation({ variables });
                });
              }

              // CREATE Tags
              let createItems = funnel.funnelTags?.filter(({ isNew }) => isNew);
              if (newFunnel.name?.length) {
                createItems.push(newFunnel);
              }
              if (createItems.length) {
                let promises = createItems.map((it, index) => {
                  let variables = {
                    funnelGroupId: funnelGroupId,
                    input: {
                      name: it.name,
                      index: it.index,
                      color: it.color,
                    },
                  };
                  return createTagMutation({ variables });
                });
              }

              // DELETE
              let deleteItems = funnelGroup?.funnelTags?.filter(
                ({ id }) => !funnel.funnelTags?.find(t => t.id === id)
              );
              if (deleteItems.length) {
                let promises = deleteItems.map(it => {
                  let variables = {
                    id: it.id,
                  };
                  return deleteTagMutation({ variables });
                });
                try {
                  await Promise.all(promises);
                } catch (error) {
                  console.log("error", error);
                }
              }

              setIsSaving(false);

              save();
            }}
          />
        </div>

        {/*Update funnel Tag name*/}
        <div className="settingsFunnelGroups">
          {funnel.funnelTags?.map((tag, index) => {
            return (
              <div className="settingsFunnelGroups__group" key={tag.id}>
                <div className="settingsFunnelGroups__group__heading">
                  <TextBox
                    type="text"
                    textBoxStyle="input"
                    maxLength={20}
                    value={tag.name}
                    autoComplete="off"
                    onChange={e => {
                      updateTag({ ...tag, name: e.target.value });
                    }}
                  />
                </div>
                <div
                  className={`settingsFunnelGroups__group__funnel ${
                    tag.color || colorArray[index] || "red"
                  }`}
                />

                <div className="settingsFunnelGroups_group__color-pallets">
                  {colorArray.map(color => (
                    <div
                      key={color}
                      className={`color-pallet ${color} ${
                        tag.color === color ? "selected" : ""
                      }`}
                      onClick={() => updateTag({ ...tag, color })}
                    />
                  ))}
                  <i
                    className="fa fa-times-circle"
                    aria-hidden="true"
                    onClick={() => {
                      setFunnel({
                        ...funnel,
                        funnelTags: funnel.funnelTags.filter(
                          ({ id }) => id !== tag.id
                        ),
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}

          {/* create new funnel tag*/}
          <div className="settingsFunnelGroups__group">
            <div className="settingsFunnelGroups__group__heading">
              <TextBox
                type="text"
                maxLength={20}
                value={newFunnel.name}
                autoComplete="off"
                onChange={e => {
                  setNewFunnel({
                    ...newFunnel,
                    name: e.target.value,
                    index: funnel.funnelTags.length || 0,
                  });
                }}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    if (!newFunnel.name.length) {
                      return;
                    }
                    setFunnel({
                      ...funnel,
                      funnelTags: [...funnel.funnelTags, newFunnel],
                    });
                    setNewFunnel(getDefaultFunnel());
                  }
                }}
              />
            </div>
            <div
              className={`settingsFunnelGroups__group__funnel ${
                newFunnel.color || "red"
              }`}
            />

            {/*Update funnel group tag color*/}
            <div className="settingsFunnelGroups__group__color-pallets">
              {colorArray.map(color => (
                <div
                  key={color}
                  className={`color-pallet ${color} ${
                    newFunnel.color === color ? "selected" : ""
                  }`}
                  onClick={() => {
                    setNewFunnel({
                      ...newFunnel,
                      color,
                    });
                  }}
                />
              ))}
              <i
                className="fa fa-plus-circle"
                aria-hidden="true"
                onClick={() => {
                  if (!newFunnel.name.length) {
                    return;
                  }
                  setFunnel({
                    ...funnel,
                    funnelTags: [...funnel.funnelTags, newFunnel],
                  });
                  setNewFunnel(getDefaultFunnel());
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/*Update funnel group name modal */}
      {updateModal && (
        <Modal
          title="Update funnel group name"
          loading={updateGroupRes.loading}
          submit={async () => {
            if (funnel.name) {
              let variables = {
                id: funnelGroup.id,
                input: {
                  name: funnel.name,
                },
              };
              try {
                await updateGroup({ variables });
              } catch (error) {
                console.log("error", error);
              }
            }
            setUpdateModal(false);
          }}
          close={() => {
            setUpdateModal(false);
          }}
          submitTxt="Save"
          closeTxt="Cancel"
          children={
            <TextBox
              textBoxStyle="settingsFunnels-container__create-new-funnel-text"
              type="text"
              value={funnel.name}
              autoComplete="off"
              onChange={e =>
                setFunnel({
                  ...funnel,
                  name: e.target.value,
                })
              }
            />
          }
        />
      )}
    </>
  );
}
