import React, { useEffect, useRef, useState } from "react";

// API STUFF
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { newsGetOne, userGet } from "private/Apollo/Queries";
import { newsCreate, newsUpdate, newsDelete, newsDeleteImage } from "private/Apollo/Mutations";

// UI COMPONENTS
import { GhostLoader, NotataButton,TextBox, Card } from "Components/NotataComponents";

//Custom Components
import { removeImagePromise } from "../NewsPromises/removeImagePromise"
import { uploadImagePromise } from "../NewsPromises/uploadImagePromise"
import {Preview} from "./Preview"

//STYLES
import "../News.scss";

//Definitions
import { news } from "definitions";

//Others
import { omit } from "lodash";
import moment from "moment";

// public File path
const publicFilePath = `https://s3-eu-west-1.amazonaws.com/notata.userfiles/public/`;

//Main Function
export default function NewsEdit({ history, match }) {
  let { id } = match?.params;

  // States
  const [data, setData] = useState({});
  const [isUploading, setIsUploading] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(undefined);

  // Queries
  const userQuery = useQuery(userGet);
  const [getOneNews, getOneNewsRes] = useLazyQuery(newsGetOne);

  // Mutations
  const [createNews, createNewsRes] = useMutation(newsCreate);
  const [updateNews, updateNewsRes] = useMutation(newsUpdate);
  const [deleteNews, deleteNewsRes] = useMutation(newsDelete);
  const [deleteImage] = useMutation(newsDeleteImage);

  // Effects
  useEffect(() => {
    if (id) {
      getOneNews({ variables: { id } });
    }
  }, [id]);

  useEffect(() => {
    if (getOneNewsRes?.data?.newsGetOne) {
      let { id, title, content, image } = getOneNewsRes?.data?.newsGetOne;
      setData({ title, content, image, id });
    }
  }, [getOneNewsRes]);

  // Data maps
  const user = userQuery.data?.userGet || {};
  const folderPath = `news/${new Date().getTime()}/`;
  const formRef = useRef(null);

  //Functions

  // Upload image
  async function handleUploadImage(e) {
    if (isUploading) {
      return;
    }
    setIsUploading(true);
    // Get file
    let file = e.target.files[0];
    // Return if no file
    if (!file) {
      return;
    }
    // Get file size
    let filesize = (file.size / 1024 / 1024).toFixed(4); // MB
    // Do not allow pictures larger than one mb
    if (filesize >= 2) {
      return;
    }
    // Get path and file name together
    const filename = `${folderPath}${file.name}`;
    // Save file to s3
    try {
      let result = await uploadImagePromise(filename, file);
      let { key } = result;
      setData({
        ...data,
        image: `${publicFilePath}${key}`,
      });
    } catch (error) {
      setIsUploading(false);
      console.log("error", error);
      return;
    }
    setIsUploading(false);
  }
  // Delete image
  async function handleDeleteImage() {
    if (isDeleting) {
      return;
    }

    setIsDeleting(true);

    let parts = data?.image?.split("/");
    let key = `/${parts[parts.length - 2]}/${parts[parts.length - 1]}`;

    try {
      await removeImagePromise(key, { level: "public" });
    } catch (error) {
      console.log("error", error);
    }

    if (id) {
      try {
        let variables = { id };
        let res = await deleteImage({ variables });

      } catch (error) {
        console.log("error", error);
      }
    }

    setData({
      ...data,
      image: undefined,
    });

    setIsDeleting(false);
    formRef.current.reset();
  }
  // Save News
  async function submitSaveNews() {
    if (!data.title || !data.content) {
      return;
    }
    try {
      let variables = {
        input: { ...omit(data, "id") },
      };
      await createNews({ variables });
    } catch (error) {
      console.log(error);
    }
    history.push(news);
  }
  // Update news
  async function submitUpdateNews() {
    if (!data.title || !data.content) {
      return;
    }
    try {
      let variables = {
        id,
        input: { ...omit(data, "id") },
      };
      await updateNews({ variables });
    } catch (error) {
      console.log(error);
    }
    history.push(news);
  }
  // Delete News
  async function submitDeleteNews() {
    if (!id) {
      return;
    }
    try {
      let variables = { id };
      await deleteNews({ variables });
    } catch (error) {
      console.log(error);
    }
    history.push(news);
  }

  if (id && !getOneNewsRes?.data && getOneNewsRes?.loading) {
    return <GhostLoader />;
  }
  return (
     <div className="news-container">
      <div className="news-container__main">
        <div className="news-container__heading">
          {(id && <span>Edit news</span>) || <span>New News</span>}
        </div>

        <div className="news-container__create-container">
         <Card containerClassName="news-container__card" >
            <div className="news-container__create-container__heading">Title</div>
            {/* news title  */}
              <TextBox
                  className="news-container__create-container__title"
                  type="text"
                  placeholder="Add news title"
                  autoComplete="off"
                  value={data.title || ""}
                  onChange={e => {
                    setData({
                      ...data,
                      title: e.target.value,
                    });
                  }}
              />
              {/* news description */}
              <textarea
                className="news-container__create-container__news-buinder"
                value={data.content || ""}
                onChange={e => {
                  setData({
                    ...data,
                    content: e.target.value,
                  });
                }}
              />
        
              {/* News footer */}
          <div className="news-container__create-container__footer">
            <div className="news-container__create-container__footer__news-by">
              <div className="news-container__create-container__footer__date">
                {moment().format("ll")}
              </div>
              <div className="news-container__create-container__footer__name">
                {user?.given_name} {user?.family_name}
              </div>
            </div>

            <div className="news-container__create-container__footer__upload-image">
              {/*<img src={data.image} />*/}
            </div>

            <div className="news-container__create-container__footer__action-btns">
              <div className="hidden-file-input">
                <form ref={formRef}>
                  <label htmlFor="file-upload" className="upload-logo-btn">
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleUploadImage}
                    />
                    <NotataButton
                      size="medium"
                      bgcolor="primary_color"
                      iconStart="add"
                      loading={isUploading}
                      text={"ADD PICTURE"}
                      buttonType="submit"
                    />
                  </label>
                  
                </form>

                {data.image && (
                  <div
                    className="delete-btn"
                    onClick={handleDeleteImage}
                    style={{
                      textAlign: "center",
                      paddingTop: "10px",
                    }}
                  >
                    {/* {(!isDeleting &&  */}
                    <NotataButton
                    size="medium"
                    bgcolor="secondary_color"
                    iconStart="add"
                    loading={isDeleting}
                    text={"delete image"}
                  />
                    {/* // <span>delete image</span>
                    ) 
                    // || (<span>deleting...</span> )
                      } */}
                    
                  </div>
                )}
              </div>
            </div>
           
          </div>
         </Card>
        </div>
        {/* PREVIEW */}
        <Preview data={data} user={user} />
        <div className="news-container__bottom-container">
          {/* Delete image */}
          {(id && (
            <NotataButton
            size="big"
            bgcolor="secondary_color"
            iconStart="done"
            loading={deleteNewsRes?.loading}
            text={"Delete"}
            onClick={submitDeleteNews}
          />
          )) || <span />}
          {/* Update & Publish button */}
          <NotataButton
            size="big"
            bgcolor="primary_color"
            iconStart="done"
            loading={updateNewsRes?.loading || createNewsRes?.loading }
            text={
              id
                ? "Update"
                : "Publish"
            }
            onClick={() => {
              id ? submitUpdateNews() : submitSaveNews();
            }}
          />
        </div>
      </div>
    </div>
  );
}