import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { evaluationTemplateSectionCreate } from "private/Apollo/Mutations";

// UI COMPONENTS
import { Modal,TextBox } from "Components/NotataComponents";

// DEFINITIONS
import { evaluation_template_page } from "definitions";




//Main Function
export default function AddSectionModal({ templateId, history, close }) {
  // STATES
  const [name, setName] = useState("");

  // MUTATIONS
  const [createSection, createSectionRes] = useMutation(
    evaluationTemplateSectionCreate
  );
  const submit = async () =>{
    let res;
    try {
      let variables = {
        templateId,
        input: {
          name: name,
        },
      };
      res = await createSection({ variables });
    } catch (error) {
      return console.log("error", error);
    }
    let id = res?.data?.evaluationTemplateSectionCreate?.id;
    history.push(`${evaluation_template_page}/${templateId}/section/${id}`);
    close();
  }
  return (
    <Modal
      title="New Section"
      loading={createSectionRes.loading}
      submit={() => {
        submit();
      }}
      close={close}
      submitTxt="Save"
      closeTxt="Cancel"
      children={
        <TextBox
          name="sectionName"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Evaluation Section Name"
          onKeyDown={e=>{
            e.stopPropagation();
            if(e.key === "Enter"){
              submit();
            }
          }}
        />
      }
    />
  );
}
