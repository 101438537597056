import img_0102_menu from "./assets/1.2-menu.jpg";
import img_010201_menu_dashboard from "./assets/1.2.1-menu-dashboard.jpg";
import img_010202_menu_my_startups from "./assets/1.2.2-menu-my-startups.jpg";
import img_010203_menu_groups from "./assets/1.2.3-menu-groups.jpg";
import img_010204_menu_reports from "./assets/1.2.4-menu-reports.jpg";
import img_010205_menu_news from "./assets/1.2.5-menu-news.jpg";
import img_010206_menu_settings from "./assets/1.2.6-menu-settings.jpg";

import img_0103_dashboard from "./assets/1.3-dashboard.jpg";
import img_010301_dashboard_group_act from "./assets/1.3.1-dashboard-group-act.jpg";
import img_010302_dashboard_group_inv from "./assets/1.3.2-dashboard-group-inv.jpg";
import img_010303_dashboard_mystartups from "./assets/1.3.3-dashboard-mystartups.jpg";
import img_010304_dashboard_news from "./assets/1.3.4-dashboard-news.jpg";
import img_010305_dashboard_notifications from "./assets/1.3.5-dashboard-notifications.jpg";
import img_010306_dashboard_reports from "./assets/1.3.6-dashboard-reports.jpg";

import img_0202_customize from "./assets/2.2-customize.png";
import img_0203_filters from "./assets/2.3-filters.png";
import img_0204_team from "./assets/2.4-teams.png";

import img_0301_man from "./assets/3.1-manually.png";
import img_0302_inv from "./assets/3.2-invited.png";

import img_030301_form from "./assets/3.3.1-form.png";
import img_030302_form from "./assets/3.3.2-form.png";
import img_030303_form from "./assets/3.3.3-form.png";

import img_overview from "./assets/overview.png";
import img_0401_notes from "./assets/4.1-notes.png";
import img_040201_subjective from "./assets/4.2.1-subjective.png";
import img_040202_esum from "./assets/4.2.2-esum.png";
import img_040203_tags from "./assets/4.2.3-tags.png";
import img_040204_funnel from "./assets/4.2.4-funnel.png";
import img_040205_group from "./assets/4.2.5-group.png";
import img_040206_esg from "./assets/4.2.6-esg.png";
import img_040207_ext from "./assets/4.2.7-ext.png";
import img_040208_similar from "./assets/4.2.8-similar.png";

import img_0501_eval from "./assets/5.3-eval.png"
import img_0502_evals from "./assets/5.4-evals.png"

import img_0505_group_evals from "./assets/5.5-group-eval.png";
import img_0506_ext_eval1 from "./assets/5.6-ext-eval.png";
import img_0506_ext_eval2 from "./assets/5.6-ext-eval2.png";

import img_0601_private from "./assets/6.1-private.png";
import img_0602_open from "./assets/6.2-open.png";
import img_0602_open2 from "./assets/6.2-open2.png";
import img_0602_public from "./assets/6.2-public.png";
import img_0602_member from "./assets/6.2-members.png";
import img_0602_startups from "./assets/6.2-startups.png";
import img_0602_plugin from "./assets/6.2-plugin.png";

import img_0701_settings from "./assets/7-settings.png";
import img_0702_funn from "./assets/7.1-funn.png";
import img_0703_team from "./assets/7.2-team.png";

import img_etl from "./assets/etl.png";



let iframeDefaultProperties = {
  width: 800,
  height: 480,
  title: "YouTube video player",
  frameborder: 0,
  allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
  allowfullscreen: "true"
}


export default  [

  {
    title: "First impression",
    content: [
      {
        type: "p",
        properties: {},
        content: "This is the notata help content. To help you guide through our help material we have created some short videos to explain the main concepts of notata."
      },
      {
        type: "iframe",
        properties: {
          ...iframeDefaultProperties,
          src: "https://www.youtube.com/embed/NmOEIp4gW_Q",
        },
        caption: "First impression video: show general features of notata."
      },
    ],
    sections: [
      {
        title: "What notata is / is not",
        content: [
          {
            type: "p",
            properties: {},
            content: "Notata is a highly customisable tool for investors of early stage startup companies. The tool was initially created for internal use by a Norwegian family office to solve two problems: how to fairly evaluate early stage startup companies, and how to engage your network of investors in an efficient way."
          },

          {
            type: "p",
            properties: {},
            content: "The tools objective is to widen the funnel of deals that comes your way, and to help you being better and more efficient in the evaluation process. We are a top-of-funnel tool for investors, and consider ourselves a pre-CRM tool that focus on data created by investors – not startups."
          },

          {
            type: "p",
            properties: {},
            content: "In other words, we are not a platform for startups. We don’t have a database of startups for you to browse, and startup companies cannot create profiles on Notata for investors to discover. The information about the startups is limited, and  every single company you might come across on the platform would have to be shared with you by another investor, incubator, accelerator or someone other than the startup themselves."
          },

          {
            type: "p",
            properties: {},
            content: "Notata is created with both VC-firms, accelerators, incubators, angel investor networks and single angle investors in mind. Though not all the features are relevant for all the types of users we cater for, we would recommend that you spend some time looking through the help material, as the software is designed as a highly customisable framework that can be used in many different ways."
          },
          {
            type: "p",
            properties: {},
            content: "Notata is created with both VC-firms, accelerators, incubators, angel investor networks and single angle investors in mind. Though not all the features are relevant for all the types of users we cater for, we would recommend that you spend some time looking through the help material, as the software is designed as a highly customisable framework that can be used in many different ways."
          },
        ]
      },
      {
        title: "The menu",
        content: [
          {
            type: "p",
            properties: {},
            content: "The menu will let you navigate to the different spaces on notata. You can always collapse the menu. On smaller devices, the menu is already collapsed."
          },
          {
            type: "img",
            properties: {
              src: img_0102_menu,
              style: {
                width: "200px",
                height: "408px"
              }
            },
            content: ""
          },
        ],
        sections: [

          {
            title: "Dashboard",
            content: [
              {
                type: "p",
                properties: {},
                content: "The dashboard will give you a quick snapshot on your account. This can be useful if you work in a team, where you get to the latest activities of your team members. Here you will also be able to see group invitations, and the latest group activities that’s been going on."
              },
              {
                type: "img",
                properties: {
                  src: img_010201_menu_dashboard,
                  style: {
                    width: "200px",
                    height: "65px"
                  }
                }
              },
              // {
              //   type: "a",
              //   properties: [{href: "http://sndsdsa.com"}],
              //   content: "Read more about the dashboard here."
              // }
            ]
          },

          {
            title: "My Startups",
            content: [
              {
                type: "p",
                properties: {},
                content: "This is you (and your teams) private deal flow. All the companies that comes your way will end up here. This is your workspace."
              },
              {
                type: "img",
                properties: {
                  src: img_010202_menu_my_startups,
                  style: {
                    width: "200px",
                    height: "61px"
                  }
                }
              },
              // {
              //   type: "a",
              //   properties: [{href: "http://sndsdsa.com"}],
              //   content: "Read more about My startups here."
              // }
            ]
          },

          {
            title: "Groups",
            content: [
              {
                type: "p",
                properties: {},
                content: "In groups you can share deals, comments and evaluations of startups with investors outside of your core team. This can for example be investor clubs, committees, and boards in closed groups, or portfolio companies, accelerator graduates, Demo Day companies etc, in open groups."
              },
              {
                type: "img",
                properties: {
                  src: img_010203_menu_groups,
                  style: {
                    width: "200px",
                    height: "61px"
                  }
                }
              },
              // {
              //   type: "a",
              //   properties: [{href: "http://sndsdsa.com"}],
              //   content: "Read more about groups here."
              // }
            ]
          },

          {
            title: "Reports",
            content: [
              {
                type: "p",
                properties: {},
                content: "In the reports you get to see the big picture of what your deal flow looks like."
              },
              {
                type: "img",
                properties: {
                  src: img_010204_menu_reports,
                  style: {
                    width: "200px",
                    height: "61px"
                  }
                }
              },
              // {
              //   type: "a",
              //   properties: [{href: "http://sndsdsa.com"}],
              //   content: "Read more about reports here."
              // }
            ]
          },

          {
            title: "News",
            content: [
              {
                type: "p",
                properties: {},
                content: "This is where Team Notata shares news with you, such as feature updates, new exciting public groups, etc."
              },
              {
                type: "img",
                properties: {
                  src: img_010205_menu_news,
                  style: {
                    width: "200px",
                    height: "61px"
                  }
                }
              },
              // {
              //   type: "a",
              //   properties: [{href: "http://sndsdsa.com"}],
              //   content: "Read more about news here."
              // }
            ]
          },

          {
            title: "Settings",
            content: [
              {
                type: "p",
                properties: {},
                content: "As mentioned, Notata is designed as a very flexible frame work, and in the settings part you get to customise your workspace to fit your needs."
              },
              {
                type: "p",
                properties: {},
                content: "The default settings in Notata is based on an angel investor’s perspective, so if you are a VC, accelerator, network or inkubator, we’d highly recommend setting notata up to match your mandate and routines."
              },
              {
                type: "img",
                properties: {
                  src: img_010206_menu_settings,
                  style: {
                    width: "200px",
                    height: "61px"
                  }
                }
              },
              // {
              //   type: "a",
              //   properties: [{href: "http://sndsdsa.com"}],
              //   content: "Read more about settings here."
              // }
            ]
          }


        ]

      },
      {
        title: "The dashboard",
        content: [
          {
            type: "p",
            properties: {},
            content: "The dashboard is meant to give you a quick overview of the latest activities that's been happening since you last logged in. Here you can get some quick info about your dealflow, what your team mates have been up to, what your community has been up to, as well as news from team Notata."
          },
          {
            type: "img",
            properties: {
              src: img_0103_dashboard,
              style: {
                width: "800px",
                height: "489px"
              }
            }
          }
        ],
        sections: [

          {
            title: "Group activities",
            content: [
              {
                type: "img",
                properties: {
                  src: img_010301_dashboard_group_act,
                  style: {
                    width: "300px",
                    height: "235px"
                  }
                }
              },
              {
                type: "p",
                properties: {},
                content: "This colored window gives you a quick overview of the latest startups that has been shared with you through groups that you are member of. Remember that startups shared in groups don't automatically get added to your deal flow."
              },
            ]
          },

          {
            title: "Group invitations",
            content: [
              {
                type: "img",
                properties: {
                  src: img_010302_dashboard_group_inv,
                  style: {
                    width: "300px",
                    height: "176px"
                  }
                }
              },
              {
                type: "p",
                properties: {},
                content: "This is where your group invitations will appear. You might be invited to closed groups or open groups. A closed group is a \"secret\" group that you have to be invited to, while open groups are open for anyone on notata to join."
              },
            ]
          },

          {
            title: "My startups",
            content: [
              {
                type: "img",
                properties: {
                  src: img_010303_dashboard_mystartups,
                  style: {
                    width: "300px",
                    height: "140px"
                  }
                }
              },
              {
                type: "p",
                properties: {},
                content: "This is simply the last three startups that you've added to your deal flow."
              },
            ]
          },

          {
            title: "News",
            content: [
              {
                type: "img",
                properties: {
                  src: img_010304_dashboard_news,
                  style: {
                    width: "300px",
                    height: "496px"
                  }
                }
              },
              {
                type: "p",
                properties: {},
                content: "The notata team will occasionally update the new section with important messages, such as events near you, new features or surveys that you might be interested in."
              },
            ]
          },

          {
            title: "Notifications",
            content: [
              {
                type: "img",
                properties: {
                  src: img_010305_dashboard_notifications,
                  style: {
                    width: "300px",
                    height: "373px"
                  }
                }
              },
              {
                type: "p",
                properties: {},
                content: "In the notifications you will be able to track what's happening to your account. If you are more than one person on your team, you will be able to see what your team mates has been up to, what companies they have worked with, etc."
              },
            ]
          },

          {
            title: "Reports",
            content: [
              {
                type: "img",
                properties: {
                  src: img_010306_dashboard_reports,
                  style: {
                    width: "300px",
                    height: "300px"
                  }
                }
              },
              {
                type: "p",
                properties: {},
                content: "The reports window on the dashboard allows you to choose one metric that you might be particularly interested in following. (You can see all the verticals in the \"Reports\" section in the menu)."
              },
            ]
          },
        ]

      },
    ],
  },

  {
    title: "The Table",
    content: [
      {
        type: "p",
        properties: {},
        content: "The table shows your main deal flow. This is where you get to see and compare all the companies that comes your way, and is a great place to start understanding what kind of deals you have access to."
      },
      {
        type: "iframe",
        properties: {
          ...iframeDefaultProperties,
          src: "https://www.youtube.com/embed/HQBaZJVbB5g",
        },
        // caption: "Say something"
      }
    ],
    sections: [
      {
        title: "Concept of deal flow",
        content: [
          {
            type: "p",
            properties: {},
            content: "A typical investor invests in about 1% of the cases they look at, so it's important to make sure that you have some volume to your deal flow. It's also important to attract the best deals as early as possible, as the shelflife of really good deals tend to be short."
          },
          {
            type: "p",
            properties: {},
            content: "For new investors it's normal to be excited about the first companies you see, but as you've looked at a houndred companies or so, this excitement will wear off, and you will be able to have a more objective approach to the companies you look at."
          },
          {
            type: "p",
            properties: {},
            content: "We would also highly recommend having a structured approach to the deal flow itself, and that you mark where the deal comes from, both generally (accelerator programme), and specifically (Y-Combinator). That way you can easily see where your most attractive deals come from."
          },
          {
            type: "p",
            properties: {},
            content: "When you've been in the game for a year or two, you will have accumulated enough data to take a retrospective look at your deal flow. You will now be able to see which good deals you turned down, and what bad deals you went into. This information will enable you to optimize the way you evaluate deals."
          },
        ],
      },
      {
        title: "Customise anything",
        content: [
          {
            type: "p",
            properties: {},
            content: "As mentioned, notata is a flexible framework that can be set up in various ways to suit your mandate and needs."
          },
          {
            type: "img",
            properties: {
              src: img_0202_customize,
              style: {
                width: "800px",
                height: "451px"
              },
            },
            caption: "The table, like most components of notata, is highly customizable."
          },
          {
            type: "p",
            properties: {},
            content: "The table itself has a lot of interactive components to it, but not all of them might be of interest to you. Play around with \"Manage Columns\" to customize the workspace to fit your needs. Also keep in mind that the more consistent you are in tagging the startups in your deal flow, the better filtering and sorting possibilities you will have in the table."
          },
          // {
          //   type: "p",
          //   properties: {},
          //   content: "Each tag group can be set as a column in the table, which gives great flexibility. Typical cases for this can be a group called \"Deal Lead\", where you get to see who in the organization is responsible for the individual deals. It's also recommended to use tags in combination with the funnel. A funnel step can for example be \"closed\", then a tag group called \"Reason for closed\" can contain tags such as \"invested\", \"wrong ticket size\", \"missed the deal\", \"no diversity on founding team\", etc. "
          // },
          {
            type: "p",
            properties: {},
            content: "We highly recommend spending some time figuring out what tags you'd like to set up, as this gives good control over the deal flow."
          },
        ],
      },
      {
        title: "Use the filters smartly",
        content: [
          {
            type: "p",
            properties: {},
            content: "With smart use of filters you can access your data in many different ways. A typical case is to compare a current startup with similar cases you've looked at before. Let's say you've come across a PropTech company. How does this compare to other PropTech companies you've looked at before? Do you want to invest? Why? Is this company better that the companies you've already said no to, or are you being charmed by a founder and a good pitch?"
          },
          {
            type: "img",
            properties: {
              src: img_0203_filters,
              style: {
                width: "800px",
                height: "272px"
              },
            },
            caption: "In the table above, we are looking at all the PropTech companies we have looked at the last year, where we have met the team, sorted by subjective score."
          },
        ],
      },
      {
        title: "What teams can do together",
        content: [


          {
            type: "p",
            properties: {},
            content: "Although notata is fun for single angel investors, its true potential opens when you start working on notata as a team. The first thing we'd recommend to do is to create a tag group called \"Deal Lead\", so that you easily can see who's responsible for the deals."
          },

          {
            type: "p",
            properties: {},
            content: "As a team on notata you get to see how your colleagues are evaluating the companies in your deal flow. You can share comments and collaborate on the deals before you lift them up in your investment committee."
          },

          {
            type: "img",
            properties: {
              src: img_0204_team,
              style: {
                width: "800px",
                height: "315px"
              },
            },
            caption: "In the table above we are looking at companies we have invested in while displaying who was the deal lead."
          },

        ],
      },
    ]
  },

  {
    title: "Adding startup to deal flow",
    content: [
      {
        type: "p",
        properties: {},
        content: "There are several ways for a startup to enter your deal flow (table):"
      },

      {
        type: "ul",
        properties: {},
        content: [
          "you add it manually",
          "you ask a startup to add themselves",
          "you have a form on your website",
          "someone shares a deal with you "
        ]
      },

      {
        type: "p",
        properties: {},
        content: "Either way, no startup will be added to your deal flow without you confirming it first. Every startup shared with you through groups, or any startup that may fill out a form on your website, will be have to be confirmed by you (or one of your team members) for it to be added to your deal flow."
      },


      {
        type: "iframe",
        properties: {
          ...iframeDefaultProperties,
          src: "https://www.youtube.com/embed/MatrMs1iPIs",
        }
      }
    ],
    sections: [
      {
        title: "Add startup manually",
        content: [
          {
            type: "p",
            properties: {},
            content: "In some cases you don't need more than the company name to start working with the deal."
          },
          {
            type: "p",
            properties: {},
            content: "Let's say you go to an event to watch 20 companies pitch. You might want to just add them all to your deal flow, put a note that you saw them pitch, and set some quick subjective scores just so you remember if you got impressed by the companies or not. This information might come in very handy when you see the companies again after six months and already have track record on them."
          },
          {
            type: "p",
            properties: {},
            content: "If you are lucky enough to be a small investor that gets to join a hot deal, it might also not feel natural to have the startup spending time filling out this information for you. This might be publicly known information that you can add yourself, or it might simply not be relevant for the deal."
          },
          {
            type: "p",
            properties: {
              style: { fontStyle: "italic" }
            },
            content: "(Information about the startup companies created by the companies themselves is outside of notata's main areas of interests, as we focus on the investor-related data)"
          },

          {
            type: "img",
            properties: {
              src: img_0301_man,
              style: {
                width: "600px",
                height: "705px"
              },
            },
            caption: "In the picture above you see the steps of creating a startups, and editing its content."
          },




        ],
      },
      {
        title: "Invite startup to fill out data",
        content: [
          {
            type: "p",
            properties: {},
            content: "Very often the startup will contact you as an investor directly, either by email or on LinkedIn. In these cases you typically want to create a startup entry in notata, and let the startup fill out the information about their business and the current investment round."
          },
          {
            type: "p",
            properties: {},
            content: "You can do this by clicking \"Invite startup to fill in this information\". When you do that a link that grants access to the startup information will be created for you to share with the startup. No other information will be available to the startup – just the data you see under the \"Startup Info\" tab will be shared, and you can always revoke this access."
          },
          {
            type: "p",
            properties: {},
            content: "Keep in mind that no email is being automatically sent from notata at this stage. A link that you can share will be created, but it's up to you to share this link."
          },
          {
            type: "p",
            properties: {},
            content: "As soon as the startup has submitted the updated startup info, a notification is being made and you will receive an email."
          },
          {
            type: "img",
            properties: {
              src: img_0302_inv,
              style: {
                width: "600px",
                height: "807px"
              },
            },
            caption: "A typical case is that you as an investor adds the startup to your deal flow, then ask the startup to complete the company information."
          }
        ],
      },
      {
        title: "Use form on website",
        content: [
          {
            type: "p",
            properties: {},
            content: "If you have a website you can add a notata form directly to your site either as an embeddable iframe or as an external link (recommended). This way startups can show their interest and share company information with you directly."
          },
          {
            type: "img",
            properties: {
              src: img_030301_form,
              style: {
                width: "600px",
                height: "311px"
              },
            },
            caption: "What the form looks like on the notata website."
          },
          {
            type: "p",
            properties: {},
            content: "Anyone can create their own public form through the settings panel. These forms are currently not customizable – something that will change in the next major release."
          },
          {
            type: "img",
            properties: {
              src: img_030302_form,
              style: {
                width: "600px",
                height: "566px"
              },
            },
            caption: "You can choose if you want the form to appear in a new window as an external link (recommended), or you can embed the form as an iframe on your site."
          },
          {
            type: "p",
            properties: {},
            content: "When a startup has completed a form on your site, you will receive a notification where you can choose to save the startup or not. The reason for this logic is for you to have complete control over what companies ends up in your deal flow."
          },
          {
            type: "img",
            properties: {
              src: img_030303_form,
              style: {
                width: "600px",
                height: "389px"
              },
            },
            caption: "After a startup has filled out the form on your website."
          }

        ],
      },
    ]
  },

  {
    title: "Startup (general)",
    content: [
      {
        type: "p",
        properties: {},
        content: "Once you've click your way into a startup from the list you can start working with that company. You have several tabs and options."
      },


      {
        type: "iframe",
        properties: {
          ...iframeDefaultProperties,
          src: "https://www.youtube.com/embed/ES-Lf4XSOzw",
        }
      },
    ],
    sections: [
      {
        title: "Notes",
        content: [
          {
            type: "p",
            properties: {},
            content: "The notes section on the startup page is a place for internal notes. These notes will stay between you and your team, and cannot be shared with startups or anyone outside your organization. (In the groups you have a separate notes section for people in the group. However, internal notes and group notes cannot be mixed)"
          },
          {
            type: "img",
            properties: {
              src: img_0401_notes,
              style: {
                width: "450px",
                height: "763px"
              },
            },
            caption: "The notes section on the startup page."
          },
        ],
      },
      {
        title: "Overview section",
        content: [
          {
            type: "p",
            properties: {},
            content: "Under the \"Overview\" part you will find the following resources:"
          },
          {
            type: "ul",
            properties: {},
            content: [
              "subjective score",
              "evaluation summaries",
              "tags",
              "funnels",
              "groups",
              "impact goals",
              "external resources",
              "similar startups",
            ]
          },
          {
            type: "img",
            properties: {
              src: img_overview,
              style: {
                width: "600px",
                height: "151px"
              },
            },
          },
        ],
        sections: [

          {
            title: "Subjective score",
            content: [
              {
                type: "p",
                properties: {},
                content: "Subjective score is something we use to mark down our \"gut feeling\". We very often know if we get excited by a company, if it fits the right ticket price, technology, mandate, etc. Most companies you will not spend much time evaluating, but it might be quite valuable to spend a second or two marking the subjective score, as it makes it easier to see if a company has progressed or pivoted since last time you met them."
              },
              {
                type: "img",
                properties: {
                  src: img_040201_subjective,
                  style: {
                    width: "600px",
                    height: "289px"
                  },
                },
                caption: "We typically let more than one person mark their subjective score, so that we know we are on the same page."
              },

            ],
          },

          {
            title: "Evaluation summaries",
            content: [
              {
                type: "p",
                properties: {},
                content: "This part of the overview section is only available to users that's more than one person on the team. "
              },
              {
                type: "p",
                properties: {},
                content: "Here you get a quick summary of all the evaluations that your team has done of this company. You can go into the \"evaluation\" tab for more details."
              },
              {
                type: "img",
                properties: {
                  src: img_040202_esum,
                  style: {
                    width: "600px",
                    height: "203px"
                  },
                },
                caption: "If you are two or more on the team, you get to see the evaluation summary averages in the startup overview section page."
              },
            ],
          },

          {
            title: "Tags",
            content: [
              {
                type: "p",
                properties: {},
                content: "Tags makes it easy to measure and compare, something that becomes increasingly valuable as you produce more and more data. If you spend some time setting up a tag system that suits your needs from the beginning you might save yourself some time in the future."
              },
              {
                type: "p",
                properties: {},
                content: "Notata comes with a set of pre-defined tags targeted at angel investors, but we see a lot of creativity when it comes to tags. Tags that might be interesting to measure can be geography (where does my deals come from), source (who gives me the best deals), or gender and diversity (how many female founders have I evaluated the last year)"
              },
              {
                type: "img",
                properties: {
                  src: img_040203_tags,
                  style: {
                    width: "600px",
                    height: "235px"
                  },
                },
                caption: "Tags makes the foundation for good sorting, filtering and reporting."
              },


            ],
          },
          {
            title: "Funnels",
            content: [
              {
                type: "p",
                properties: {},
                content: "Though it's possible to create more than one funnel, we recommend that you stick to one funnel per process, and that you don't mix them up. It's recommended that you combine your funnels with tags in order to get the most our of the system. We see many people creating a group called \"reason of rejection\" that they combine with the funnel. That way your funnel can be \"Met Team\" and your \"reason for rejection\" can be \"Too high ticket price\"."
              },
              {
                type: "p",
                properties: {},
                content: "Like tags you can customize your funnels under the settings menu in the left bar."
              },
              {
                type: "img",
                properties: {
                  src: img_040204_funnel,
                  style: {
                    width: "600px",
                    height: "376px"
                  },
                },
                caption: "Example of having two funnels. We don't recommend this unless you have two separate processes, such as an incubator and a fund under the same organization."
              },


            ],
          },
          {
            title: "Groups",
            content: [
              {
                type: "p",
                properties: {},
                content: "In this box you quickly get to see if the startup is in any groups that you are member of, and you can quickly add the startup to groups from here."
              },
              {
                type: "img",
                properties: {
                  src: img_040205_group,
                  style: {
                    width: "600px",
                    height: "252px"
                  },
                },
                caption: "This startup is in four groups that we are member of."
              },


            ],
          },
          {
            title: "Impact goals",
            content: [
              {
                type: "p",
                properties: {},
                content: "Here you can put some \"impact stickers\" on the startup, to mark how you understand this company ESG approach. It's often useful to let the investors, not startups, do this, as the startups tend to be too generous with themselves when selecting these stickers."
              },

              {
                type: "img",
                properties: {
                  src: img_040206_esg,
                  style: {
                    width: "600px",
                    height: "445px"
                  },
                },
                caption: "Simple impact stickers"
              },

            ],
          },
          {
            title: "External resources",
            content: [
              {
                type: "p",
                properties: {},
                content: "The external resources lets you link the startup page with external websites. This can be anything from a private DD room in DropBox or Google Drive, the Crunch Base page, or any investor relations tool the startup may use."
              },

              {
                type: "img",
                properties: {
                  src: img_040207_ext,
                  style: {
                    width: "600px",
                    height: "209px"
                  },
                },
                caption: "External resources can be any url"
              }

            ],
          },
          {
            title: "Similar startups",
            content: [
              {
                type: "p",
                properties: {},
                content: "At the bottom of the page we get the last three startups we looked at that's the most similar to the one we are looking at. This is calculated based on the tags we've set."
              },
              {
                type: "img",
                properties: {
                  src: img_040208_similar,
                  style: {
                    width: "600px",
                    height: "296px"
                  },
                },
                caption: "The three most similar startups we've looked at recently."
              },
            ],
          },
        ]
      },
    ]
  },

  {
    title: "Evaluations",
    content: [
      {
        type: "p",
        properties: {},
        content: "This is one of the core concepts of notata, and you can \"collect\" evaluations from team members, group members, and external experts."
      },
      {
        type: "p",
        properties: {},
        content: "Startups in early stage are generally hard to evaluate, even for the most seasoned investors. Companies at this stage often don't have many numbers or any hard facts to go by, leaving room for subjectivitiy and unconcious bias in the evaluation process."
      },
      {
        type: "p",
        properties: {},
        content: "This is quite problematic, as most people, unconciously, get attracted to people that are the most similar to themselves. In startup investments this phenomenon is quite visible, as all research shows that diverse teams statistically gives better return on investment, though most investments end up in all white male teams."
      },
      {
        type: "p",
        properties: {},
        content: "One of the reason for this unlogical asymmetry is obviously because most investors are white men, but it's also interesing to know that female founders tend to be met with very different, and much harder, questions than male founders."
      },
      {
        type: "p",
        properties: {},
        content: "The first step in overcoming this hurdle is to meet female, male, and founders of minority backgrounds with the same questions every time."
      },
      {
        type: "p",
        properties: {},
        content: "In notata you can create as many evaluation templates as possible, and you can set each of them up any way you please. Each template will generate a score, and you can set your own criteria. (More about this under 7. Customization)"
      },
      {
        type: "iframe",
        properties: {
          ...iframeDefaultProperties,
          src: "https://www.youtube.com/embed/F8kTCHXPGmU"
        }
      },
    ],
    sections: [
      {
        title: "Evaluate startup",
        content: [
          {
            type: "p",
            properties: {},
            content: "It's fairly simple to evaluate a startup. You basically choose an evaluation from one of your templates and fill out a form. This will in turn generate a score based on your own scoring system that you can set up in settings. You can always go back and change an evaluation, or you can evaluate the same company again with the same template to see if there has been any progression (typical case of for accelerator)."
          },
          {
            type: "img",
            properties: {
              src: img_0501_eval,
              style: {
                width: "600px",
                height: "211px"
              },
            },
            caption: "Here are the three evaluations I've made of this company"
          },
        ],
      },
      {
        title: "Evaluation summary",
        content: [
          {
            type: "p",
            properties: {},
            content: "If you click on the little eye on any evaluation you get access to see the evaluation summary, and how the percentage score is calculated. If there are any comments you get to see them here as well."
          },
          {
            type: "img",
            properties: {
              src: img_0502_evals,
              style: {
                width: "600px",
                height: "537px"
              },
            },
            // caption: "Here are the three evaluations I've made of this company"
          },
        ],
      },
      {
        title: "Team evaluations",
        content: [
          {
            type: "p",
            properties: {},
            content: "If you work in a team you all share the same data, so everybody will be able to see each others evaluations and comments. If one of your team members have evaluated a company, you will get a notification so that you can take a look at it. It's recommended that more than one team member performs the evaluation, so that you can get a more nuanced result."
          },
          {
            type: "iframe",
            properties: {
              ...iframeDefaultProperties,
              src: "https://www.youtube.com/embed/5x4KaE07yfI",
            }
          }
        ],
      },
      {
        title: "Group evaluations",
        content: [
          {
            type: "p",
            properties: {},
            content: "If any external evaluations have been shared with you in a group, they will appear below your teams evaluations."
          },
          {
            type: "p",
            properties: {},
            content: "It can be very useful to share evaluations in groups as that way you'll be able to share this data with people outside of your core team. If you are providing a curated deal flow to a group of investors, adding such an evaluation might underline the legitimacy and the work that's been put in to select this very company. It's also useful if you have an investment committee, as it enables you to share simple DD's and other relevant evaluations in a safe space."
          },
          {
            type: "img",
            properties: {
              src: img_0505_group_evals,
              style: {
                width: "600px",
                height: "271px"
              },
            },
            caption: "Evaluations shared with me through groups."
          },
        ],
      },
      {
        title: "External evaluations",
        content: [
          {
            type: "p",
            properties: {},
            content: "So far we've covered your evaluations, your teams evaluations and group evaluations, but you have one more way of collecting evaluations."
          },
          {
            type: "p",
            properties: {},
            content: "External evaluations don't require that the evaluator signs up to notata in order to perform the evaluation for you. You simply create a public evaluate link and send it to the person you want to make the evaluation for you. This can be useful if you know an industry expert you want to collect an evaluation from, or it can be that you have a large volume of companies to evaluate (for an accelerator for example), and outsource the evaluation process."
          },
          {
            type: "img",
            properties: {
              src: img_0506_ext_eval1,
              style: {
                width: "600px",
                height: "465px"
              },
            },
            caption: "If you navigate over to the \"sharing\" tab, you will be able to select \"share with individuals\". You will then get the option if you want to share just the startup info, if you want to attach an evaluation of your own with the startup info, and if you want the receiver to perform an evaluation for you."
          },
          {
            type: "img",
            properties: {
              src: img_0506_ext_eval2,
              style: {
                width: "600px",
                height: "495px"
              },
            },
            caption: "As soon as an external evaluation has been completed it will appear on the bottom of the evaluation list for all your team members to see, like show above."
          },
        ],
      },
    ]
  },

  {
    title: "Groups",
    content: [
      {
        type: "p",
        properties: {},
        content: "Groups is the part of notata where you can share information with people outside of your core team, and you have great control over what access level you want the group to have."
      },
      {
        type: "p",
        properties: {},
        content: "This is great for creating investment committees, promote a demo day, share a curated deal flow, or to host an investment clubs."
      },
      {
        type: "p",
        properties: {},
        content: "There are three types of groups:"
      },
      {
        type: "ul",
        properties: {},
        content: [
          "private",
          "open",
          "public"
        ]
      },
    ],
    sections: [
      {
        title: "Private groups",
        content: [
          {
            type: "p",
            properties: {},
            content: "Private groups are the default groups where all the members have to be invited to the group. No information is being shared automatically, and you can choose if you want the members to be able to see each other and if you want the members to be able to share startups, evaluations and comments with the group."
          },
          {
            type: "p",
            properties: {},
            content: "When you invite a person to join your group they will automatically receive an email with instructions to join. If they don't have an account on notata they will receive an email with instructions on how to create a free account."
          },
          {
            type: "img",
            properties: {
              src: img_0601_private,
              style: {
                width: "600px",
                height: "762px"
              },
            },
            caption: "Settings for a group where members can receive whatever data you share with them, but they don't have access to share anything."
          },
        ],
      },
      {
        title: "Open groups",
        content: [
          {
            type: "p",
            properties: {},
            content: "Open groups will let anyone on notata be able to join your group, and is a great way of promoting your demo day or portfolio companies."
          },
          {
            type: "img",
            properties: {
              src: img_0602_open,
              style: {
                width: "600px",
                height: "273px"
              },
            },
            caption: "When setting a group to \"open\" everyone on notata will be able to join your group."
          },
          {
            type: "img",
            properties: {
              src: img_0602_open2,
              style: {
                width: "600px",
                height: "402px"
              },
            },
            caption: "All open groups will appear on everybody's dashboard when they log in."
          },
        ],
      },
      {
        title: "Public groups",
        content: [

          {
            type: "p",
            properties: {},
            content: "Public groups can have many purposes, and can be customized through the settings. You can also choose what level of interactivity you want the members of the groups to have."
          },

          {
            type: "img",
            properties: {
              src: img_0602_public,
              style: {
                width: "600px",
                height: "738px"
              },
            },
            caption: "You can choose to protect a group with a PIN number. If you add \"evaluation\" or \"subjective score\" the public members will have to register their names as well, so you know who provides the information."
          },

          {
            type: "p",
            properties: {},
            content: "When you set your group settings to \"public\" you will be able to share the public link with anyone you want. This can be useful in newsletters, etc."
          },
          {
            type: "p",
            properties: {},
            content: "Enabling \"subjective score\" or \"evaluation\" allows anyone with the link to contribute with their evaluations, something that tend to be quite successful at demo days, where you get to engage the audience in a unique way."
          },

          {
            type: "iframe",
            properties: {
              ...iframeDefaultProperties,
              src: "https://www.youtube.com/embed/bYIaAexLIyQ",
            }
          },
        ],
      },
      {
        title: "Group members",
        content: [
          {
            type: "p",
            properties: {},
            content: "You can invite anyone to join your group. They don't need to have an account with notata before you invite them. As soon as you invite someone an email will be sent with instructions on how to create a free account if they already don't have one."
          },
          {
            type: "img",
            properties: {
              src: img_0602_member,
              style: {
                width: "600px",
                height: "659px"
              },
            },
            caption: "Here you can manage your groups members."
          },
        ],
      },
      {
        title: "Group startups",
        content: [
          {
            type: "p",
            properties: {},
            content: "Here you can manage the startups that's in your group. You can also do this from the table directly, or from the individual startup pages."
          },
          {
            type: "img",
            properties: {
              src: img_0602_startups,
              style: {
                width: "600px",
                height: "618px"
              },
            },
            caption: "Here you can manage your groups startups."
          },
        ],
      },
      {
        title: "Group plugin",
        content: [
          {
            type: "p",
            properties: {},
            content: "The group plugin allows you to showcase your portfolio on your website. This is a great way to show off the cases you want to promote, as it enables investors to import these companies directly into their personal deal flows."
          },
          {
            type: "img",
            properties: {
              src: img_0602_plugin,
              style: {
                width: "600px",
                height: "515px"
              },
            },
            caption: "What the group plugin looks like when embedded on a website."
          },
          {
            type: "iframe",
            properties: {
              ...iframeDefaultProperties,
              src: "https://www.youtube.com/embed/bYIaAexLIyQ",
            },
            caption: "The group plugin allows you to display startups on your website."
          },
        ],
      },
    ]
  },

  {
    title: "Customisation",
    content: [
      {
        type: "p",
        properties: {},
        content: "Notata is a flexible framework that can be set up to serve many different needs. The basic configuration of the software is designed to serve inexperienced angel investors, so if you are an experienced investor, or run an incubator, accelerator or angel network, we highly recommend spending some time setting up your notata workspace so that it can tailor your needs."
      },
      {
        type: "img",
        properties: {
          src: img_0701_settings,
          style: {
            width: "600px",
            height: "401px"
          },
        },
        caption: "You can customize your workspace through settings"
      },

    ],
    sections: [
      {
        title: "Funnels & Tags",
        content: [
          {
            type: "iframe",
            properties: {
              ...iframeDefaultProperties,
              src: "https://www.youtube.com/embed/-__DORgZJvg",
            },
            // caption: "This is caption"
          },
          {
            type: "p",
            properties: {},
            content: "As a rule of thumb you control your process with funnels, and organize with tags. Tags and funnels in combination gives you perfect control, and we recommend spending some time setting this up."
          },
          {
            type: "p",
            properties: {},
            content: "The funnel should represent the steps of your decision process. Although it's possible to have more than one funnel, we don't recommend it unless you have more than one process. An example of this is if you are an investor that also have an incubator. You would typically have one process for the investment decisions, and another for the incubator."
          },
          {
            type: "p",
            properties: {},
            content: "Tags will help you organize your data, and makes the foundation for reporting. When setting up tags think about how you'd like to organize your data, and what you like to measure. Typically you would tag companies with ticket size, what kind of technology they are producing, and where in the world they are located. This information makes it easy to find companies within given parameters, that might be useful to find similar companies. Tags such as \"Famale Founder\", \"Diversity on the Team\" or \"Impact Awareness\" can be used to measure what kind of deals you have access to."
          },
          {
            type: "p",
            properties: {},
            content: "Finally, we would like to recommend combining funnels with tags. As most investors invest in about 1% of the companies they evaluate, it might be interesting to know the reason for rejection. In order to track this you can create a tag group called \"Reason for Rejection\" with tags such as \"Wrong Ticket Size\", \"Wrong Tech\", \"Immature Team\" or \"Market not Large Enough\". In combination with funnels, you can extract information such as: Funnel - Met Team | Reason for Rejection - Immature Team."
          },
          {
            type: "img",
            properties: {
              src: img_0702_funn,
              style: {
                width: "600px",
                height: "125px"
              },
            },
            caption: "Showing funnel stage \"Met Team\" and the \"Rejected\" tag group."
          },
        ],
      },
      {
        title: "Team members",
        content: [
          {
            type: "iframe",
            properties: {
              ...iframeDefaultProperties,
              src: "https://www.youtube.com/embed/OTaHWLMlNWA",
            }
          },
          {
            type: "p",
            properties: {},
            content: "You can add people to join your team. Although it's possible to share information between people through groups, it gives quite limited ability for collaboration."
          },
          {
            type: "p",
            properties: {},
            content: "In order to properly work with your team you have to add them to your account. Everybody on your team has access to the same deal flow (My Startups), the same templates, settings, etc. All evaluations are visible to everybody on the team, and you can have internal comments on the individual cases. If someone on your team adds a new startup, or has completed an evaluation, you will receive a notification so that you can keep updated with your organization."
          },
          {
            type: "img",
            properties: {
              src: img_040201_subjective,
              style: {
                width: "600px",
                height: "289px"
              },
            },
            caption: "When you are on a team you get to see each others work"
          },
          {
            type: "img",
            properties: {
              src: img_0703_team,
              style: {
                width: "600px",
                height: "564px"
              },
            },
            caption: "Settings > My Team"
          },

        ],
      },
      {
        title: "Evaluation templates",
        content: [
          {
            type: "iframe",
            properties: {
              ...iframeDefaultProperties,
              src: "https://www.youtube.com/embed/qciE6VMpNhw",
            },
            caption: "This is caption"
          },
          {
            type: "p",
            properties: {},
            content: "You evaluate companies based on evaluation templates. You can evaluate each company as many times as you want, with as many evaluation templates as you like. "
          },
          {
            type: "p",
            properties: {},
            content: "The evaluation templates themselves are simple scoring systems based on traditional forms. You set yourself a list of questions that you are going to answer, and then you give each anser option a different value. That way we can calculate a score for each company you evaluate. This can be very useful as this method tends to eliminate unconcious bias."
          },
          {
            type: "p",
            properties: {},
            content: "Notata comes with a few pre defined templates, specifically targetted at angel investors, but we recommend that more senior investors plays around with the evlauation templates a bit in order to set it up to be in line with your interests or mandate."
          },
          {
            type: "img",
            properties: {
              src: img_etl,
              style: {
                width: "600px",
                height: "359px"
              },
            },
            caption: "The scoring system"
          },

        ],
      },
      {
        title: "Web form",
        content: [
          {
            type: "iframe",
            properties: {
              ...iframeDefaultProperties,
              src: "https://www.youtube.com/embed/slNWIi2H6Yg",
            },
            // caption: "This is caption"
          },
          {
            type: "p",
            properties: {},
            content: "If you have a website you can add a notata form directly to your site either as an embeddable iframe or as an external link (recommended). This way startups can show their interest and share company information with you directly."
          },
          {
            type: "img",
            properties: {
              src: img_030301_form,
              style: {
                width: "600px",
                height: "311px"
              },
            },
            caption: "What the form looks like on the notata website."
          },
          {
            type: "p",
            properties: {},
            content: "Anyone can create their own public form through the settings panel. These forms are currently not customizable – something that will change in the next major release."
          },
          {
            type: "img",
            properties: {
              src: img_030302_form,
              style: {
                width: "600px",
                height: "566px"
              },
            },
            caption: "You can choose if you want the form to appear in a new window as an external link (recommended), or you can embed the form as an iframe on your site."
          },
          {
            type: "p",
            properties: {},
            content: "When a startup has completed a form on your site, you will receive a notification where you can choose to save the startup or not. The reason for this logic is for you to have complete control over what companies ends up in your deal flow."
          },
          {
            type: "img",
            properties: {
              src: img_030303_form,
              style: {
                width: "600px",
                height: "389px"
              },
            },
            caption: "After a startup has filled out the form on your website."
          }
        ],
      },

    ]
  },



  {
    title: "Cases",
    content: [
      {
        type: "p",
        properties: {},
        content: "There are many different use cases for notata, and the software itself is built as a flexible framework that can be molded around your existing workflow. However, there are some features, best practises and \"pro-tips\" that are more relevant for some groups than others."
      },
      {
        type: "iframe",
        properties: {
          ...iframeDefaultProperties,
          src: "https://www.youtube.com/embed/W-T8XCcm5TU",
        }
      },
    ],
    sections: [
      {
        title: "VC firms / Corporate Venture",
        content: [
          {
            type: "p",
            properties: {},
            content: "Most VC-firms already have a set mandate, workflow and habits they like to follow, and in order to get the most out of notata you should spend some time setting up the software to reflect the way you already work today."
          },
          {
            type: "p",
            properties: {},
            content: "We recognize that the groups and sharing features of the software is not the most used features by VC's. To them, internal collaboration, systematic evaluation, good documentation and excellent reporting tend to be more important."
          },
          {
            type: "p",
            properties: {},
            content: "To these kinds of users we highly recommend spending some time doing the following:"
          },
          {
            type: "ul",
            properties: {},
            content: [
              "Set up your team",
              "Define funnel",
              "Define tags (for reporting)",
              "Customize evaluation templates to suit your mandate",
              "Add startup form to your website"
            ]
          },
          {
            type: "p",
            properties: {},
            content: "You may also be interested in taking a look at the public group plugin, so that you can showcase your portfolio companies on your website."
          },
          {
            type: "p",
            properties: {},
            content: "If you already have a spreadsheet with companies that you've looked at before we can import that into your account manually. Just contact support, and they will help you."
          },
        ],
      },
      {
        title: "Accelerators & Incubators",
        content: [
          {
            type: "p",
            properties: {},
            content: "Accelerators and incubators work quite differently from VC-firms, as they to a larger extend depends on their networks to promote themselves."
          },
          {
            type: "p",
            properties: {},
            content: "Many accelerators also invest in some of the companies that goes through their programme, meaning that they often have two separate processes - one for the programme application and one for the investment process."
          },
          {
            type: "p",
            properties: {},
            content: "If you have two processes like that, we recommend creating two separate funnels."
          },
          {
            type: "p",
            properties: {},
            content: "Apart from that, the initial setup should be like the one for VC's, as shown above. However, there are a few more features that might be of your interest."
          },
        ],
        sections: [
          {
            title: "Groups for accelerators / incubators",
            content: [
              {
                type: "p",
                properties: {},
                content: "Groups will give you the ability to share companies with people outside of your core team. There are many cases where this can come in handy."
              },
              {
                type: "p",
                properties: {},
                content: "Some accelerators have a large amount of applicants and have to outsource part of the initial evaluation proceess. You can do this with groups by simply add the startups that you want to have evaluated in a group before you invite the external evaluators. This way you can collect data from external people without having to your own sensitive information."
              },
              {
                type: "p",
                properties: {},
                content: "The next step might be to shortlist the top candidates and discuss the cases with a board of some sorts. You can simply do this by adding the top companies to a new group and invite your board to vote up their favorites. "
              },
              {
                type: "p",
                properties: {},
                content: "It's common to have a Demo Day where the accelerator or incubator is is inviting their network to see the graduates pitch. You can in a very efficient way promote these companies through a public group on notata. You can also choose how protected you want that group to be, and how interactive you want it to be. You may want to include the selected companies in a newsletter to invited investors, something you can simply do by making a public group. Or, if you want to engage the audience on the demo day, you can ask everybody to rate the pitching companies on a scale from 1 to 10, selecting the audience favourite."
              },
            ]
          }
        ]

      },
      {
        title: "Investor Networks",
        content: [
          {
            type: "p",
            properties: {},
            content: "Investor networks typically provide a curated dealflow to a group of paying members. They go though a lot of companies, and present a select few quality deals that they present to their members."
          },
          {
            type: "p",
            properties: {},
            content: "In general the setup for an account like this is similar to the one of VC's and incubators / accelerators, as we recommend customizing the platform to suit your needs and processes."
          },
          {
            type: "p",
            properties: {},
            content: "However, it can be quite valuable to manage your network of angel investors through notata for several reasons."
          },
          {
            type: "p",
            properties: {},
            content: "First of all you get to present your investment cases in a closed, secure group, giving you ultimate control over what information your members get access to. This is typically done by email today, meaning that you don't get to see how active your members are. In notata your members can share comments and evaluations of the companies (if you allow them to), enabling having a way more interactive following. Many angel networks also attach an evaluation or a DD with the companies they present to their members, underlining the quality of the deal they are presenting."
          },
          {
            type: "p",
            properties: {},
            content: "Second of all it allows your members to \"collect\" startups you share with them over time, which in turn builds self confidence that can trigger an investment in the future. It's quite common for angel investors to sign up to investor networks, only to stay passive for long stretches of time. During this time they will receive lots of emails with investment opportunities that they won't take. Two years down the road they might decide to get back in the game, but now they have to look through old emails, find contact details of their investor friends, call the network they are part of to check for events, and generally make quite a large effort. With notata they can just log in and see what they missed out on in their dormancy, and see what investors they now that are still active."
          },
        ],
      },
      {
        title: "Angel Investors",
        content: [
          {
            type: "iframe",
            properties: {
              ...iframeDefaultProperties,
              src: "https://www.youtube.com/embed/EL7Jv0F9jiU",
            }
          },
          {
            type: "p",
            properties: {},
            content: "As an angel investor you can use notata to \"collect deals\", and to learn how to become good at evaluating startups."
          },
          {
            type: "p",
            properties: {},
            content: "It's quite hard to evaluate early stage startup companies, as most of the time you will not have much data to go by. Though it's important to invest in companies that excite you, you should know that it's quite common for your unconscious bias to drive your decision processes. This is true for even the most seasoned investors."
          },
          {
            type: "p",
            properties: {},
            content: "An example of this is that it's statistically proven that diverse founding teams makes the best return of investment. At the same time it is true that nearly all invested capital goes to companies lead by white male founders. This doesn't make any financial sense, but comes from a psychological phenomenon that proves that we are more likely to be attracted to people that are the most like ourselves, and since most investors are white men, most startups that receive invested capital are also white men."
          },
          {
            type: "p",
            properties: {},
            content: "However, if you have a structured approach to how you evaluate these companies, your own biases will become much clearer, and you will be able to make more informed decisions."
          },
          {
            type: "p",
            properties: {},
            content: "Notata can also be used to subscribe to startups coming from local incubators, accelerators or hubs. You can also create your own investment clubs, and create a community."
          },
        ],
      }
    ]
  },


]
