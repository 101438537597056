import React, { useEffect, useState } from "react";

// API STUFF
import { useQuery, useMutation } from "@apollo/client";
import { logGet } from "private/Apollo/Queries";
import { logCreate, logDelete, logUpdate } from "private/Apollo/Mutations";

// COMMON COMPONENT
import CommentBox from "Components/Common/CommentBox/CommentBox";


// MAIN FUNCTION
export default function CommentBoxLogic({ connectionId,coomentBox }) {
  // STATES
  const [comments, setComments] = useState([]);

  let variables = { connectionId };

  // Queries
  const { data, loading, error } = useQuery(logGet, { variables });

  // Mutations
  const [mutationLogCreate, {loading : createLoader}] = useMutation(logCreate);
  const [mutationLogDelete] = useMutation(logDelete);
  const [mutationLogUpdate, {loading : editLoader}] = useMutation(logUpdate, {
    fetchPolicy: 'no-cache'
  });

  // EFFECTS
  useEffect(()=>{
    if(data?.logGet){
      setComments(data?.logGet?.filter(l => l.logType === "COMMENT"));
    }
  }, [data])

  // Update Comment
  const updateComment = async (commentId, newVal, index) => {
    let variables = {
      id: commentId,
      input: {
        logType: "COMMENT",
        dataPairs: [
          {
            key: "TEXT",
            val: newVal,
          },
        ],
      },
    };

    try {
      let {data : {logUpdate : updatedComment }} = await mutationLogUpdate({ variables });
      if(updatedComment){
        let commentCopy = [...comments];
        commentCopy[index] = updatedComment;
        setComments(commentCopy);
      }
    } catch (error) {
      return console.log("error", error);
    }
  };

  // Create Comment
  const createComment = async (newComment, commentsRef) => {
    if (!newComment || !newComment.length) {
      return;
    }

    let variables = {
      connectionId: connectionId,
      input: {
        logType: "COMMENT",
        dataPairs: {
          val: newComment,
          key: "TEXT",
        },
      },
    };

    try {
      let {data:{logCreate}} = await mutationLogCreate({ variables });
      if(logCreate){
        setComments(prevState=>([
          ...prevState,
          logCreate
        ]))
        // Adding 200 just to make sure next comment will be covered
        commentsRef.scroll({ top: commentsRef.scrollHeight+200, behavior: 'smooth' });
      }
    } catch (error) {
      return console.log("error", error);
    }

  };

  // Delete Comment
  const deleteComment = async (deleteId, index) => {
    if (!deleteId) {
      return;
    }

    try {
      let variables = {
        id: deleteId,
      };
      let {data : {logDelete : {message} } } =  await mutationLogDelete({ variables });
      if(message){
        let commentCopy = [...comments];
        commentCopy.splice(index, 1);
        setComments(commentCopy);
      }
    } catch (error) {
      return console.log("error", error);
    }
  };

  if (error) return <div>SOME ERROR OCCURUED</div>;

  return (
    <CommentBox
      comments={comments}
      loading={loading}
      createLoader={createLoader}
      editLoader={editLoader}
      deleteComment={deleteComment}
      createComment={createComment}
      updateComment={updateComment}
      coomentBox={coomentBox}
    />
  );
}
