import React from "react";

export default function ReportBarChart({ tagsData, totalConnectionCount, stylingForDashbaord }) {
  // ("tagsData",tagsData)
  const data = [...tagsData]

  return (
    <div className={`bar-chart-container ${stylingForDashbaord ? 'bar-chart-container-dashbaord' : ''}`}>
      <div className="bar-chart-container__data">

        {data?.sort((a, b) => a.connectionCount > b.connectionCount ? -1 : 1).map((tag, index) => {
          return (
            <div className="bar-chart-container__data__row" key={`bar-data-key-${index}`}>
              <div className="td-country">{tag.tagName}</div>
              <div className="td-startups">{tag.connectionCount}</div>
              <div className="td-noof-startups">
              {/* && Math.ceil((tag.connectionCount * 100) / totalConnectionCount)===1 */}
              {tag.connectionCount === 1 && Math.ceil((tag.connectionCount * 100) / totalConnectionCount)===1 ? <i class="fa fa-chevron-left icon_left"></i>:<i class="fa fa-chevron-left iconvisible"></i>}
                <span>{tag.connectionCount === 0 ? 0 : Math.ceil((tag.connectionCount * 100) / totalConnectionCount)}%</span>
                <div className="bar-container">
                  <div
                    className={`bar-${Math.ceil(
                      (tag.connectionCount * 100) / totalConnectionCount
                    )}`}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}


