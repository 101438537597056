import React from "react";

//common UI Component
import { NotataList } from "Components/NotataComponents";

//Definition
import { evaluate_page, evaluation_summary_page, group, group_dashboard } from "definitions";

//Others
import { Link } from "react-router-dom";
import moment from "moment";

//Main function
export default function GroupEvaluations({ connection, setEvaluateModalContent, setSummaryModalContent }) {

  //Get data
  let sharingInfo = connection?.groupSharingInfo;
  sharingInfo = sharingInfo.filter(
    ({ evaluationSummaries }) => evaluationSummaries.length
  );

  return (
    <div className={`col-sm-12 details`}>
      <div className="">
        {sharingInfo?.map(info => (
          <div
            key={info.group?.id}
            className="row evalations-container__details"
            // onClick={async () => {
            //   let variables = {
            //     id: group.id,
            //   };
            // }}
          >
            <Link to={`${group_dashboard}/${info.group?.id}`}>
              <ul className={`group-heading`}>
                <li className={"group_item"}>{info.group?.name}</li>
              </ul>
            </Link>

            {info.evaluationSummaries.map(summary => {

              let evaluations = info?.evaluations?.filter(
                evaluation => evaluation?.template?.id === summary?.templateId
              );

              // table header
              let tableHeader = [
                {
                  className: "eval-score-heading",
                  content: <div>{summary?.templateName}</div>,
                },
                {
                  className: "heading-submissions hide-on-mobile",
                  content: <div>{summary?.submissions} Submissions</div>,
                },
                {
                  className: "heading-score",
                  content: <div>{summary?.averagePercentageScore}%</div>,
                },
                {
                  className: "heading-right-icons",
                  content: <span/>,
                },
              ]



              // table body
              let tableBody = evaluations?.map(evaluation => [
                // Name
                {
                  content: (
                    <div className="eval-score-name">
                      {evaluation.createdByUser?.given_name}{" "}
                      {evaluation.createdByUser?.family_name}
                      {evaluation?.createdByUser?.isMe && (
                        <i
                          className="fa fa-eye green-eye"
                          aria-hidden="true"
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setEvaluateModalContent(evaluation)
                          }}
                        />
                      )}
                    </div>
                  )
                },

                // Submissions
                {
                  content: (
                    <div className="submissions hide-on-mobile">
                      {moment(evaluation.createdAt).format("ll")}
                    </div>
                  )
                },

                // Score percent
                {
                  content: (
                    <div>{evaluation?.summary?.scorePercent || 0}%</div>
                  )
                },

                // Icons
                {
                  content: (
                    <div>
                      <i
                        className="fa fa-eye green-eye"
                        aria-hidden="true"
                        style={{cursor: "pointer"}}
                        onClick={() => {
                          setSummaryModalContent(evaluation)
                        }}
                      />
                    </div>
                  )
                }

              ])
              
              return (
                <div className="group-evaluation-notata-list" key={summary?.templateId} >
                  <NotataList
                    header={tableHeader}
                    body={tableBody}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}