import React, { useEffect, useState } from "react";

// COMPONENTS
import Sidebar from "../Sidebar";
import TasgFilter from "./TagsFilter";
import { NotataDatepicker, TextBox } from "Components/NotataComponents";
import FunnelFilter from "./FunnelFilter"

// API
import { useQuery } from "@apollo/client";
import { funnelGroupGet } from "private/Apollo/Queries";
import moment from "moment";
//STYLES
import "react-datepicker/dist/react-datepicker.css";
import "../Sidebar.scss";

import { defaultFilters } from "../../constants"
import { getHasFilters } from "../../getHasFilters";

export default function FilterSideBar({
  close,
  filters,
  setFilters
}) {

  // STATES
  const [ searchValue, setSearchValue ] = useState("");
  const [dateFlag, setDateFlag] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isCalendarVisible, setCalendarVisible] = useState(false);

  // QUERIES
  const { data } = useQuery(funnelGroupGet);

  // MAPS & REDUCERS
  const hasFilters = getHasFilters(filters);
  const funnelGroupArray = data?.accountGet?.funnelGroups || [];

  // EFFECTS
  useEffect(() => {
    if (filters.fromDate && filters.toDate) {
      setStartDate(new Date(parseInt(filters.fromDate)));
      setEndDate(new Date(parseInt(filters.toDate)));
    }
    setDateFlag(true);
  }, [filters.fromDate && filters.toDate && !dateFlag]);

  // FUNCTIONS
  const dateRange = (dates, range) => {
    let fromDate = range[0] ? moment(range[0]).format("x") : "";
    let toDate = range[1] ? moment(range[1]).format("x") : "";
    if (fromDate && toDate) {
      setFilters({ ...filters, fromDate, toDate });
    }
    setStartDate(range[0]);
    setEndDate(range[1]);
    if (fromDate && toDate) {
      setCalendarVisible(false);
    }
  };

  const onDateChange = dates => {
    const [start, end] = dates;
    let fromDate = start ? moment(start).format("x") : "";
    let toDate = end ? moment(end).format("x") : "";
    if (fromDate && toDate) {
      setFilters({ ...filters, fromDate, toDate });
    }
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setCalendarVisible(false);
    }
  };


  return (
    <Sidebar
      title={
        // Clear all button
        hasFilters ? (
          <button
            className='clearAllButton'
            type="button"
            onClick={() => {
              setFilters(defaultFilters);
              setStartDate(null);
              setEndDate(null);
            }}
          >
            Clear All Filters
          </button>
        ) : (
          <span className='sidebarHeading'>Filters</span>
        )
      }
      close={close}
    >
      <div className='filter'>

        {/* SEARCH */}
        <div className='search'>
          <TextBox
             type="text"
             value={searchValue}
             onChange={e => {
               setSearchValue(e.target.value);
             }}
             autoComplete="off"
          />
          <button
            onClick={() => {
              setFilters({ ...filters, search: searchValue });
            }}
          >
            Search
          </button>
        </div>

        {/* STARRED */}
        <div className="filterbar_stared_content">
          <h2>STARRED</h2>
          <i
            className={`fas fa-star ${filters?.starred?"star-select":"star-not-select"}`}
            onClick={()=>{
              setFilters({ ...filters, starred: filters?.starred ? null : true })
            }}
          />
        </div>

        {/* FUNNELS */}
        <div className='funnelStage'>
          <h2>FUNNEL</h2>
          <FunnelFilter
            funnelGroupArray={funnelGroupArray}
            setFilters={setFilters}
            filters={filters}
          />
        </div>

        {/* TAGS */}
        <TasgFilter
          filters={filters}
          setFilters={setFilters}
        />

        {/* DATE */}
        <div className='funnelStage'>
          <h2>DATE</h2>
          <NotataDatepicker
            selected={startDate}
            setCalendarVisible={setCalendarVisible}
            onChange={onDateChange}
            dateRange={dateRange}
            isCalendarVisible={isCalendarVisible}
            startDate={startDate}
            endDate={endDate}
            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
          />
        </div>
      </div>

    </Sidebar>
  );
}
