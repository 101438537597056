import React from 'react';
import { Card } from "Components/NotataComponents";
import CardMessageContainer from 'private/Pages/Group/Components/CardMessageContainer';
import "./PinAuthenticator.scss";

export default function AccessDenied(){
    return(
      <div className="pin-authenticator-container">
        <Card
          heading="Notata group"
          containerClassName="pin-authenticator-container__content"
          headerClassName="pin-authenticator-container__content__header"
        >
          <CardMessageContainer
            title="Access Denied"
            message={`We’re sorry, but this group is for invited people only.`}
            error
          />
        </Card>
      </div>
    )
}