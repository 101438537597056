import React, { useState } from "react";

// Components
// import SortByPopup from "./SortByPopup";

// Styles
import styles from "../Kanban.module.css";

// Images
import DropDown from "../../../../../assets/images/DropDown.svg";
import Sort from "../../../../../assets/images/sort.svg";
import { BrowseDropDown } from "Components/NotataComponents";
import { SvgComp } from "private/Pages/commonFunctions";

export default function BoardHeader({
  children,
  color,
  filters,
  setFilter,
  index,
}) {
  // States
  const [popup, setPopup] = useState(false);
  const [name, setName] = useState("");

  // Functions////////
  const handleName = name => {
    setName(name);
    handleSorting(index);
  };

  const handlePopup = () => {
    setPopup(!popup);
  };

  const handleSorting = index => {
    if (name === "Company Name") {
      let sortBy = "ALPHA";
      let sortDirection =
        filters.sortBy === "ALPHA" && filters.sortDirection !== "DESC"
          ? "DESC"
          : "ASC";
      setFilter({
        sortBy,
        sortDirection,
        indexNumber: index,
      });
    } else {
      let sortBy = "STARRED";
      let sortDirection =
        filters.sortBy === "STARRED" && filters.sortDirection !== "DESC"
          ? "DESC"
          : "ASC";
      setFilter({
        sortBy,
        sortDirection,
        indexNumber: index,
      });
    }
  };

  const handleNames = name => {
    setPopup(name);
  };

  // JSX
  return (
    <div className={styles.boardHead}>
      <SvgComp index={index} color={color} />
      <h2 className={styles.boardHeader}>{children}</h2>
      <span className={styles.boardHeadSortBy}>

        <span
          onClick={() => handlePopup()}
          onMouseLeave={() => setPopup(false)}
        >
          Sort By<img src={DropDown} alt="Drop Down Icon"></img>
          <div className={styles.drop_down}>
            <BrowseDropDown
              open={popup}
              setOpen={handleNames}
              items={[
                { text: "Starred", callBack: () => { handleName("Starred") } },
                { text: "Company Name", callBack: () => { handleName("Company Name") } }
              ]}
            />
          </div>
        </span>
        <span onClick={() => handleSorting(index)}>
          <img src={Sort} alt="Sort"></img>
        </span>
      </span>
    </div>
  );
}
