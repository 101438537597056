import React from "react";

//API 
import { useMutation } from "@apollo/client";
import { newsSet } from "../../../Apollo/Mutations";

//Assets
import like from "assets/images/like.svg";
import graylike from "assets/images/graylike.svg";
import graydislike from "assets/images/graydislike.svg";
import dislike from "assets/images/dislike.svg";

// UI COMPONENTS
import { Card } from "Components/NotataComponents";

//Definitions
import { edit_news } from "definitions";
//Others
import moment from "moment";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";

//Main Function
export function EachNews({ data }) {

  // Mutations
  const [setUseful] = useMutation(newsSet);

  return (
    <Card containerClassName="news-container__card" size="large" heading={data.title}
      headerClassName="news-container__card__heading"
      nextToHeaderComponent={
        // Edit icon
        data.canEdit && (
          <Link to={`${edit_news}/${data?.id}`} style={{ textDecoration: 'none' }}>
            <i className="fas fa-pen edit-icon" />
          </Link>
        )
      }
    >
      {/* news image & description */}
      {data.image && (
        <div
          // className="row"
          >
          <div
            // className="col-xs-12 col-sm-4 col-md-4 col-sm-push-8 col-md-push-8"
            >
            <div className="news-container__card__news__img">
              <img src={data.image} alt="" />
            </div>
          </div>
          <div
            // className="col-xs-12 col-sm-8 col-md-8 col-sm-pull-4 col-md-pull-4"
          >
            <p className="news-container__card__news news-container__card__news__partition">
              <ReactMarkdown>
                {data.content}
              </ReactMarkdown>
            </p>
          </div>
        </div>
      )}

      {!data.image && (
        <p className="news-container__card__news news-container__card__news__partition">
          {data.content}
        </p>
      )}

    {/* News footer */}
      <div className="news-container__card__footer">
        <div className="news-container__card__footer__like-dislike">
          <div className="news-container__card__footer__like-dislike__text">
            Did you find this article useful?
          </div>
          {/* lik and dislike thumbs images */}
          <div className="news-container__card__footer__like-dislike__icons">
            <img
              alt="" className={data.isItUseful === false ? "disabled" : "enabled"}
              src={(data.isItUseful === true || data.isItUseful === null) ? like : graylike}

              onClick={() => {
                let variables = {
                  id: data.id,
                  useful: true,
                };
                let optimisticResponse = {
                  newsSet: {
                    ...data,
                    isItUseful: true,
                  },
                };
                setUseful({
                  variables,
                  optimisticResponse,
                });
              }}
            />

            <img alt="" className={data.isItUseful === true ? "disabled" : "enabled"}
              src={data.isItUseful === false || data.isItUseful === null ? dislike : graydislike}
              onClick={() => {
                let variables = {
                  id: data.id,
                  useful: false,
                };
                let optimisticResponse = {
                  newsSet: {
                    ...data,
                    isItUseful: false,
                  },
                };
                setUseful({
                  variables,
                  optimisticResponse
                });
              }}
            />
          </div>
        </div>

        <div className="news-container__card__footer__news-by news-footer">
          <div className="news-container__card__footer__news-by__date">
            {moment(data.createdAt).format("ll")}
          </div>
          <div className="news-container__card__footer__news-by__name">
            {data.createdByUser?.given_name} {data.createdByUser?.family_name}
          </div>
        </div>
      </div>
    </Card>
  );
}