import React from "react";

// API STUFF
import { accountGet } from "private/Apollo/Queries";
import { useQuery } from "@apollo/client";

// STYLES
import "./FunnelsComp.scss"

// OTHERS
import Funnel from "./Funnel"

// MAIN FUNCTION
export default function FunnelsComp({ connection }) {

  // Queries
  const { data  } = useQuery(accountGet);
  let funnelGroups = data?.accountGet?.funnelGroups || [];

  return (
    <div>
      {/* FUNNELS */}
      <div className="funnels-container__funnels">
        {funnelGroups.map(funnelGroup => {
          return (
            <Funnel
              key={funnelGroup.id}
              connection={connection}
              funnelGroup={funnelGroup}
            />
          );
        })}
      </div>
    </div>
  );
}
