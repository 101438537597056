import React, { useState } from "react";

// CUSTOM COMPONENTS
import ScoreSummary from "Components/NotataComponents/ScoreSummary/ScoreSummary";

// HELPERS
import { getSubjectiveScoreSummary } from "../../../../../commonFunctions";

// MAIN FUNCTION
export default function EvaluationSummaries({ startup, group, adminView }) {

  // Constants
  let subjectiveScoreSummary = getSubjectiveScoreSummary(startup);
  let settings = group?.settings;

  // States
  const [viewSummaries, setViewSummaries] = useState(true);

  // Maps and reducers 

  // Return if there are no scores
  if (!subjectiveScoreSummary && !startup.evaluationSummaries) {
    return <span />;
  }

  // Return if it's not enabled in Settings
  if (!settings?.showSummaries && !adminView) {
    return <span />;
  }

  let withPublicSubjectiveScore = group?.publicSettings?.withSubjectiveScores
  let withPublicEvaluation = group?.publicSettings?.withEvaluationTemplate && group?.publicEvaluationTemplate

  return (
    <>

      <div className="startup-card-container__section_header">
        <i
          className={`startup-card-container__section_header__icon ${
            viewSummaries ? "fa fa-chevron-down" : "fa fa-chevron-up"
          }`}
          aria-hidden="true"
          onClick={() => setViewSummaries(!viewSummaries)}
        />
        <span className="startup-card-container__section_header__heading">Evaluation Summaries</span>
      </div>

      {viewSummaries &&
        <>

          {/*PRIVATE*/}
          <div className="startup-card-container__section">
          <div className="row">

            {/* subjective score summary */}
            {subjectiveScoreSummary && (
              <div
                className="col-xs-12 col-sm-6 col-md-4 group-startup-card__score-summaries__subjective-score"
              >
                <ScoreSummary
                  title="Subjective Score"
                  score={subjectiveScoreSummary?.average || 0}
                  highest={subjectiveScoreSummary?.max}
                  lowest={subjectiveScoreSummary?.min}
                  submissions={subjectiveScoreSummary?.submissions}
                />
              </div>
            )}


            {/* evaluation summaries */}
            {startup.evaluationSummaries?.map((summary, i) => (
              <div
                className="col-xs-12 col-sm-6 col-md-4 group-startup-card__score-summaries__subjective-score"
                key={`evaluation-summary-${i}`}
              >
                <ScoreSummary
                  title={summary.templateName}
                  score={summary.averagePercentageScore}
                  highest={summary.highestScore}
                  lowest={summary.lowestScore}
                  submissions={summary?.submissions}
                />
              </div>
            ))}
          </div>
        </div>

          {/*PUBLIC*/}
          {
            (withPublicSubjectiveScore || withPublicEvaluation) && (
              <div className="startup-card-container__section">
                <div className="startup-card-container__section__mini-title">PUBLIC</div>

                <div className="row">

                  {/* subjective score summary */}
                  {
                    withPublicSubjectiveScore && (
                      <div
                        className="col-xs-12 col-sm-6 col-md-4 group-startup-card__score-summaries__subjective-score"
                      >
                        <ScoreSummary
                          title="Subjective Score"
                          score={startup?.publicAvgSubjectiveScore || 0}
                          highest={Math.max(...startup?.publicSubjectiveScores?.map(b => b.score || 0) || [0])}
                          lowest={Math.min(...startup?.publicSubjectiveScores?.map(b => b.score || 0) || [0])}
                          submissions={startup?.publicSubjectiveScores?.length || 0}
                        />
                      </div>
                    )}

                  {
                    withPublicEvaluation && (
                      <>
                        {/* evaluation summaries */}
                        {startup?.publicEvaluationSummaries?.map((summary, i) => (
                          <div
                            className="col-xs-12 col-sm-6 col-md-4 group-startup-card__score-summaries__subjective-score"
                            key={`evaluation-summary-${i}`}
                          >
                            <ScoreSummary
                              title={summary.templateName}
                              score={summary.averagePercentageScore}
                              highest={summary.highestScore}
                              lowest={summary.lowestScore}
                              submissions={summary?.submissions}
                            />
                          </div>
                        ))}
                      </>
                    )
                  }

                </div>
              </div>
            )
          }

        </>
      }
    </>
  );
}



