import React from "react";

// UI component
import { NotataList } from "Components/NotataComponents";


// COMMON FUNCTION
import { getEvaluationsByTemplate } from "../../../../commonFunctions";

// Others
import moment from "moment";

// Main function
export default function MyTeamEvaluations({ connection, setEvaluateModalContent, setSummaryModalContent }) {


  //Get data
  let evaluationsByTemplate = getEvaluationsByTemplate(connection);

  return (
    <>
      <div className={`col-sm-12 details`}>
        {evaluationsByTemplate?.map(({ summary, evaluations }, i) => {

        // Table header
        let tableHeader = [
          {
            className: "eval-score-heading",
            content: <div>{summary?.templateName}</div>,
          },
          {
            className: "heading-submissions hide-on-mobile",
            content: <div>{summary?.submissions} Submissions</div>,
          },
          {
            className: "heading-score",
            content: <div>{summary?.averagePercentageScore}%</div>,
          },
          {
            className: "heading-right-icons",
            content: <span/>,
          },
        ]

        // Table body
        let tableBody = evaluations?.map(evaluation => [

          // Name
          {
            content: (
              <div className="eval-score-name">
                {evaluation?.createdByUser?.given_name}{" "}
                {evaluation?.createdByUser?.family_name}{" "}
                {evaluation?.createdByUser?.isMe && (
                  <i
                    className="fa fa-pen green-eye"
                    aria-hidden="true"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      setEvaluateModalContent(evaluation)
                    }}
                  />
                )}
              </div>
            )
          },

          // Submissions
          {
            content: (
              <div className="submissions hide-on-mobile">
                {moment(evaluation.updatedAt).format("ll")}
              </div>
            )
          },

          // Score percent
          {
            content: (
              <div>{evaluation?.summary?.scorePercent || 0}%</div>
            )
          },

          // Icons
          {
            content: (
              <div>
                <i
                  className="fa fa-eye green-eye"
                  aria-hidden="true"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    setSummaryModalContent(evaluation)
                  }}
                />
              </div>
            )
          }
        ])

        return (
          <div
            key={`item-${i}`}
            style={{marginTop: "20px"}}
            >
            <NotataList
              header={tableHeader}
              body={tableBody}
            />
          </div>
        )

      })}

      </div>
    </>
  );
}