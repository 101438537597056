import React from "react";

// Library Components
import moment from "moment";

// CUSTOM COMPONENTS
import AccountInvitation from "../Notifications/Actions/AccountInvitation";
import PublicCreativeCreate from "../Notifications/Actions/PublicCreativeCreate";
import PublicCreativeUpdate from "../Notifications/Actions/PublicCreativeUpdate";
import ConnectionCreate from "../Notifications/Actions/ConnectionCreate";
import GroupInvitation from "../Notifications/Actions/GroupInvitation";

// STYLES
import "./Notitifications.scss";

// CONSTANTS
import {
  ACCOUNT_INVITATION,
  CONNECTION_CREATE,
  GROUP_INVITATION,
  PUBLIC_CREATIVE_CREATE,
  PUBLIC_CREATIVE_UPDATE,
  ACCOUNT_INVITATION_RESPONSE,
  GROUP_INVITATION_RESPONSE
} from "private/Pages/constants"

//Helper Function
function NotificationIcon({ notificationType }) {
  switch (notificationType) {
    case ACCOUNT_INVITATION:
      return <div className='icon'><i className="fas fa-user-plus" /></div>;

    case PUBLIC_CREATIVE_CREATE:
      return <div className='icon'> <i className="fas fa-layer-plus" /></div>;

    case PUBLIC_CREATIVE_UPDATE:
      return <div className='icon'> <i className="fas fa-file-edit" /></div>;

    case GROUP_INVITATION:
      return <div className='icon'> <i className="fas fa-users" /></div>;

    case ACCOUNT_INVITATION_RESPONSE:
      return <div className='icon'> <i className="fas fa-users-medical" /></div>;

    case GROUP_INVITATION_RESPONSE:
      return <div className='icon'><i className="fas fa-users-medical" /></div>;

    default:
      return <div className='icon'><i className="fas fa-newspaper" /></div>;
  }
}

//Main Function
export default function Notification({ notification, history }) {


  let { notificationType, resolved } = notification;

  return (
    <div className="notifications-container-new__notification">
      <NotificationIcon notificationType={notificationType} />

      <div className="notifications-container-new__notification__text">
        <div className="notifications-container-new__notification__text__message">
          {notification.content}
        </div>

        {!resolved && notificationType === ACCOUNT_INVITATION && (
          <AccountInvitation notification={notification} />
        )}

        {!resolved && notificationType === PUBLIC_CREATIVE_CREATE && (
          <PublicCreativeCreate
            notification={notification}
            history={history}
          />
        )}

        {!resolved && notificationType === PUBLIC_CREATIVE_UPDATE && (
          <PublicCreativeUpdate
            notification={notification}
            history={history}
          />
        )}

        {!resolved && notificationType === CONNECTION_CREATE && (
          <ConnectionCreate
            notification={notification}
            history={history}
          />
        )}

        {!resolved && notificationType === GROUP_INVITATION && (
          <GroupInvitation
            notification={notification}
            history={history}
          />
        )}

        <div className="notifications-container-new__notification__text__hour-ago">
          {moment(notification.createdAt).format("lll")}
        </div>
      </div>
    </div>
  );
}
