import React, {useEffect} from "react";
import "./Printable.scss";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GhostLoader } from "../../../../Components/NotataComponents";
import { creativeTemplateGet } from "../../../Apollo/Queries";
import connectionGet from "./GQL_printable_query";

import { filter, groupBy, sumBy } from "lodash";
import moment from "moment";


const getAnswer = ({ question, answers }) => {
  let allAnswers = filter(answers,['questionId', question.id]);
  return allAnswers.map((ans) => ans.val)

}

function StartupInfoSection({connection, creative, creativeTemplate}) {
  const answers = groupBy(connection?.creative?.answers , 'sectionName');
  return (
    <div className="printable-startup__template-form">

      <div className="printable-startup__template-form__name">
        {creative.name}
      </div>

      {
        connection?.tags?.length !== 0 && (
          <div className="printable-startup__template-form__tags">

            {
              connection?.tags?.map((tag, i) => (
                <div
                  className="printable-startup__template-form__tags__tag"
                  key={`${tag.id}-${i}`}
                >
                  {tag?.group?.name}: {tag?.name}
                </div>
              ))
            }
          </div>
        )

      }

      {creativeTemplate?.sections?.map(section => {
        return (
          <div
            className="printable-startup__template-form__section"
            key={`ans-${section.id}`}
          >

            <div className="printable-startup__template-form__section__name">
              {section.name}
            </div>

            {section.questions.map((question)=> {

              let question_answers = getAnswer({
                question,
                answers: answers[section.name]})

              return (
                <div
                  className="printable-startup__template-form__section__question"
                  key={`${question.id}-${section.id}`}
                >
                  <div className="printable-startup__template-form__section__question__name">
                    {question.name}
                  </div>

                  {question.description && (
                    <div className="printable-startup__template-form__section__question__description">
                      {question.description}
                    </div>
                  )}

                  {
                    question_answers.length ?
                    question_answers.map((answer, i) => (
                      <div
                        key={`${section.id}-${question.id}-ans-${i}`}
                        className="printable-startup__template-form__section__question__answer"
                      >
                        {answer}
                      </div>
                    ))
                    :
                    <div className="printable-startup__template-form__section__question__no-answer">
                      not answered
                    </div>
                  }
                </div>
              )}

            )}

          </div>
        )
      })}

    </div>
  )

}

function EvaluationSections({evaluation}) {

  const {createdByUser, createdByEmail, createdAt, summary, template, answers } = evaluation || {};
  const { given_name, family_name } = createdByUser || {};
  const { sections } = summary || {};
  const { name } = template || {};


  function getQuestion(id) {
    let q;
    for (let section of template?.sections || []) {
      for (let question of section?.questions || []) {
        if (question.id === id) {
          q = question;
        }
      }
    }
    return q;
  }

  const getComment = (id) =>{
    const comment = answers?.find(
      ({ questionId, inputType }) =>
        questionId === id &&
        inputType === "COMMENT"
    );
    return comment ? comment.val : ""
  }

  return (
    <div className={`printable-startup__template-form block-style`}>

      <div className="printable-startup__evaluation-section">

        <div className="printable-startup__evaluation-section__title">
          Evaluation: {name}
        </div>

        {
          createdByEmail ? (
            <div className="printable-startup__evaluation-section__by-name">
              <span>By: {createdByEmail}</span>
            </div>
          ) : (
            <div className="printable-startup__evaluation-section__by-name">
              <span>By: {given_name} {family_name}</span>
            </div>
          )
        }

        <div className="printable-startup__evaluation-section__by-date">
          {`Last updated: ${moment(createdAt).format('ll')}`}
        </div>

        <div className="printable-startup__evaluation-section__summary__header">
          Summary
        </div>

        {sections?.map(section => (
          <div
            key={section.sectionId}
            className="printable-startup__evaluation-section__summary__section">

            <div className="printable-startup__evaluation-section__summary__section__name">
              {section.sectionName}
            </div>

            <div className="printable-startup__evaluation-section__summary__section__points">
              {`${sumBy(section.scorePerAnswer, 'score')} of ${sumBy(section.scorePerAnswer, 'possibleScore')} points`}
            </div>

          </div>

        ))}

        </div>


      {sections?.map(section => {
        return (
          <div
            key={section.sectionId}
            className="printable-startup__template-form__section"
            >

            <div className="printable-startup__template-form__section__name">
              {section.sectionName}
            </div>
              {section?.scorePerAnswer?.map((score, index) => {
                let templateQuestion = getQuestion(score.questionId)

                return (
                  <div
                    key={`${section.sectionId}_${index}`}
                    className="printable-startup__template-form__section__question"
                  >
                    <div className="printable-startup__template-form__section__question__name">
                      <div>{score.questionName}</div>
                    </div>

                    { templateQuestion?.description && (
                      <div className="printable-startup__template-form__section__question__description">
                        {templateQuestion?.description}
                      </div>
                    )}

                    {
                      score.answer ? (
                        <div className="printable-startup__template-form__section__question__answer">
                          {score.answer}
                        </div>
                      ) : (
                        <div className="printable-startup__template-form__section__question__no-answer">
                          not answered
                        </div>
                      )
                    }

                    {
                      getComment(score.questionId) && (
                        <div
                          className="printable-startup__template-form__section__question__comment"
                        >
                          Comment: "{getComment(score.questionId)}"
                        </div>
                      )
                    }

                  </div>

                );
              })}
            </div>

        );
      })}



    </div>
  )


}


export default function Printable({match}) {

  let id = match?.params?.id;

  let creativeTemplateQuery = useQuery(creativeTemplateGet)
  let [getConnection, { data, error, loading }] = useLazyQuery(connectionGet)


  useEffect(() => {
    if (id) {
      getConnection({variables: { id }})
    }
  }, [id])

  if (loading) {
    return (
      <GhostLoader/>
    )
  }


  if (error) {
    return (
      <div>
        Hmm... that doesn't seem right...
      </div>
    )
  }

  const connection = data?.connectionGet;
  const creativeTemplate = creativeTemplateQuery?.data?.creativeTemplateGet;
  const creative = connection?.creative || {};


  return (
    <div className="printable-startup">

      <StartupInfoSection
        connection={connection}
        creativeTemplate={creativeTemplate}
        creative={creative}
      />

      <hr/>

      {connection?.evaluations?.length !== 0 && (
        <>
          {
            connection?.evaluations?.map(evaluation => (
              <EvaluationSections
                key={evaluation.id}
                evaluation={evaluation}
              />
            ))
          }
        </>
      )}


      {
        connection?.externalEvaluations?.length !== 0 && (
          <>
            {
              connection?.externalEvaluations?.map(evaluation => (
                <EvaluationSections
                  key={evaluation.id}
                  evaluation={evaluation}
                />
              ))
            }
          </>
        )
      }

      {
        connection?.groupSharingInfo?.length !== 0 && (
          <>
            {
              connection?.groupSharingInfo.map((groupSharing) => (
                <div
                  key={groupSharing.group.id}
                >

                  {
                    groupSharing?.evaluations?.length !== 0 && (
                      <>
                        {
                          groupSharing?.evaluations?.map(evaluation => (
                            <EvaluationSections
                              key={evaluation.id}
                              evaluation={evaluation}
                            />
                          ))
                        }
                      </>
                    )
                  }

                </div>
              ))
            }
          </>
        )
      }


    </div>
  )

}

