import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { tagGroupsGet } from "../../../../Apollo/Queries";
import { tagGroupUpdate } from "../../../../Apollo/Mutations";

// MODALS
import { Modal } from "Components/NotataComponents/Modal/Modal";
import { TextBox } from "Components/NotataComponents";

// MAIN SUNCTION
export default function EditTagGroupModal({ tagGroup, setEditTagGroupModal }) {
  // STATES
  const [tagName, setTagName] = useState(tagGroup.name);
  // MUTATION
  const [updateTagGroup, updateTagGroupRes] = useMutation(tagGroupUpdate, {
    refetchQueries: [{ query: tagGroupsGet }],
  });

  return (
    <Modal
      title="Edit Tag Group"
      loading={updateTagGroupRes.loading}
      submit={async () => {
        if (updateTagGroupRes.loading) {
          return;
        }

        if (!tagName.length) {
          return;
        }

        let variables = {
          id: tagGroup.id,
          input: {
            name: tagName,
          },
        };
        try {
          await updateTagGroup({ variables });
        } catch (error) {
          console.log("error", error);
        }
        setEditTagGroupModal(undefined);
      }}
      close={() => {
        setEditTagGroupModal(undefined);
      }}
      submitTxt="Save"
      closeTxt="Cancel"
      submitButtonStyle="secondary"
      children={
        <TextBox
          textBoxStyle={"settingsTags-container1__marginToptext"}
          value={tagName}
          type="text"
          onChange={e => setTagName(e.target.value)}
          autoComplete="off"
        />
      }
    />
  );
}
