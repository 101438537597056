import React, { useState } from "react";

// API STUFF
import {
  connectionGet
} from "private/Apollo/Queries";

import {
  evaluationUpdate,
  evaluationCreate,
} from "private/Apollo/Mutations";


// UI COMPONENTS
import { NotataButton, Modal, CardComponent } from "Components/NotataComponents";

// COMPONENTS
import GroupEvaluations from "./EvaluationsComponent/GroupEvaluations";
import ExternalEvaluations from "./EvaluationsComponent/ExternalEvaluations";
import MyTeamEvaluations from "./EvaluationsComponent/MyTeamEvaluations";
import MyEvaluations from "./EvaluationsComponent/MyEvaluations";

// OTHER CUSTOM COMPONENTS
import StartupCard from "../../CommonComponents/StartupCard";
import EvaluationsModal from "./EvaluationsModal/EvaluationsModal";
import CommentBox from "../../CommonComponents/CommentBox";

// STYLES
import "./Evaluations.scss";

// DEFINITIONS
import { evaluation_templates_page } from "definitions";

// HELPERS
import {
  getEvaluationsByTemplate,
  getExternalEvaluationsByTemplate,
} from "../../../commonFunctions";
import EvaluationSummaryModal from "../../../EvaluatePage/EvaluationSummaryModal/EvaluationSummaryModal";
import EvaluateModal from "../../../EvaluatePage/EvaluateModal/EvaluateModal";
import { useMutation } from "@apollo/client";


//MAIN FUNCTION
export default function Evaluations({ connection, account, history }) {

  // STATES
  const [ evaluateModalContent, setEvaluateModalContent ] = useState(undefined)
  const [ summaryModalContent, setSummaryModalContent ] = useState(undefined)
  const [ evaluateModal, setEvaluateModal ] = useState(false);

  // MUTATIONS
  const [mutateEvaluationUpdate, updateRes] = useMutation(evaluationUpdate);
  const [mutateEvaluationCreate, createRes] = useMutation(evaluationCreate, {
    refetchQueries: [{
      query: connectionGet,
      variables: { id: connection.id }
    }]
  });

  // MAPS AND REDUCERS
  let singeAccount = account?.members?.length === 1;
  let sharingInfo = connection?.groupSharingInfo?.filter(
    ({ evaluationSummaries }) => evaluationSummaries.length
  );

  // GET GROUP EVALUATIONS
  let withGroupEvaluations = !!sharingInfo?.filter(
    info =>
      !!info?.evaluationSummaries?.filter(
        summary =>
          !!info?.evaluations?.filter(
            evaluation => evaluation?.template?.id === summary?.templateId
          )
      )
  ).length;

  // GET MY EVALUATIONS
  let myEvaluations = connection?.evaluations?.filter(
    ({ createdByUser, template }) => createdByUser?.isMe && template?.name
  );


  async function saveEvaluation(newAnswers) {

    // Maintain state
    setEvaluateModalContent({
      ...evaluateModalContent,
      answers: newAnswers
    })

    // Save evaluation
    let savedEvaluation;

    // Update existing
    if (evaluateModalContent.id) {
      try {
        let variables = {
          id: evaluateModalContent.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationUpdate({variables})
        savedEvaluation = res?.data?.evaluationUpdate;
      } catch (error) {
        console.log('error', error)
      }
    }

    // Create new
    if (!evaluateModalContent.id) {
      try {
        let variables = {
          connectionId: connection?.id,
          templateId: evaluateModalContent?.template?.id,
          answers: newAnswers
        }
        let res = await mutateEvaluationCreate({variables})
        savedEvaluation = res?.data?.evaluationCreate;
      } catch (error) {
        console.log('error', error)
      }
    }

    // Hide edit modal
    setEvaluateModalContent(undefined)

    // Open summary modal
    setSummaryModalContent(savedEvaluation)

  }


  return (

    <>

      <div className="row tab-panel-container">
        <div className="col-sm-12 col-md-8">
          <StartupCard connection={connection} />

          {/* My Evaluations */}
          <CardComponent
            containerClassName="evalations-container"
            title="My Evaluations"
            titleSize="medium"
            padding="medium"
          >
            <>
              {myEvaluations?.length === 0 && (
                <div className="card-empty-text">
                  Here you can evaluate startups and get a score.
                  <br />
                  You can create and customize as many evaluation templates as you
                  want.
                </div>
              )}

              <MyEvaluations
                connection={connection}
                setSummaryModalContent={setSummaryModalContent}
                setEvaluateModalContent={setEvaluateModalContent}
              />


              <div>
                <NotataButton
                  size="big"
                  bgcolor="primary_color"
                  text="Evaluate startup"
                  datatut="evaluation-startup"
                  buttonStyle="evaluate-startup-btn"
                  onClick={() => setEvaluateModal(true)}
                />
              </div>
            </>
          </CardComponent>

          {/* My Team Evaluations */}
          {!singeAccount && getEvaluationsByTemplate(connection)?.length > 0 && (
            <CardComponent
              containerClassName="evalations-container"
              title={"My Team Evaluations"}
              titleSize={"medium"}
              padding={"medium"}
            >
              <MyTeamEvaluations
                connection={connection}
                setSummaryModalContent={setSummaryModalContent}
                setEvaluateModalContent={setEvaluateModalContent}
              />
            </CardComponent>
          )}

          {/* Group Evaluations */}
          {withGroupEvaluations && (
            <CardComponent
              containerClassName={"evalations-container"}
              title={"Group Evaluations"}
              titleSize={"medium"}
              padding={"medium"}
            >
              <GroupEvaluations
                connection={connection}
                setSummaryModalContent={setSummaryModalContent}
                setEvaluateModalContent={setEvaluateModalContent}
              />
            </CardComponent>
          )}

          {/* External Evaluations  */}
          {getExternalEvaluationsByTemplate(connection)?.length > 0 && (
            <CardComponent
              containerClassName="evalations-container"
              title={"External Evaluations"}
              titleSize={"medium"}
              padding={"medium"}
            >
              <ExternalEvaluations
                connection={connection}
                setSummaryModalContent={setSummaryModalContent}
              />
            </CardComponent>
          )}

          {/* Evaluations Templates */}
          <div className="col-sm-12 text-right-evaluate">
            <NotataButton
              size="large"
              bgcolor="empty-green"
              text="Evaluations templates"
              datatut="evaluation-templates"
              onClick={() => history.push(evaluation_templates_page)}
            />
          </div>
        </div>

        {/* Comment Box */}
        <CommentBox
          commentBox={"comment_box"}
          connectionId={connection?.id}
        />

      </div>

      {/* Evaluations Modals */}
      {evaluateModal && (
        <Modal
          title="Evaluate startup"
          close={() => {
            setEvaluateModal(false);
          }}
        >
          <EvaluationsModal
            history={history}
            account={account}
            connection={connection}
            setSelectedTemplate={template => {
              setEvaluateModalContent({template})
              setEvaluateModal(false);
            }}
          />
        </Modal>
      )}

      {
        summaryModalContent && (
          <EvaluationSummaryModal
            evaluation={summaryModalContent}
            connectionId={connection.id}
            close={() => setSummaryModalContent(undefined)}
            edit={() => {
              setEvaluateModalContent(summaryModalContent)
              setSummaryModalContent(undefined)
            }}
          />
        )
      }

      {
        evaluateModalContent && (
          <EvaluateModal
            creative={connection?.creative}
            template={evaluateModalContent?.template}
            evaluation={evaluateModalContent}
            connectionId={connection.id}
            close={() => setEvaluateModalContent(undefined)}
            save={saveEvaluation}
            loading={updateRes?.loading || createRes?.loading}
          />
        )
      }

    </>
  );
}


