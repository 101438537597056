import gql from "graphql-tag";

export default gql`
    fragment FunnelTagFields on FunnelTag {
        id
        funnelGroupId
        name
        index
        group {
            id
            name
            funnelTags {
                id
                name
                index
                color
            }
        }
    }
`




