import React, { useState } from "react";

// API STUFF
import { useMutation } from "@apollo/client";
import { tagGroupsGet } from "../../../../Apollo/Queries";
import { tagUpdate } from "../../../../Apollo/Mutations";

// UI COMPONENTS
import { Modal } from "Components/NotataComponents/Modal/Modal";
import { TextBox } from "Components/NotataComponents";

// MAIN FUNCTION
export default function EditTagModal({ tag, setEditTagModal }) {
  // STATES
  const [tagName, setTagName] = useState(tag.name);
  // MUTATION
  const [updateTag, updateTagRes] = useMutation(tagUpdate, {
    refetchQueries: [{ query: tagGroupsGet }],
  });

  return (
    <Modal
      title="Edit tag"
      loading={updateTagRes.loading}
      submit={async () => {
        if (updateTagRes.loading) {
          return;
        }

        let variables = {
          id: tag.id,
          input: {
            name: tagName,
          },
        };
        try {
          await updateTag({ variables });
        } catch (error) {
          console.log("error", error);
        }
        setEditTagModal(undefined);
      }}
      close={() => {
        setEditTagModal(undefined);
      }}
      submitTxt="Save"
      closeTxt="Cancel"
      submitButtonStyle="secondary"
      children={
        <TextBox
          textBoxStyle="settingsTags-container1__create-new-tag-group-text"
          type="text"
          value={tagName}
          onChange={e => setTagName(e.target.value)}
          autoComplete="off"
        />
      }
    />
  );
}
