import React, { useEffect, useState } from "react";
import './App.css';
import { useMutation, useQuery } from "@apollo/client";
import { accountGet, userGet } from "private/Apollo/Queries";
import { appSumoAssignCode } from "private/Apollo/Mutations";
import { GhostLoader, NotataButton } from "Components/NotataComponents";
import Product from "./Product";
import moment from "moment";
import CardMessageContainer from "../../Group/Components/CardMessageContainer";

import { STAGE } from "../../../../awsconfig";
const urls = {
  "dev2": "https://g825mzjbni.execute-api.eu-west-1.amazonaws.com/dev2",
  "v3prod": "https://nm9eylystj.execute-api.eu-west-1.amazonaws.com/v3prod"
}
const BASE_URL = urls[STAGE] || urls["dev2"];

export default function Payment() {

  const [isAppSumo, setIsAppSumo] = useState(false)
  const [discount, setDiscount ] = useState(undefined)
  const [loadingItems, setLoadingItems] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [pricing, setPricing] = useState([]);

  const { data, loading, refetch } = useQuery(accountGet, { pollInterval: 5000 });
  const { data: userData, loading: userDataLoading } = useQuery(userGet);

  const [assignCode] = useMutation(appSumoAssignCode);

  const quantity = data?.accountGet?.members?.length || 1;
  const accountId = data?.accountGet?.id;
  const session_id = data?.accountGet?.stripeInfo?.session_id;
  const stripeInfo = data?.accountGet?.stripeInfo;
  const cognitoIdentityId = userData?.userGet?.cognitoIdentityId;

  useEffect(() => {
    getPricing();
  }, [])

  useEffect(() => {
    let code = window.localStorage.getItem("app_sumo_code")
    if (code) {
      assignCode({variables: { code }})
    }
  }, [window.localStorage])

  useEffect( () => {
    const query = new URLSearchParams(window.location.search);
    const coupon = query.get('coupon');
    if (coupon) {
      (async () => {
        const resp = await fetch(`${BASE_URL}/get-coupon?coupon=${coupon}`);
        const data = await resp.json();
        setDiscount(data?.percent_off)
      })()
    }
  }, [window.location.search])


  useEffect(() => {
    let code = window.localStorage.getItem("app_sumo_code")
    if (code) {
      (async () => {
        const resp = await fetch(`${BASE_URL}/get-coupon?coupon=AppSumoDiscount`);
        const data = await resp.json();
        setIsAppSumo(true)
        setDiscount(data?.percent_off)
      })()
    }
  }, [window.localStorage])

  useEffect(() => {
    if (stripeInfo) {
      window.localStorage.removeItem("app_sumo_code")
      setIsAppSumo(false)
    }
  }, [stripeInfo])


  const getPricing = async () => {
    const resp = await fetch(`${BASE_URL}/get-pricing`);
    let pricing = await resp.json();
    pricing = pricing?.data.filter(i => i.product.active);
    setPricing(pricing);
  }

  const getCheckoutUrl = async (priceId) =>{

    if (!accountId) {
      setError("Account ID must be provided!")
    }

    if (loadingItems[priceId]) { return }
    setLoadingItems({
      ...loadingItems,
      [priceId]: true
    })

    const body = {
      priceId,
      accountId,
      cognitoIdentityId,
      quantity
    }

    const query = new URLSearchParams(window.location.search);
    const coupon = query.get('coupon');
    if (coupon) { body.coupon = coupon }

    if (!coupon) {
      let code = window.localStorage.getItem("app_sumo_code")
      if (code) { body.coupon = "AppSumoDiscount"}
    }

    const resp = await fetch(`${BASE_URL}/checkout-session`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const session = await resp.json();
    setLoadingItems({...loadingItems, [priceId]: false})
    window.open(session?.url, '_self')
  }

  const cancelSubscription = async () => {
    setSuccess(false)
    if (loadingItems["free"]) { return }
    setLoadingItems({
      ...loadingItems,
      ["free"]: true
    })

    await fetch(`${BASE_URL}/cancel-subscription`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        subscriptionId: stripeInfo?.subscription_id,
        cognitoIdentityId,
        accountId,
      })
    });
    setTimeout(() => {
      setLoadingItems({...loadingItems, ["free"]: false})
      refetch()
      setSuccess(true)
    }, 1000)

  }

  const updateSubscription = async (priceId) => {
    setSuccess(false)
    if (loadingItems[priceId]) { return }
    setLoadingItems({
      ...loadingItems,
      [priceId]: true
    })
    await fetch(`${BASE_URL}/update-subscription`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        priceId,
        subscriptionId: stripeInfo?.subscription_id,
        cognitoIdentityId,
        accountId,
        quantity
      })
    });
    setTimeout(() => {
      setLoadingItems({...loadingItems, [priceId]: false})
      refetch()
      setSuccess(true)
    }, 1000)
  }

  const manageBilling = async (s_id) => {
    setLoadingItems({...loadingItems, [s_id]: true})
    const resp = await fetch(`${BASE_URL}/create-portal-session`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ session_id: s_id, accountId, cognitoIdentityId })
    });
    const portalSession = await resp.json();
    setLoadingItems({...loadingItems, [s_id]: false})
    window.open(portalSession?.url, '_self')
  }

  const getCurrentPlanName = () => {
    let priceObj = pricing?.find(({ id }) => id === stripeInfo?.price_id)
    let planName = priceObj?.product?.name || "";
    return planName;
  }

  const getDiscountedPrice = value => {
    if (!discount) return value;
    return ((value/100) * (100-discount)).toFixed(1)
  }



  if ((loading || userDataLoading) && !data) {
    return <GhostLoader />;
  }


  let noActiveSubscription = !stripeInfo
    || stripeInfo?.cancel_at_period_end === "true"


  return (
    <div
      className="payment-container"
      >

      {
        !isAppSumo && (
          <CardMessageContainer
            containerStyle={{padding: "0px", marginBottom: "30px"}}
            title="LIMITED OFFER 🚀"
            notice
            message={`Subscribe to "Early adopter" plan now, and secure a whopping 69% discount for the next 2 years!`}
          />
        )
      }

      {
        isAppSumo && (
          <CardMessageContainer
            containerStyle={{padding: "0px", marginBottom: "30px"}}
            title="APP SUMO EARLY ADOPTER 🚀"
            notice
            message={`You have registered a valid App Sumo discount code, giving you an additional 70% discount on our highly discounted price.`}
          />
        )
      }


      <div className={"row"}>

        <div className="col-md-6 col-12">
          <Product
            plan={"Free rider"}
            isSelectedPlan={
              noActiveSubscription
            }
            priceAmount={0}
            price_id={"free"}
            interval={"month"}
            loading={loadingItems["free"]}
            meta={[
              {
                label: "Your account has:",
                text: `${quantity} user${quantity !== 1 ? "s" : ""}`
              },
              {
                label: "Regular price:",
                text: <span style={{textDecoration: "line-through"}}>${(900 * quantity) / 100} / month</span>
              },
              {
                label: "You pay:",
                text: `$0 / month`
              }
            ]}
            callBack={cancelSubscription}
          />
        </div>


        {
          pricing?.sort((a, b) => a.unit_amount - b.unit_amount).map(priceObj =>
            <div
              key={priceObj.id}
              className="col-md-6 col-12"
              >
              <Product
                plan={priceObj.product.name || ""}
                isSelectedPlan={stripeInfo?.price_id === priceObj.id}
                isCanceled={stripeInfo?.cancel_at_period_end === "true"}
                priceAmount={getDiscountedPrice(priceObj.unit_amount / 100)}
                price_id={priceObj.id}
                interval={priceObj.recurring.interval}
                loading={loadingItems[priceObj.id]}
                meta={[
                  {
                    label: "Your account has:",
                    text: `${quantity} user${quantity !== 1 ? "s" : ""}`
                  },
                  {
                    label: "Regular price:",
                    text: <span style={{textDecoration: "line-through"}}>${(2900 * quantity) / 100} / {priceObj.recurring.interval}</span>
                  },
                  {
                    label: "You pay:",
                    text: `$${getDiscountedPrice((priceObj.unit_amount / 100) * quantity)} / ${priceObj.recurring.interval}`
                  }
                ]}
                callBack={(priceId) => {
                  stripeInfo?.subscription_id
                    ? updateSubscription(priceId)
                    : getCheckoutUrl(priceId)
                }}
              />
            </div>
          )
        }
      </div>


      {
        error && error !== "" && (
          <CardMessageContainer
            containerStyle={{
              padding: "0px",
              marginBottom: "30px",
              marginTop: "30px",
              background: "white",
            }}
            title="BLIP BLOP 🤖"
            error
            message={error}
          />
        )
      }

      {
        success && (
          <div
            style={{
              background: "white",
              border: "1px solid rgb(185, 231, 249)",
              borderRadius: "5px",
              padding: "20px",
              color: "var(--ui-color-gray-dark1)",
              position: "relative",
              marginTop: "30px"
            }}
          >
            <div
              style={{
                fontSize: "18px",
                lineHeight: "24px",
                position: "relative",
                paddingBottom: "8px",
                color: "#53cab2"
              }}
              >
              Your plan has been successfully updated!
            </div>
            <div
              style={{
                fontSize: "14px",
                lineHeight: "22px",
                color: "#515d6ea8"
              }}
            >
              Can't see it above? Hold tight! It might take a minute for this to update.
            </div>
          </div>
        )
      }


      {
        session_id && (

          <div className="payment-container__subscriber">

            <div className="payment-container__subscriber__info-row">
              You are currently subscribing to{" "}
              <span>{getCurrentPlanName()}</span>
              {" "}plan ❤️
            </div>

            {
              stripeInfo.cancel_at_period_end === "true" && (
                <div className="payment-container__subscriber__info-row">
                  Your plan is <span style={{color: "#C80000"}}>canceled</span>, and will be downgraded on{" "}
                  <span>
                    {moment(parseInt(stripeInfo.current_period_end), 'X').format('lll')}
                  </span>
                </div>
              )
            }


            {
              stripeInfo.cancel_at_period_end !== "true" && (
                <div className="payment-container__subscriber__info-row">
                  Your plan will renew in{" "}
                  <span>
                    {moment(parseInt(stripeInfo.current_period_end), 'X').fromNow('days')}
                  </span>
                </div>
              )
            }

            <div className="payment-container__subscriber__button-container">
              <NotataButton
                size="x_large"
                bgcolor={"primary_color"}
                text={"Go to billing dashboard (stripe)"}
                loading={loadingItems[session_id]}
                onClick={() => manageBilling(session_id)}
              />
            </div>

          </div>
        )
      }


    </div>
  );
}