import React  from "react";
import Evaluate from "../Components/Evaluate";
import { saveEvaluationToLocalDB } from "utils/groupSharing";
import { useMutation } from "@apollo/client";
import StartupCard from "private/Pages/StartupPage/CommonComponents/StartupCard";
import { publicGroupEvaluationCreate } from "public/Apollo/Mutations";
import { useHistory } from "react-router-dom";
import { Card } from "Components/NotataComponents";

export default function EvaluateComponent({
  group,
  selectedStartUp,
  storedData,
  setEvaluateModal,
  setViewStartup,
  setThankYouModal

  }) {

  const history = useHistory()

  const groupId = group.id;

  const queryOptions = { context: { clientName: "public" } };
  const [createEvaluation, createEvaluationRes] = useMutation(publicGroupEvaluationCreate, queryOptions);

  const evaluateSave = async (answers) => {
    saveEvaluationToLocalDB(groupId, answers, selectedStartUp.id)
    let resp = await createEvaluation({
      variables: {
        groupId: groupId,
        creativeId: selectedStartUp.id,
        email: storedData?.member?.email,
        input: {
          name: 'evaluation',
          description: 'evaluation-dec',
          answers
        },
        family_name: storedData?.member?.family_name,
        given_name: storedData?.member?.given_name,
        pin: storedData?.pin
      }
    })
    if (resp?.data?.publicGroupEvaluationCreate?.message) {
      setEvaluateModal(undefined);
      setThankYouModal(true);
    }
  }

  const evaluation = storedData?.evaluations?.find(evaluation =>
    evaluation.creativeId === selectedStartUp?.id
  ) || [];

  return (
    <div
      className="startup-container"
      style={{ width: "100%" }}
    >

      <div
        style={{
          marginBottom: "20px"
        }}
      >
        <div
          className="back-button"
          onClick={() => {
            setEvaluateModal(undefined);
            setViewStartup(undefined)
          }}
          style={{
            marginBottom: "10px"
          }}
        >
            <span className="material-icons back-icon">
              arrow_back_ios
            </span>
          <span className="page-heading">
              GO BACK TO GROUP
            </span>
        </div>
        <div
          className="back-button"
          onClick={() => {
            setEvaluateModal(undefined);
            setViewStartup(selectedStartUp.id)
          }}
        >
            <span className="material-icons back-icon">
              arrow_back_ios
            </span>
          <span className="page-heading">
              GO BACK TO STARTUP
            </span>
        </div>
      </div>

      <StartupCard
        connection={{ creative: selectedStartUp }}
      />

      <div style={{width: "100%"}}>
        <Card
          style={{paddingTop: "0px"}}
        >
          <Evaluate
            template={group?.evaluationTemplate}
            loading={createEvaluationRes.loading}
            setAnswers={evaluateSave}
            history={history}
            answers={evaluation?.answers || []}
            onCancel={() => setEvaluateModal(undefined)}
          />
        </Card>
      </div>

    </div>
  )
}