import React from "react";

// UI COMPONENTS
import { TextBox } from "Components/NotataComponents";

// STYLES
import "./FreeText.scss";

// MAIN FUNCTION
export default function TextLines({ questions, question, setQuestions }) {
  return (
    <div className="text-lines-conatiner">
      <div className="input">
        <TextBox placeholder="Text line name" />
      </div>
      <div className="">
      </div>
    </div>
  );
}
