import React, { useState, useEffect } from "react";


import moment from "moment";

// API
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  connectionsGet
} from "private/Apollo/Queries";

import {
  connectionSetStar,
  connectionFunnelTagAdd,
} from "private/Apollo/Mutations";


// COMPONENTS
import AddFunnelModal from "../Modals/AddFunnelModal";
import SubjectiveScoreModal from "../Modals/SubjectiveScoreModal";
import AddToGroupModalNew from "../Modals/AddToGroupModalNew";

import Table from "./Table/Table";
import AddTagsForConnectionModal from "../../StartupPage/Tabs/Overview/OverviewModals/AddTagsForConnectionModal";
import FilterBar from "../FilterBar/FilterBar";

//CSS
import "./Spreadsheet.css";
import { accountGet } from "../../../Apollo/Queries";
import { useHistory } from "react-router-dom";
import { defaultFilters } from "../constants";
import { getHasFilters } from "../getHasFilters";
import DownloadAsCsv from "./DownloadAsCsv";



function getCleanFilterData(filters) {
  let clean = {};
  for (let key in filters) {
    if (
      (filters[key] && filters[key].length) ||
      (typeof filters[key] === "boolean" && filters[key])
    ) {
      clean[key] = filters[key];
    }
  }
  // just to keep filters clean with ids only
  if (filters?.tags && filters?.tags.length > 0) {
    clean.tags = filters.tags.map(tag => tag?.id);
  }
  return clean;
}




export default function Spreadsheet({
  filters,
  setFilters,
  currentPage,
  setCurrentPage,
  evaluationTemplates,
  columnValues
}) {

  const history = useHistory()

  // States
  const [showTagGroupForId, setShowTagGroupForId] = useState();
  const [showStartUpForId, setShowStartUpForId] = useState();
  const [showSubjectiveScoreForId, setShowSubjectiveScoreForId] = useState();
  const [showFunnelScoreForId, setShowFunnelScoreForId] = useState();
  const [funnelLoad, setFunnelLoad] = useState(false);


  const cleanFilters = filters && getCleanFilterData(filters)

  // Queries
  const accountQuery = useQuery(accountGet);
  const { data, loading, fetchMore, refetch } = useQuery(
    connectionsGet,
    {
      // fetchPolicy: (currentPage || funnelDropDownId) ? "store-only" : "network-only",
      notifyOnNetworkStatusChange: true,
      variables: { filters: cleanFilters },
    }
  );

  // Mutation
  const [mutate] = useMutation(connectionFunnelTagAdd);
  const [setStarMutation] = useMutation(connectionSetStar);

  // Data maps
  const funnelGroups = accountQuery?.data?.accountGet?.funnelGroups || [];
  const tagGroups = accountQuery?.data?.accountGet?.tagGroups || [];


  // Effects
  useEffect(() => {
    let LastEvaluatedId = currentPage && currentPage.LastEvaluatedId;
    let variables = { LastEvaluatedId };
    if (currentPage?.pageNo) {
      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult || prev,
      });
    }
  }, [currentPage]);

  // Data maps
  const connectionResp = data?.connectionsGet;
  const connections = connectionResp?.records || [];
  const connectionsCount = connectionResp?.count || 0;
  const hasFilters = getHasFilters(cleanFilters);

  // Functions
  const updateFunnelTag = async (funnelTagId, connectionId) => {
    setFunnelLoad(true);
    const variables = {
      connectionId,
      funnelTagId,
    };
    await mutate({
      variables,
    });
    setFunnelLoad(false);
  };


  return (
    <>
      <div style={{
        marginBottom: "30px",
        // position: "relative"
      }}>
        <div className="totalCount">
          <div> {connectionsCount || 0 } result{connectionsCount !== 1 && "s"}</div>
        </div>


        {
          hasFilters && (
            <FilterBar
              filters={filters}
              setFilters={setFilters}
              funnelGroups={funnelGroups}
              tagGroups={tagGroups}
              defaultFilters={defaultFilters}
            />
          )
        }

        <Table
          tagGroups={tagGroups}
          setStarMutation={setStarMutation}
          columnValues={columnValues}
          connections={connections || []}
          filters={filters}
          setFilters={setFilters}
          funnelLoad={funnelLoad}
          evaluationTemplates={evaluationTemplates}
          loading={loading}
          emptyLabel={"No results."}
          history={history}
          refetch={refetch}
          setShowFunnelScoreForId={setShowFunnelScoreForId}
          setShowTagGroupForId={setShowTagGroupForId}
          setShowStartUpForId={setShowStartUpForId}
          setShowSubjectiveScoreForId={setShowSubjectiveScoreForId}
          updateFunnelTag={updateFunnelTag}
          setCurrentPage={setCurrentPage}
        />

        <DownloadAsCsv
          columnValues={columnValues}
          filters={cleanFilters}
        />


        {showTagGroupForId && (
          <AddToGroupModalNew
            connection={connections?.find(({ id }) => id === showTagGroupForId)}
            close={() => setShowTagGroupForId(undefined)}
            history={history}
            success={() => {
              refetch();
              setShowTagGroupForId(false);
            }}
          />
        )}

        {showFunnelScoreForId && (
          <AddFunnelModal
            updateFunnelTag={updateFunnelTag}
            funnelLoad={funnelLoad}
            connection={connections?.find(
              ({ id }) => id === showFunnelScoreForId
            )}
            close={() => setShowFunnelScoreForId(undefined)}
          />
        )}

        {showStartUpForId && (
          <AddTagsForConnectionModal
            connection={connections?.find(({ id }) => id === showStartUpForId)}
            close={() => setShowStartUpForId(undefined)}
          />
        )}

        {showSubjectiveScoreForId && (
          <SubjectiveScoreModal
            connection={connections?.find(
              ({ id }) => id === showSubjectiveScoreForId
            )}
            close={() => setShowSubjectiveScoreForId(undefined)}
          />
        )}
      </div>
    </>
  );
}
