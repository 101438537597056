import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";

// API Queries		
import {
  connectionAutoCompleteName,
  groupsGetV2
} from "private/Apollo/Queries";

// CUSTOM COMPONENTS
import { Dropdown, TextBox } from "Components/NotataComponents";

// Main Function
export default function Startup({ data, setData }) {

  // States
  const [selected, setSelected] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [items, setItems] = useState({});
  const [addedItems, setAddedItems] = useState([]);
  const [searchInput, setSearchInput] = useState(undefined);

  // Queries
  let groupsQuery = useQuery(groupsGetV2);
  let [getStartups, getStartupsRes] = useLazyQuery(connectionAutoCompleteName);

  // Effects
  useEffect(() => {

    // Maps and Reducers
    let dataItems = groupsQuery?.data?.groupsGetV2 || {};
    setItems(dataItems);
    let suggestions = getStartupsRes?.data?.connectionAutoCompleteName || [];
    const getAdded = () =>
      Object.keys(data.startups)
        .map(creativeId => data.startups[creativeId])
        .filter(x => x);
    setAddedItems(getAdded);

    suggestions = suggestions.filter(
      ({ creativeId }) => !getAdded().some(({ id }) => creativeId === id)
    );
    setSuggestions(suggestions);
  }, [groupsQuery, getStartupsRes, selected, data])
  return (
    <div className="startup-container">
      <div className="add-startups row">
        <div className="col-sm-12 col-xs-12 add-text">
          Add startups from existing group:
        </div>
        {/* drop-down */}
        <div className="col-sm-12 col-xs-12 group-drop-down">
           <Dropdown
            title="Group"
            items={items}
            setSelectedItem={item => {
              setSelected(item);
            }}
          /> 
        </div>
      </div>

      <div className="startup-list">
        {selected?.startups?.map(startup => {
          return (
            <div className="row startup" key={startup.creative.id}>
              <div className="col-sm-8 col-xs-8 name">
                <span>{startup.creative.name}</span>
              </div>
              <div
                className="col-sm-4 col-xs-4 button"
                onClick={() => {
                  setData({
                    ...data,
                    startups: {
                      ...data.startups,
                      [startup.creative.id]: data.startups[startup.creative.id]
                        ? undefined
                        : startup.creative,
                    },
                  });
                }}
              >
                {data.startups[startup.creative.id] ? "added" : "+ Add"}
              </div>
            </div>
          );
        })}
      </div>

      <div className="search search-align">
        {getStartupsRes.loading && searchInput?.length > 0 ? <span className="fa fa-spinner fa-spin search-spinner" /> : <span className="material-icons search_icon">search</span>}
        {/* search-box  */}
        <TextBox
          textBoxStyle="search-box"
          placeholder="Search Startup"
          autoComplete="off"
          type="text"
          onKeyPress={event => {
            if (event.key === 'Enter') {
              setShowInfo(true);
            }
          }}
          onChange={e => {
            let search = e.target?.value;
            setSearchInput(search);
            if (search?.length === 0) {
              return setSuggestions([]);
            }
            getStartups({ variables: { search } });
          }}
        />
      </div>

      <div className="startup-list">
        {showInfo && suggestions?.length === 0 ? <div className="no_suggestion_text">This startup is not in dealflow</div> :
          suggestions?.map(suggestion => {
            return (
              <div className="row startup" key={suggestion.creativeId}>
                <div className="col-sm-8 col-xs-8 name">
                  <span>{suggestion.creativeName}</span>
                </div>
                <div
                  className="col-sm-4 col-xs-4 button"
                  onClick={() => {
                    setData({
                      ...data,
                      startups: {
                        ...data.startups,
                        [suggestion.creativeId]: data.startups[
                          suggestion.creativeId
                        ]
                          ? undefined
                          : {
                            id: suggestion.creativeId,
                            name: suggestion.creativeName,
                          },
                      },
                    });
                  }}
                >
                  {data.startups[suggestion.creativeId] ? "added" : "+ Add"}
                </div>
              </div>
            );
          })}
      </div>

      <div className="startup-list">
        <hr />
        {addedItems?.map(creative => {
          return (
            <div className="row startup" key={creative.id}>
              <div className="col-sm-8 col-xs-8 name">
                <i
                  className="fal fa-times"
                  onClick={() => {
                    setData({
                      ...data,
                      startups: {
                        ...data.startups,
                        [creative.id]: undefined,
                      },
                    });
                  }}
                />
                <span>{creative.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
