import React ,{ useState } from 'react'

// API STUFF
import { useMutation } from "@apollo/client";
import { groupUserSetRole, groupUserRemove} from "private/Apollo/Mutations";

// MAIN FUNCTION
export default function InvitedMember({canInvite, group, canRemove }) {

  // Constants
  let canChangeRole = group.iAmAdmin;
  // STATES
  const [isRemovingUser, setIsRemovingUser] = useState({});
  const [isChangingRole, setIsChangingRole] = useState({});
  // MUTATIONS
  const [userRemove] = useMutation(groupUserRemove);
  const [setRole] = useMutation(groupUserSetRole);
  const removeUser = async (email) => {
    if (isRemovingUser[email]) {
      return;
    }

    setIsRemovingUser({
      ...isRemovingUser,
      [email]: true,
    });

    let variables = {
      groupId: group.id,
      email: email,
    };

    try {
      await userRemove({ variables });
    } catch (error) {
      console.log("error", error);
    }

    setIsRemovingUser({
      ...isRemovingUser,
      [email]: false,
    });
  }
  const changeRole =async(email, role) =>{
    if (isChangingRole[email]) {
      return;
    }

    setIsChangingRole({
      ...isChangingRole,
      [email]: true,
    });

    let variables = {
      groupId: group.id,
      email: email,
      role: role === "USER" ? "ADMIN" : "USER",
    };

    try {
      await setRole({ variables });
    } catch (error) {
      console.log("error", error);
    }

    setIsChangingRole({
      ...isChangingRole,
      [email]: false,
    });
  }
  return (
    <div className="invite-member-modal__member-container">
      <span className="invite-member-modal__member-container__heading">Members</span>
      <div className="invite-member-modal__member-container__data">
        {group.members?.map((member, index) => {
          return <div className="invite-member-modal__member-container__member" key={`group-memebr-key-${index}`}>
            {canRemove && (
              <i
                className={` ${member.user?.isMe ? 'dissabled ' : ''}
                    ${isRemovingUser[member.user?.email]
                  ? "fa fa-spinner fa-spin icon"
                  : "fas fa-trash-alt"
                }`
                }
                aria-hidden="true"
                onClick={async () => !member.user?.isMe ? await removeUser(member.user?.email) : null}
              />
            )}
            <div className="invite-member-modal__member-container__member__info">
              <div className="invite-member-modal__member-container__member__info__name">
                {member.user?.given_name} {member.user?.family_name}{" "}
                {member.user?.isMe && " (you)"}
              </div>
              <div className="invite-member-modal__member-container__member__info__email">{member?.user?.email}</div>
            </div>
            <div className="member-btn"
                 style={{
                   background:
                     member?.role === "USER" ? "#FAC76F" : "#C4A9FC",
                 }}
            >
                  <span>
                    {isChangingRole[member.user?.email] ? (
                      <i
                        className="fa fa-spinner fa-spin changerole_spinner"
                      />
                    ) : member?.role === "USER" ? (
                      "member"
                    ) : (
                      "admin"
                    )}
                  </span>

              {canChangeRole && (
                <div
                  className="member-role-slct"
                  onClick={async () => await changeRole(member.user?.email, member?.role)}
                >
                  <div>
                    {member?.role === "USER" && (
                      <i className="far fa-check check" />
                    )}
                    user
                  </div>
                  <div>
                    {member?.role === "ADMIN" && (
                      <i className="far fa-check check" />
                    )}
                    admin
                  </div>
                </div>
              )}
            </div>
          </div>
        })}
      </div>
    </div>
  )
}