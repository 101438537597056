import gql from "graphql-tag";

export default gql`
  fragment accountFields on Account {
    id
    name
    description
    createdBy
    createdAt
    updatedAt
    stripeInfo{
      customer_id
      session_id
      price_id
      subscription_id
      event_type
      status
      amount
      current_period_start
      current_period_end
      cancel_at_period_end
    }
  }
`;
