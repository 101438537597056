import React from "react";

// API STUFF
import { useQuery } from "@apollo/client";
import { accountGet } from "../../../Apollo/Queries";

// UI COMPONENTS
import {
  BreadCrumbs,
  CardComponent,
  CodeSnippet,
} from "Components/NotataComponents";

// DEFINITIONS
import { settings } from "definitions";

// STYLES
import "./SettingsWebForm.scss";

// MAIN FUNCTION
export default function SettingsWebForm({ history }) {
  // Queries
  const { data, loading } = useQuery(accountGet);

  // Data maps
  let account = data?.accountGet || {};

  // CONSTANTS
  const iFrameUrl =
    `${window.location.protocol}//` +
    `${window.location.host}/` +
    `public/` +
    `creative_new/` +
    `${account.id || "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"}/` +
    `form.html`;

  const iFrameContent = `<iframe src="${iFrameUrl}" style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; border: none;"></iframe>`;

  return (
    <>
      <BreadCrumbs
        onClick={() => history.push(settings)}
        routePage={`Settings`}
        history={history}
        pages={[{ seperator: ">", page1: "WEB FORM" }]}
      />

      <div className="settingsWeb-form-container">
        <CardComponent
          containerClassName="settingsWeb-form-container__card col-md-12"
          padding="large"
          title="Web form"
          titleSize="medium"
          backLink={() => history.push(settings)}
          content={
            <div className="settingsWeb-form">
              <div>
                <p className="settingsWeb-form__description">
                  This form can be posted on your website. Please copy and paste
                  the embeddable code below, or refer to the link.
                </p>
              </div>
              <CodeSnippet
                heading="Link"
                content={iFrameUrl}
                contentType="Link"
                loading={loading}
              />
              <CodeSnippet
                heading="Code"
                content={iFrameContent}
                contentType="Code"
                loading={loading}
              />
            </div>
          }
        />
      </div>
    </>
  );
}
