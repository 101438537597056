import React from "react";

// COMPONENTS
import { SubjectiveScore } from "./SubjectiveScore";
import { Modal } from "Components/NotataComponents";


export default function SubjectiveScoreModal({
   connection,
   close,
   isPublic,
   score,
   submit,
   loading,
   isSuccess
  }) {


  return (
    <Modal
      title="Set subjective score"
      close={close}
      submit={submit}
      submitTxt={"OK"}
      loading={loading}
    >
      <div
        style={{
          fontSize: "14px",
          color: "rgb(150, 155, 163)",
          marginTop: "20px",
          marginBottom: "20px"
        }}
      >
        On a scale from 1 to 10, how would you score this company?
      </div>
      <SubjectiveScore
        connection={connection}
        isPublic={isPublic}
        score={score}
      />

      {
        isSuccess && (
          <div
            style={{
              fontSize: "12px",
              textAlign: "center",
              color: "#53cab2",
              marginTop: "10px"
            }}
          >
            Score successfully saved 🎉
          </div>
        )
      }

    </Modal>
  );
}
