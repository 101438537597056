import React, { useEffect, useState } from "react";
import {Card, NotataButton, TextBox, CheckBox} from "Components/NotataComponents";
import CardMessageContainer from 'private/Pages/Group/Components/CardMessageContainer';
import "./PinAuthenticator.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import { publicGroupTestEmail } from "public/Apollo/Queries";
import { publicGroupRegisterUser } from "public/Apollo/Mutations";
import AccessDenied from "./AccessDenied";
import { getStoredDetails } from "utils/groupSharing";

export default function MemberDetails({groupId, settings, success}) {

  let defaultMember = {
    email: "",
    family_name: "",
    given_name: "",
    consentToTerms: false
  };

  const [ member, setMember ] = useState(defaultMember)
  const [ customError, setCustomError ] = useState(undefined)
  const [ doRegisterUser, setDoRegisterUser ] = useState(false)

  const queryOptions = { context: { clientName: "public" } };
  const [testEmail, { error, data, loading, called  }] = useLazyQuery(publicGroupTestEmail, queryOptions);
  const [registerUser, registerUserRes] = useMutation(publicGroupRegisterUser, queryOptions);

  const storedData = getStoredDetails(groupId);

  const { email, family_name, given_name, consentToTerms} = member;

  const handleChange = (e) =>{
    const {name, value} = e.target;
    setMember({
      ...member,
      [name]: value
    })
  }

  async function handleSubmit() {


    if (!member.consentToTerms) {
      return setCustomError("You have to accept the terms and conditions!")
    }

    if (!member.email) {
      return setCustomError("You have to provide email!")
    }

    if (!member.family_name) {
      return setCustomError("You have to provide your last name!")
    }

    if (!member.given_name) {
      return setCustomError("You have to provide first name!")
    }

    if (settings.invitedOnly) {

      console.log('invited user logic')

      let variables = {
        groupId,
        email: member.email
      }
      if (storedData.pin) {
        variables.pin = storedData.pin
      }
      await testEmail({ variables })
    }

    setDoRegisterUser(true)

  }

  useEffect(() => {
    if (doRegisterUser && !error) {

      let variables = {
        groupId,
        ...member
      }
      if (storedData?.pin) {
        variables.pin = storedData.pin
      }
      registerUser({variables})
    }
  }, [doRegisterUser])

  useEffect(() => {
    if (registerUserRes.data && !registerUserRes.error) {
      success(member)
    }
  }, [registerUserRes])


  if (registerUserRes.error) {
    return (
      <AccessDenied/>
    )
  }

  return(
    <div className="pin-authenticator-container">
      <Card
        containerClassName="pin-authenticator-container__content"
        headerClassName="pin-authenticator-container__content__header"
      >

        {
          customError && (
            <CardMessageContainer
              title="ERROR"
              message={customError}
              error
            />
          )
        }

        {
          error && (
            <CardMessageContainer
              title="ERROR"
              message="This group is for invited users only..."
              error
            />
          )
        }


        {
          !customError && !error && (
            <CardMessageContainer
              title="Heads up!"
              message={`This group requires that you tell us who you are.`}
              notice
            />
          )
        }


        <div className="pin-authenticator-container__content__member-detail-form">
          <div className="pin-authenticator-container__content__member-detail-form__field">
            <div className="pin-authenticator-container__content__member-detail-form__field__label">
              First Name
            </div>
            <TextBox
              name="given_name"
              value={given_name}
              placeholder="First Name"
              onChange={handleChange}
              maxWidth
            />
          </div>
          <div className="pin-authenticator-container__content__member-detail-form__field">
            <div className="pin-authenticator-container__content__member-detail-form__field__label">
              Last Name
            </div>
            <TextBox
              name="family_name"
              value={family_name}
              placeholder="Last Name"
              onChange={handleChange}
              maxWidth
            />
          </div>
          <div className="pin-authenticator-container__content__member-detail-form__field">
            <div className="pin-authenticator-container__content__member-detail-form__field__label">
              Email
            </div>
            <TextBox name="email" value={email} onChange={handleChange} placeholder="name@nameson.com " maxWidth/>
          </div>
          <div className="pin-authenticator-container__content__member-detail-form__field">
            <CheckBox
              name="consentToTerms"
              value={consentToTerms}
              onSelect={()=>{
                setMember({
                  ...member,
                  "consentToTerms" : !consentToTerms
                })
              }}
              label={<span>I accept the <a href={'https://notata.io/public/terms/public-group'} style={{color: "#337ab7"}}target="_blank">terms & conditions</a></span>}
            />
          </div>
        </div>
        <div className="pin-authenticator-container__content__submit">
          <NotataButton
            loading={loading}
            onClick={handleSubmit}
            text="Next"
            buttonStyle="primary_color next_button medium"
          />
        </div>
      </Card>
    </div>
  )
}